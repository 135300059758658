import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, Paper } from "@material-ui/core";
import ImmobilisationDetailsTable from "./ImmobilisationDetailsTable";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  style1: {
    fontSize: "5em",
  },
}));
export default function ImmobilisationDetails() {
  const classes = useStyles();
  return (
    <MainContainer {...headerDataImmo}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <ImmobilisationDetailsTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
