import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const TraceabilityService = {
  //ADD USER 
  async saveTraceabilityValidation( observation, validation_state, id) {
    //console.log("io le iz", first_name, last_name, telephone, sex_id, email, password, group);
    try {
        const result = await axios.put(`${apiUrl}/traceabilityValidation/id/${id}`, {
          observation,
          validation_state, 
          id
        })

      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  //makanle traceability
  async getTraceability() {
    try {
      const result = await axios.get(`${apiUrl}/traceabilityValidation/`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async deleteTraceability(id) {
    try {
      const result = await axios.delete(`${apiUrl}/traceabilityValidation/id/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
   
  //maka anle user par id
  async getUserId(id) {
    try {
      const result = await axios.get(`${apiUrl}/traceabilityValidation/id/${id}`);
      if (result) {


        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async updateTVP(observation,validation_state,id) {
    try {
      const result = await axios.put(`${apiUrl}/TraceabilityValidation/updateTVP/${id}`,{
        observation,
        validation_state
      })
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.message;
      }
    }
  },
  async updateAllTVP(observation,validation_state,listVp) {
    try {
      const result = await axios.put(`${apiUrl}/TraceabilityValidation/updateAllTVP`,{
        observation,
        validation_state,
        listVp
      })
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.message;
      }
    }
  },
}

export default TraceabilityService;