import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../../components/Title";
import { DeleteOutlineRounded, Subject, CalendarToday, CreateTwoTone, Clear, Add, PostAdd, CalendarViewDayTwoTonew, CalendarTodayRounded, CalendarViewDaySharp, CalendarTodaySharp, CalendarTodayTwoTone } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Send";
import { DataGrid } from '@material-ui/data-grid';
import PlanningMissionService from "../../../services/PlanningMissionService";
import MissionRequestValidationService from "../../../services/MissionRequestValidationService";
import MissionService from "../../../services/MissionService";
import ConfirmationDialog from "../../tools/ConfirmationDialog";
import Message from "../../tools/Message";
import PlanningMissionSummary from "../planning-mission/PlanningMissionSummary";
import TDRService from "../../../services/TDRService";
// 
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import Spacer from "../../../components/Spacer";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function TDRElaborationTable() {
  const navigate = useNavigate();
  const contextData = useContext(userDetailContext);
  const [rows, setRows] = useState([]);
  const [note, setNote] = useState([]);
  const [id_to_delete, setId_to_delete] = useState(0);
  const [id_to_update, setId_to_update] = useState(0);
  const [lien, setLien] = useState({
    reference: ""
  });
  const [mission_id, setMission_id] = useState(0);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez vous vraiment supprimer ?',
  });

  const { openDialog, messageDialog } = confirmationDialog;

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  const [summary, setSummary] = React.useState({
    openDialogPlanningMissionSummary: false,
    dataPlanningMissionSummary: {
      reference: '',
      mission_title: '',
      location: '',
      user_name: '',
      mission_planning_id: ''
    },
  });
  const { openDialogPlanningMissionSummary, dataPlanningMissionSummary } = summary;

  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  useEffect(() => {
    console.log(localStorage.getItem('USER'));
    setOpenLoadingSimpleBackdrop(true);
    PlanningMissionService.getUserMission().then((result) => {
      if (result && result.data) {
        setRows(result.data?.map((row) => ({ ...row, id: row.number })));
        setNote(result.data);
        console.log(rows);
        // console.log(result.data?.map((row)=>({...row, id:row.mission_planning_id})) + "note ***/");
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);

  const createAgenda = (id) => (event) => {
    MissionService.checkAttachment(id).then((result) => {
      if (result && result.data) {
        if (result.data[1] === true) {
          navigate("/mission/agenda/view/" + id);
        } else {
          navigate("/mission/agenda/" + id);
        }
      }
    });
  };

  const createTDR = (id) => (event) => {
    MissionService.checkAttachment(id).then((result) => {
      console.log("++++" + id);
      console.log("result " + result.data[0]);
      if (result && result.data) {
        if (result.data[0] === true) {
          navigate("/mission/tdr/view/" + id);
        } else {
          navigate("/mission/tdr/" + id);
        }
      }
    });
  };

  const sendRequestValidation = (id) => (event) => {
    setMission_id(id);
    console.log(" +++" + id);
    PlanningMissionService.getUserMission(localStorage.getItem('USER'), id).then((result) => {
      PlanningMissionService.getMissionPlanningId(id).then((res) => {
        TDRService.generateTDR(id).then((result) => {
          if (result && result.data) {
            setLien({
              reference: result.data
            });
            console.log("****" + lien.reference);
            // SetOpenLoadingSimpleBackdrop(false);
          }
        });
        if (result && result.data) {
          if (res && res.data) {
            console.log("SUMMARY = ", res.data);
            setSummary({
              dataPlanningMissionSummary: res.data,
              openDialogPlanningMissionSummary: true
            });
          }
        }
      });
    });
  }

  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function closeDialogPlanningMissionSummary() {
    setSummary({ ...summary, openDialogPlanningMissionSummary: false });
  }

  function validateDialogPlanningMissionSummary() {
    MissionRequestValidationService.sendRequestValidatationMission(mission_id, localStorage.getItem('USER')).then((result) => {
      PlanningMissionService.getUserMission(localStorage.getItem('USER')).then((result) => {
        if (result && result.data) {
          setRows(result.data?.map((row) => ({ ...row, id: row.mission_planning_id })));
          setOpenLoadingSimpleBackdrop(false);
        } else {
          setOpenLoadingSimpleBackdrop(false);
        }
      });
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
        setSummary({ ...summary, openDialogPlanningMissionSummary: false });
        navigate("/mission/elaboration_tdr");
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
      }
    });
  }
  function cancelDialogPlanningMissionSummary() {
    setSummary({ ...summary, openDialogPlanningMissionSummary: false });
  }

  const handleDelete = (id) => (event) => {
    setId_to_delete(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true });
  };

  const handleUpdateValidate = (id) => (event) => {
    setId_to_update(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true });
  };

  const handleEdit = (id) => (event) => {
    navigate("/mission/planning_mission/add/" + id);
  };


  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    console.log("id delete =" + id_to_delete);
    MissionService.deleteElaborationTDR(id_to_delete).then((result) => {
      PlanningMissionService.getUserMission(localStorage.getItem('USER')).then((result) => {
        if (result && result.data) {
          console.log(result.data);
          console.log(localStorage.getItem('USER'));
          setRows(result.data?.map((row) => ({ ...row, id: row.mission_planning_id })));
          console.log("**************");
          console.log(rows);
          setOpenLoadingSimpleBackdrop(false);
        } else {
          setOpenLoadingSimpleBackdrop(false);
        }
      });


      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
        setSummary({ ...summary, openDialogPlanningMissionSummary: false });
        navigate("/mission/elaboration_tdr");
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
      }
    });
  }

  const columns = [
    // { field: 'number', headerName: 'N°', width: 150, },
    { field: 'reference', headerName: 'Référence', width: 200, },
    { field: 'mission_title', headerName: 'Intitulé', width: 200, },
    { field: 'location', headerName: 'Localisation Destinée', width: 200, },
    { field: 'user_name', headerName: 'Intervenant Actuel', width: 200, },
    {
      field: 'elaboration', headerName: 'Elaboration', disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <strong>
            <Button onClick={createTDR(params.row.mission_planning_id)}>
              {params.row.isTDRExist == 1 && <Subject style={{ color: "green" }} />}
              {params.row.isTDRExist == 0 && <PostAdd style={{ color: "red" }} />}
            </Button>
            <Button onClick={createAgenda(params.row.mission_planning_id)}>
              {params.row.isAgendaExist == 1 && <CalendarToday style={{ color: "green" }} />}
              {params.row.isAgendaExist == 0 && <CalendarTodayTwoTone style={{ color: "red" }} />}
            </Button>
          </strong>
        );
      },
      width: 200,
    },
    {
      field: 'actions', headerName: 'Actions', disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <strong>
            {params.row.isAgendaExist == 1 && params.row.isTDRExist == 1 && params.row.isAwaitingValidation == 0 &&
              <Button title="Envoyer pour validation" onClick={sendRequestValidation(params.row.mission_planning_id)}>
                <CheckIcon style={{ color: "green" }} />
              </Button>
            }

            {/* {params.row.isAwaitingValidation==0 &&
              <Button onClick={handleEdit(params.id)}>
                <CreateTwoTone style={{ color: "black" }} />
              </Button>
            } */}

            {params.row.isAwaitingValidation == 0 &&
              <Button onClick={handleDelete(params.id)}>
                <DeleteOutlineRounded style={{ color: "red" }} />
              </Button>
            }

            {params.row.isAwaitingValidation == 1 &&
              // <Button  onClick={handleUpdateValidate(params.id)}>
              // {/* <Clear style={{ color: "red" }} /> */}
              <h3> {params.row.note} </h3>
              // </Button>
            }

          </strong>
        );
      },
      width: 200,
    }
  ];

  return (
    <React.Fragment>
      <PlanningMissionSummary
        openDialogPlanningMissionSummary={openDialogPlanningMissionSummary}
        dataPlanningMissionSummary={dataPlanningMissionSummary}
        lien={lien.reference}
        closeDialogPlanningMissionSummary={closeDialogPlanningMissionSummary}
        validateDialogPlanningMissionSummary={validateDialogPlanningMissionSummary}
        cancelDialogPlanningMissionSummary={cancelDialogPlanningMissionSummary} />
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog}
        closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Elaboration TDR</Title>
      <Spacer y={2} />
      <div style={{ height: "70vh", width: '100%' }}>
        <DataGrid rows={rows} columns={columns} />
      </div>
    </React.Fragment>
  );
}
