import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const FamilyMemberService = {
    async getPersonalFamily() {
        try {
            const result = await axios.get(`${apiUrl}/familyMember`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getPersonalSummaryById(user_id) {
        try {
            const result = await axios.get(`${apiUrl}/familyMember/personalSummary/${user_id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getJointPersonalConfig() {
        try {
            const result = await axios.get(`${apiUrl}/familyMember/jointPersonalConfig`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getChildPersonalConfig() {
        try {
            const result = await axios.get(`${apiUrl}/familyMember/childPersonalConfig`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getPersonalPersonalConfig() {
        try {
            const result = await axios.get(`${apiUrl}/familyMember/personalPersonalConfig`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getCountChild(user_id) {
        try {
            const result = await axios.get(`${apiUrl}/familyMember/countChild/${user_id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async saveFamilyMember(formData) {
        try {
            const result = await axios.post(`${apiUrl}/familyMember`, {
                "first_name": formData.first_name,
                "last_name": formData.last_name,
                "personnal_email": formData.personnal_email,
                "card_number": formData.card_number,
                "birth_date": formData.birth_date,
                "telephone": formData.telephone,
                "sex_id": formData.sex_id,
                "card_deliverance_date": formData.card_deliverance_date,
                "address": formData.address,
                "marital_status": formData.marital_status,
                "birth_place": formData.birth_place,
                "card_deliverance_place": formData.card_deliverance_place,
                "user_id": formData.user_id,
                "relationship_type_id": formData.relationship_type_id,
                "value_personal_config": formData.value_personal_config,
                "card_duplicate_date": formData.card_duplicate_date
            }
            );
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async savePersonalConfigPersonal(formData) {
        try {
            const result = await axios.post(`${apiUrl}/familyMember/personalPersonalConfig`, {
                "user_id": formData.user_id,
                "relationship_type_id": formData.relationship_type_id,
                "value_personal_config": formData.value_personal_config
            }
            );
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getJointByUserId(user_id) {
        try {
            const result = await axios.get(`${apiUrl}/familyMember/joint/${user_id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getChildByUserId(user_id) {
        try {
            const result = await axios.get(`${apiUrl}/familyMember/child/${user_id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updateFamilyMember(formData, person_id) {
        try {
            const result = await axios.put(`${apiUrl}/familyMember/${person_id}`, {
                "first_name": formData.first_name,
                "last_name": formData.last_name,
                "personnal_email": formData.personnal_email,
                "card_number": formData.card_number,
                "birth_date": formData.birth_date,
                "telephone": formData.telephone,
                "sex_id": formData.sex_id,
                "card_deliverance_date": formData.card_deliverance_date,
                "address": formData.address,
                "marital_status": formData.marital_status,
                "birth_place": formData.birth_place,
                "card_deliverance_place": formData.card_deliverance_place,
                "card_duplicate_date": formData.card_duplicate_date
            }
            );
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getSex_id(user_id) {
        try {
            const result = await axios.get(`${apiUrl}/familyMember/jointSex/${user_id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async isMarried(user_id) {
        try {
            const result = await axios.get(`${apiUrl}/familyMember/isMarried/${user_id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getAddress(user_id) {
        try {
            const result = await axios.get(`${apiUrl}/familyMember/address/${user_id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async saveContract(formData) {
        try {
            const result = await axios.post(`${apiUrl}/contract`, {
                "filename": formData.filename,
                "uploader_user_id": formData.uploader_user_id,
                "begin_date": formData.begin_date,
                "end_date": formData.end_date,
                "user_id": formData.user_id,
                "desc": formData.desc,
                "direction_id": formData.direction
            }
            );
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async uploadFile(formData) {
        try {
            const result = await axios.post(`${apiUrl}/uploadFile`, formData);
            if (result) {
                // TODO: replace result.data.message by the token
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}

export default FamilyMemberService;
