import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Chip,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataAdmin } from "../../data/modules/admin-header";
import NestedMenuItem from "material-ui-nested-menu-item";
import GroupService from "../../services/GroupService";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../utils/privilege";
import Message from "../tools/Message";
import Box from "@mui/material/Box";
const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
    }}
  >
    *
  </Box>
);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

function GroupAdd(props) {
  const [isChecked, setIsChecked] = React.useState(false);
  const [modules, setModules] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const idGroup = params.id;

  const handleChangeMultipleElement = (element) => () => {
    const value = [...form.privilege];

    const index = value.indexOf(element);
    if (index > -1) {
      value.splice(index, 1);

      setIsChecked(false);
    } else {
      value.push(element);

      setIsChecked(true);
    }
    setForm({ ...form, privilege: value });
  };

  const classes = useStyles();
  const [message, setMessage] = useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;
  const [form, setForm] = useState({
    name: "",
    desc: "",
    privilege: [],
  });

  function handleClick() {
    GroupService.saveGroup(form.name, form.desc, form.privilege, form.id)
      .then((res) => {
        var responseErrorMessage = res.message;
        if (res.status) {
          setMessage({
            ...message,
            open: true,
            color: "green",
            messageInfo: "Succès",
          });
          navigate("/groups");
        } else {
          setMessage({
            ...message,
            open: true,
            color: "red",
            messageInfo: responseErrorMessage,
          });
        }
      })
      .catch((err) => {
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: "Une erreur s'est produite. Veuillez réessayer",
        });
      });
  }

  function handleChange1(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
  }
  
  function handleSelect() {
    GroupService.getModuleWithPrivilege().then((result) => {
      if (result && result.data) {
        setModules(
          result.data.map((d) => {
            return { id: d.group_id, label: d.name, privileges: d.privileges };
          })
        );
      }
    });
  }

  useEffect(() => {
    /**  GroupService.getModuleWithPrivilege().then((result) => {
       if (result && result.data) {
         setModules(
           result.data.map((d) => {
             return { id: d.group_id, label: d.name, privileges: d.privileges };
           })
         );
       }
     });*/
    if (idGroup) {
      GroupService.groupById(idGroup).then(({ data: info }) => {
        setForm({
          id: info.group_id,
          name: info.name,
          desc: info.desc,
          privilege: info.privilege.map((pv) => pv.privilege_id),
        });
      });
    }
  }, []);

  const getItemLabel = (id) => {
    for (const row of modules) {
      for (const privilege of row.privileges) {
        if (privilege.privilege_id === id) {
          return privilege.desc;
        }
      }
    }
    return "";
  };
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  const contextData = useContext(userDetailContext);
  function handleCancel() {
    navigate("/groups");
  }
  return (
    <MainContainer {...headerDataAdmin}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <Grid container item xs={12}>
        {hasPrivilege(contextData.privileges, "Admin") && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            ENREGISTRER
          </Button>
        )}
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <label class="aligneleft">Nom{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Nom"
              variant="outlined"
              onChange={handleChange1}
              value={form.name}
              name="name"
            />
          </Grid>

          <Grid item xs={3}>
            <label class="aligneleft">Description</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Description"
              variant="outlined"
              onChange={handleChange1}
              value={form.desc}
              name="desc"
            />
          </Grid>

          <Grid item xs={3}>
            <label class="aligneleft">Privilège{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Privilège
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Privilège"
                value={form.privilege}
                name="module_id"
                onFocus={handleSelect}
                onChange={handleChange1}
                multiple={true}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={getItemLabel(value)}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
              >
                {modules.map((row) => (
                  <NestedMenuItem label={row.label} parentMenuOpen={true}>
                    {row.privileges.map((privilege) => (
                      <MenuItem
                        key={privilege.privilege_id}
                        value={privilege.privilege_id}
                        onClick={handleChangeMultipleElement(
                          privilege.privilege_id
                        )}
                      >
                        <Checkbox
                          checked={form.privilege.includes(
                            privilege.privilege_id
                          )}
                        />
                        <ListItemText primary={privilege.desc} />
                      </MenuItem>
                    ))}
                  </NestedMenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <label className={classes.text}>
              ({bullRed}):Champs obligatoires
            </label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default GroupAdd;
