import React, { useEffect, useState } from "react";
import Color from "color";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ListLeavesInDayService from "../../services/ListLeavesInDayService";
import "aos/dist/aos.css";
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import ProfileCard from "./ProfileCard";
import { Alert } from "@mui/material";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));


const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  actionArea: {
    borderRadius: 16,
    transition: "0.6s",
    "&:hover": {
      transform: "scale(1.1)",
    },
    width: '256px',
    height: '307px',
  },
  card: ({ color }) => ({
    [theme.breakpoints.down("sm")]: {
      maxWidth: 200,
    },
    minWidth: 256,
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 ${Color(color)
        .rotate(-12)
        .darken(0.2)}`,
    },
  }),
  content: ({ color, textColor }) => {
    return {
      justifyContent: "center",
      color: textColor,
      backgroundColor: color,
      padding: "1rem 1.5rem 1.5rem",
      height: '115px',
    };
  },
  title: {
    fontFamily: "Open sans",
    fontSize: "1.5rem",
    //color: "#fff",
    //textTransform: 'uppercase',
  },
  disabledActionArea: {
    background: "black",
    cursor: "default",
    pointerEvents: "none !important",
  },
  disabledCard: {
    opacity: "0.3",
  },
  paper: {
    // height: "50px",
    padding: "10px",
  },
  subModuleActionGrid: {
    color: "black !important",
    opacity: "0.6",
    textDecoration: "none",
    '&:hover': {
      textDecoration: "underline",
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function ListLeaveCardPortal(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  const classes = useStyles();
  const gridStyles = useGridStyles();
  const [resultFinal, setResultFinal] = useState([]);
  const [resultFinalEmpty, setResultFinalEmpty] = useState(false);
  const [messageForEmptyResult, setMessageForEmptyResult] = useState("");

  
  const [userDir, setUserDir] = useState("");
  const [userDirCode, setUserDirCode] = useState("");
  const [displayDirectionList, setDisplayDirectionList] = useState("");
  const [dropDownListDirection, setDropDownListDirection] = useState(0);

  function LoadResult() {
    setOpenLoadingSimpleBackdrop(true);
    ListLeavesInDayService.getLeavesByOptions().then((result) => {
      if (result && result.status) {
        setResultFinal(result.data);
        setResultFinalEmpty(false);
        // setRows(result.data.map((row) => ({ ...row, id: row.leave_id, file: row.file_request_id })));
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
        setResultFinalEmpty(true);
        setMessageForEmptyResult(result.message);
      }

    });
  }

  function LoadResultByDirection(usrDir) {
    setOpenLoadingSimpleBackdrop(true);
    ListLeavesInDayService.getLeavesByDirection(usrDir).then((result) => {
      if (result && result.status) {
        setResultFinal(result.data);
        setResultFinalEmpty(false);
        // setRows(result.data.map((row) => ({ ...row, id: row.leave_id, file: row.file_request_id })));
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
        setResultFinalEmpty(true);
        setMessageForEmptyResult(result.message);
      }

    });
  }

  useEffect(() => {
    
    setUserDir(localStorage.getItem("USER_DIR"));
    setUserDirCode(localStorage.getItem("USER_DIR_CODE"));
    // LoadResult();

  }, [props]);

  
  useEffect(() => {
    if (userDir != "") {
      if (userDir != "1") {
        LoadResultByDirection(userDir);
      } else {
        LoadResult();
      }
    }

  }, [userDir]);
  return (
    <>

      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />

      <Grid classes={gridStyles} container spacing={4}>
        {!resultFinalEmpty &&
          resultFinal.map((row) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ProfileCard info={row} />
            </Grid>
          ))
        }
        {resultFinalEmpty &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Alert>{messageForEmptyResult}</Alert>
          </Grid>
        }
      </Grid>
    </>
  );
}
export default ListLeaveCardPortal;