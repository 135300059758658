import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import InventoryService from "../../services/InventoryService";
import Title from "../../components/Title";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  style1: {},
}));
export default function InventoryDetailsTable() {
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const codeId = localStorage.getItem("my_id");
  const codeImmo = localStorage.getItem("codeI");
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    React.useState(false);
  function loadTable() {
    setOpenLoadingSimpleBackdrop(true);
    InventoryService.getHistoryByCode(codeId).then((result) => {
      if (result && result.status) {
        console.log("result******", result);
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.history_id,
          }))
        );
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }
  useEffect(() => {
    loadTable();
  }, []);
  const columns = [
    {
      field: "date_inventaire",
      headerName: "Date inventaire",
      width: 250,
    },

    {
      field: "name",
      headerName: "Matériel",
      width: 250,
    },
    {
      field: "nom",
      headerName: "Personnes affectés",
      width: 250,
    },
    {
      field: "logistics_manager",
      headerName: "Responsable logistique",
      width: 250,
    },
    {
      field: "state",
      headerName: "Etat matériel",
      width: 250,
      renderCell: (params) => {
        if (params.row.state == "TRES BON ETAT") {
          return (
            <div
              style={{ background: "cyan", width: 250, textAlign: "center" }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "BON ETAT") {
          return (
            <div
              style={{ background: "lime", width: 250, textAlign: "center" }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "ETAT MOYEN") {
          return (
            <div
              style={{ background: "yellow", width: 250, textAlign: "center" }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "MAUVAIS ETAT") {
          return (
            <div style={{ background: "red", width: 250, textAlign: "center" }}>
              {params.row.state}
            </div>
          );
        }
      },
    },
  ];
  return (
    <>
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />

      <Title>Details inventaire de {codeImmo}</Title>
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </>
  );
}
