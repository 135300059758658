import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import Message from "../tools/Message";
import { DataGrid } from "@material-ui/data-grid";
import { escapeRegExp } from "../../utils";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import ImmobilisationService from "../../services/ImmobilisationService";
import EquipmentCategoryService from "../../services/EquipmentCategoryService";
import EquipmentConfigService from "../../services/EquipmentConfigService";
import { BuildSharp } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import DirectionService from "../../services/DirectionService";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from "@material-ui/core";
import ExportExcel from "./ExportExcel";
import FundingService from "../../services/FundingService";
import Spacer from "../../components/Spacer";
import Title from "../../components/Title";
import EditNoteIcon from '@mui/icons-material/Article';
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

export default function ImmobilisationTable(props) {
  const type = props.isExtra;
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getFullYear() +
    "/" +
    (myCurrentDate.getMonth() + 1) +
    "/" +
    myCurrentDate.getDate();
  const dirs = localStorage.getItem("direction");
  const [dataToExport, setDataToExport] = useState([]);
  const [fileName, setFileName] = useState("Code Immo matériel " + date);
  const isGenerateQrCode = props.isGenerateQrCode;
  // --------------------------------------------
  const [receptions, setReception] = useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiDataGrid-cell': {
        backgroundColor: theme.palette.mode === 'dark' ? '' : '',
      },
      '& .reception-cell': {
        backgroundColor: 'green',
      },
      '& .generateQrCode-cell': {
        backgroundColor: 'yellow',
      },
    },
  }));
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = useState([]);
  const [id_to_delete, setId_to_delete] = useState(0);
  const [id_to_config, setId_to_config] = useState(0);
  const [dir, setDir] = useState([]);
  const [direction_id, setIddir] = useState(1);
  const miditra = 0;
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: "Voulez vous vraiment supprimer  ?",
  });
  const [configurationDialog, setConfigurationDialog] = React.useState({
    openDialog: false,
    messageDialog: "Voulez vous vraiment configurer  ?",
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;
  const [form, setForm] = useState({
    direction_id: " ",
    funding_id: " ",
    service_id: " ",
  });
  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const [funding, setFunding] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);

  const [openGenerateQrCode, setopenGenerateQrCode] = React.useState(false);
  const pagesNextCursor = React.useRef({});
  const wscols = [
    {
      wch: 25,
    },
    { wch: 25 },
    { wch: 45 },
    { wch: 15 },
    { wch: 25 },
    { wch: 45 },
  ];
 
  function loadTable(page,name,code,date1,date2,fond,dir) {
    
    setOpenLoadingSimpleBackdrop(true);
    console.log(type,
      name, code);
    if (type === 0) {
   
              ImmobilisationService.getAllCodeImmo(page,name,code,date1,date2,fond,dir).then((result) => {
                if (result && result.status) {
                  setRows(
                    result.data.map((row) => ({
                      ...row,
                      id: row.code_immo_id,

                      id2: row.equipment_ranking_id,
                      codeImmo: row.code_immo_code,
                      nom: row.name,
                    }))
                  );
                  setOpenLoadingSimpleBackdrop(false);
                } else {
                  setOpenLoadingSimpleBackdrop(false);
                }
              });
      
    } else {
      ImmobilisationService.getAllCodeImmoExtra(page,name,code,date1,date2,fond,dir).then((result) => {
        if (result && result.status) {
          setRows(
            result.data.map((row) => ({
              ...row,
              id: row.code_immo_id,

              id2: row.equipment_ranking_id,
              codeImmo: row.code_immo_code,
              nom: row.name,
            }))
          );
          setOpenLoadingSimpleBackdrop(false);
        } else {
          setOpenLoadingSimpleBackdrop(false);
        }
      });
    }
  }
  function handleCloseDialog() {
    setIsOpen(false);
  }
  function initalizeStorage(name){
    if(localStorage.getItem(name)){
      localStorage.setItem(name,localStorage.getItem(name));
     }
    else{
      localStorage.setItem(name, "");
    }
  }
  function loadTableByDirection(direction_id) {
    setOpenLoadingSimpleBackdrop(true);
    ImmobilisationService.getAllCodeImmoByDirection(type, direction_id).then(
      (result) => {
        if (result && result.status) {
          setRows(
            result.data.map((row) => ({
              ...row,
              id: row.code_immo_id,

              id2: row.equipment_ranking_id,
              codeImmo: row.code_immo_code,
              nom: row.name,
            }))
          );
          setRowCount(result.data.length);
          setOpenLoadingSimpleBackdrop(false);
        } else {
          setRows([]);
          setOpenLoadingSimpleBackdrop(false);
        }
      }
      
    );
  }

  function changeValue(key,value){
    
    let valeurActuelle = localStorage.getItem(key);

    valeurActuelle = value;


    localStorage.setItem("'"+key+"'", value);

  }

  useEffect(() => {
      initalizeStorage('name');
      initalizeStorage('code');
      initalizeStorage('date1');
      initalizeStorage('date2');
      initalizeStorage('funding');
      initalizeStorage('dir');

    const name = localStorage.getItem('name');
    const code = localStorage.getItem('code');
    const date1 = localStorage.getItem('date1');
    const date2 = localStorage.getItem('date2');
    const fund = localStorage.getItem('funding');
    const dir = localStorage.getItem('dir');
    console.log("const nom", name);
    console.log("const nom", code);
      loadTable(page,name,code,date1,date2,fund,dir);
      ImmobilisationService.getTotalCountOfImmo(type,name,code,date1,date2,fund,dir).then((res) => {
        if (res && res.status) {
          setRowCount(res.data);
        }
      });
   
    DirectionService.getDirection().then((res) => {
      console.log(res);
      if (res && res.status) {
        setDir(res.data);
      }
    });
    FundingService.getFunding().then((res) => {
      if (res && res.status) {
        setFunding(res.data);
      }
    });
    const essai = "Manette";
  }, []);
  function handleChange(event) {
    let direction = 0;
    let funding = 0;

    //localStorage.removeItem("direction");
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    if (name === "direction_id") {
      localStorage.removeItem("direction");

      setIddir(value);
      console.log("direction= ", value);
      localStorage.setItem("direction", value);
    }
    direction = localStorage.getItem("direction");
    if (name === "funding_id") {
      localStorage.removeItem("funding");

      console.log("funding= ", value);
      localStorage.setItem("funding", value);
    }
    funding = localStorage.getItem("funding");

    ImmobilisationService.exportExcel(direction, funding).then((result) => {
      console.log(direction);
      if (result && result.data) {
        setDataToExport(result.data);
      }
    });
  }
  const handleEdit = (id, id2, codeImmo, nom) => (ev) => {
    setId_to_config(id);

    localStorage.setItem("key1", id);
    localStorage.setItem("er", id2);
    localStorage.setItem("codeImmo", codeImmo);
    localStorage.setItem("nom", nom);

    console.log(
        "id immo: ", id,
        "  |id2: ", id2,
        "  |codeImmo: ", codeImmo,
        "  |nom: ", nom,
    );
    
    console.log(id2);
    EquipmentConfigService.verification(id2).then((result) => {
      localStorage.setItem("verify", result.data[0]["total"]);

      console.log("verify= ", result.data[0]["total"]);
    });
    const verify = parseInt(localStorage.getItem("verify"));

    navigate("/Logistic/immo/add/" + id);

  };


  const handleSelect = (id, codeImmo) => (ev) => {
    navigate("/Logistic/details/" + id);
    localStorage.setItem("key1", id);
    localStorage.setItem("codeImmo", codeImmo);
  };


  const handleConfig = (id, id2, codeImmo, nom) => (ev) => {
    setId_to_config(id);
    setConfigurationDialog({
      ...configurationDialog,
      openDialog: true,
      messageDialog: "Voulez vous vraiment configurer?",
    });
    console.log(id2);
    EquipmentConfigService.verification(id2).then((result) => {
      localStorage.setItem("verify", result.data[0]["total"]);

      console.log("verify= ", result.data[0]["total"]);
    });
    const verify = parseInt(localStorage.getItem("verify"));
    console.log(typeof verify);

    console.log("1= ", verify);
    //navigate("/Logistic/feature/" + id);
    navigate("/Logistic/immo/add/configuration/" + id);

    localStorage.setItem("key1", id);
    localStorage.setItem("er", id2);
    localStorage.setItem("codeImmo", codeImmo);
    localStorage.setItem("nom", nom);

    EquipmentConfigService.deleteConfig(id);
    console.log(rows);
  };


  const handleDelete = (id) => (ev) => {
    setId_to_delete(id);
    setConfirmationDialog({
      ...confirmationDialog,
      openDialog: true,
      messageDialog: "Voulez vous vraiment supprimer?",
    });
  };

  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    ImmobilisationService.deleteCodeImmo(id_to_delete)
      .then((result) => {
        if (result.status) {
          setMessage({
            ...message,
            open: true,
            color: "green",
            messageInfo: result.message,
          });
          window.location.reload();
        } else {
          setMessage({
            ...message,
            open: true,
            color: "red",
            messageInfo: result.message,
          });
        }
      })
      .catch((err) => {
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: "erreur",
        });
      });
  }

  function handleClickGenerateQrCode() {
    setopenGenerateQrCode(true);
  }

  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  const handleDeleteConfig = (id) => (ev) => { };
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  const handlePageChange = (newPage) => {
    const name = localStorage.getItem('name');
    const code = localStorage.getItem('code');
    const date1 = localStorage.getItem('date1');
    const date2 = localStorage.getItem('date2');
    const fund = localStorage.getItem('funding');
    const dir = localStorage.getItem('dir');
    setPage(newPage);
    loadTable(newPage,name,code,date1,date2,fund,dir);
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = [...rows].filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
  };
  const columns = [
    {
      field: "code_immo_code",
      headerName: "Code",
      width: 200,
    },
    {
      field: "name",
      headerName: "Matériel",
      width: 200,
    },
    {
      field: "acquis".toLocaleString(),
      headerName: "Valeur d'acquisition",
      width: 200,

      valueFormatter: (params) => {
        return `${params.row.acquis} ${params.row.unite}`;
      },
    },
    
    {
      field: "date_acquis_fr",
      headerName: "Date d'acquisition",
      width: 200,
    },
    {
      field: "codeFunding",
      headerName: "Financement",
      width: 150,
    },
    {
      field: "codeDirection",
      headerName: "Direction",
      width: 300,
      valueGetter: (params) => {
        return params.row.direction_name + "(" + params.row.codeDirection + ")";
      },
    },
    { field: "number_ordre", headerName: "Ordre", width: 150 },
    {
      field: "action",
      headerName: "Action",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        if (params.row.isReception==1) {
          return (
            <div>
              <Button onClick={handleSelect(params.id, params.row.codeImmo)} title="Détails configuration">
                <ArticleIcon />
              </Button>
  
              <Button
                onClick={handleEdit(
                  params.id,
                  params.row.id2,
                  params.row.codeImmo,
                  params.row.nom
                )}
                title="Modifier"
              >
                <CreateTwoTone style={{ color: "black" }} />
              </Button>
              <Button onClick={handleDelete(params.id)} title="Supprimer">
                <DeleteOutlineRounded style={{ color: "red" }} />
              </Button>

              <Button onClick={handleSelect(params.id, params.row.codeImmo)} title="Facturer">
                <EditNoteIcon style={{ color: "rgb(58, 139, 58)" }}  />
              </Button>
            
            </div>
          );
        }else{
          return (
              <div>
                <Button onClick={handleSelect(params.id, params.row.codeImmo)} title="Détails configuration">
                  <ArticleIcon />
                </Button>

                <Button
                  onClick={handleEdit(
                    params.id,
                    params.row.id2,
                    params.row.codeImmo,
                    params.row.nom
                  )}
                  title="Modifier"
                >
                  <CreateTwoTone style={{ color: "black" }} />
                </Button>
                <Button onClick={handleDelete(params.id)} title="Supprimer">
                  <DeleteOutlineRounded style={{ color: "red" }} />
                </Button>
              </div>
        );
      }
        
      },
      width: 300,
    },
  ];

  const columnsForGenerateQrCode = [
    {
      field: "code_immo_code",
      headerName: "Code",
      width: 200,
    },
    {
      field: "name",
      headerName: "Libellé",
      width: 200,
    },
    {
      field: "acquis".toLocaleString(),
      headerName: "Valeur d'acquisition",
      width: 200,

      valueFormatter: (params) => {
        return `${params.row.acquis} ${params.row.unite}`;
      },
    },
    {
      field: "date_acquis_fr",
      headerName: "Date d'acquisition",
      width: 200,
    },
    {
      field: "codeFunding",
      headerName: "Financement",
      width: 150,
    },
    {
      field: "codeDirection",
      headerName: "Direction",
      width: 300,
      valueGetter: (params) => {
        return params.row.direction_name + "(" + params.row.codeDirection + ")";
      },
    },
    { field: "number_ordre", headerName: "Ordre", width: 150 },
  ];

  return (
    <>
      <ConfirmationDialog
        openDialog={openDialog}
        messageDialog={messageDialog}
        closeDialog={closeDialog}
        confirmDialog={confirmDialog}
        cancelDialog={cancelDialog}
      />
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <Title>Listes des Matériels avec leurs Codes Immos</Title>
      <Spacer y={2} />

      <div style={{ height: "70vh", width: "100%" }}>
        <Spacer y={2} />
        <DataGrid
          rows={rows}
          columns={isGenerateQrCode ? columnsForGenerateQrCode : columns}
          pagination
          pageSize={7}
          className={classes.root}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
          rowCount={rowCount}
          paginationMode="server"
          onPageChange={handlePageChange}
          page={page}
          getCellClassName={(params) =>
            params.row.isReception == 1 ? 'reception-cell' : ''
          }
          getRowClassName={(params) =>
            (params.row.reference_file != null && isGenerateQrCode) ? 'generateQrCode-cell' : ''
          }
        />
      </div>
    </>
  );
}
