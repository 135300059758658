
import React, { useEffect, useState } from 'react'
import Timeline, {
    TimelineHeaders,
    SidebarHeader,
    DateHeader
} from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import ValidatePlanningDialog from '../tools/ValidatePlanningDialog';
import RefusedPlanning from '../tools/RefusedPlanning';
import ValidationService from '../../services/ValidationService';
import TraceabilityService from "../../services/TraceabilityService";
import ValidationPlanningService from "../../services/ValidationPlanningService";

export default function TimelineView(props) {

    const startTime = props.start_time;
    const endTime = props.end_time;
    const difference_In_Days = props.difference_In_Days;
    const groups = props.groups;
    const items = props.items;
    const open = props.open;
    const id_parameter = props.id_parameter;
    const type = props.type;
    const handleCancel = props.closeDialog4;
    const handleConfirmRefused = props.closeConfirm;
    const openValidatePlanningTimeline = props.openValidatePlanningTimeline;
    const handleRefusedTimeline = props.handleRefusedTimeline;
    const closeValidate = props.closeValidate;
    // const openValidatePlanning = props.openValidatePlanning;
    const handleClickItem = props.handleClick;
    const openRefuseStep1 = props.openRefuseStep1;
    const openLoadingSimpleBackdrop = props.openLoadingSimpleBackdrop;

    const [openValidatePlanning, setopenValidatePlanning] = useState(false);
    const [idPlanning, setidPlanning] = useState(0);
    const [userId, setuserId] = useState(0);

    const [windowSize, setWindowSize] = useState(getWindowSize());
    // const [defaultTimeStart, setdefaultTimeStart] = useState(moment("2022-10-01").add(-1, 'day'));
    // const [defaultTimeEnd, setdefaultTimeEnd] = useState(moment("2022-10-01").add(41, 'day'));
    // const [defaultVisableTimeEnd, setdefaultVisableTimeEnd] = useState(moment("2022-10-01").add(365, 'day'));
    const [defaultTimeStart, setdefaultTimeStart] = useState(moment(startTime).add(-1, 'day'));

    const [defaultTimeEnd, setdefaultTimeEnd] = useState(windowSize.innerWidth <= 1000 ? moment(startTime).add(15, 'day') :
        (1000 < windowSize.innerWidth && windowSize.innerWidth <= 1200) ? moment(startTime).add(35, 'day') : (1200 < windowSize.innerWidth && windowSize.innerWidth <= 1400) ? moment(startTime).add(50, 'day') :
            (1400 < windowSize.innerWidth && windowSize.innerWidth <= 1600) ? moment(startTime).add(60, 'day') : moment(startTime).add(80, 'day'));

    const [defaultVisableTimeEnd, setdefaultVisableTimeEnd] = useState(moment(startTime).add(difference_In_Days + 1, 'day'));

    const [defaultTimeRange, setdefaultTimeRange] = useState();
    const [itemFinal, setItemFinal] = useState([]);
    const [isItemChange, setisItemChange] = useState(false);

    const itemRenderer = ({
        item,
        itemContext,
        getItemProps,
        getResizeProps
    }) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
        return (
            <>
                {/* <div {...getItemProps(item.itemProps)} onClick={() => handleClick(item.id, item.itemProps.userID)}> */}
                <div {...getItemProps(item.itemProps)}>

                </div>
            </>

        )
    }

    useEffect(() => {

        setdefaultVisableTimeEnd(moment(startTime).add(difference_In_Days + 1, 'day'));
        if (open) {
            setdefaultVisableTimeEnd(moment(startTime).add(difference_In_Days + 1, 'day'));
        }
        setdefaultTimeRange(defaultTimeStart - defaultVisableTimeEnd);
        const itemFinalTemp = [];
        console.log("itemsssss", items);
        items.map((item) => {
            // console.log('item*******************2222', item);
            const itemTemp = new Array();
            itemTemp.id = item.planning_id ? item.planning_id : item.id;
            itemTemp.group = item.group;
            // itemTemp.title = "title";
            itemTemp.start_time = moment(item.start_time).add(0, 'hour');
            itemTemp.end_time = moment(item.end_time).add(0, 'hour');
            itemTemp.canResize = false;
            // itemTemp.itemProps = item.itemProps;
            var style = [];
            var _onDoubleClick = "";
            var itemProps = [];
            if (item.planning) {
                switch (item.itemProps) {
                    case 1: // confirmed
                        style.background = '#008000';
                        break;
                    case 0: // declined
                        style.background = '#FF0000';
                        break;
                    case 2: // pending
                        _onDoubleClick = () => handleClickItem(item.planning_id);
                        style.background = '#65C5EB';
                        break;
                    case 3: // pending
                        // _onDoubleClick = () => handleClickItem(item.planning_id);
                        style.background = '#133E67';
                        break;
                }
            } else {
                switch (item.itemProps) {
                    case 0: // en attente
                        style.background = '#6ACFF6';
                        break;
                    case 1: // confirmé
                        style.background = '#133E67';
                        break;
                    case 2: // congé validé
                        style.background = '#FFFF00';
                        break;
                    case 3: // congé consommé
                        style.background = '#00FF00';
                        break;
                    case 4: // annulé ou refusé
                        style.background = '#FF0000';
                        break;
                }
            }

            itemProps.style = style;
            itemProps.userID = item.user_id;
            //itemProps.onDoubleClick = _onDoubleClick;
            itemTemp.itemProps = itemProps;
            itemFinalTemp.push(itemTemp);
        })
        console.log("itemFinalTemp***", itemFinalTemp);
        setItemFinal(itemFinalTemp);
        setWindowSize(getWindowSize());
    }, [items]);

    useEffect(() => {
        // if (openValidatePlanningTimeline || !openValidatePlanningTimeline) {
        //     setopenValidatePlanning(openValidatePlanningTimeline);
        // }
        console.log("open ve", openValidatePlanningTimeline);
        setopenValidatePlanning(openValidatePlanningTimeline);
    }, [openValidatePlanningTimeline]);

    // useEffect(() => {
    //     console.log("props.maxWidth***", windowSize);
    //     if(windowSize.innerWidth <= 1000){
    //         console.log("props.maxWidth ato***");
    //         setdefaultTimeEnd(moment(startTime).add(15, 'day'));
    //     }else if(1000<windowSize.innerWidth<=1200){
    //         console.log("props.maxWidth ato1***");
    //         setdefaultTimeEnd(moment(startTime).add(30, 'day'));
    //     }else if(1200<windowSize.innerWidth<=1400){
    //         console.log("props.maxWidth ato2***");
    //         setdefaultTimeEnd(moment(startTime).add(40, 'day'));
    //     }else if(1400<windowSize.innerWidth<=1600){
    //         console.log("props.maxWidth ato3***");
    //         setdefaultTimeEnd(moment(startTime).add(60, 'day'));
    //     }else{
    //         console.log("props.maxWidth ato4***");
    //         setdefaultTimeEnd(moment(startTime).add(80, 'day'));
    //     }
    // }, [windowSize]);
    // const handleClick = (id, event) => {
    //     console.log("voakoit", id);
    //     setopenValidatePlanning(true);
    //     setidPlanning(id);
    //     ValidationService.getPlanningDetail(id).then((res)=>{

    //     });
    // }

    function handleClick(id, userID) {
        setopenValidatePlanning(true);
        setidPlanning(id);
        setuserId(userID);
    }
    function handlecloseDialog() {
        setopenValidatePlanning(false);
    }




    function handleValidateTimeline() {
        const validation_state = 0;
        const observation = null;
        ValidationPlanningService.confirmedVP(idPlanning).then((res) => {
            TraceabilityService.updateTVP(
                observation,
                validation_state,
                res.data
            ).then((result) => {
                ValidationPlanningService.verification(userId, id_parameter).then((r) => {
                    if (r && r.status) {
                        closeValidate();
                        setopenValidatePlanning(false);
                    }
                })
            })
        })
    }
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    return (
        <div>
            <ValidatePlanningDialog openDialog={openValidatePlanning} closeDialog={handlecloseDialog}
                idPlanning={idPlanning} handleRefused={handleRefusedTimeline} handleValidate={handleValidateTimeline} />

            {/* <RefusedPlanning idRequest={idPlanning} id_parameter={id_parameter} type={type}
                openRefuseStep1={openRefuseStep1} closeDialog4={handleCancel}
                closeConfirm={handleConfirmRefused} /> */}
            <div>
                <Timeline
                    groups={groups}
                    items={itemFinal}
                    defaultTimeStart={defaultTimeStart}
                    defaultTimeEnd={defaultTimeEnd}
                    minZoom={defaultTimeRange}
                    maxZoom={defaultTimeRange}
                    onTimeChange={(_start, _end, updateScrollCanvas) => {
                        if (_start > defaultTimeStart && _end < defaultVisableTimeEnd)
                            updateScrollCanvas(_start, _end);
                    }}
                    itemRenderer={itemRenderer}
                    fixedHeader="fixed"
                >

                    <TimelineHeaders className="timelineSticky">
                        <SidebarHeader>
                            {({ getRootProps }) => {
                                return <div className="divHeaderName" {...getRootProps()}>Prénom(s)</div>;
                            }}
                        </SidebarHeader>
                        <DateHeader unit="primaryHeader" />
                        <DateHeader />
                    </TimelineHeaders>
                </Timeline>
            </div>
        </div>
    )
}
