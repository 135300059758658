import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Spacer from "../../components/Spacer";
import Grid from "@material-ui/core/Grid";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Chip,
  Checkbox,
  ListItemText,
  Button
} from "@material-ui/core";
import { useLocation, useNavigate } from 'react-router-dom';
import PersonnelService from "../../services/PersonnelService";
import Message from "./Message";
import Alert from "@material-ui/lab/Alert";
import Box from '@mui/material/Box';
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  select: {
    width: 400,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  datePicker: {
    margin: 0,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      float: 'right',
    },
  },
  forms:
  {
    width: 500,
  },
  labelPosition: {
    float: 'left',
  },
  lien: {
    float: 'right',
  },
}));

export default function Setting() {

  const navigate = useNavigate();
  const classes = useStyles();
  const [tests, setTests] = React.useState([]);
  const [groupIsNull, setGroupIsNull] = React.useState(false);
  const [personName, setPersonName] = useState([]);
  const [etat, setEtat] = useState({ open: false, message: "message" });
  const [groups, setGroups] = useState({
    group: [],
  });
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const idGroup = splitLocation[3];
  const handleChange = (event) => {
    const { name, value } = event.target;
    setGroups({ ...groups, [name]: value });
    setPersonName(event.target.value);
  };

  function handleClick() {
    if (groups.group.length > 0) {
      localStorage.setItem("groups", JSON.stringify(groups.group));
      if (idGroup) {
        if (idGroup !== "parametrage") {
          navigate("/personnel/add/" + idGroup + "/utile");
          window.location.reload();
        } else {
          navigate("/personnel/add/utile");
          window.location.reload();
        }
      } else {
        navigate("/personnel/add/utile");
        window.location.reload();
      }
    } else {
      setGroupIsNull(true);
    }

  }
  useEffect(() => {
    PersonnelService.getGroup().then((result) => {
      if (result && result.data) {
        setTests(result.data);
      }
    });
    if (localStorage.getItem("groups")) {
      const group_id = JSON.parse(localStorage.getItem("groups")) + "";
      var group = group_id.split(",");
      setGroups(
        {
          group: group
        }
      );
    }
  }, [setGroups]);

  const getItemLabel1 = (id) => {
    for (const row of tests) {
      if (row.group_id === id) {
        return row.name;
      }

    }
    return "";
  };

  return (
    <React.Fragment>
      <Message isActive={etat.open} message={etat.message} />
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Groupes{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" className={classes.forms} size="small">
            <InputLabel id="demo-mutiple-name-label">Group</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              multiple={true}
              value={groups.group}
              name="group"
              onChange={handleChange}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={getItemLabel1(value)}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
            >
              {tests.map((row) => (

                <MenuItem value={row.group_id}>
                  <Checkbox checked={groups.group.includes(row.group_id)} />
                  {/* <Checkbox checked={personName.indexOf(row.group_id) > -1} /> */}

                  <ListItemText primary={row.name} />
                </MenuItem>
              ))}
            </Select>
            {groupIsNull && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Veuillez configurer le groupe pour cet personnel!</Alert></>
            )}
          </FormControl>
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>

        </Grid>
        <Grid item xs={8}>
          <div className={classes.root}>
            <Button variant="contained" color="primary" onClick={handleClick}>
              Valider
            </Button>

          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
