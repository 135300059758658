import { Grid, Paper } from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import EquipmentConfigTable from "./EquipmentConfigTable";
import EquipmentConfigRankingTable from "./EquipmentConfigRankingTable";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  style1: {},
}));
export default function EquipmentConfigRankingList() {
  const classes = useStyles();
  const navigate = useNavigate();

  function handleClick() {
    navigate("/Logistic/config/add");
  }
  return (
    <MainContainer {...headerDataImmo}>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <EquipmentConfigRankingTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
