import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const InventoryService = {
  async getHistory(name_materiel,code_immo,state) {
    try {
      const result = await axios.get(`${apiUrl}/HistoryInventory/`,{params: {name_materiel:name_materiel,code_immo:code_immo,state:state}});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getHistoryTransfered(name_materiel,code_immo,state) {
    try {
      const result = await axios.get(`${apiUrl}/HistoryInventory/transfered`,{params: {name_materiel:name_materiel,code_immo:code_immo,state:state}});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getEtatValue(date1,date2) {
    try {
      const result = await axios.get(`${apiUrl}/HistoryInventory/statisticState/`,{params: {date1 : date1, date2 : date2}});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getHistoryByCode(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/HistoryInventory/codeImmo/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async logistics_manager() {
    try {
      const result = await axios.get(
        `${apiUrl}/HistoryInventory/respLogistic`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async exportExcel() {
    try {
      const result = await axios.get(`${apiUrl}/HistoryInventory/export`);
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async exportDetenteurExcel() {
    try {
      const result = await axios.get(`${apiUrl}/HistoryInventory/exportDetenteur`);
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getHistoryImmo(data) {
    try {
      const result = await axios.post(`${apiUrl}/HistoryInventory/codeImmos`, {
        data:data
    });
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async validateImmoToStock(data) {
    try {
      const result = await axios.post(`${apiUrl}/HistoryInventory/addImmoToStock`, {
        data:data
    });
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async validateImmoNewAffectation(data,user_id) {
    try {
      const result = await axios.post(`${apiUrl}/HistoryInventory/addNewAffectation`, {
        data:data,
        id:user_id
    });
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async validateImmoNewTransfert(data,direction_id,recepteur_id) {
    try {
      const result = await axios.post(`${apiUrl}/HistoryInventory/addNewATransfert`, {
        data:data,
        id:direction_id,
        id_recepteur:recepteur_id
    });
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async validateStateByOldHolder(data,user_id) {
    try {
      const result = await axios.post(`${apiUrl}/HistoryInventory/validateStateByOldHolder`, {
        data:data,
        id:user_id
    });
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async validateStateAffectationHolder(data,user_id) {
    try {
      const result = await axios.post(`${apiUrl}/HistoryInventory/validateStateAffectationHolder`, {
        data:data,
        id:user_id
    });
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async validateStateAffectationLogisticsManager(data) {
    try {
      const result = await axios.post(`${apiUrl}/HistoryInventory/validateStateAffectationLogisticsManager`, {
        data:data
    });
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async validateStateTransfert(data) {
    try {
      const result = await axios.post(`${apiUrl}/HistoryInventory/validateStateTransfert`, {
        data:data
    });
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getMineImmoHistory() {
    try {
      const result = await axios.get(`${apiUrl}/HistoryInventory/Mine`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getCountMineImmoHistory() {
    try {
      const result = await axios.get(`${apiUrl}/HistoryInventory/mineCount`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getMinePendingImmoHistory(name_materiel,code_immo,state,dates2,status1,status2) {
    try {
      const result = await axios.get(`${apiUrl}/HistoryInventory/PendingValidation`,{params: {name_materiel:name_materiel,code_immo:code_immo,state:state,dates2:dates2,status1:status1,status2:status2}});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getCountMinePendingImmoHistory() {
    try {
      const result = await axios.get(`${apiUrl}/HistoryInventory/countPendingValidationByUser`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async generateSignedPDf(id) {
    try {
      const result = await axios.get(`${apiUrl}/HistoryInventory/generateSignedPDf/${id}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf',
        }});
      if (result) {
        // TODO: replace result.data.message by the token
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};
export default InventoryService;
