import React, { useState, useEffect } from "react";
import MainContainer from "../../../components/MainContainer";
import { headerDataLibrary } from "../../../data/modules/rubrique_header";
import { Grid, Paper, Button } from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import TextField from "@material-ui/core/TextField";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import RubriquesService from "../../../services/RubriquesService";
import { useLocation } from "react-router-dom";
import Result from "../../personnel/Result";

const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
    }}
  >
    *
  </Box>
);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  // text: {
  //   width: 370,
  // },
  labelPosition: {
    float: "left",
  },
  text: {
    textTransform: "uppercase",
    fontSize: "16px",
    fontWeight: "bold",
    // textTransform: "capitalize"
  },

  colorInput: {
    width: "80px",
  },
}));

export default function RubriqueAdd() {
  const classes = useStyles();
  const [etats, setEtats] = useState({
    open: false,
    message: "message",
    color: "",
  });

  //creation de formulaire
  const [form, setForm] = useState({
    id: "",
    code_couleur: "",
    codification: "",
    titre_rubrique: "",
    desc_rubrique: "",
    rubrique_mere: "",
    visionnage: "",
  });

  const [rubrique, setRubrique] = useState([]);

  const [userList, setUserList] = useState([]);

  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const navigate = useNavigate();

  const [color, setColor] = useState("");

  function handleChange(ev) {
    const { name, value } = ev.target; //destructuration

    if (name == "codification") {
      setForm({ ...form, [name]: value });
    } else if (name == "titre_rubrique") {
      setForm({ ...form, [name]: value });
    } else if (name == "desc_rubrique") {
      setForm({ ...form, [name]: value });
    } else if (name == "visionnage") {
      setForm({ ...form, [name]: value });
    } else {
      setForm({ ...form, [name]: value });
    }
  }
  const userId = localStorage.getItem("USER");

  //const varInt = parseInt(userId);
  //console.log('******* type de data userid', typeof(varInt)); //string

  // const [insertedRubriqueId, setInsertedRubriqueId] = useState(0);
  // const [insertedRubriqueName, setInsertedRubriqueName] = useState("");

  //fonction pour le bouton enregistrer
  function handleClick() {
    RubriquesService.saveRubrique(
      form.code_couleur,
      form.codification,
      form.titre_rubrique,
      form.desc_rubrique,
      form.rubrique_mere,
      form.id,
      form.visionnage
    ).then((results) => {
      if (results.status) {
        setEtats({
          ...etats,
          open: true,
          color: "green",
          message: results.message,
        });

        navigate("/rubrique");

        console.log("rubriques existantes , ", results);

        const lastInsertedRubriqueId = results.lastInsertedRubriqueId;
        console.log("lastInsertedRubriqueId: ", lastInsertedRubriqueId);

        // -------------------------------------

        RubriquesService.savePrivilege(
          "rubrique",
          0,
          1,
          0,
          0,
          1,
          lastInsertedRubriqueId,
          userList,
          "specifique",
          1,
          userId
        ).then((resultPrivilege) => {
          if (resultPrivilege.status) {
            console.log(
              "resultPrivilege: ******************* ",
              resultPrivilege
            );
            console.log("nety ny ketrika  tafiditra ny pv");
          } else {
            console.log("tsy nety ny fika tsy tafiditra ny pv");
          }
        });

        // -------------------------------------

        if (results.inserted_rubrique !== undefined) {
          console.log("Ajout rubrique");
          const rubriqueIdInserted = results.inserted_rubrique["id_rubrique"];
          const rubriqueName = results.inserted_rubrique["titre_rubrique"];

          const userId = localStorage.getItem("USER");

          RubriquesService.saveHistory(
            userId,
            rubriqueIdInserted,
            rubriqueName,
            "Ajout d'une rubrique"
          ).then((historyResult) => {
            if (historyResult.status) {
              console.log("Historique d'ajout enregistrée");
            } else {
              console.log("Historique d'ajout non enregistrée");
            }
          });
        } else {
          
          const updatedRubriqueId = results.updatedRubrique["id_rubrique"];
          const updatedRubriqueName = results.updatedRubrique["titre_rubrique"];

          const userId = localStorage.getItem("USER");

          RubriquesService.saveHistory(
            userId,
            updatedRubriqueId,
            updatedRubriqueName,
            "Modification de rubrique"
          ).then((historyResult) => {
            if (historyResult.status) {
              console.log("Historique de modification enregistrée");
            } else {
              console.log("Historique de modification non enregistrée");
            }
          });
        }
      } else {
        setEtats({
          ...etats,
          open: true,
          color: "red",
          message: results.message,
        });
      }
    });
  }

  function handleClickAnnuler(e) {
    navigate("/rubrique");
  }

  function handleChangeRubriqueMere(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    RubriquesService.getNom(value).then((results) => {
      if (results && results.data) {
        setRubrique(results.data);
      }
    });
  }

  //const [rubriqueIdLists, setRubriqueIdLists] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(0);

  useEffect(() => {
    const ActiveUserId = parseInt(localStorage.getItem("USER"));

    setUserList((oldArray) => [...oldArray, ActiveUserId]);

    RubriquesService.getNom().then((res) => {
      if (res && res.status) {
        setRubrique(res.data);
      }
    });
    const idGroup = splitLocation[3];

    if (idGroup) {
      RubriquesService.RubriqueById(idGroup).then((library) => {
        console.log(library);
        setForm({
          id: library.data.id,
          code_couleur: library.data.code_couleur,
          codification: library.data.codification,
          titre_rubrique: library.data.nom,
          desc_rubrique: library.data.description,
          rubrique_mere: library.data.rubrique_mere,
          visionnage: library.data.visionnage,
        });
        console.log("library", library);
      });
    }
  }, [location]);

  function handleCloseMessage() {
    setEtats({ ...etats, open: false });
  }

  function handleChangeColor(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    setColor(event.target.value);
    console.log("***", event.target.value);
  }
  return (
    <MainContainer {...headerDataLibrary}>
      <Result
        message={etats.message}
        color={etats.color}
        open={etats.open}
        handleClose={handleCloseMessage}
      />
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>

        <Spacer x={2} />

        <Button
          variant="contained"
          color="grey"
          onClick={(e) => handleClickAnnuler(e)}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>

      <Spacer y={4} />

      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Code couleur {bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              type="color"
              className={classes.colorInput}
              onChange={(e) => handleChangeColor(e)}
              value={form.code_couleur}
              name="code_couleur"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Codification {bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              //label="Nom"
              variant="outlined"
              onChange={handleChange}
              //inputProps={{ textTransform: 'capitalize', }}
              value={form.codification}
              name="codification"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Nom de la rubrique {bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              className={classes.text}
              fullWidth
              id="outlined-basic"
              size="small"
              //label="Nom"
              variant="outlined"
              onChange={handleChange}
              //inputProps={{ textTransform: 'capitalize', }}

              value={form.titre_rubrique}
              name="titre_rubrique"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Description {bullRed}
            </label>
          </Grid>

          <Grid item xs={8}>
            <TextField
              id="outlined-multiline-static"
              // label="Multiline"
              multiline
              rows={3}
              size="small"
              // label="Description..."
              className={classes.text}
              variant="outlined"
              onChange={handleChange}
              value={form.desc_rubrique}
              name="desc_rubrique"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Rubrique mère{bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Rubrique mère
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={form.rubrique_mere}
                name="rubrique_mere"
                label="rubrique_mere"
                onChange={handleChangeRubriqueMere}
              >
                <MenuItem value={0}>Aucune rubrique mère</MenuItem>

                {rubrique.map((row) => (
                  <MenuItem value={row.id_rubrique}>
                    {row.titre_rubrique}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>Visionnage{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Choisir une option
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={form.visionnage}
                name="visionnage"
                label=" Choisir une option"
                onChange={handleChange}
              >
                <MenuItem value={"public"}>Public</MenuItem>
                <MenuItem value={"privé"}>Privé</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <label className={classes.text}>
              ({bullRed}):Champs obligatoires{" "}
            </label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}
