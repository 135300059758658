import MainContainer from "../../components/MainContainer";
import { headerMyDataImmo } from "../../data/modules/my-materials-header";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect, useContext } from "react";
import UserService from '../../services/UserService';
import { Grid, Paper } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import InventoryMineTable from "./InventoryMineTable";
import InventoryService from "../../services/InventoryService";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  style1: {
    fontSize: "5em",
  },
}));
export default function InventoryMineList() {
  const [acceptButton, setAcceptButton] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [message, setMessage] = useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  const wscols = [
    { wch: 25 },
    { wch: 25 },
    { wch: 45 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 45 },
    { wch: 10 },
    { wch: 25 },
  ];

  const wscolsDetenteur = [
    { wch: 25 },
    { wch: 25 },
    { wch: 45 },
    { wch: 45 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
  ];

  const downloadPDF = () => {
    var user_id = localStorage.getItem("USER");
    InventoryService.generateSignedPDf(user_id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      const extension = 'pdf';
      const fileName = 'Fiche_de_detention';
      var user_id = localStorage.getItem("USER");
     
        
      const uniqueFileName = `${fileName}_${user_id}.${extension}`;
      a.download = uniqueFileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
      .catch((error) => {
        console.error('Erreur lors du téléchargement du PDF :', error);
      });
  };

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  useEffect(() => {
    var countList = InventoryService.getCountMineImmoHistory().then((result) => {
      if (result && result.status) {
        setAcceptButton(true);
    } else {
      setAcceptButton(false);
    }
  });
    
}, []);
  return (
    <>
      <MainContainer {...headerMyDataImmo}>
        <Grid container item xs={12}>
        { acceptButton
       && (
          <Button
            variant="contained"
            color="primary"
            onClick={downloadPDF}
            style={{ fontWeight: 600 }}
          >Fiche de détention
          </Button>
)}
        </Grid>

        <Spacer y={2} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper} variant="outlined">
              <InventoryMineTable />

            </Paper>
          </Grid>
        </Grid>
      </MainContainer>
    </>

  );
}
