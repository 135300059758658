import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Typography, Box } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataRH } from "../../data/modules/hr-header";
import FamilyMemberService from "../../services/FamilyMemberService";
import { AntTab, AntTabs } from '../../components/ant-tab';
import PropTypes from 'prop-types';
import PersonalSummary from './tabs/PersonalSummary';
import Joint from './tabs/Joint';
import Child from './tabs/Child';
import Contract from "./tabs/Contract";
import PaginationChild from "./tabs/PaginationChild";
import { useParams } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    width: 370,
  }
}));

function FamilyMemberAdd(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [countChild, setcountChild] = useState(1);
  const [defaultValueOfJoint, setDefaultValueOfJoint] = useState();
  const [sex_id, setSex_id] = useState(0);
  const [isMarried, setIsMarried] = useState(true);
  const [indexTab, setIndexTab] = useState(1);
  const [address, setAddress] = useState('');
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    FamilyMemberService.getSex_id(id).then((result) => {
      setSex_id(result.data);
    });

    FamilyMemberService.isMarried(id).then((result) => {
      setIsMarried(result.data);
    });
    FamilyMemberService.getAddress(id).then((result) => {
      setAddress(result.data);
    });
    FamilyMemberService.getJointByUserId(id).then(({ data: jointData }) => {
      if (jointData !== undefined) {
        setDefaultValueOfJoint({
          ...jointData, marital_status: "Marié(e)", user_id: props.user_id, relationship_type_id: 1, value_personal_config: []
        });
        // console.log("ADDRESS JOINT ========= ", jointData.address);
      }
    });

    if (isMarried) {
      setIndexTab(2);
    } else {
      setIndexTab(1);
    }
  }, [isMarried, indexTab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const items = [];
  for (var i = 0; i < countChild; i++) {
    items.push(
      <>
        <Child user_id={id} />
      </>
    );
  }

  return (
    <MainContainer {...headerDataRH}>
      <Spacer y={3} />
      <Paper className={classes.root}>
        <AntTabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" >
          <AntTab label="Résumé du Personnel" />
          {isMarried && <AntTab label="Conjoint" />}
          <AntTab label="Enfant(s)" />
          {/* <AntTab label="Contrat" /> */}
        </AntTabs>
        <TabPanel value={value} index={0}>
          <PersonalSummary user_id={id} />
        </TabPanel>
        {isMarried &&
          <TabPanel value={value} index={1}>
            <Joint user_id={id} sex_id={sex_id} defaultValueOfJoint={defaultValueOfJoint} address={address} />
          </TabPanel>
        }
        <TabPanel value={value} index={indexTab}>
          <PaginationChild user_id={id} address={address} />
        </TabPanel>
        <TabPanel value={value} index={indexTab + 1}>
          <Contract user_id={id} />
        </TabPanel>
      </Paper>
    </MainContainer>
  );
}

export default FamilyMemberAdd;
