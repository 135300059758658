import { Button, makeStyles } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState, useContext } from "react";
import DocumentService from "../../../services/DocumentService";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import Result from "../../personnel/Result";
import Message from "../../personnel/Message";
import { useNavigate } from "react-router";
import Title from "../../../components/Title";
import Spacer from "../../../components/Spacer";
import { hasPrivilege } from "../../../utils/privilege";
import { userDetailContext } from "../../../App";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  textField: {
    float: "rigth",
    width: "20%",
  },
  select: {
    width: "20%",
  },
}));

export default function EmplacementTable() {
  const classes = useStyles();

  const contextData = useContext(userDetailContext);

  const columns = [
    // {
    //   field: "id",
    //   headerName: "N°",
    //   width: 100,
    // },
    {
      field: "direction_name",
      headerName: "Direction",
      width: 300,
    },

    {
      field: "seat_name",
      headerName: "Bureau",
      width: 250,
    },

    {
      field: "building_name",
      headerName: "Bâtiment",
      width: 250,
    },

    {
      field: "storage_place",
      headerName: "Lieu de stockage",
      width: 250,
    },

    {
      field: "storage_1",
      headerName: "Rangement 1",
      width: 230,
    },

    {
      field: "storage_2",
      headerName: "Rangement 2",
      width: 230,
    },

    {
      field: "storage_3",
      headerName: "Rangement 3",
      width: 230,
    },

    {
      field: "action",
      headerName: "Action",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            {hasPrivilege(
              contextData.privileges,
              "Button_edit_document_location"
            ) && (
              <Button
                onClick={(e) => handleEdit(e, params.id)}
                title="Modifier"
              >
                <CreateTwoTone style={{ color: "black" }} />
              </Button>
            )}

            {hasPrivilege(
              contextData.privileges,
              "Button_delete_document_location"
            ) && (
              <Button onClick={handleDelete(params.id)} title="Supprimer">
                <DeleteOutlineRounded style={{ color: "red" }} />
              </Button>
            )}
          </div>
        );
      },
      width: 200,
    },
  ];

  const [rows, setRows] = useState([]);

  const [etats, setEtats] = useState({
    open: false,
    message: "",
    color: "",
  });

  const [deleteResult, setDeleteResult] = useState({
    open: false,
    message: "",
    accept: false,
  });

  const [idToDelete, setIdToDelete] = useState(0);

  const navigate = useNavigate();

  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);

  function handleEdit(event, id) {
    console.log("Editer la ligne : ", id);
    navigate("/emplacement/add/" + id);
  }

  const handleDelete = (id) => (ev) => {
    console.log("Supprimer la ligne : ", id);

    setDeleteResult({
      open: true,
      message: "Voulez vous vraiment supprimer  ?",
    });
    setIdToDelete(id);
  };

  function handleAcceptDeleting() {
    setDeleteResult({
      open: false,
      message: "",
      accept: true,
    });

  DocumentService.deleteLocation(idToDelete).then((response) => {
      if (response.status) {
        setEtats({
          ...etats,
          open: true,
          color: "green",
          message: response.message,
        });

        DocumentService.getLocation().then((results) => {
          if (results.status ) {
            setRows(results.data);
          } else {
            setRows([]);
          }
        });
      }
    });
  }

  function handleCloseMessage() {
    setDeleteResult({
      open: false,
      message: "",
    });
  }

  function handleResultCloseMessage() {
    setEtats({ ...etats, open: false });
  }

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    DocumentService.getLocation().then((response) => {
      if (response.status) {
        setRows(response.data);
       console.log(response.data);
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <Title>Liste des emplacements </Title>

      <Spacer y={2} />

      <div style={{ height: 500, width: "100%" }} className={classes.root}>
        <DataGrid
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[12]}
          pageSize={12}
        />
      </div>

      <Result
        message={etats.message}
        color={etats.color}
        open={etats.open}
        handleClose={handleResultCloseMessage}
      />

      <Message
        isActive={deleteResult.open}
        message={deleteResult.message}
        accept={deleteResult.accept}
        handleClose={handleCloseMessage}
        handleConfirm={handleAcceptDeleting}
      />

      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
    </React.Fragment>
  );
}
