import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const RubriquePrincipaleService = {
    async savePrincipale(nom, description, id) {
        try {
            const result = id ?
                await axios.put(`${apiUrl}/rubriquePrincipale/${id}`, {
                    id: id,
                    nom: nom,
                    description: description
                })
                :
                await axios.post(`${apiUrl}/rubriquePrincipale`, {
                    id: id,
                    nom: nom,
                    description: description,

                });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },


    async getNom() {
        try {
            const result = await axios.get(`${apiUrl}/rubriquePrincipale/nom`);
            if (result) {
                return result.data;
            }
        }
        catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
    ,


    async principaleById(id) {
        try {
            const result = await axios.get(`${apiUrl}/rubriquePrincipale/id/${id}`);
            if (result) {
                return result.data;
            }
        }
        catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
    ,
    async getPrincipale() {
        try {
            const result = await axios.get(`${apiUrl}/rubriquePrincipale/`);
            if (result) {
                return result.data;
            }
        }
        catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
    ,

    async deletePrincipale(id) {
        try {
            const result = await axios.delete(`${apiUrl}/rubriquePrincipale/${id}`);
            if (result) {
                return result.data;
            }
        }
        catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}

export default RubriquePrincipaleService;