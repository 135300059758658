import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const ACCESS_TOKEN = "ACCESS_TOKEN";
const USER = "USER";

const AuthService = {
  async login(email, password, isChecked) {
    try {
      const result = await axios.post(`${apiUrl}/authentication/login`, {
        email,
        password,
        isChecked
      });
      if (result) {
        // TODO: replace result.data.message by the token

        localStorage.clear();
        localStorage.setItem(ACCESS_TOKEN, result.data.token);
        localStorage.setItem(USER, result.data.user);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  isAuthenticated() {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let connected = false;
    if (token) {
      
      axios.defaults.headers.common['Authorization'] = token;
      connected = true;
      // return false;

    }
    // else{
    //   // TODO:
    //   axios.defaults.headers.common['Authorization'] = token;
    //   connected = true;
    //   return true;

    // }
    return connected;
  },

  logOut() {
    localStorage.clear();
    return localStorage.removeItem(ACCESS_TOKEN);
  },
  //TODO: Permission user by the token zah
  /*getPermissions: () => {
     const role = localStorage.getItem('permissions');
     return role ? Promise.resolve(role) : Promise.reject();
}*/
};

export default AuthService;
