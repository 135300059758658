import { Navigate, Outlet } from 'react-router-dom';
import AuthService from '../services/AuthService';
import ResetPassService from '../services/ResetPassService';

/* interface ProtectedRouteProps {
  path: String;
  exact: boolean;
  component: any;
  access: string[];
  redirectPath: any;
} */

const ProtectedRoute = (props) => {
  /* const path = props.path;
  const component = props.component;
  const access = props.access; */
  const { path, component, access, redirectPath, exact, children } = props;
  // TODO: check user has access or redirect to home 
  // const user = {}

  // if(!AuthService.isAuthenticated()) {
    return !AuthService.isAuthenticated() ? <Navigate to={redirectPath ?? '/'} replace/> : <Outlet/>;
  // }

  // TODO: check user access
  /* if(!access.includes(user.role)) {
    return <Redirect path={redirectPath ?? '/'} />
  } */

  // return (
  //   <Route {...props}>
  //     {children}
  //   </Route>
  // )
}

export default ProtectedRoute;