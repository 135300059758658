import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const BuildingService = {

   async getBuildingBySeat(id){
    try {
        const result = await axios.get(`${apiUrl}/building/getBuildingBySeat/${id}` );
        if (result) {
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    }
,

async getBySeat(id){
  try {
      const result = await axios.get(`${apiUrl}/building/seat/${id}` );
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
,
    async getAllBuilding(){
        try {
            const result = await axios.get(`${apiUrl}/building/`  );
            if (result) {
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        }
    ,

    async getAllDirection() {
      try {
        const result = await axios.get(`${apiUrl}/building/allDirection/`);
        if (result) {
          // TODO: replace result.data.message by the token
  
          return result.data;
        }
      }
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    },


    async getAsc(){
      try {
          const result = await axios.get(`${apiUrl}/building/asc`  );
          if (result) {
            return result.data;
          }
        } 
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      }
  ,

    async BuildingById(id) {
      try {
        const result = await axios.get(`${apiUrl}/building/id/${id}`);
        if (result) {
          // TODO: replace result.data.message by the token
  
          return result.data;
        }
      }
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    }
    ,

 


 async directionById(id){
   try {
       const result = await axios.get(`${apiUrl}/direction/id/${id}` );
       if (result) {
         // console.log("result ", result);
         return result.data;
       }
     } 
     catch (error) {
       if (error.response) {
         return error.response.data;
       }
     }
   }
,
async getDirection(){
 try {
     const result = await axios.get(`${apiUrl}/direction/`);
     if (result) {
       // TODO: replace result.data.message by the token

       return result.data;
     }
   } 
   catch (error) {
     if (error.response) {
       return error.response.data;
     }
   }
 }
,
async getWithDirection(){
 try {
     const result = await axios.get(`${apiUrl}/direction`  );
     if (result) {
       return result.data;
     }
   } 
   catch (error) {
     if (error.response) {
       return error.response.data;
     }
   }
 }
,

async deleteBuilding(id){
  try {
      const result = await axios.delete(`${apiUrl}/building/id/${id}`  );
      if (result) {
         return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

 async getBuilding(){
     try {
         const result = await axios.get(`${apiUrl}/Building/`  );
         if (result) {
 
           return result.data;
         }
       } 
       catch (error) {
         if (error.response) {
           return error.response.data;
         }
       }
     
   }
    ,
     
 
    async Direction(id){
      try {
          const result = await axios.get(`${apiUrl}/building/direction/${id}`);
          if (result) {
            return result.data;
          }
        } 
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      }
     ,
     async Service(){
      try {
          const result = await axios.get(`${apiUrl}/building/Service`);
          if (result) {
            return result.data;
          }
        } 
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      }
     ,
    async importDirection(){
     try {
         const result = await axios.post(`${apiUrl}/direction/import/`);
         if (result) {
 
           return result.data;
         }
       } 
       catch (error) {
         if (error.response) {
           return error.response.data;
         }
       }
     
   },


   async saveBuilding(building_name,seat_id, id) {
    try {
       const result = id ?
        await axios.put(`${apiUrl}/building/${id}`, {
         id:id,
         building_name:building_name,
         seat_id:seat_id,
      })
      :
        await axios.post(`${apiUrl}/building`, {
          id:id,
          building_name:building_name,
          seat_id:seat_id
      });
      if (result) {
         return result.data;
      }
     } catch (error) {
       if (error.response) {
         return error.response.data;
        }
      }
} ,
}
export default BuildingService;