import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../components/Title";
import { Grid, Paper, TextField } from "@material-ui/core";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import { TableContainer, TablePagination } from "@material-ui/core";
import { DataGrid } from '@material-ui/data-grid';
import { headerData } from '../../data/modules/my-validations-header';
import MainContainer from '../../components/MainContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import PlanningService from "../../services/PlanningService";
import TableRow from '@material-ui/core/TableRow';
import Link from "@material-ui/core/Link";
import ValidationPlanning from "./ValidationPlanning";
import RefusedPlanning from "../tools/RefusedPlanning";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Spacer from "../../components/Spacer";
import { Alert, AlertTitle } from '@mui/material';
import ValidationPlanningService from "../../services/ValidationPlanningService";
import Message from "../tools/Message";

import { CircularProgress } from "@material-ui/core";
const bullGreen = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: 'green', }}
  >

  </Box>

);
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', backgroundColor: 'red' }}
  >

  </Box>

);

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  root: {
    // borderTopWidth: 0.5,
    // borderColor: '0.5px solid grey',
    // borderStyle: 'solid',
    Width: "auto",
    textAlign: "center",
  },
  userExist: {
    backgroundColor: '#14ac14',
    color: 'white'
  },
  userNotExist: {
    backgroundColor: '#ff0000c9',
    color: 'white'
  },
  sroot: {
    // borderTopWidth: 1,
    // borderColor: 'black',
    // borderStyle: 'solid',
    Width: "auto",
    textAlign: "center",
    fontWeight: "bold",
    color: 'rgba(0,0,0,0.6)'
  },
  link: {
    borderColor: 'black',
    backgroundColor: 'rgb(19, 62, 103)',
    textAlign: "center",
  },
  user: {
    borderTopWidth: 0,
    borderColor: 'black',
    borderStyle: 'solid',
  },
  status: {
    textAlign: "center",
  },
}));

export default function PlanningTable(props) {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [all, setAll] = useState([]);
  const [liste, setListe] = useState([]);
  const [isCanValidate, setIsCanValidate] = useState(true);
  const [isLoadingMail, setIsLoadingMail] = useState(false);
  const [listMail, setListMail] = useState([]);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const classes = useStyles();
  const location = useLocation();
  const [message, setMessage] = React.useState({
    openMessage: false,
    color: 'green',
    messageInfo: '',
  });
  const { openMessage, color, messageInfo } = message;
  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    PlanningService.All().then((result) => {
      if (result && result.data) {
        setAll(result.data);
        // setListe(result.liste);
      }
    });
    setOpenLoadingSimpleBackdrop(false);
  }, [location]);


  useEffect(() => {
    var listMailTemp = [];
    if (all.length !== 0) {
      all.map((row) => {
        row.liste.map((ros) => {
          if (!ros.exist) {
            console.log("ros", ros);
            setIsCanValidate(false);
            listMailTemp.push(ros.email);
          }
        })
      })
    }

    setListMail(listMailTemp);
  }, [all]);

  console.log("listMail", listMail);
  const handleValidate = (id, type) => (ev) => {
    navigate(`/planning/table/validation/` + id + `/` + type);
  }

  function handleRemind(){
    setIsLoadingMail(true);
    ValidationPlanningService.remindToSendPlanning(listMail).then((result) => {
      if (result && result.status) {
        setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.message });
        setIsLoadingMail(false);
      }else{
        
        setMessage({ ...message, openMessage: true, color: 'red', messageInfo: "Une erreur c'est produite, veuillez réessayer" });
        setIsLoadingMail(false);
      }
    });
  }

  function handleCloseMessage() {
    setMessage({ ...message, openMessage: false });
  }
  return (
    <MainContainer {...headerData}>
      <React.Fragment>
        <Message open={openMessage} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
        
        <Box sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography sx={{ fontSize: 22 }} color="text.secondary" gutterBottom>
              Légende des couleurs
            </Typography>
            <Typography variant="h5" component="div">

            </Typography>
            <Typography sx={{ mb: 1.5, fontSize: 17 }}  >
              {bullGreen}:L'Utilisateur a déjà envoyé une planification
            </Typography>
            <Typography sx={{ mb: 1.5, fontSize: 17 }}  >
              {bullRed}:L'Utilisateur n'a pas encore envoyé sa planification ou a déjà envoyé mais encore en attente de validation de son supérieur direct
            </Typography>
          </CardContent>
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            Vous ne pouvez passer à la validation que si vos subordonnés ont tous envoyé leur planning
          </Alert>
          <Spacer y={2} />
        </Box>
        <TableContainer className={classes.root}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.sroot} >Action</TableCell>
                <TableCell className={classes.sroot} >Session de validation</TableCell>
                <TableCell className={classes.sroot} >Type Module</TableCell>
                <TableCell className={classes.sroot} >Utilisateur concerné</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {all.map((row) => (
                <Fragment>
                  <TableRow >
                    <TableCell className={classes.root} rowSpan={row.liste.length + 3}>
                      {isCanValidate && <Button variant="contained" color="primary" onClick={handleValidate(row.idparam, row.type)}>Voir détail</Button>}
                      {/* <Spacer x={1} /> */}
                      {!isCanValidate &&
                        <Button variant="contained" color="primary" onClick={handleRemind}>
                          {isLoadingMail? <CircularProgress /> : "Rappel d'envoi planning"}
                        </Button>
                      }

                    </TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.root} rowSpan={row.liste.length + 2} >{row.session}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.root} rowSpan={row.liste.length + 1} >{row.type}</TableCell>
                  </TableRow >
                  {row.liste.map((ros) => (
                    <>
                      {ros.exist &&
                        <TableRow >
                          <TableCell className={classes.userExist}>{ros.first_name}      {ros.last_name}</TableCell>
                        </TableRow >
                      }
                      {!ros.exist &&
                        <TableRow >
                          <TableCell className={classes.userNotExist}>{ros.first_name}      {ros.last_name}</TableCell>
                        </TableRow >
                      }
                    </>
                  ))}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    </MainContainer>
  );
}
