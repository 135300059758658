import React, { useEffect,useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, CloudUpload, ErrorOutline } from '@mui/icons-material';
import HistoryMettingService from "../../services/HistoryMettingService";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: 20,
    borderRadius: 10,
    width: 800,
    maxWidth: 'none', 
  },
  uploadSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: '2px dashed #d3d3d3',
    padding: 20,
    marginBottom: 20,
  },
  uploadText: {
    margin: '10px 0',
  },
  uploadedFiles: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  fileItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    border: '1px solid #d3d3d3',
    borderRadius: 5,
  },
  fileProgress: {
    width: '100%',
    margin: '0 10px',
  },
  errorText: {
    color: 'red',
  },
}));

const PvPopup = ({ open, handleClose, id, setMessage }) => {
    const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const navigate = useNavigate();

  const handleFileSelect = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const removeFile = (fileName) => {
    setSelectedFiles(selectedFiles.filter(file => file.name !== fileName));
  };

  const handleClick = async () => {
    try {

      const formData = new FormData();

      if (Array.isArray(selectedFiles)) {
        selectedFiles.forEach((file, index) => {
          formData.append(`file${index}`, file);
          // console.log('File added to formData:', formData);
        });
      } else if (selectedFiles instanceof File) {
        formData.append("file", selectedFiles);
      } else {
        return;
      }

        // formData.append(
        //   "file",
        // selectedFiles,
        // selectedFiles.name
        // );

        const results = await Promise.all(selectedFiles.map(file =>
          HistoryMettingService.saveMettingPv(file.name, id)
        ));


        HistoryMettingService.importPv(formData).then((result) => {
            if (result.status) {
              setMessage({ openMessage: true, color: 'green', messageInfo: result.message });
            } else {
              setMessage({ openMessage: true, color: 'red', messageInfo: result.message });
            }
          })
            .catch((err) => {
              setMessage({ openMessage: true, color: 'red', messageInfo: 'erreur' });
            });
      
      let allSuccess = true;
      results.forEach(res => {
        const responseErrorMessage = res.message;
        if (res.status) {
          setMessage({ openMessage: true, color: 'green', messageInfo: "Succès" });
        } else {
          allSuccess = false;
          setMessage({ openMessage: true, color: 'red', messageInfo: responseErrorMessage });
        }
      });

      if (allSuccess) {
        setSelectedFiles([]);
        handleClose();
        navigate("/Logistic/meeting_management/finish");
      }
    } catch (err) {
      setMessage({ openMessage: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
    }
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      console.log(selectedFiles);
    }
  }, [selectedFiles]);

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      <DialogContent>
        <div>
          <div className={classes.uploadSection}>
            <CloudUpload fontSize="large" />
            <Typography variant="body1" className={classes.uploadText}>Parcourir des fichiers</Typography>
            <Button variant="contained" component="label">
              Parcourir
              <input type="file" multiple hidden onChange={handleFileSelect} />
            </Button>
          </div>
          <div className={classes.uploadedFiles}>
            {selectedFiles.map((file, index) => (
              <div className={classes.fileItem} key={index}>
                <Typography variant="body2">{file.name}</Typography>
                <IconButton size="small" onClick={() => removeFile(file.name)}>
                  <Delete />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Annuler</Button>
        <Button onClick={handleClick} color="primary">Enregistrer</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PvPopup;
