import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../../components/Title";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import DirectionService from "../../../services/DirectionService";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import Message from "../../personnel/Message";
import Result from "../../personnel/Result";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';
import RubriqueSecondaireService from "../../../services/RubriqueSecondaireService";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  textField: {
    float: 'rigth',
    width: '20%',
  },
  select: {
    width: '20%',
  },
}));

export default function RubriqueSecondaireTable(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [etat, setEtat] = useState({ open: false, message: "message", accept: false });
  const [etats, setEtats] = useState({ open: false, message: "message", color: "" });
  const [isid, setIsid] = useState(0);

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);


  const handleDelete = (id) => (ev) => {
    setEtat({
      open: true,
      message: "Voulez vous vraiment supprimer  ?"
    });
    setIsid(id);
  };
  function handleMessage() {
    setEtat({
      open: false,
      message: ""
    });
  }

  function handleAccept() {
    setEtat({
      open: false,
      message: "",
      accept: true
    });
    RubriqueSecondaireService.deleteSecondaire(isid).then((results) => {
      RubriqueSecondaireService.getSecondaire().then((result) => {

        if (result && result.data) {
          setRows(result.data.map((row) => ({ ...row, id: row.id })));
        }
      });
      if (results.status) {
        setEtats({ ...etats, open: true, color: 'green', message: results.message });
        navigate("/secondaire");
      }
      else {
        setEtats({ ...etats, open: true, color: 'red', message: results.message });
      }
    });
  }
  const handleCloseMessage = () => {
    setEtats({ ...etats, open: false });
  };
  const handleEdit = (id) => (ev) => {
    navigate("/secondaire/add/" + id);
  };

  const columns = [
    {
      field: 'number',
      headerName: 'N°',
      width: 150,
    }, 
    {
      field: 'nom',
      headerName: 'Nom rubrique',
      width: 250,
    },
    {
      field: 'description',
      headerName: 'Decription',
      width: 350,
    },
    {
      field: 'nom_rubrique_principale',
      headerName: 'Rubrique principale',
      width: 250,
    },
    {
      field: 'action',
      headerName: 'Action',
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Button onClick={handleEdit(params.id)} title="Modifier"><CreateTwoTone style={{ color: "black" }} />
            </Button>
            <Button onClick={handleDelete(params.id)} title="Supprimer">
              <DeleteOutlineRounded style={{ color: "red" }} />
            </Button>
          </div>
        );
      },
      width: 200,
    },
  ];

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    RubriqueSecondaireService.getSecondaire().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.id })));
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <React.Fragment>
      <Result message={etats.message} color={etats.color} open={etats.open} handleClose={handleCloseMessage} />
      <Message isActive={etat.open} message={etat.message} accept={etat.accept} handleClose={handleMessage} handleConfirm={handleAccept} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Listes des rubriques secondaires</Title>
      <div style={{ height: 400, width: '100%' }} className={classes.root}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </div>
    </React.Fragment>
  );
}
