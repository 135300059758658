import React from "react";
import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import ImmoList from "./ImmoList";
import ExtratList from "./ExtratList";
import { AntTab, AntTabs } from '../../components/ant-tab';
import AppBar from "@material-ui/core/AppBar";
import ImmobilisationService from "../../services/ImmobilisationService";
import ImportExcel from "./ImportExcel";
import GenerateQrCode from "./GenerateQrCode";
import TextField from "@material-ui/core/TextField";
import * as FileSaver from "file-saver";
import { useNavigate } from 'react-router-dom';

import {
    FormControl,
    Select,
    MenuItem,
    InputLabel,
  } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        elevation: 0
    },
    tab1: {
        width: "50%"
    },
}));

export default function ImmobilisationGenerateQrCode() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [openGenerateQrCode, setopenGenerateQrCode] = React.useState(false);
    const [closeGenerateQrCode, setcloseGenerateQrCode] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [valueType, setValueType] = React.useState(0);
    const classes = useStyles();

    const [direction_id, setIdDir] = React.useState(0);
    const [financement_id, setIdFinancement] = React.useState(0);
    const [showMessage, setShowMessage] = React.useState(false);
    
    const [funding, setFunding] = useState([]);
    const [directions, setDirection] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const [form, setForm] = useState({
        name: "",
        code: "",
        date1: "",
        date2:"",
        funding: "",
        dir: "",
    });
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        SetFilter('name',form.name);
        SetFilter('date1',form.date1);
        SetFilter('date2',form.date2);
        SetFilter('funding',form.funding);
        SetFilter('code',form.code);
        SetFilter('dir',form.dir);

        window.location.reload();
    };

    const Refresh = (e) => {
        e.preventDefault();
        localStorage.removeItem('name');
        localStorage.removeItem('code');
        localStorage.removeItem('date1');
        localStorage.removeItem('date2');
        localStorage.removeItem('funding');
        localStorage.removeItem('dir');
        localStorage.setItem('name',"");
        localStorage.setItem('code',"");
        localStorage.setItem('date1',"");
        localStorage.setItem('date2',"");
        localStorage.setItem('funding',"");
        localStorage.setItem('dir',"");
        
        window.location.reload();
    };

    
    const SetFilter = (name,value)=>{
        if(!value){
          if(localStorage.getItem(name)){
            localStorage.setItem(name,localStorage.getItem(name));
          }
        }
        else{
  
          localStorage.setItem(name,value);
        }

    }
    
    const setValeurChamps = (name)=>{
        const valeurLocalStorage = localStorage.getItem(name);
        console.log("valeur champs sont : "+valeurLocalStorage)
        return valeurLocalStorage;
    }

    const handleChanges = (e) => {
        const { name, value } = e.target;
        if (localStorage.getItem(name)) {
          
        }
        setForm({ ...form, [name]: value });
    };

    useEffect(() => {
        ImmobilisationService.direction().then((result) => {
          if (result && result.data) {
            setDirection(result.data);
          }
        });
        ImmobilisationService.funding().then((result) => {
          if (result && result.data) {
            setFunding(result.data);
          }
        });
    }, []);

    function handleClick() {
        navigate("/Logistic/immobilisations/add");
    }

    function handleCloseDialog() {
        setIsOpen(false);
    }

    function handleClickImport() {
        setIsOpen(true);
    }

    function handleChangeRadioButtonForTypeImmo(e) {
        setValueType(e);
    }

    function handleClickGenerateQrCode() {
        setopenGenerateQrCode(true);
    }

    function cancelGenerateQrCode() {
        setopenGenerateQrCode(false);
    }

    function confirmGenerateQrCode() {
        setIsLoading(true);
        ImmobilisationService.generateQrCode(valueType, direction_id, financement_id).then((result) => {
            if (result && result.status != 206) {
                var data = result.data;
                var type = result.type;
                console.log(result);
                ImmobilisationService.readFile(data.pdfGenerated, valueType, type).then((res) => {
                    if (res) {
                        if (valueType == 1) { //valueType==1 pour l'extrat
                            if(type == 0){console.log("extrat pdf");
                                const blob = new Blob([res.data], {
                                    type: 'application/pdf',
                                });
                                const fileURL = URL.createObjectURL(blob);
                                const w = window.open(fileURL, '_blank');
                                w && w.focus();
                                URL.revokeObjectURL(fileURL);
                                setopenGenerateQrCode(false);
                                setIsLoading(false);
                            }else{console.log("extrat zip");
                                const blob = new Blob([res.data], {
                                    type: 'application/zip',
                                });
    
                                FileSaver.saveAs(blob, "immo_qrCode.zip");
                                setopenGenerateQrCode(false);
                                setIsLoading(false);
                            }

                        } else if (valueType == 0) { //valueType==0 pour l'immo et 1 pour l'extrat
                            if(type == 0){console.log("immo pdf");
                                const blob = new Blob([res.data], {
                                    type: 'application/pdf',
                                });
                                const fileURL = URL.createObjectURL(blob);
                                const w = window.open(fileURL, '_blank');
                                w && w.focus();
                                URL.revokeObjectURL(fileURL);
                                setopenGenerateQrCode(false);
                                setIsLoading(false);
                            }else{console.log("immo zip");
                                const blob = new Blob([res.data], {
                                    type: 'application/zip',
                                });
    
                                FileSaver.saveAs(blob, "immo_qrCode.zip");
                                setopenGenerateQrCode(false);
                                setIsLoading(false);
                            }
                        }
                    }
                });

            } else {
                setShowMessage(true);
                setIsLoading(false);
                // setopenGenerateQrCode(false);

            }
        });
    }

    function handleChangeRadioButtonForTypeImmo(e) {
        setValueType(e.target.value);
    }

    function handleChangeDirection(e) {
        const value = e.target.value;
        setIdDir(value);
        setShowMessage(false);
    }

    function handleChangeFinancement(e) {
        const value = e.target.value;
        setIdFinancement(value);
        setShowMessage(false);
    }
    return (
        <MainContainer {...headerDataImmo}>
            <Grid container item xs={12}>
                <Button
                    variant="contained"
                    onClick={handleClickGenerateQrCode}
                    style={{ fontWeight: 600 }}
                >
                    GENERER QRCODE
                </Button>
    
                <Spacer x={2} />
                
                <Button
                    variant="contained"
                    onClick={Refresh}
                    style={{ fontWeight: 600, backgroundColor:'darkgreen', color:'white' }}
                    >
                    réinitialiser
                    </Button>
                    <Spacer x={2} />
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ fontWeight: 600}}
                >
                    Filtrer
                </Button>
            </Grid>
            <Spacer y={4} /> 
            <Grid container xs={12} spacing={2} >       
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.labelPosition}>Financement:</label>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={setValeurChamps('funding')}
                            name="funding"
                            style={{ width: '300px'}}
                            onChange={handleChanges}
                        >
                            
                            {funding.map((row) => (
                            <MenuItem value={row.funding_id}>{row.code}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} >
                    <label className={classes.labelPosition}>Direction:</label>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={setValeurChamps('dir')}
                            label="dir"
                            name="dir"
                            style={{ width: '300px'}}
                            onChange={handleChanges}
                        >
                            {directions.map((row) => (
                            <MenuItem value={row.direction_id}>
                                {row.name}({row.code})
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} ></Grid>
                <Grid item xs={12} sm={6} md={4} ></Grid>
                <Grid item xs={12} sm={6} md={4} ></Grid>
            </Grid>
            <Spacer y={2} />
            <Grid container xs={12}>
                <AppBar position="static" color="default">
                    <AntTabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                        <AntTab disableRipple label="Immo" className={classes.tab1} />
                        <AntTab label="Extrat" className={classes.tab1} />
                    </AntTabs>
                </AppBar>
            </Grid>
            <Spacer y={2} />
            {value === 0 && <Grid item xl={12}>
                <Paper className={classes.paper} variant="outlined">
                    <ImmoList isGenerateQrCode={true} />
                </Paper>
            </Grid>}
            {value === 1 && <Grid item xl={12}>
                <Paper className={classes.paper} variant="outlined">
                    <ExtratList isGenerateQrCode={true} />
                </Paper>
            </Grid>}
            <Spacer y={2} />

            <ImportExcel open={isOpen} closeDialog={handleCloseDialog} />
            <GenerateQrCode openDialog={openGenerateQrCode} closeDialog={closeGenerateQrCode} handleChange={handleChangeRadioButtonForTypeImmo}
                value={valueType} cancelDialog={cancelGenerateQrCode} confirmDialog={confirmGenerateQrCode} isLoading={isLoading} isShowMessage={showMessage}
                handleChangeDirection={handleChangeDirection} handleChangeFinancement={handleChangeFinancement} direction_id={direction_id} financement_id={financement_id} />
        </MainContainer>
    );
}
