import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const LeavePerWeekService = {
  
 

  async getLeaveType() {
    try {
      const result = await axios.get(`${apiUrl}/LeaveType/`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

 
  async getLeavesByOptions(options) {
    try {
      const result = await axios.get(`${apiUrl}/LeavePerWeek`, {params: options});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getLeaveById(id) {
    try {
      const result = await axios.get(`${apiUrl}/leave/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
 
  
 
  async getLeavesStaffPerCurrentWeek() {
    try {
      const result = await axios.get(`${apiUrl}/LeavePerWeek/getLeavesStaffPerCurrentWeek`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
};

export default LeavePerWeekService;
