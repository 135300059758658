import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const apiMis = process.env.REACT_APP_API_MIS;
const PlanningMissionService = {
  async getPlanning() {
    try {
      const result = await axios.get(`${apiUrl}/planningMission`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getActivity() {
    try {
      const result = await axios.get(`${apiUrl}/planningMission/activity`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getCategory(category_mission_id) {
    try {
      const result = category_mission_id
        ? await axios.get(
            `${apiUrl}/planningMission/category/${category_mission_id}`
          )
        : await axios.get(`${apiUrl}/planningMission/category`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getDirectionInMis() {
    try {
      const result = await axios.get(
        `http://192.168.1.18:8080/misrepo_interne/webservice/apisite_ws/directions`,
        {
          headers: {
            "Sec-Fetch-Mode": "cors",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            Accept: "application/json,text/plain,*/*",

            "Content-Type": "application/json",
            Authorization:
              "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjQzMiIsImVtYWlsIjoic2FtdWVsYS5yYW1hcm92YXZ5QGZpZC5tZyIsInRpbWVzdGFtcCI6MTY1ODkyMDUwOH0.GeKezyIzKTIT3npQcaK4cXEOWPfbMI6fSZI7aK4eiec",
          },
        }
      );
      console.log(result);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getDirection() {
    try {
      const result = await axios.get(`${apiUrl}/planningMission/direction`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getService(id) {
    try {
      const result = await axios.get(`${apiUrl}/planningMission/service/` + id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getIntervenant(direction_id, service_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/intervenant/${direction_id.direction_id}/${service_id.service_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async saveMissionPlanning(formData, mission_planning_id) {
    try {
      // const result = mission_planning_id?await axios.put(`${apiUrl}/planningMission/id/${mission_planning_id}`, formData
      // ):await axios.post(`${apiUrl}/planningMission`, formData);
      const result = await axios.post(`${apiUrl}/planningMission`, formData);
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  
  async saveMissionPlanningAgenda(formData, mission_planning_id, modif) {
    try {
      const result = modif
        ? await axios.put(
            `${apiUrl}/planningMission/agenda/${mission_planning_id}`,
            formData
          )
        : await axios.post(
            `${apiUrl}/planningMission/agenda/${mission_planning_id}`,
            formData
          );
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getMissionPlanningDays(mission_planning_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/days/${mission_planning_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getMissionary(mission_planning_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/missionary/${mission_planning_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async deleteMissionPlanning(mission_planning_id) {
    try {
      const result = await axios.delete(
        `${apiUrl}/planningMission/id/${mission_planning_id}`
      );
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getPlanningMissionById(mission_planning_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/id/${mission_planning_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async updatePlanningMissionById(mission_planning_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/update/${mission_planning_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getMissionPlanningId(mission_planning_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/post/${mission_planning_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async getUserMission(user_id, mission_planning_id) {
    try {
      const result = mission_planning_id
        ? await axios.get(
            `${apiUrl}/planningMission/mission/${user_id}/${mission_planning_id}`
          )
        : await axios.get(`${apiUrl}/planningMission/mission/${user_id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getAgendaMissionPlanning(mission_planning_id) {
    try {
      const result = await axios.get(
        `${apiUrl}/planningMission/agenda/${mission_planning_id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};

export default PlanningMissionService;
