import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../components/Title";
import { People } from "@material-ui/icons";
import FamilyMemberService from "../../services/FamilyMemberService";
import { DataGrid } from '@material-ui/data-grid';
import Spacer from "../../components/Spacer";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    '& .personal-style': {
      backgroundColor: '#E8E8E8',
    },
  },
}));

export default function FamilyMemberTable() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    FamilyMemberService.getPersonalFamily().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.user_id })));
        setOpenLoadingSimpleBackdrop(false);
      }else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);

  const handleEdit = (id) => (ev) => {
    navigate("/information_famille/add/" + id);
  };

  const columns = [
    { field: 'user_name', headerName: 'Nom et Prénom(s) du Personnel', width: 300, cellClassName: 'personal-style' },
    { field: 'joint', headerName: 'Nom et Prénom(s) Conjoint', width: 300, },
    { field: 'child', headerName: 'Nom et Prénom(s) des enfants', width: 300, },
    {
      field: 'action',
      headerName: 'Action',
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={handleEdit(params.id)} ><People style={{ color: "black" }} /></Button>
          </>
        );
      },
      width: 140,
    },
  ];

  return (
    <React.Fragment>
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Détails Personnels</Title>
      <Spacer y={2} />
      <div style={{ height: "70vh", width: '100%' }} className={classes.root}>
        <DataGrid
          rows={rows} columns={columns} disableSelectionOnClick
        />
      </div>
    </React.Fragment>
  );
}
