import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Chip,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataAdmin } from "../../data/modules/admin-header";
import { useParams } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Alert from "@material-ui/lab/Alert";
import PersonnelService from "../../services/PersonnelService";
import { useNavigate } from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { userDetailContext } from "../../App";
import { useContext } from "react";
import Message from "../tools/Message";
import UserService from "../../services/UserService";
import "react-phone-input-2/lib/style.css";
import { hasPrivilege } from "../../utils/privilege";
import Phone from "../tools/Phone";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Avatar from '@material-ui/core/Avatar';
import Box from '@mui/material/Box';

import DirectionService from "../../services/DirectionService";
import PlanningService from "../../services/PlanningService";
import { useLocation } from 'react-router-dom';
import FonctionService from "../../services/FonctionService";
import Autocomplete from "@material-ui/lab/Autocomplete";

const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: "100vh",
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  datePicker: {
    margin: 0,
  },
  input: {
    textTransform: "uppercase",
    marginTop: "10px",
    width: "100%",
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
  },
  type: {
    display: 'none',
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  small: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));


function UserAdd(props) {
  const classes = useStyles();
  const location = useLocation();

  const [personName, setPersonName] = React.useState([]);
  const [number, setNumber] = useState(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' ', '']);
  const [compt, setCompt] = useState(false);
  const [isPhoneNumberEmpty, setIsPhoneNumberEmpty] = useState(false);
  const [isPhoneNumberLengthNotValid, setPhoneNumberLengthNotValid] = useState(false);

  const [message, setMessage] = useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;


  const [dir, setDir] = useState([]);
  const [service, setService] = useState([]);
  const [idservice, setIdservice] = useState(0);
  const [iddir, setIddir] = React.useState(0);
  const [isUserUndefined, setisUserUndefined] = React.useState(false);
  const [idUserSup, setIdUserSup] = React.useState(0);
  const [matricule, setMatricule] = useState("");

  const [idUserUpdated, setIdUserUpdated] = useState(0);
  // const [isEditing, setisEditing] = useState(false);


  const [dirSupHierarchique, setDirSupHierarchique] = useState([]);
  const [serviceSupHierarchique, setServiceSupHierarchique] = useState([]);
  const [idserviceSupHierarchique, setIdserviceSupHierarchique] = useState(0);
  const [iddirSupHierarchique, setIddirSupHierarchique] = React.useState(0);
  const [users, setUsers] = useState([]);
  const [functionList, setFunctionList] = useState([]);

  const [functionId, setFunctionId] = useState(0);
  

  function CapitalString(str) {
    var nextCapital = true;
    var newString = "";
    for (var i = 0; i < str.length; i++) {
      var cur = str.charAt(i);
      if (nextCapital) {
        newString += cur.toUpperCase();
      } else {
        newString += cur.toLowerCase();
      }
      if (cur === " ") {
        nextCapital = true;
      } else {
        nextCapital = false;
      }
    }
    return newString;
  };

  function handleChange1(ev) {
    const { name, value } = ev.target;
    if (name == 'first_name') {
      setForm({ ...form, [name]: CapitalString(value) });
      setPersonName(ev.target.value);
    }
    if (name == 'matricule') {
      setMatricule(ev.target.value);
    }
    if (name == 'telephone') {

      var i = 0;
      var a = 0;
      for (let index = 0; index < value.length; index++) {
        for (let indexs = 0; indexs < number.length; indexs++) {
          if (number[indexs] == value[index]) {
            i++;
          }
        }
      }
      if (i != value.length) {
        setCompt(true);
      }
      else {
        setCompt(false);
        setForm({ ...form, [name]: value });
      }
    }
    else {
      setForm({ ...form, [name]: value });
      setPersonName(ev.target.value);
    }
  }

  const [opens, setOpens] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const navigate = useNavigate();



  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    telephone: "",
    sex: [],
    email: "",
    password: "",
    group: [],
    name_image: "",
    url_name_image: ""
  });

  /*
   * Variable for phone and email validator and last name empty checking
   */
  const [isValidPhone, setIsvalidForm] = useState(false);
  const [isValidEmail, setIsvalidEmail] = useState(false);
  const [isEmptyLastName, setEmptyLastName] = useState(false);
  // const regexForEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
  const regexForEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[A-Za-z]+$/;
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [state, setState] = useState({
    file: null,
    picture: null,
  });

  const formaterNumeroTelephone = (numero) => {
    return numero.replace(/(\d{3})(\d{2})(\d{3})(\d{2})/, '$1 $2 $3 $4');
  }


  function handleClick() {
    setOpenLoadingSimpleBackdrop(true);
    if (form.telephone === "" || form.telephone === null) {
      setIsPhoneNumberEmpty(true);
    } else if (form.telephone.length !== 13  && form.telephone.length !== 10 ) {
        setPhoneNumberLengthNotValid(true);
    } else {

      if (form.last_name && !isValidEmail && !isValidPhone) {
        UserService.saveUser(
          form.first_name,
          form.last_name,
          form.telephone,
          form.sex,
          form.email,
          form.password,
          form.group,
          form.name_image,
          idservice,
          idUserSup,
          matricule,
          functionId.function_id,
          form.id
        )
          .then((res) => {
            var responseErrorMessage = res.message;
            if (res.status) {
              setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
              navigate("/users");
              setOpenLoadingSimpleBackdrop(false);
            } else {
              setMessage({ ...message, open: true, color: 'red', messageInfo: responseErrorMessage });
              setOpenLoadingSimpleBackdrop(false);
            }
          })
          .catch((err) => {
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
          });
      }
    }

  }
  function handleBlurEmail(e) {
    if (!regexForEmail.test(e.target.value)) {
      setIsvalidEmail(true);
    }
  }

  function handleFocusEmail() {
    setIsvalidEmail(false);

  }
  function handlePopoverOpen() {
  }
  function handleFocusLastName() {
    setEmptyLastName(false);
  }

  const handleChangeUpload = (event) => {
    setState({
      file: URL.createObjectURL(event.target.files[0]),
      picture: event.target.files[0],
    })
    const lien = event.target.files[0];
    setOpens(false);
    setFile(event.target.files[0]);
    const formData = new FormData();
    formData.append(
      "photos",
      lien,
      lien.name
    );
    setForm({ ...form, name_image: lien.name });
    PersonnelService.importService(formData).then((result) => {
    })
  }
  const handleClose = () => {
    setOpens(false);
  };
  const handleUpload = () => {
    setOpens(true);
  };

  const [tests, setTests] = useState([]);

  const [sexe, setSexe] = useState([]);
  const [errorMessageUserEmpty, setErrorMessageUserEmpty] = React.useState("");



  const params = useParams();
  const idUser = params.id;

  function handleSelectGroups() {

    // setOpenLoadingSimpleBackdrop(true);

    UserService.getAllGroup().then((result) => {
      if (result && result.data) {
        setTests(
          result.data.map((d) => {
            return { group_id: d.group_id, name: d.name, desc: d.desc };
          })
        );
      }
    });
  }

  //get group
  useEffect(() => {

    DirectionService.getDirection().then((res) => {
      if (res && res.status) {
        setDir(res.data);
        setDirSupHierarchique(res.data);
      }
    });

    UserService.getSex().then((sex) => {
      if (sex && sex.status) {
        setSexe(
          sex.data.map((s) => {
            return { sex_id: s.sex_id, name: s.name };
          })
        );
      }
    });

    if (idUser) {
      setIdUserUpdated(idUser);

    }


    //get all function
    FonctionService.getfonction().then((results) => {
      if (results && results.data) {
        setFunctionList(results.data);
        // setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, [props]);

  useEffect(() => {
    DirectionService.getDirection().then((res) => {
      if (res && res.status) {
        setDir(res.data);
      }
    });

    UserService.getSex().then((sex) => {
      if (sex && sex.status) {
        setSexe(
          sex.data.map((s) => {
            return { sex_id: s.sex_id, name: s.name };
          })
        );
      }
    });
    UserService.getAllGroup().then((result) => {
      if (result && result.data) {
        setTests(
          result.data.map((d) => {
            return { group_id: d.group_id, name: d.name, desc: d.desc };
          })
        );
      }
    });

    UserService.getUserId(idUserUpdated).then((info) => {
      setForm({
        id: idUserUpdated,
        first_name: info.data.person.first_name,
        last_name: info.data.person.last_name,
        telephone: info.data.phone_number,
        email: info.data.email,
        sex: info.data.person.sex_id,
        password: info.data.password,
        group: info.data.group.map((pv) => pv.group_id),
        name_image: info.data.name_image,
        url_name_image: info.data._url_name_image
      });

    });

    UserService.getUserSummaryByUserId(idUserUpdated).then((res) => {
      if (res && res.status) {
        const dir = res.data.direction_id;
        const service = res.data.service_id;
        const matricule = res.data.registration_number;
        setIddir(dir);
        setMatricule(matricule);
        setFunctionId({
          "name":res.data.function_name,
          "funcion_id":res.data.function_id
        });
        PlanningService.Direction(dir).then((results) => {
          if (results && results.data) {
            setService(results.data);
            setIdservice(service);
          }
        });
      }
    });

    UserService.getUserSuperiorByUserId(idUserUpdated).then((res) => {
      if (res && res.status) {
        setIdUserSup(res.data.superior_id);
        UserService.getUserSummaryByUserId(res.data.superior_id).then((res) => {
          if (res && res.status) {

            // setIdUserSup({
            //   "name": "",
            //   "user_id": res.data.user_id,
            //   "email": res.data.email
            // });
            const dir = res.data.direction_id;
            const service = res.data.service_id;
            setIddirSupHierarchique(dir);
            PlanningService.Direction(dir).then((results) => {
              if (results && results.data) {
                setServiceSupHierarchique(results.data);
                setIdserviceSupHierarchique(service);
                UserService.getAllUserByService(idUserUpdated ? true : false, service, idUserUpdated ? idUserUpdated : 0).then((results) => {
                  if (results && results.status) {
                    setUsers(results.data);
                  } else {
                    setErrorMessageUserEmpty(results.message);
                  }
                });
              }
            });
          }
        });
      }
    });
  }, [idUserUpdated])

  const getItemLabel1 = (id) => {
    for (const row of tests) {
      if (row.group_id === id) {
        return row.name;
      }
    }
    return "";
  };
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  function handleFocusPhoneNumber() {
    setIsPhoneNumberEmpty(false);
    setPhoneNumberLengthNotValid(false);
  }
  const contextData = useContext(userDetailContext);
  function handleCancel() {
    navigate("/users");
  }

  function handleChangeDirection(event) {
    const { name, value } = event.target;
    setIddir(value);
    setForm({ ...form, [name]: value });
    PlanningService.Direction(value).then((results) => {
      if (results && results.data) {
        setService(results.data);
      }
    })
  }
  function handleChangeService(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
    setIdservice(value);

    // UserService.getAllUserByService(idUser ? true : false, value, idUser ? idUser : 0).then((results) => {
    //   if (results && results.status) {
    //     console.log("users***", results.data);
    //     setUsers(results.data);
    //   } else {
    //     setErrorMessageUserEmpty(results.message);
    //   }
    // });
  }



  function handleChangeDirectionSupHierarchique(event) {
    const { name, value } = event.target;
    setIddirSupHierarchique(value);
    setUsers([]);
    // setForm({ ...form, [name]: value });
    PlanningService.Direction(value).then((results) => {
      if (results && results.data) {
        setServiceSupHierarchique(results.data);
      }
    })
  }
  function handleChangeServiceSupHierarchique(ev) {
    const { name, value } = ev.target;
    // setForm({ ...form, [name]: value });
    setIdserviceSupHierarchique(value);

    UserService.getAllUserByService(idUser ? true : false, value, idUser ? idUser : 0).then((results) => {
      if (results && results.status) {
        setUsers(results.data);
      } else {
        setErrorMessageUserEmpty(results.message);
      }
    });
  }
  function handleChangeUsers(ev,newValue) {
   
    // const { name, value } = ev.target;
    // console.log(name,newValue);
    setIdUserSup(newValue);
    setForm(prevForm => ({
      ...prevForm,
      user_id: newValue
    }));
    setisUserUndefined(false);
  }


  function handleOnChangeFunction(ev, value) {
    // const { name, value } = ev.target;
    setFunctionId(value);
  }

  return (
    <>

      <MainContainer {...headerDataAdmin}>

        <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
        <Grid container item xs={12}>
          {hasPrivilege(contextData.privileges, 'Admin-User-List') &&
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClick}
              style={{ fontWeight: 600 }}
            >
              ENREGISTRER

            </Button>
          }
          <Spacer x={2} />
          <Button
            variant="contained"
            color="grey"
            onClick={handleCancel}
            style={{ fontWeight: 600 }}
          >
            ANNULER
        </Button>
        </Grid>

        <Spacer y={2} />
        <Paper className={classes.paper} variant="outlined">

          <Grid container xs={12} spacing={1} >

            <Grid item xs={12}>
              <fieldset>
                <legend>Information personnelle</legend>
                <Grid item container xs={12}>
                  <Grid item container xs={9} spacing={1}>
                    <Grid item xs={3}>
                      <label>Nom{bullRed}</label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        size="small"
                        label="Nom"
                        variant="outlined"
                        onChange={handleChange1}
                        value={form.last_name}
                        error={!!isEmptyLastName}
                        helperText={isEmptyLastName}
                        name="last_name"
                        onFocus={handleFocusLastName}
                        inputProps={{ style: { textTransform: "uppercase" } }}

                      />
                      {isEmptyLastName && (
                        <div style={{ color: "red", textAlign: "left" }}>
                          <span>Ce champ est obligatoire!</span>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <label>Prénom</label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        size="small"
                        label="Prénom"
                        variant="outlined"
                        onChange={handleChange1}

                        value={form.first_name}
                        name="first_name"
                        inputProps={{ style: { textTransform: 'capitalize', } }}

                      />

                    </Grid>
                    <Grid item xs={3}>
                      <label>Téléphone{bullRed}</label>
                    </Grid>
                    <Grid item xs={8}>
                      <Phone x1={3} x2={8} name="telephone" value={form.telephone} onChange={handleChange1} onFocus={handleFocusPhoneNumber} />

                      {compt && <><Spacer y={1} /><Alert severity="error">Le numero de téléphone ne contient pas de lettres</Alert></>}
                      {isPhoneNumberEmpty && <><Spacer y={1} /><Alert severity="error">Ce champ est obligatoire</Alert></>}
                      {isPhoneNumberLengthNotValid && <><Spacer y={1} /><Alert severity="error">Veuillez vérifier votre numéro de téléphone. La longueur n'est pas valide</Alert></>}

                    </Grid>

                    <Grid item xs={3}>
                      <label class="aligneleft">Sexe{bullRed}</label>
                    </Grid>

                    <Grid item xs={8}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="sex"
                          name="sex"
                          onChange={handleChange1}
                          row
                          value={form.sex}
                        >
                          {sexe.map((row) => (
                            <FormControlLabel
                              value={row.sex_id}
                              control={<Radio />}
                              label={row.name}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <label class="aligneleft">Email{bullRed}</label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth


                        id="outlined-basic"
                        size="small"
                        label="email"
                        variant="outlined"
                        onChange={handleChange1}
                        onFocus={handleFocusEmail}
                        onBlur={handleBlurEmail}
                        error={!!isValidEmail}
                        helperText={isValidEmail}

                        value={form.email}
                        name="email"
                      />

                      {isValidEmail && <div style={{ color: "red", textAlign: "left" }}><span>Adresse email invalide</span></div>}

                    </Grid>
                    <Grid item xs={3}>
                      <label class="aligneleft">Groupes{bullRed}</label>
                    </Grid>

                    <Grid item xs={8}>
                      <FormControl fullWidth variant="outlined" size="small">

                        <InputLabel id="demo-simple-select-outlined-label" >

                          Groupes
                    </InputLabel>

                        <Select

                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          multiple
                          onFocus={handleSelectGroups}
                          onChange={handleChange1}
                          value={form.group}
                          name="group"
                          label="Groupes"
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}

                                  label={getItemLabel1(value)}
                                  className={classes.chip}
                                />
                              ))}
                            </div>
                          )}
                        >
                          {tests.map((row) => (
                            <MenuItem value={row.group_id}>
                              <Checkbox checked={form.group.includes(row.group_id)} />

                              <ListItemText primary={row.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                      <label class="aligneleft">Direction</label>
                    </Grid>

                    <Grid item xs={8}>
                      <FormControl fullWidth variant="outlined" size="small">

                        <InputLabel id="demo-simple-select-outlined-label" >

                          Direction
                    </InputLabel>

                        <Select labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={iddir}
                          name="direction_id"
                          label="Direction"
                          onChange={handleChangeDirection}>
                          {dir.map((row) => (
                            <MenuItem value={row.direction_id}>{row.code}</MenuItem>

                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                      <label class="aligneleft">Service</label>
                    </Grid>

                    <Grid item xs={8}>
                      <FormControl fullWidth variant="outlined" size="small">

                        <InputLabel id="demo-simple-select-outlined-label" >

                          Service
                    </InputLabel>

                        <Select labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={idservice}
                          name="service_id"
                          onChange={handleChangeService}
                          label="Service" >
                          {service.map((row) => (
                            <MenuItem value={row.service_id}>{row.code}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>


                    <Grid item xs={3}>
                      <label class="aligneleft">Poste</label>
                    </Grid>

                    <Grid item xs={8}>
                      <Autocomplete
                        onChange={handleOnChangeFunction}
                        options={functionList} 
                        value={functionId}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Poste"
                            size="small"
                            variant="outlined"
                            InputProps={{ ...params.InputProps, type: "search" }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <label>Matricule</label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        size="small"
                        label="Matricule"
                        variant="outlined"
                        onChange={handleChange1}
                        value={matricule}
                        // error={!!isEmptyLastName}
                        // helperText={isEmptyLastName}
                        name="matricule"
                        onFocus={handleFocusLastName}
                        inputProps={{ style: { textTransform: "uppercase" } }}

                      />
                      {isEmptyLastName && (
                        <div style={{ color: "red", textAlign: "left" }}>
                          <span>Ce champ est obligatoire!</span>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>

                    {/* <Grid item xs={4}> */}
                    <div className={classes.input}>
                      {state.file == null && (
                        <Button title="Cliquez pour inserer une photo">
                          <Avatar className={classes.large} type="file" onChange={handleChange1} accept="image/*"
                            onClick={handleUpload} onMouseOver={handlePopoverOpen}>
                            {form.name_image !== "" && form.name_image !== null && form.name_image !== undefined && (
                              <img src={form.url_name_image} style={{ width: "120px", height: "120px" }} />)}
                            {(form.name_image === "" || form.name_image === null) && <PhotoCamera className={classes.small} />}
                          </Avatar>
                        </Button>
                      )}
                      {state.file !== null && (
                        <>
                          <Button title="Cliquez pour inserer une photo">
                            <Avatar className={classes.large} type="file" onChange={handleChange1} accept="image/*"
                              onClick={handleUpload} onMouseOver={handlePopoverOpen}>
                              <img src={state.file} style={{ width: "170px", height: "170px" }} />
                            </Avatar>
                          </Button>
                        </>
                      )}
                    </div>
                    {/* </Grid> */}
                  </Grid>
                  <div>
                    <Dialog
                      open={opens}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="form-dialog-title" style={{ color: 'white', }}>
                        {"Information"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {"La taille de l'image doit être < 1Mb et le type doit être .jpg , .png , .gif"}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <input
                          accept="image/*"
                          variant="contained"
                          color="primary"
                          className={classes.type}
                          id="contained-button-file"
                          onChange={handleChangeUpload}
                          multiple
                          name="photos"
                          type="file"
                        />
                        <label htmlFor="contained-button-file">
                          <Button variant="contained" color="primary" component="span" >
                            Accept
                          </Button>
                        </label>
                      </DialogActions>
                    </Dialog>
                  </div>
                </Grid>
              </fieldset>
            </Grid>


            {/* <Grid item xs={12}>
              <fieldset >
                <legend>Supérieur hiérarchique</legend>
                <Grid item container xs={12}>

                  <Grid item container xs={9} spacing={1}>
                    <Grid item xs={3}>
                      <label class="aligneleft">Direction</label>
                    </Grid>

                    <Grid item xs={8}>
                      <FormControl fullWidth variant="outlined" size="small">

                        <InputLabel id="demo-simple-select-outlined-label" >

                          Direction
                        </InputLabel>

                        <Select labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={iddirSupHierarchique}
                          name="direction_id"
                          label="Direction"
                          onChange={handleChangeDirectionSupHierarchique}>
                          {dirSupHierarchique.map((row) => (
                            <MenuItem value={row.direction_id}>{row.code}</MenuItem>

                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                      <label class="aligneleft">Service</label>
                    </Grid>

                    <Grid item xs={8}>
                      <FormControl fullWidth variant="outlined" size="small">

                        <InputLabel id="demo-simple-select-outlined-label" >

                          Service
                        </InputLabel>

                        <Select labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={idserviceSupHierarchique}
                          name="service_id"
                          onChange={handleChangeServiceSupHierarchique}
                          label="Service" >
                          {serviceSupHierarchique.map((row) => (
                            <MenuItem value={row.service_id}>{row.code}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                      <label class="aligneleft">Supérieur hiérarchique</label>
                    </Grid>

                    <Grid item xs={8}>
                      <FormControl fullWidth variant="outlined" size="small"> */}

                        {/* <InputLabel id="demo-simple-select-outlined-label" >

                          Supérieur hiérarchique
                        </InputLabel> */}

                          {/* <Select labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={idUserSup}
                            name="user_id"
                            label="Supérieur hiérarchique"
                            onChange={handleChangeUsers}>
                            {users.map((row) => (
                              <MenuItem value={row.user_id}>{row.first_name} {row.last_name}</MenuItem>
                            ))}
                          </Select> */}
                          {/* <Autocomplete
                            id="demo-simple-select-outlined"
                            value={idUserSup}
                            name="user_id"
                            options={users.slice(0, 5)} // Limitez les suggestions à 5
                            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                            onChange={handleChangeUsers}
                            renderInput={(params) => <TextField {...params} label="AAAA" variant="outlined" />}
                          /> */}

                        {/* <Autocomplete
                          id="demo-autocomplete"
                          name="user_id"
                          value={ users.find(option => option.user_id === idUserSup)}
                          options={users}
                          getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                          onChange={(event, newValue) => handleChangeUsers(event, newValue.user_id)}
                          filterOptions={(options, { inputValue }) => {
                            const filteredOptions = options.filter(option =>
                              `${option.first_name} ${option.last_name}`.toLowerCase().includes(inputValue.toLowerCase())
                            );
                            // Retourner seulement les trois premières options filtrées
                            return filteredOptions.slice(0, 3);
                          }}
                          renderInput={(params) => <TextField {...params} label="Supérieur hiérarchique" variant="outlined" />}
                        />

                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={3} spacing={1}>
                  </Grid>
                </Grid>
              </fieldset> */}
            {/* </Grid> */}
            <Grid item xs={12}>
              <label className={classes.text}>({bullRed}):Champs obligatoires</label>
            </Grid>
          </Grid>

        </Paper>
      </MainContainer>
    </>
  );

}

export default UserAdd;
