import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import {
  Grid,
  Paper,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import DateFnsUtils from "@date-io/date-fns";
import Moment, { duration } from "moment";
import { extendMoment } from "moment-range";
import { Autocomplete } from "@material-ui/lab";
import { useLocation } from 'react-router-dom';
//import LeaveService from "../../../services/LeaveService";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
/* The handleChange() function to set a new state for input */

import LeaveService from "../../../services/LeaveService";
import DayService from "../../../services/DayService";
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { hasPrivilege } from "../../../utils/privilege";
import Message from "../../tools/Message";


import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { fr } from "date-fns/locale";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import BalanceConfigurationService from "../../../services/BalanceConfigurationService";
import { WarningTwoTone } from '@material-ui/icons';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import { headerDataRH } from "../../../data/modules/hr-header";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import ConfirmationDialog from "../../tools/ConfirmationDialog";
import MotifAnnulationLeave from "../../tools/MotifAnnulationLeave";
import { CircularProgress } from "@material-ui/core";
import UserService from "../../../services/UserService";
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  datePicker: {
    margin: 0,
  },
  businessInput: {
    display: "none",
  },
  disabled: {
    cursor: "default",
    opacity: "0.1",
  },
  solde1: {
    background: "red",

  },
  disabledSolde: {
    background: "rgb(235, 235, 228)",
    cursor: "default",
    pointerEvents: "none !important",
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  warningGrid: {
    paddingTop: "20px !important"
  }
}));


function LeaveAdd(props) {
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [radioBeginDate, setRadioBeginDate] = useState(null);
  const [radioEndDate, setRadioEndDate] = useState(null);
  const [isPriority, setIsPriority] = useState(false);
  const [motif, setMotif] = useState("");
  const [delta, setDelta] = useState();
  const [balanceAfter, setBalanceAfter] = useState();
  const [isvalid, setIsvalid] = useState(0);
  const [file, setFile] = useState(null);

  const [form, setForm] = useState({
    leave_type_id: "",
    delta: "",
    beginDate: " ",
    radioBeginDate: "",
    endDate: " ",
    radioEndDate: "",
    isPriority: "",
    balanceBefore: "",
    balanceAfter: "",
    duration: "",
    reason: "",
  });

  const [askedDayOff, setAskedDayOff] = useState(0);
  const [errorDate, setErrorDate] = useState("");
  const [dayOffType, setDayOffType] = useState("");
  const [leave_type, setLeaveType] = useState([]);
  const [day, setDay] = useState([]);
  const [soldePrevisional, setSoldePrevisional] = useState(0);
  const [soldePrevisionalAfter, setSoldePrevisionalAfter] = useState(0);
  const [inputStyle, setInputStyle] = useState({
    border: '1px solid black'
  });
  const [inputStyle1, setInputStyle1] = useState({
    border: '1px solid black'
  });

  const [valid, setValid] = useState(false);
  const [validdebut, setValiddebut] = useState(false);
  const [beginDateNotMatch, setBeginDateNotMatch] = useState(false);
  const [dateBeginFormatNotMatch, setDateBeginFormatNotMatch] = useState(false);
  const [dateEndFormatNotMatch, setDateEndFormatNotMatch] = useState(false);
  const [dateBeginInDayNotCounted, setDateBeginInDayNotCounted] = useState(false);
  const [dateEndinDayNotCounted, setDateEndinDayNotCounted] = useState(false);
  const [balanceBefore, setBalanceBefore] = useState(0);
  const [balanceAfters, setBalanceAfters] = useState(0);
  const [realdelta, setRealdelta] = useState(0);
  const [afterdelta, setAfterdelta] = useState(0);
  const [message, setMessage] = useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // const valide = splitLocation[4];
  const [leaveValide, setLeaveValide] = useState(0);
  const [idLeave, setIdLeave] = useState(0);

  /* ********** Samuela: solde réel déjà configuré dans la base de données ********* */
  const [isRealBalanceAlreadyConfigurate, setisRealBalanceAlreadyConfigurate] = useState(false);
  const [errorMessageForRealBalanceEmpty, seterrorMessageForRealBalanceEmpty] = useState("");

  const [realBalanceBeforeStorage, setRealBalanceBeforeStorage] = useState(0);


  const [isLeaveUpdated, setLeaveUpdated] = useState(false);
  const [isConfirmationFinal, setIsConfirmationFinal] = useState(false);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = useState(false);
  const contextData = useContext(userDetailContext);
  const navigate = useNavigate();
  const params = useParams();


  const [confirmationDialog, setConfirmationDialog] = useState({
    openDialog: false,
    messageDialog: "Voulez vous vraiment annuler votre demande de congé  ?",
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [isCancel, setIsCancel] = useState(false);

  //confirmmation annulation validation
  const [cancel, setCancel] = useState(false);
  const [emptyMotif, setEmptyMotif] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [raison, setRaison] = useState('');
  const [codeobselete, setCodeobselete] = useState('');
  const [codeLeave, setCodeLeave] = useState('');
  const [fileObselete, setFileObselete] = useState('');
  const [fileRequest, setFileRequest] = useState('');

  const [isValidEdited, setIsValidEdited] = useState(false);
  const [confirmLeaveAdd, setConfirmLeaveAdd] = useState(false);

  const [loadingPlanning, setloadingPlanning] = useState(false);
  const [loadingValidate, setloadingValidate] = useState(false);
  const [isLeavePlanningUpdated, setisLeavePlanningUpdated] = useState(false);
  const [idLeavePlanningUpdated, setidLeavePlanningUpdated] = useState(false);

  const [isValidatorNumberConfigurate, setisValidatorNumberConfigurate] = useState(false);
  const [dayOffNumberWithoutValidation, setdayOffNumberWithoutValidation] = useState(false);

  function getDayLeave(beginDate, endDate) {
    var dateArray = [];
    var currentDate = moment(beginDate);
    var stopDate = moment(endDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
      currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  }
  function getDayParameter(beginDate, endDate) {
    let count = 0;
    const tab = getDayLeave(beginDate, endDate);
    for (let j = 0; j < day.length; j++) {
      for (let i = 0; i < tab.length; i++) {
        if (day[j] == tab[i]) {
          count++;
        }
      }
    }
    return count;
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }


  //const [leave_delta, setLeaveDelta] = useState([]);

  const idLeaveToUpdated = params.id ? params.id : "";
  const valide = params.valide;
  const idLeaveToValidate = params.idLeaveToValidate;

  function handleChangeLeave(isRealRequest = null) {
    setLoading(true);
    LeaveService.saveLeave(
      dayOffType,
      beginDate,
      radioBeginDate,
      endDate,
      radioEndDate,
      isPriority,
      delta,
      balanceAfter,
      askedDayOff,
      motif,
      isvalid,
      isValidEdited,
      file,
      idLeave,
      isRealRequest,
      isLeavePlanningUpdated,
      isValidatorNumberConfigurate,
      dayOffNumberWithoutValidation
    )
      .then((res) => {
        if (res && res.status === 200) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
          setLoading(false);
          setloadingPlanning(false);
          setloadingValidate(false);
          navigate("/me/leaves");
        } else {
          if (res.message == "") {
            setLoading(false);
            setloadingPlanning(false);
            setloadingValidate(false);
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur undefined" });
          } else {
            setLoading(false);
            setloadingPlanning(false);
            setloadingValidate(false);
            setMessage({ ...message, open: true, color: 'red', messageInfo: res.message });
          }

        }
      })
      .catch((err) => {
        setLoading(false);
        setloadingPlanning(false);
        setloadingValidate(false);
        setMessage({ ...message, open: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
      });
  }

  function Daynumber(beginDate, endDate, radioBeginDate, radioEndDate) {
    if (beginDate !== undefined && endDate !== undefined) {
      LeaveService.getLeaveDay(beginDate, endDate).then((result) => {
        const countDay = getDayParameter(beginDate, endDate);
        if (countDay != null) {
          result.data = result.data - countDay;
        } else {
          result.data = result.data;
        }
        if (radioBeginDate === "afternoon") {
          // Remove 0.5 day from the dayoff if start is in the afternoon
          result.data -= 0.5;
        }
        if (radioEndDate === "afternoon") {
          // Add 0.5 day from the dayoff if end is in the afternoon
          result.data += 0.5;
        }
        // console.log("androooo", result.data)
        setAskedDayOff(result.data);
        calculatePrevisionalAndRealBalanceAfter(dayOffType, result.data, isRealBalanceAlreadyConfigurate, balanceBefore, realdelta);
      });
    }
  }

  function calculatePrevisionalAndRealBalanceAfter(typeOfLeave, duration, isRealBalanceExist, previsionalBefore, realBefore) {
    if (!isRealBalanceExist) {
      // console.log("ato")
      //function calculate_provisionnal_balance_After
      if (typeOfLeave == 1 || typeOfLeave.trim() == "") {
        // LeaveService.getBalanceAfter(duration).then((results) => {
        //   setBalanceAfters(results.data);
        //   if (results.data < 0) {
        //     setInputStyle({
        //       border: form.delta - duration < 0 ? '5px solid red' : 'none'
        //     })
        //   }
        //   else {
        //     setInputStyle({
        //       border: form.delta - duration < 0 ? '2px solid  rgb(19, 62, 103)' : 'none'
        //     })
        //   }
        // });

        //function calculate_real_balance_After
        LeaveService.getRealBalanceAfter(duration).then((results) => {
          setAfterdelta(results.data);
          if (results.data < 0) {
            setInputStyle1({
              border: form.delta - duration < 0 ? '5px solid red' : 'none'
            })
          }
          else {
            setInputStyle1({
              border: form.delta - duration < 0 ? '2px solid  rgb(19, 62, 103)' : 'none'
            })
          }
        });
      } else {
        setBalanceAfters(previsionalBefore);
        setAfterdelta(realBefore);
      }
    } else {

      if (typeOfLeave == 1 || typeOfLeave.trim() == "") {
        // const totalBalanceAfter = previsionalBefore - duration;
        // setBalanceAfters(totalBalanceAfter);
        // if (totalBalanceAfter < 0) {
        //   setInputStyle({
        //     border: form.delta - duration < 0 ? '5px solid red' : 'none'
        //   })
        // } else {
        //   setInputStyle({
        //     border: form.delta - duration < 0 ? '2px solid  rgb(19, 62, 103)' : 'none'
        //   })
        // }

        //set real_balance_After
        const totalAfterDelta = realBefore - duration;
        setAfterdelta(totalAfterDelta);
        if (totalAfterDelta < 0) {
          setInputStyle1({
            border: '5px solid red'
          })
        } else {
          setInputStyle1({
            border: '2px solid  rgb(19, 62, 103)'
          })
        }
      } else {
        setBalanceAfters(previsionalBefore);
        setAfterdelta(realBefore);
      }

    }
  }

  function handleClick() {
    if (!dateBeginFormatNotMatch && !dateEndFormatNotMatch &&
      !dateBeginInDayNotCounted && !dateEndinDayNotCounted) {
      if (!valid && !validdebut) {
        if (valide === "valide") {
          if (isCancel && askedDayOff === 0) {

            setConfirmationDialog({ ...confirmationDialog, openDialog: true });
          } else {
            if (leaveValide === 1) {
              handleChangeLeave(0);
              // console.log("oui ato")
            } else {
              handleChangeLeave(0);
              // console.log("non ato")
            }

          }
        } else {
          if (realdelta < 0){
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Votre solde est négatif" });
          } else if(afterdelta < 0){
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Votre solde est insuffisant" });
          } else {
            if (isLeavePlanningUpdated) { // si modification planning
              handleChangeLeave(0);
            } else {//si ajout normal

              setConfirmLeaveAdd(true);
              setConfirmationDialog({ ...confirmationDialog, openDialog: true });
            }
          }
        }
      }

    }
  }

  const classes = useStyles();

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  function handleBeginDateChange(e) {
    // const { name, value } = e.target;
    const debutDate = moment(new Date(e)).format("YYYY-MM-DD");

    if (checkIfDateInDayNotCompted(debutDate)) {
      setDateBeginInDayNotCounted(true);
      setBeginDate(debutDate);
      setForm({ ...form, beginDate: debutDate });
    } else {
      setDateBeginInDayNotCounted(false);

      // setForm({ ...form, beginDate: debutDate });
      setBeginDateNotMatch(false);
      setDateBeginFormatNotMatch(false);
      if (isValidDate(e)) {
        if (moment(new Date(e)).year() < 999) {
          setDateBeginFormatNotMatch(true);
        } else {
          if (form.endDate !== "" && form.endDate !== undefined && (debutDate < form.endDate)) {
            // if (debutDate > form.endDate) {
            //   setValiddebut(true);

            // } else {
            setValiddebut(false);
            setValid(false);
            const debutDateYear = new Date(e).getFullYear();
            const endDateYear = new Date(form.endDate).getFullYear();
            setForm({ ...form, beginDate: debutDate });
            if (debutDateYear === endDateYear) {
              setBeginDateNotMatch(false);
              if (valide === "valide") {
                if (beginDate === debutDate) {

                  setBeginDate(debutDate);
                  Daynumber(form.beginDate, form.endDate, radioBeginDate, radioEndDate);
                  setLeaveValide(0);
                  setIsValidEdited(false);
                } else {
                  Daynumber(debutDate, form.endDate, radioBeginDate, radioEndDate);
                  setBeginDate(debutDate);
                  setLeaveValide(1);
                  setFile(null);
                  setIsValidEdited(true);
                }
                setForm({ ...form, beginDate: debutDate });
              } else {
                if (form.endDate !== "") {
                  Daynumber(debutDate, form.endDate, radioBeginDate, radioEndDate);
                }
                setBeginDate(debutDate);
                setForm({ ...form, beginDate: debutDate });
              }
            } else if (debutDateYear !== endDateYear && debutDateYear.toString().length === 4) {
              setBeginDateNotMatch(true);
            }
            // }
          } else if (form.endDate !== "" && form.endDate !== undefined && (debutDate > form.endDate)) {
            setValiddebut(true);
          } else if (valide === "valide" && form.endDate !== "" && form.endDate !== undefined && debutDate == form.endDate) {
            // console.log("vvvv");
            if (radioBeginDate === radioEndDate) {
              setIsCancel(true);
              setValid(false);
              setBeginDate(debutDate);
              setForm({ ...form, beginDate: debutDate });
              Daynumber(debutDate, form.endDate, radioBeginDate, radioEndDate);
              setIsValidEdited(false);
            } else {
              Daynumber(debutDate, form.endDate, radioBeginDate, radioEndDate);
              setBeginDate(debutDate);
              setLeaveValide(1);
              setFile(null);
              setIsValidEdited(true);
            }
          } else {
            setBeginDate(debutDate);
            setForm({ ...form, beginDate: debutDate });
          }
        }
      }
    }
  }

  function handleEndDateChange(e) {
    const datefin = moment(new Date(e)).format("YYYY-MM-DD");
    // setForm({ ...form, endDate: datefin });

    if (checkIfDateInDayNotCompted(datefin)) {
      setEndDate(datefin);
      setForm({ ...form, endDate: datefin });
      setDateEndinDayNotCounted(true);
    } else {
      setDateEndinDayNotCounted(false);
      setDateEndFormatNotMatch(false);
      if (isValidDate(e)) {
        if (moment(new Date(e)).year() < 999) {
          setDateEndFormatNotMatch(true);
        } else {
          if (form.beginDate !== "" && form.beginDate !== undefined && (beginDate < datefin)) {
            setValid(false);
            setValiddebut(false);
            if (valide === "valide") {
              if (endDate === datefin) {
                Daynumber(form.beginDate, datefin, radioBeginDate, radioEndDate);
                setEndDate(datefin);
                setLeaveValide(0);
                setIsValidEdited(false);
              } else {
                Daynumber(form.beginDate, datefin, radioBeginDate, radioEndDate);
                setEndDate(datefin);
                setLeaveValide(1);
                setFile(null);
                setIsValidEdited(true);
              }
              setForm({ ...form, endDate: datefin });
            } else {
              if (form.beginDate !== "") {
                Daynumber(form.beginDate, datefin, radioBeginDate, radioEndDate);
              }
              setEndDate(datefin);
              setForm({ ...form, endDate: datefin });
            }
          } else if (form.beginDate !== "" && form.beginDate !== undefined && beginDate > datefin) {
            setValid(true);
          } else if (valide === "valide" && form.beginDate !== "" && form.beginDate !== undefined && beginDate == datefin) {
            if (radioBeginDate === radioEndDate) {
              setIsCancel(true);
              setValid(false);
              setEndDate(datefin);
              setForm({ ...form, endDate: datefin });
              Daynumber(form.beginDate, datefin, radioBeginDate, radioEndDate);
              setIsValidEdited(false);
            } else {
              Daynumber(form.beginDate, datefin, radioBeginDate, radioEndDate);
              setEndDate(datefin);
              setLeaveValide(1);
              setFile(null);
              setIsValidEdited(true);
            }
          } else {
            setEndDate(datefin);
            setForm({ ...form, endDate: datefin });
          }
        }
      }
    }
  }

  const handleChangeMotif = (e, value) => {
    if (valide === "valide") {
      if (motif == value) {
        setMotif(value);
        setLeaveValide(0);
      } else {
        setMotif(value);
        setLeaveValide(1);
        setFile(null);
      }
    } else {
      setMotif(value);
    }
  };

  const handleChangeInputMotif = (e) => {
    setMotif(e.target.value)
  }

  const handleChangeDelta = (e) => {
    setDelta(e.target.value);
  };
  const handleChangeBalanceAfter = (e) => {
    setBalanceAfter(e.target.value);
  };
  useEffect(() => {
    localStorage.setItem('status', 0);
    setForm({
      leave_type_id: "",
      delta: "",
      beginDate: "",
      radioBeginDate: "morning",
      endDate: "",
      radioEndDate: "morning",
      isPriority: "",
      balanceBefore: "",
      balanceAfter: "",
      duration: "",
      reason: "",
    });

    setRadioBeginDate("morning");
    setRadioEndDate("morning");
    /** Check if real balance is already configurate for user connected */


    const propsInitialBalance = localStorage.getItem("isInitialBalanceConfigurated");

    if (propsInitialBalance !== null && propsInitialBalance !== undefined && propsInitialBalance.trim().length !== 0) {
      setisRealBalanceAlreadyConfigurate(propsInitialBalance);
    }
    // setOpenLoadingSimpleBackdrop(true);
    // BalanceConfigurationService.isInitialBalanceConfigurated().then((res) => {
    //   if (res && res.data) {
    //     setisRealBalanceAlreadyConfigurate(true);
    //     localStorage.setItem("isRealBalanceAlreadyConfigurate", true);
    //     setOpenLoadingSimpleBackdrop(false);
    //   } else {
    //     setisRealBalanceAlreadyConfigurate(false);
    //     seterrorMessageForRealBalanceEmpty(res.message);
    //     setOpenLoadingSimpleBackdrop(false);
    //   }
    // })

    //function calculate_provisionnal_balance
    LeaveService.getBalanceBefore().then((result) => {
      setBalanceBefore(result.data);

      localStorage.setItem("previsionalBefore", result.data);
    });

    //function calculate_real_balance
    const propsActualBalance = localStorage.getItem("realBefore");

    if (propsActualBalance !== null && propsActualBalance !== undefined && propsActualBalance.trim().length !== 0) {
      setRealdelta(propsActualBalance);
    }
    // LeaveService.getRealBalanceBefore().then((result) => {
    //   setRealdelta(result.data);
    //   localStorage.setItem("realBefore", result.data);
    // });

    if (beginDate && endDate) {
      LeaveService.getLeavePrevisional(contextData.id, moment().format('YYYY-MM-DD'), beginDate).then((result) => {
        const previsionalLeave = result.data.data.reduce(function (sum, leave) {

          return sum + parseFloat(leave.duration);
        }, 0);

        setSoldePrevisional(form.delta - previsionalLeave)

      })
    }
    DayService.getDay().then((results) => {
      if (results && results.data) {
        setDay(results.data);
      }
    });
    LeaveService.getLeaveType().then((result) => {
      if (result && result.data) {
        setLeaveType(
          result.data.map((d) => {
            return { id: d.leave_type_id, label: d.name, desc: d.desc };
          })
        );
      }
    });
    if (idLeaveToUpdated) {
      setIdLeave(idLeaveToUpdated)
      setLeaveUpdated(true);
    }
    if (valide === "valide") {
      setIsConfirmationFinal(true);
      setIdLeave(idLeaveToValidate);
      if (leaveValide !== 1) {
        setIsvalid(1);
      } else if (leaveValide === 1) {
        setFile(null);
      }
    }


    if (valide === "editPlanning") {
      setIdLeave(idLeaveToValidate);
      setidLeavePlanningUpdated(idLeaveToValidate);
      setisLeavePlanningUpdated(true);
      // setLeaveUpdated(true);
      // setLeaveUpdated(true);
    }

    setInputStyle({
      border: form.delta - askedDayOff < 0 ? '5px solid black' : 'none'
    })
    setInputStyle1({
      border: form.delta - askedDayOff < 0 ? '5px solid black' : 'none'
    })

    UserService.checkValidatornumberByUserId(0).then((results) => {
      if (results && results.status) {
        console.log("7777777777", results)
        setisValidatorNumberConfigurate(true);
        setdayOffNumberWithoutValidation(results.data.user_validator_number_dayNumberWithoutValidation);
      }
    });
  }, [location]);

  useEffect(() => {
    if (isRealBalanceAlreadyConfigurate) {
      // LeaveService.getRealBalanceBefore().then((result) => {
      //   setRealdelta(result.data);
      //   localStorage.setItem("realBefore", result.data);
      // });

      DayService.getDay().then((results) => {
        if (results && results.data) {
          setDay(results.data);
        }
      });
    }

  }, [isRealBalanceAlreadyConfigurate]);



  useEffect(() => {
    if (isLeaveUpdated || idLeavePlanningUpdated) {

      const propsInitialBalance = localStorage.getItem("isInitialBalanceConfigurated");

      if (propsInitialBalance !== null && propsInitialBalance !== undefined && propsInitialBalance.trim().length !== 0) {
        setisRealBalanceAlreadyConfigurate(propsInitialBalance);
      }


      const propsActualBalance = localStorage.getItem("realBefore");

      if (propsActualBalance !== null && propsActualBalance !== undefined && propsActualBalance.trim().length !== 0) {
        setRealdelta(propsActualBalance);
      }

      LeaveService.getLeaveType().then((result) => {
        if (result && result.data) {
          setLeaveType(
            result.data.map((d) => {
              return { id: d.leave_type_id, label: d.name, desc: d.desc };
            })
          );
        }
      });

      LeaveService.getLeaveById(idLeave).then((info) => {
        setIsvalid(0);
        setFile(info.data.file);
        setDayOffType(info.data.leave_type_id);
        setMotif(info.data.reason);
        setRadioBeginDate(info.data.begin_time_session);
        setRadioEndDate(info.data.end_time_session);
        if (info.data.is_priority == 0) {
          setIsPriority(false);
        } else {
          setIsPriority(true);
        }
        setAskedDayOff(info.data.duration);
        setForm({
          id: idLeave,
          begin_time: info.data.begin_time,
          duration: info.data.duration,
          end_time: info.data.end_time,
          is_valid: info.data.is_valid,
          leave_id: info.data.leave_id,
          leave_type_id: info.data.leave_type_id,
          reason: info.data.reason,
          user_id: info.data.user_id,
          is_priority: info.data.is_priority,
        });
        //date display on modification
        const results = info.data.begin_time;
        const date = results.split(' ');
        const fin = info.data.end_time;
        const findate = fin.split(' ');
        const begindate = info.data.begin_time_session;
        setForm({
          beginDate: date[0],
          endDate: findate[0],
        });

        setBeginDate(date[0]);
        setEndDate(findate[0]);
        // const isRealBalanceExist = JSON.parse(localStorage.getItem("isRealBalanceAlreadyConfigurate"));
        // const previsionalBalanceBeforeStorage = JSON.parse(localStorage.getItem("previsionalBefore"));
        // const realBalanceBeforeStorage = JSON.parse(localStorage.getItem("realBefore"));

        // calculatePrevisionalAndRealBalanceAfter(info.data.leave_type_id, info.data.duration, isRealBalanceExist, previsionalBalanceBeforeStorage, realdelta);
        setOpenLoadingSimpleBackdrop(false);
        getPrevisionnalBalanceBefore(info.date.begin_time);
      });
    }
  }, [isLeaveUpdated, isLeavePlanningUpdated]);



  useEffect(() => {
    if (isConfirmationFinal) {
      setOpenLoadingSimpleBackdrop(true);

      const propsInitialBalance = localStorage.getItem("isInitialBalanceConfigurated");

      if (propsInitialBalance !== null && propsInitialBalance !== undefined && propsInitialBalance.trim().length !== 0) {
        setisRealBalanceAlreadyConfigurate(propsInitialBalance);
      }


      const propsActualBalance = localStorage.getItem("realBefore");

      if (propsActualBalance !== null && propsActualBalance !== undefined && propsActualBalance.trim().length !== 0) {
        setRealdelta(propsActualBalance);
      }

      LeaveService.getLeaveType().then((result) => {
        if (result && result.data) {
          setLeaveType(
            result.data.map((d) => {
              return { id: d.leave_type_id, label: d.name, desc: d.desc };
            })
          );
        }
      });

      LeaveService.getLeaveById(idLeaveToValidate).then((info) => {

        setFile(info.data.file);
        setDayOffType(info.data.leave_type_id);
        setMotif(info.data.reason);
        setRadioBeginDate(info.data.begin_time_session);
        setRadioEndDate(info.data.end_time_session);
        if (info.data.is_priority == 0) {
          setIsPriority(false);
        } else {
          setIsPriority(true);
        }
        setAskedDayOff(info.data.duration);
        setForm({
          id: idLeaveToValidate,
          begin_time: info.data.begin_time,
          duration: info.data.duration,
          end_time: info.data.end_time,
          is_valid: info.data.is_valid,
          leave_id: info.data.leave_id,
          leave_type_id: info.data.leave_type_id,
          reason: info.data.reason,
          user_id: info.data.user_id,
          is_priority: info.data.is_priority,
        });
        //date display on modification
        const results = info.data.begin_time;
        const date = results.split(' ');
        const fin = info.data.end_time;
        const findate = fin.split(' ');
        const begindate = info.data.begin_time_session;
        setForm({
          beginDate: date[0],
          endDate: findate[0],
        });

        setBeginDate(date[0]);
        setEndDate(findate[0]);
        setAskedDayOff(info.data.duration);
        setCodeLeave(info.data.leave_code);
        setFileRequest(info.data.file);
        // const isRealBalanceExist = JSON.parse(localStorage.getItem("isRealBalanceAlreadyConfigurate"));
        // const previsionalBalanceBeforeStorage = JSON.parse(localStorage.getItem("previsionalBefore"));
        // const realBalanceBeforeStorage = JSON.parse(localStorage.getItem("realBefore"));

        //calculatePrevisionalAndRealBalanceAfter(info.data.leave_type_id, info.data.duration, isRealBalanceExist, previsionalBalanceBeforeStorage, realdelta);
        setOpenLoadingSimpleBackdrop(false);
      });
    }
  }, [isConfirmationFinal]);

  useEffect(() => {
    if (dayOffType == 2) {
      setAfterdelta(realdelta);
      // setBalanceAfters(balanceBefore);
      setSoldePrevisionalAfter(soldePrevisional)
    } else {
      if (dayOffType.trim().length !== 0) {
        calculatePrevisionalAndRealBalanceAfter(dayOffType, askedDayOff, isRealBalanceAlreadyConfigurate, balanceBefore, realdelta);
        setSoldePrevisionalAfter(soldePrevisional - askedDayOff);
      }
    }

  }, [dayOffType]);


  useEffect(() => {
    if (leaveValide !== 1) {
      setIsvalid(1);
    } else {
      setIsvalid(0);
    }

  }, [leaveValide])


  useEffect(() => {
    if (realdelta) {
      calculatePrevisionalAndRealBalanceAfter(dayOffType, askedDayOff, isRealBalanceAlreadyConfigurate, balanceBefore, realdelta);
      if (soldePrevisional) {
        setSoldePrevisionalAfter(soldePrevisional - askedDayOff);
      }
    }
  }, [askedDayOff]);

  useEffect(() => {
    if (form.endDate !== undefined && form.endDate.trim().length !== 0) {
      LeaveService.getLeaveType().then((result) => {
        if (result && result.data) {
          setLeaveType(
            result.data.map((d) => {
              return { id: d.leave_type_id, label: d.name, desc: d.desc };
            })
          );
        }
      });
    }

  }, [form.endDate]);


  useEffect(() => {
    if (askedDayOff != 0) {
      setSoldePrevisionalAfter(soldePrevisional - askedDayOff);
    }
  }, [soldePrevisional]);


  useEffect(() => {
    setInputStyle({
      border: soldePrevisionalAfter < 0 ? '5px solid red' : '2px solid  rgb(19, 62, 103)'
    })
  }, [soldePrevisionalAfter]);

  useEffect(() => {
    if (form.beginDate !== undefined && form.beginDate.trim().length !== 0) {
      getPrevisionnalBalanceBefore(form.beginDate);
    }

  }, [form.beginDate]);

  // change radio button
  const handleRadioChange = (name) => (event) => {
    if (name === "begin") {
      if (valide === "valide") {
        if (radioBeginDate === event.target.value) {
          Daynumber(form.beginDate, form.endDate, event.target.value, radioEndDate);
          setRadioBeginDate(event.target.value);
          setLeaveValide(0);

        } else {
          Daynumber(form.beginDate, form.endDate, event.target.value, radioEndDate);
          setRadioBeginDate(event.target.value);
          setLeaveValide(1);
          setIsValidEdited(true);
        }
      } else {
        if (form.endDate !== undefined) {
          Daynumber(form.beginDate, form.endDate, event.target.value, radioEndDate);
        }
        setRadioBeginDate(event.target.value);

      }
      // Daynumber(form.beginDate,form.endDate);
    } else {
      if (valide === "valide") {
        if (radioEndDate === event.target.value) {
          Daynumber(form.beginDate, form.endDate, radioBeginDate, event.target.value);
          setRadioEndDate(event.target.value);
          setLeaveValide(0);
        } else {
          Daynumber(form.beginDate, form.endDate, radioBeginDate, event.target.value);
          setRadioEndDate(event.target.value);
          setLeaveValide(1);
          setIsValidEdited(true);
        }
      } else {

        setRadioEndDate(event.target.value);
        if (form.endDate !== undefined) {
          Daynumber(form.beginDate, form.endDate, radioBeginDate, event.target.value);
        }
      }

    }
  };
  const handleCheckChange = () => {
    setIsPriority(!isPriority)
  };
  const handleChangeType = (e) => {
    if (valide === "valide") {
      if (dayOffType === e.target.value) {
        setDayOffType(e.target.value);
        setLeaveValide(0);
      } else {
        setDayOffType(e.target.value);
        setLeaveValide(1);
      }
    } else {
      setDayOffType(e.target.value);
    }
  };
  function handleCancel() {
    navigate("/me/leaves");
  }

  function getPrevisionnalBalanceBefore(beginDate) {
    LeaveService.getPrevisonalBalance(beginDate).then((result) => {
      if (result && result.status) {
        setSoldePrevisional(result.data);
      }
    });
  }


  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });

  }

  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }


  const handleValidCancel = () => {
    setLoading(true);
    setEmptyMotif(false);
    if (raison && raison.trim().length !== 0 && raison !== undefined) {
      LeaveService.getLeaveObselete(raison, codeobselete, fileObselete, idLeaveToValidate).then((result) => {
        // LeaveService.getLeaveType().then((results) => {
        //   if (results && results.data) {
        //     setLoading(false);
        //     setMessage({ ...message, open: true, color: 'green', messageInfo: "Annulé avec succès" });
        //     navigate("/me/leaves");
        //     // setData(results.data);
        //     // LoadTable();
        //   }
        // });
        if (result != "") {
          if (result.status) {
            setCancel(false);
            setRaison("");
            setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.data.message });

            setEmptyMotif(false);
            navigate("/me/leaves");
            // LoadTable();
          } else {
            setMessage({ ...message, openMessage: true, color: 'red', messageInfo: result.message });
            // LoadTable();
          }

          // LoadTable();
        } else {
          setMessage({ ...message, openMessage: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
        }
      }).catch((err) => {
        setMessage({ ...message, openMessage: true, color: 'red', messageInfo: 'erreur' });
      });
    } else {
      setEmptyMotif(true);
      setLoading(false);
    }
  };

  const handleCloseCancel = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setCancel(false);
  };

  function handleRaison(ev) {
    const { name, value } = ev.target;
    setRaison(ev.target.value);
    setEmptyMotif(false);
  };


  function handleCancelingLeave(id) {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false, messageDialog: "Voulez-vous vraiment annuler la demande de congé" });
    setCancel(true);
    setCodeobselete(codeLeave);
    setFileObselete(fileRequest);
    // console.log('voakitika')
  }

  function checkIfDateInDayNotCompted(date) {
    // console.log(day);
    if (day.includes(date)) {
      return true;
    } else {
      return false;
    }
  }


  function handleValidateLeaveDialog() {
    // setisRealRequest(true);
    setloadingValidate(true);
    handleChangeLeave(1);
  }

  function handleJustePlanningDialog() {
    // setisRealRequest(true);
    setloadingPlanning(true);
    handleChangeLeave(0);
  }

  return (
    <MainContainer {...headerDataRH}>
      <ConfirmationDialog
        confirmLeaveAdd={confirmLeaveAdd}
        isCancel={isCancel}
        idLeave={idLeaveToValidate}
        openDialog={openDialog}
        messageDialog={messageDialog}
        closeDialog={closeDialog}
        confirmDialog={handleCancelingLeave}
        cancelDialog={cancelDialog}
        loadingPlanning={loadingPlanning}
        loadingValidate={loadingValidate}
        handleJustePlanningDialog={handleJustePlanningDialog}
        handleValidateLeaveDialog={handleValidateLeaveDialog}
        isValidatorNumberConfigurate={isValidatorNumberConfigurate}
        dayOffNumberWithoutValidation={dayOffNumberWithoutValidation}
        dayOffRequested={askedDayOff} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />

      <MotifAnnulationLeave openDialog={cancel} handleCloseCancel={handleCloseCancel} raison={raison}
        handleRaison={handleRaison} emptyMotif={emptyMotif} isLoading={isLoading} handleValidCancel={handleValidCancel} />
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Paper className={classes.paper} variant="outlined">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <label>Date de départ{bullRed}</label>
            </Grid>
            <Grid item xs={8}>

              <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="dd/MM/yyyy"
                  value={form.beginDate === undefined || form.beginDate === null ? " " : form.beginDate}
                  onChange={handleBeginDateChange}
                  renderInput={(params) =>
                    <TextField {...params} error={validdebut} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" />
                  }
                />
              </LocalizationProvider>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                  value={radioBeginDate}
                  onChange={handleRadioChange("begin")}
                >
                  <FormControlLabel
                    value="morning"
                    control={<Radio color="primary" />}
                    label="Matin"
                  />

                  <FormControlLabel
                    value="afternoon"
                    control={<Radio color="primary" />}
                    label="Après-midi"
                  />
                </RadioGroup>
              </FormControl>
              {validdebut && <Alert severity="error">La date de départ doit être inférieur à la date de retour</Alert>}
              {beginDateNotMatch && <Alert severity="error">L'année de votre départ n'est pas conforme à celle du retour. Veuillez vérifier!</Alert>}
              {dateBeginFormatNotMatch && <Alert severity="warning">La norme demandée pour la date c'est JJ/MM/AAAA). Veuillez vérifier!</Alert>}
              {dateBeginInDayNotCounted && <Alert severity="warning">Votre date de départ est un jour férié ou weekend. Veuillez vérifier!</Alert>}
            </Grid>
            <Grid item xs={4}>
              <label>Date de retour{bullRed}</label>
            </Grid>
            <Grid item xs={8}>
              <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="dd/MM/yyyy"
                  value={form.endDate === undefined ? " " : form.endDate}
                  onChange={handleEndDateChange}
                  renderInput={(params) =>
                    <TextField {...params} error={valid} helperText={errorDate} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" />
                  }
                />
              </LocalizationProvider>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                  value={radioEndDate}
                  onChange={handleRadioChange("end")}
                >
                  <FormControlLabel
                    value="morning"
                    control={<Radio color="primary" />}
                    label="Matin"
                  />

                  <FormControlLabel
                    value="afternoon"
                    control={<Radio color="primary" />}
                    label="Après-midi"
                  />
                </RadioGroup>
              </FormControl>
              {valid && <Alert severity="error">La date de retour doit être superieur à la date de départ</Alert>}
              {dateEndFormatNotMatch && <Alert severity="warning">La norme demandée pour la date c'est JJ/MM/AAAA). Veuillez vérifier!</Alert>}
              {dateEndinDayNotCounted && <Alert severity="warning">Votre date de retour est un jour férié ou weekend. Veuillez vérifier!</Alert>}
            </Grid>

            <Grid item xs={4}>
              <label>Type de congé{bullRed}</label>
            </Grid>

            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined" size="small">
                {/*<InputLabel id="demo-simple-select-outlined-label"></InputLabel>*/}
                <InputLabel id="demo-simple-select-outlined-label">
                  Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Module"
                  onChange={handleChangeType}
                  value={dayOffType}
                  name="leave_type_id"
                >
                  {leave_type.map((row) => (
                    <MenuItem value={row.id} disabled={row.label==="Autres"?true:false}>{row.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Motif de Congé Payé*/}
            {dayOffType === "1" && (
              <>
                <Grid item xs={4}>
                  <label>Motif{bullRed}</label>
                </Grid>

                <Grid item xs={8}>
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    onChange={handleChangeMotif}
                    inputValue={motif}
                    options={leave_type.map((option) => option.desc)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleChangeInputMotif}
                        label="Motif"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <label>Urgence</label>
                </Grid>

                <Grid item xs={8}>
                  <input
                    type="checkbox"
                    checked={isPriority}
                    onChange={handleCheckChange}
                  />
                </Grid>
              </>
            )}
            {/* Motif de Permission*/}
            {dayOffType === "2" && (
              <>
                {/*<Grid container> */}
                <Grid item xs={4}>
                  <label>Motif</label>
                </Grid>

                <Grid item xs={8}>
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    onChange={handleChangeMotif}
                    inputValue={motif}
                    options={leave_type.map((option) => option.desc)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleChangeInputMotif}
                        label="Motif"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            {/*Solde réel avant congé*/}
            <Grid item xs={4}>
              <label>Solde réel avant</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                variant="outlined"
                readOnly={true}
                value={realdelta}
                name="delta"
                onChange={handleChangeDelta}
                disabled={true}
                className={classes.disabledSolde}
              />
            </Grid>
            {/*Nombre de jour demandé */}
            <Grid item xs={4}>
              <label>Nombre de jour demandé</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                variant="outlined"
                value={askedDayOff}
                name="duration"
                readOnly={true}
                disabled={true}
                className={classes.disabledSolde}
              />
            </Grid>

            {/* Solde réel après congé */}
            <Grid item xs={4}>
              <label>Solde réel après</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                variant="outlined"
                readOnly={true}
                value={afterdelta}
                name="balanceAfter"
                disabled={true}
                className={classes.disabledSolde}
                style={inputStyle1}
              //onChange={handleChangeBalanceAfter}
              />
            </Grid>

            {/***************** 12/09/2022: commenter solde prévisionnel selon demande RH *********/}
            {/***************** 27/09/2022: commenter solde prévisionnel selon demande RH *********/}
            {/* Solde prévisionnel avant congé*/}
            {!isConfirmationFinal &&
              <>
                <Grid item xs={4}>
                  <label>Solde prévisionnel avant</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    readOnly={true}
                    value={soldePrevisional}
                    name="delta"
                    disabled={true}
                    className={classes.disabledSolde}
                  //style={inputStyle}
                  />
                </Grid>

                {/* Solde prévisionnel après congé */}
                <Grid item xs={4}>
                  <label>Solde prévisionnel après</label>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    readOnly={true}
                    value={soldePrevisionalAfter}
                    name="balanceAfter"
                    disabled={true}
                    className={classes.disabledSolde}
                    style={inputStyle}
                  //onChange={handleChangeBalanceAfter}
                  />
                </Grid>
              </>
            }

            {/*Test Autocomplete */}
            {valide == "valide" && (
              <>
                <Spacer y={2} />
                <Grid container item xs={12} direction="row-reverse">
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "grey" }}
                    onClick={handleCancel}
                    style={{ fontWeight: 600 }}
                  >
                    ANNULER
                  </Button>
                  <Spacer x={2} />
                  {hasPrivilege(contextData.privileges, 'Admin-Button-Save-Leave') &&
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClick}
                      style={{ fontWeight: 600 }}
                    >
                      {isLoading ? <CircularProgress /> : "CONFIRMER"}
                    </Button>
                  }
                </Grid>
              </>
            )}
            {valide !== "valide" && (
              <>
                <Spacer y={2} />
                <Grid item xs={12}>
                  <label className={classes.text}>({bullRed}):Champs obligatoires</label>
                </Grid>
                {!isRealBalanceAlreadyConfigurate &&
                  <>

                    <Alert severity="warning">{errorMessageForRealBalanceEmpty}</Alert>
                  </>
                }
                <Spacer y={2} />
                <Grid container item xs={12} direction="row-reverse">
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "grey" }}
                    onClick={handleCancel}
                    style={{ fontWeight: 600 }}
                  >
                    ANNULER
                  </Button>
                  <Spacer x={2} />
                  {hasPrivilege(contextData.privileges, 'Admin-Button-Save-Leave') &&
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleClick}
                      disabled={(!isRealBalanceAlreadyConfigurate || dateBeginInDayNotCounted || dateBeginFormatNotMatch || dateEndFormatNotMatch || dateEndinDayNotCounted) ? true : false}
                      style={{ fontWeight: 600 }}
                    >
                      {isLoading ? <CircularProgress /> : "ENREGISTRER"}
                    </Button>
                  }
                </Grid>
              </>
            )}

          </Grid>
        </Paper>
      </MuiPickersUtilsProvider>
    </MainContainer>
  );
}
export default LeaveAdd;