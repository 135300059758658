import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const ContextService = {
    async add(form) {
        try {
            const result = await axios.post(`${apiUrl}/context/saveContext/`,
                {
                    "mission_planning_id": form.mission_planning_id,
                    "description_context": form.context,
                });
            if (result) {
                // TODO: replace result.data.message by the token
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
}
export default ContextService;
