import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const FundingService = {
    async getFunding(){
        try{
            const result = await axios.get(`${apiUrl}/funding`);
            if (result) {
              return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async saveFunding(code, description, funding_id){
        try{
            const result = funding_id?await axios.put(`${apiUrl}/funding/id/${funding_id}`, {
                'code':code, 
                'description':description
           }):await axios.post(`${apiUrl}/funding`, {
                'code':code, 
                'description':description
           });
           if (result) {
             // TODO: replace result.data.message by the token
              return result.data;
           }
        }catch(error){
            if(error.response){
              return error.response.data;
            }
        }
    },
    async deleteFunding(id){
        try {
            const result = await axios.delete(`${apiUrl}/funding/id/${id}`  );
            if (result) {
                // TODO: replace result.data.message by the token
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getFundingById(funding_id){
        try{
            const result = await axios.get(`${apiUrl}/funding/id/${funding_id}`);
            if (result) {
                // TODO: replace result.data.message by the token
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}

export default FundingService;
