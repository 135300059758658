import { Grid,Button } from "@material-ui/core";
import { headerDataState } from "../../data/modules/custom-states-header";
import MainContainer from '../../components/MainContainer';

import Spacer from "../../components/Spacer";
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import LeaveNextWeekTable from "./LeaveNextWeekTable";
import { useNavigate } from 'react-router-dom';

export default function LeaveNextWeek(){
    const navigate = useNavigate();
    function handleLast(){
        navigate("/leave_week");
    }
    return(
        <MainContainer {...headerDataState}>
            <Grid >
                <LeaveNextWeekTable/>
            </Grid>
            <Spacer y={2} />
            <Grid container item xs={12}>
                
                <Button variant="contained" style={{ fontWeight: 600 }} onClick={handleLast}><KeyboardDoubleArrowLeftOutlinedIcon/>Semaine courante</Button>
                <Spacer x={2} />
      
            </Grid>
        </MainContainer>
    )
}