import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import ServiceService from "../../services/ServiceService";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import Message from "../tools/Message";
import { DataGrid } from '@material-ui/data-grid';
import Title from "../../components/Title";

import Spacer from "../../components/Spacer";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function ServiceTable() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [id_to_delete, setId_to_delete] = useState(0);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez vous vraiment supprimer  ?',
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    ServiceService.getService().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.service_id })));
        
        setOpenLoadingSimpleBackdrop(false);
      }else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);
  const handleEdit = (id) => (ev) => {
    navigate("/services/add/" + id);
  };
  const handleDelete = (id) => (ev) => {
    setId_to_delete(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true });
  };
  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    ServiceService.deleteService(id_to_delete).then((result) => {
      ServiceService.getService().then((result) => {
        if (result && result.data) {
          setRows(result.data.map((row) => ({ ...row, id: row.service_id })));
        }
      });
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
        navigate("/services");
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
    });
  }
  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const columns = [
    { field: 'code', headerName: 'Code', width: 150, },
    { field: 'direction_name', headerName: 'Direction/Antenne', width: 200, },
    { field: 'name', headerName: 'Désignation', width: 200, },
    { field: 'description', headerName: 'Description', width: 200, },
    {
      field: 'action',
      headerName: 'Actions',
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <>
          <Button onClick={handleEdit(params.id)} title="Modifier"><CreateTwoTone style={{ color: "black" }} /></Button>
          <Button onClick={handleDelete(params.id)} title="Supprimer" ><DeleteOutlineRounded style={{ color: "red" }} /></Button>
          </>
        );
      },
      width: 189,
    },
  ];
  const fetchData = React.useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
    var newId = fetchIdRef.current++;
    const fetchId = newId;
    (async () => {
      setLoading(true)
      var options = { page: pageIndex + 1, perPage: pageSize }
      options.sort_by = (sortBy).map((e) => e.id + "." + (e.desc ? "desc" : "asc"));
      if (filters !== undefined) {
        filters.forEach(e => options[e.id + "[contains]"] = e.value);
      }
      const result = await ServiceService.getService();
      if (result && result.data) {
        setData(result.data.map((row) => ({ ...row, ['id']: row.service_id })));
        setRowCount(result.count);
        setPageCount(Math.ceil(result.count / pageSize))
      }
      setLoading(false)
    })();
  }, [])

  return (
    <React.Fragment>
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Listes des Services</Title>
      <Spacer y={2} />
      <div style={{ height: "70vh", width: '100%' }}>
        {/* <Table columns={columns} data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} rowCount={rowCount} /> */}
        <DataGrid rows={rows} columns={columns} />
      </div>
    </React.Fragment>
  );
}
