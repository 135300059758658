import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../components/Title";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import PersonnelService from "../../services/PersonnelService";
import { DataGrid } from '@material-ui/data-grid';
import Message from "./Message";
import Result from "./Result";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function PersonnelTable(props) {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [etat, setEtat] = useState({ open: false, message: "message", accept: false });
  const [etats, setEtats] = useState({ open: false, message: "message", color: "" });
  const [isid, setIsid] = useState(0);

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  const classes = useStyles();

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    PersonnelService.getList().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.person_id })));

        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });

  }, [setRows]);

  function handleMessage() {
    setEtat({
      open: false,
      message: ""
    });
  }

  function handleAccept() {
    setEtat({
      open: false,
      message: "",
      accept: true
    });
    PersonnelService.isActiveUser(isid).then((results) => {
      PersonnelService.getList().then((result) => {
        if (result && result.data) {
          setRows(result.data.map((row) => ({ ...row, id: row.person_id })));
        }
      });

      if (results.status) {
        setEtats({ ...etats, open: true, color: 'green', message: results.message });
      }
      else {
        setEtats({ ...etats, open: true, color: 'red', message: results.message });
      }
    });
  }
  const handleCloseMessage = () => {
    setEtats({ ...etats, open: false });
  };
  const handleEdit = (id) => (ev) => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    localStorage.clear();
    localStorage.setItem("ACCESS_TOKEN", token);
    navigate("/personnel/add/" + id);
  };

  function getFullName(params) {
    return `${params.getValue(params.id, 'last_name') || ''} ${params.getValue(params.id, 'first_name') || ''
      }`;
  }
  const handleDelete = (id) => (ev) => {
    setEtat({
      open: true,
      message: "Voulez vous vraiment supprimer  ?"
    });
    setIsid(id);
  };
  const columns = [
    {
      field: 'direction',
      headerName: 'Direction',
      width: 300,
    },
    {
      field: 'fullName',
      headerName: 'Nom et prénom(s)',
      width: 350,
      valueGetter: getFullName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        getFullName(cellParams1).localeCompare(getFullName(cellParams2)),
    },
    {
      field: 'fonction',
      headerName: 'Fonction',
      width: 250,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 250,
    },
    {
      field: 'flotte',
      headerName: 'Flotte',
      width: 250,
    },
    {
      field: 'interne',
      headerName: 'Numero interne',
      width: 250,
    },
    {
      field: 'seat',
      headerName: 'Siège',
      width: 200,
    },
    {
      field: 'building',
      headerName: 'Batiment',
      width: 200,
    },
    {
      field: 'porte',
      headerName: 'Porte',
      width: 200,
    },
    {
      field: 'action',
      headerName: 'Action',
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Button onClick={handleEdit(params.id)}><CreateTwoTone style={{ color: "black" }} />
            </Button>
            <Button onClick={handleDelete(params.id)}>
              <DeleteOutlineRounded style={{ color: "red" }} />
            </Button>
          </div>
        );
      },
      width: 200,
    },
  ];
  return (
    <React.Fragment>
      <Result message={etats.message} color={etats.color} open={etats.open} handleClose={handleCloseMessage} />
      <Message isActive={etat.open} message={etat.message} accept={etat.accept} handleClose={handleMessage} handleConfirm={handleAccept} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Listes des Personnels</Title>
      <div style={{ height: "70vh", width: '100%' }} className={classes.root} >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
        />
      </div>
    </React.Fragment>
  );
}
