import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const ACCESS_TOKEN = "ACCESS_TOKEN";

const NewPassService = {
  async newpassword(token, newPassword, confirmPassword) {
    try {
      const result = await axios.put(`${apiUrl}/ForgotPassword/newPassword`, {
        token,
        newpassword: newPassword,
        confirmpassword: confirmPassword
      });
      if (result) {
        // TODO: replace result.data.message by the token
        localStorage.setItem(ACCESS_TOKEN, result.data.message);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        if(error.response.status === 404){ // Samuela: retour pour token expiré
          return error.response;
        }
        else{
          return error.response.data;
        }
      }
    }
  },
  isNew() {
    return localStorage.getItem(ACCESS_TOKEN) !== undefined;
  },
  async deleteToken(token) {
    try {
      const result = await axios.delete(`${apiUrl}/ForgotPassword/deleteToken/${token}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        if(error.response.status === 404){ // Samuela: retour pour token expiré
          return error.response;
        }
        else{
          return error.response.data;
        }
      }
    }
  },
};

export default NewPassService;
