import React from "react";
import ImmobilisationTable from "./ImmobilisationTable";
import Paper from '@material-ui/core/Paper';
import { Grid } from "@material-ui/core";
import Spacer from "../../components/Spacer";
function ExtratList(props) {
    
  const isGenerateQrCode = props.isGenerateQrCode;
    return (
        <ImmobilisationTable isExtra={1} isGenerateQrCode={isGenerateQrCode}/>
    );
}
export default ExtratList