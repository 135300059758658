import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const ACCESS_TOKEN = "ACCESS_TOKEN";

const MailBoxService = {

    // async send() {
    //     try {
    //         // const result = await axios.post(`${apiUrl}/MailMulti/send`);

    //         // return { success: true };
    //         const token = localStorage.getItem(ACCESS_TOKEN);
    //         const result = await axios.post(`${apiUrl}/MailMulti/send`, {}, {
    //             headers: {
    //                 'Authorization': token,
    //                 // Add any other headers required by the API here
    //             }
    //         });
    //         return { success: true };
    //     } catch (error) {
    //         if (error.response) {
    //             console.log('MISY ERRREUR :', error.response.data);
    //             return { success: false, error: error.response.data };
    //         } else {
    //             console.error('Erreur réseau:', error.message);
    //             return { success: false, error: 'Erreur réseau' };
    //         }
    //     }
    // },


     ////TEST MAIL WITH SUBJECT AND MESSAGE
    // async send(subject, message) {
    //     try {
    //         // const result = await axios.post(`${apiUrl}/MailMulti/send`);

    //         // return { success: true };
    //         const token = localStorage.getItem(ACCESS_TOKEN);
    //         const result = await axios.post(`${apiUrl}/MailMulti/send/`, {subject,message}, {
    //             headers: {
    //                 'Authorization': token,
    //                 // Add any other headers required by the API here
    //             }
    //         });
    //         return { success: true };
    //     } catch (error) {
    //         if (error.response) {
    //             console.log('MISY ERRREUR :', error.response.data);
    //             return { success: false, error: error.response.data };
    //         } else {
    //             console.error('Erreur réseau:', error.message);
    //             return { success: false, error: 'Erreur réseau' };
    //         }
    //     }
    // },


    // async send(subject, message, user) {
    //     try {

    //         // console.log("PJS : ", selectedFiles);

    //         const token = localStorage.getItem(ACCESS_TOKEN);
    //         const result = await axios.post(`${apiUrl}/MailMulti/send/`, {subject,message,user}, {
    //             headers: {
    //                 'Authorization': token,
    //             }
    //         });
    //         return { success: true, check : "success" };

    //     } catch (error) {
    //         if (error.response) {
    //             console.log('MISY ERRREUR :', error.response.data);
    //             // return { success: false, error: error.response.data };
    //             return { success: false, error: error.response.data, check : "error" };

    //         } else {
    //             console.error('Erreur réseau:', error.message);
    //             return { success: false, error: 'Erreur réseau' };
    //         }
    //     }
    // },


    async send(subject, message, user, selectedFiles) {
        try {

            // console.log("PJS : ", selectedFiles);

            const token = localStorage.getItem(ACCESS_TOKEN);
            const result = await axios.post(`${apiUrl}MailMulti/send/`, {subject,message,user, selectedFiles}, {
                headers: {
                    'Authorization': token,
                }
            });
            return { success: true, check : "success" };

        } catch (error) {
            if (error.response) {
                console.log('MISY ERRREUR :', error.response.data);
                // return { success: false, error: error.response.data };
                return { success: false, error: error.response.data, check : "error" };

            } else {
                console.error('Erreur réseau:', error.message);
                return { success: false, error: 'Erreur réseau' };
            }
        }
    },

    async insert(subject,message){
        try {
            const response = await axios.post(`${apiUrl}MailMulti/`, {
                subject,
                message
            });
            console.log("ISA ");
            return response.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            } 
        }
    },

    async getLastMailId(){
        try {
            const result = await axios.get(`${apiUrl}MailMulti/lastMail`  );
            // console.log('USER OHHH : ', localStorage.getItem("USER"));
            if (result) {
                console.log('VERIFICATION DU RESULTAT : ', result.data);
                console.log('VERIFICATION DU RESULTAT TENA IZY : ', result.data.last);
            console.log("ROA ");

              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }  
    },


    async insert_details(idfunction, idLastmail,thisUser,list){
        try {
            const response = await axios.post(`${apiUrl}MailMulti/details`, {
                idfunction,idLastmail,thisUser,list
            });
            console.log("TELO ");

            return response.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            } 
        }
    },

    ///////////////////

    async insert_pj(idLastmail,selectedFiles){
        try {
            const response = await axios.post(`${apiUrl}MailMulti/pj`, {
                idLastmail,selectedFiles
            });
            console.log("TELO ");

            return response.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            } 
        }
    },


    async importPj(formData) {
        try {
          const result = await axios.post(`${apiUrl}MailMulti/import`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log("RESULT PJ : ", result);
          console.log("RESULTAT PJ : ", result.data);
        //   return result.data;
        return true;

        } catch (error) {
          if (error.response) {
            console.log("Error upload : ", error.response.data);
            return error.response.data;
          }
        }
      },



}

export default MailBoxService;