import React from "react";
import ImmoFilter from "./ImmoFilterTable";
import Paper from '@material-ui/core/Paper';
import { Grid } from "@material-ui/core";
import ImmoFilterTable from "./ImmoFilterTable";
function ImmoFilterList(props) {
    const isGenerateQrCode = props.isGenerateQrCode;
    return (
        <ImmoFilterTable isExtra={0} isGenerateQrCode={isGenerateQrCode}/>
    );
}
export default ImmoFilterList