import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { DeleteOutlineRounded, Subject, CalendarToday, CreateTwoTone, Clear, Add, PostAdd, Edit } from "@material-ui/icons";
import { headerDataMission } from "../../../data/modules/mission-header";
import { AntTab, AntTabs } from "../../../components/ant-tab";
import { Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import MissionService from "../../../services/MissionService";
import ObjectifService from "../../../services/ObjectifService";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Message from "../../tools/Message";
import { useContext } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { hasPrivilege } from "../../../utils/privilege";
import { userDetailContext } from "../../../App";
import { useNavigate } from 'react-router-dom';
import ResultatService from "../../../services/ResultatService";
import ContextService from "../../../services/ContextService";
import TacheService from "../../../services/TacheService";
import { set } from "date-fns";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    // maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    width: 750,
    height: 100,
  },
  table: {
    width: "1150px",
    textAlign: "center"
  },
  dialog: {
    width: "1150px",
    height: "1150px",
    size: "1000px",
    position: "center"
  }
}));



function Car(props) {
  return (
    <div>
      <ul>
        <p>
          I am a {props.brand}
          <Button>
            <Edit style={{ color: "black" }} />
          </Button>
          <Button>
            <DeleteOutlineRounded style={{ color: "red" }} />
          </Button>

        </p>
      </ul>
    </div>
  );
}


function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
//

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
    }}
  >
    *
  </Box>
);

function TDRElaborationAdd(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [opens, setOpens] = React.useState(false);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez vous vraiment supprimer  ?',
  });
  const cars = ['Ford', 'BMW', 'Audi'];
  const [id_to_delete, setId_to_delete] = useState(0);
  const [ouvert, setOuvert] = React.useState(false);
  const [dialogue, setDialogue] = React.useState(false);

  const [isValidContext, setIsValidContext] = useState(false);
  const [isValidGoals, setIsValidGoals] = useState(false);
  const [isValidExpected_result, setIsValidExpected_result] = useState(false);
  const [isValidActivity, setIsValidActivity] = useState(false);
  const params = useParams();
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const tdrId = params.tdrId;
  const [isSoldeEmpty, setisSoldeEmpty] = React.useState(false);
  const mission_planning_id = params.id;
  const user_id = localStorage.getItem('USER');
  const [rows, setRows] = useState([]);
  const [id, setId] = useState([]);
  const [idid, setIdid] = useState([]);
  const [attendu, setAttendu] = useState([]);
  const [form, setForm] = useState({
    mission_planning_id: mission_planning_id,
    context: "",

    objectif_id: [],
    description_objectif: "",
    commentaire_objectif: "",
    type_objectif: "",
    taux_objectif: "",
    echelonne_objectif: "",
    qualitatif_objectif: "",
    chiffre_objectif: "",
    mode_mesure_id: "",
    unite_objectif: "",

    resultat_attendu_id: [],
    description_resultat: "",
    commentaire_resultat: "",
    type_resultat: "",
    taux_resultat: "",
    echelonne_resultat: "",
    qualitatif_resultat: "",
    chiffre_resultat: "",
    unite: "",

    description_tache: "",
    user_id: user_id
  });
  const [value, setValue] = useState(0);
  const [next, setNext] = useState([]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePrev = (ev) => {
    if (value == 0) {
      setValue(0);
    } else {
      setValue(value - 1);
    }
  };

  const handleChangeNext = (ev) => {
    if (value == 3) {
      setValue(3);
    } else {
      setValue(value + 1);
    }
  };


  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: ''
  });
  const { open, color, messageInfo } = message;

  const storage = JSON.parse(
    localStorage.getItem("storage")
  );

  useEffect(() => {


    ObjectifService.getById(mission_planning_id).then((result) => {
      if (result && result.data) {
        setRows(result.data);
        console.log(rows + "456");
        console.log(mission_planning_id);
      } else {
        console.log("false erreur");
      }
    })
      .catch((err) => {
      });

    ResultatService.getById(mission_planning_id).then((result) => {
      if (result && result.data) {
        setAttendu(result.data);
        console.log(rows);
      } else {
        console.log("false erreur");
      }
    })
      .catch((err) => {
      });

    setForm({
      mission_planning_id: mission_planning_id,
      context: "",
      description_objectif: "",
      commentaire_objectif: "",
      type_objectif: "choix",
      taux_objectif: "",
      echelonne_objectif: "",
      qualitatif_objectif: "",
      chiffre_objectif: "",
      unite_objectif: "",

      description_resultat: "",
      commentaire_resultat: "",
      type_resultat: "choix",
      taux_resultat: "",
      echelonne_resultat: "",
      qualitatif_resultat: "",
      chiffre_resultat: "",
      unite: "",

      description_tache: "",
      user_id: user_id
    });


    if (tdrId) {
      MissionService.getTDRMission(mission_planning_id, user_id, tdrId).then(({ data: tdr }) => {
        setForm({
          mission_planning_id: mission_planning_id,
          context: tdr.context,
          goals: tdr.goals,
          expected_result: tdr.expected_result,
          activity: tdr.activity,
          user_id: tdr.user_id,
          tdr_mission_id: tdrId
        });
      });
    }
  }, []);

  const handleDelete = (id) => (ev) => {
    setId_to_delete(id);
    console.log("Dialogue non");
    console.log(id);
    setOuvert(true);
  };

  const handleDeleteResultat = (id) => (ev) => {
    setId_to_delete(id);
    console.log("Dialogue non");
    console.log(id);
    setDialogue(true);
  };



  const handleClickClose = () => {
    setOuvert(false);
    setDialogue(false);
  };


  function handleClick() {
    if (form.context === '') {
      setIsValidContext(true);
    }
    if (form.goals === '') {
      setIsValidGoals(true);
    }
    if (form.expected_result === '') {
      setIsValidExpected_result(true);
    }
    if (form.activity === '') {
      setIsValidActivity(true);
    }
    if (form.context !== '' && form.goals !== '' && form.expected_result !== '' && form.activity !== '') {
      MissionService.saveTDRMission(form, form.tdr_mission_id).then((result) => {
        if (result.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          navigate("/mission/elaboration_tdr");
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
        }
      })
        .catch((err) => {
          setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        });
    }
  }



  function handleInsertContext() {
    setOpenLoadingSimpleBackdrop(true);
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    console.log("print");
    console.log(form);

    ContextService.add(form).then((result) => {
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
        setOpenLoadingSimpleBackdrop(true);

      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
        setOpenLoadingSimpleBackdrop(false);

      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      setOpenLoadingSimpleBackdrop(false);

    });
    setForm({
      mission_planning_id: mission_planning_id,
      context: "",
      description_objectif: "",
      commentaire_objectif: "",
      type_objectif: "choix",
      taux_objectif: "",
      echelonne_objectif: "",
      qualitatif_objectif: "",
      chiffre_objectif: "",
      unite_objectif: "",

      description_resultat: "",
      commentaire_resultat: "",
      type_resultat: "",
      taux_resultat: "",
      echelonne_resultat: "",
      qualitatif_resultat: "",
      chiffre_resultat: "",
      unite: "",

      description_tache: "",
      user_id: user_id
    });
      setValue(1);
  }


  // function handleEditObjectif(id) {
  //   console.log(id);
  //   setOpens(true);
  // };


  // const handleEditObjectif = () => {
  //   setOpens(true);
  // };




  function handleStockInsertObjectif() {
    setOpenLoadingSimpleBackdrop(true);
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    console.log("print");
    console.log(form);

    ObjectifService.add(form, form.objectif_id).then((result) => {
      console.log("sds" + id);
      ObjectifService.getById(mission_planning_id).then((res) => {
        if (result.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setRows(res.data);
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
        setOpens(false);
      });
      setForm({
        mission_planning_id: mission_planning_id,
        context: "",
        description_objectif: "",
        commentaire_objectif: "",
        type_objectif: "choix",
        taux_objectif: "",
        echelonne_objectif: "",
        qualitatif_objectif: "",
        chiffre_objectif: "",
        unite_objectif: "",

        description_resultat: "",
        commentaire_resultat: "",
        type_resultat: "",
        taux_resultat: "",
        echelonne_resultat: "",
        qualitatif_resultat: "",
        chiffre_resultat: "",
        unite: "",

        description_tache: "",
        user_id: user_id
      });
      setId([]);
    });
    setOpens(false);

  }


  function handleInsertObjectif() {
    setOpenLoadingSimpleBackdrop(true);
    console.log("form ***");
    console.log(form);
    ObjectifService.insertion(mission_planning_id).then((result) => {
      ObjectifService.getById(mission_planning_id).then((res) => {
        console.log("Je suis " + res.data);
        console.log("Je suis " + mission_planning_id);
        if (result.status && res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setRows(res.data);
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setRows([]);
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
        setOuvert(false);
      });
    });
    setValue(2);
  }



  function handleStockInsertResultat() {
    setOpenLoadingSimpleBackdrop(true);
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    console.log("print *****************");
    console.log(form.resultat_attendu_id);
    ResultatService.add(form, form.resultat_attendu_id).then((result) => {
      ResultatService.getById(mission_planning_id).then((res) => {
        if (result.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setAttendu(res.data);
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
        setOpens(false);
      });
      setForm({
        mission_planning_id: mission_planning_id,
        context: "",
        description_objectif: "",
        commentaire_objectif: "",
        type_objectif: "choix",
        taux_objectif: "",
        echelonne_objectif: "",
        qualitatif_objectif: "",
        chiffre_objectif: "",
        unite_objectif: "",

        description_resultat: "",
        commentaire_resultat: "",
        type_resultat: "",
        taux_resultat: "",
        echelonne_resultat: "",
        qualitatif_resultat: "",
        chiffre_resultat: "",
        unite: "",

        description_tache: "",
        user_id: user_id
      });
      setIdid([]);
    });
    setOpens(false);
  }


  function handleInsertResultat() {
    setOpenLoadingSimpleBackdrop(true);
    ResultatService.insertion().then((result) => {
      ResultatService.getById(mission_planning_id).then((res) => {
        if (result.status && res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setAttendu(res.data);
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setAttendu([]);
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
        setDialogue(false);
      });
    });
    setValue(3);
  }



  function handleInsertTache() {
    setOpenLoadingSimpleBackdrop(true);
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    console.log("print");
    console.log(form);

    TacheService.add(form).then((result) => {
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
        setOpenLoadingSimpleBackdrop(false);
        navigate("/mission/elaboration_tdr");
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
        setOpenLoadingSimpleBackdrop(false);

      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      setOpenLoadingSimpleBackdrop(false);

    });
    setForm({
      mission_planning_id: mission_planning_id,
      context: "",
      description_objectif: "",
      commentaire_objectif: "",
      type_objectif: "choix",
      taux_objectif: "",
      echelonne_objectif: "",
      qualitatif_objectif: "",
      chiffre_objectif: "",
      unite_objectif: "",

      description_resultat: "",
      commentaire_resultat: "",
      type_resultat: "",
      taux_resultat: "",
      echelonne_resultat: "",
      qualitatif_resultat: "",
      chiffre_resultat: "",
      unite: "",

      description_tache: "",
      user_id: user_id
    });
  }




  function handleChange(event) {
    const { name, value } = event.target;
    console.log("**********name" + name + " " + value + form.type_objectif);
    setForm({ ...form, [name]: value });
  }


  function handleChange_resultat(event) {
    const { name, value } = event.target;
    console.log("**********name" + name + " " + value + form.type_resultat);
    setForm({ ...form, [name]: value });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }



  function handleChangeEchelonne(event) {
    const { name, value } = event.target;
    const newValue = value.replace(" ", ",");
    setForm({ ...form, [name]: newValue });
  }


  function handleChangeBalance(ev) {
    const { name, value } = ev.target;
    const newValue = value.replace(/[^0-9.]/g, '');
    setForm({ ...form, [name]: newValue });
    setisSoldeEmpty(false);
  }


  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClose = () => {
    setOpens(false);
  };

  const handleEdit = (id) => (ev) => {
    setOpenLoadingSimpleBackdrop(true);
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  };

  const handleEditObjectif = (id) => (ev) => {
    console.log(id);
    setOpens(true);
    ObjectifService.getId(id).then((res) => {
      if (res.status) {
        console.log(res.data);
        setForm({
          mission_planning_id: mission_planning_id,
          context: "",

          objectif_id: res.data.objectif_id,
          description_objectif: res.data.description,
          commentaire_objectif: res.data.commentaire,
          type_objectif: res.data.libelle_mode_mesure,
          taux_objectif: res.data.valeur,
          echelonne_objectif: res.data.valeur,
          qualitatif_objectif: res.data.valeur,
          chiffre_objectif: res.data.valeur,
          unite_objectif: res.data.unite,
          mode_mesure_id: res.data.mode_mesure_id,

          resultat_attendu_id: "",
          description_resultat: "",
          commentaire_resultat: "",
          type_resultat: "",
          taux_resultat: "",
          echelonne_resultat: "",
          qualitatif_resultat: "",
          chiffre_resultat: "",
          unite: "",

          description_tache: "",
          user_id: user_id
        });
        setOpenLoadingSimpleBackdrop(true);
      } else {
        setForm([]);
        setOpenLoadingSimpleBackdrop(false);
      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      setOpenLoadingSimpleBackdrop(false);
    });
  };


  const handleEditResultat = (id) => (ev) => {
    console.log(id);
    setOpens(true);
    ResultatService.getId(id).then((res) => {
      if (res.status) {
        console.log(res.data);
        setForm({
          mission_planning_id: mission_planning_id,
          context: "",

          objectif_id: "",
          description_objectif: "",
          commentaire_objectif: "",
          type_objectif: "",
          taux_objectif: "",
          echelonne_objectif: "",
          qualitatif_objectif: "",
          chiffre_objectif: "",
          unite_objectif: "",
          mode_mesure_id: res.data.mode_mesure_id,

          resultat_attendu_id: res.data.resultat_attendu_id,
          description_resultat: res.data.description,
          commentaire_resultat: res.data.commentaire,
          type_resultat: res.data.libelle_mode_mesure,
          taux_resultat: res.data.libelle_mode_mesure,
          echelonne_resultat: res.data.valeur,
          qualitatif_resultat: res.data.valeur,
          chiffre_resultat: res.data.valeur,
          unite: res.data.unite,

          description_tache: "",
          user_id: user_id
        });
        setOpenLoadingSimpleBackdrop(true);
      } else {
        setForm([]);
        setOpenLoadingSimpleBackdrop(false);
      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      setOpenLoadingSimpleBackdrop(false);
    });
  };


  const handleToClose = () => {
    setOuvert(false);
    setOpenLoadingSimpleBackdrop(true);
    console.log(id_to_delete);
    ObjectifService.delete(id_to_delete).then((result) => {
      ObjectifService.getById(mission_planning_id).then((res) => {
        if (result.status && res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setRows(res.data);
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setRows([]);
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
        setOuvert(false);
      });
    });
  };



  const handleToCloseResultat = () => {
    setDialogue(false);
    setOpenLoadingSimpleBackdrop(true);
    console.log(id_to_delete);
    ResultatService.delete(id_to_delete).then((result) => {
      ResultatService.getById(mission_planning_id).then((res) => {
        if (result.status && res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setAttendu(res.data);
          setOpenLoadingSimpleBackdrop(true);
        } else {
          setAttendu([]);
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          setOpenLoadingSimpleBackdrop(false);
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        setOpenLoadingSimpleBackdrop(false);
        setDialogue(false);
      });
    });
  };




  const contextData = useContext(userDetailContext);
  return (
    <MainContainer {...headerDataMission}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      {/* <Grid container item xs={12}>
        {hasPrivilege(contextData.privileges, 'Admin-Button-Save-Service') &&
          <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >ENREGISTRER</Button>
        }
      </Grid> */}

      <Spacer y={2} />
      <Paper className={classes.root}>
        <AntTabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary" /*centered*/
        >
          <AntTab label="Contexte et justifcation" />
          <AntTab label="Objectifs" />
          <AntTab label="Résultats atendus" />
          <AntTab label="Tâches" />
        </AntTabs>

        <TabPanel value={value} index={0}>
          <Grid container spacing={3} className={classes.paper}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <label className={classes.labelPosition}>Contexte et justifcation {bullRed}</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5}
                    id="outlined-basic" size="small" value={form.context}
                    label="Context et Justification"
                    variant="outlined" name="context" onChange={handleChange} />
                  {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                </Grid>


                <Grid container item xs={9}>
                  {/* <Button
                  
                    variant="contained" color="secondary"
                    onClick={handleInsertContext}
                    style={{ fontWeight: 600 }} >
                    ENREGISTRER
                  </Button> */}
                </Grid>

                <Grid container item xs={3}>
                  {/* <Button variant="contained" color="primary" onClick={handleClick} style={{ fontWeight: 600 }} >Annuler</Button> */}
                  <Button
                  variant="contained" color="secondary"
                  onClick={handleInsertContext}
                  style={{ fontWeight: 600 }} >
                  ENREGISTRER
                </Button>
                </Grid>

                <Grid container item xs={12}>
                  <hr></hr>
                  <Button
                    name="prev"
                    value={value}
                    onClick={handleChangePrev}>
                    Prev
                  </Button>
                  <Button
                    name="next"
                    value={value}
                    onClick={handleChangeNext}>
                    Next
                  </Button>
                </Grid>

              </Grid>
              <br />
            </Grid>
          </Grid>
        </TabPanel>



        <TabPanel value={value} index={1}>
          <Grid container spacing={3} className={classes.paper}>
            <Grid item xs={12}>
              <fieldset>
                <legend>Objectif</legend>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h1>
                      <Button title="Ajout d'observation" onClick={handleClickOpen}>
                        <Add style={{ color: "green" }} />
                      </Button>
                    </h1>
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>Commentaire</th>
                            <th>Qualitatif</th>
                            <th>Valeur</th>
                            <th>Unité</th>
                            <th> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((row) => (
                            <tr>
                              <td>{row.description}</td>
                              <td>{row.commentaire}</td>
                              <td>{row.libelle_mode_mesure}</td>
                              <td>{row.valeur}</td>
                              <td>{row.unite}</td>
                              <td>
                                <Button title="modifier l'observation"
                                  onClick={handleEditObjectif(row.objectif_id)}
                                >
                                  <Edit style={{ color: "black" }} />
                                </Button>
                                <Button title="supprimer l'observation"
                                  onClick={handleDelete(row.objectif_id)}
                                >
                                  <DeleteOutlineRounded style={{ color: "red" }} />
                                </Button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>

                  <Dialog open={ouvert}
                    // onClose={handleToClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <DialogContentText>
                        voulez-vous vraiment supprimer ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClickClose}
                        variant="contained" color="secondary">
                        Annuler
                      </Button>
                      <Button
                        onClick={handleToClose}
                        variant="contained" color="primary">
                        valider
                      </Button>
                    </DialogActions>
                  </Dialog>


                  <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={opens}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Observation
                    </DialogTitle>
                    <DialogContent
                      sx={{ height: "40vh" }}>
                      {/* <Grid item xs={3}>
                    <Button title="Ajout d'observation" onClick={handleInsertObjectif}>
                      <Add style={{ color: "green" }} />
                    </Button>
                  </Grid> */}

                      <Grid item xs={3}>
                        <label className={classes.labelPosition}>Description {bullRed}</label>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5}
                          id="outlined-basic" size="small" label="Description" variant="outlined"
                          value={form.description_objectif} name="description_objectif" onChange={handleChange} />
                        {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                      </Grid>

                      <Grid item xs={3}>
                        <label className={classes.labelPosition}>Commentaire </label>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic"
                          size="small" label="Commentaire" variant="outlined" value={form.commentaire_objectif} name="commentaire_objectif" onChange={handleChange} />
                        {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                      </Grid>

                      <Grid item xs={3}>
                        <label className={classes.labelPosition}>Mode mesure {bullRed}</label>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                          <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Type" name="type_objectif"
                            value={form.type_objectif} onChange={handleChange}>
                            <MenuItem value="choix">Type ...</MenuItem>
                            <MenuItem value="taux">Taux</MenuItem>
                            <MenuItem value="echélonné">Echélonné</MenuItem>
                            <MenuItem value="qualitatif">Qualitatif</MenuItem>
                            <MenuItem value="chiffre">Chiffre</MenuItem>
                          </Select>
                        </FormControl>
                        {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                      </Grid>

                      {form.type_objectif === "taux" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}>. Taux {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField multiline className={classes.text} width="auto" rows={1} rowsMax={5} id="outlined-basic"
                              size="small" value={100} variant="outlined" name="taux_objectif" onChange={handleChange} />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}

                      {form.type_objectif === "echélonné" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}> . Echélonné {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5}
                              id="outlined-basic" size="small" label="mot séparé par une virgule" value={form.echelonne_objectif} variant="outlined"
                              name="echelonne_objectif" onChange={handleChangeEchelonne} />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}


                      {form.type_objectif === "qualitatif" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}> . Qualitatif {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5}
                              id="outlined-basic" size="small" value={form.qualitatif_objectif} label="Qualitatif" variant="outlined"
                              name="qualitatif_objectif" onChange={handleChange} />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}



                      {form.type_objectif === "chiffre" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}> . Chiffre {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField
                              fullWidth
                              className={classes.text}
                              error={isSoldeEmpty}
                              id="outlined-basic"
                              size="small"
                              label="Seulement des chiffres"
                              variant="outlined"
                              value={form.chiffre_objectif}
                              name="chiffre_objectif"
                              onChange={handleChangeBalance}
                            />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}

                      <Grid>
                        <Grid item xs={3}>
                          <label className={classes.labelPosition}> Unité {bullRed}</label>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5}
                            id="outlined-basic" size="small" value={form.unite_objectif} label="Unité" variant="outlined"
                            name="unite_objectif" onChange={handleChange} />
                          {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                        </Grid>
                      </Grid>

                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleStockInsertObjectif}>Enregistrer</Button>
                      <Button onClick={handleClose} autoFocus>
                        Annuler
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Grid container item xs={9}>
                    {/* <Button
                      variant="contained" color="secondary"
                      onClick={handleInsertTache}
                      style={{ fontWeight: 600 }} >
                      ENREGISTRER
                    </Button> */}
                  </Grid>

                  <Grid container item xs={3}>
                    {/* <Button variant="contained" color="primary" onClick={handleClick} style={{ fontWeight: 600 }} >Annuler</Button> */}
                    <Button
                      variant="contained" color="secondary"
                      onClick={handleInsertObjectif}
                      style={{ fontWeight: 600 }} >
                      ENREGISTRER
                    </Button>
                  </Grid>

                  <Grid container item xs={12}>
                    <hr></hr>
                    <Button
                      name="prev"
                      value={value}
                      onClick={handleChangePrev}>
                      Prev
                    </Button>
                    <Button
                      name="next"
                      value={value}
                      onClick={handleChangeNext}>
                      Next
                    </Button>
                  </Grid>

                  {/* <Grid container item xs={3}>
                    <Button variant="contained" color="primary"
                      onClick={handleClick}
                      style={{ fontWeight: 600 }}
                    >
                      Annuler
                    </Button>
                  </Grid> */}

                </Grid>
                <br />
              </fieldset>
            </Grid>
          </Grid>
        </TabPanel>


        <TabPanel value={value} index={2}>
          <Grid container spacing={3} className={classes.paper}>

            <Dialog
              open={dialogue}
              // onClose={handleToClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText>
                  voulez-vous vraiment supprimer ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClickClose}
                  variant="contained" color="secondary">
                  Annuler
                </Button>
                <Button
                  onClick={handleToCloseResultat}
                  variant="contained" color="primary">
                  valider
                </Button>
              </DialogActions>
            </Dialog>

            <Grid item xs={12}>
              <fieldset>
                <legend>Résultat attendu</legend>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h1>
                      <Button title="Ajout d'observation" onClick={handleClickOpen}>
                        <Add style={{ color: "green" }} />
                      </Button>
                    </h1>
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>Commentaire</th>
                            <th>Qualitatif</th>
                            <th>Valeur</th>
                            <th>Unité</th>
                            <th> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {attendu.map((row) => (
                            <tr>
                              <td>{row.description}</td>
                              <td>{row.commentaire}</td>
                              <td>{row.libelle_mode_mesure}</td>
                              <td>{row.valeur}</td>
                              <td>{row.unite}</td>
                              <td>
                                <Button title="modifier l'observation"
                                  onClick={handleEditResultat(row.resultat_attendu_id)}
                                >
                                  <Edit style={{ color: "black" }} />
                                </Button>
                                <Button title="supprimer le résultat attendu"
                                  onClick={handleDeleteResultat(row.resultat_attendu_id)}
                                >
                                  <DeleteOutlineRounded style={{ color: "red" }} />
                                </Button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>

                  <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={opens}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Résultat
                    </DialogTitle>
                    <DialogContent
                    sx={{ height: "40vh" }}
                    >
                      <Grid item xs={3}>
                        <label className={classes.labelPosition}>Description {bullRed}</label>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic"
                          size="small" label="Résultat attendus" variant="outlined"
                          value={form.description_resultat} name="description_resultat" onChange={handleChange} />
                        {isValidExpected_result && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                      </Grid>


                      <Grid item xs={3}>
                        <label className={classes.labelPosition}>Commentaire </label>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic"
                          size="small" label="Commentaire" variant="outlined"
                          value={form.commentaire_resultat} name="commentaire_resultat" onChange={handleChange} />
                        {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                      </Grid>

                      <Grid item xs={3}>
                        <label className={classes.labelPosition}>Mode mesure {bullRed}</label>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                          <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined"
                            label="Type" name="type_resultat" value={form.type_resultat} onChange={handleChange_resultat}>
                            <MenuItem value="choix">Type ...</MenuItem>
                            <MenuItem value="taux">Taux</MenuItem>
                            <MenuItem value="echélonné">Echélonné</MenuItem>
                            <MenuItem value="qualitatif">Qualitatif</MenuItem>
                            <MenuItem value="chiffre">Chiffre</MenuItem>
                          </Select>
                        </FormControl>
                        {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                      </Grid>

                      {form.type_resultat === "taux" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}>. Taux {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField multiline className={classes.text} width="auto" rows={1} rowsMax={5} id="outlined-basic" size="small" value={100}
                              variant="outlined" name="taux_resultat" onChange={handleChange} />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}

                      {form.type_resultat === "echélonné" && (
                        <Grid>

                          <Grid item xs={3}>
                            <label className={classes.labelPosition}> . Echélonné {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic" size="small"
                              label="mot séparé par une virgule" variant="outlined"
                              value={form.echelonne_resultat} name="echelonne_resultat" onChange={handleChangeEchelonne} />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}


                      {form.type_resultat === "qualitatif" && (
                        <Grid>

                          <Grid item xs={3}>
                            <label className={classes.labelPosition}> . Qualitatif {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic" size="small"
                              label="Qualitatif" variant="outlined"
                              value={form.qualitatif_resultat} name="qualitatif_resultat" onChange={handleChange} />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}



                      {form.type_resultat === "chiffre" && (
                        <Grid>
                          <Grid item xs={3}>
                            <label className={classes.labelPosition}> . Chiffre {bullRed}</label>
                          </Grid>
                          <Grid item xs={9}>
                            <TextField
                              fullWidth
                              error={isSoldeEmpty}
                              id="outlined-basic"
                              size="small"
                              label="Seulement des chiffres"
                              variant="outlined"
                              value={form.chiffre_resultat}
                              name="chiffre_resultat"
                              onChange={handleChangeBalance}
                            />
                            {isValidGoals && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                          </Grid>
                        </Grid>
                      )}


                      <Grid item xs={3}>
                        <label className={classes.labelPosition}>Unité {bullRed}</label>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic" size="small" label="Résultat attendus"
                          variant="outlined" name="unite" value={form.unite} onChange={handleChange} />
                        {isValidExpected_result && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                      </Grid>

                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleStockInsertResultat}>Enregistrer</Button>
                      <Button onClick={handleClick} autoFocus>
                        Annuler
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Grid container item xs={9}>
                    {/* <Button
                      variant="contained" color="secondary"
                      onClick={handleInsertTache}
                      style={{ fontWeight: 600 }} >
                      ENREGISTRER
                    </Button> */}
                  </Grid>

                  <Grid container item xs={3}>
                    {/* <Button variant="contained" color="primary" onClick={handleClick} style={{ fontWeight: 600 }} >Annuler</Button> */}
                    <Button
                      variant="contained" color="secondary"
                      onClick={handleInsertResultat}
                      style={{ fontWeight: 600 }} >
                      ENREGISTRER
                    </Button>
                  </Grid>


                  <Grid container item xs={12}>
                    <hr></hr>
                    <Button
                      name="prev"
                      value={value}
                      onClick={handleChangePrev}>
                      Prev
                    </Button>
                    <Button
                      name="next"
                      value={value}
                      onClick={handleChangeNext}>
                      Next
                    </Button>
                  </Grid>

                  {/* <Grid container item xs={3}>
                    <Button
                      variant="contained" color="primary"
                      onClick={handleClick}
                      style={{ fontWeight: 600 }} >
                      Annuler
                    </Button>
                  </Grid> */}

                </Grid>
                <br />
              </fieldset>


            </Grid>
          </Grid>
        </TabPanel>



        <TabPanel value={value} index={3}>
          <Grid container spacing={3} className={classes.paper}>
            <Grid item xs={12}>
              <fieldset>
                <legend>Tâche à faire</legend>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <label className={classes.labelPosition}>Description {bullRed}</label>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic" size="small" label="Description"
                      variant="outlined" value={form.description_tache} name="description_tache" onChange={handleChange} />
                    {isValidActivity && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                  </Grid>

                  <Grid container item xs={9}>
                    {/* <Button
                      variant="contained" color="secondary"
                      onClick={handleInsertTache}
                      style={{ fontWeight: 600 }} >
                      ENREGISTRER
                    </Button> */}
                  </Grid>

                  <Grid container item xs={3}>
                    {/* <Button variant="contained" color="primary" onClick={handleClick} style={{ fontWeight: 600 }} >Annuler</Button> */}
                    <Button
                      variant="contained" color="secondary"
                      onClick={handleInsertTache}
                      style={{ fontWeight: 600 }} >
                      ENREGISTRER
                    </Button>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <hr></hr>
                  <Button
                    name="prev"
                    value={value}
                    onClick={handleChangePrev}>
                    Prev
                  </Button>
                  <Button
                    name="next"
                    value={value}
                    onClick={handleChangeNext}>
                    Next
                  </Button>
                </Grid>

                <br />
              </fieldset>
            </Grid>
          </Grid>
        </TabPanel>

      </Paper>
    </MainContainer >
  );
}

export default TDRElaborationAdd;
