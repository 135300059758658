import axios from "axios";

const apiMISUrl = process.env.REACT_APP_API_MISURL;
const LocalisationMisService = {
    async getAllDirection() {
        try {
            const result = await axios.get(`${apiMISUrl}/directions`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getRegionsByDirection(id_direction) {
        try {
            const result = await axios.get(`${apiMISUrl}/regions`, {
                params: {
                    direction: id_direction
                }
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getDistrictByRegion(id_region) {
        try {
            const result = await axios.get(`${apiMISUrl}/districts`, {
                params: {
                    region: id_region
                }
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getCommunesByDistrict(id_district) {
        try {
            const result = await axios.get(`${apiMISUrl}/communes`, {
                params: {
                    district: id_district
                }
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getFokontantByCommune(id_commune) {
        try {
            const result = await axios.get(`${apiMISUrl}/fokontanys`, {
                params: {
                    commune: id_commune
                }
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}
export default LocalisationMisService;