import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import InventoryService from "../../services/InventoryService";
import Title from "../../components/Title";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArticleIcon from "@mui/icons-material/Article";
import { style } from "@mui/system";
import { id } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import Message from "../tools/Message";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  style1: {
    backfaceVisibility: "cyan",
  },
}));
export default function InventoryMineTable() {
  const [rows, setRows] = useState([]);
  const [immoArray, setImmoArray] = useState([]);
  const classes = useStyles();
  const navigate = useNavigate();

  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =React.useState(false);
  const [message, setMessage] = useState({
      open: false,
      color: 'green',
      messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  function loadTable() {
    setOpenLoadingSimpleBackdrop(true);
    InventoryService.getMineImmoHistory().then((result) => {
      if (result && result.status) {
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.history_id,
            id2: row.code_immo_id,
            code: row.code_immo,
          }))
        );
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }

  useEffect(() => {
    loadTable();
  }, []);

  const columns = [
    {
      field: "code_immo",
      headerName: "Code Immo",
      width: 200,
    },
    {
      field: "name",
      headerName: "Matériel",
      width: 250,
    },
    {
      field: "state",
      headerName: "Etat matériel",
      width: 170,
      renderCell: (params) => {
        if (params.row.state == "TRES BON ETAT") {
          return (
            <div
              style={{
                backgroundColor: "rgb(52, 168, 168, 0.747)",
                width: 200,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "BON ETAT") {
          return (
            <div
              style={{
                backgroundColor: "rgb(58, 139, 58)",
                width: 200,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "MOYEN ETAT") {
          return (
            <div
              style={{
                background: "rgb(167, 167, 55)",
                width: 200,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "MAUVAIS ETAT") {
          return (
            <div
              style={{
                background: "rgb(167, 54, 54)",
                width: 200,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {params.row.state}
            </div>
          );
        }
      },
    },
    {
      field: "date_inventaire",
      headerName: "Date inventaire",
      width: 170,
    },
    {
      field: "observation",
      headerName: "Observation",
      width: 200,
    },
    {
      field: "logistics_manager",
      headerName: "Responsable logistique",
      width: 250,
    },
  ];
  return (
    <>
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />

      <Title>Liste de(s) matériel(s) dont vous êtes affecté(s)</Title>
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </>
  );
}
