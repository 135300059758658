import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import StockInventoryService from "../../services/StockInventoryService";
import Title from "../../components/Title";
import Button from "@material-ui/core/Button";

export default function StockTable() {
  const [rows, setRows] = useState([]);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [immoArray, setImmoArray] = useState([]);

  function loadTable(name_materiel,code_immo,state) {
    setOpenLoadingSimpleBackdrop(true);
    StockInventoryService.getHistory(name_materiel,code_immo,state).then((result) => {
      if (result && result.status) {
        console.log("result******", result);
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.history_id,
          }))
        );
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }
  function initalizeStorage(name){
    if(localStorage.getItem(name)){
      localStorage.setItem(name,localStorage.getItem(name));
      console.log("ambony");
     }
    else{
      localStorage.setItem(name, "");
      console.log("ambany");
    }
  }
  useEffect(() => {
    initalizeStorage('name_materiel');
    initalizeStorage('code_immo');
    initalizeStorage('state');

  const name_materiel = localStorage.getItem('name_materiel');
  const code_immo = localStorage.getItem('code_immo');
  const state = localStorage.getItem('state');
  loadTable(name_materiel,code_immo,state);
  }, []);  

  const handleChecked = (id) => (ev) => {
    console.log("id param: ", id, ev.target.checked);
    if(ev.target.checked == true){
      immoArray.push(id);
    }
    else{
      immoArray.pop(id);
    }    
    setImmoArray(immoArray => [...immoArray]);
    localStorage.setItem("immoArrayStorage", immoArray);
    console.log(immoArray);
  };

  const columns = [
    {
      field: "code_immo",
      headerName: "Code Immo",
      width: 250,
    },
    {
      field: "name",
      headerName: "Matériel",
      width: 250,
    },
    {
      field: "address",
      headerName: "Adresse de stockage",
      width: 250,
    },
    {
      field: "state",
      headerName: "Etat matériel",
      width: 250,
      renderCell: (params) => {
        if (params.row.state == "TRES BON ETAT") {
          return (
            <div
              style={{ background: "cyan", width: 250, textAlign: "center" }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "BON ETAT") {
          return (
            <div
              style={{ background: "lime", width: 250, textAlign: "center" }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "MOYEN ETAT") {
          return (
            <div
              style={{ background: "yellow", width: 250, textAlign: "center" }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "MAUVAIS ETAT") {
          return (
            <div style={{ background: "red", width: 250, textAlign: "center" }}>
              {params.row.state}
            </div>
          );
        }
      },
    },
    {
      field: "date_inventaire",
      headerName: "Date inventaire",
      width: 250,
    },
    {
      field: "observation",
      headerName: "Observation",
      width: 250,
    },
    {
      field: "action",
      headerName: "Action",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Button>
              <input type="checkbox" onChange={handleChecked(params.row.code_immo)} title="Affectation"/>
            </Button>
          </div>
        );
      },
      width: 200,
    },
  ];
  return (
    <>
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />

      <Title>Matériel stocké dans le magasin</Title>
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
        />
      </div>
    </>
  );
}
