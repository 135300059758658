import { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Message from "../tools/Message";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import ImmobilisationService from "../../services/ImmobilisationService";
import { useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import InventoryService from "../../services/InventoryService";
import ValidateRequest from "../tools/ValidateImmobilisationStepRequest";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function InventoryValidation(props) {
    const navigate = useNavigate();    
    const openDialog = props.open;
    const closeDialog = props.closeDialog;
    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState({
        open: false,
        color: 'green',
        messageInfo: '',
    });
    const { open, color, messageInfo } = message;
    const [openValidateRequest, setOpenValidateRequest] = useState(false);

    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }

    function handleClickReinit() {
        window.location.reload();
    }

    function handleFirstValidatation() {
        var allImmoSelected = props.dataImmo;
        localStorage.setItem("allImmoSelectedIdStorage", JSON.stringify(allImmoSelected));
        if(props.isAffecation){
            localStorage.setItem("validationAffectation",true);
        }
        else{
            localStorage.setItem("firstValidationStorage",true);
        }
        setOpenValidateRequest(true);
    }

    const handleCloseValidateRequest = (event) => {
        setOpenValidateRequest(false);
    }
    
    return (
        <>
        <div>
            <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="lg">
                <DialogTitle id="form-dialog-title" color="primary">{"Validation par le responsable logistique"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {" Liste des immobilisations à valider: "}<br />
                    </DialogContentText>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Code</TableCell>
                                    <TableCell align="left">Matériel</TableCell>
                                    <TableCell align="left">Date d'acquisition</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {props.dataImmo.map((row) => (
                                <TableRow>
                                    <TableCell align="left">{row.code_immo_code}</TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.date_acquis}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </DialogContent>
                <DialogActions>                    
                    <Button variant="contained" onClick={handleFirstValidatation}   style={{ backgroundColor:"green", color:"#FFFFFF" }}>Valider</Button>
                    <Button variant="contained" onClick={handleClickReinit} >Réinitialiser la liste</Button>
                    <Button variant="contained" color="secondary" onClick={closeDialog} >Annuler</Button>
                </DialogActions>
            </Dialog>
        </div>

        <ValidateRequest
        openValidateRequest={openValidateRequest}
        handleCloseValidateRequest={handleCloseValidateRequest} />
        </>
    );
}

