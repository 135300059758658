import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Paper } from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header"; 
import PlanningMissionTable from "./PlanningMissionTable";
import Planning from "../../tools/planning";
import RequestValidationService from "../../../services/RequestValidationService";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function PlanningMissionList() {
  const navigate = useNavigate();
  const classes = useStyles();
  function handleClick() {
    navigate("/mission/planning_mission/add");
  }
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez allez valider la planification du mission de la date du',
  });
  const { openDialog, messageDialog } = confirmationDialog;
  function handelClickConfirmation()
  {
    setConfirmationDialog({ ...confirmationDialog, openDialog: true });
  }
  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
 function confirmDialog()
 {
    RequestValidationService.save();
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
 }
 function cancelDialog() {
  setConfirmationDialog({ ...confirmationDialog, openDialog: false });
}

  return (
    <MainContainer {...headerDataMission}>
     <Planning openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <Grid container item xs={12}>
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >CREER</Button>
         <Spacer x={112} />
         {/* <Button
          variant="contained"
          color="secondary"
          onClick={handelClickConfirmation}
          style={{ fontWeight: 600 }}
        >
          VALIDER PLANIFICATION
        </Button>  */}
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
          <PlanningMissionTable />
        </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
