import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import InventoryService from "../../services/InventoryService";
import Title from "../../components/Title";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArticleIcon from "@mui/icons-material/Article";
import { style } from "@mui/system";
import { id } from "date-fns/locale";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  style1: {
    backfaceVisibility: "cyan",
  },
}));
export default function InventoryTable() {
  const [rows, setRows] = useState([]);
  const [immoArray, setImmoArray] = useState([]);
  const classes = useStyles();
  const navigate = useNavigate();

  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    React.useState(false);

  function loadTable(name_materiel,code_immo,state) {
    setOpenLoadingSimpleBackdrop(true);
    InventoryService.getHistory(name_materiel,code_immo,state).then((result) => {
      if (result && result.status) {
        console.log("result******", result);
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.history_id,
            id2: row.code_immo_id,
            code: row.code_immo,
          }))
        );
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }
  function initalizeStorage(name){
    if(localStorage.getItem(name)){
      localStorage.setItem(name,localStorage.getItem(name));
      console.log("ambony");
     }
    else{
      localStorage.setItem(name, "");
      console.log("ambany");
    }
  }
  const handleChecked = (id) => (ev) => {
    console.log("id param: ", id, ev.target.checked);
    if(ev.target.checked == true){
      immoArray.push(id);
    }
    else{
      immoArray.pop(id);
    }    
    setImmoArray(immoArray => [...immoArray]);
    localStorage.setItem("immoArrayStorage", immoArray);
    console.log(immoArray);
  };

  useEffect(() => {
    localStorage.removeItem("immoArrayStorage", immoArray);
    initalizeStorage('name_materiel');
      initalizeStorage('code_immo');
      initalizeStorage('state');

    const name_materiel = localStorage.getItem('name_materiel');
    const code_immo = localStorage.getItem('code_immo');
    const state = localStorage.getItem('state');
    loadTable(name_materiel,code_immo,state);
  }, []);


  const handleSelect = (id2, code) => (ev) => {
    navigate("/Logistic/recap/" + id2);
    localStorage.setItem("my_id", id2);
    localStorage.setItem("codeI", code);
  };
  const columns = [
    {
      field: "code_immo",
      headerName: "Code Immo",
      width: 250,
    },
    {
      field: "name",
      headerName: "Matériel",
      width: 250,
    },
    {
      field: "nom",
      headerName: "Détenteur",
      width: 250,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "direction",
      headerName: "Direction",
      width: 150,
    },
    
    {
      field: "service",
      headerName: "Service",
      width: 150,
    },
    {
      field: "porte",
      headerName: "Porte",
      width: 150,
    },
    {
      field: "state",
      headerName: "Etat matériel",
      width: 250,
      renderCell: (params) => {
        if (params.row.state == "TRES BON ETAT") {
          return (
            <div
              style={{
                backgroundColor: "rgb(52, 168, 168, 0.747)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "BON ETAT") {
          return (
            <div
              style={{
                backgroundColor: "rgb(58, 139, 58)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "MOYEN ETAT") {
          return (
            <div
              style={{
                background: "rgb(167, 167, 55)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {params.row.state}
            </div>
          );
        } else if (params.row.state == "MAUVAIS ETAT") {
          return (
            <div
              style={{
                background: "rgb(167, 54, 54)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              {params.row.state}
            </div>
          );
        }
      },
    },

    {
      field: "date_inventaire",
      headerName: "Date inventaire",
      width: 250,
    },
    {
      field: "observation",
      headerName: "Observation",
      width: 250,
    },
    {
      field: "period",
      headerName: "Période",
      width: 250,
    },
    {
      field: "status_by_the_logistics_manager",
      headerName: "Validation Responsable Logistique",
      width: 250,
      renderCell: (params) => {
        if (params.row.status_by_the_logistics_manager == "PENDING") {
          return (
            <div
              style={{
                background: "rgb(167, 54, 54)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              En attente de validation
            </div>
          );
        }else if (params.row.status_by_the_logistics_manager == "CONFIRMED") {
          return (
            <div
              style={{
                backgroundColor: "rgb(58, 139, 58)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              Validé
            </div>
          );
        } else {
          return (
            <div
              style={{
                background: "rgb(167, 54, 54)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              Non validé
            </div>
          );
        }
      },
    },
    {
      field: "status_by_the_holder",
      headerName: "Validation détenteur",
      width: 250,
      renderCell: (params) => {
        if (params.row.status_by_the_holder == "PENDING") {
          return (
            <div
              style={{
                background: "rgb(167, 54, 54)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              En attente de validation
            </div>
          );
        }else if (params.row.status_by_the_holder == "CONFIRMED") {
          return (
            <div
              style={{
                backgroundColor: "rgb(58, 139, 58)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              Validé
            </div>
          );
        } else {
          return (
            <div
              style={{
                background: "rgb(167, 54, 54)",
                width: 250,
                textAlign: "center",
                color: "rgb(248, 248, 248)",
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              Non validé
            </div>
          );
        }
      },
    },
    {
      field: "historique",
      headerName: "Historique d'inventaire",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Button
              onClick={handleSelect(params.row.id2, params.row.code)}
              title="Details inventaire"
            >
              <ArticleIcon style={{ color: "black" }} />
            </Button>
          </div>
        );
      },
      width: 200,
    },
    {
      field: "action",
      headerName: "Action",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Button>
              <input type="checkbox" onChange={handleChecked(params.row.code_immo)} title={params.row.code_immo}/>
            </Button>
          </div>
        );
      },
      width: 200,
    },
  ];
  return (
    <>
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />

      <Title>Matériel affecté</Title>
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </>
  );
}
