export const headerDataRH = {
    title: 'Ressources humaines',
    submodules: [
        createData(0, "Planning de congé", "/me/leaves"),
        // createData(1, "Gestion de congé", "/hr/leaves"),
        createData(2, "Gestion du personnel", "/personnel"),
        createData(3, "Annuaire", "/annuaire"),
        createData(4, "Information sur la Famille", "/information_famille"),
    ],
}

function createData(id, moduleName, ref) {
    return { id, moduleName, ref };
}