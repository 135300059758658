import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const SeatService = {

  async getBuildingBySeat(id) {
    try {
      const result = await axios.get(`${apiUrl}/building/getBuildingBySeat/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async getAllSeat() {
    try {
      const result = await axios.get(`${apiUrl}/seat/`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,

  async getAllDirection() {
    try {
      const result = await axios.get(`${apiUrl}/building/allDirection/`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getAsc() {
    try {
      const result = await axios.get(`${apiUrl}/seat/asc`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,


  async SeatById(id) {
    try {
      const result = await axios.get(`${apiUrl}/seat/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,


  async saveSeat(seat_name, direction_id, id) {
    try {
      const result = id ?
        await axios.put(`${apiUrl}/seat/${id}`, {
          id: id,
          seat_name: seat_name,
          direction_id: direction_id,
        })
        :
        await axios.post(`${apiUrl}/seat`, {
          id: id,
          seat_name: seat_name,
          direction_id: direction_id
        });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async directionById(id) {
    try {
      const result = await axios.get(`${apiUrl}/direction/id/${id}`);
      if (result) {
        // console.log("result ", result);
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async getDirection() {
    try {
      const result = await axios.get(`${apiUrl}/direction/`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,

  async Direction(id) {
    try {
      const result = await axios.get(`${apiUrl}/Seat/direction/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,

  async deleteSeat(id) {
    try {
      const result = await axios.delete(`${apiUrl}/seat/id/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getSeat() {
    try {
      const result = await axios.get(`${apiUrl}/seats/`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
}
export default SeatService;