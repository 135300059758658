//import React from 'react';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Title from "../../../components/Title";
import {
  CancelOutlined,
  CreateTwoTone,
  DeleteOutlineRounded,
  DoneTwoTone,
  SettingsSystemDaydreamTwoTone,
} from "@material-ui/icons";
import LeaveService from "../../../services/LeaveService";
import { escapeRegExp } from "../../../utils";
import { useNavigate } from "react-router-dom";
import CustomToolbar from "../../../components/CustomToolbar";
import { DataGrid, GridToolbarExport } from "@material-ui/data-grid";
import CheckIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import RemoveIcon from "@material-ui/icons/Remove";
import FileService from "../../../services/FileService";
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment, { isDuration } from "moment";
import Grid from "@material-ui/core/Grid";
import {
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress
} from "@material-ui/core";

import { Document } from "react-pdf";
//import Message from "../../personnel/Message";

import ConfirmationDialog from "../../tools/ConfirmationDialog";
import Message from "../../tools/Message";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import Spacer from "../../../components/Spacer";
import Loading from "../../../components/loading";
import Alert from "@material-ui/lab/Alert";
import MotifAnnulationLeave from "../../tools/MotifAnnulationLeave";
import UserService from "../../../services/UserService";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  id: {
    color: "#3f51b5",
    fontWeight: "bold",
  },
  actualBalance: {
    textAlign: "right",
    margin: "auto"
  }
}));
//io 

export default function LeaveTable(props) {
  const [id_to_delete, setId_to_delete] = useState(0);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'voulez vous supprimer?',
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    openMessage: false,
    color: 'green',
    messageInfo: '',
  });
  const { openMessage, color, messageInfo } = message;
  const [iscancel, setIscancel] = React.useState();
  const [data, setData] = React.useState(false);
  const classes = useStyles();
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [filename, setFilename] = React.useState("");

  const [lastname, setlastname] = React.useState("");
  const [duration, setduration] = React.useState("");
  const [reason, setreason] = React.useState("");
  const [type, settype] = React.useState("");
  const [beginMoment, setBeginMoment] = React.useState("");
  const [beginMomentSession, setBeginMomentSession] = React.useState("");
  const [beginTime, setBeginTime] = React.useState("");
  const [leavecode, setLeavecode] = React.useState("");
  const [leaveid, setIdleave] = useState();
  const [leave, setLeave] = useState();

  const [endMoment, setEndMoment] = React.useState("");
  const [endMomentSession, setEndMomentSession] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const [nomprenom, setNomprenom] = React.useState("");


  const [firstname, setFirstname] = React.useState("");
  const [leaveID, setLeaveID] = React.useState(0);
  const [filePath, setfilePath] = React.useState("");
  const [qrCode, setQrCode] = React.useState("");
  const [solde_Previsionnelle, setSolde_Previsionnelle] = React.useState("");



  const [solde_relle, setSolde_relle] = React.useState("");
  const [nomprenomSup, setNomprenomSup] = React.useState("");
  const [superStatus, setSuperStatus] = React.useState("");
  const [userSupList, setuserSupList] = React.useState([]);

  const [balanceBefore, setBalanceBefore] = useState(0);
  const [balanceAfters, setBalanceAfters] = useState(0);
  const [realdelta, setRealdelta] = useState(0);
  const [afterdelta, setAfterdelta] = useState(0);
  const [statuus, setStatuus] = useState(0);
  const [idobselete, setIdobselete] = useState('');
  const [codeobselete, setCodeobselete] = useState('');
  const [raison, setRaison] = useState('');

  // Samuela: set loading while fetching api data
  const [isLoading, setLoading] = useState(false);
  const [isLoadingSendForValidation, setLoadingSendForValidation] = useState(false);
  const [isLoadingHandleValidateLeave, setLoadingHandleValidateLeave] = useState(false);

  //confirmmation annulation validation
  const [cancel, setCancel] = React.useState(false);

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  /* ********** Samuela: solde réel déjà configuré dans la base de données ********* */
  const [isRealBalanceAlreadyConfigurate, setisRealBalanceAlreadyConfigurate] = useState(false);
  const [errorMessageForRealBalanceEmpty, seterrorMessageForRealBalanceEmpty] = useState("");

  const [userConnectedName, setUserConnectedName] = useState('');
  const [userConnectedActualBalance, setUserConnectedActualBalance] = useState('');
  const [idLeaveToCancel, setIdLeaveToCancel] = React.useState(0);
  const [emptyMotif, setEmptyMotif] = React.useState(false);

  const [isPlanning, setIsPlanning] = React.useState(false);
  const [isValidPlanning, setIsValidPlanning] = React.useState(false);

  const [isValidatorNumberConfigurate, setisValidatorNumberConfigurate] = useState(false);
  const [dayOffNumberWithoutValidation, setdayOffNumberWithoutValidation] = useState(false);

  const handleClickOpenCancel = () => {
    setCancel(true);
  };

  const handleCloseCancel = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setCancel(false);
  };
  const handleValidCancel = () => {
    setLoading(true);
    setEmptyMotif(false);
    if (raison && raison.trim().length !== 0 && raison !== undefined) {
      LeaveService.getLeaveObselete(raison, codeobselete, leaveid, leave, isPlanning).then((result) => {
        LeaveService.getLeaveType().then((results) => {
          if (results && results.data) {
            setLoading(false);
            setData(results.data);
            LoadTable();
          }
        });
        if (result != "") {
          if (result.status) {
            setCancel(false);
            setRaison("");
            setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.data.message });

            setEmptyMotif(false);
            // LoadTable();
          } else {
            setMessage({ ...message, openMessage: true, color: 'red', messageInfo: result.message });
            // LoadTable();
          }

          // LoadTable();
        } else {
          setMessage({ ...message, openMessage: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
        }
      }).catch((err) => {
        setMessage({ ...message, openMessage: true, color: 'red', messageInfo: 'erreur' });
      });
    } else {
      setEmptyMotif(true);
      setLoading(false);
    }
  };
  function handleRaison(ev) {
    const { name, value } = ev.target;
    setRaison(ev.target.value);
    setEmptyMotif(false);
  };
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [statusStorage, setStatusStorage] = useState(1);
  function LoadTable() {
    setOpenLoadingSimpleBackdrop(true);
    LeaveService.getLeavesByOptions().then((result) => {
      if (result) {
        if (statusStorage == 2) {
          setRows(result.planning.map((row) => ({ ...row, id: row.leave_id, file: row.file_request_id })));
          setOpenLoadingSimpleBackdrop(false);
        }
        if (statusStorage == 1) {
          setRows(result.reel.map((row) => ({ ...row, id: row.leave_id, file: row.file_request_id })));
          setOpenLoadingSimpleBackdrop(false);
        }

        if (statusStorage == 0) {
          setRows(result.nouveau.map((row) => ({ ...row, id: row.leave_id, file: row.file_request_id })));
          setOpenLoadingSimpleBackdrop(false);
        }
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
      // if (result && result.status) {

      //   setRows(result.data.map((row) => ({ ...row, id: row.leave_id, file: row.file_request_id })));
      //   setOpenLoadingSimpleBackdrop(false);
      // } else {
      //   setOpenLoadingSimpleBackdrop(false);
      // }
    });
  }

  useEffect(() => {
    if (localStorage.getItem("status")) {
      setStatus(localStorage.getItem("status"));

    } else {

      setStatus(1);
    }
    setStatusStorage(localStorage.getItem("status"));
    LeaveService.getLeaveType().then((result) => {
      if (result && result.data) {
        setLeaveTypes(
          result.data.map((d) => {
            return { id: d.leave_type_id, label: d.name, desc: d.desc };
          })
        );
      }
    });
    // console.log("props1", props);
    const isInitialBalanceConfigurated = localStorage.getItem('isInitialBalanceConfigurated');
    if (isInitialBalanceConfigurated) {
      setisRealBalanceAlreadyConfigurate(true);
    } else {
      setisRealBalanceAlreadyConfigurate(false);
    }
    // console.log("props2", userConnectedActualBalance);
    const propsUserName = props.userConnectedName;
    const propsActualBalance = props.userConnectedActualBalance;
    if (propsUserName !== null && propsUserName !== undefined && propsUserName.trim().length !== 0) {
      setUserConnectedName(propsUserName);
    }
    if (propsActualBalance !== null && propsActualBalance !== undefined && propsActualBalance.trim().length !== 0) {
      setUserConnectedActualBalance(propsActualBalance);
    }

    UserService.checkValidatornumberByUserId(0).then((results) => {
      if (results && results.status) {
        setisValidatorNumberConfigurate(true);
        setdayOffNumberWithoutValidation(results.data.user_validator_number_dayNumberWithoutValidation);
      }
    });

    LoadTable();
  }, [props]);


  useEffect(() => {
    localStorage.setItem("status", status);
  }, [status]);

  function getFullName(params) {
    return `${params.getValue(params.id, 'last_name') || ''} ${params.getValue(params.id, 'first_name') || ''
      }`;
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = [...rows].filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
  };
  const handleDelete = (id) => (ev) => {
    setIscancel(false);
    setId_to_delete(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true, messageDialog: 'voulez vous supprimer?' });
  };
  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false, messageDialog: 'voulez vous supprimer?' });
  }
  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false, messageDialog: 'voulez vous supprimer?' });
    LeaveService.deleteLeave(id_to_delete).then((result) => {
      LeaveService.getLeaveType().then((results) => {
        if (results && results.data) {
          setData(results.data);
          LoadTable();
        }
      });
      if (result.status) {
        setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.message });
      } else {
        setMessage({ ...message, openMessage: true, color: 'red', messageInfo: result.message });
      }
    }).catch((err) => {
      setMessage({ ...message, openMessage: true, color: 'red', messageInfo: 'erreur' });
    });
  }
  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function handleCloseMessage() {
    setMessage({ ...message, openMessage: false });
  }
  const handleEdit = (id) => (ev) => {
    navigate(`/me/leaves/add/${id}`);
  };
  const handleValide = (id) => (ev) => {
    navigate(`/me/leaves/add/valide/${id}`);
  };
  const handleGenerateFile = (id) => (ev) => {
    setOpenLoadingSimpleBackdrop(true);
    // props.match.params.id
    setLoadingSendForValidation(true);
    FileService.generateFile(id)
      .then((res) => {
        if (res.status) {
          setOpen(true);
          setOpenLoadingSimpleBackdrop(false);
          setfilePath(res.filepath);
          setFilename(res.filename);

          setFirstname(res.firstname);
          setlastname(res.lastname);
          setduration(res.duration);
          setreason(res.reason);
          settype(res.type);

          setSolde_Previsionnelle(res.solde_Previsionnelle);
          setSolde_relle(res.solde_relle);
          setBeginMoment(res.beginMoment);
          setSuperStatus(res.superStatus);

          setEndMoment(res.endMoment);
          setBeginMomentSession(res.beginTimeSession);
          setEndMomentSession(res.endTimeSession);
          setLeavecode(res.leaveInfo.leave_code);
          setNomprenom(res.nomprenom);
          setLeaveID(id);
          setuserSupList(res.userSupList);
          setLoadingSendForValidation(false);
          setQrCode(res.qrCode);
          setIsValidPlanning(res.isValidPlanning);
        }

        if (!isRealBalanceAlreadyConfigurate) {

          //function calculate_provisionnal_balance
          LeaveService.getBalanceBefore().then((result) => {
            setBalanceBefore(result.data);
          });
          //function calculate_real_balance
          LeaveService.getRealBalanceBefore().then((result) => {
            setRealdelta(result.data);
          });
          LeaveService.getBalanceAfter(res.duration).then((result) => {
            setBalanceAfters(result.data);
          });
          //function calculate_real_balance_After
          LeaveService.getRealBalanceAfter(res.duration).then((result) => {
            setAfterdelta(result.data);
          });
        } else {
          //provisional and real balance for balance already configurate

          //function calculate_provisionnal_balance
          LeaveService.getBalanceBefore().then((result) => {
            setBalanceBefore(result.data);
            const totalProvisionalBalance = result.data - res.duration;
            setBalanceAfters(totalProvisionalBalance);
          });
          //function calculate_real_balance
          LeaveService.getRealBalanceBefore().then((result) => {
            setRealdelta(result.data);
            console.log("Balanceafter : ", result.data, "Duration : ", res.duration, "LeaveType  : ", res.type);
            const totalRealBalance = (res.type == "congé") ? (result.data - res.duration) : result.data;
            // console.log("Balanceafter : ", result.data, "Duration : ", res.duration, "LeaveType  : ", res.type, "totalRealBalance",totalRealBalance);
            setAfterdelta(totalRealBalance);
          });
        }
      })
      .catch((err) => {
        setLoadingSendForValidation(false);
      });


  };

  function handleCancelLeave() {
    FileService.deleteFile(filename)
      .then((res) => {
        if (res.status) {
          setOpen(false);
        }
      })
      .catch((err) => { });
  }

  function handleValidateLeave() {
    // const module = JSON.parse(localStorage.getItem("module"));
    // const tmodule = module['label'];
    setLoadingHandleValidateLeave(true);
    // LeaveService.validateFile(leaveID, filename, tmodule, leavecode)
    LeaveService.validateFile(leaveID, filename, leavecode, qrCode, isValidatorNumberConfigurate, dayOffNumberWithoutValidation)
      .then((res) => {
        if (res.status) {
          setOpen(false);
          LoadTable();
          setLoadingHandleValidateLeave(false);
        }
      })
      .catch((err) => {

        setLoadingHandleValidateLeave(false);
      });
  }

  function handleCancelingLeave(id) {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false, messageDialog: "Voulez-vous vraiment annuler la demande de congé" });
    setCancel(true);
    // console.log('voakitika')
  }

  const handlecancel = (id, code, idleave, is, status) => (ev) => {
    if (status == "Planning confirmé" || status == "Planning en attente" ||
      status == "Planning validé" || status == "Planning réel confirmé") {
      setIsPlanning(true);
      setConfirmationDialog({ ...confirmationDialog, openDialog: true, messageDialog: "Voulez-vous vraiment annuler cette planification de congé" });
    } else {
      setConfirmationDialog({ ...confirmationDialog, openDialog: true, messageDialog: "Voulez-vous vraiment annuler la demande de congé" });
    }
    setIdleave(idleave);
    setIdobselete(id);
    setCodeobselete(code);
    setLeave(is);
    setIscancel(true);
    setIdLeaveToCancel(id);
  }

  const columns = [

    {
      field: 'fullName',
      headerName: 'Nom et prénom(s)',
      width: 350,
      valueGetter: getFullName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        getFullName(cellParams1).localeCompare(getFullName(cellParams2)),
    },
    {
      field: 'leave_code',
      headerName: 'Identification',
      width: 300,
      cellClassName: 'identifiant',
    },
    {
      field: "begin_time",
      headerName: "Date de départ",
      width: 200,
      valueFormatter: (params) => {
        return `${moment(params.row.begin_time).format('DD/MM/YYYY')} ${params.row.begin_time_session === 'morning' ? 'Matin' : 'Après-midi'}`;
      },
    },
    {
      field: "end_time",
      headerName: "Date de retour",
      width: 200,
      valueFormatter: (params) => {
        return `${moment(params.row.end_time).format('DD/MM/YYYY')} ${params.row.end_time_session === 'morning' ? 'Matin' : 'Après-midi'}`;
      },
    },
    {
      field: "leave_type_id",
      headerName: "Type",
      width: 200,
      valueFormatter: (params) => {
        const leaveType = leaveTypes.find(
          (type) => type.id === params.row.leave_type_id
        );
        return leaveType ? `${leaveType.label} - ${leaveType.desc}` : undefined;
      },
    },
    { field: "reason", headerName: "Motif", width: 200 },
    { field: "duration", headerName: "Durée (en jours)", width: 200 },

    {
      field: "states",
      headerName: "Statut",
      width: 300,
      valueFormatter: (params) => {
        return `${((params.row.StateValidate === 'DECLINED' && params.row.StatusPlanning === 'CONFIRMED' && status == 1) ||
          (params.row.statusFinal === "Congé consommé" && params.row.states === "2/2")) || params.row.statusFinal === "Annulé" ? params.row.statusFinal :
          (params.row.StateValidate === 'Validé' && params.row.StatusPlanning === 'CONFIRMED' && status == 1) ? "Planning réel validé" :
            (params.row.states !== null && params.row.StatusPlanning !== null && status == 1) ? "Réel " + params.row.states :
              (params.row.states !== null && params.row.StatusPlanning !== null && status == 2) ? "Planning validé " :
                params.row.statusFinal === "Congé validé" ? "Réel " + params.row.states : params.row.statusFinal === "En attente" ? "Réel " + params.row.states :
                  params.row.statusFinal === "Nouveau" ? "Nouveau" : params.row.statusFinal === "Confirmé" ? "Réel " + params.row.states :
                    params.row.statusFinal === "Planning confirmé" ? "Planning " + params.row.statePlanning :
                      params.row.statusFinal === params.row.statusFinal.split("Planning refusé") ? params.row.statusFinal :
                        params.row.statusFinal.split("Refusé par") ? params.row.statusFinal : params.row.statusFinal === "Planning en attente" ? "Planning en attente" :
                          params.row.statusFinal === "Congé consommé" ? params.row.statusFinal : "Planning validé"}`;
      },
    },
    {
      field: "observation",
      headerName: "Observation",
      width: 200,
      valueFormatter: (params) => {
        const leaveType = leaveTypes.find(
          (type) => type.id === params.row.leave_type_id
        );
        return params.row.observation && params.row.observation !== undefined && params.row.observation.trim() !== "" ?
          params.row.observation : "-";
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <span>
          {params.row.statusFinal === "Nouveau" && (
            <>
              <Button
                onClick={handleGenerateFile(params.id)}
                title="Envoyer pour validation"
                disabled={isLoadingSendForValidation ? true : false}
              >
                <CheckIcon style={{ color: "green" }} />
              </Button>
              <Button onClick={handleEdit(params.id)} title="Modifier">
                <CreateTwoTone style={{ color: "black" }} />
              </Button>
              <Button onClick={handleDelete(params.id)} title="Supprimer">
                <DeleteOutlineRounded style={{ color: "red" }} />
              </Button>
            </>
          )}
          {(params.row.statusFinal === "Planning validé" && (params.row.states === "" || params.row.states === null)) && (
            <>
              <Button
                onClick={handleGenerateFile(params.id)}
                title="Envoyer pour validation"
              >
                <CheckIcon style={{ color: "green" }} />
              </Button>

              {/* <Button onClick={handlecancel(params.id, params.row.leave_code, params.row.file_request_id, params.row.leave_id)} title="Annuler ">
                <CloseIcon style={{ color: "red" }} />
              </Button> */}
            </>
          )}
          {params.row.statusReel === "DECLINED" && status == 1 && (
            <Button title="Refusé">
              <RemoveIcon style={{ color: "rgb(19, 62, 103)" }} />
            </Button>
          )}
          {/* {params.row.action === "DECLINED" && (
            <Button onClick={handleEdit(params.id)} title="Modifier">
              <CreateTwoTone style={{ color: "black" }} />
            </Button>
          )} */}
          {params.row.statusFinal === "En attente" && (
            <Button onClick={handlecancel(params.id, params.row.leave_code, params.row.file_request_id, params.row.leave_id, params.row.statusFinal)} title="Annuler ">
              <CloseIcon style={{ color: "red" }} />
            </Button>
          )}
          {params.row.statusFinal === "Confirmé" && (
            <Button onClick={handlecancel(params.id, params.row.leave_code, params.row.file_request_id, params.row.leave_id, params.row.statusFinal)} title="Annuler ">
              <CloseIcon style={{ color: "red" }} />
            </Button>
          )}
          {params.row.statusFinal === "Planning confirmé" && (
            <Button onClick={handlecancel(params.id, params.row.leave_code, params.row.file_request_id, params.row.leave_id, params.row.statusFinal)} title="Annuler ">
              <CloseIcon style={{ color: "red" }} />
            </Button>
          )}
          {params.row.statusFinal === "Planning en attente" && (
            <Button onClick={handlecancel(params.id, params.row.leave_code, params.row.file_request_id, params.row.leave_id, params.row.statusFinal)} title="En attente ">
              <CloseIcon style={{ color: "red" }} />
            </Button>
          )}
          {params.row.statusFinal === "Congé validé" && (
            <>
              <Button onClick={handleValide(params.id)} title="Confirmer">
                <DoneAllIcon style={{ color: "rgb(19, 62, 103)" }} />
              </Button>
              {/* <Button onClick={handlecancel(params.id, params.row.leave_code, params.row.file_request_id, params.row.leave_id)} title="Annuler ">
                <CloseIcon style={{ color: "red" }} />
              </Button> */}
            </>
          )}
          {params.row.StatusPlanning === "DECLINED" && (
            <Button onClick={handleEdit(params.id)} title="Modifier">
              <CreateTwoTone style={{ color: "black" }} />
            </Button>
          )}
          {((params.row.statusFinal === "Planning réel validé" && status == 1) || (params.row.statusFinal === "Planning réel validé" && params.row.states === "2/2")) && (
            <>
              <Button onClick={handleValide(params.id)} title="Valider">
                <DoneAllIcon style={{ color: "rgb(19, 62, 103)" }} />
              </Button>
              {/* <Button onClick={handlecancel(params.id, params.row.leave_code, params.row.file_request_id, params.row.leave_id)} title="Annuler ">
                <CloseIcon style={{ color: "red" }} />
              </Button> */}
            </>
          )}
          {((params.row.statusFinal === "Planning validé" && params.row.states === "0/2") || (params.row.statusFinal === "Planning réel confirmé" && params.row.states !== null)) && (
            <Button onClick={handlecancel(params.id, params.row.leave_code, params.row.file_request_id, params.row.leave_id, params.row.statusFinal)} title="Annuler ">
              <CloseIcon style={{ color: "red" }} />
            </Button>
          )}

        </span>

      ),
    },
  ];
  function handleChangeStatus(event) {
    const { name, value } = event.target;
    console.log(event.target);
    setStatus(value);
    if (event.target.value == 2) {
      setOpenLoadingSimpleBackdrop(true);
      LeaveService.getLeavesByOptions().then((result) => {
        if (result && result.planning) {
          setRows(result.planning.map((row) => ({ ...row, id: row.leave_id, file: row.file_request_id })));
          setOpenLoadingSimpleBackdrop(false);
        }
        else {
          setOpenLoadingSimpleBackdrop(false);
        }
      });
    }
    if (event.target.value == 1) {
      LeaveService.getLeavesByOptions().then((result) => {
        if (result && result.reel) {
          setRows(result.reel.map((row) => ({ ...row, id: row.leave_id, file: row.file_request_id })));
        }
      });
    }

    if (event.target.value == 0) {
      LeaveService.getLeavesByOptions().then((result) => {
        if (result && result.nouveau) {
          console.log("ressss newww", result.nouveau)
          setRows(result.nouveau.map((row) => ({ ...row, id: row.leave_id, file: row.file_request_id })));
        }
      });
    }

  }

  function handleClickPdf() {
    FileService.readFile(filename).then((result) => {
      if (result) {
        const blob = new Blob([result.data], {
          type: 'application/pdf',
        });

        const fileURL = URL.createObjectURL(blob);
        const w = window.open(fileURL, '_blank');
        w && w.focus();
        URL.revokeObjectURL(fileURL);
      }
    });
  }

  function handleModifLeavePlanning() {
    navigate(`/me/leaves/add/editPlanning/${leaveID}`);
  };

  return (
    <>
      {iscancel === false && <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} isCancel={iscancel}
        isPlanning={isPlanning} />}
      {iscancel === true && <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={handleCancelingLeave} cancelDialog={cancelDialog}
        isCancel={iscancel} idLeave={idLeaveToCancel} isPlanning={isPlanning} />}
      <Message open={openMessage} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-label">Statut</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="status"
              onChange={handleChangeStatus}
            >
              <MenuItem value={0}>Nouveau</MenuItem>
              <MenuItem value={1}>Réel</MenuItem>
              <MenuItem value={2}>Planning</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} className={classes.actualBalance}>
          <div style={{ fontSize: "16px", justifyContent: "center" }}> <b>{userConnectedName}</b>, votre solde actuel est de <b>{userConnectedActualBalance} jour(s)</b> </div>
        </Grid>
      </Grid>
      <Title>Absence / Congé</Title>

      <div style={{ height: "70vh", width: "100%" }}>

        <DataGrid
          columns={columns}
          //components={{ Toolbar: CustomToolbar }}
          rows={rows}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
          getRowClassName={(params) =>
            (params.row.statusReel === "DECLINED" || params.row.StatusPlanning === "DECLINED") ? 'declinedRow' : ''
          }
        />
      </div>

      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Detail de votre {type} </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} >
            {/* <Grid item xs={3}>
            
              </Grid> */}
            <Grid item xs={4}>
              <label className={classes.id}>Identification:</label>
            </Grid>
            <Grid item xs={8}>
              <label className={classes.id}>{leavecode}</label>
            </Grid>

            <Grid item xs={4}>
              <label className={classes.label}>Nom et Prénom du demandeur:</label>
            </Grid>
            <Grid item xs={8}>
              <label className={classes.label1}>{nomprenom}</label>
            </Grid>


            <Grid item xs={4}>
              <label className={classes.label}>Date de Départ:</label>
            </Grid>
            <Grid item xs={2}>
              <label className={classes.label1}>{beginMoment}</label>


            </Grid><Grid item xs={6}>
              <label className={classes.label1}>{beginMomentSession}</label>


            </Grid>

            <Grid item xs={4}>
              <label className={classes.label}>Date de retour:</label>
            </Grid>
            <Grid item xs={2}>
              <label className={classes.label1}>{endMoment}</label>

            </Grid>
            <Grid item xs={6}>
              <label className={classes.label1}>{endMomentSession}</label>

            </Grid>

            <Grid item xs={4}>
              <label className={classes.label}>Nombre de jours:</label>
            </Grid>
            <Grid item xs={8}>
              <label className={classes.label1}>{duration}</label>
            </Grid>
            <Grid item xs={4}>
              <label className={classes.label}>Motif:</label>
            </Grid>
            <Grid item xs={8}>
              <label className={classes.label1}>{reason}</label>
            </Grid>

            {/* 12/09/2022: commenter solde prévisionnel selon demmande RH */}
            {/* <Grid item xs={4}>
              <label className={classes.label}>Solde prévisionnelle avant:</label>
            </Grid>
            <Grid item xs={8}>
              <label className={classes.label1}>{balanceBefore}</label>
            </Grid>
            <Grid item xs={4}>
              <label className={classes.label}>Solde prévisionnelle après:</label>
            </Grid>
            <Grid item xs={8}>
              <label className={classes.label1}>{balanceAfters}</label>
            </Grid> */}
            <Grid item xs={4}>
              <label className={classes.label}>Solde réelle avant:</label>
            </Grid>
            <Grid item xs={8}>
              <label className={classes.label1}>{realdelta}</label>
            </Grid>
            <Grid item xs={4}>
              <label className={classes.label}>Solde réelle après:</label>
            </Grid>
            <Grid item xs={8}>
              <label className={classes.label1}>{afterdelta}</label>
            </Grid>
          </Grid>
          <br></br>
          <span>
            Le rendu de votre demande sous forme{" "}
            <Link
              to={"#"}
              onClick={handleClickPdf}
            >
              pdf.
              </Link>
            <br></br>
            {!isValidPlanning &&
              <>
                {isValidatorNumberConfigurate && duration <= dayOffNumberWithoutValidation &&
                  <Alert severity="info">
                    <span>La durée de votre congé est {duration < dayOffNumberWithoutValidation ? "inférieure" : "inférieure ou égale"} à {dayOffNumberWithoutValidation} jours.
                    Dans le cadre d'une configuration spéciale sans validation associée à votre compte,
                    vous avez la possibilité de le valider directement, ce qui déclenchera l'envoi automatique d'une notification aux équipes des ressources humaines</span>
                  </Alert>
                }

                {isValidatorNumberConfigurate && duration > dayOffNumberWithoutValidation &&
                  <span>La durée de votre congé est supérieure à {dayOffNumberWithoutValidation} jours.
                  Dans le cadre d'une configuration spéciale sans validation associée à votre compte,
                  cette demande de congé sera envoyé pour validation de votre supérieur hierarchique : <label className={classes.label1}>
                      <span><b>{userSupList.last_name + ' ' + userSupList.first_name}</b></span></label>

                    <br></br>
                    Confirmez-vous votre action?
                  </span>
                }
                {!isValidatorNumberConfigurate &&
                  <>
                    <span>"Cette demande de congé sera envoyé pour validation de votre supérieur hierarchique : <label className={classes.label1}>
                      <span><b>{userSupList.last_name + ' ' + userSupList.first_name}</b></span>
                    </label>"</span>


                    <br></br>
                    Confirmez-vous votre action?
                  </>
                }
              </>
            }
            {isValidPlanning &&
              <>
                <Alert severity="info">
                  <span>Ceci est un planning validé!<br /> Vous pouvez le modifier en cliquant sur le bouton "<i>Modifier</i>" ou l'envoyer à votre supérieur hiérarchique
                pour demander sa validation à l'aide du bouton "<i>Valider</i>".<br />
                    <b><u>NB:</u></b>Votre supérieur hiérarchique actuel est <b>{userSupList.last_name + ' ' + userSupList.first_name}</b>
                  </span>
                </Alert>

              </>
            }
          </span>
        </DialogContent>
        <DialogActions>
          {!isValidPlanning &&
            <>
              <Button
                style={{ backgroundColor: "##f50057", color: "#FFFFFF" }}
                onClick={handleCancelLeave}
                color="secondary"
                variant="contained"
              >
                Annuler
          </Button>
              <Button
                style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF" }}
                onClick={handleValidateLeave}
                color="primary"
                variant="contained"
                disabled={isLoadingHandleValidateLeave ? true : false}
              >
                {isLoadingHandleValidateLeave ? <CircularProgress /> : "Valider"}
              </Button>
            </>
          }
          {isValidPlanning &&
            <>
              <Button
                style={{ backgroundColor: "##f50057", color: "#FFFFFF" }}
                onClick={handleCancelLeave}
                color="secondary"
                variant="contained"
              >
                Retour
              </Button>
              <Button
                style={{ backgroundColor: "rgb(106, 207, 246)", color: "#FFFFFF" }}
                onClick={handleModifLeavePlanning}
                color="primary"
                variant="contained"
                disabled={isLoadingHandleValidateLeave ? true : false}
              >
                {isLoadingHandleValidateLeave ? <CircularProgress /> : "Modifier"}
              </Button>
              <Button
                style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF" }}
                onClick={handleValidateLeave}
                color="primary"
                variant="contained"
                disabled={isLoadingHandleValidateLeave ? true : false}
              >
                {isLoadingHandleValidateLeave ? <CircularProgress /> : "Valider"}
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>
      <MotifAnnulationLeave openDialog={cancel} handleCloseCancel={handleCloseCancel} raison={raison}
        handleRaison={handleRaison} emptyMotif={emptyMotif} isLoading={isLoading} handleValidCancel={handleValidCancel}
        isPlanning={isPlanning} />
      {/* <div>
        <Dialog open={cancel} onClose={handleCloseCancel}
          aria-labelledby="form-dialog-title1"
          fullWidth
          maxWidth="sm">
          <DialogTitle id="form-dialog-title1">Motif d'annulation</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Raison"
              variant="outlined"
              value={raison}
              name="raison"
              onChange={handleRaison}
            />
            {emptyMotif &&
              <Alert severity="error">Veuillez spécifier la raison de votre demande d'annulation</Alert>
            }
            <Spacer y={2}></Spacer> */}

      {/* Samuela: set loading while fetching api data */}
      {/* {isLoading && <Loading />}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleCloseCancel} color="secondary">Annuler</Button>
            <Button variant="contained" onClick={handleValidCancel} color="primary">Valider</Button>
          </DialogActions>
        </Dialog>
      </div> */}
    </>
  );
}
