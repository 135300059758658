import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';


import {
  Button,
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataLibrary } from "../../../data/modules/library-header";
import DirectionService from "../../../services/DirectionService";
import { useLocation } from 'react-router-dom';
import Result from "../../personnel/Result";
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../../utils/privilege";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import RubriquePrincipaleService from "../../../services/RubriquePrincipaleService";
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

function RubriquePrincipaleAdd(props) {

  const classes = useStyles();

  const [form, setForm] = useState({
    id: "",
    nom: "",
    description: ""
  });

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [open, setOpen] = React.useState(false);
  const [etats, setEtats] = useState({ open: false, message: "message", color: "" });
  const navigate = useNavigate();

  const handleClickOpen = () => {
    navigate("/principale/add/");
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/principale");
  };

  const handleCloseMessage = () => {
    setEtats({ ...etats, open: false });

  };

  useEffect(() => {
    const idGroup = splitLocation[3];
    if (idGroup) {
      RubriquePrincipaleService.principaleById(idGroup).then((library) => {
        setForm({
          id: library.data.id,
          nom: library.data.nom,
          description: library.data.description
        });
      });
    }
  }, [setForm]);


  function handleClick() {
    RubriquePrincipaleService.savePrincipale(form.nom, form.description, form.id)
      .then((results) => {
        if (results.status) {
          setEtats({ ...etats, open: true, color: 'green', message: results.message });
          navigate("/principale");
        }
        else {
          setEtats({ ...etats, open: true, color: 'red', message: results.message });
        }
      })
      .catch((err) => {
        alert("error");
      });
  }
  function CapitalString(str) {
    var nextCapital = true;
    var newString = "";
    for (var i = 0; i < str.length; i++) {
      var cur = str.charAt(i);
      if (nextCapital) {
        newString += cur.toUpperCase();
      } else {
        newString += cur.toLowerCase();
      }
      if (cur === " ") {
        nextCapital = true;
      } else {
        nextCapital = false;
      }
    }
    return newString;
  };

  function handleChange(ev) {
    const { name, value } = ev.target;
    if (name == 'name') {
      setForm({ ...form, [name]: value})
    }
    else if (name == 'description') {
      setForm({ ...form, [name]: value })
    }
    else {
      setForm({ ...form, [name]: value })

    }
  }
  const contextData = useContext(userDetailContext);
  function handleCancel() {
    navigate("/principale");
  }
  return (
    <MainContainer {...headerDataLibrary}>
      <Result message={etats.message} color={etats.color} open={etats.open} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        {hasPrivilege(contextData.privileges, 'Admin-Button-Save-Direction') &&
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            ENREGISTRER
          </Button>
        }
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Nom du rubrique principale{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="nom"
              variant="outlined"
              onChange={handleChange}
              inputProps={{ textTransform: 'capitalize', }}
              value={form.nom}
              name="nom"
            />
          </Grid>


          <Grid item xs={4}>
            <label className={classes.labelPosition}>Description</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="outlined-multiline-static"
              // label="Multiline"
              multiline
              rows={3}
              size="small"
              label="description"
              className={classes.text}
              variant="outlined"
              onChange={handleChange}
              value={form.description}
              name="description"
            />
          </Grid>

          <Grid item xs={12}>
            <label className={classes.text}>({bullRed}):Champs obligatoires </label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default RubriquePrincipaleAdd;
