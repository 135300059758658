import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataAdmin } from "../../data/modules/admin-header";
import ServiceService from "../../services/ServiceService";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Message from "../tools/Message";
import { useContext } from "react";
import { hasPrivilege } from "../../utils/privilege";
import { userDetailContext } from "../../App";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: "left",
  },
  text: {
    width: 370,
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

function ServiceAdd(props) {
  const classes = useStyles();
  const [listeChoix, setListeChoix] = useState([]);
  const [nomChoix, setNomChoix] = useState("Choix");
  const [typeChoice, setTypeChoice] = useState("");
  const [form, setForm] = useState({
    direction_id: "",
    name: "",
    code: "",
    description: "",
    type: "",
  });
  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;

  const [isValidType, setIsValidType] = useState(false);
  const [isValidDirection_id, setIsValidDirection_id] = useState(false);
  const [isValidCode, setIsValidCode] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    const service_id = params.id;
    console.log(service_id)
    if (service_id) {
      ServiceService.getServiceById(service_id).then(({ data: service }) => {
        setForm({
          direction_id: service.direction_id,
          name: service.name,
          code: service.code,
          description: service.description,
          service_id: service_id,
          type: service.direction_type,
        });
        if (service.direction_type == "direction") {
          setNomChoix("Direction");
        } else if (service.direction_type == "antenne") {
          setNomChoix("Antenne");
        }
        ServiceService.getDirectionByType(service.direction_type).then((result) => {
          if (result && result.data) {
            setListeChoix(
              result.data.map((listeChoix) => {
                return {
                  direction_id: listeChoix.direction_id,
                  name: listeChoix.name,
                };
              })
            );
          }
        });
      });
    }
  }, []);

  function handleChangeType(event) {
    const { name, value } = event.target;
    setTypeChoice(value);
    if (value == "direction") {
      setNomChoix("Direction");
    } else if (value == "antenne") {
      setNomChoix("Antenne");
    }
    setForm({ ...form, [name]: value });
    ServiceService.getDirectionByType(value).then((result) => {
      if (result && result.data) {
        setListeChoix(
          result.data.map((listeChoix) => {
            return {
              direction_id: listeChoix.direction_id,
              name: listeChoix.name,
            };
          })
        );
      }
    });
  }

  function handleClick() {
    if (typeChoice === "") {
      setIsValidType(true);
    }
    if (form.direction_id === "") {
      setIsValidDirection_id(true);
    }
    if (form.code === "") {
      setIsValidCode(true);
    }
    if (form.name === "") {
      setIsValidName(true);
    }
    if (
      typeChoice !== "" &&
      form.direction_id !== "" &&
      form.code !== "" &&
      form.name !== ""
    ) {
      ServiceService.saveService(
        form.direction_id,
        form.name,
        form.code,
        form.description,
        form.service_id
      )
        .then((result) => {
          if (result.status) {
            setMessage({
              ...message,
              open: true,
              color: "green",
              messageInfo: result.message,
            });
            navigate("/services");
          } else {
            setMessage({
              ...message,
              open: true,
              color: "red",
              messageInfo: result.message,
            });
          }
        })
        .catch((err) => {
          setMessage({
            ...message,
            open: true,
            color: "red",
            messageInfo: "erreur",
          });
        });
    }
  }

  function toTableCaractere(letter) {
    let table = [];
    let i = 0;
    for (i = 0; i < letter.length; i++) {
      table[i] = letter.charAt(i);
    }
    return table;
  }

  function firstLetterToUpperCase(letter) {
    let table = toTableCaractere(letter);
    let i = 0;
    for (i = 0; i < table.length - 1; i++) {
      if (table[i] == " ") {
        table[i + 1] = table[i + 1].toUpperCase();
      }
    }
    letter = table.join("");
    letter = letter.charAt(0).toUpperCase() + letter.slice(1);
    return letter;
  }

  function handleChange(event) {
    const { name, value } = event.target;
    if (name == "code") {
      setForm({ ...form, [name]: value.toUpperCase() });
    } else if (name == "name") {
      setForm({ ...form, [name]: firstLetterToUpperCase(value) });
    } else {
      setForm({ ...form, [name]: value });
    }
  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  const contextData = useContext(userDetailContext);
  function handleCancel() {
    navigate("/services");
  }
  return (
    <MainContainer {...headerDataAdmin}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <Grid container item xs={12}>
        {hasPrivilege(contextData.privileges, "Admin-Button-Save-Service") && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            ENREGISTRER
          </Button>
        )}
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Type{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Type"
                name="type"
                onChange={handleChangeType}
                value={form.type}
              >
                <MenuItem value="direction">Direction</MenuItem>
                <MenuItem value="antenne">Antenne</MenuItem>
              </Select>
            </FormControl>
            {isValidType && (
              <Alert severity="error">Veuillez remplir ce champ</Alert>
            )}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>{nomChoix}{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                {nomChoix}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label={nomChoix}
                value={form.direction_id}
                name="direction_id"
                onChange={handleChange}
              >
                {listeChoix.map((row) => (
                  <MenuItem value={row.direction_id}>{row.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {isValidDirection_id && (
              <Alert severity="error">Veuillez remplir ce champ</Alert>
            )}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Code{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Code"
              variant="outlined"
              value={form.code}
              name="code"
              onChange={handleChange}
              inputProps={{ maxLength: 10 }}
            />
            {isValidCode && (
              <Alert severity="error">Veuillez remplir ce champ</Alert>
            )}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Désignation{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Désignation"
              variant="outlined"
              value={form.name}
              name="name"
              onChange={handleChange}
            />
            {isValidName && (
              <Alert severity="error">Veuillez remplir ce champ</Alert>
            )}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Description</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              multiline
              className={classes.text}
              width="auto"
              rows={3}
              rowsMax={5}
              id="outlined-basic"
              size="small"
              label="Description"
              variant="outlined"
              value={form.description}
              name="description"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <label className={classes.text}>({bullRed}):Champs obligatoires</label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default ServiceAdd;
