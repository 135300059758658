import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from '@material-ui/data-grid';
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import BanqueDeCvService from "../../services/BanqueCvService"
import Spacer from "../../components/Spacer";
import { Tooltip, Typography } from "@material-ui/core";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { exportToExcel } from "../../helpers/ExportHelpers";
import { LoadingButton } from '@mui/lab'

export const INTERNAL_FILE_STORAGE_PATH = process.env.REACT_APP_API_URL.replace("api", "uploads/cv/");

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function CvList(props) {
  const [rows, setRows] = useState([]);
  const [tableColumns, setTableColumn] = useState([]);
  const [load, showLoadCv] = useState(false);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  const classes = useStyles();
  const filter = props.filter;

  useEffect(() => {
    handleSwitchCVinternExtern(filter.isCVIntern);
    refreshTable();
  }, [filter.refresh, filter.isCVIntern]);

  useEffect(() => {
    handleExportToExcel(props.excel_export);
  }, [props.excel_export]);

  const refreshTable = () => {
    setOpenLoadingSimpleBackdrop(true);
    BanqueDeCvService.list(filter).then((result) => {
      
      if (result && result.data) {
        
        setRows(result.data.map((row) => ({ ...row, id: row.ref })));
        setOpenLoadingSimpleBackdrop(false);
      }
      else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }

  const handleShowSelectedCV = (rw) => (ev) => {
    showLoadCv(true);
    
    try {
      if (filter.isCVIntern) {
        BanqueDeCvService.showCvByUserId(rw.us_id).then((result) => {
          if (result.data) {
            window.open(INTERNAL_FILE_STORAGE_PATH + result.data, '_blank');
          } else {
            alert(rw.full_name + " ne dispose pas de CV dans le systeme");
          }
  
        })
      } else {
        BanqueDeCvService.showCvByUserIdexterne(rw.us_id).then((result) => {
          if (result.data) {
            window.open(INTERNAL_FILE_STORAGE_PATH + result.data, '_blank');
          } else {
            alert(rw.full_name + " ne dispose pas de CV dans le systeme");
          }
  
        })
      }
      
    } catch (err) {
      console.log(err);
    } finally {
      showLoadCv(false);
    }
  }

  const handleExportToExcel = (stat) => {
    let titre = 'Banque de CV ' + filter.formation + ' ' + filter.diplome + ' ' + filter.competence;
    if (stat > 0) {
      if (!((filter.direction_id === 0 || filter.direction_id === ''))) {
        titre += +' ' + filter.direction_id.name;
        if (!(filter.service_id === 0 || filter.service_id === ''))
          titre += +' ' + filter.service_id.name;
      }
      exportToExcel(rows, columns, titre);
    }
  }

  const handleSwitchCVinternExtern = (candidatExtern) => {
    setTableColumn(columns);
    if (!candidatExtern) {
      const cvIntFieldExcludeFiltre = ["direction_name", "service_name", "matricule","poste"];
      setTableColumn(columns.filter(element => !cvIntFieldExcludeFiltre.includes(element.field)));
    } else {
      const cvIntFieldExcludeFiltre = ["objet_candidature"];
      setTableColumn(columns.filter(element => !cvIntFieldExcludeFiltre.includes(element.field)));
    }
  }

  const columns = [
    {
      field: 'matricule',
      headerName: 'Matricule',
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    }, {
      field: 'full_name',
      headerName: 'Nom et prenoms',
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'objet_candidature',
      headerName: 'Objet candidature',
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'poste',
      headerName: 'Fonction',
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'direction_name',
      headerName: 'Direction',
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'service_name',
      headerName: 'Service',
      width: 400,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'diplome_formation',
      headerName: 'Diplômes et Formations',
      width: 400,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'competence',
      headerName: 'Domaines de compétence',
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'sous_domaine_competence',
      headerName: 'Sous domaine de compétence',
      width: 300,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'dispose_un_cv',
      headerName: 'Avoir CV',
      width: 150,
    },
    {
      field: 'libelle_actions',
      headerName: 'Actions',
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title={"Cliquer pour afficher le cv de " + params.row.full_name}>
              <LoadingButton
                onClick={handleShowSelectedCV(params.row)}
                color="primary" variant="outlined"
                loadingPosition="start"
                loading={load}
                disabled={params.row.dispose_un_cv === "Non"} >
                <VisibilityOutlinedIcon style={{ color: (params.row.dispose_un_cv !== "Non") ? "blue" : "GrayText", display:(load)?'none':'' }} />
                <Typography> Voir Cv</Typography>
              </LoadingButton>
            </Tooltip>
          </div>
        );
      },
      width: 200,
    },
  ];

  return (
    <React.Fragment>
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      {/*  <SimpleSnackbar message={snackBarProps.Message} type_box={snackBarProps.type_snak} openSnak={snackBarProps.open} /> */}
      <Spacer y={2} />
      <div style={{ height: "60vh", width: '100%' }} className={classes.root} >
        <DataGrid
          rows={rows}
          columns={tableColumns}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
        />
      </div>
    </React.Fragment>
  );
}
