import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import BalanceConfigurationTable from "./BalanceConfigurationTable";
import { Button } from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataSettings } from "../../../data/modules/settings-header";
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../../utils/privilege";
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function BalanceConfigurationList() {
  
  const navigate = useNavigate();
  const contextData = useContext(userDetailContext);

  function handleClick() {
    navigate("/balance_configuration/add");
  }

  const classes = useStyles();
  return (
    <MainContainer {...headerDataSettings}>
      <Grid container item xs={12}>
        <Spacer x={2} />

        {hasPrivilege(contextData.privileges, 'RH_Button_Create_Balance_Configuration') &&
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            CREER
          </Button>
        }

        <Spacer x={2} />
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <BalanceConfigurationTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
