import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Button} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataAdmin } from "../../data/modules/admin-header";
import { useParams } from "react-router-dom";
import PersonalSettingService from "../../services/PersonalSettingService";
import Message from "../tools/Message";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { format } from 'date-fns';
import CandidatureService from "../../services/CandidatureService";
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 800,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    width: 370,
  }
}));

function CandidatureAdd(props) {
  const classes = useStyles();
  const [form, setForm] = useState({name_field: "", date_start: new Date(), date_end: new Date(),dateend:"",datestart:"", descript: ""});
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const params = useParams();
  const personal_config_id = params.id;
  const navigate = useNavigate();

  useEffect(() => {
    if(personal_config_id) {
      PersonalSettingService.getPersonalSettingsById(personal_config_id).then(({ data:personalSetting }) => {
        setForm({
          name_field: personalSetting.name_field,
          type_field: personalSetting.type_field,
          required: personalSetting.required,
          personal_config_id: personalSetting.personal_config_id,
        });
      });
    }
  }, []);

  function handleClick(){
    CandidatureService.insert(form.name_field,form.date_start,form.date_end,form.descript).then((result) => {
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
        navigate('/list_candidature');
      }
      else{
        setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur" });
      }
    });    
   }
    

  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }

  const handleChangedate = (newValue) => {
    const formattedDate = format(newValue, 'dd/MM/yyyy'); 
    setForm({ ...form,date_start:newValue, datestart: formattedDate });
  };

  const handleChangedateend = (newValue) => {
    setForm({ ...form, date_end: newValue,dateend:format(newValue, 'dd/MM/yyyy') });
  };

  
  function handleCloseMessage(){
    setMessage({...message, open: false});
  }
  return (
    <MainContainer {...headerDataAdmin}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >ENREGISTRER</Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Nom du poste*</label>
          </Grid>
          <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="Nom du poste" variant="outlined" value={form.name_field} name="name_field" onChange={handleChange} />
           
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Date de début de la publication *</label>
          </Grid>
          <Grid item xs={8}>
              {/* <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={form.date_start}
                name="date_start"
                onChange={handleChangedate}
                renderInput={(params) =>
                  <TextField {...params} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" error={false} />
                }
              /> */}
             <LocalizationProvider  dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={form.date_start}
                name="date_start"
                onChange={handleChangedate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    id="date-picker-dialog"
                    size="small"
                    color="primary"
                    error={false}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <label>Date de fin de la publication *</label>
          </Grid>
          <Grid item xs={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={form.date_end}
                name="date_end"
                onChange={handleChangedateend}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    id="date-picker-dialog"
                    size="small"
                    color="primary"
                    error={false}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Référence AMI *</label>
          </Grid>
          <Grid item xs={8}>
          <TextField fullWidth id="outlined-basic" size="small" name="descript" value={form.descript}  onChange={handleChange} label="Référence AMI" variant="outlined"  />
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default CandidatureAdd;