import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DirectionService from "../../services/DirectionService";
import ActualBalanceService from "../../services/ActualBalanceService";
import PlanningService from "../../services/PlanningService";
import { DataGrid } from "@material-ui/data-grid";
import Spacer from "../../components/Spacer";
import { escapeRegExp } from "../../utils";
import TextField from '@material-ui/core/TextField'
import {
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Grid
} from "@material-ui/core";
export default function ActualBalanceTable() {
    const [rows, setRows] = useState([]);
    const [dir, setDir] = useState([]);
    const [service, setService] = useState([]);
    const [idservice, setIdservice] = useState(0);
    const [iddir, setIddir] = React.useState(0);
    const [searchText, setSearchText] = React.useState("");
    const [form, setForm] = useState({
        direction_id: " ",
        service_id: " "
    });
    function loadTable(id_direction, id_service) {
        //setOpenLoadingSimpleBackdrop(true);
        ActualBalanceService.findAllConfig(id_direction, id_service).then((res) => {
            if (res && res.data) {
                setRows(
                    res.data.map((row) => ({
                        ...row,
                        id: row.user_id,
                    }))
                );
                //setOpenLoadingSimpleBackdrop(false);
            } else {
                setRows([]);
                //setOpenLoadingSimpleBackdrop(false);
            }
        })
    }
    useEffect(() => {
        loadTable(iddir, idservice);
        DirectionService.getDirection().then((res) => {
            if (res && res.status) {
                setDir(res.data);
            }
        })
    }, []);
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = [...rows].filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
    };
    function handleChangeDirection(event) {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
        setIddir(value);
        PlanningService.Direction(value).then((results) => {
            if (results && results.data) {
                setService(results.data);
            }
        })
        loadTable(value, idservice);
    }
    function handleChangeService(ev) {
        const { name, value } = ev.target;
        setForm({ ...form, [name]: value });
        setIdservice(value);
        loadTable(iddir, value);
    }
    function handleChangeUser(ev) {
        const { name, value } = ev.target;
        setForm({ ...form, [name]: value });
        setIdservice(value);
        loadTable(iddir, value);
    }
    const column = [
        {
            field: "name",
            headerName: "Nom et prénom(s)",
            width: 300,
            valueFormatter: (params) => {
                return `${params.row.last_name} ${params.row.first_name === null ? " " : params.row.first_name}`;
            },
        },
        {
            field: "real_balance",
            headerName: "Solde réel restant",
            width: 250,
        },
        {
            field: "direction_name",
            headerName: "Direction",
            width: 250,
        },
        {
            field: "service_name",
            headerName: "Service",
            width: 250,
        },



    ]
    return (
        <React.Fragment>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={5} sm={6} lg={5}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="demo-simple-select-outlined-label">Direction</InputLabel>
                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Direction" value={form.direction_id} name="direction_id" onChange={handleChangeDirection} >
                            {dir.map((row) => (
                                <MenuItem value={row.direction_id}>{row.name}  ( {row.code} )</MenuItem>

                            ))}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} md={5} sm={6} lg={5}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="demo-simple-select-outlined-label">Service</InputLabel>
                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Service" value={form.service_id} name="service_id" onChange={handleChangeService} >
                            {service.map((row) => (
                                <MenuItem value={row.service_id}>{row.name}  ( {row.code} )</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </Grid>

            </Grid>
            <Spacer y={3} />
            <div style={{ height: "70vh", width: "100%" }}>
                <DataGrid
                    columns={column}
                    rows={rows}
                    pageSize={7}
                    rowsPerPageOptions={[7]}
                    disableSelectionOnClick
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(""),
                        },
                    }}
                />
            </div>
        </React.Fragment>
    );
}