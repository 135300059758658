import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const FileValidationRequestService = {
  async getParticipatedDemands() {
    try {
      const result = await axios.get(
        `${apiUrl}/FileValidationRequest/myParticipatedDemands/`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getFileValidationRequest(){
    try {
        const result = await axios.get(`${apiUrl}/FileValidationRequest/`);
        if (result) {
          // TODO: replace result.data.message by the token

          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    
  }
   ,
  async getMyFileValidations() {
    try {
      const result = await axios.get(
        `${apiUrl}/FileValidationRequest/myPendingValidations/`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getMyPendingDemands() {
    try {
      const result = await axios.get(
        `${apiUrl}/FileValidationRequest/myPendingDemands/`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async checkFileValidationRequestType(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/FileValidationRequest/getRequestType/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getFileValidationForLeaveById(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/FileValidationRequest/getFileValidationForLeaveById/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  
  async getAllPendingDemands() {
    try {
      const result = await axios.get(
        `${apiUrl}/FileValidationRequest/getAllPendingDemands/`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getUserSupOfPendingValidationByFileRequest(id_file) {
    try {
      const result = await axios.get(
        `${apiUrl}/FileValidationRequest/getUserSupOfPendingValidationByFileRequest/${id_file}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
};

export default FileValidationRequestService;
