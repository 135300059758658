import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const MonthCalendarService = {
      async getMonthCalendar(){
        try {
            const result = await axios.get(`${apiUrl}/monthCalendar/`);
            if (result) {
              // TODO: replace result.data.message by the token
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        }
      ,
}
export default MonthCalendarService;
