import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FamilyMemberService from "../../../services/FamilyMemberService";
import Card from '@material-ui/core/Card';

const apiUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition1: {
    float: 'left',
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: 'left',
    fontWeight: 'bold'
  },
  root: {
    width: 200,
    height: 200,
    marginTop: '5.5%',
  },
}));

export default function PersonalSummary(props) {
  const classes = useStyles();
  const [row, setRow] = useState([]);

  useEffect(() => {
    FamilyMemberService.getPersonalSummaryById(props.user_id).then((result) => {
      if(result && result.data){
        setRow(result.data);
      }
    });
  }, []); 

  return (
    <Grid container /*className={classes.paper}*/ variant="outlined">
      <Grid container item spacing={3} xs={8}>
        <Grid item xs={5}>
            <label className={classes.labelPosition}>Nom : </label>
        </Grid>
        <Grid item xs={7}>
            <label className={classes.labelPosition1}>{row.first_name}</label>
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition}>Prénom(s) : </label>
        </Grid>
        <Grid item xs={7}>
            <label className={classes.labelPosition1}>{row.last_name}</label>
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition}>Fonction : </label>
        </Grid>
        <Grid item xs={7}>
            <label className={classes.labelPosition1}>{row.function_name}</label>
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition}>Direction : </label>
        </Grid>
        <Grid item xs={7}>
            <label className={classes.labelPosition1}>{row.direction_name}</label>
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition}>Sexe : </label>
        </Grid>
        <Grid item xs={7}>
            <label className={classes.labelPosition1}>{row.sex}</label>
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition}>E-mail : </label>
        </Grid>
        <Grid item xs={7}>
            <label className={classes.labelPosition1}>{row.email}</label>
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition}>Contact en cas d'urgence : </label>
        </Grid>
        <Grid item xs={7}>
            <label className={classes.labelPosition1}>{row.emergency_phone}</label>
        </Grid>
      </Grid>
      <Grid container item xs={4}>
        <Card className={classes.root}>
          <img
            src={row.name_image}
            title={row.first_name}
            alt={row.first_name}
            height={200}
            width={200}
          />
          
        </Card>
      </Grid>
    </Grid>
  );
}