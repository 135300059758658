import MainContainer from "../../../components/MainContainer";
import { headerData } from "../../../data/modules/my-validations-header";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper } from "@material-ui/core";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import ImmobilisationMineTable from "./ImmobilisationMineTable";
import ValidateRequest from "../../tools/ValidateImmobilisationStepRequest";
import InventoryService from "../../../services/InventoryService";
import Message from "../../tools/Message";
import FilterValidation from "../../../components/FilterValidation";
import filterSubmit from "../../../components/FilterValidation";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  style1: {
    fontSize: "5em",
  },
}));
export default function ImmobilisationMineList() {
  const classes = useStyles();
  const [status, setStatus] = useState([]);
  const [rows, setRows] = useState([]);
  const [acceptButton, setAcceptButton] = useState(true);
  const [logistics_manager, setLogistics_manager] = useState([]);
  const [openValidateRequest, setOpenValidateRequest] = useState(false);
  const [message, setMessage] = useState({
      open: false,
      color: 'green',
      messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  const wscols = [
    { wch: 25 },
    { wch: 25 },
    { wch: 45 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 45 },
    { wch: 10 },
    { wch: 25 },
  ];

  const wscolsDetenteur = [
    { wch: 25 },
    { wch: 25 },
    { wch: 45 },
    { wch: 45 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
  ];
  const [form, setForm] = useState({
    name_materiel: "",
    code_immo: "",
    state: "",
    logistics_manager:"",
    status1: "",
    status2: "",
  });
  
  function handleValidateRequest() {
    localStorage.setItem("immoStatusStorage", "CONFIRMED");
    localStorage.setItem("confirmationByOldHolderStorage", true);
    console.log('All validated: ',localStorage.getItem("confirmationByOldHolderStorage"));
    setOpenValidateRequest(true);
  }

  function handleRefusedRequest() {
    localStorage.setItem("declinedByOldHolderStorage", true);
    setOpenValidateRequest(true);
  }

  
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  const SetFilter = (name,value)=>{
    if(!value){
      if(localStorage.getItem(name)){
        localStorage.setItem(name,localStorage.getItem(name));
      }
    }
    else{

      localStorage.setItem(name,value);
    }
   
  }
    const handleSubmit = (e) => {
      window.location.reload();

    };
    const Refresh = (e) => {
      e.preventDefault();
        localStorage.removeItem('name_materiel');
        localStorage.removeItem('code_immo');
        localStorage.removeItem('state');
        localStorage.removeItem('logistics_manager');
        localStorage.removeItem('status1');
        localStorage.removeItem('status2');
        localStorage.setItem('name_materiel',"");
        localStorage.setItem('code_immo',"");
        localStorage.setItem('state',"");
        localStorage.setItem('logistics_manager',"");
        localStorage.setItem('status1',"");
        localStorage.setItem('status2',"");
        
        window.location.reload()
    };
      
      //}
      useEffect(() => {
          var countList = InventoryService.getCountMinePendingImmoHistory().then((result) => {
            if (result && result.status) {
              setAcceptButton(true);
          } else {
            setAcceptButton(false);
          }
        });
          
      }, []);
  const handleCloseValidateRequest = (event) => {
    setOpenValidateRequest(false);
  }

  return (
    <>
    <MainContainer {...headerData}>
      <Grid container item xs={12}>
        <Button
                  variant="contained"
                  onClick={Refresh}
                  style={{ fontWeight: 600, backgroundColor:'darkred', color:'white'}}
                >
                  réinitialiser le filtre
                </Button>
                <Spacer x={2} />
                <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ fontWeight: 600} }
              >
                Filtrer
              </Button>
        <Spacer x={2} />

      <FilterValidation type={2} />

      { acceptButton
            && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleValidateRequest}
                style={{ fontWeight: 600, backgroundColor:"green", color:"#FFFFFF", marginLeft: "auto"  }}
              >
                TOUS CONFIRMER / TOUS ACCEPTER
              </Button>
      )}
      <Spacer x={1} />
      { acceptButton
              && (
                <Button
                  variant="contained"
                  color="secondary"
                >
                  TOUS REFUSER
                </Button>
        )}
      </Grid>
      <Spacer y={2} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <ImmobilisationMineTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
    
    <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
    
    <ValidateRequest
        openValidateRequest={openValidateRequest}
        handleCloseValidateRequest={handleCloseValidateRequest} />
    </>
    
  );
}
