import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Message from "../tools/Message";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import Loading from '../../components/loading';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, MenuItem, Select, FormControl } from "@material-ui/core";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogExport: {
        margin: "0 important",
    },
    displayBlock: {
        display: "inline-flex",
    },
    displayNone: {
        display: "none",
    }
});

const useStyles = makeStyles((theme) => ({
    gridLoading: {
        margin: "auto"
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function PlanningPerSemesterExportFilter(props) {
    const openDialog = props.openDialog;
    const closeDialog = props.closeDialog;
    const handleChangeRadioButton = props.handleChangeRadioButton;
    const confirmDialog = props.confirmDialog;
    const cancelDialog = props.cancelDialog;
    const value = props.radioButtonValue;
    const isLoading = props.isLoading;
    const semestre = props.semestre;
    const direction = props.direction;
    const service = props.service;
    const valueSemesterFilter = props.valueSemesterFilter;
    const direction_id_filter = props.idDirFilter;
    const service_id_filter = props.idServiceFilter;
    const dir = props.dir;
    const row = props.row;
    const monthName = props.monthSelected;
    const handleChangeSemester = props.handleChangeSemester;
    const handleChangeDirection = props.handleChangeDirection;
    const handleChangeService = props.handleChangeService;

    const isOther = props.isOther;
    const [message, setMessage] = React.useState({
        open: false,
        color: 'green',
        messageInfo: '',
    });

    const [displayIfIsOther, setDisplayIfIsOther] = React.useState(false);
    const { open, color, messageInfo } = message;
    const classes = useStyles();
    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }

    useEffect(() => {
        console.log("isOther", isOther);
        if (isOther == 1) {
            setDisplayIfIsOther(true);
        } else {
            setDisplayIfIsOther(false);
        }

    }, [isOther]);
    return (
        <div>
            <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth
                maxWidth="sm">
                <DialogTitle id="form-dialog-title" color="primary" /*style={{backgroundColor: '#0000B3'}}*/ >{"Exportation suivi planning par semestre"}</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
                        <FormControl component="fieldset">
                            <RadioGroup
                                // aria-label="gender"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={handleChangeRadioButton}
                            >
                                <FormControlLabel value="0" control={<Radio />} label={"Avec le filtre actuel: " + `${semestre}` + " - " + `${direction}` + " - " + `${service}`} />
                                <FormControlLabel value="1" control={<Radio />} label="Autre filtre sans service" />
                            </RadioGroup>
                        </FormControl>
                    </DialogContentText> */}
                    <Grid container justifyContent="center" spacing={2} >
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined" size="small">
                                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={valueSemesterFilter} name="semester" onChange={handleChangeSemester} >
                                    <MenuItem value="1">1er semestre</MenuItem>
                                    <MenuItem value="2">2ème semestre</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>

                            <FormControl fullWidth variant="outlined" size="small">
                                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={direction_id_filter} name="direction_id" onChange={handleChangeDirection}>
                                    {dir.map((row) => (
                                        <MenuItem value={row.direction_id}>{row.code}</MenuItem>

                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {service.length > 0 &&
                            <Grid item xs={4}>
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={service_id_filter} name="service" onChange={handleChangeService} >

                                        <MenuItem value={0}>{"Tous service"}</MenuItem>
                                        {service.map((row) => (
                                            <MenuItem value={row.service_id}>{row.code}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {isLoading && <Grid xs={7} className={classes.gridLoading}>
                        <p>Veuillez patienter, ceci peut prendre beaucoup de temps</p>
                        <Loading />
                    </Grid>}
                    {!isLoading &&
                        <>
                            <Button variant="contained" color="primary" onClick={confirmDialog} >Confirmer</Button>
                            <Button variant="contained" color="secondary" onClick={cancelDialog} >Annuler</Button>
                        </>
                    }
                </DialogActions>
            </Dialog>

            {/* <ExportExcel dataToExport={dataToExport} fileName={fileName} /> */}
        </div>
    );
}

