import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const ServiceService = {
    async getService(){
        try{
            const result = await axios.get(`${apiUrl}/service/`);
            if (result) {
              return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getDirectionByType(type){
        try {
            const result = await axios.get(`${apiUrl}/service/type/${type}`  );
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async saveService(direction_id, name, code, description, service_id){
        try{
            const result = service_id?await axios.put(`${apiUrl}/service/id/${service_id}`, {
                'direction_id':direction_id,
                'name':name, 
                'code':code, 
                'description':description
           }):await axios.post(`${apiUrl}/service`, {
                'direction_id':direction_id,
                'name':name, 
                'code':code, 
                'description':description
           });
           if (result) {
             // TODO: replace result.data.message by the token
              return result.data;
           }
        }catch(error){
            if(error.response){
              return error.response.data;
            }
        }
    },
    async deleteService(id){
        try {
            const result = await axios.delete(`${apiUrl}/service/id/${id}`  );
            if (result) {
                // TODO: replace result.data.message by the token
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getServiceById(service_id){
        try{
            const result = await axios.get(`${apiUrl}/service/id/${service_id}`);
            if (result) {
                // TODO: replace result.data.message by the token
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async exportExcel(){
        try{
            const result = await axios.get(`${apiUrl}/service/export`);
            if (result) {
                // TODO: replace result.data.message by the token
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async importService(formData){
        try{
            const result = await axios.post(`${apiUrl}/service/import`, formData);
           if (result) {
             // TODO: replace result.data.message by the token
              return result.data;
           }
        }catch(error){
            if(error.response){
              return error.response.data;
            }
        }
    },
    async getServById(direction_id){
        try {
            const result = await axios.get(`${apiUrl}/service/getServiceByIdDirection/${direction_id}`);
            if (result) {
                // TODO: replace result.data.message by the token
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}

export default ServiceService;
