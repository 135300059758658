import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import Spacer from '../../components/Spacer';
import MainContainer from '../../components/MainContainer';
import { headerDataAdmin } from '../../data/modules/admin-header';
import { userDetailContext } from "../../App";
import {useContext} from "react";
import { hasPrivilege } from "../../utils/privilege";
import Title from "../../components/Title";
import { useNavigate } from 'react-router-dom';
import CandidatureTable from './CandidatureTable';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    elevation: 0
  },
}));


export default function CandidatureList() {

  const navigate = useNavigate();
  const classes = useStyles();

  return (
    
    <MainContainer {...headerDataAdmin}>  
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <CandidatureTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
