import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spacer from "../../components/Spacer";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
} from "@material-ui/core";
import TextField from '@mui/material/TextField';
import RefusedRequest from "./RefusedRequest";
import ValidateRequest from "./ValidateRequest";
import RefusedRequestService from "../../services/RefusedRequestService";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import LeaveService from "../../services/LeaveService";
import BalanceConfigurationService from "../../services/BalanceConfigurationService";
import FileValidationRequestService from "../../services/FileValidationRequestService";
import { CircularProgress } from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  step1Title: {
    textAlign: "center",
  },
  step1Grid: {
    padding: "10px",
  },
  gridItem: {
    height: "25px",
  },
  id: {
    color: "#3f51b5",
    fontWeight: "bold",
  },
}));

export default function RequestSummary(props) {
  const requestSummaryId = props.requestSummaryId;
  const isRhConnected = props.isRhConnected;
  const classes = useStyles();
  const open = props.openDialogRequestSummary;
  const handleCloseRequestSummary = props.handleCloseRequestSummary;
  const [step, setStep] = useState(1);
  const [openRefusedRequest, setOpenRefusedRequest] = useState(false);
  const [openValidateRequest, setOpenValidateRequest] = useState(false);
  const [formular, setFormular] = useState([]);
  const [form, setForm] = useState({ validation_with_observation: "" });
  const [balanceBefore, setBalanceBefore] = useState(0);
  const [realdelta, setRealdelta] = useState(0);
  const [balanceAfters, setBalanceAfters] = useState(0);
  const [afterdelta, setAfterdelta] = useState(0);
  const [leaveDuration, setleaveDuration] = useState(0);
  const [rows, setRows] = useState([]);
  const [stateLeave, setStateLeave] = useState(0);
  const [supHierarchique, setSupHierarchique] = useState("");
  const [leave, setLeave] = useState([]);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [openLoadingValidateLeave, setOpenLoadingValidateLeave] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [observationWithValidation, setObservationWithValidation] = useState("");


  useEffect(() => {
    if (open) {
      setOpenState(true);
    } else {
      setOpenState(false);
    }

  }, [open]);

  useEffect(() => {
    if (openState) {
      setOpenLoadingSimpleBackdrop(true);
      setOpenLoadingValidateLeave(true);
      FileValidationRequestService.getMyFileValidations().then((result) => {
        if (result && result.data) {
          console.log(result.data);
          setRows(result.data[0]);

          setOpenLoadingSimpleBackdrop(false);
        } else {
          setOpenLoadingSimpleBackdrop(false);
        }
      });

      LeaveService.getLeave(requestSummaryId.file_validation_request_id).then((result) => {
        if (result && result.data) {
          const userIdOfLeave = result.data.user_id;
          const leaveId = result.data.leave_id;
          const nbOfDayRequested = result.data.duration;
          setleaveDuration(result.data.duration);
          setLeave(result.data);
          // setOpenLoadingSimpleBackdrop(false);

          BalanceConfigurationService.getConfigBalanceByUserId(userIdOfLeave).then((result) => {
            if (result && result.data) {
              setBalanceBefore(result.data.real_balance);
              const balance_after = result.data.real_balance - nbOfDayRequested;
              setBalanceAfters(balance_after);
              setOpenLoadingSimpleBackdrop(false);
            }
          });

          LeaveService.getStateLeave(leaveId).then((result) => {
            if (result && result.status) {
              setStateLeave(result.data.states);
              setOpenLoadingSimpleBackdrop(false);
            }
          });

          FileValidationRequestService.getUserSupOfPendingValidationByFileRequest(requestSummaryId.file_validation_request_id)
            .then((result) => {
              if (result && result.status) {
                const userSupFullName = `${result.data.last_name} ${result.data.first_name} `;
                setSupHierarchique(userSupFullName);
                setOpenLoadingSimpleBackdrop(false);
              }
            });
          setOpenLoadingValidateLeave(false);
        }
      });
    }
  }, [openState]);

  function handleRefused() {
    RefusedRequestService.getFormular(requestSummaryId.module_type_id, requestSummaryId.type_observation).then((result) => {
      if (result && result.data) {
        setFormular(result.data);
      }
    });
    setStep(2);
    setOpenRefusedRequest(true);
  }

  function handleValidateRequest() {
    setStep(2);
    setOpenValidateRequest(true);
  }

  function handleChangeObservationWithValidation(event){
    const {name, value} = event.target;
    setForm({ ...form, [name]: value });
    setObservationWithValidation(value);
    localStorage.setItem("observation", JSON.stringify(value));
  }

  const handleCloseRefusedRequest = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setStep(1);
    setOpenRefusedRequest(false);
  }

  const handleCloseValidateRequest = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setStep(1);
    setOpenValidateRequest(false);
  }

  function handleCloseRefusedDialog(){
    setOpenRefusedRequest(false);
  }

  return (
    <>
      <RefusedRequest
        type_observation={requestSummaryId.type_observation}
        formular={formular}
        module_type_id={requestSummaryId.module_type_id}
        file_validation_request_id={requestSummaryId.file_validation_request_id}
        validation_id={requestSummaryId.validation_id}
        openRefusedRequest={openRefusedRequest}
        handleCloseRefusedRequest={handleCloseRefusedRequest} 
        handleCloseRefusedDialog={handleCloseRefusedDialog}/>
      <ValidateRequest
        openValidateRequest={openValidateRequest}
        validation_id={requestSummaryId.validation_id}
        module_type_id={requestSummaryId.module_type_id}
        file_validation_request_id={requestSummaryId.file_validation_request_id}
        handleCloseValidateRequest={handleCloseValidateRequest}
        user_requested_id={requestSummaryId.submitter_user_id} />
      {step == 1 &&
        <Dialog open={open} onClose={handleCloseRequestSummary} aria-labelledby="form-dialog-title1" fullWidth maxWidth="sm">
          <DialogTitle id="form-dialog-title1">
            Validation éléctronique
          </DialogTitle>
          <DialogContent>

            {requestSummaryId.module_type_id == 3 || requestSummaryId.module_type_id == 2 ?
              <DialogContentText>

                <Grid container spacing={2} >

                  <Grid item xs={4}>
                    <label className={classes.id}>
                      Identification:</label>
                  </Grid>

                  <Grid item xs={8}>
                    <label className={classes.id}>{rows['leave_code']}</label>
                  </Grid>

                  <Grid item xs={4}>
                    <label className={classes.label}>Nom et Prénom du demandeur:</label>
                  </Grid>
                  <Grid item xs={8}>
                    <label className={classes.label1}> {rows['last_name']} {rows['first_name']}</label>
                  </Grid>


                  <Grid item xs={4}>
                    <label className={classes.label}>Type :</label>
                  </Grid>
                  <Grid item xs={2}>
                    <label className={classes.label1}>{rows['type_mission_request']}</label>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <label className={classes.label1}></label>
                  </Grid>

                  
                  <Grid item xs={4}>
                    <label className={classes.label}>Nombre de jours:</label>
                  </Grid>
                  <Grid item xs={8}>
                    <label className={classes.label1}>{rows['duration']}</label>
                  </Grid>

                  {isRhConnected &&
                    <>
                      <Grid item xs={4}>
                        <label className={classes.label}>Solde actuel:</label>
                      </Grid>
                      <Grid item xs={8}>
                        <label className={classes.label1}>{balanceBefore}</label>
                      </Grid>
                      <Grid item xs={4}>
                        <label className={classes.label}>Reliquat après la présente demande:</label>
                      </Grid>
                      <Grid item xs={8}>
                        <label className={classes.label1}>{balanceAfters}</label>
                      </Grid>
                    </>
                  }


                  <Grid item xs={4}>
                    <label className={classes.label}>Motif: </label>
                  </Grid>
                  <Grid item xs={8}>
                    <label className={classes.label1}>{rows['reason']}</label>
                  </Grid>

                  {isRhConnected &&
                    <>
                      <Grid item xs={4}>
                        <label className={classes.label}>Statut de la demande:</label>
                      </Grid>
                      <Grid item xs={8}>
                        <label className={classes.label1}>Réel {stateLeave}, en attente de validation de {supHierarchique}</label>
                      </Grid>
                    </>
                  }
                </Grid>

                <br></br>
                <span>
                  La demande sous forme{" "}
                  <Link to={"/file/" + requestSummaryId.path_folder + "/" + requestSummaryId.filename}> pdf. </Link>
                  <br></br>
                  {!isRhConnected &&
                    <span>Confirmez-vous votre action?</span>
                  }

                </span>
              </DialogContentText>
              :

              <DialogContentText>

                <Grid container spacing={2} >

                  <Grid item xs={4}>
                    <label className={classes.id}>Identification:</label>
                  </Grid>

                  <Grid item xs={8}>
                    <label className={classes.id}>{leave['leave_code']}</label>
                  </Grid>

                  <Grid item xs={4}>
                    <label className={classes.label}>Nom et Prénom du demandeur:</label>
                  </Grid>
                  <Grid item xs={8}>
                    <label className={classes.label1}> {leave['last_name']} {leave['first_name']}</label>
                  </Grid>


                  <Grid item xs={4}>
                    <label className={classes.label}>Date de Départ:</label>
                  </Grid>
                  <Grid item xs={2}>
                    <label className={classes.label1}>{leave['begin_time']}</label>


                  </Grid><Grid item xs={6}>
                    <label className={classes.label1}>{leave['begin_time_session'] === "morning" ? "Matin" : "Après-midi"}</label>


                  </Grid>

                  <Grid item xs={4}>
                    <label className={classes.label}>Date de retour:</label>
                  </Grid>
                  <Grid item xs={2}>
                    <label className={classes.label1}>{leave['end_time']}</label>

                  </Grid>
                  <Grid item xs={6}>
                    <label className={classes.label1}>{leave['end_time_session'] === "morning" ? "Matin" : "Après-midi"}</label>

                  </Grid>

                  <Grid item xs={4}>
                    <label className={classes.label}>Nombre de jours:</label>
                  </Grid>
                  <Grid item xs={8}>
                    <label className={classes.label1}>{leave['duration']}</label>
                  </Grid>

                  {isRhConnected &&
                    <>
                      <Grid item xs={4}>
                        <label className={classes.label}>Solde actuel:</label>
                      </Grid>
                      <Grid item xs={8}>
                        <label className={classes.label1}>{balanceBefore}</label>
                      </Grid>
                      <Grid item xs={4}>
                        <label className={classes.label}>Reliquat après la présente demande:</label>
                      </Grid>
                      <Grid item xs={8}>
                        <label className={classes.label1}>{balanceAfters}</label>
                      </Grid>
                    </>
                  }


                  <Grid item xs={4}>
                    <label className={classes.label}>Motif:</label>
                  </Grid>
                  <Grid item xs={8}>
                    <label className={classes.label1}>{leave['reason']}</label>
                  </Grid>
                  
                  
                  <Grid item xs={4}>
                    <label className={classes.label}>Observation: </label>
                  </Grid>
                  <Grid item xs={8}>
                      <TextField
                          fullWidth
                          id="outlined-basic"
                          size="small"
                          label="Validation avec observation"
                          variant="outlined"
                          value={form.validation_with_observation}
                          name="validation_with_observation"
                          onChange={handleChangeObservationWithValidation}
                      />
                  </Grid>

                  {isRhConnected &&
                    <>
                      <Grid item xs={4}>
                        <label className={classes.label}>Statut de la demande:</label>
                      </Grid>
                      <Grid item xs={8}>
                        <label className={classes.label1}>Réel {stateLeave}, en attente de validation de {supHierarchique}</label>
                      </Grid>
                    </>
                  }
                </Grid>

                <br></br>
                <span>
                  La demande sous forme{" "}
                  <Link to={"/file/" + requestSummaryId.path_folder + "/" + requestSummaryId.filename}> pdf. </Link>
                  <br></br>
                  {!isRhConnected &&
                    <span>Confirmez-vous votre action?</span>
                  }

                </span>
              </DialogContentText>


            }

          </DialogContent>
          <br />
          <DialogActions>
            {!isRhConnected &&
              <>
                <Button style={{ backgroundColor: "#f50057", color: "#FFFFFF" }} onClick={handleCloseRequestSummary} color="secondary" variant="contained" >
                  Annuler
                </Button>
                <Button style={{ backgroundColor: "#3f51b5", color: "#FFFFFF" }} onClick={handleRefused} color="primary" variant="contained" >
                  Refuser
                </Button>
                <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF", }} onClick={handleValidateRequest} color="primary" variant="contained"
                // disabled={openLoadingValidateLeave ? true : false}
                > Valider
                  {/* {openLoadingValidateLeave ? <CircularProgress /> : "Valider"} */}
                </Button>
              </>
            }

            {isRhConnected &&

              <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF" }} onClick={handleCloseRequestSummary} color="secondary" variant="contained" >
                OK
              </Button>
            }
          </DialogActions>


        </Dialog>}
    </>
  );
}


