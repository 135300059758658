import { Button } from "@material-ui/core";
import {
  CreateTwoTone,
  DeleteOutlineRounded,
} from "@material-ui/icons";
import SettingsApplicationsSharpIcon from "@mui/icons-material/SettingsApplicationsSharp";
import React, { useEffect, useState } from "react";
import RubriquesService from "../../services/RubriquesService";
import DocumentService from "../../services/DocumentService";

export default function PrivilegeButton(props) {
  const rubriqueId = props.rubriqueId;
  const documentId = props.documentId;
  const userId = props.userId;
  const editPrivilege = props.edit;
  const deletePrivilege = props.delete;
  const privilegeForm = props.privilegeForm;
  const moduleType = props.module_type;

  const [privilege, setPrivilege] = useState({
    modifier: false,
    supprimer: false,
    voir: false,
    configurer_privilege: false,
  });

  useEffect(() => {
    if (rubriqueId !== undefined) {
      RubriquesService.getAction(rubriqueId, userId).then((response) => {
        if (response.status) {
          // console.log(
          //   "privilegebutton pour rubrique ",
          //   rubriqueId,
          //   ": ",
          //   response
          // );

          const action = response.data.action;

         // console.log("data.action pour rubrique ", rubriqueId, ": ", action);

          if (action[2] === "1") {
           // console.log("see rubrique: ", 1);
            setPrivilege((prev_value) => ({
              ...prev_value,
              voir: true,
            }));
          }

          if (action[4] === "1") {
            //console.log("edit rubrique: ", 1);
            setPrivilege((prev_value) => ({
              ...prev_value,
              modifier: true,
            }));
          }

          if (action[6] === "1") {
            //console.log("delete rubrique: ", 1);

            setPrivilege((prev_value) => ({
              ...prev_value,
              supprimer: true,
            }));
          }

          // if (action[8] === "1") {
          //   //console.log("configurer le privilège: ", 1);

          //   setPrivilege((prev_value) => ({
          //     ...prev_value,
          //     configurer_privilege: true,
          //   }));
          // }
        }
      });
    } else if (documentId !== undefined) {
      DocumentService.getAction(userId, documentId).then((response) => {
        if (response.status) {
          // console.log(
          //   "privilegebutton pour document ",
          //   documentId,
          //   ": ",
          //   response
          // );

          const action = response.data.action;

          //console.log("data.action pour document ", documentId, ": ", action);

          if (action[2] === "1") {
            //console.log("see document: ", 1);
            setPrivilege((prev_value) => ({
              ...prev_value,
              voir: true,
            }));
          }

          if (action[4] === "1") {
           // console.log("edit document: ", 1);
            setPrivilege((prev_value) => ({
              ...prev_value,
              modifier: true,
            }));
          }

          if (action[6] === "1") {
           // console.log("delete document: ", 1);

            setPrivilege((prev_value) => ({
              ...prev_value,
              supprimer: true,
            }));
          }

          // if (action[8] === "1") {
          //   console.log("configurer le privilège: ", 1);

          //   setPrivilege((prev_value) => ({
          //     ...prev_value,
          //     configurer_privilege: true,
          //   }));
          // }
        }
      });
    } else console.log("undefined ny id doc");
  }, [rubriqueId, documentId]);

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          padding: "20px",
        }}
      >
        {privilege.modifier === true ? (
          <div>
            <Button onClick={editPrivilege} title="Modifier">
              <CreateTwoTone style={{ color: "black" }} />
            </Button>
          </div>
        ) : (
          <div></div>
        )}

        {privilege.supprimer === true ? (
          <div>
            <Button onClick={deletePrivilege} title="Supprimer">
              <DeleteOutlineRounded style={{ color: "red" }} />
            </Button>
          </div>
        ) : (
          <div></div>
        )}

        {/* {privilege.configurer_privilege === true ? (
          <div>
            <Button onClick={privilegeForm} title="Configurer les privilèges">
              <SettingsApplicationsSharpIcon style={{ color: "grey" }} />
            </Button>
          </div>
        ) : (
          <div></div>
        )} */}
      </div>
    </>
  );
}
