import React, { useState, useEffect } from 'react'
import AnnualCalendarService from "../../services/AnnualCalendarService";
import MonthCalendarService from "../../services/MonthCalendarService";
import { Button, TextField, Select, MenuItem, FormControl } from "@material-ui/core";
import { CreateTwoTone, DeleteOutlineRounded, CheckTwoTone} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import TextColumnFilter from "../../components/filter/TextColumnFilter";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import Message from "../tools/Message";
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableContainer } from '@material-ui/core';
import DayService from "../../services/DayService";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import Spacer from '../../components/Spacer';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  // root: {
  //   fontWeight: "bold",
  // },
  // Table: {
  //   border: "2px",
  // },
  // p:{
  //   width:"20px",
  // }
  tdtrWithBorder:{
    border: "1px solid black"
  },
  actionBtn:{
    verticalAlign: "top !important",
  },
  tdDescription:{
    verticalAlign: "top !important",
    
  }
})); 
function AnnuarCalendarTable() {
  const navigate = useNavigate();
  const [id_to_delete, setId_to_delete] = useState(0);
  const [rows, setRows] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez vous vraiment supprimer  ?',
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const contextData = useContext(userDetailContext);
  const [compt, setCompt] = useState();

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  function loadTable() {

    setOpenLoadingSimpleBackdrop(true);
    AnnualCalendarService.getAnnualCalendar().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.annual_calendar_id })));
      }
    });
    MonthCalendarService.getMonthCalendar().then((results) => {
      if (results && results.data) {
        setData(results.data.map((row) => ({ ...row, id: row.month_calendar_id })));
      }
    });
    DayService.getDays().then((results) => {
      if (results && results.data) {
        setDays(results.data);
        setOpenLoadingSimpleBackdrop(false);
      }
    });

  }
  const handleDelete = (id) => (ev) => {
    setId_to_delete(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true });
  };
  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    AnnualCalendarService.deleteAnnualCalendar(id_to_delete).then((result) => {
      AnnualCalendarService.getAnnualCalendar().then((result) => {
        if (result && result.data) {
          setData(result.data);
        }
      });
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
        loadTable();
        loadTable();
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
    });
  }
  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  const handleActivate = (id) => (ev) => {
    AnnualCalendarService.ActivateAnnualCalendar(id).then((res) => {
      var responseErrorMessage = res.message;
      if (res.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: res.message });
        loadTable();
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: res.message });
      }

    })
  };

  const handleDesactivate = (id) => (ev) => {
    AnnualCalendarService.desactivateAnnualCalendar(id).then((res) => {
      var responseErrorMessage = res.message;
      if (res.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: res.message });
        loadTable();
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: res.message });
      }

    })
  };
  const handleEdit = (id) => (ev) => {
    navigate("/annual_calendar/add/" + id);
  };
  const handleEditMonth = (id, anid) => (ev) => {
    navigate("/annual_calendar/month/" + id + "/" + anid);
  };
  function createData(month_calendar_id, month_calendar) {
    return { month_calendar_id, month_calendar };
  }
  const columns = React.useMemo(
    () => [
      { Header: 'day_label', accessor: 'day_label', Filter: TextColumnFilter, width: 400, },
      { Header: 'years', accessor: 'years', Filter: TextColumnFilter, width: 400, },
      { Header: 'desc', accessor: 'desc', Filter: TextColumnFilter, width: 400, },
      { Header: 'active', accessor: 'active', Filter: TextColumnFilter, width: 400, },
      { Header: 'month_calendar', accessor: 'année', Filter: TextColumnFilter, width: 400, },
      {
        Header: "Actions", accessor: "actions", disableSortBy: true, disableFilters: true,
        Cell: (props) => {
          const rowIdx = props.row.original.leave_type_id;
          return (
            <strong>
              <Button onClick={handleEdit(rowIdx)}>
                <CreateTwoTone style={{ color: "black" }} />
              </Button>
              <Button onClick={handleDelete(rowIdx)}>
                <DeleteOutlineRounded style={{ color: "red" }} />
              </Button>
              <Button onClick={handleActivate(rowIdx)}>
                <CheckTwoTone style={{ color: "black" }} />
              </Button>
            </strong>
          );
        },
      }
    ],
    []
  )
  const [data, setData] = React.useState([])
  const [days, setDays] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const [rowCount, setRowCount] = React.useState(0)
  const fetchIdRef = React.useRef(0)
  const classes = useStyles();
  const fetchData = React.useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
    var newId = fetchIdRef.current++;
    const fetchId = newId;
  }, []);

  useEffect(() => {

    setOpenLoadingSimpleBackdrop(true);
    AnnualCalendarService.getAnnualCalendar().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.annual_calendar_id })));
        setCompt(result.count);
      }
    });
    MonthCalendarService.getMonthCalendar().then((results) => {
      if (results && results.data) {
        setData(results.data.map((row) => ({ ...row, id: row.month_calendar_id })));
      }
    });
    DayService.getDays().then((results) => {
      if (results && results.data) {
        setDays(results.data);

        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);
  return (
    // <Title>Listes des parametrages des jours non comptés</Title>

    <>
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      {/* <Title>Liste des jours non comptés dans le congé</Title> */}
      <Spacer y={2} />
      <React.Fragment>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={clsx(classes.root, classes.tdtrWithBorder)}>Actions</TableCell>
                <TableCell className={clsx(classes.root, classes.tdtrWithBorder)}>Libelle jours</TableCell>
                <TableCell className={clsx(classes.root, classes.tdtrWithBorder)}>Année</TableCell>
                <TableCell className={clsx(classes.root, classes.tdtrWithBorder)}>Description</TableCell>
                <TableCell className={clsx(classes.root, classes.tdtrWithBorder)}>Actif</TableCell>
                <TableCell className={clsx(classes.root, classes.tdtrWithBorder)} style={{ width: 'max-content', }}>Jours non compté</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow className={classes.tdtrWithBorder} key={row.annual_calendar_id}>
       
                  <TableCell className={clsx(classes.tdtrWithBorder, classes.actionBtn)}>
                    <Button onClick={handleEdit(row.annual_calendar_id)} title="Modifier les paramètres"><CreateTwoTone style={{ color: "black" }} /></Button>
                    <Button onClick={handleDelete(row.annual_calendar_id)} title="Supprimer les paramètres"><DeleteOutlineRounded style={{ color: "red" }} /></Button>
                    {row.active !== "oui" && <Button onClick={handleActivate(row.annual_calendar_id)} title="Activer cet paramètre"><CheckTwoTone style={{ color: "green" }} /></Button>}
                    {row.active === "oui" && <Button onClick={handleDesactivate(row.annual_calendar_id)} title="Désactiver cet paramètre"><CloseIcon style={{ color: "red" }} /></Button>}
                  </TableCell>
                  <TableCell className={clsx(classes.text, classes.tdtrWithBorder, classes.tdDescription)}>{row.day_label}</TableCell>
                  <TableCell className={clsx(classes.text, classes.tdtrWithBorder, classes.tdDescription)}>{row.years}</TableCell>
                  <TableCell className={clsx(classes.text, classes.tdtrWithBorder, classes.tdDescription)}>{row.desc}</TableCell>
                  <TableCell className={clsx(classes.text, classes.tdtrWithBorder, classes.tdDescription)}>{row.active== "oui" ? "OUI":"NON"}</TableCell>
        
                  <Grid container spacing={3}>
                    {data.map((rowt) =>
                      <Grid item xy={3} xs={6} >

                        <TableCell className={classes.text} >{rowt.month_calendar}
                        <Button onClick={handleEditMonth(rowt.month_calendar_id, row.annual_calendar_id)} title="Ajouter les jours non comptés">
                          <AddCircleOutlineIcon style={{ color: "black" }} />
                        </Button>
                          {days.map((day) =>

                            <p style={{width:"900px"}} >
                              {day.month_calendar_id == rowt.month_calendar_id && day.annual_calendar_id == row.annual_calendar_id && day.date}</p>
                          )}

                        </TableCell> 
                      </Grid>
                    )}
                  </Grid>
                </TableRow>

              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    </>
  );
}
export default AnnuarCalendarTable