import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataAdmin } from "../../data/modules/admin-header";
import DirectionService from "../../services/DirectionService";
import { useLocation } from 'react-router-dom';
import Result from "../personnel/Result";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../utils/privilege";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import SeatService from "../../services/SeatService";
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

function SeatAdd(props) {

  const classes = useStyles();
  const params = useParams();
  const [modules, setModules] = useState([]);
  const idSeat = params.id ? params.id : "";
  const [seats, setSeats] = useState([]);
  const [idservice, setIdservice] = useState(0);
  const [iddir, setIddir] = React.useState(0);
  const [service, setService] = useState([]);

  const [form, setForm] = useState({
    direction: "Direction",
    seat_name: "",
    direction_id: [],
  });

  const location = useLocation();
  const { pathname } = location;
  // const splitLocation = pathname.split("/");
  const [open, setOpen] = React.useState(false);
  const [etats, setEtats] = useState({ open: false, message: "message", color: "" });
  const navigate = useNavigate();

  // const handleClickOpen = () => {
  //   navigate("/seat/add/");
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   navigate("/seat");
  // };

  const handleCloseMessage = () => {
    setEtats({ ...etats, open: false });

  };

  // function handleChange(ev) {
  //   const { name, value } = ev.target;
  //   setForm({ ...form, [name]: value });
  // }

  
  function handleSelect() {
    SeatService.getAllDirection().then((result) => {
      if (result && result.data) {
        setModules(
          result.data.map((d) => {
            return { id: d.direction_id, label: d.name, desc: d.desc };
          })
        );

      }
    });
  }

  
  // ---------------------- change load 
  function handleChangeDirection(event) {
    setSeats([]);
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    setIddir(value);
    SeatService.Direction(value).then((results) => {
      if (results && results.data) {
        setSeats(
          results.data.map((d) => {
    // console.log( 'jikcqsqsdqdjk' + d.seat_name);

            // console.log('****asc' + d.seat_id);
            return { id: d.seat_id, label: d.seat_name };
            // return d.seat_name;
          })
        );
      }
    })
  }
  // function handleChangeService(ev) {
  //   console.log('sjkhdkjds' + setIdservice(value));
  //   const { name, value } = ev.target;
  //   setForm({ ...form, [name]: value });
  //   return setIdservice(value);
  // }
  // ------------------------- end change load

  // function handleSelectSeat() {
  //   SeatService.getAsc().then((result) => {
  //     if (result && result.data) {
  //       setSeats(
          
  //         result.data.map((d) => {
  //           // console.log('****asc' + d.seat_id);
  //           return { id: d.seat_id, label: d.seat_name, desc: d.desc };
  //         })
  //       );

  //     }
  //   });
  // }

 

  useEffect(() => {
    if (idSeat) {
      console.log("this ID", idSeat);
      SeatService.SeatById(idSeat).then((info) => {
        // console.log('mbol tafiditr') ;
        console.log("this", info);
        setForm({
          direction: info.data[0].direction,
          seat: info.data[0].seat,

          direction_id: info.data[0].direction_id,
          id: idSeat,
        });
      });
    }
  }, []);


  function handleClick() {
    console.log('vide' + form.seat_name);
    SeatService.saveSeat(form.seat_name, form.direction_id, form.id)
      .then((results) => {
    // console.log("ato lele ***********" + results.status);
        if (results.status) {
          setEtats({ ...etats, open: true, color: 'green', message: results.message });
          navigate("/seat");
        }
        else {
          setEtats({ ...etats, open: true, color: 'red', message: results.message });
        }
      })
      .catch((err) => {
        alert("error");
      });
  }
  function CapitalString(str) {
    var nextCapital = true;
    var newString = "";
    for (var i = 0; i < str.length; i++) {
      var cur = str.charAt(i);
      if (nextCapital) {
        newString += cur.toUpperCase();
      } else {
        newString += cur.toLowerCase();
      }
      if (cur === " ") {
        nextCapital = true;
      } else {
        nextCapital = false;
      }
    }
    return newString;
  };

  function handleChange(ev) {
    const { name, value } = ev.target;
    console.log(name, "*************", value);
    if (name == 'seat_name') {
      setForm({ ...form, [name]: value })
    }
    else if (name == 'direction_id') {
      setForm({ ...form, [name]: CapitalString(value) })
    }
    else {
      setForm({ ...form, [name]: value })

    }
  }
  const contextData = useContext(userDetailContext);
  function handleCancel() {
    navigate("/seat");
  }
  return (
    <MainContainer {...headerDataAdmin}>
      <Result message={etats.message} color={etats.color} open={etats.open} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        {hasPrivilege(contextData.privileges, 'Admin-Button-Save-Direction') &&
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            ENREGISTRER
          </Button>
        }
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
        <Grid item xs={4}>
            <label class="aligneleft">Direction{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Direction
              </InputLabel>
              <Select
                onChange={handleChangeDirection}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="direction"
                value={form.direction_id}
                onFocus={handleSelect}
                name="direction_id"
                // onChange={handleChange}
              >
                {modules.map((row) => (
                  <MenuItem value={row.id}>{row.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <label class="aligneleft">Bureau{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Bureau
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="seat"
                value={form.seat_name}
                // onFocus={handleSelectSeat}
                // onFocus={handleChangeService}
                name="seat_name"
                onChange={handleChange}
              >
                {seats.map((row) => (
                  <MenuItem value={row.label}>{row.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <label className={classes.text}>({bullRed}):Champs obligatoires</label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default SeatAdd;
