import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import Title from "../../components/Title";
import { makeStyles } from "@material-ui/core/styles";
import EquipmentConfigService from "../../services/EquipmentConfigService";
export default function ImmobilisationDetailsTable() {
  const codeImmo = localStorage.getItem("codeImmo");

  const [rows, setRows] = useState([]);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    React.useState(false);
  const code = localStorage.getItem("key1");

  function loadTable() {
    setOpenLoadingSimpleBackdrop(true);
    EquipmentConfigService.getConfigById(code).then((result) => {
      if (result && result.status) {
        console.log("result******", result);
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.immo_config_id,
          }))
        );
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }
  useEffect(() => {
    loadTable();
  }, []);
  const columns = [
    {
      field: "configuration",
      headerName: "Configurations",
      width: 500,
    },
    {
      field: "valeur",
      headerName: "Valeurs",
      width: 500,
    },
  ];
  return (
    <>
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <Title>Matériels: {codeImmo}</Title>
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </>
  );
}
