import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Chip,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import { useLocation } from "react-router-dom";
import ImmobilisationService from "../../services/ImmobilisationService";
import Message from "../tools/Message";
import EquipmentService from "../../services/EquipmentService";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import EquipmentConfigService from "../../services/EquipmentConfigService";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 1000,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: "left",
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));
function ImmoConfigAdd(props) {
  const classes = useStyles();

  const [listOfImmo, setImmoList] = useState([]);
  const [listOfConfig, setConfigList] = useState([]);
  const [form, setForm] = useState({
    code_immo_id: "",
    equipment_config_id: "",
    value: "",
  });
  const [message, setMessage] = useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;

  function handleSelect() {
    ImmobilisationService.getList().then((result) => {
      if (result && result.data) {
        setImmoList(
          result.data.map((d) => {
            return {
              code_immo_id: d.code_immo_id,
              code: d.code_immo_code,
            };
          })
        );
      }
    });
  }
  function handleSelectConfig() {
    EquipmentConfigService.getAllEquipmentConfigId(124).then((result) => {
      if (result && result.data) {
        setConfigList(
          result.data.map((d) => {
            return {
              id: d.equipment_config_id,
              config: d.configuration,
            };
          })
        );
      }
    });
  }
  function handleChange(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  function handleClick() {
    const code = localStorage.getItem("key1");
    EquipmentConfigService.saveConfig(
      (form.code_immo_id = code),
      form.equipment_config_id,
      form.value
    )
      .then((res) => {
        var responseErrorMessage = res.message;
        if (res.status) {
          setMessage({
            ...message,
            open: true,
            color: "green",
            messageInfo: "Succès",
          });
          window.location.href = "Logistic/ranking";
        } else {
          setMessage({
            ...message,
            open: true,
            color: "red",
            messageInfo: responseErrorMessage,
          });
        }
      })
      .catch((err) => {
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: "Une erreur s'est produite. Veuillez réessayer",
        });
      });
  }
  useEffect(() => {
    ImmobilisationService.getList().then((result) => {
      if (result && result.data) {
        setImmoList(
          result.data.map((d) => {
            return {
              id: d.code_immo_id,
              code: d.code_immo_code,
            };
          })
        );
      }
    });
    EquipmentConfigService.getAllEquipmentConfigId(124).then((result) => {
      if (result && result.data) {
        setConfigList(
          result.data.map((d) => {
            return {
              id: d.equipment_config_id,
              config: d.configuration,
            };
          })
        );
      }
    });
  }, []);
  return (
    <MainContainer {...headerDataImmo}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>
        <Spacer x={2} />
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label class="aligneleft">Configuration</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Configuration
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Configuration"
                value={form.equipment_config_id}
                onFocus={handleSelectConfig}
                name="equipment_config_id"
                onChange={handleChange}
              >
                {listOfConfig.map((row) => (
                  <MenuItem value={row.id}>{row.config}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <label class="aligneleft">Valeur</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Nom"
              variant="outlined"
              value={form.value}
              onChange={handleChange}
              name="value"
            />
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}
