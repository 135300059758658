//import React, { Component } from 'react'
//import CssBaseline from '@material-ui/core/CssBaseline'; import Container from '@material-ui/core/Container';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { AppBar, Toolbar } from '@material-ui/core';
import MainContainer from '../../../components/MainContainer';
import { headerData } from '../../../data/modules/portal-header';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import UserService from '../../../services/UserService';
import { Grid } from '@material-ui/core';
import UserInfo from '../../family-member/tabs/UserInfo';
import TextField from "@material-ui/core/TextField";
import { FormControl } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Phone from "../../tools/Phone";
import { CreateTwoTone } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Message from "../../tools/Message";
import { useNavigate, useLocation } from "react-router-dom";
import DetailAccordions from './DetailAccordion';
import Spacer from '../../../components/Spacer';
import { isComputedPropertyName } from 'typescript';
import DirectionService from '../../../services/DirectionService';
import PlanningService from '../../../services/PlanningService';
import ConfirmationDialog from '../../tools/ConfirmationDialog';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
}));
const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100%',
        left: '0px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    welcom: {
        fontSize: '4.5rem',
        justifyContent: 'center',

    },
    avatar: {
        justifyContent: "center"
    },
    userAccount: {
        margin: "20px",
    },
    userAccountTitle: {
        borderBottom: "1px solid",
    },
    userAccountTitleBtn: {
        padding: "20px",
    },
    userPasswordTitle: {
        borderBottom: "1px solid",
    },
    userPasswordBtn: {
        padding: "20px",
    },
    userAccountChangePassword: {
        margin: "20px",
    }
}));
function MyAccount() {
    const location = useLocation();
    const classes = useStyles();
    const [userInfo, setUserInfo] = useState({
        userId: "",
        userFirstName: "",
        userLastName: "",
        userEmail: "",
        userphone: "",
        userSex: "",
        userImage: ""
    });


    const [message, setMessage] = useState({
        open: false,
        color: 'green',
        messageInfo: '',
    });

    const navigate = useNavigate();
    const { open, color, messageInfo } = message;
    const [sexe, setSexe] = useState([]);
    const [isDisable, setIsDisable] = useState(true);
    const [isModifiedPassword, setIsModifiedPassword] = useState(false);
    const [form, setForm] = useState({
        newpassword: "",
        confirmpassword: ""
    });
    const [isEmptyNewPass, setIsEmptyNewPass] = useState(false);
    const [isEmptyNewPassMessage, setIsEmptyNewPassMessage] = useState("");
    const [isEmptyConfirmPass, setIsEmptyConfirmPass] = useState(false);
    const [isEmptyConfirmPassMessage, setIsEmptyConfirmPassMessage] = useState("");
    const [IsCompatibleNewAndConfirmPassword, setIsCompatibleNewAndConfirmPassword] = useState(true);
    const [IsCompatibleNewAndConfirmPasswordMessage, setIsCompatibleNewAndConfirmPasswordMessage] = useState("");
    const apiUrl = process.env.REACT_APP_API_URL;

    const [dir, setDir] = useState([]);
    const [service, setService] = useState([]);
    const [users, setUsers] = useState([]);

    const [idservice, setIdservice] = useState(0);
    const [iddir, setIddir] = React.useState(0);
    const [iduser, setIdUser] = React.useState(0);
    const [idDelegate, setIdDelegate] = React.useState(0);
    const [showAddDelegateForm, setShowAddDelegateForm] = React.useState(true);
    const [showDelegateDetail, setShowDelegateDetail] = React.useState(false);
    const [expanded, setExpanded] = React.useState('panel1');
    const [loading, setLoading] = React.useState(false);

    const [idDelegateDetail, setidDelegateDetail] = React.useState([]);
    
    const [confirmationDialog, setConfirmationDialog] = React.useState({
        openDialog: false,
        messageDialog: 'Voulez vous annuler la délégation?',
    });
    const { openDialog, messageDialog } = confirmationDialog;
    
    const handleChangePanel = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        UserService.user_info().then((result) => {
            if (result) {
                setUserInfo({
                    userId: result.data.userId,
                    userFirstName: result.data.first_name,
                    userLastName: result.data.last_name,
                    userEmail: result.data.email,
                    userphone: result.data.telephone,
                    userSex: result.data.sex_id,
                    userImage: result.data.name_image
                });
                //   setRows(result.data);
            }
        });

        UserService.getSex().then((sex) => {
            if (sex) {
                setSexe(
                    sex.data.map((s) => {
                        return { sex_id: s.sex_id, name: s.name };
                    })
                );
            }
        });
        DirectionService.getDirection().then((res) => {
            if (res && res.status) {
                setDir(res.data);
            }
        });
        
        UserService.getUserDelegateAuthorityByUserId().then((results) => {
            console.log("atooo eee");
            if (results && results.status) {
                setIdDelegate(results.data);
                setidDelegateDetail(results.data);
                setIddir(results.data.user_delegate_direction);
                console.log(results.data);
                UserService.getAllUserByDirection(results.data.user_delegate_direction).then((results) => {
                    if (results && results.status) {
                        setUsers(results.data);
                    }
                })
                setIdUser({
                    "name": results.data.user_delegate_name,
                    "user_id": results.data.user_delegation_authority_userDelegateId,
                    "email": results.data.user_delegate_email
                });
                setShowAddDelegateForm(false);
            }else{
                console.log("tsisyyy", iduser);
                setIdDelegate(0);
                setIdUser({
                    "name": "",
                    "user_id": 0,
                    "email": ""
                });
            }
        })
    }, [location]);

    useEffect(()=>{
        if(idDelegate !== 0){
            setShowDelegateDetail(true);
            setShowAddDelegateForm(false);
        }else{
            setShowDelegateDetail(false);
            setShowAddDelegateForm(true);
        }
    },[idDelegate]);

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }

    function handleClickEditInfo() {
        setIsDisable(!isDisable);
    }

    function handleClickBtnSave() {

    }

    function handleClickEditPassword() {
        setIsModifiedPassword(!isModifiedPassword);
    }

    function handleClickBtnSavePassword() {
        if (!form.newpassword) {
            setIsEmptyNewPass(true);
            setIsEmptyNewPassMessage("Ce champ est obligatoire");
        } else if (!form.confirmpassword) {
            setIsEmptyConfirmPass(true);
            setIsEmptyConfirmPassMessage("Ce champ est obligatoire");
        } else if (form.newpassword !== form.confirmpassword) {
            setIsCompatibleNewAndConfirmPassword(false);
            setIsCompatibleNewAndConfirmPasswordMessage("Les mots de passe ne correspondent pas");
        } else {
            UserService.my_account_update_password(userInfo.userEmail, form.confirmpassword).then((result) => {
                if (result && result.status != 411) {
                    if (result.status) {
                        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
                        setIsModifiedPassword(false);

                        setTimeout(function () { navigate("/"); }, 7000);

                    } else {
                        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
                        setForm({ ...form, newpassword: "", confirmpassword: "" });
                    }
                } else {
                    setIsCompatibleNewAndConfirmPassword(false);
                    setIsCompatibleNewAndConfirmPasswordMessage(result.data.message);
                }
            });
        }
    }

    function handleFocusNewPassword() {
        setIsEmptyNewPass(false);
        setIsCompatibleNewAndConfirmPassword(true);
    }

    function handleFocusConfirmPassword() {
        setIsEmptyConfirmPass(false);
        setIsCompatibleNewAndConfirmPassword(true);
    }


    function handleChangeDirection(event) {
        const { name, value } = event.target;
        setIddir(value);
        setForm({ ...form, [name]: value });
        //     PlanningService.Direction(value).then((results) => {
        //         if (results && results.data) {
        //             setService(results.data);
        //         }
        //     })
        UserService.getAllUserByDirection(value).then((results) => {
            if (results && results.status) {
                setUsers(results.data);
            }
        })
    }
    function handleChangeService(ev) {
        const { name, value } = ev.target;
        setForm({ ...form, [name]: value });
        setIdservice(value);
        // UserService.getAllUserByService(false, value, iddir).then((results) => {
        //     if (results && results.status) {
        //         setUsers(results.data);
        //     }
        // })
    }
    function handleChangeUsers(ev, value) {
        // const { name, value } = ev.target;
        setIdUser(value);
    }

    function handleClickBtnSaveDelegateAuthority(){
        setLoading(true);
        UserService.saveUserDelegateAuthority(iduser, idDelegate.user_delegation_authority_id).then((results) => {
            if (results && results.status) {
                setUsers(results.data);
                navigate("/myAccount");
                setExpanded("panel3");
                setLoading(false);
            }else{
                setLoading(false);
            }
        })
    }
    
    function handleClickBtnModifDelegateAuthority(){
        setShowAddDelegateForm(true);
        setShowDelegateDetail(false);
    }

    function handleClickBtnCancelDelegateAuthority(){
        setConfirmationDialog({ ...confirmationDialog, openDialog: true, messageDialog: 'Confirmer-vous la délégation de pouvoir?' });
        
    }

    
    function closeDialog() {
        setConfirmationDialog({ ...confirmationDialog, openDialog: false, messageDialog: 'Confirmer-vous la délégation de pouvoir?' });
    }
    function confirmDialog() {
        setConfirmationDialog({ ...confirmationDialog, openDialog: false, messageDialog: 'Confirmer-vous la délégation de pouvoir?' });
        
        UserService.deleteUserDelegateAuthority(iduser, idDelegate.user_delegation_authority_id).then((results) => {
            if (results && results.status) {
                // setUsers(results.data);
                setIdUser({
                    "name": "",
                    "user_id": 0,
                    "email": ""
                });
                setLoading(false);
                setIdDelegate(0);
                setIddir(0);
                navigate("/myAccount");
                setExpanded("panel3");
                // setIdUser([]);
                
            }else{
                setLoading(false);
            }
        })
    }
    function cancelDialog() {
        setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    }
    
    return (
        <MainContainer {...headerData}>
            <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
            
            <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            <div className={classes.container}>
                <div className={classes.avatar} width="sm">
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >
                        <Avatar alt={userInfo.userFirstName} src={userInfo.userImage} sx={{ width: 100, height: 100 }} />
                    </StyledBadge>
                    {/*<div>
                        <Grid container spacing={2} xs={12} className={classes.userAccountTitle}>
                            <Grid xs={11}>
                                <h2>Information personnelle</h2>
                            </Grid> */}
                    {/* <Grid xs={1} className={classes.userAccountTitleBtn}>
                                <Button title="Modifier" onClick={handleClickEditInfo} style={{ background: isDisable ? "" : "#6ACFF6" }}>
                                    <CreateTwoTone style={{ color: "black" }} />
                                </Button>
                            </Grid> */}
                    {/* </Grid>
                        <Grid container spacing={2} xs={6} className={classes.userAccount}>

                            <Grid item xs={4}>
                                <label>Nom:</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label="Nom"
                                    variant="outlined"
                                    value={userInfo.userLastName.toUpperCase()}
                                    name="last_name"
                                    disabled={isDisable}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <label>Prénom:</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label="Prénom"
                                    variant="outlined"
                                    value={userInfo.userFirstName}
                                    name="first_name"
                                    disabled={isDisable}
                                />

                            </Grid>
                            <Grid item xs={4}>
                                <label>Téléphone:</label>
                            </Grid>
                            <Grid item xs={8}>
                                <Phone x1={3} x2={8} name="telephone" value={userInfo.userphone} disabled={isDisable} />
                            </Grid>

                            <Grid item xs={4}>
                                <label class="aligneleft">Sexe:</label>
                            </Grid>

                            <Grid item xs={8}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="sex"
                                        name="sex"
                                        row
                                        value={userInfo.userSex}
                                    >
                                        {sexe.map((row) => (
                                            <FormControlLabel

                                                disabled={isDisable}
                                                value={row.sex_id}
                                                control={<Radio />}
                                                label={row.name}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <label class="aligneleft">Email:</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label="email"
                                    variant="outlined"
                                    value={userInfo.userEmail}
                                    name="email"
                                    disabled={isDisable}
                                />
                            </Grid>
                            {!isDisable &&
                                <>
                                    <Grid item xs={9}></Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleClickBtnSave}
                                            style={{ fontWeight: 600 }}
                                        >
                                            ENREGISTRER

                                        </Button>
                                    </Grid>
                                </>
                            }
                        </Grid>

                    </div> */}
                    {/* <Grid container spacing={2} xs={12} className={classes.userPasswordTitle}>
                        <Grid xs={11}>
                            <h2>Mot de passe</h2>
                        </Grid>
                        <Grid xs={1} className={classes.userPasswordBtn}>
                            <Button title="Modifier" onClick={handleClickEditPassword} style={{ background: isModifiedPassword ? "#6ACFF6" : "" }}>
                                <CreateTwoTone style={{ color: "black" }} />
                            </Button>
                        </Grid>
                    </Grid>

                    {isModifiedPassword &&
                        <form className={classes.form}>
                            <Grid container spacing={2} xs={6} className={classes.userAccountChangePassword}>
                                <Grid item xs={4}>
                                    <label>Nouveau mot de passe*:</label>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        size="small"
                                        label="Nouveau mot de passe"
                                        variant="outlined"
                                        name="newpassword"
                                        type="password"
                                        onChange={handleChange}
                                        onFocus={handleFocusNewPassword}
                                    />
                                    {isEmptyNewPass && (
                                        <Alert severity="error">{isEmptyNewPassMessage}</Alert>
                                    )}
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={8}><p><h4>Niveau de sécurité du mot de passe :</h4>
Utilisez au moins 8 caractères contenant des lettres en capitales et en minuscules, des nombres et des caractères spéciaux.</p></Grid>
                                <Grid item xs={4}>
                                    <label>Confirmation mot de passe*:</label>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        size="small"
                                        label="Confirmation mot de passe"
                                        variant="outlined"
                                        type="password"
                                        name="confirmpassword"
                                        onChange={handleChange}
                                        onFocus={handleFocusConfirmPassword}
                                    />
                                    {isEmptyConfirmPass && (
                                        <Alert severity="error">{isEmptyConfirmPassMessage}</Alert>
                                    )}
                                    {!IsCompatibleNewAndConfirmPassword && (
                                        <Alert severity="error">{IsCompatibleNewAndConfirmPasswordMessage}</Alert>
                                    )}

                                </Grid>
                                <Grid item xs={9}></Grid>
                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleClickBtnSavePassword}
                                        style={{ fontWeight: 600 }}
                                    >
                                        ENREGISTRER

                                        </Button>
                                </Grid>
                            </Grid>
                        </form>
                    }*/}
                </div>
                <Spacer y={2} />
                <DetailAccordions message={message} userInfo={userInfo} sexe={sexe}
                    isDisable={isDisable} form={form} isEmptyNewPass={isEmptyNewPass} loading={loading}
                    isEmptyNewPassMessage={isEmptyNewPassMessage} isEmptyConfirmPass={isEmptyConfirmPass}
                    isEmptyConfirmPassMessage={isEmptyConfirmPassMessage} IsCompatibleNewAndConfirmPassword={IsCompatibleNewAndConfirmPassword}
                    IsCompatibleNewAndConfirmPasswordMessage={IsCompatibleNewAndConfirmPasswordMessage} handleChangePassword={handleChange}
                    handleClickBtnSave={handleClickBtnSave} handleClickBtnSavePassword={handleClickBtnSavePassword}
                    handleFocusConfirmPassword={handleFocusConfirmPassword} handleFocusNewPassword={handleFocusNewPassword}
                    dir={dir} service={service} users={users} handleChangeDirection={handleChangeDirection}
                    handleChangeService={handleChangeService} handleChangeUsers={handleChangeUsers}
                    idDir={iddir} idservice={idservice} iduser={iduser} showAddDelegateForm={showAddDelegateForm}
                    handleClickBtnSaveDelegateAuthority={handleClickBtnSaveDelegateAuthority} 
                    idDelegate={idDelegate} handleClickBtnModifDelegateAuthority={handleClickBtnModifDelegateAuthority}
                    showDelegateDetail={showDelegateDetail} expanded={expanded} handleChangePanel={handleChangePanel}
                    handleClickBtnCancelDelegateAuthority={handleClickBtnCancelDelegateAuthority}/>
            </div>
        </MainContainer >
    )
}

export default MyAccount;
