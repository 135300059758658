import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const CandidatureService = {
    async list() {
        try {
          const result = await axios.get(`${apiUrl}/Candidature/index`);
          if (result) {
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      },
      async getlistidentity(id) {
        try {
          const result = await axios.get(`${apiUrl}/Candidature/getlist/`+id);
          if (result) {
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      },
      // async getlistidentity(id) {
      //   try {
      //     const result = await axios.get(`${apiUrl}/Candidature/generateExcel/`+id);
      //     if (result) {
      //       return result.data;
      //     }
      //   } catch (error) {
      //     if (error.response) {
      //       return error.response.data;
      //     }
      //   }
      // },
      async getCV(id) {
        try {
          const result = await axios.get(`${apiUrl}Candidature/downloadFiles/`+id);
          if (result) {
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      },
      async insert(name,date_start,date_end,desc) {
        try {
          const result = await axios.post(`${apiUrl}/Candidature/insertposte`,
            {
              name:name,
              date_start:date_start,
              date_end:date_end,
              desc:desc
            }
          );
          if (result) {
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      },

      async generate() {
        try {
          const result = await axios.get(`${apiUrl}/Candidature/generates`);
          if (result) {
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      },

      async cloture(id) {
        try {
          const result = await axios.get(`${apiUrl}/Candidature/getcloture/`+id);
          if (result) {
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      },
      
}
export default CandidatureService;