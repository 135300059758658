import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const RefusedRequestService = {
    async getFormular(module_type_id, type_observation){
        try{
            const result = await axios.get(`${apiUrl}/refusedRequest/${module_type_id}/${type_observation}`);
            if (result) {
              return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async addObservation(module_type_id, validation_id, observationData, type_observation, file_validation_request_id){
        try{
            const result = await axios.post(`${apiUrl}/refusedRequest/observation/${type_observation}`, {
                validation_id : validation_id,
                observation : observationData.observation,
                observation_detailed : observationData.observation_detailed,
                module_type_id : module_type_id,
                file_validation_request_id : file_validation_request_id
            });
           if (result) {
              return result.data;
           }
        }catch(error){
            if(error.response){
              return error.response.data;
            }
        }
    }
}

export default RefusedRequestService;
