import React from 'react';
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Message from "../tools/Message";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import ImmobilisationService from "../../services/ImmobilisationService";
import InventoryService from "../../services/InventoryService";
import { useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Spacer from '../../components/Spacer';

import {
    FormControl,
    Grid,
    TextField,
    Select,
    InputLabel,
    MenuItem
} from "@material-ui/core";
import AutocompleteCombobox from '../../components/AutocompleteCombobox';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
      elevation: 0,
    },
    tab1: {
      width: "50%",
    },
  }));
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function InventoryTransfert(props) {
    const navigate = useNavigate();    
    const openDialog = props.open;
    const closeDialog = props.closeDialog;
    const dataImmo = props.dataImmo;
    const rows = props.rows;
    const [directions, setDirection] = useState([]);
    const [message, setMessage] = useState({
        open: false,
        color: 'green',
        messageInfo: '',
    });
    const classes = useStyles();
    const { open, color, messageInfo } = message;

    const [form, setForm] = useState({
        dir: "",
        user_id: "",
    });
    const handleChanges = (e) => {

        const { name, value } = e.target;
        if (localStorage.getItem(name)) {
          
        }
        setForm({ ...form, [name]: value });
        };
    function handleChangeUser(personId) {
            setForm({ ...form, user_id: personId });
    }
    function handleChangeDir(directionId) {
        setForm({ ...form, dir: directionId });
}
    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }

    function handleClickReinit() {
        window.location.reload();
    }

    useEffect(() => {
        ImmobilisationService.direction().then((result) => {
          if (result && result.data) {
            setDirection(result.data);
          }
        });
        }, []);
    function handleValidateImmoNewAffectation() {
        var allImmoSelected = props.dataImmo;
        console.log("daataaaaa : " ,form);
        InventoryService.validateImmoNewTransfert(allImmoSelected,form.dir,form.user_id).then((result) => {
            if (result.status == true) {
                setMessage({
                    ...message,
                    open: true,
                    color: "green",
                    messageInfo: result.message,
                });
                
                const intervalId = setInterval(() => {
                    window.location.reload();
                }, 2500);                
            }
            else{
                setMessage({
                    ...message,
                    open: true,
                    color: "red",
                    messageInfo: result.message,
                });
            }
        });
    }
    
    return (
        <div>
            <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="lg">
                <DialogTitle id="form-dialog-title" color="primary">{"Transfère vers une autre direction "}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{fontWeight:"bold",color:'black' }} >
                        {" Choisissez le nouveau détenteur: "}<br />
                
                    </DialogContentText>
                    
                    <AutocompleteCombobox onPersonIdChange={handleChangeUser} onDirectionIdChange={handleChangeDir} />
                <Spacer y={2} />
                <DialogContentText id="alert-dialog-description" style={{fontWeight:"bold",color:'black' }}>
                        {" Liste des immobilisations à valider: "}<br />
                    </DialogContentText>
                <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Code</TableCell>
                                    <TableCell align="left">Matériel</TableCell>
                                    <TableCell align="left">Date d'acquisition</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {props.dataImmo.map((row) => (
                                <TableRow>
                                    <TableCell align="left">{row.code_immo_code}</TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.date_acquis}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>

                <DialogActions>                    
                    <Button variant="contained" color="primary" onClick={handleValidateImmoNewAffectation}
                    style={{ backgroundColor:"green", color:"#FFFFFF" }}>Valider</Button>
                    <Button variant="contained" onClick={handleClickReinit} >Réinitialiser la liste</Button>
                    <Button variant="contained" color="secondary" onClick={closeDialog} >Annuler</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

