import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import { useState, useEffect } from "react";
import Copyright from "../../components/Copyright";
import useStyles from "./newpassword.styles";
import NewPassService from "../../services/NewPassService";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import id from "date-fns/esm/locale/id/index.js";
import PrivilegeService from '../../services/PrivilegeService';

function NewPassword(props) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState("");
  const [form, setForm] = useState({});
  const [alertSeverity, setAlertSeverity] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [failedNewPassword, setFailedNewPassword] = useState(false);
  const navigate = useNavigate();
  const [isPasswordSent, setPasswordSent] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [failedNewPasswordToken, setFailedPwdTofenExpired] = useState(false);
  const [isValidPassword, setFailedIsValidPassword] = useState(false);

  const params = useParams();
  const token = params.token;

  // useEffect(() => {
  //   // TODO: get token from url and check its validity to the backend
  //   PrivilegeService.getUserPrivilege().then((result) => {
  //     if (result && result.data) {

  //     }
  //   })
  //   //console.log("this is the string",props.match.params.email);
  // }, [])

  function handleSubmit(event) {
    event.preventDefault();
    setFailedNewPassword(false);


     if (!form.newpassword) {
     setIsValid(false);
      setErrorMessage("Nouveau mot de passe ");
      return;
    }
   if (!form.confirmpassword) {
      setIsValid(false);
     setErrorMessage("Confirmation mot de passe");
      return;
     }

    NewPassService.newpassword(token, form.newpassword, form.confirmpassword)
      .then((res) => {
        var responseErrorMessage = res.message;
        console.log(res);
        console.log(res.message);
        if (res.status && res.status !== 404) {
          NewPassService.deleteToken(token).then((res)=>{
            setSuccessMessage('Votre mot de passe a été réinitialisé,vous allez être redirigé vers la page de connection ');
            setPasswordSent(true);
            setTimeout(function () { navigate("/"); }, 7000);
          });
        } else {
          // Samuela: test retour pour token expiré ou déjà utilisé
          if (res.status === 404) {
            setFailedPwdTofenExpired(true);
            setAlertSeverity("error");
          }else if (res.status === 400) {
            setIsValid(true);
            setAlertSeverity("error");
          }else {
             setIsValid(false);
             setErrorMessage(responseErrorMessage);
            setAlertSeverity("error");
          }
        }
      })
      .catch((err) => {
        setFailedNewPassword(true);
        setErrorMessage("Une erreur s'est produite. Veuillez réessayer");
      });
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  function handleFocusNewPassword() {
    setIsValid(true);
  }
  function handleFocusConfirmPassword() {
    setIsValid(true);
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.logo}>
        </div>
        <div className={classes.divCenter}>
          <div className={classes.alert}> Entrer votre mot de passe </div>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              error={!isValid}
              variant="outlined"
              margin="normal"
              required={true}
              fullWidth={true}
              name="newpassword"
              label="Nouveau mot de passe"
              type="password"
              id="newpassword"
              onChange={handleChange}
              autoComplete="current-newpassword"
              value={form.newpassword || ""}
              autoFocus
              onFocus={handleFocusNewPassword}
            />
            <TextField
              error={!isValid}
              variant="outlined"
              margin="normal"
              required={true}
              fullWidth={true}
              name="confirmpassword"
              label="Confirmation mot de passe"
              type="password"
              id="confirmpassword"
              onChange={handleChange}
              autoComplete="current-confirmpassword"
              value={form.confirmpassword || ""}
              onFocus={handleFocusConfirmPassword}
            />
            {isPasswordSent && (
              <Alert severity="success">{successMessage}</Alert>
            )}

            {!isValid&& (
              <Alert severity="error">{errorMessage}</Alert>
            )}

            {failedNewPasswordToken && (
              <div align="center" style={{color:'red'}}>
                <p>Votre lien de récupération est expiré ou déjà utilisé! Veuillez cliquer <Link to="/reset-password">ici</Link> pour demander un nouveau</p>
              </div>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={failedNewPasswordToken}
            >
              Reinitialiser
            </Button>
            <Grid container>
              <Grid item xs>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      <Box mt={6}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default NewPassword;
