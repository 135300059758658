import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const LeaveTypeService = {
    async add(name, desc,leave_count_factor, leave_type_date) {
        try {
          const result = await axios.post(`${apiUrl}/LeaveType/`, {
            name,
            desc,
            leave_count_factor,
            leave_type_date
          });
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
    }
    ,
    async saveLeaveType(name, desc,leave_count_factor, leave_type_date,leave_type_id) {
        try {
          const result = leave_type_id ?
            await axios.put(`${apiUrl}/LeaveType/id/${leave_type_id}`, {
                name,
                desc,
                leave_count_factor,
                leave_type_date
            })
            :
            await axios.post(`${apiUrl}/LeaveType/add/`, {
                name,
                desc,
                leave_count_factor,
                leave_type_date
            });
          if (result) {
            // TODO: replace result.data.message by the token
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      }
      ,
      async deleteLeaveType(id) {
        try {
          const result = await axios.delete(`${apiUrl}/LeaveType/id/${id}`);
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        }
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      }
      ,
      async getLeaveType(options) {
        try {
          const result = await axios.get(`${apiUrl}/LeaveType/`, {
            params: options,
            // paramsSerializer: params => {
            //   return qs.stringify(params)
            // }
          });
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        }
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
    
      }
      ,
      async LeaveTypeById(id) {
        try {
          const result = await axios.get(`${apiUrl}/LeaveType/id/${id}`);
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        }
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
    }
    ,
    
    async checkCountDate(date) {
      try {
        const result = await axios.get(`${apiUrl}/LeaveType/checkCountDate/${date}`);
        if (result) {
          return result.data;
        }
      }
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
  }
  ,
}
export default LeaveTypeService;
