import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import GroupService from "../../services/GroupService";
import { DataGrid } from '@material-ui/data-grid';
import Message from "../tools/Message";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';
import Title from "../../components/Title";
import Spacer from "../../components/Spacer";
const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function GroupTable(props) {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const [isid, setIsid] = useState(0);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'voulez vous supprimer?',
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    openMessage: false,
    color: 'green',
    messageInfo: '',
  });
  const { openMessage, color, messageInfo } = message;

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    GroupService.getGroup().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.group_id })));
        setOpenLoadingSimpleBackdrop(false);
      }
      else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);


  const handleDelete = (id) => (ev) => {
    setIsid(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true, messageDialog: 'voulez vous supprimer?' });
  };
  
  function loadTable() {
    GroupService.getGroup().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.group_id })));
      }
    });
  }
  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false, messageDialog: 'voulez vous supprimer?' });
  }
  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false, messageDialog: 'voulez vous supprimer?' });
    GroupService.deleteGroup(isid).then((result) => {
      if (result.status==true) {
      
        setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.message });
        loadTable();
      } else {
        setMessage({ ...message, openMessage: true, color: 'red', messageInfo: result.message });
      }
    // })
    }).catch((err) => {
      setMessage({ ...message, openMessage: true, color: 'red', messageInfo: 'Erreur'.isid });
    });
  }
  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function handleCloseMessage() {
    setMessage({ ...message, openMessage: false });
  }

  const handleEdit = (id) => (ev) => {
    navigate("/groups/add/" + id);
  };
  const columns = [
    { 
      field: 'name',
      headerName: 'Nom',
      width: 300,
    },
    {
      field: 'desc',
      headerName: 'Description',
      width: 400,
    },
    {
      field: 'action',
      headerName: 'Action',
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Button onClick={handleEdit(params.id)} title="Modifier un group"><CreateTwoTone style={{ color: "black" }} />
            </Button>
            <Button onClick={handleDelete(params.id)} title="Supprimer un group">
              <DeleteOutlineRounded style={{ color: "red" }} />
            </Button>
          </div>
        );
      },
      width: 275,
    },
  ];
  return (
    <React.Fragment>
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <Message open={openMessage} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      
      <Title>Listes des Groupes</Title>
      <Spacer y={2} />
      <div style={{ height: "70vh", width: '100%' }} className={classes.root} >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
        />
      </div>
    </React.Fragment>
  );
}
