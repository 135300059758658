import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const PlanningService = {

async Listeuser(){
  try {
      const result = await axios.get(`${apiUrl}/Planning/liste`);
      if (result) {

        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  
}
 ,
 async All(){
  try {
      const result = await axios.get(`${apiUrl}/Planning/all`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async Direction(id){
  try {
      const result = await axios.get(`${apiUrl}/Planning/Direction/${id}`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async Service(){
  try {
      const result = await axios.get(`${apiUrl}/Planning/Service`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async Month(id){
  try {
      const result = await axios.get(`${apiUrl}/Planning/month/${id}`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async Days(id){
  try {
      const result = await axios.get(`${apiUrl}/Planning/days/${id}`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async Final(id,id1,id3,id4){
  try {
      const result = await axios.get(`${apiUrl}/Planning/leavemonth/${id}/${id1}/${id3}/${id4}`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async getById(id){
  try {
      const result = await axios.get(`${apiUrl}/Planning/id/${id}`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
 async getUser(id){
  try {
      const result = await axios.get(`${apiUrl}/Planning/usersuperior`);
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
 ,
}

export default PlanningService;