import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import ValidationSettingTable from "./validationSettingTable";
import { Button } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataSettings } from "../../data/modules/settings-header";
import { useNavigate } from 'react-router-dom';

export default function ValidationSettingList() {

  const navigate = useNavigate();
  function handleClick() {
    navigate("/validation_planning/add");
  }
  return (
    <MainContainer {...headerDataSettings}>
      <Grid container item xs={12}>
        <Spacer x={2} />

        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          CREER
          </Button>

        <Spacer x={2} />
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <ValidationSettingTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
