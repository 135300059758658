import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataAdmin } from "../../data/modules/admin-header";
import AnnualCalendarService from "../../services/AnnualCalendarService";
import AnnualCalendarMonthService from "../../services/AnnualCalendarMonthService";
import { hasPrivilege } from "../../utils/privilege";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";
import Message from "../tools/Message";
import Box from '@mui/material/Box';
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

function AnnualCalendarAdd(props) {
  const [failedLogin, setFailedLogin] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const [annees, setAnnees] = useState(['2021', '2022', '2023', '2024', '2025', '2026', '2027']);

  const [modules, setModules] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const params = useParams();
  const annual_calendar_id = params.id ? params.id : "";
  const [form, setForm] = useState({
    day_label: "",
    years: "",
    desc: "",
  });
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  function handleClick() {
    AnnualCalendarService.saveAnnualCalendar(
      form.day_label,
      form.years,
      form.desc,
      annual_calendar_id
    )
      .then((res) => {
        var responseErrorMessage = res.message;
        if (res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
          navigate("/annual_calendar");
        } else {
          setFailedLogin(true);
          setErrorMessage(responseErrorMessage);
          setMessage({ ...message, open: true, color: 'red', messageInfo: responseErrorMessage });
          setAlertSeverity("error");
        }
      })
      .catch((err) => {
        setFailedLogin(true);
        setErrorMessage("Une erreur s'est produite. Veuillez réessayer");
      });

  }
  function handleChange(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
  }
  useEffect(() => {
    if (annual_calendar_id) {
      AnnualCalendarService.AnnualCalendarById(annual_calendar_id).then(({ data: info }) => {
        setForm({
          day_label: info.day_label,
          years: info.years,
          desc: info.desc,
          annual_calendar_id: annual_calendar_id,
        });
      });
    }
  }, []);
  const contextData = useContext(userDetailContext);
  function handleCancel() {
    navigate("/annual_calendar");
  }
  return (
    <MainContainer {...headerDataAdmin}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
        {failedLogin && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>

          <Grid item xs={4}>
            <label class="aligneleft">Libellé jours{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Libelle jours"
              variant="outlined"
              value={form.day_label}
              name="day_label"
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={4}>
            <label class="aligneleft">Année{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-label">Année</InputLabel>
              <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Année" value={form.years} name="years" onChange={handleChange} >
                {annees.map((row) => (
                  <MenuItem value={row}>{row}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <label class="aligneleft">Description</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Description"
              variant="outlined"
              value={form.desc}
              name="desc"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <label className={classes.text}>({bullRed}):Champs obligatoires</label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}
export default AnnualCalendarAdd;
