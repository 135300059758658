import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const MailListPersonService = {
    async getListPerson(id){
        try {
            const result = await axios.get(`${apiUrl}/userInfo/getByIdFunction/${id}`);
            if (result) {
                // console.log('RESULT : ', result.data);
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }  
    },

    async getUserById(id){
      try {
          const result = await axios.get(`${apiUrl}/userInfo/getById/${id}`);
          if (result) {
              // console.log('RESULT : ', result.data);
            return result.data;
          }
        } 
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }  
  },

  /////
  
  async getUserByMultipleDirection(selectedValueDirectionIdList){
    try {
      const queryString = selectedValueDirectionIdList.join(',');
      const urlWithParams = `${apiUrl}/userInfo/getUserByIdMultipleDirection/?selectedValueDirectionIdList=${queryString}`;
      const result = await axios.get(urlWithParams);
      console.log('RESULT : ', result);
      if (result) {

        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  } ,

  async getUserByMultipleService(selectedValueServiceIdList){
    try {
      const queryString = selectedValueServiceIdList.join(',');
      const urlWithParams = `${apiUrl}/userInfo/getUserByIdMultipleService/?selectedValueServiceIdList=${queryString}`;
      const result = await axios.get(urlWithParams);
      console.log('RESULT : ', result);
      if (result) {

        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  } ,

  async getUserByMultipleFonction(selectedValueFonctionIdList){
    try {
      const queryString = selectedValueFonctionIdList.join(',');
      const urlWithParams = `${apiUrl}/userInfo/getUserByIdMultipleFonction/?selectedValueFonctionIdList=${queryString}`;
      const result = await axios.get(urlWithParams);
      console.log('RESULT : ', result);
      if (result) {

        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  } ,

}

export default MailListPersonService;