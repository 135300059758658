import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const RequestValidationService = {
  async saveRequestValidation(){
    try{
      const result = await axios.post(`${apiUrl}/RequestValidation/`);
      return result;
    }catch (error){
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async save(){
    try{
      const result = await axios.post(`${apiUrl}/RequestValidation/save`);
      return result;
    }catch (error){
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async verification(){
    try{
      const result = await axios.get(`${apiUrl}/RequestValidation/verify`);
      return result;
    }catch (error){
      if (error.response) {
        return error.response.data;
      }
    }
  },
};


export default RequestValidationService;
