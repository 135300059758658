import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const MettingService = {

  async getCountMettingRequest(){
    try {
        const result = await axios.get(`${apiUrl}/metting/countmeetingrequest/`  );
        if (result) {
          // TODO: replace result.data.message by the token
          
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }  
  },

  async getCountMettingApproved(){
    try {
        const result = await axios.get(`${apiUrl}/metting/countmeetingapproved/`  );
        if (result) {
          // TODO: replace result.data.message by the token
          
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }  
  },

  async getCountMettingRefused(){
    try {
        const result = await axios.get(`${apiUrl}/metting/countmeetingrefused/`  );
        if (result) {
          // TODO: replace result.data.message by the token
          
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }  
  },

  async getMettingRequest(){
    try {
        const result = await axios.get(`${apiUrl}/metting/meetingrequest`  );
        if (result) {
          // TODO: replace result.data.message by the token
          
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }  
  },

  async getMettingApproved(){
    try {
        const result = await axios.get(`${apiUrl}/metting/meetingapproved`  );
        if (result) {
          // TODO: replace result.data.message by the token
          
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }  
  },

  async getMettingRefused(){
    try {
        const result = await axios.get(`${apiUrl}/metting/meetingrefused`  );
        if (result) {
          // TODO: replace result.data.message by the token
          
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }  
  },


    async getMetting(){
        try {
            const result = await axios.get(`${apiUrl}/metting/`  );
            if (result) {
              // TODO: replace result.data.message by the token
              
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }  
    },

    async saveMetting(date_meeting, time_meeting_start, time_meeting_end, user_meeting, object_meeting, link, id_meeting_room, id) {
        try {
           const result = id ?
            await axios.put(`${apiUrl}/metting/id/${id}`, {
             id,
             date_meeting,
             time_meeting_start,
             time_meeting_end,
             user_meeting,
             object_meeting,
             link,
             id_meeting_room
          })
          :
            await axios.post(`${apiUrl}/metting/`, {
                date_meeting,
                time_meeting_start,
                time_meeting_end,
                user_meeting,
                object_meeting,
                link,
                id_meeting_room
          });
          if (result) {
            // TODO: replace result.data.message by the token
   
             return result.data;
          }
         } catch (error) {
          // console.log(error.response);
           if (error.response) {
            console.log(date_meeting,time_meeting_start,time_meeting_end,user_meeting,object_meeting);
            console.log(error.response.data.status);
            console.log(error.response.data.message);
             return error.response.data;
            }
          }
    } ,

    async ApprovedMettingByIdMetting(comment,id){
      try {
              const result = id ?
              await axios.put(`${apiUrl}/metting/idapproved/${id}`, {
              id,
              comment
              })
              :
              null;
              if (result) {
                  // TODO: replace result.data.message by the token
      
                  return result.data;
              }
          } catch (error) {
              if (error.response) {
                  return error.response.data;
              }
          }
  },

  async RefusedMettingByIdMetting(comment,id){
    try {
            const result = id ?
            await axios.put(`${apiUrl}/metting/idrefused/${id}`, {
            id,
            comment
            })
            :
            null;
            if (result) {
                // TODO: replace result.data.message by the token
    
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
  },

  async deleteMettingById(id){
    try {
        const result = await axios.delete(`${apiUrl}/metting/id/${id}`  );
        if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
        }
    } 
    catch (error) {
        if (error.response) {
        return error.response.data;
        }
    }
  },


   //modification de donnée
   async MettingById(id){
    try {
        const result = await axios.get(`${apiUrl}/metting/id/${id}`  );
        if (result) {
          // TODO: replace result.data.message by the token

          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    } ,

}

export default MettingService;