import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from 'react';
import ValidationPlanningService from "../../services/ValidationPlanningService";
import Grid from "@material-ui/core/Grid";
import { Alert, AlertTitle } from '@mui/material';
import Spacer from '../../components/Spacer';

export default function ValidatePlanningDialog(props) {
  const openDialog = props.openDialog;
  const messageDialog = props.messageDialog;
  const closeDialog = props.closeDialog;
  const confirmDialog = props.confirmDialog;
  const handleRefused = props.handleRefused;
  const handleValidate = props.handleValidate;
  const idPlanning = props.idPlanning;

  const [detail, setDetail] = useState([]);
  const [isValidatePerUserConnected, setValidatePerUserConnected] = useState(false);
  // const envoyerDialog = props.envoyerDialog;
  useEffect(() => {
    if (idPlanning !== 0) {
      ValidationPlanningService.getPlanningDetail(idPlanning).then((res) => {
        if (res && res.status) {
          setDetail(res.data);
        }
      });

      ValidationPlanningService.getUserValidatorOfPlanningId(idPlanning).then((res) => {
        if (res.status) {
          setValidatePerUserConnected(true);
        }else{
          setValidatePerUserConnected(false);
        }
      });
    }
  }, [openDialog]);
  return (
    <div>
      <Dialog open={openDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="form-dialog-title" color="primary" >{"Validation planning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>Info</AlertTitle>
                  Vous êtes sur le point de valider le planning de votre subordonné:
                </Alert>
              </Grid>
              <Grid container justifyContent="center" item xs={12} spacing={2}>
                <Grid item xs={4}>
                  Nom et prénom(s):
                  </Grid>
                <Grid item xs={8}>
                  {detail.last_name} {detail.first_name}
                </Grid>
                <Grid item xs={4}>
                  Date début:
                  </Grid>
                <Grid item xs={8}>
                  {detail.date_start}
                </Grid>
                <Grid item xs={4}>
                  Date retour:
                  </Grid>
                <Grid item xs={8}>
                  {detail.date_end}
                </Grid>
                
                <Grid item xs={4}>
                  Statut:
                  </Grid>
                <Grid item xs={8}>
                  {detail.StatusPlanning === "en attente"? "En attente de validation": detail.StatusPlanning === "DECLINED"? "Planning réfusé": 
                  (detail.StatusPlanning === "CONFIRMED" && isValidatePerUserConnected)?"Planning déjà validé":"En attente de validation"}
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions>

          <Grid container justifyContent="center">
            {(detail.StatusPlanning === "en attente" || (detail.StatusPlanning !== "en attente" && !isValidatePerUserConnected)) &&
            detail.StatusPlanning !== "DECLINED" && 
              <>
              <Button style={{ backgroundColor: "#f50057", color: "#FFFFFF" }} 
              color="secondary" variant="contained" onClick={closeDialog}>
                Annuler
              </Button>
              <Spacer x={1} />
              <Button style={{ backgroundColor: "#3f51b5", color: "#FFFFFF" }} 
              color="primary" variant="contained" onClick={handleRefused}>
                Refuser
              </Button>
              <Spacer x={1} />
              <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF", }} 
              color="primary" variant="contained" onClick={handleValidate}>
                Valider
              </Button>
              </>
            }
            {((detail.StatusPlanning !== "en attente" && isValidatePerUserConnected) || detail.StatusPlanning === "DECLINED") && 
              <>
              <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF" }} 
              color="secondary" variant="contained" onClick={closeDialog}>
                Ok
              </Button>
              </>
            }
              
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
