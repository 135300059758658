import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from 'react';
import LeaveService from '../../services/LeaveService';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle, Typography } from '@mui/material';
import Spacer from '../../components/Spacer';
import Loading from "../../components/loading";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    btnAnnulerDialog: {
        background: "red",
        color: "white",
    },
    btnValiderDialog: {
        background: "rgb(19, 62, 103)",
        color: "white",
    },
    label: {
        fontWeight: "bold",
    },
    id: {
        color: "#3f51b5",
        fontWeight: "bold",
    },
    actualBalance: {
        textAlign: "right",
        margin: "auto"
    },
    detail: {
        fontSize: "13px"
    },
    divDetail: {
        justifyContent: "center"
    }
}));
export default function MotifAnnulationLeave(props) {
    const openDialog = props.openDialog;
    const handleCloseCancel = props.handleCloseCancel;
    const handleValidCancel = props.handleValidCancel;
    const handleRaison = props.handleRaison;
    const isLoading = props.isLoading ? true : false;
    const emptyMotif = props.emptyMotif;
    const raison = props.raison;
    const isPlanning = props.isPlanning;

    useEffect(() => {
    }, props)
    // const envoyerDialog = props.envoyerDialog;
    return (

        <div>
            <Dialog open={openDialog} onClose={handleCloseCancel}
                aria-labelledby="form-dialog-title1"
                fullWidth
                maxWidth="sm">
                <DialogTitle id="form-dialog-title1">Motif d'annulation</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        size="small"
                        label="Raison"
                        variant="outlined"
                        value={raison}
                        name="raison"
                        onChange={handleRaison}
                    />
                    {emptyMotif &&
                        <Alert severity="error">Veuillez spécifier la raison de votre demande d'annulation</Alert>
                    }
                    <Spacer y={2}></Spacer>

                    {/* Samuela: set loading while fetching api data */}
                    {isLoading && <Loading />}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseCancel} color="secondary">Annuler</Button>
                    <Button variant="contained" onClick={handleValidCancel} color="primary">Valider</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
