import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function Message(props){

  const openDialog=props.isActive;
  const messageDialog=props.message;
  const handleClose=props.handleClose;
  const handleConfirm=props.handleConfirm;
  return (
    <div>
      <Dialog open={openDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="form-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {messageDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}  variant="contained" color="primary"> OK </Button>
          <Button onClick={handleClose}  variant="contained" color="secondary"> Annuler </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}