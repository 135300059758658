import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import { makeStyles } from "@material-ui/core/styles";
import ExportExcel from "./ExportExcel";
import { useState, useEffect, useContext } from "react";

import { Grid, Paper } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import StockTable from "./StockTable";
import StockInventoryService from "../../services/StockInventoryService";
import UserService from "../../services/UserService";
import ImmobilisationService from "../../services/ImmobilisationService";
import InventoryTransfert from "../inventory/InventoryTransfert";
import FilterValidation from "../../components/FilterValidation";
import InventoryAffectation from "../inventory/InventoryAffectation";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function StockList() {
  const classes = useStyles();
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getFullYear() +
    "/" +
    (myCurrentDate.getMonth() + 1) +
    "/" +
    myCurrentDate.getDate();
  const [dataToExport, setDataToExport] = useState([]);
  const [fileName, setFileName] = useState(
    "Inventaire Stock enregistré le " + date
  );
  const [isOpenAffectedTo, setIsOpenAffectedTo] = useState(false);
  const [isOpenTransferedTo, setIsOpenTranferedTo] = useState(false);
  const [rows, setRows] = useState([]);
  const [dataImmo, setDataImmo] = useState([]);

  useEffect(() => {
    StockInventoryService.exportExcel().then((result) => {
      if (result && result.data) {
        setDataToExport(result.data);
      }
    });
  }, []);
  const handleSubmit = (e) => {
    window.location.reload();

  };
  function handleCloseDialogAffectedTo() {
    setIsOpenAffectedTo(false);
  }
  const Refresh = (e) => {
    e.preventDefault();
      localStorage.removeItem('name_materiel');
      localStorage.removeItem('code_immo');
      localStorage.removeItem('state');
      localStorage.removeItem('logistics_manager');
      localStorage.removeItem('status1');
      localStorage.removeItem('status2');
      localStorage.setItem('name_materiel',"");
      localStorage.setItem('code_immo',"");
      localStorage.setItem('state',"");
      localStorage.setItem('logistics_manager',"");
      localStorage.setItem('status1',"");
      localStorage.setItem('status2',"");
      
      window.location.reload()
  };
  
  function handleClickAffectedTo() {
    var allImmoSelected = localStorage.getItem("immoArrayStorage");
    ImmobilisationService.getAllSelectedCodeImmo(allImmoSelected).then((result) => {
      if (result && result.data) {
        setDataImmo(result.data);
      }
    });
    UserService.getAllUserActive().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.user_id })));
      } 
    });
    setIsOpenAffectedTo(true);
  }



  function handleClickTransferedTo() {
    var allImmoSelected = localStorage.getItem("immoArrayStorage");
    ImmobilisationService.getAllSelectedCodeImmo(allImmoSelected).then((result) => {
      if (result && result.data) {
        setDataImmo(result.data);
      }
    });
    setIsOpenTranferedTo(true);
  }
  function handleCloseDialogTranferedTo() {
    setIsOpenTranferedTo(false);
  }

  return (
    <MainContainer {...headerDataImmo}>
      <Grid container item xs={12}>

        <Button
          variant="contained"
          color="primary"
          onClick={handleClickAffectedTo}
          style={{ fontWeight: 600 }}
        >
          AFFECTER
        </Button>
        {/*
        <Spacer x={2} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickTransferedTo}
          style={{ fontWeight: 600 , backgroundColor:"yellowgreen"}}
        >
          TRANSFERER
        </Button>
        */}
       
        <Spacer x={2} />

        <Spacer y={2} />
        
        <Spacer xs={2} />

        <ExportExcel dataToExport={dataToExport} fileName={fileName} />    
        <Spacer x={2} />    
        <Button
                  variant="contained"
                  onClick={Refresh}
                  style={{ fontWeight: 600, backgroundColor:'darkred', color:'white'}}
                >
                  réinitialiser le filtre
                </Button>
                <Spacer x={2} />
                <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ fontWeight: 600} }
              >
                Filtrer
              </Button>
        <Spacer x={2} />
        <FilterValidation type={1} />
        <InventoryAffectation
          dataImmo={dataImmo}
          rows={rows}
          open={isOpenAffectedTo}
          closeDialog={handleCloseDialogAffectedTo}
          fullWidth
          maxWidth="sm"
        />

        
        <InventoryTransfert
          dataImmo={dataImmo}
          rows={rows}
          open={isOpenTransferedTo}
          closeDialog={handleCloseDialogTranferedTo}
          fullWidth
          maxWidth="sm"
        />

      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <StockTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
