export const extraData = {
    formation: [
        { value: "Sciences et technologies", name: "Sciences et technologies" },
        { value: "Sciences sociales et humaines", name: "Sciences sociales et humaines" },
        { value: "Arts et lettres", name: "Arts et lettres" },
        { value: "Santé et sciences de la vie", name: "Santé et sciences de la vie" },
        { value: "Commerce et gestion", name: "Commerce et gestion" },
        { value: "Environnement et développement durable", name: "Environnement et développement durable" },
        { value: "Industrie pétrolière et gazière", name: "Industrie pétrolière et gazière" },
        { value: "Santé et sciences de la vie", name: "Santé et sciences de la vie" },
        { value: "Géologie et géosciences", name: "Géologie et géosciences" },
        { value: "Elevages et agriculture", name: "Elevages et agriculture" },


        //autre value
        { value: "Autres", name: "Autres" },
    ],
    diplome : [
        { value:"Tous", name : "Tous"},

        
        { value:"CEPE", name : "CEPE"},
        { value:"BEPC", name : "BEPC"},
        { value:"Bacc", name : "Bacc"},
        { value:"Bacc+2-DTS", name : "Bacc+2-DTS"},
        { value:"Bacc+3-Licence", name : "Bacc+3-Licence"},
        { value:"Bacc+4-Master I", name : "Bacc+4-Maitrise"},
        { value:"Bacc+5-Master II", name : "Bacc+5-Master II/Ingénieur"},
        { value:"DEA", name : "DEA"},
        { value:"AEA", name : "AEA"},
        { value:"Doctorat", name : "Doctorat"},
    ]
}