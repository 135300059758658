import { Button, Grid, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { set } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../components/MainContainer";
import Spacer from "../../components/Spacer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import EquipmentCategoryService from "../../services/EquipmentCategoryService";
import Message from "../tools/Message";
import Alert from "@material-ui/lab/Alert";
import Box from "@mui/material/Box";
const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
    }}
  >
    *
  </Box>
);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 1000,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: "left",
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));
function EquipmentCategoryAdd(props) {
  const classes = useStyles();
  const [form, setForm] = useState({
    account_code: "",
    name_category: "",
    desc_category: "",
  });
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [message, setMessage] = useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const [acIsEmpty, setAcIsEmpty] = useState(false);
  const [nameIsEmpty, setNameIsEmpty] = useState(false);

  const { open, color, messageInfo } = message;
  const navigate = useNavigate();
  function handleClick() {
    const idGroup = splitLocation[4];
    if (idGroup) {
      EquipmentCategoryService.update(
        form.account_code,
        form.name_category,
        form.desc_category,
        idGroup
      )
        .then((results) => {
          if (results.status) {
            setMessage({
              ...message,
              open: true,
              color: "green",
              messageInfo: "Succèes",
            });
            setTimeout(() => {
            navigate("/Logistic/category");
            }, 1000);
          } else {
            setMessage({
              ...message,
              open: true,
              color: "red",
              messageInfo: "Une erreur s'est produite. Veuillez réessayer",
            });
          }
        })
        .catch((err) => {
          alert("error");
        });
    } else {
      setMessage({
        ...message,
        open: true,
        color: "green",
        messageInfo: "succès",
      });
      EquipmentCategoryService.save(
        form.account_code,
        form.name_category,
        form.desc_category
      )
        .then((results) => {
          if (results.data.status) {
          setMessage({
            ...message,
            open: true,
            color: "green",
            messageInfo: "succès",
          });
            setTimeout(() => {
              navigate("/Logistic/category");
              }, 1000);
          } else {
            setMessage({
              ...message,
              open: true,
              color: "red",
              messageInfo: "Une erreur s'est produite. Veuillez réessayer",
            });
          }
        })
        .catch((err) => {
          alert("error");
        });
    }
    if (
      form.account_code == null ||
      form.account_code == undefined ||
      form.account_code == ""
    ) {
      setAcIsEmpty(true);
    } else if (
      form.name_category == null ||
      form.name_category == undefined ||
      form.name_category == ""
    ) {
      setNameIsEmpty(true);
    }
  }
  function handleChange(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
  }
  useEffect(() => {
    const idGroup = splitLocation[4];
    console.log(idGroup);
    if (idGroup) {
      EquipmentCategoryService.getEquipmentCategoryById(idGroup).then(
        (info) => {
          setForm({
            account_code: info.data.account_code,
            name_category: info.data.name_category,
            desc_category: info.data.desc_category,
          });
        }
      );
    }
  }, []);
  function handleCancel() {
    navigate("/Logistic/category");
  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  return (
    <MainContainer {...headerDataImmo}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Code compta {bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Code compta"
              variant="outlined"
              onChange={handleChange}
              value={form.account_code}
              name="account_code"
            />
            {acIsEmpty && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Ce champ est obligatoire!</Alert>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Catégories {bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Catégories"
              variant="outlined"
              onChange={handleChange}
              value={form.name_category}
              name="name_category"
            />
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Description {bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Description"
              variant="outlined"
              onChange={handleChange}
              value={form.desc_category}
              name="desc_category"
            />
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}
export default EquipmentCategoryAdd;
