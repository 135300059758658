import React from "react";
import ImmobilisationTable from "./ImmobilisationTable";
import Paper from '@material-ui/core/Paper';
import { Grid } from "@material-ui/core";
function ImmoList(props) {
    const isGenerateQrCode = props.isGenerateQrCode;
    return (
        <ImmobilisationTable isExtra={0} isGenerateQrCode={isGenerateQrCode}/>
    );
}
export default ImmoList