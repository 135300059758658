import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { headerDataState } from '../../data/modules/custom-states-header';
import MainContainer from '../../components/MainContainer';
import ActualBalanceTable from "./ActualBalanceTable";
export default function ActualBalance(){
    return(
        <MainContainer {...headerDataState}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper variant="outlined">
                    <ActualBalanceTable/>
                </Paper>
              </Grid>
            </Grid>
        </MainContainer>
    )
}