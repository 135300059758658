import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../components/Title";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import { TableContainer, TablePagination } from "@material-ui/core";
import AntenneService from "../../services/AntenneService";
import { DataGrid } from '@material-ui/data-grid';
import Message from "../personnel/Message";
import Result from "../personnel/Result";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function AntenneTable(props) {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const [etat, setEtat] = useState({ open: false, message: "message", accept: false });
  const [etats, setEtats] = useState({ open: false, message: "message", color: "" });
  const [isid, setIsid] = useState(0);

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    AntenneService.addAntenne().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.id })));
        setOpenLoadingSimpleBackdrop(false);
      }else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, [setRows]);


  const handleDelete = (id) => (ev) => {
    setEtat({
      open: true,
      message: "Voulez vous vraiment supprimer  ?"
    });
    setIsid(id);
  };
  function handleMessage() {
    setEtat({
      open: false,
      message: ""
    });
  }

  function handleAccept() {
    setEtat({
      open: false,
      message: "",
      accept: true
    });
    AntenneService.deleteAntenne(isid).then((results) => {
      AntenneService.getAntenne().then((result) => {
        if (result && result.data) {
          setRows(result.data.map((row) => ({ ...row, id: row.direction_id })));
        }
      });


      if (results.status) {
        setEtats({ ...etats, open: true, color: 'green', message: results.message });
        navigate("/antenne");
      }
      else {
        setEtats({ ...etats, open: true, color: 'red', message: results.message });
      }
    });
  }
  const handleCloseMessage = () => {
    setEtats({ ...etats, open: false });
  };
  const handleEdit = (id) => (ev) => {
    navigate("/antenne/add/" + id);
  };
  const columns = [
    {
      field: 'codeA',
      headerName: 'Code',
      width: 150,
    },
    {
      field: 'nameD',
      headerName: 'Direction',
      width: 150,
    },
    {
      field: 'nameA',
      headerName: 'Désignation',
      width: 250,
    },
    {
      field: 'descA',
      headerName: 'Description',
      width: 250,
    },
    {
      field: 'action',
      headerName: 'Action',
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Button onClick={handleEdit(params.id)} title="Modifier"><CreateTwoTone style={{ color: "black" }} />
            </Button>
            <Button onClick={handleDelete(params.id)} title="Supprimer">
              <DeleteOutlineRounded style={{ color: "red" }} />
            </Button>
          </div>
        );
      },
      width: 200,
    },
  ];

  return (
    <React.Fragment>
      <Result message={etats.message} color={etats.color} open={etats.open} handleClose={handleCloseMessage} />
      <Message isActive={etat.open} message={etat.message} accept={etat.accept} handleClose={handleMessage} handleConfirm={handleAccept} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Listes des Antennes</Title>
      <div style={{ height: "70vh", width: '100%' }} className={classes.root} >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
        />
      </div>
    </React.Fragment>
  );
}
