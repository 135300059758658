//import React from 'react';
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Title from "../../../components/Title";
import { escapeRegExp } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import BalanceConfigurationService from "../../../services/BalanceConfigurationService";
import LeaveService from "../../../services/LeaveService";
import Spacer from "../../../components/Spacer";
import LeaveBalanceSummaryTable from "./LeaveBalanceSummaryTable";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    btnAnnulerDialog: {
        background: "red",
        color: "white",
    },
    btnValiderDialog: {
        background: "rgb(19, 62, 103)",
        color: "white",
    },
    label: {
        fontWeight: "bold",
    },
    id: {
        color: "#3f51b5",
        fontWeight: "bold",
    },
    actualBalance: {
        textAlign: "right",
        margin: "auto"
    },
}));
//io 

export default function LeaveBalanceSummaryOfSubordonnate(props) {
    const rows = props.rows;
    const initialBalancedate = props.initialBalancedate;
    const [initialBalance, setInitialBalance] = useState("");
    const [summaryBalanceRows, setSummaryBalanceRows] = useState("");
    const [actualBalance, setActualBalance] = useState("");
    const [userName, setUserName] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [showDetailId, setShowDetailID] = useState("");
    const [searchText, setSearchText] = useState("");

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = [...rows].filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
    };

    const columns = [

        {
            field: 'fullName',
            headerName: 'Nom et prénom(s)',
            width: 400,
            valueGetter: getFullName,
            sortComparator: (v1, v2, cellParams1, cellParams2) =>
                getFullName(cellParams1).localeCompare(getFullName(cellParams2)),
        },
        {
            field: '-',
            headerName: `Solde congé initial`,
            width: 350,
            valueGetter: getInitialBalance,
        },
        {
            field: "actualBalance",
            headerName: "Solde congé actuel",
            width: 250,
        },
    ];

    function getFullName(params) {
        return `${params.getValue(params.id, "last_name") || ""
            } ${params.getValue(params.id, "first_name") || ""}`;
    }

    function getInitialBalance(params) {
        return `${params.getValue(params.id, "initialBalanceDate") || ""
            } : ${params.getValue(params.id, "initialBalance") || ""}`;
    }

    function handleRowClick(){
        setOpenDialog(true);
        // console.log("showDetailId***",showDetailId);
    }

    function handleClose(){
        setOpenDialog(false);
    }

    useEffect(()=>{
        if(openDialog){
            setUserName(`${rows[showDetailId]['last_name']} ${rows[showDetailId]['first_name']}`)
            setInitialBalance(rows[showDetailId]['initialBalance']);
            setActualBalance(rows[showDetailId]['actualBalance']);
            setSummaryBalanceRows(rows[showDetailId]['summaryBalance'].map((row, index) => ({ ...row, id: index })))
        }
    }, [openDialog])
    return (
        <>
            <Title>Listes de tous vos subordonnés</Title>
            <Spacer y={2} />

            <div style={{ height: "70vh", width: "100%" }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    pageSize={7}
                    rowsPerPageOptions={[7]}
                    // disableSelectionOnClick
                    onRowClick={handleRowClick}
                    onSelectionModelChange={itm => setShowDetailID(itm)}
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(""),
                        },
                    }}
                />
            </div>

            
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >

          <DialogTitle id="form-dialog-title1">Détail solde congé de {userName}</DialogTitle> <br />
          <Spacer y={3} />
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <LeaveBalanceSummaryTable initialBalancedate={initialBalancedate} initialBalance={initialBalance} rows={summaryBalanceRows} actualBalance={actualBalance}/>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        </>
    );
}
