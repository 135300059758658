import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Spacer from "../../components/Spacer";
import Grid from "@material-ui/core/Grid";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import "react-phone-input-2/lib/style.css";
import PersonnelService from "../../services/PersonnelService";
import SeatService from "../../services/SeatService";
import BuildingService from "../../services/BuildingService";
import Message from "./Message";
import Phone from "../tools/Phone";
import Box from '@mui/material/Box';
import CardPortal from "../portal/CardPortal";
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  labelPosition: {
    float: 'left',
  },
  phoneInput: {
    width: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      float: 'right',
    },
  },
}));

export default function UtilInformation() {

  const navigate = useNavigate();
  const classes = useStyles();
  const [nomChoix, setNomChoix] = useState("Choix");
  const [rows, setRows] = useState([]);
  const [antennes, setAntennes] = useState([]);
  const [options, setOptions] = useState([]);
  const [services, setServices] = useState([]);
  const [seat, setSeat] = useState([]);
  const [building, setBuilding] = useState([]);
  const [superieur, setSuperieur] = useState([]);
  const [dayofType, setDayofType] = useState("");
  const [user, setUser] = useState();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const idGroup = splitLocation[3];
  const [etat, setEtat] = useState({
    opens: false, message: "message"
  });
  const { opens, message } = etat;
  const [form, setForm] = useState({
    porte: "",
    bureau: "",
    flotte: "",
    fonction: "",
    service: JSON.parse(localStorage.getItem("service")),
    siege: "",
    batiment: "",
    superieur: "",
    urgence: "",
    type: "",
    dir: "",
  });

  const [bureauIsEmpty, setBureauisEmpty] = useState(false);
  const [flotteIsEmpty, setFlotteIsEmpty] = useState(false);
  const [urgenceIsEmpty, setUrgenceIsEmpty] = useState(false);
  const [fonctionIsEmpty, setFonctionIsEmpty] = useState(false);
  const [serviceIsEmpty, setServiceIsEmpty] = useState(false);
  const [superieurIsEmpty, setSuperieurIsEmpty] = useState(false);
  const [matriculeIsEmpty, setMatriculeIsEmpty] = useState(false);
  const [siegeIsEmpty, setSiegeIsEmpty] = useState(false);
  const [batimentIsEmpty, setBatimentIsEmpty] = useState(false);

  useEffect(() => {
    setForm({
      porte: JSON.parse(localStorage.getItem("porte")),
      bureau: JSON.parse(localStorage.getItem("bureau")),
      flotte: JSON.parse(localStorage.getItem("flotte")),
      fonction: JSON.parse(localStorage.getItem("fonction")),
      service: JSON.parse(localStorage.getItem("service")),
      siege: JSON.parse(localStorage.getItem("siege")),
      batiment: JSON.parse(localStorage.getItem("batiment")),
      superieur: JSON.parse(localStorage.getItem("superieur")),
      matricule: JSON.parse(localStorage.getItem("matricule")),
      urgence: JSON.parse(localStorage.getItem("urgence")),
      type: JSON.parse(localStorage.getItem("type")),
      dir: JSON.parse(localStorage.getItem("dir")),
    });

    PersonnelService.getFunction().then((result) => {
      if (result && result.data) {
        setOptions(result.data);
      }
    });
    PersonnelService.getServices().then((result) => {
      if (result && result.data) {
        setServices(result.data);
      }
    });
    SeatService.getSeat().then((result) => {
      if (result && result.data) {
        setSeat(result.data);
      }
    });
    BuildingService.getAllBuilding().then((result) => {
      if (result && result.data) {
        setBuilding(result.data);
      }
    });
    PersonnelService.getDirection().then((result) => {
      if (result && result.data) {
        setRows(result.data);
      }
    });
    if (form.service !== null) {
      PersonnelService.getuserSuperior(form.service).then((result) => {
        if (result && result.data) {
          setSuperieur(result.data);
        }
      });
    }
    setDayofType(JSON.parse(localStorage.getItem("type")))
    setNomChoix(JSON.parse(localStorage.getItem("type")))
  }, [setOptions, setServices]);

  function handleChangeType(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value })
    if (value == 'Direction') {
      setNomChoix('Direction');
      setDayofType(event.target.value);

      PersonnelService.getDirection().then((result) => {
        if (result && result.data) {
          setRows(result.data);
        }
      });
    } else if (value == 'Antenne') {
      setNomChoix('Antenne');
      setDayofType(event.target.value);
      PersonnelService.getAntenne().then((result) => {
        if (result && result.data) {
          setAntennes(result.data);
        }
      });
    }
  };

  function handleClick() {
    console.log("form  : ",form, "Group  : " ,idGroup);
    if (form.flotte !== null && form.bureau !== null && form.fonction !== undefined &&
      form.service !== undefined && form.superieur !== undefined && form.superieur !== null && form.matricule !== null && form.batiment !== null && form.siege !== null) {
      localStorage.setItem("porte", JSON.stringify(form.porte));
      localStorage.setItem("bureau", JSON.stringify(form.bureau));
      localStorage.setItem("flotte", JSON.stringify(form.flotte));
      localStorage.setItem("fonction", JSON.stringify(form.fonction));
      localStorage.setItem("service", JSON.stringify(form.service));
      localStorage.setItem("siege", JSON.stringify(form.siege));
      localStorage.setItem("batiment", JSON.stringify(form.batiment));
      localStorage.setItem("superieur", JSON.stringify(form.superieur));
      localStorage.setItem("urgence", JSON.stringify(form.urgence));
      localStorage.setItem("matricule", JSON.stringify(form.matricule));
      localStorage.setItem("type", JSON.stringify(form.type));
      localStorage.setItem("dir", JSON.stringify(form.dir));
      if (idGroup) {

        if (idGroup !== "utile") {
          navigate("/personnel/add/" + idGroup + "/information");
          window.location.reload();
        } else {
          navigate("/personnel/add/information");
          window.location.reload();
        }
      } else {
        navigate("/personnel/add/information");
        window.location.reload();
      }
    } else {
      if (form.bureau === null || form.bureau === "") {
        setBureauisEmpty(true);
      }
      if (form.flotte === null || form.flotte === "") {
        setFlotteIsEmpty(true);
      }
      if (form.siege === null || form.siege === "") {
        setSiegeIsEmpty(true);
      }
      if (form.batiment === null || form.batiment === "") {
        setBatimentIsEmpty(true);
      }

      // if (form.urgence === null || form.urgence === "") {
      //   setUrgenceIsEmpty(true);
      // }

      if (form.fonction === null || form.fonction === "" || form.fonction === undefined) {
        setFonctionIsEmpty(true);
      }

      if (form.service === null || form.service === "" || form.service === undefined) {
        setServiceIsEmpty(true);
      }

      if (form.superieur === null || form.superieur === "" || form.superieur === undefined) {
        setSuperieurIsEmpty(true);
      }

      if (form.matricule === null || form.matricule === "") {
        setMatriculeIsEmpty(true);
      }
    }

  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (name == 'matricule') {
      setForm({ ...form, [name]: value.toUpperCase() });
      setMatriculeIsEmpty(false);
    } else if (name == 'fonction') {
      setForm({ ...form, [name]: value });
      setFonctionIsEmpty(false);
    } else if (name == 'service') {
      setForm({ ...form, [name]: value })
      setUser(e.target.value)
      PersonnelService.getuserSuperior(value).then((result) => {
        if (result && result.data) {
          setSuperieur(result.data);
        }
      });
      setServiceIsEmpty(false);
    } else if (name == 'superieur') {
      setForm({ ...form, [name]: value });
      setSuperieurIsEmpty(false);
    } else if (name == 'bureau') {
      const phoneNumber = value.replace(/[^\d]/g, '');
      setForm({ ...form, [name]: phoneNumber })
    } else if(name == 'siege') {
      setForm({ ...form, [name]: value });
      setSiegeIsEmpty(false);
    } else if(name == 'batiment') {
      setForm({ ...form, [name]: value });
      setBatimentIsEmpty(false);
    }
    else {
      setForm({ ...form, [name]: value })
    }
  }

  function handleFocusPhone(e) {
    const { name, value } = e.target;
    if (name == 'bureau') {
      setBureauisEmpty(false);
    }
    if (name == 'flotte') {
      setFlotteIsEmpty(false);
    }
    if (name == 'urgence') {
      setUrgenceIsEmpty(false);
    }
  }
  return (
    <React.Fragment>
      <Message isActive={opens} message={message} />
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Siège{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="demo-simple-select-outlined-label">Siège</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Siège" name="siege" value={form.siege} onChange={handleChange}>
                {seat.map((row) => (
                  <MenuItem value={row.seat_id}>{row.seat}</MenuItem>
                ))}
            </Select>
          </FormControl>
          {siegeIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Batiment{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="demo-simple-select-outlined-label">Batiment</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Batiment" name="batiment" value={form.batiment} onChange={handleChange}>
                {building.map((row) => (
                    <MenuItem value={row.building_id}>{row.building}</MenuItem>
                  ))}
            </Select>
          </FormControl>
          {batimentIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Porte</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            label="Porte"
            variant="outlined"
            onChange={handleChange}
            value={form.porte}
            name="porte"
          />
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Flotte{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <Phone x1={3} x2={8} name="flotte" value={form.flotte} onChange={handleChange} onFocus={handleFocusPhone} />

          {flotteIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Numero interne{bullRed}</label>
        </Grid>
        <Grid item xs={8}>

          {/* <Phone x1={3} x2={8} name="flotte" value={form.flotte} onChange={handleChange} onFocus={handleFocusPhone} /> */}
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            label={"Numero interne"}
            name="bureau"
            variant="outlined"
            inputmode="numeric"
            onChange={handleChange}
            onFocus={handleFocusPhone}
            value={form.bureau}
            inputProps={{ maxLength: 3 }}
          />
          {bureauIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Numéro en cas d'urgence</label>
        </Grid>
        <Grid item xs={8}>
          <Phone x1={3} x2={8} name="urgence" value={form.urgence} onChange={handleChange} onFocus={handleFocusPhone} />
          {urgenceIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Fonction{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-outlined-label">Fonction</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Fonction" value={form.fonction} name="fonction" onChange={handleChange}>
              {options.map((row) => (
                <MenuItem value={row.function_id}>{row.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {fonctionIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Direction/Antenne{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Type" name="type" onChange={handleChangeType} value={form.type}>
              <MenuItem value="Direction">Direction</MenuItem>
              <MenuItem value="Antenne">Antenne</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Spacer y={9} />
        {dayofType === "Direction" && (
          <>
            <Grid item xs={3}>
              <label className={classes.labelPosition}>{nomChoix}</label>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">{nomChoix}</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Nom" name="dir" onChange={handleChangeType} value={form.dir}>
                  {rows.map((row) => (
                    <MenuItem value={row.direction_id}>{row.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
        {dayofType === "Antenne" && (
          <>
            <Grid item xs={3}>
              <label className={classes.labelPosition}>{nomChoix}{bullRed}</label>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">{nomChoix}</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Nom" name="dir" onChange={handleChangeType}>
                  {antennes.map((row) => (
                    <MenuItem value={row.direction_id}>{row.name}</MenuItem>
                    //ato no ovaina 
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Service{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-outlined-label">Service</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Service" name="service" value={form.service} onChange={handleChange}>
              {services.map((row) => (
                <MenuItem value={row.service_id}>{row.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {serviceIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Utilisateur Superieur{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-outlined-label">Superieur</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Superieur" name="superieur" value={form.superieur} onChange={handleChange}>
              {superieur.map((row) => (
                <MenuItem value={row.user_id}>{row.first_name}   {row.last_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {superieurIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Matricule{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            label="Matricule"
            variant="outlined"
            onChange={handleChange}
            inputProps={{ textTransform: 'capitalize', }}
            value={form.matricule}
            name="matricule"
          />

          {matriculeIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>

        </Grid>
        <Grid item xs={8}>
          <div className={classes.root}>
            <Button variant="contained" color="primary" onClick={handleClick}>
              Valider
            </Button>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
