import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { escapeRegExp } from "../../../utils";
import ValidationService from "../../../services/ValidationService";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    btnAnnulerDialog: {
        background: "red",
        color: "white",
    },
    btnValiderDialog: {
        background: "rgb(19, 62, 103)",
        color: "white",
    },
    label: {
        fontWeight: "bold",
    },
    id: {
        color: "#3f51b5",
        fontWeight: "bold",
    },
}));

function MyRequestState(props) {
    const [searchText, setSearchText] = React.useState("");

    const [openDialogDetail, setOpenDialogDetail] = React.useState(false);
    const [rows, setRows] = useState([]);
    const [rowDetail, setRowDetail] = useState([]);

    //Samuela: paramètrage pour le loader
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

    const classes = useStyles();
    useEffect(() => {

        setOpenLoadingSimpleBackdrop(true);
        ValidationService.getAllStateRequestByUser().then((result) => {
            if (result && result.data) {
                console.log("result ********", result.data);
                setRows(
                    result.data.map((row, index) => ({
                        ...row,
                        id: index,
                    }))
                );

                setOpenLoadingSimpleBackdrop(false);
            }else{
              setOpenLoadingSimpleBackdrop(false);
            }
        });
    }, []);

    function handleClickRow(e) {
        if (e.row.libelle_module_type === "congé") {
            ValidationService.getDetailOfLeave(e.row.validation_id).then((result) => {
                if (result && result.data) {
                    console.log("details ********", result.data);
                    setRowDetail(result.data);
                }
            });
        }
        if (e.row.libelle_module_type === "mission") {

        }
        setOpenDialogDetail(true);
    }

    function handleClose() {
        setOpenDialogDetail(false);
    };

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = [...rows].filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
    };
    const columns = [
        {
            field: "submission_time",
            headerName: "Date de la demande de validation",
            width: "350",
        },
        {
            field: "libelle_module_type",
            headerName: "Type",
            width: '200',
        },

        {
            field: "state",
            headerName: "Status",
            width: "350",
            valueFormatter: (params) => {
                switch (params.row.state) {
                    case 'DECLINED':
                        const stateDeclinedStr = 'Réfusé par ' + params.row.superior_name;
                        return stateDeclinedStr;
                    case 'CONFIRMED':
                        const stateOkStr = 'Validé par ' + params.row.superior_name;
                        return stateOkStr;
                }
            }
        },
        {
            field: "observation",
            headerName: "observation",
            width: "450",
            valueFormatter: (params) => {
                if (params.row.observation != "") {
                    return params.row.observation;
                } else {
                    return "-";
                }
            }
        },
    ];

    return (
        <React.Fragment>

            <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
            <div style={{ height: "70vh", width: "100%" }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    pageSize={7}
                    rowsPerPageOptions={[7]}
                    disableSelectionOnClick
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(""),
                        },
                    }}
                    onRowClick={handleClickRow}
                />
            </div>
            <div>
                <Dialog
                    open={openDialogDetail}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="sm"
                >
                    <DialogTitle id="form-dialog-title" style={{ color: 'white', }}>
                        {"Détail de la demande"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" >
                            <Grid container spacing={2} >
                                <Grid item xs={4}>
                                    <label className={classes.label}>Identification:</label>
                                </Grid>
                                <Grid item xs={8}>
                                    <label className={classes.id}>{rowDetail.leave_code}</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <label className={classes.label}>Date de Départ:</label>
                                </Grid>
                                <Grid item xs={2}>
                                    <label className={classes.label1}>{rowDetail.begin_time}</label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className={classes.label1}>{rowDetail.begin_time_session === "morning" ? "Matin" : "Après-midi"}</label>
                                </Grid>

                                <Grid item xs={4}>
                                    <label className={classes.label}>Date de retour:</label>
                                </Grid>
                                <Grid item xs={2}>
                                    <label className={classes.label1}>{rowDetail.end_time}</label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className={classes.label1}>{rowDetail.end_time_session === "morning" ? "Matin" : "Après-midi"}</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <label className={classes.label}>Nombre de jours:</label>
                                </Grid>
                                <Grid item xs={8}>
                                    <label className={classes.label1}>{rowDetail.duration}</label>
                                </Grid>
                                <Grid item xs={4}>
                                    <label className={classes.label}>Motif:</label>
                                </Grid>
                                <Grid item xs={8}>
                                    <label className={classes.label1}>{rowDetail.reason}</label>
                                </Grid>
                            </Grid>

                            <br></br>
                            <span>
                                Le rendu de votre demande sous forme{" "}
                                <Link
                                    to={"/leavePdfRender/" + rowDetail.filename}
                                    target="_blank"
                                >
                                    pdf.
                                </Link>
                                <br></br>
                            </span>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" color="primary">
                            OK
              </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </React.Fragment>
    );
}
export default MyRequestState;