import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const ObjectifService = {
  async add(form, id) {
    try {
      const result = id ?
        await axios.put(`${apiUrl}/objectif/saveStockObjectif/${id}`, {
          id: id,
          "mission_planning_id": form.mission_planning_id,
          "description_objectif": form.description_objectif,
          "commentaire_objectif": form.commentaire_objectif,
          "libelle_mode_mesure": form.type_objectif,
          "taux_objectif": form.taux_objectif,
          "echelonne_objectif": form.echelonne_objectif,
          "qualitatif_objectif": form.qualitatif_objectif,
          "chiffre_objectif": form.chiffre_objectif,
          "unite_objectif": form.unite_objectif,
          "mode_mesure_id": form.mode_mesure_id
        })
        :
        await axios.post(`${apiUrl}/objectif/saveStockObjectif/`, {
          "mission_planning_id": form.mission_planning_id,
          "description_objectif": form.description_objectif,
          "commentaire_objectif": form.commentaire_objectif,
          "libelle_mode_mesure": form.type_objectif,
          "taux_objectif": form.taux_objectif,
          "echelonne_objectif": form.echelonne_objectif,
          "qualitatif_objectif": form.qualitatif_objectif,
          "chiffre_objectif": form.chiffre_objectif,
          "unite_objectif": form.unite_objectif
        });
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }

  ,

  async insertion() {
    try {
      const result = await axios.get(`${apiUrl}/objectif/saveObjectif/`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getByIdMissionPlanning(id) {
    try {
      const result = await axios.get(`${apiUrl}/MissionRequestUserRequired/getAll/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getId(id) {
    try {
      const result = await axios.get(`${apiUrl}/objectif/getById/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async getById(id) {
    try {
      const result = await axios.get(`${apiUrl}/objectif/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async delete(id) {
    try {
      const result = await axios.delete(`${apiUrl}/objectif/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

}
export default ObjectifService;
