import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Message from "../tools/Message";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import ImmobilisationService from "../../services/ImmobilisationService";
import FileService from "../../services/FileService";
import * as FileSaver from "file-saver";
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function ImportExcel(props) {
    const navigate = useNavigate();
    var myCurrentDate = new Date();
    var date = myCurrentDate.getFullYear() + '_' + (myCurrentDate.getMonth() + 1) + '_' + myCurrentDate.getDate() + '_' + myCurrentDate.getHours() + '_' + myCurrentDate.getMinutes() + '_' + myCurrentDate.getSeconds();

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const openDialog = props.open;
    const closeDialog = props.closeDialog;
    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = React.useState({
        open: false,
        color: 'green',
        messageInfo: '',
    });
    const { open, color, messageInfo } = message;
    const [buttonName, setButtonName] = useState('Choisir un fichier');
    const initialState = "IMPORTER";
    const [buttonText, setButtonText] = useState(initialState);
    
    function onFileChange(event) {
        setSelectedFile(event.target.files[0]);
        setButtonName(event.target.files[0].name);
    }

    function onFileUpload() {
        const formData = new FormData();
        formData.append(
            "code_immo",
            selectedFile,
            selectedFile.name
        );
        console.log("props :"+1);
        setButtonText("IMPORTATION EN COURS ...");
            
        ImmobilisationService.importFile(formData)
            .then((result) => {                
                if (result.status) {
                    setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
                    setTimeout(function () {
                        navigate("/logistic/immobilisations"); 
                        window.location.reload(); 
                    }, 3000);
                } else {
                    setButtonText(initialState);
                    setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
                }
            })
            .catch((err) => {
                setButtonText(initialState);
                setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
            });
    }
    
    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }

    function onDownloadModel() {
        FileService.readModeleImmo().then((result) => {
            if (result) {
                const data = new Blob([result.data], { type: fileType });
                FileSaver.saveAs(data, "modele_immobilisation.xlsx");
            }
        });
    }
    return (
        <div>
            <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="form-dialog-title" color="primary" /*style={{backgroundColor: '#0000B3'}}*/ >{"Importer des immobilisations"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {" A RECOMMANDER: Télécharger d'abord le modèle à remplir en cliquant sur le bouton \"Télécharger modèle\"."}<br />
                        {" NB: Assurez-vous que tous les contenus dans votre fichier .xls|.xlsx sont conformes au modèle proposé. "}
                    </DialogContentText>
                    {buttonText === 'IMPORTATION EN COURS ...' && <LinearProgress />}
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: "#6ACFF6" }} variant="contained" onClick={onDownloadModel}>Télécharger modèle</Button>
                    <input
                        id="contained-button-file"
                        type="file" name="code_immo" onChange={onFileChange} style={{ display: 'none' }} />
                    {buttonName !== 'Choisir un fichier' && <Button variant="contained" color="primary" onClick={onFileUpload}>{buttonText}</Button>} 
                    {/*buttonName !== 'Choisir un fichier' && <Button variant="contained" color="primary" onClick={onFileUpload}>Modifier</Button>*/} 
                    
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="" component="span" >{buttonName}</Button>
                    </label>
                </DialogActions>
            </Dialog>

            {/* <ExportExcel dataToExport={dataToExport} fileName={fileName} /> */}
        </div>
    );
}

