import { useState, useEffect } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Spacer from "../../../components/Spacer";
import PlanningMissionService from "../../../services/PlanningMissionService";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header";
import Title from "../../../components/Title"; 
import { useParams } from "react-router-dom";
import { CreateTwoTone } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    bold: {
        fontWeight: 600
    },
    labelPosition1: {
        float: 'right',
    },
});

function AgendaMissionView(props) {
    const navigate = useNavigate();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const params = useParams();
    const mission_planning_id = params.id;
    useEffect(() => {
        PlanningMissionService.getAgendaMissionPlanning(mission_planning_id).then((result) => {
            if (result && result.data) {
                setData(result.data);
            }
        });
    }, []);
    const handleEdit = () => (ev) => {
        navigate("/mission/agenda/"+mission_planning_id+"/modif");
    };
    return(
        <MainContainer {...headerDataMission}>
        <Title>Agenda</Title>
        <Spacer y={2} />
            <TableContainer component={Paper}>
                <Spacer y={1} />
                {/* <Button onClick={handleEdit()} style={{ float: "right" }}>
                    <CreateTwoTone style={{ color: "black"}} />
                </Button> */}
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.bold} >Date</TableCell>
                            <TableCell className={classes.bold} >Activités</TableCell>
                            <TableCell className={classes.bold} >Missionnaires</TableCell>
                            <TableCell className={classes.bold} >Participants</TableCell>
                            <TableCell className={classes.bold} >Observation</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.map((item) => (
                        <TableRow key={item.mission_planning_agenda_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="th" scope="row" className={classes.bold} >{item.date}</TableCell>
                            <TableCell>{item.activity}</TableCell>
                            <TableCell>{item.missionary}</TableCell>
                            <TableCell>{item.participants}</TableCell>
                            <TableCell>{item.observation}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainContainer>
    );
}
export default AgendaMissionView;