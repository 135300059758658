import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import Spacer from '../../components/Spacer';
import MainContainer from '../../components/MainContainer';
import { headerDataRH } from "../../data/modules/hr-header";
import CandidatureService from "../../services/CandidatureService";
import { useNavigate } from 'react-router-dom';
import Title from "../../components/Title";
import { useEffect, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import Message from "../tools/Message";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    elevation: 0
  },
}));


export default function CandidatureAffichage() {

  const classes = useStyles();
  const navigate = useNavigate();
  const [rows,setRows]=useState([]);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = useState(false);
  const [message, setMessage] = useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  const columns = [
      { 
        field: 'libelle_type',
        headerName: 'Poste',
        width: 300,
      },
      { 
        field: 'description',
        headerName: 'Référence AMI',
        width: 200,
      },
      {
        field: 'formatted_date_debut_publication',
        headerName: 'Date début publication',
        width: 200,
      },
      { 
        field: 'formatted_date_fin_publication',
        headerName: 'Date fin publication',
        width: 200,
      },
      {
        field: 'cloture',
        headerName: 'Statut',
        width: 120,
        renderCell: (params) => (
          params.value === "0" ? "Ouvert  " : "Cloturé"
        )
      }, 
      {
        field: 'ct',
        headerName: 'Action',
        width: 120,
        renderCell: (params) => {
          return (
            <div>
              {params.row.cloture === "0" && (
                <Button variant="outlined" color="primary" style={{ fontWeight: 600 }} onClick={() => handleCloture(params.row.id_type)}>
                  Cloturer
                </Button>
              )}
            </div>
          );
        },
      }
        
    ];

    function handleCloture(id){
      CandidatureService.cloture(id).then((result) => {
        if (result.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
          setOpenLoadingSimpleBackdrop(true);
          CandidatureService.list().then((result) => {
            setOpenLoadingSimpleBackdrop(true);
            if (result && result.data) {
              setRows(result.data.map((row) => ({ ...row, id: row.id_type })));
              setOpenLoadingSimpleBackdrop(false);
            }
            else{
              setOpenLoadingSimpleBackdrop(false);
            }
          });
        }
        else{
          setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur" });
        }
      });
    }

  useEffect(() => {
    CandidatureService.list().then((result) => {
      setOpenLoadingSimpleBackdrop(true);
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.id_type })));
        setOpenLoadingSimpleBackdrop(false);
      }
      else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);

  function handleCloseMessage(){
    setMessage({...message, open: false});
  }
  function handleClick(){
     navigate('/add_candidature');
   }
  return (
    
    <MainContainer {...headerDataRH}>  
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
           <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
           <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
          <Button variant="contained" color="secondary"   onClick={handleClick}  style={{ fontWeight: 600,marginBottom:"20px" }} >CREER</Button>
          <Paper className={classes.paper} variant="outlined">
             <Title>Listes des Candidatures</Title>
             <Spacer y={2} />
             <div style={{ height: "70vh", width: '100%' }} className={classes.root} >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={7}
                  rowsPerPageOptions={[7]}
                  disableSelectionOnClick
                />
             </div> 
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
