import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const AntenneService = {
    async saveAntenne(type,name, code, desc,d_id, id) {
      console.log("aaaaa",parseInt(d_id))
         try {
            const result = id ?
             await axios.put(`${apiUrl}/antenne/${id}`, {
              id:id,
              type:type,
              name:name,
              code:code,
              description:desc,
              direction_parent_id:parseInt(d_id)
           })
           :
             await axios.post(`${apiUrl}/antenne`, {
              type:type,
              name:name,
              code:code,
              description:desc,
              direction_parent_id:parseInt(d_id)
           });
           if (result) {
              return result.data;
           }
          } catch (error) {
            if (error.response) {
              return error.response.data;
             }
           }
    } ,
    
    async antenneById(id){
      try {
          const result = await axios.get(`${apiUrl}/antenne/id/${id}`  );
          if (result) {
            // TODO: replace result.data.message by the token
  
            return result.data;
          }
        } 
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      }
  ,
  async getAntenne(){
    try {
        const result = await axios.get(`${apiUrl}/antenne`);
        if (result) {
          // TODO: replace result.data.message by the token

          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    }
,
  async getWithAntenne(){
    try {
        const result = await axios.get(`${apiUrl}/antenne/direction`);
        if (result) {
          // TODO: replace result.data.message by the token

          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    }
,

async deleteAntenne(id){
    try {
        const result = await axios.delete(`${apiUrl}/antenne/${id}`  );
        if (result) {
           return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    },
       async getService(){
        try {
            const result = await axios.get(`${apiUrl}/antenne/export/`);
            if (result) {
    
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        
      }
       ,
       async importAntenne(){
        try {
            const result = await axios.post(`${apiUrl}/antenne/import/`);
            if (result) {
    
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        
      }
       ,
        
       async addAntenne(){
        try {
            const result = await axios.get(`${apiUrl}/antenne/name`);
            if (result) {
    
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        
      }
       ,

}

export default AntenneService;