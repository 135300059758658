import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  paper: {
      marginTop: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
  },
  avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
  },
  form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
  },
  submit: {
      margin: theme.spacing(3, 0, 2),
      display: "center",
      backgroundColor: "rgb(19,62,103)",
  },
  logo: {
      backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/fid_logo.png'})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      marginBottom: "20px",
      width: '150px',
      height: '150px',
  },
  divCenter: {
      display: "flex",
      flexDirection: "column",
      margin: "0px auto 0px",
      padding: "32px 40px",
      background: "rgb(255, 255, 255) none repeat scroll 0% 0%",
      borderRadius: "3px",
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 20px",
      boxSizing: "border-box",
      color: "rgb(94, 108, 132)",
      width: "100%",
      textAlign: "center",
  },
  alert: {
      textAlign: "center",
  },
  imgLogo: {
      width: '100px',
      height: '100px',
  },
  linkForgotPwd: {
      color: "rgb(19,62,103)",
  }
}));
export default useStyles;