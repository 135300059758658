import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import LeaveTypeTable from './LeaveTypeTable';
import { Button } from '@material-ui/core';
import Spacer from '../../components/Spacer';
import MainContainer from '../../components/MainContainer';
import { userDetailContext } from "../../App";
import {useContext} from "react";
import axios from 'axios';
import { headerDataSettings } from '../../data/modules/settings-header';
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    elevation: 0
  },
}));


export default function LeaveTypeList() {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const fetchData = () =>{
     axios.get('https://jsonplaceholder.typicode.com/posts').then(r => setData(r.data) )
    }
    fetchData()
  }, [])

  function handleClick() {
      navigate("/leave_type/add");
  }

  const classes = useStyles()
  const contextData = useContext(userDetailContext);
  return (
    
    <MainContainer {...headerDataSettings}>
      <Grid container item xs={12}>
        <Spacer x={2} />
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }}>
          CREER
        </Button>

        <Spacer x={2} />
      
        {/* <TextField
          id="outlined-basic"
          label="Recherche"
          variant="outlined"
          size="small"
      
        /> */}
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
     
        
          <Paper variant="outlined" className={classes.paper}>
            <LeaveTypeTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
