import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  Box,
  Autocomplete,
  Checkbox,
} from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem, InputLabel, Chip, OutlinedInput } from "@material-ui/core";
import { extendMoment } from "moment-range";
import { useNavigate,useParams } from "react-router-dom";
import { userDetailContext } from "../../App";
import Moment from "moment";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import MettingService from "../../services/MettingService";
import MeetingRoomService from "../../services/MeetingRoomService";
import { format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import Message from "../tools/Message";
import MailConfigAddService from "../../services/MailConfigAddService";
import { Menu} from "@mui/material";
import { headerMail } from "../../data/modules/mail-header";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const MailConfigAdd = () => {
  const [directions, setDirections] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValueDirectionList, setSelectedValueDirectionList] = useState([]);
  const [selectedValueDirectionIdList, setSelectedValueDirectionIdList] = useState([]);


  const [services, setServices] = useState([]);
  const [selectedValueService, setSelectedValueService] = useState('');
  const [selectedValueServiceList, setSelectedValueServiceList] = useState([]);
  const [selectedValueServiceIdList, setSelectedValueServiceIdList] = useState([]);
  const [disableservice, setDisableservice] = useState(true);


  const [fonctions, setFonctions] = useState([]);
  const [selectedValueFonction, setSelectedValueFonction] = useState('');
  const [selectedValueFonctionList, setSelectedValueFonctionList] = useState([]);
  const [selectedValueFonctionIdList, setSelectedValueFonctionIdList] = useState([]);
  const [disablefonction, setDisablefonction] = useState(true);

  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const directionResult = await MailConfigAddService.getDirection();
        setDirections(directionResult.data);

      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);


  // const handleChange = async (event) => {
  //   console.log('Selected value:', event.target.value);
  //   setSelectedValue(event.target.value);
  //   const idD = event.target.value;
  
  //   try {
  //     const response = await MailConfigAddService.getServiceByDirection(idD);
  //     setServices(response.data);
  //   } catch (error) {
  //     console.error('Failed to fetch services:', error);
  //   }
  // };

  // const handleChangeService = async (event) => {
  //   console.log('Selected value:', event.target.value);
  //   setSelectedValueService(event.target.value);
  //   // selectedValueServiceList.push[event.target.value];
  //   // console.log('OKK LISTE : ', selectedValueServiceList);
  //   const idS = event.target.value;

  //   try {
  //     const response = await MailConfigAddService.getFunctionByService(idS);
  //     setFonctions(response.data);
  //   } catch (error) {
  //     console.error('Failed to fetch services:', error);
  //   }
  // };

  // const handleChangeFonction = (event) => {
  //   console.log('Selected value:', event.target.value);
  //   setSelectedValueFonction(event.target.value);
  // };

  const handleRestart = (event) => {
    setSelectedValue(null);
    selectedValueDirectionList.length = 0;
    selectedValueServiceList.length = 0;
    selectedValueFonctionList.length = 0;
    setSelectedValueService(null);
    setSelectedValueFonction(null);

    navigate("/mailsend");
  };


  const [messagepop, setMessagepop] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = messagepop;


  const handleNextt = (event) => {
    console.log('Direction BEFORE :'. selectedValueDirectionIdList);
    console.log('Service BEFORE :'. selectedValueServiceIdList);
    console.log('Fonction BEFORE :'. selectedValueFonctionIdList);
            
    if(selectedValueDirectionList.length == 0){
      setMessagepop({ ...messagepop, open: true, color: "red", messageInfo: "Veuillez choisir une direction" });
    }else if(selectedValueDirectionIdList.length > 0 && selectedValueServiceIdList.length == 0 && selectedValueFonctionIdList.length == 0){
      selectedValueServiceIdList.length = 0;
      selectedValueFonctionIdList.length = 0;
      navigate("/maillist", { state: { selectedValueDirectionIdList , selectedValueServiceIdList, selectedValueFonctionIdList } });
    }else if(selectedValueServiceIdList.length > 0 && selectedValueFonctionIdList.length == 0){
      selectedValueDirectionIdList.length = 0;
      selectedValueFonctionIdList.length = 0;
      navigate("/maillist", { state: { selectedValueDirectionIdList , selectedValueServiceIdList, selectedValueFonctionIdList } });
    }else if(selectedValueFonctionIdList.length > 0){
      selectedValueServiceIdList.length = 0;
      selectedValueDirectionIdList.length = 0;
      navigate("/maillist", { state: { selectedValueDirectionIdList , selectedValueServiceIdList, selectedValueFonctionIdList } });
    }

    console.log('Direction PASS :'. selectedValueDirectionIdList);
    console.log('Service PASS :'. selectedValueServiceIdList);
    console.log('Fonction PASS :'. selectedValueFonctionIdList);


  };

  function handleCloseMessage() {
    setMessagepop({ ...messagepop, openMessage: false });
  }

//////////////////////////////////////////////////////////
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const handleChangeDirectionListe = (event, newValue) => {
  setSelectedValueDirectionList([...newValue]);
  const directionIds = newValue.map(direction => parseInt(direction.direction_id));

  setSelectedValueDirectionIdList([...directionIds]);
  console.log("ATO AM DIRECTION : ", selectedValueDirectionIdList);
  console.log("ATO AM DIRECTION NO ID : ", selectedValueDirectionList);
  setDisableservice(false);
  
  // console.log('Direction IDs: ', directionIds);
};


useEffect(() => {
  const fetchData = async () => {
    if (selectedValueDirectionList.length > 0) {
      try {
    console.log("ATO AM SERVICE : ", selectedValueDirectionIdList);
    console.log("ATO AM SERVICE NO ID : ", selectedValueDirectionList);
        const response = await MailConfigAddService.getServiceByMultipleDirection(selectedValueDirectionIdList);
        setServices(response.data);
        // console.log('SERVICES :', services);
      } catch (error) {
        console.error('Failed to fetch services:', error);
      }
    }
  };
  fetchData();
}, [selectedValueDirectionList, selectedValueDirectionIdList]);




const handleChangeServiceListe = (event, newValue) => {
  setSelectedValueServiceList([...newValue]);
  const serviceIds = newValue.map(service => parseInt(service.service_id));
  setSelectedValueServiceIdList([...serviceIds]);
  setDisablefonction(false);
};

// useEffect(() => {
//   console.log('Updated Valeur service:', selectedValueServiceList);
// }, [selectedValueServiceList]);

// useEffect(() => {
//   console.log('Updated Valeur serviceID:', selectedValueServiceIdList);
// }, [selectedValueServiceIdList]);

useEffect(() => {
  const fetchData = async () => {
    if (selectedValueServiceList.length > 0) {
      try {
        const response = await MailConfigAddService.getFunctionByMultipleService(selectedValueServiceIdList);
        setFonctions(response.data);
        console.log('FONCTIONS :', fonctions);
      } catch (error) {
        console.error('Failed to fetch services:', error);
      }
    }
  };
  fetchData();
}, [selectedValueServiceIdList]);

const handleChangeFonctionListe = (event, newValue) => {
  setSelectedValueFonctionList([...newValue]);
  const fonctionIds = newValue.map(fonction => parseInt(fonction.function_id));
  setSelectedValueFonctionIdList([...fonctionIds]);

};


  return (
      <MainContainer {...headerMail}>
        <Message open={messagepop.open} color={messagepop.color} messageInfo={messagepop.messageInfo} handleClose={handleCloseMessage} />
         <Container sx={{ width: '900px' }}>
            <Box component="form" mt={2}>
              <Grid container spacing={3}>
                  <Grid item xs={12}>
                      <Typography variant="h4" gutterBottom style={{textAlign : "center"}}>
                        Choisissez votre déstinataire
                      </Typography>
                      <Box mt={2} justifyContent="space-between" width="100%" style={{marginLeft : "350px"}}>
                        <Button onClick={handleRestart} style={{ minWidth: '50px', maxWidth: '150px', marginLeft : '350px'}}>
                          REINITIALISER
                        </Button>
                      </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Autocomplete
                            multiple
                            id="direction-checkboxes-tags-demo"
                            options={directions}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.code}
                            renderOption={(props, option, { selected }) => {
                              const { key, ...optionProps } = props;
                              return (
                                <li key={key} {...optionProps}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.code}
                                </li>
                              );
                            }}
                            value={selectedValueDirectionList}
                            onChange={handleChangeDirectionListe}
                            name = "dir"
                            // style={{ width: 500 }}
                            renderInput={(params) => (
                              <TextField
                              {...params}
                              fullWidth
                              id="direction-outlined-basic"
                              size="small"
                              label="Direction"
                              placeholder="Direction"
                              />
                            )}
                          />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <Autocomplete
                          multiple
                          id="service-checkboxes-tags-demo"
                          options={services}
                          disabled={disableservice}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.code}
                          renderOption={(props, option, { selected }) => {
                            const { key, ...optionProps } = props;
                            return (
                              <li key={key} {...optionProps}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.code}
                              </li>
                            );
                          }}
                          value={selectedValueServiceList}
                          onChange={handleChangeServiceListe}
                          renderInput={(params) => (
                            <TextField
                            {...params}
                            fullWidth
                            id="service-outlined-basic"
                            size="small"
                            label="Service"
                            placeholder="Service"
                            />
                          )}
                        />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <Autocomplete
                          multiple
                          id="fonction-checkboxes-tags-demo"
                          options={fonctions}
                          disabled={disablefonction}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.code}
                          renderOption={(props, option, { selected }) => {
                            const { key, ...optionProps } = props;
                            return (
                              <li key={key} {...optionProps}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            );
                          }}
                          value={selectedValueFonctionList}
                          onChange={handleChangeFonctionListe}
                          renderInput={(params) => (
                            <TextField
                            {...params}
                            fullWidth
                            id="fonction-outlined-basic"
                            size="small"
                            label="Fonction"
                            placeholder="Fonction"
                            />
                          )}
                        />
                  </FormControl>
                  </Grid>

                  <Box mt={2} display="flex" justifyContent="space-between" width="100%" style={{marginLeft : "400px"}}>
                    <Button variant="contained" color="primary" onClick={handleNextt} style={{ minWidth: '50px', maxWidth: '150px', marginLeft : '350px'}}>
                      SUIVANT
                    </Button>
                  </Box>

                </Grid>
              </Box>
            </Container>
        </MainContainer>
  );

};

export default MailConfigAdd;
