import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import {
    Grid,
    Paper,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField
} from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        elevation: 0
    },
}));


export default function PersonnalSpecialValidatorList(props) {
    const rows = props.rows;
    const handleEditSpecialValidator = props.handleEditSpecialValidator;
    const handleDeleteSpecialValidator = props.handleDeleteSpecialValidator;
    const classes = useStyles();

    const specialValidatorConfigColumns = [
        {
            field: 'sup',
            headerName: 'Liste de(s) validateur(s) spécial',
            width: 500,
        },
        {
            field: 'action',
            headerName: 'Action',
            disableClickEventBulbing: true,
            renderCell: (params) => {
                return (
                    <div>
                        <Button onClick={handleEditSpecialValidator(params.id)} title="Modifier ce validateur">
                            <CreateTwoTone style={{ color: "black" }} />
                        </Button>
                        <Button onClick={handleDeleteSpecialValidator(params.id)} title="Supprimer cette configuration">
                            <DeleteOutlineRounded style={{ color: "red" }} />
                        </Button>
                    </div>
                );
            },
            width: 175,
        },
    ];

    return (
        <React.Fragment>
            <div style={{ height: "40vh", width: '100%' }} className={classes.root} >
                <DataGrid
                    rows={rows}
                    columns={specialValidatorConfigColumns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                />
            </div>
        </React.Fragment>
    );
}
