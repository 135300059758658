import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DownloadIcon from "@mui/icons-material/Download";
import Title from "../../../components/Title";
import SettingsApplicationsSharpIcon from "@mui/icons-material/SettingsApplicationsSharp";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import Message from "../../personnel/Message";
import Result from "../../personnel/Result";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DocumentService from "../../../services/DocumentService";
import * as FileSaver from "file-saver";
import PrivilegeDialog from "../../tools/PrivilegeDialog";
import PrivilegeButton from "../../tools/PrivilegeButton";
import RubriquesService from "../../../services/RubriquesService";
import Spacer from "../../../components/Spacer";
import { hasPrivilege } from "../../../utils/privilege";
import { userDetailContext } from "../../../App";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AddLocation from "../../tools/AddLocationDialog";
import AddLocationDialog from "../../tools/AddLocationDialog";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  textField: {
    float: "rigth",
    width: "20%",
  },
  select: {
    width: "20%",
  },
}));

export default function DocumentTable(props) {
  const navigate = useNavigate();

  const classes = useStyles();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const contextData = useContext(userDetailContext);

  const location = useLocation();

  const { pathname } = location;

  // const [fichier, setFichier] = useState(false);

  const [openDialogFile, setOpenDialogFile] = useState(false);

  const splitLocation = pathname.split("/");

  const [fileNameToDownload, setFileNameToDownload] = useState("");

  const [documentIdToDownload, setDocumentIdToDownload] = useState(0);

  const [isid, setIsid] = useState(0);

  const [ip, setIp] = useState(0);

  const [documentIdToSetPrivilege, setDocumentIdToSetPrivilege] = useState([]);

  const [selectedId, setSelectedId] = useState(0);

  const [document, setDocument] = useState([]);

  const [etat, setEtat] = useState({
    openDeleteDialog: false,
    message: "message",
    accept: false,
  });

  const [etats, setEtats] = useState({
    open: false,
    message: "message",
    color: "",
  });

  const [download, setDownload] = useState({
    openDownloadDialog: false,
    message: "message",
    accept: false,
  });

  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);

  const [addLocationForId, setAddLocationForId] = useState([]);

  const [form, setForm] = useState({
    id: "",
    auteur: "",
    titre: "",
    resume: "",
    date_edition: "",
    date_creation: "",
    mot_cle: "",
    fichier: "",
    nom_rubrique_secondaire: "",
    rubrique_secondaire_id: [],
  });

  const [rows, setRows] = useState([]);

  const columns = [
    // {
    //   field: "number",
    //   headerName: "N°",
    //   width: 100,
    // },

    {
      field: "codification",
      headerName: "Codification",
      width: 200,
    },
    {
      field: "titre",
      headerName: "Titre",
      width: 300,
    },

    {
      field: "rubrique_mere",
      headerName: "Rubrique mère",
      width: 200,
    },

    {
      field: "code_couleur",
      headerName: "Code couleur de la rubrique mère",
      width: 350,
      renderCell: (params) => {
        return (
          <>
            {document.map((item) => {
              if (item.id == params.id) {
                return (
                  <div
                    style={{
                      background: item.code_couleur,
                      width: "60px",
                      height: "20px",
                      marginLeft: "50px",
                    }}
                  ></div>
                );
              }
            })}
          </>
        );
      },
    },

    {
      field: "auteur",
      headerName: "Uploader",
      width: 300,
    },

    {
      field: "resume",
      headerName: "Resumé",
      width: 300,
    },

    {
      field: "date_creation",
      headerName: "Date de création",
      width: 200,
    },

    {
      field: "date_edition",
      headerName: "Date de modification",
      width: 230,
    },

    {
      field: "mot_cle",
      headerName: "Mots clé",
      width: 250,
    },

    {
      field: "fichier",
      headerName: "Nom du fichier",
      width: 250,
    },

    {
      field: "label",
      headerName: "Etat physique du document",
      width: 300,
    },

    {
      field: "current_holder",
      headerName: "Detenteur(s) actuel",
      width: 700,
    },

    {
      field: "location",
      headerName: "Emplacement",
      width: 700,
    },
    {
      field: "action",
      headerName: "Action",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto auto",
              }}
            >
              {/* {console.log('document id list to set privilege: ', documentIdToSetPrivilege)} */}
              {hasPrivilege(
                contextData.privileges,
                "Button_setting_privilege"
              ) === true ? (
                <Button
                  onClick={(e) => handlePrivilegeDialog(e, params.id)}
                  title="Configurer les privilèges"
                >
                  <SettingsApplicationsSharpIcon style={{ color: "grey" }} />
                </Button>
              ) : (
                documentIdToSetPrivilege.map((id) => {
                  if (params.id == id) {
                    return (
                      <Button
                        onClick={(e) => handlePrivilegeDialog(e, params.id)}
                        title="Configurer les privilèges"
                      >
                        <SettingsApplicationsSharpIcon
                          style={{ color: "grey" }}
                        />
                      </Button>
                    );
                  } else return null;
                })
              )}

              <Button
                onClick={(e) => {
                  handleOpenAddLocationDialog(e, params.id);
                }}
                title="Ajouter ou modifier l' emplacement du document"
                style={{ color: "#004D01" }}
                startIcon={<AddCircleOutlineIcon />}
              ></Button>

              <Button
                onClick={handleDownload(params.id)}
                title="Télécharger le fichier"
                style={{ color: "green" }}
                startIcon={<DownloadIcon />}
              ></Button>

              <PrivilegeButton
                documentId={params.id}
                userId={localStorage.getItem("USER")}
                edit={(e) => handleEditDocument(e, params.id)}
                delete={(e) => handleDeleteDocument(e, params.id)}
                moduleType={"document"}
              />
            </div>
          </>
        );
      },
      width: 350,
    },
  ];

  const [privilegeDialog, setPrivilegeDialog] = useState({
    open: false,
  });

  const [addLocationDialog, setAddLocationDialog] = useState({
    open: false,
  });

  function handleDeleteDocument(event, id) {
    setEtat({
      openDeleteDialog: true,
      message: "Voulez-vous vraiment supprimer ?",
    });
    setIsid(id);
  }

  function handleAccept() {
    setEtat({
      open: false,
      message: "",
      accept: true,
    });

    DocumentService.deleteDocument(isid).then((results) => {
      console.log("delete results: ", results);

      const userId = localStorage.getItem("USER");
      const deletedDocumenteId = results.deletedDocument["document_id"];
      const deletedDocumentName = results.deletedDocument["titre"];

      RubriquesService.saveHistory(
        userId,
        deletedDocumenteId,
        deletedDocumentName,
        "Suppression de document"
      ).then((historyResponse) => {
        if (historyResponse.status) {
          console.log("Historique de suppression de document enregistrée");
        } else {
          console.log("Historique de suppression de document non enregistrée");
        }
      });

      DocumentService.getDocument().then((result) => {
        console.log(result);
        if (result && result.data) {
          setRows(result.data.map((row) => ({ ...row, id: row.id })));
         
        }
      });
      if (results.status) {
        setEtats({
          ...etats,
          open: true,
          color: "green",
          message: results.message,
        });
        navigate("/document");
      } else {
        setEtats({
          ...etats,
          open: true,
          color: "red",
          message: results.message,
        });
      }
    });
  }

  function handleMessage() {
    setEtat({
      open: false,
      message: "",
    });
    setDownload({
      openDownloadDialog: false,
    });
  }

  const handleDownload = (id) => (ev) => {
    setDownload({
      openDownloadDialog: true,
      message: "Voulez-vous télécharger le fichier ?",
    });

    setDocumentIdToDownload(id);
  };

  function handleAcceptDownload() {
    setDownload({
      open: false,
      message: "",
      accept: true,
    });

    const userId = localStorage.getItem("USER");

    DocumentService.getFichier(documentIdToDownload).then((result) => {
      if (result && result.data) {
        console.log(result.data);
        console.log(result.data.fichier);
        console.log(typeof result.data);

        setFileNameToDownload(result.data.fichier);

        const filenameResult = result.data.fichier;
        DocumentService.readFileContent(filenameResult).then((results) => {
          if (results) {
            const data = new Blob([results.data], { type: fileType });
            FileSaver.saveAs(data, filenameResult);
          }
        });

        RubriquesService.saveHistory(
          userId,
          documentIdToDownload,
          result.data.fichier,
          "Télechargement du fichier",
          ip
        ).then((response) => {
          if (response.status) {
            console.log("Historique ajoutée pour le telechargement");
          }
        });
      }
    });
  }

  function handleClickOpen() {
    setOpenDialogFile(true);
  }

  function handleClose() {
    setOpenDialogFile(false);
  }

  function handleCloseMessage() {
    setEtats({ ...etats, open: false });
    setDownload({
      openDownloadDialog: false,
    });
  }

  function handleEditDocument(e, id) {
    navigate("/document/add/" + id);
  }

  function handlePrivilegeDialog(e, id) {
    setPrivilegeDialog({
      ...privilegeDialog,
      open: true,
    });
    setSelectedId(id);
    console.log("id document pour la configuration de privilege: ", id);
  }

  function handleClosePrivilegeDialog() {
    setPrivilegeDialog({ ...privilegeDialog, open: false });
  }

  const getIp = async () => {
    // Connecter ipapi.co avec fetch()
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    // Définir l'adresse IP avec la constante `ip`.
    setIp(data.ip);

    
  };

  useEffect(() => {
    const userId = localStorage.getItem("USER");

    getIp();

    setOpenLoadingSimpleBackdrop(true);

    const filteredRows = [];

    DocumentService.getDocument().then((result) => {
      if (result && result.data) {
        //setRows(result.data.map((row) => ({ ...row, id: row.id })));
        // console.log("******", result.documentIdLIst);

        //  console.log("document", result.data);

        setDocument(result.data);

        const documentIdLIst = result.documentIdLIst;

        documentIdLIst.map((id) =>
          DocumentService.getAction(userId, id).then((response) => {
            if (response.status) {
              const action = response.data.action;

              //Vérifier si l'utilisateur connecté a le droit de configurer le privilège d'un document
              if (action[8] === "1") {
                setDocumentIdToSetPrivilege((oldArray) => [...oldArray, id]);
              }

              //filtrer les documents que l'utilisateur connecté peut voir
              const matchingRows = result.data.filter(
                (row) => row.id === id && action[2] === "1"
              );

              filteredRows.push(...matchingRows);

              setRows(filteredRows.map((row) => ({ ...row, id: row.id })));
            }
          })
        );

        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });

  
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function handleOpenAddLocationDialog(e, documentId) {
    setAddLocationDialog({
      ...addLocationDialog,
      open: true,
    });

    setAddLocationForId(documentId);
  }

  function handleCloseAddLocationDialog(e) {
    setAddLocationDialog({
      ...addLocationDialog,
      open: false,
    });
  }

  return (
    <React.Fragment>
      <AddLocationDialog
        openAddLocationDialog={addLocationDialog.open}
        closeAddLocationDialog={(e) => {
          handleCloseAddLocationDialog(e);
        }}
        documentId={addLocationForId}
      />

      <Result
        message={etats.message}
        color={etats.color}
        open={etats.open}
        handleClose={handleCloseMessage}
      />

      <Message
        isActive={download.openDownloadDialog}
        message={download.message}
        accept={download.accept}
        handleClose={handleMessage}
        handleConfirm={handleAcceptDownload}
      />

      <Message
        isActive={etat.openDeleteDialog}
        message={etat.message}
        accept={etat.accept}
        handleClose={handleMessage}
        handleConfirm={handleAccept}
      />

      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />

      <Title>Listes des documents</Title>

      <Spacer y={3} />

      <div style={{ height: 500, width: "100%" }} className={classes.root}>
        <DataGrid rows={rows} columns={columns} rowsPerPageOptions={[10]} />
      </div>

      <PrivilegeDialog
        isActive={privilegeDialog.open}
        handleClose={handleClosePrivilegeDialog}
        selectedId={selectedId}
        typePrivilege={"document"}
      />
    </React.Fragment>
  );
}
