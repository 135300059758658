import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const TDRService = {
  async saveTypeActivity(composant) {
    try {
      const result = await axios.post(`${apiUrl}/TDR/type`, {
        name: composant,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async saveMissionRequest(form,id) {
    try {
      //console.log("ato lay izy a",type,raison,desc,nbrjr,valComposant)
      const result = id ?
        await axios.put(`${apiUrl}/TDR/missionrequest/`, form)
        :
        await axios.post(`${apiUrl}/TDR/missionrequest`,form);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async saveMissionActivity(valComposant, raison, id) {
    try {
      //console.log("ato lay donnnes",valComposant,raison,id);
      const result = id ?
        await axios.put(`${apiUrl}/TDR/missioactivity/${id}`, {
          id: id,
          name: valComposant,
          raison: raison,
        })
        :
        await axios.post(`${apiUrl}/TDR/missionactivity`, {
          name: valComposant,
          raison: raison,
        });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async addComposant() {
    try {
      const result = await axios.get(`${apiUrl}/TDR/composant`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async All() {
    try {
      const result = await axios.get(`${apiUrl}/TDR/all`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async Alls(id) {
    try {
      const result = await axios.get(`${apiUrl}/TDR/alls/${id}`);
      if (result) {
        console.log("ato ny valiny", result.data);
        return result.data;

      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async pdf(id) {
    try {
      const result = await axios.get(`${apiUrl}/FileTDR/generate/${id}`);
      if (result) {
        return result.data;

      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async generateTDR(id) {
    try {
      const result = await axios.get(`${apiUrl}/FileTDR/generateTDR/${id}`);
      if (result) {
        return result.data;

      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async saveFile(filename, id) {
    try {
      const result = await axios.post(`${apiUrl}/FileTDR/addfile`, {
        id: id,
        filename: filename,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async renderpdf(id) {
    try {
      const result = await axios.get(`${apiUrl}/FileTDR/readFile/${id}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        }
      });
      if (result) {
        return result;

      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async Add(id) {
    try {
      const result = await axios.get(`${apiUrl}/TDR/add/${id}`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async delete(id) {
    try {
      const result = await axios.delete(`${apiUrl}/TDR/${id}`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async getSuperior(id) {
    try {
      const result = await axios.get(`${apiUrl}/TDR/${id}`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },
  async updateTDR(id) {
    try {
      const result = await axios.get(`${apiUrl}/TDR/update/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },
  async getTypeActivity() {
    try {
      const result = await axios.get(`${apiUrl}/TDR/getTypeActivity`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
}
export default TDRService;