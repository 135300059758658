import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataRH } from "../../data/modules/hr-header";
import FamilyMemberService from "../../services/FamilyMemberService";
import FamilyMemberTable from "./FamilyMemberTable";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function FamilyMemberList() {
  const classes = useStyles();

  return (
    <MainContainer {...headerDataRH}>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
          <FamilyMemberTable />
        </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
