import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  Button
} from "@material-ui/core";
import {
  ArrowBack
} from "@material-ui/icons";
import MainContainer from "../../components/MainContainer";
import { headerDataSettings } from "../../data/modules/settings-header";
import DayService from "../../services/DayService";
import { DataGrid } from '@material-ui/data-grid';
import { useLocation } from 'react-router-dom';
import CloseIcon from "@material-ui/icons/Close";
import Result from "../personnel/Result";
import Message from "../personnel/Message";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useNavigate } from 'react-router-dom';
import { fr } from "date-fns/locale";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 800,
    elevation: 0,
    maxHeigth: "auto",
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: 'left',
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  mois: {
    textAlign: 'center',
    fontWeight: "bold",
    fontSize: "25px",
  },
}));




function AddDay(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [date, setDate] = React.useState(null);
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState();
  const [etat, setEtat] = useState({ open: false, message: "message", accept: false });
  const [etats, setEtats] = useState({ open: false, message: "message", color: "" });
  const [isid, setIsid] = useState(0);
  const [month, setMonth] = useState("");
  const [moiss, setMoiss] = useState("");
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const id = splitLocation[3];
  const param = splitLocation[4];
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  function handleMessage() {
    setEtat({
      open: false,
      message: ""
    });
  }

  function handleAccept() {
    const id = splitLocation[3];
    setEtat({
      open: false,
      message: "",
      accept: true
    });
    DayService.deleteDay(isid, param).then((results) => {
      DayService.getMonth(id, param).then((result) => {
        if (result && result.data) {
          setRows(result.data.map((row) => ({ ...row, id: row.id })));
        }
      });
    });
  }

  const handleCloseMessage = () => {
    setEtats({ ...etats, open: false });
  };
  function handleChangeDayWeek() {
    setOpenLoadingSimpleBackdrop(true);
    console.log("njibijh", id, param)
    DayService.DayWeek(id, param).then((result) => {
      DayService.getMonth(id, param).then((result) => {
        if (result && result.data) {
          setRows(result.data.map((row) => ({ ...row, id: row.id })));
          setOpenLoadingSimpleBackdrop(false);
        }
      });
    });
  }
  function handleChange(ev) {
    setOpenLoadingSimpleBackdrop(true);
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value })
    if (id < 10) {
      var mois = "0" + id;
      var month = value;
      var tabMonth = month.split("-");
      var Day = tabMonth[1];
      if (Day === mois) {
        var compt = 0;
        for (let index = 0; index < rows.length; index++) {
          if (month == rows[index]['date_days']) {
            compt++;
          }
        }
        if (compt > 0) {
          setEtats({ ...etats, open: true, color: 'red', message: "Le jour existe dèja " });
          setOpenLoadingSimpleBackdrop(false);
        } else {
          DayService.saveDay(value, id, param)
            .then((results) => {
              if (results.status) {
                DayService.getMonth(id, param).then((result) => {
                  if (result && result.data) {
                    setRows(result.data.map((row) => ({ ...row, id: row.id })));
                    setOpenLoadingSimpleBackdrop(false);
                  }
                });
              }
            })
            .catch((err) => {
              alert("error");
              setOpenLoadingSimpleBackdrop(false);
            });
        }

      } else {
        setEtats({ ...etats, open: true, color: 'red', message: "Le mois à inserer est " + moiss });
        setOpenLoadingSimpleBackdrop(false);
      }
    } else {
      var month = value;
      var tabMonth = month.split("-");
      var Day = tabMonth[1];
      if (Day === id) {
        var compt = 0;
        for (let index = 0; index < rows.length; index++) {
          if (month == rows[index]['date_days']) {
            compt++;
          }
        }
        if (compt > 0) {
          setEtats({ ...etats, open: true, color: 'red', message: "Le jour existe dèja " });
          setOpenLoadingSimpleBackdrop(false);
        } else {
          DayService.saveDay(value, id, param)
            .then((results) => {
              if (results.status) {
                DayService.getMonth(id, param).then((result) => {
                  if (result && result.data) {
                    setRows(result.data.map((row) => ({ ...row, id: row.id })));
                    setOpenLoadingSimpleBackdrop(false);
                  }
                });
              }
            })
            .catch((err) => {
              alert("error");
              setOpenLoadingSimpleBackdrop(false);
            });
        }
      } else {
        setEtats({ ...etats, open: true, color: 'red', message: "Le mois à inserer est   " + moiss });
        setOpenLoadingSimpleBackdrop(false);
      }
    }
  }
  const handleQuit = () => (ev) => {
    navigate("/annual_calendar/");
  };
  const handleDelete = (id) => (ev) => {
    setEtat({
      open: true,
      message: "Voulez vous vraiment supprimer  ?"
    });
    setIsid(id);
    
    const id_month = splitLocation[3];
    var date = new Date();
    if (id < 10) {
      var currentMonth = date.getFullYear() + "-0" + id_month + "-05";
    } else {
      var currentMonth = date.getFullYear() + "-" + id_month + "-05";
    }
    setDate(currentMonth);
  };

  function handleChangeDate(e) {
    if (e !== null) {
      setDate(e);
      setOpenLoadingSimpleBackdrop(true);
      const { name, value } = e;
      setForm({ ...form, [name]: value })
      if (id < 10) {
        var mois = "0" + id;
        // var month = e;
        var d = new Date(e),
          month = '' + (d.getMonth() + 1);
        // console.log(month);
        //var tabMonth = month.split("-");
        // var Day = tabMonth[1];
        var Day = month;
        if (Day === id) {
          var compt = 0;
          for (let index = 0; index < rows.length; index++) {
            if (month == rows[index]['date_days']) {
              compt++;
            }
          }
          if (compt > 0) {
            setEtats({ ...etats, open: true, color: 'red', message: "Le jour existe dèja " });
            setOpenLoadingSimpleBackdrop(false);
          } else {
            DayService.saveDay(e, id, param)
              .then((results) => {
                if (results.status) {
                  DayService.getMonth(id, param).then((result) => {
                    if (result && result.data) {
                      setRows(result.data.map((row) => ({ ...row, id: row.id })));
                      setOpenLoadingSimpleBackdrop(false);
                    }
                  });
                } else {
                  setEtats({ ...etats, open: true, color: 'red', message: results.message });
                  setOpenLoadingSimpleBackdrop(false);
                }
              })
              .catch((err) => {
                alert("error");
                setOpenLoadingSimpleBackdrop(false);
              });
          }

        } else {
          setEtats({ ...etats, open: true, color: 'red', message: "Le mois à inserer est " + moiss });
          setOpenLoadingSimpleBackdrop(false);
        }
      } else {
        // var month = e;

        var d = new Date(e),
          month = '' + (d.getMonth() + 1);
        // var tabMonth = month.split("-");
        // var Day = tabMonth[1];
        var Day = month;
        if (Day === id) {
          var compt = 0;
          for (let index = 0; index < rows.length; index++) {
            if (month == rows[index]['date_days']) {
              compt++;
            }
          }
          if (compt > 0) {
            setEtats({ ...etats, open: true, color: 'red', message: "Le jour existe dèja " });
            setOpenLoadingSimpleBackdrop(false);
          } else {
            DayService.saveDay(e, id, param)
              .then((results) => {
                if (results.status) {
                  DayService.getMonth(id, param).then((result) => {
                    if (result && result.data) {
                      setRows(result.data.map((row) => ({ ...row, id: row.id })));
                      setOpenLoadingSimpleBackdrop(false);
                    }
                  });
                } else {
                  setEtats({ ...etats, open: true, color: 'red', message: results.message });
                  setOpenLoadingSimpleBackdrop(false);
                }
              })
              .catch((err) => {
                alert("error");
                setOpenLoadingSimpleBackdrop(false);
              });
          }
        } else {
          setEtats({ ...etats, open: true, color: 'red', message: "Le mois à inserer est   " + moiss });
          setOpenLoadingSimpleBackdrop(false);
        }
      }

    }
  }

  useEffect(() => {
    const id = splitLocation[3];
    const param = splitLocation[4];
    DayService.getMonth(id, param).then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.id })));
      }
    });
    var months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aôut', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    var is = id - 1;
    setMoiss(months[is]);
    var date = new Date();
    if (id < 10) {
      var currentMonth = date.getFullYear() + "-0" + id + "-05";
    } else {
      var currentMonth = date.getFullYear() + "-" + id + "-05";
    }
    setDate(currentMonth);
  }, [setRows]);
  const columns = [
    {
      field: 'days',
      headerName: 'Jours',
      width: 150,
    },
    {
      field: 'date_days',
      headerName: 'Date',
      width: 150,
    },
    {
      field: '',
      headerName: 'Action',
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Button onClick={handleDelete(params.id)} title="Supprimer ">
              <CloseIcon style={{ color: "red" }} />
            </Button>
          </div>
        );
      },
      width: 200,
    },
  ];
  return (
    <MainContainer {...headerDataSettings}>
      <Message isActive={etat.open} message={etat.message} accept={etat.accept} handleClose={handleMessage} handleConfirm={handleAccept} />
      <Result message={etats.message} color={etats.color} open={etats.open} handleClose={handleCloseMessage} />
      < LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Button onClick={handleQuit()}><ArrowBack />Retour</Button>
          </Grid>
          <Grid item xs={6}>
            <p className={classes.mois}>{moiss} </p>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider locale={fr}  dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={date}
                onChange={handleChangeDate}
                renderInput={(params) =>
                  <TextField {...params} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" />
                }
              />
            </LocalizationProvider>
            {/* <TextField
              fullWidth
              type='number'
              id="date-picker-dialog"
              size="small"
              type="date"
              variant="outlined"
              inputProps={{ style: { textTransform: "uppercase" } }}
              onChange={handleChange}
              value={month}
              name="month"
            /> */}
          </Grid>
          <Grid item xs={8}>
            <div style={{ height: 400, width: '95%' }} className={classes.root} >
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" color="secondary" onClick={handleChangeDayWeek} style={{ width: '100%' }}>
              Ajouter tous les weekends
          </Button>
          </Grid>

        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default AddDay;
