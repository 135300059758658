import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Button, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataSettings } from "../../data/modules/settings-header";
import FundingService from "../../services/FundingService";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Message from "../tools/Message";
import { useContext } from "react";
import { hasPrivilege } from "../../utils/privilege";
import { userDetailContext } from "../../App";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    width: 370,
  },
  champ: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

function FundingAdd(props) {
  const classes = useStyles();
  const [form, setForm] = useState({ code: "", description: "" });
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  const [isValidCode, setIsValidCode] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  useEffect(() => {
    const funding_id = id;
    if (funding_id) {
      FundingService.getFundingById(funding_id).then(({ data: service }) => {
        setForm({
          code: service.code,
          description: service.description,
          funding_id: funding_id,
        });
      });
    }
  }, []);

  function handleClick() {
    if (form.code === '') {
      setIsValidCode(true);
    }
    if (form.code !== '') {
      FundingService.saveFunding(form.code, form.description, form.funding_id)
        .then((result) => {
          if (result.status) {
            setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
            navigate("/funding");
          } else {
            setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
          }
        })
        .catch((err) => {
          setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        });
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    if (name == 'code') {
      setForm({ ...form, [name]: value.toUpperCase() });
    } else {
      setForm({ ...form, [name]: value });
    }
  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  const contextData = useContext(userDetailContext);
  function handleCancel() {
    navigate("/funding");
  }
  return (
    <MainContainer {...headerDataSettings}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        {hasPrivilege(contextData.privileges, 'Admin-Button-Save-Service') &&
          <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >ENREGISTRER</Button>
        }
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Code{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="Code" variant="outlined" value={form.code} name="code" onChange={handleChange} inputProps={{ maxLength: 10 }} />
            {isValidCode && <Alert severity="error">Veuillez remplir ce champ</Alert>}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Description</label>
          </Grid>
          <Grid item xs={8}>
            <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic" size="small" label="Description" variant="outlined" value={form.description} name="description" onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <label className={classes.champ}>({bullRed}):Champs obligatoires</label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default FundingAdd;
