
import { makeStyles } from "@material-ui/core/styles";
import { Grid,Paper ,Button } from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header";
import DemandeTable from "./demandeTable";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function DemandeList() {
  const classes = useStyles();
  const navigate = useNavigate();
  function handleClick() {
    navigate("/mission/demande/add");
  }

  return (
    <MainContainer {...headerDataMission}>
      <Grid container item xs={12}>
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >CREER</Button>
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <DemandeTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
