import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import FileValidationRequestService from "../../../services/FileValidationRequestService";
import { Camera, CameraAlt, CameraEnhance, CameraFront, CameraRear, CameraRoll, CameraSharp, FindInPageTwoTone, ViewAgendaTwoTone, ViewListOutlined, ViewModuleRounded } from "@material-ui/icons";
import { ViewCompactRounded } from "@material-ui/icons";
import { DataGrid } from "@material-ui/data-grid";
import { escapeRegExp } from "../../../utils";
import RequestSummary from "../../tools/RequestSummary";
import { useNavigate } from "react-router-dom";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { hasPrivilege } from "../../../utils/privilege";
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import { useLocation } from 'react-router-dom';
import moment from "moment";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => {
    const backgroundColor =
      theme.palette.mode === 'dark' ? '#376331' : '#FFFF62';
    return {
      root: {
        '& .MuiDataGrid-cell--editable': {
          backgroundColor,
        },
      },
    };
  },
  { defaultTheme },
);
function MyValidation(props) {
  // const rowsForRH = props.rowsForRH;
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [openDialogRequestSummary, setOpenDialogRequestSummary] = React.useState(false);
  const [requestSummaryId, setRequestSummaryId] = useState({});
  const [rows, setRows] = useState([]);
  const [isRhConnected, setIsRhConnected] = useState(false);

  const classes = useStyles();
  const contextData = useContext(userDetailContext);
  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  useEffect(() => {
    console.log("***123" + requestSummaryId.module_type_id)
    setOpenLoadingSimpleBackdrop(true);
    if (isRh()) {
      loadRhRow();
    } else {
      FileValidationRequestService.getMyFileValidations().then((result) => {
        if (result && result.data) {
          setRows(
            result.data.map((row) => ({
              ...row,
              id: row.file_validation_request_id,
            }))
          );

          setOpenLoadingSimpleBackdrop(false);
        } else {
          setOpenLoadingSimpleBackdrop(false);
        }
      });
    }
  }, []);

  function isRh() {
    if (hasPrivilege(contextData.privileges, 'RH_list_of_request')) {
      setIsRhConnected(true);
      return true;
    }
  }

  function loadRhRow() {
    FileValidationRequestService.getAllPendingDemands().then((result) => {
      if (result && result.status) {
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.file_validation_request_id,
          }))
        );
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }

  const validateFile = (row) => () => {
    setRequestSummaryId(row);
    setOpenDialogRequestSummary(true);
    setOpenDialog(true);
  };

  
  const voirDetail = (row) => () => {
    setRequestSummaryId(row);
    setOpenDialogRequestSummary(true);
    setOpenDialog(true);
  };

  function getFullName(params) {
    return `${params.getValue(params.id, "submitter_person_first_name") || ""
      } ${params.getValue(params.id, "submitter_person_last_name") || ""}`;
  }

  // function getFullName(params) {
  //   return `${params.getValue(params.id, "submitter_person_first_name") || ""
  //     } ${params.getValue(params.id, "submitter_person_last_name") || ""}`;
  // }
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = [...rows].filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
  };
  const columns = [
    {
      field: "submission_time",
      headerName: (
        <p style={{ whiteSpace: "pre-line", lineHeight: "1.4", padding: '0', margin: '0' }}>
          Date de création <br /> de la demande
        </p>
      ),
      width: 200,
      editable: true,
    },
    {
      field: "fullName",
      headerName: "Nom et prénom(s)",
      width: 350,
      valueGetter: getFullName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        getFullName(cellParams1).localeCompare(getFullName(cellParams2)),
      editable: true,
    },
    {
      field: "libelle_module_type",
      headerName: "Type",
      width: 140,
      editable: true,
    },
    {
      headerName: "Date de départ",
      field: "begin_time",
      width: 180,
      editable: true,
      valueFormatter: (params) => {
        return `${moment(params.row.begin_time).format('DD/MM/YYYY')} ${params.row.begin_time_session === 'morning' ? 'Matin' : 'Après-midi'}`;
      },
    },
    {
      headerName: "Date de retour",
      field: "end_time",
      width: 180,
      editable: true,
      valueFormatter: (params) => {
        return `${moment(params.row.end_time).format('DD/MM/YYYY')} ${params.row.end_time_session === 'morning' ? 'Matin' : 'Après-midi'}`;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 128,
      renderCell: (params) => (
        <span>
          {params.row.isRh === 1 &&
            <Button
              onClick={voirDetail(params.row)}
              title="Voir Détail"
            >
              <CameraSharp style={{ color: "black" }} />
            </Button>
            }
            {params.row.isRh === 0 &&
            <Button
              onClick={validateFile(params.row)}
              title="Passer à la validation"
            >
              <FindInPageTwoTone style={{ color: "black" }} />
            </Button>
            }
        </span>
      ),
      editable: true,
    },
  ];
  const handleCloseRequestSummary = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setOpenDialogRequestSummary(false);
    setOpenDialog(false);

  }
  return (
    <React.Fragment>
      { openDialog &&
        <RequestSummary
          requestSummaryId={requestSummaryId} openDialogRequestSummary={openDialogRequestSummary} 
          handleCloseRequestSummary={handleCloseRequestSummary} isRhConnected={isRhConnected}/>}

      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          className={classes.root}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
          isCellEditable={(params) => params.row.is_priority == "1"}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },

          }}
        />
      </div>
    </React.Fragment>
  );
}
export default MyValidation;