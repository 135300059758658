import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import UserService from '../../../services/UserService';
import TextField from "@material-ui/core/TextField";
import Phone from "../../tools/Phone";
import { CreateTwoTone } from "@material-ui/icons";
import BanqueDeCvService from "../../../services/BanqueCvService";
import Alert from "@material-ui/lab/Alert";
import Message from "../../tools/Message";
import { useNavigate } from "react-router-dom";
import {
    Grid,
    Paper,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Link,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AlertTitle } from '@material-ui/lab';
import { CircularProgress } from "@material-ui/core";
import Spacer from '../../../components/Spacer';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100%',
        left: '0px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    welcom: {
        fontSize: '4.5rem',
        justifyContent: 'center',

    },
    avatar: {
        justifyContent: "center"
    },
    userAccount: {
        margin: "20px",
    },
    userAccountTitle: {
        borderBottom: "1px solid",
    },
    userAccountTitleBtn: {
        padding: "20px",
    },
    userPasswordTitle: {
        borderBottom: "1px solid",
    },
    userPasswordBtn: {
        padding: "20px",
    },
    userAccountChangePassword: {
        margin: "20px",
    },
    userAccordionFooter: {
        marginLeft: "20px",
    }
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
export const INTERNAL_FILE_STORAGE_PATH = process.env.REACT_APP_API_URL.replace("api", "uploads/cv/");
export default function DetailAccordions(props) {

    const navigate = useNavigate();
    // const [expanded, setExpanded] = React.useState('panel1');

    // const handleChange = (panel) => (event, newExpanded) => {
    //     setExpanded(newExpanded ? panel : false);
    // };

    const classes = useStyles();
    const userInfo = props.userInfo;

    const message = props.message;

    const sexe = props.sexe;
    const isDisable = props.isDisable;
    // const [isModifiedPassword, setIsModifiedPassword] = useState(false);
    const form = props.form;
    const isEmptyNewPass = props.isEmptyNewPass;
    const isEmptyNewPassMessage = props.isEmptyNewPassMessage;
    const isEmptyConfirmPass = props.isEmptyConfirmPass;
    const isEmptyConfirmPassMessage = props.isEmptyConfirmPassMessage;
    const IsCompatibleNewAndConfirmPassword = props.IsCompatibleNewAndConfirmPassword;
    const IsCompatibleNewAndConfirmPasswordMessage = props.IsCompatibleNewAndConfirmPasswordMessage;

    const handleChangePassword = props.handleChangePassword;
    const handleClickBtnSave = props.handleClickBtnSave;
    const handleClickBtnSavePassword = props.handleClickBtnSavePassword;
    const handleFocusConfirmPassword = props.handleFocusConfirmPassword;
    const handleFocusNewPassword = props.handleFocusNewPassword;
    const handleClickBtnSaveDelegateAuthority = props.handleClickBtnSaveDelegateAuthority;
    const handleClickBtnModifDelegateAuthority = props.handleClickBtnModifDelegateAuthority;
    const handleClickBtnCancelDelegateAuthority = props.handleClickBtnCancelDelegateAuthority;
    const handleChange = props.handleChangePanel;

    const dir = props.dir;
    const service = props.service;
    const users = props.users;
    const handleChangeDirection = props.handleChangeDirection;
    const handleSelect = props.handleSelect;
    const handleChangeService = props.handleChangeService;
    const handleChangeUsers = props.handleChangeUsers;

    const idDir = props.idDir;
    const idservice = props.idservice;
    const iduser = props.iduser;
    const idDelegate = props.idDelegate;
    const showAddDelegateForm = props.showAddDelegateForm;
    const showDelegateDetail = props.showDelegateDetail;
    const expanded = props.expanded;
    const loading = props.loading;

    
    const idDelegateDetail = props.idDelegateDetail;
   

    useEffect(() => {
        // if (idDelegate !== 0) {
        //     setshowDelegateDetail(true);
        // } else {
        //     setshowDelegateDetail(false);
        // }
    }, [props]);


    const handleShowSelectedCV = (iduser) => {
        // const storedUser = localStorage.getItem('USER');
        
        try {
          BanqueDeCvService.showCvByUserId(iduser).then((result) => {
            if (result.data) {
              window.open(INTERNAL_FILE_STORAGE_PATH + result.data, '_blank');
            } else {
              alert( "Pas de CV dans le systeme");
            }
    
          })
        } catch (err) {
          console.log(err);
        } finally {

        }
      }

    // const handleChangePassword = (e) => {
    //     setForm({ ...form, [e.target.name]: e.target.value });
    // };

    // function handleClickBtnSave() {

    // }


    // function handleClickBtnSavePassword() {
    //     console.log("new pass*******", form.newpassword);
    //     if (!form.newpassword) {
    //         setIsEmptyNewPass(true);
    //         setIsEmptyNewPassMessage("Ce champ est obligatoire");
    //     } else if (!form.confirmpassword) {
    //         setIsEmptyConfirmPass(true);
    //         setIsEmptyConfirmPassMessage("Ce champ est obligatoire");
    //     } else if (form.newpassword !== form.confirmpassword) {
    //         setIsCompatibleNewAndConfirmPassword(false);
    //         setIsCompatibleNewAndConfirmPasswordMessage("Les mots de passe ne correspondent pas");
    //     } else {
    //         UserService.my_account_update_password(userInfo.userEmail, form.confirmpassword).then((result) => {
    //             console.log("result e****", result);
    //             if (result && result.status != 411) {
    //                 if (result.status) {
    //                     setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
    //                     setIsModifiedPassword(false);

    //                     setTimeout(function () { navigate("/"); }, 7000);

    //                 } else {
    //                     setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
    //                     setForm({ ...form, newpassword: "", confirmpassword: "" });
    //                 }
    //             } else {
    //                 setIsCompatibleNewAndConfirmPassword(false);
    //                 setIsCompatibleNewAndConfirmPasswordMessage(result.data.message);
    //             }
    //         });
    //     }
    // }

    // function handleFocusNewPassword() {
    //     setIsEmptyNewPass(false);
    //     setIsCompatibleNewAndConfirmPassword(true);
    // }

    // function handleFocusConfirmPassword() {
    //     setIsEmptyConfirmPass(false);
    //     setIsCompatibleNewAndConfirmPassword(true);
    // }
    const [detailsCv,setDetailscv]=useState({});

    useEffect(() => {
        UserService.getCV().then((results) => {
            if (results && results.status) {
                setDetailscv(results.data);
            }
        })
        
    }, [props]);

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Information personnel</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Grid container spacing={2} xs={6} className={classes.userAccount}>

                            <Grid item xs={4}>
                                <label>Nom:</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label="Nom"
                                    variant="outlined"
                                    value={userInfo.userLastName.toUpperCase()}
                                    name="last_name"
                                    disabled={isDisable}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <label>Prénom:</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label="Prénom"
                                    variant="outlined"
                                    value={userInfo.userFirstName}
                                    name="first_name"
                                    disabled={isDisable}
                                />

                            </Grid>
                            <Grid item xs={4}>
                                <label>Téléphone:</label>
                            </Grid>
                            <Grid item xs={8}>
                                <Phone x1={3} x2={8} name="telephone" value={userInfo.userphone} disabled={isDisable} />
                            </Grid>

                            <Grid item xs={4}>
                                <label class="aligneleft">Sexe:</label>
                            </Grid>

                            <Grid item xs={8}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="sex"
                                        name="sex"
                                        row
                                        value={userInfo.userSex}
                                    >
                                        {sexe.map((row) => (
                                            <FormControlLabel

                                                disabled={isDisable}
                                                value={row.sex_id}
                                                control={<Radio />}
                                                label={row.name}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <label class="aligneleft">Email:</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label="email"
                                    variant="outlined"
                                    value={userInfo.userEmail}
                                    name="email"
                                    disabled={isDisable}
                                />
                            </Grid>
                            {!isDisable &&
                                <>
                                    <Grid item xs={9}></Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleClickBtnSave}
                                            style={{ fontWeight: 600 }}
                                        >
                                            ENREGISTRER

                                        </Button>
                                    </Grid>
                                </>
                            }
                        </Grid>

                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Mot de passe</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* {isModifiedPassword && */}
                    <form className={classes.form}>
                        <Grid container spacing={2} xs={6} className={classes.userAccountChangePassword}>
                            <Grid item xs={4}>
                                <label>Nouveau mot de passe*:</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label="Nouveau mot de passe"
                                    variant="outlined"
                                    name="newpassword"
                                    type="password"
                                    onChange={handleChangePassword}
                                    onFocus={handleFocusNewPassword}
                                />
                                {isEmptyNewPass && (
                                    <Alert severity="error">{isEmptyNewPassMessage}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={8}><p><h4>Niveau de sécurité du mot de passe :</h4>
Utilisez au moins 8 caractères contenant des lettres en capitales et en minuscules, des nombres et des caractères spéciaux.</p></Grid>
                            <Grid item xs={4}>
                                <label>Confirmation mot de passe*:</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label="Confirmation mot de passe"
                                    variant="outlined"
                                    type="password"
                                    name="confirmpassword"
                                    onChange={handleChangePassword}
                                    onFocus={handleFocusConfirmPassword}
                                />
                                {isEmptyConfirmPass && (
                                    <Alert severity="error">{isEmptyConfirmPassMessage}</Alert>
                                )}
                                {!IsCompatibleNewAndConfirmPassword && (
                                    <Alert severity="error">{IsCompatibleNewAndConfirmPasswordMessage}</Alert>
                                )}

                            </Grid>
                            <Grid item xs={9}></Grid>
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleClickBtnSavePassword}
                                    style={{ fontWeight: 600 }}
                                >
                                    ENREGISTRER

                                        </Button>
                            </Grid>
                        </Grid>
                    </form>
                    {/* } */}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Délégation de pouvoir</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {showDelegateDetail &&
                        <>
                            <Alert
                                action={
                                    <>
                                    <Button color="inherit" size="small"
                                        onClick={handleClickBtnModifDelegateAuthority}>
                                        Modifier
                                    </Button>
                                    <Spacer x={1} />
                                    <Button color="inherit" size="small" style={{color: "red"}}
                                        onClick={handleClickBtnCancelDelegateAuthority}>
                                        Annuler
                                    </Button>
                                    </>
                                }
                            >
                                <AlertTitle>Configuration</AlertTitle>
                                Votre compte est maintenant délégé à <strong>{idDelegate.user_delegate_name}</strong>
                            </Alert>
                        </>
                    }
                    {showAddDelegateForm &&
                        <Grid container spacing={2} xs={6} className={classes.userAccountChangePassword}>
                            <Grid item xs={4}>
                                <label>Direction:</label>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={idDir} name="direction_id" onChange={handleChangeDirection} onFocus={handleSelect}>

                                        {dir.map((row) => (
                                            <MenuItem value={row.direction_id}>{row.name}  ( {row.code} )</MenuItem>

                                        ))}
                                    </Select>

                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={4}>
                            <label>Service:</label>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl fullWidth variant="outlined" size="small">
                                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={idservice} name="service_id" onChange={handleChangeService} >

                                    {service.map((row) => (
                                        <MenuItem value={row.service_id}> {row.code} </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid> */}
                            <Grid item xs={4}>
                                <label>Utilisateur:</label>
                            </Grid>
                            <Grid item xs={8}>
                                {/* <FormControl fullWidth variant="outlined" size="small">
                                <Select abelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={iduser} name="user_id" onChange={handleChangeUsers} >
                                    {users.map((row) => (
                                        <MenuItem value={row.user_id}>{row.first_name} {row.last_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                                <Autocomplete
                                    onChange={handleChangeUsers}
                                    options={users}
                                    defaultValue={iduser}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            InputProps={{ ...params.InputProps, type: "search" }}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={9}></Grid>
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleClickBtnSaveDelegateAuthority}
                                    style={{ fontWeight: 600 }}
                                >
                                    {loading ? <CircularProgress /> : "ENREGISTRER"}

                            </Button>
                            </Grid>
                        </Grid>
                    }
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>Mise à jour du CV</Typography>
                </AccordionSummary>
                <AccordionDetails> 
                
                    <div>
                        <Grid item xs={12} className={classes.userAccordionFooter}>                    
                                <Alert icon={<WarningAmberIcon fontSize="inherit" />} severity="info">
                                        Veuillez vérifier que les informations concernant la Direction, le Service et la Fonction sont correctes.
                                </Alert>
                        </Grid>
                     <Grid container spacing={2} xs={8} className={classes.userAccount}>
                     
                        {detailsCv!==null?
                            <>                         
                              
                               <Grid item xs={3}>
                                  <label>Direction :</label>
                               </Grid>
                                <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    value={detailsCv.direction_name}
                                    name="direction_name"
                                    disabled={isDisable}
                                />
                                </Grid>
                               <Grid item xs={3} >
                                  <label>Service :</label>
                                </Grid>
                                <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    value={detailsCv.service_name}
                                    name="service_name"
                                    disabled={isDisable}
                                />
                                 
                                </Grid>
                             
                               <Grid item xs={3} >
                                  <label>Fonction :</label>
                                </Grid>
                                <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    value={detailsCv.function_name}
                                    name="function_name"
                                    disabled={isDisable}
                                />
                                </Grid>
                        
                               <Grid item xs={3}>
                                  <label>Nom :</label>
                                </Grid>
                                <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    value={detailsCv.nom}
                                    name="user_name"
                                    disabled={isDisable}
                                />
                                  
                                </Grid>
                              
                               <Grid item xs={3} >
                                  <label>CV:</label>
                                </Grid>
                                <Grid item xs={8}>
                                    
                                  {/* <Typography><Link underline="hover" href={`${process.env.REACT_APP_CV_URL}${detailsCv.file_cv}`} target="_blank" rel="noopener noreferrer">PJ</Link></Typography> */}
                                  
                                  <Typography>
                                        <Link
                                            component="button"
                                            onClick={() => handleShowSelectedCV(detailsCv.iduser)}
                                            underline="hover"
                                            sx={{ cursor: 'pointer' }} 
                                        >
                                            PJ
                                        </Link>
                                 </Typography>
                                </Grid>
                            </>
                        :
                            <>
                             <Grid item xs={12}>
                              <Typography> Votre CV est encore incomplet</Typography>
                            </Grid>
                            </>
                        }
                       

                    </Grid>
                    </div>
                   <>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={8} className={classes.userAccordionFooter}>                  
                           <Typography> Veuillez suivre ce <Link underline="hover" href={`${process.env.REACT_APP_URL_MAJ_CV_USER}${localStorage.getItem('USER')}`} target="_blank" rel="noopener noreferrer">lien</Link> pour mettre à jour votre CV.</Typography>
                    </Grid>
                   
                    </> 
                </AccordionDetails>
            </Accordion>
        </div >
    );
}
