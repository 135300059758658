import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import { useLocation } from "react-router-dom";
import ImmobilisationService from "../../services/ImmobilisationService";
import Message from "../tools/Message";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import EquipmentCategoryService from "../../services/EquipmentCategoryService";
import EquipmentRankingService from "../../services/EquipmentRankingService";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EquipmentConfigService from "../../services/EquipmentConfigService";

const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
    }}
  >
    *
  </Box>
);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 1000,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: "left",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      float: "right",
    },
  },
  text: {
    width: 370,
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

function ImmobilisationAdd(props) {
  const classes = useStyles();
  const [directions, setDirection] = useState([]);
  const [funding, setFunding] = useState([]);
  const [listOfMaterial, setMaterialList] = useState([]);
  const [listOfCategory, setCategoryList] = useState([]);
  const [category, setCategory] = useState([]);
  const [isMaterialEmpty, setMaterialEmpty] = useState(false);
  const [isModification, setIsModification] = useState(false);
  const [messageForEmptyMaterial, setMessageForEmptyMaterial] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    React.useState(false);
  const [tests, setTests] = React.useState([]);
  const [equipment_ranking, setEquipmentRanking] = useState([]);

  const navigate = useNavigate();
  const [form, setForm] = useState({
    equipment_ranking_id: "",
    name: "",
    code: "",
    acquis: "",
    unite: "",
    date: "",
    funding: "",
    dir: "",
    ordre: "",
  });

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [ordre, setOrdre] = useState(5);
  const [maxId, setMaxId] = useState();

  const [number, setNumber] = useState([
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    " ",
  ]);
  const [message, setMessage] = useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: "Passer à la configuration du matériel  ?",
  });
  const { openDialog, messageDialog } = confirmationDialog;

  const { open, color, messageInfo } = message;
  const [compt, setCompt] = useState(false);
  const [acqs, setAcqs] = useState(false);
  const [isExtra, setIsExtra] = useState(false);
  
  const [listDevise, setListDevise] = useState([]);

  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    navigate("/Logistic/immo/add/configuration");
    window.location.reload();
  }

  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  function handleClick() {
    const idCodeImmo = splitLocation[4];
    const idmax = parseInt(localStorage.getItem("idmax")) + 1;
    console.log("form : " ,form.funding);
    

    if (idCodeImmo) {
      ImmobilisationService.update(
        form.equipment_ranking_id,
        form.name,
        form.code,
        form.acquis,
        form.unite,
        form.date,
        form.funding,
        form.dir,
        form.ordre,
        idCodeImmo
      )
        .then((results) => {
          if (results.status) {
            setMessage({
              ...message,
              open: true,
              color: "green",
              messageInfo: "Succès",
            });
            navigate("/Logistic/immo/add/configuration");
            window.location.reload();
          } else {
            setMessage({
              ...message,
              open: true,
              color: "red",
              messageInfo: results.message,
            });
          }
        })
        .catch((err) => {
          alert("error");
        });
    } 
    else {
      ImmobilisationService.save(
        form.equipment_ranking_id,
        form.name,
        form.code,
        form.acquis,
        form.unite,
        form.date,
        form.funding,
        form.dir,
        form.ordre,
        quantity,
        isExtra
      )
        .then((results) => {
          console.log(results);
          const idGroup = splitLocation[4];

          if (results.status) {
            setMessage({
              ...message,
              open: true,
              color: "green",
              messageInfo: "Succès, l'ajout du code immo : "+form.code+" a été réussi",
            });
           navigate("/Logistic/immo/add/configuration");
           window.location.reload();
          } else {
            setMessage({
              ...message,
              open: true,
              color: "red",
              messageInfo: results.message,
            });
          }
        })
        .catch((err) => {
          alert("error");
        });
    }
  }
  const cat = localStorage.getItem("category");

  useEffect(() => {
    EquipmentCategoryService.getAllEquipmentCategory().then((res) => {
      if (res && res.status) {
        setCategoryList(res.data);
        console.log(directions);
      } else {
        setMessageForEmptyMaterial(res.message);
      }
    });

    const idGroup = splitLocation[4];
    ImmobilisationService.direction().then((result) => {
      if (result && result.data) {
        setDirection(result.data);
      }
    });

    const cd = localStorage.getItem("acquis");
    ImmobilisationService.ordre(cd).then((result) => {
      if (result && result.data) {
        setOrdre(result.data);
      }
    });

    ImmobilisationService.idmax().then((result) => {
      console.log("idmax= ", result.data.idmax);
      localStorage.setItem("idmax", parseInt(result.data.idmax) + 1);
    });

    ImmobilisationService.funding().then((result) => {
      if (result && result.data) {
        setFunding(result.data);
      }
    });

    EquipmentRankingService.getAllEquipmentRanking().then((result) => {
      if (result && result.data) {
        setEquipmentRanking(
          result.data.map((d) => {
            return {
              id: d.equipment_ranking_id,
              code: d.equipment_ranking_code,
              name: d.equipment_ranking_name,
            };
          })
        );
      }

      console.log(
        "set ranking : ", result.data,        
        "equipment_ranking  : ", equipment_ranking,
        "form ranking : " , form.equipment_ranking_id
      );
      
    });

    if (idGroup) {
      ImmobilisationService.getCodeImmoById(idGroup).then((info) => {
        const av = info.data.acquis_value.split(" ");

        setForm({
          name: info.data.name,
          code: info.data.code_name,
          acquis: av[0],
          unite: av[1],
          date: info.data.date_acquis,
          funding: info.data.funding,
          dir: info.data.direction,
          ordre: info.data.number_ordre,
          equipment_ranking_id:info.data.equipment_ranking_id
        });

        console.log(
          "Info :  ", info,
          "Edit form : ", form);
      });
      setIsModification(true);
    }
  }, []);

  useEffect(()=>{
    ImmobilisationService.getListDevise().then((info) => {
      if(info && info.status){
        setListDevise(info.data);
      }
    });
  }, [props]);

  function getacquis(value) {
    var tab;
    var sep = " ";
    if (value % 3 === 0) {
      tab = value.substring(0, 3);
      for (let index = 3; index < value.length; index++) {
        tab = tab + sep + value.substring(index, index + 3);
        index = index + 3;
      }
    }
    return value;
  }

  function loadTable() {
    setOpenLoadingSimpleBackdrop(true);
    const cat = localStorage.getItem("category");
    EquipmentRankingService.getByCategory(cat).then((res) => {
      if (res && res.status) {
        setMaterialList(res.data);
        setOpenLoadingSimpleBackdrop(false);

        localStorage.removeItem("category");
        cat = 0;
      } else {
        setOpenLoadingSimpleBackdrop(true);

        setMessageForEmptyMaterial(res.message);
      }
    });
  }

  function handleSelect() {
    EquipmentRankingService.getAllEquipmentRanking().then((result) => {
      console.log("ssss", result);
      if (result && result.data) {
        setEquipmentRanking(
          result.data.map((d) => {
            return {
              id: d.equipment_ranking_id,
              code: d.equipment_ranking_code,
              name: d.equipment_ranking_name,
            };
          })
        );
      }
    });
  }

  function handleChange(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
    
    if (name === "code") {
      setForm({ ...form, [name]: value.toUpperCase() });
    }
    else if (name === "unite") {
      setForm({ ...form, [name]: value });
    }
    else if (name === "ordre") {
      var i = 0;
      var a = 0;
      for (let index = 0; index < value.length; index++) {
        for (let indexs = 0; indexs < number.length; indexs++) {
          if (number[indexs] === value[index]) {
            i++;
          }
        }
      }
      if (i !== value.length) {
        setCompt(true);
      } else {
        setCompt(false);
        setForm({ ...form, [name]: value });
      }
    } 
    else if (name === "acquis") {
      var i = 0;
      var a = 0;
      for (let index = 0; index < value.length; index++) {
        for (let indexs = 0; indexs < number.length; indexs++) {
          if (number[indexs] === value[index]) {
            i++;
          }
        }
      }
      if (typeof i != "number") {
        console.log(value.length);

        setAcqs(true);
      } else {
        setAcqs(false);
        var val = getacquis(value);
        setForm({ ...form, [name]: val });
      }
    } 
    else if (name === "equipment_ranking_id") {
      EquipmentRankingService.getEquipmentRankingById(value).then((res) => {
        console.log(res.data.equipment_ranking_id);
        console.log(res.data.equipment_ranking_name);

        localStorage.setItem("ranking", res.data.equipment_ranking_id);
        if (res && res.data) {
          setForm({
            equipment_ranking_id: res.data.equipment_ranking_id,
            code: res.data.equipment_ranking_code,
            name: res.data.equipment_ranking_name,
          });
        }
      });
      localStorage.removeItem("category");
    } 
    else if (name === "quantity") {
      setQuantity(value);
      console.log(value);
      localStorage.setItem("quantite", value);
    } 
    else if (name === "category") {
      localStorage.setItem("category", value);
      const cat = localStorage.getItem("category");

      EquipmentRankingService.getByCategory(cat).then((res) => {
        if (res && res.status) {
          setMaterialList(res.data);
        } else {
          setMessageForEmptyMaterial(res.message);
        }
      });
    }
  }


  function handleBlur(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
    if (name == "funding") {
      const n_ordre = localStorage.getItem("acquis");
      ImmobilisationService.ordre(n_ordre).then((result) => {
        console.log(result.data);
        const x = result.data.ordre;
        console.log(x);
        setForm({ ...form, ordre: parseInt(x) + 1 });
      });
    }
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  function handleExtraChange() {
    setIsExtra(!isExtra);
  }

  function handleCancel() {
    navigate("/Logistic/immobilisations");
  }

  return (
    <>
      <ConfirmationDialog
        openDialog={openDialog}
        messageDialog={messageDialog}
        closeDialog={closeDialog}
        confirmDialog={confirmDialog}
        cancelDialog={cancelDialog}
      />

      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />

      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
        {!splitLocation[4] && (
          <>
           
          </>
        )}
         <Grid item xs={4}>
              <label className={classes.labelPosition}>
                Catégorie matériel {bullRed}
              </label>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined" size="small">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="category"
                  onChange={handleChange}
                  //value={form.name}
                >
                  {listOfCategory.map((row) => (
                    <MenuItem value={row.equipment_category_id}>
                      {row.name_category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

          {/* <Grid item xs={4}>
            <label className={classes.labelPosition}>Classement *</label>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              id="tags-outlined"
              options={listOfMaterial}
              getOptionLabel={(option) => option.equipment_ranking_name}
              filterSelectedOptions
              onChange={(event, value) => setTests(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id="outlined-basic"
                  size="small"
                  label="Composants"
                  variant="outlined"
                  placeholder="Composant"
                  name="name"
                  onChange={handleChange}
                  //value={form.composant}
                />
              )}
            />
          </Grid> */}
          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Classement matériel {bullRed}
            </label>
          </Grid>

          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={form.equipment_ranking_id}
                onFocus={handleSelect}
                name="equipment_ranking_id"
                onChange={handleChange}
              >
                  {equipment_ranking.map((row) => (
                      <MenuItem value={row.id}>{row.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>

            {isMaterialEmpty && (
              <>
                <Spacer y={3} />
                <Alert severity="error">
                  Veuillez configurer les matériels existants dans Gestion
                  matériel
                </Alert>
              </>
            )}
            {/* <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Désignation"
              variant="outlined"
              onChange={handleChange}
              inputProps={{ textTransform: 'capitalize', }}
              value={form.name}
              name="name"
            /> */}
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>Code{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Code"
              variant="outlined"
              onChange={handleChange}
              inputProps={{
                style: { textTransform: "uppercase" },
                maxLength: 4,
              }}
              value={form.code}
              name="code"
              type="numeric"
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Valeur d’acquisition{bullRed}
            </label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              variant="outlined"
              onChange={handleChange}
              value={form.acquis}
              name="acquis"
            />
            {acqs && (
              <Alert severity="error">La valeur doit être un numerique</Alert>
            )}
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-label">Unité</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.unite}
                label="unite"
                name="unite"
                onChange={handleChange}
              >
                {listDevise.map((row) => (
                  <MenuItem value={row.code_immo_currency_id}>
                    {row.code_immo_currency_devise}({row.code_immo_currency_symbol})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Date d'acquisition{bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              variant="outlined"
              onChange={handleChange}
              value={form.date}
              name="date"
              type="date"
            />
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Financement{bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-label">Financement</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.funding}
                name="funding"
                onBlur={handleBlur}
                onChange={handleChange}
                {...localStorage.setItem("acquis", form.funding)}
              >
                
                {funding.map((row) => (
                  <MenuItem value={row.funding_id}>{row.code}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Direction{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-label">Direction</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.dir}
                label="dir"
                name="dir"
                onChange={handleChange}
              >
                {directions.map((row) => (
                  <MenuItem value={row.direction_id}>
                    {row.name}({row.code})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Numero d'ordre{bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Numéro d'ordre"
              variant="outlined"
              onChange={handleChange}
              value={form.ordre}
              name="ordre"
              type="numeric"
              inputProps={{ maxLength: 4 }}
            />
            {compt && (
              <Alert severity="error">
                Le numero d'ordre doit être un chiffre
              </Alert>
            )}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Extrat</label>
          </Grid>
          <Grid item xs={8}>
            <input
              type="checkbox"
              checked={isExtra}
              onChange={handleExtraChange}
              disabled={isModification}
            />
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Quantité{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Quantité"
              variant="outlined"
              onChange={handleChange}
              value={quantity}
              name="quantity"
              type="numeric"
              disabled={isModification}
            />
          </Grid>
          <Grid item xs={12}>
            <label className={classes.text}>
              ({bullRed}):Champs obligatoires
            </label>
          </Grid>
          <Grid item xs={9}>
            <Spacer y={6} />
            <div className={classes.root}>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Suivant
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
              >
                Annuler
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default ImmobilisationAdd;
