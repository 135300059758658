import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const MailConfigAddService = {
    async getDirection(){
        try {
            const result = await axios.get(`${apiUrl}/direction/index2`  );
            if (result) {
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }  
    },


   //service by direction
   async getServiceByDirection(id){
    try {
        const result = await axios.get(`${apiUrl}/service/getServiceByIdDirection/${id}`  );
        if (result) {
          // TODO: replace result.data.message by the token

          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    } ,

    async getServiceByMultipleDirection(selectedValueDirectionIdList){
      try {
        const queryString = selectedValueDirectionIdList.join(',');
        const urlWithParams = `${apiUrl}/service/getServiceByIdMultipleDirection/?selectedValueDirectionIdList=${queryString}`;
        const result = await axios.get(urlWithParams);
        console.log('RESULT : ', result);
        if (result) {

          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    } ,

    



    //fonction by service
    async getFunctionByService(id){
        try {
            const result = await axios.get(`${apiUrl}/fonction/getByIdService/${id}`  );
            if (result) {
              // TODO: replace result.data.message by the token
    
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        } ,

        async getFunctionByMultipleService(selectedValueServiceIdList){
          try {
            const queryString = selectedValueServiceIdList.join(',');
            const urlWithParams = `${apiUrl}/fonction/getFunctionByIdMultipleService/?selectedValueServiceIdList=${queryString}`;
            const result = await axios.get(urlWithParams);
            console.log('RESULT : ', result);
            if (result) {
    
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        } ,

}

export default MailConfigAddService;