import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import {
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import Alert from "@material-ui/lab/Alert";
import { withRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Message from "../tools/Message";
import EquipmentConfigService from "../../services/EquipmentConfigService";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition1: {
    float: "left",
  },
  labelPosition: {
    float: "right",
  },
  text: {
    width: 370,
  },
}));


function ImmobilisationConfiguration(props) {

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const idCodeImmo = splitLocation[4];

  var andrana = parseInt(2);
  const quantite = 20;

  const navigate = useNavigate();
  let l = 0;
  let v =0;
  const codeImmo = localStorage.getItem("codeImmo");
  const nom = localStorage.getItem("nom");
  const classes = useStyles();
  const [form, setForm] = useState({
    immo_config: [],
  });
  const[config,setConfig]=useState({
    valeur:"",
    name:'',
  })
  const [message, setMessage] = useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const [equipmentConfig, setEquipmentConfig] = useState([]);
  const [equipmentValue,setEquipmentValue] = useState([]);
  const [ImmoConfigTemp, setImmoConfigTemp] = useState([]);
  const val = Array();
  const { open, color, messageInfo } = message;
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  var code = parseInt(localStorage.getItem("idmax"));


  useEffect(() => {
    const id = localStorage.getItem("er");
    const ranking = localStorage.getItem("ranking");
    
    console.log(
      "id=", id,
      "qte= ", quantite,
      "ranking= ", ranking,
    );


    if (ranking) {
      EquipmentConfigService.getAllEquipmentConfigId(ranking).then((result) => {
        console.log("ll", result.data.length);

        localStorage.setItem("ll", result.data.length);
        const rec = localStorage.getItem("ll");
        console.log(rec);
        if (result && result.data) {
          l = rec;
          setEquipmentConfig(result.data);
        } else {
          l = 0;
        }
      });
    } else if (id) {
      EquipmentConfigService.getAllEquipmentConfigId(id).then((result) => {
        console.log("ll", result.data.length);

        localStorage.setItem("ll", result.data.length);
        const rec = localStorage.getItem("ll");
        console.log("EquipmentConfigService ---- getAllEquipmentConfigId :",result.data);
        if (result && result.data) {
          l = rec;
          setEquipmentConfig(result.data);
        } else {
          l = 0;
        }
      });
    }

    console.log("testtest=", l);
    console.log("x=", equipmentConfig);

    const code = localStorage.getItem("key1");

    if (code) {
      EquipmentConfigService.getConfigById(code).then((result)=>{
        if (result && result.data) {
          setEquipmentValue(result.data);
        }
      });

      
    }
    
  }, []);

  function getValueByName(name,table){
    for (let i = 0; i < table.length; i++) {
      if (table[i].configuration == name) {
        return table[i].valeur;
      }
      
    }
    return "";
  }
 
  function handleChange(event) {
    const { name, value } = event.target;
    console.log("cc", code);
    const code1 = localStorage.getItem("key1");
    if (code1) {
      ImmoConfigTemp[name] = {
        code_immo_id: code1,
        equipment_config_id: name,
        value: value,
      };
    } else {
      ImmoConfigTemp[name] = {
        code_immo_id: code,
        equipment_config_id: name,
        value: value,
      };
    }
    //console.log("test=", equipmentConfig.type_config);
  }
  function handleClick() {
    console.log(quantite);
    const id = localStorage.getItem("key1");

    for (var i = 0; i < ImmoConfigTemp.length; i++) {
      if (ImmoConfigTemp[i] != null) {
        form.immo_config.push(ImmoConfigTemp[i]);
      }
    }

    console.log("FORM DATA OTHER === ", form);
    
    EquipmentConfigService.deleteConfig(id);
    EquipmentConfigService.saveConfig(form).then((res) => {
        var responseErrorMessage = res.data.message;
        if (res.data.status) {
          setMessage({
            ...message,
            open: true,
            color: "green",
            messageInfo: responseErrorMessage,
          });
          
          localStorage.removeItem("idmax");
          localStorage.removeItem("ranking");
          localStorage.removeItem("key1");
          setTimeout(function () { 
            navigate("/logistic/immobilisations");
          }, 2000);
        } else {
          setMessage({
            ...message,
            open: true,
            color: "red",
            messageInfo: responseErrorMessage,
          });
        }
      })
      .catch((err) => {
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: "Une erreur s'est produite. Veuillez réessayer",
        });
      });
  }

  function handleCancel() {
    navigate("/Logistic/immobilisations");
  }

  const equipmentConfigItems = [];
  const y = parseInt(localStorage.getItem("verify"));
  console.log("y= ", y);
  
  console.log("equipmentConfig----length :", equipmentConfig.length);


  for (var i = 0; i < equipmentConfig.length; i++) {
    console.log("hellll= ", localStorage.getItem("verify"), equipmentConfig[i]);

    if (equipmentConfig[i].type_config === "TEXTE") {
      val[equipmentConfig[i].equipment_config_id] = getValueByName(equipmentConfig[i].configuration,equipmentValue);
      v=val;
      equipmentConfigItems.push(
        <>
          <Grid item xs={4}>
            <label className={classes.labelPosition1}>
              {equipmentConfig[i].configuration}
              {console.log(equipmentConfig[i].type_config)}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              //placeholder={equipmentConfig[i].type_config}
              fullWidth
              id="outlined-basic"
             // value={val[equipmentConfig[i].equipment_config_id]}
              size="small"
              label={equipmentConfig[i].configuration}
              variant="outlined"
              name={equipmentConfig[i].equipment_config_id}
              onChange={(event) =>handleChange(event,v)}
            />
          </Grid>
        </>
      );
    } else if (equipmentConfig[i].type_config === "NUMERIQUE") {
      val[equipmentConfig[i].equipment_config_id] = getValueByName(equipmentConfig[i].configuration,equipmentValue);
      console.log("Teste narisolo"+val[equipmentConfig[i].equipment_config_id]);
      v=val;
      equipmentConfigItems.push(
        <>
          <Grid item xs={4}>
            <label className={classes.labelPosition1}>
              {equipmentConfig[i].configuration}
              {console.log(equipmentConfig[i].type_config)}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              //placeholder={equipmentConfig[i].type_config}
              type="number"
              fullWidth
              id="outlined-basic"
             // value={val[equipmentConfig[i].equipment_config_id]}
              size="small"
              variant="outlined"
              helperText="Veuillez mettre les informations en type numériques"
              name={equipmentConfig[i].equipment_config_id}
              onChange={(event) =>handleChange(event,v)}
            />
          </Grid>
        </>
      );
    } else if (equipmentConfig[i].type_config === "DATE") {
      val[equipmentConfig[i].equipment_config_id] = getValueByName(equipmentConfig[i].configuration,equipmentValue);
      v=val;
      equipmentConfigItems.push(
        <>
          <Grid item xs={4}>
            <label className={classes.labelPosition1}>
              {equipmentConfig[i].configuration}
              {console.log(equipmentConfig[i].type_config)}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              //placeholder={equipmentConfig[i].type_config}
              type="date"
              fullWidth
              id="outlined-basic"
              //value={val[equipmentConfig[i].equipment_config_id]}
              size="small"
              variant="outlined"
              name={equipmentConfig[i].equipment_config_id}
              //onChange={handleChange(val[equipmentConfig[i].equipment_config_id])}
            />
          </Grid>
        </>
      );
    } else if (equipmentConfig[i].type_config === "BOOLEEN") {
       val[equipmentConfig[i].equipment_config_id] = getValueByName(equipmentConfig[i].configuration,equipmentValue);
      v=i;
      equipmentConfigItems.push(
        <>
          <Grid item xs={4}>
            <label className={classes.labelPosition1}>
              {equipmentConfig[i].configuration}
              {console.log(equipmentConfig[i].type_config)}
            </label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                //value={val[equipmentConfig[i].equipment_config_id]}
                label="Type"
                name={equipmentConfig[i].equipment_config_id}
                //value={form.type_config}
                //onChange={handleChange}
              >
                <MenuItem value="OUI">OUI</MenuItem>
                <MenuItem value="NON">NON</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </>
      );
    }
  }

  return (
    <>
      <Grid container spacing={3} className={classes.paper} variant="outlined">
        <Message
          open={open}
          color={color}
          messageInfo={messageInfo}
          handleClose={handleCloseMessage}
        />
        {/*  */}
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
          <label
            className={classes.labelPosition1}
            style={{ textDecorationLine: "underline" }}
          >
            Configuration matériel
          </label>
        </Grid>

        {equipmentConfigItems}
        {/*  */}
        <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
              >
                Annuler
              </Button>
              
              <Button
                className={classes.labelPosition}
                variant="contained"
                color="primary"
                onClick={handleClick}
              >
                Enregistrer
              </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default ImmobilisationConfiguration;
