import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spacer from "../../components/Spacer";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CodePinDialog from "./CodePinDialog";
import AcceptRequestService from "../../services/AcceptRequestService";
import Alert from "@material-ui/lab/Alert";
import Loading from "../../components/loading";
import Message from "../tools/Message";
import { useNavigate } from 'react-router-dom';
import AcceptedHelpService from "../../services/AcceptedHelpService";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  step1Title: {
    textAlign: "center",
  },
  step1Grid: {
    padding: "10px",
  },
  gridItem: {
    height: "25px",
  },
}));

export default function ValidateRequest(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [form, setForm] = useState({
    mdp: "",
  });
  const [openCodePin, setOpenCodePin] = useState(false);
  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);
  const [errorMdpMessage, setErrorMdpMessage] = useState('');
  const [inputValue, setInputValue] = useState("");
  const [failedCodePin, setFailedCodePin] = useState(false);
  const [errorMessageFailedCodePin, setErrorMessageFailedCodePin] = useState(false);
  const open = props.openValidateRequest;
  const handleCloseValidateRequest = props.handleCloseValidateRequest;
  const validation_id = props.validation_id;
  const module_type_id = props.module_type_id;
  const file_validation_request_id = props.file_validation_request_id;

  const id = props.id;
  const mission_request_id = props.mission_request_id;
  const mission_request_reference = props.mission_request_reference;
  const mission_request_user_required_mission_request_id = props.mission_request_user_required_mission_request_id;
  const mission_request_user_required_user_id = props.mission_request_user_required_user_id;
  const name = props.name;
  const number_day = props.number_day;
  const reason = props.reason;
  const service_id = props.service_id;
  const type_mission_request = props.type_mission_request;
  const user_id = props.user_id;
  const date = props.date;
  const description = props.description;
  const file_request_id = props.file_request_id;

  // Samuela: set loading while fetching api data
  const [isLoading, setLoading] = useState(false);

  const [message, setMessage] = React.useState({
    openMessage: false,
    color: "green",
    messageInfo: "",
  });
  const { openMessage, color, messageInfo } = message;

  useEffect(() => {
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }

  function handleClickPassword() {

    if (form.mdp.trim() == "") {
      setError(true);
      setErrorMdpMessage("Mot de passe requis!!");

    } else {

      setLoading(true);
      AcceptedHelpService.getSupValidatorNumber().then((res) => {
        // console.log(res.data);

        AcceptRequestService.verifyMdp(form.mdp).then((result) => {
          if (result.status) {
            // if (res.data == 0) {
              setStep(2);
              setOpenCodePin(true);
              setLoading(false);
            // } else {
            //   console.log("**121212121**" + mission_request_id);

            //   AcceptedHelpService.saveMissionRequestUserRequiredSup(mission_request_id)
            //     .then((res) => {
            //       var responseErrorMessage = res.message;
            //       if (res.status) {
            //         setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
            //         navigate("/acceptedHelp");
            //         setLoading(false);

            //         // setOpenLoadingSimpleBackdrop(true);
            //       } else {
            //         setMessage({ ...message, open: true, color: 'red', messageInfo: responseErrorMessage });
            //       }
            //     })
            //     .catch((err) => {
            //       setLoading(false);

            //       // setOpenLoadingSimpleBackdrop(true);
            //       setMessage({ ...message, open: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
            //     });
            // }

          } else {
            setError(true);
            setErrorMdpMessage(result.message);
            setLoading(false);
          }
        }).catch((err) => {
          setError(true);
          setErrorMdpMessage('Une erreur s\'est produite');
          setLoading(false);
          navigate("/acceptedHelp");

        });
      });
    }
  }

  const handleCloseCodePin = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setStep(1);
    setOpenCodePin(false);
  }

  function handleValidateCodePin() {
    console.log("sjdksjdksdjksdjksjdksjdkjskdjk " + module_type_id);
    if (inputValue.trim() == "") {
      setFailedCodePin(true);
      setErrorMessageFailedCodePin("Veuillez saisir votre code PIN");
    } else {

      AcceptedHelpService.insert(inputValue, id, mission_request_id,
        mission_request_reference, mission_request_user_required_mission_request_id,
        mission_request_user_required_user_id, name, number_day, reason, service_id, type_mission_request,
        user_id, date, description, description, file_request_id).then((result) => {
          if (result.status) {
            setMessage({
              ...message,
              openMessage: true,
              color: "green",
              messageInfo: result.message,
            });
            setTimeout(function () { navigate("/me/validations"); }, 2000);
            window.location.reload();
          } else {
            setFailedCodePin(true);
            setErrorMessageFailedCodePin(result.message);
          }
        }).catch((err) => {
          setFailedCodePin(true);
          setErrorMessageFailedCodePin('Une erreur s\'est produite');
        });

    }
  }

  function handleFocusPassword() {
    setError(false);
  }


  function handleCloseMessage() {
    setMessage({ ...message, openMessage: false });
  }

  return (
    <>

      <Message
        open={openMessage}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <CodePinDialog openCodePin={openCodePin} handleCloseCodePin={handleCloseCodePin}
        validation_id={validation_id}
        module_type_id={module_type_id}
        file_validation_request_id={file_validation_request_id}
        onChangeInputValue={setInputValue}
        handleValidateCodePin={handleValidateCodePin}
        failedCodePin={failedCodePin}
        errorMessageFailedCodePin={errorMessageFailedCodePin}
      />
      {step == 1 &&
        <Dialog open={open} onClose={handleCloseValidateRequest} aria-labelledby="form-dialog-title1" fullWidth maxWidth="sm">
          <DialogTitle id="form-dialog-title1">
            Validation - Etape 1
          </DialogTitle>
          <br />
          <div className={classes.root}>
            <div>
              <h3 className={classes.step1Title}>Votre mot de passe </h3>
            </div>
            <Grid className={classes.step1Grid} container>
              <Grid className={classes.gridItem} item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  size="small"
                  label="Votre mot de passe"
                  variant="outlined"
                  value={form.mdp}
                  name="mdp"
                  type="password"
                  onChange={handleChange}
                  onFocus={handleFocusPassword}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={12}>

                <Spacer y={3} />
                {/* Samuela: set loading while fetching api data */}
                {isLoading && <Loading />}
                {error && (
                  <Alert severity="error">{errorMdpMessage}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Spacer y={2} />
                <DialogActions>
                  <Button style={{ backgroundColor: "##f50057", color: "#FFFFFF" }} onClick={handleCloseValidateRequest} color="secondary" variant="contained" >
                    Annuler
                  </Button>
                  <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF", }} onClick={handleClickPassword} color="secondary" variant="contained" >
                    Valider
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      }
    </>
  );
}
