import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useState } from 'react';

export default function HelpDialog(props){
  const openHelpDialog = props.openHelpDialog;
  const closeHelpDialog = props.closeHelpDialog;

  return (
    <div>
      <Dialog open={openHelpDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="form-dialog-title" color="primary" /*style={{backgroundColor: '#0000B3'}}*/ >{"Aide"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Si votre rubrique n'est pas dans la liste, veuillez activer la case à cocher << Créer >> dans paramétrage privilège."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={closeHelpDialog} >Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}