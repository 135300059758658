import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Button, FormControl, Select, MenuItem, InputLabel, Radio, 
  RadioGroup,
  FormControlLabel
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataAdmin } from "../../data/modules/admin-header";
import { useParams } from "react-router-dom";
import PersonalSettingService from "../../services/PersonalSettingService";
import Alert from "@material-ui/lab/Alert";
import Message from "../tools/Message";
import { hasPrivilege } from "../../utils/privilege";
import { userDetailContext } from "../../App";
import {useContext} from "react";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    width: 370,
  }
}));

function PersonalSettingUpdate(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [form, setForm] = useState({name_field: "", type_field: "", required: "1", personal_config_id: 0});
  const [isValidName_field, setIsValidName_field] = useState(false);
  const [isValidType_field, setIsValidType_field] = useState(false);
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const params = useParams();
  const personal_config_id = params.id;

  useEffect(() => {
    if(personal_config_id) {
      PersonalSettingService.getPersonalSettingsById(personal_config_id).then(({ data:personalSetting }) => {
        setForm({
          name_field: personalSetting.name_field,
          type_field: personalSetting.type_field,
          required: personalSetting.required,
          personal_config_id: personalSetting.personal_config_id,
        });
      });
    }
  }, []);

  function handleClick(){
    if(form.name_field===''){
      setIsValidName_field(true);
    }
    if(form.type_field===''){
      setIsValidType_field(true);
    }
    if(form.name_field!=='' && form.type_field!==''){
      PersonalSettingService.updatePersonalSettings(form).then((result) => {
        if(result.status){
          setMessage({...message, open: true, color: 'green', messageInfo: result.message});
          navigate("/champ_dynamique");
        }else{
          setMessage({...message, open: true, color: 'red', messageInfo: result.message});
        }
      })
      .catch((err) => {
        setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      });
    }
    
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }
  function handleCloseMessage(){
    setMessage({...message, open: false});
  }
  const contextData = useContext(userDetailContext);
  return (
    <MainContainer {...headerDataAdmin}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
      {hasPrivilege(contextData.privileges, 'Admin-Button-Save-ChampD') &&
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >ENREGISTRER</Button>
  }
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Nom du champ*</label>
          </Grid>
          <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="Nom du champ" variant="outlined" value={form.name_field} name="name_field" onChange={handleChange} />
            {isValidName_field && <Alert severity="error">Veuillez remplir ce champ</Alert>}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Type champ*</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">Type Champ</InputLabel>
              <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Type Champ" value={form.type_field} name="type_field" onChange={handleChange}>
              <MenuItem value={form.type_field}>{form.type_field}</MenuItem>
              { form.type_field!='Texte' && (<MenuItem value="Texte">Texte</MenuItem>) }
              </Select>
            </FormControl>
            {isValidType_field && <Alert severity="error">Veuillez remplir ce champ</Alert>}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Obligation*</label>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup aria-label="required" name="required" value={form.required} onChange={handleChange}>
              <FormControlLabel value="1" control={<Radio />} label="Oui" />
              <FormControlLabel value="0" control={<Radio />} label="Non" />
            </RadioGroup>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default PersonalSettingUpdate;