// import logo from './logo.svg';
import { createContext, useState, useRef } from 'react';
import './App.css';
import "./portal.css";
import { Navigate, useNavigate } from 'react-router-dom';
import IndexesRoutes from './routes'
import PrivilegeService from './services/PrivilegeService';
import AuthService from './services/AuthService';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'
import BalanceConfigurationService from './services/BalanceConfigurationService';
import UserService from './services/UserService';

export const userDetailContext = createContext(null);

function App(props) {

  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({ id: '', privileges: [] });
  const willMount = useRef(true);

  const [shouldLogOut, setShouldLogOut] = useState(false);
  const location = useLocation();


  useEffect(() => {
    if (!location.pathname.includes("new-password")) {
      if (AuthService.isAuthenticated()) {
        loadPrivilege();
        updateLeaveBalance();
      }
    }

    /** display list by direction: set user direction into localStorage */
    if(localStorage.getItem("USER") && localStorage.getItem("USER") !== "" && 
    localStorage.getItem("USER") !== null && localStorage.getItem("USER") !== undefined){
      user_info(localStorage.getItem("USER"));
    }
  }, [location]);

  // if (!location.pathname.includes("new-password")) {
  //   console.log("atooooo****", location);
  //   if (willMount.current) {
  //     if (AuthService.isAuthenticated()) {
  //       loadPrivilege();
  //     }
  //   }
  // }
  // willMount.current = false;
  
  useEffect(() => {
    if (shouldLogOut) {
      AuthService.logOut()
      setShouldLogOut(false);
      return <Navigate to={'/'} replace/>
    }
  }, [shouldLogOut]);

  function loadPrivilege(){
    PrivilegeService.getUserPrivilege().then((result) => {
      if (result && result.data) {
        const privileges = result.data.map(d => {
          return { id: d.privilege_id, label: d.name }
        });
        setUserInfo({ ...userInfo, privileges: privileges })
      }
      else if (result && result.logout) {
        navigate("/");
        setShouldLogOut(true);
      }
    });
  }

  function updateLeaveBalance(){
    BalanceConfigurationService.checkIfDateNowIsEqualToCountDayInLeaveType().then((result) => {
      if (result && result.status) {
        const leaveType = result.data;
        BalanceConfigurationService.updateBalancePerMonth(leaveType);
      }
    });
  }
  
  function user_info(id){
    UserService.getUserSummaryByUserId(id).then((result) => {
      if (result && result.status) {
        localStorage.setItem("USER_DIR",result.data.direction_id);
        localStorage.setItem("USER_DIR_CODE",`${result.data.direction_name} - ${result.data.direction_code}`);
      }
    });
  }
  return (
    <div className="App">
      <userDetailContext.Provider value={userInfo} >
        <IndexesRoutes />
      </userDetailContext.Provider>
    </div>
  );
}


export default App;
