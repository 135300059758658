import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import Button from "@material-ui/core/Button";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import EquipmentService from "../../services/EquipmentService";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import Message from "../tools/Message";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { escapeRegExp } from "../../utils";
import { useNavigate } from 'react-router-dom';
export default function EquipmentTable() {

  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  const [id_to_delete, setId_to_delete] = useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: "Voulez vous vraiment supprimer ?",
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  function loadTable() {
    setOpenLoadingSimpleBackdrop(true);
    EquipmentService.getAllEquipment().then((result) => {
      if (result && result.status) {
        console.log("result******", result);
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.equipment_id,
          }))
        );
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }
  useEffect(() => {
    loadTable();
  }, [])







  const handleEdit = (id) => (ev) => {
    navigate("/Logistic/materiels/add/" + id);
  };
  const handleDelete = (id) => (ev) => {
    setId_to_delete(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true, messageDialog: "Voulez vous vraiment supprimer?" });

  };
  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    EquipmentService.deleteEquipment(id_to_delete)
      .then((result) => {
        if (result.status) {
          loadTable();
          setMessage({
            ...message,
            open: true,
            color: "green",
            messageInfo: result.message,
          });
        } else {
          setMessage({
            ...message,
            open: true,
            color: "red",
            messageInfo: result.message,
          });
        }
      })
      .catch((err) => {
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: "erreur",
        });
      });
  }
  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = [...rows].filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
  };

  const columns = [

    {
      field: 'equipment_name',
      headerName: 'Libellé',
      width: 300,
    },
    {
      field: 'equipment_category',
      headerName: 'Catégorie',
      width: 300,
    },
    {
      field: 'equipment_code',
      headerName: 'code',
      width: 250,
    },
    {
      field: 'equipment_description',
      headerName: 'Description',
      width: 350,
    },
    {
      field: 'action',
      headerName: 'Action',
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Button onClick={handleEdit(params.id)} title="Modifier"><CreateTwoTone style={{ color: "black" }} />
            </Button>
            <Button onClick={handleDelete(params.id)} title="Supprimer">
              <DeleteOutlineRounded style={{ color: "red" }} />
            </Button>
          </div>
        );
      },
      width: 150,
    },
  ]
  
  return (
    <>
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}

          pageSize={7}

          disableSelectionOnClick
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
        />
      </div>
    </>
  )
}