
import React from 'react';
import { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Message from "../tools/Message";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import ImmobilisationService from "../../services/ImmobilisationService";
import InventoryService from "../../services/InventoryService";
import { useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Spacer from '../../components/Spacer';

import {
    FormControl,
    Grid,
    TextField,
    Select,
    InputLabel,
    MenuItem
} from "@material-ui/core";
import AutocompleteCombobox from '../../components/AutocompleteCombobox';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function InventoryDetentionSheet(props) {
    const navigate = useNavigate();    
    const openDialog = props.open;
    const closeDialog = props.closeDialog;
    const dataImmo = props.dataImmo;
    const rows = props.rows;

    const [message, setMessage] = useState({
        open: false,
        color: 'green',
        messageInfo: '',
    });
    const { open, color, messageInfo } = message;

    const [form, setForm] = useState({
        user_id: "",
        dir :"",
    });

    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }

    function handleChangeDir(directionId) {
        setForm({ ...form, dir: directionId });
}
    function handleChangeUser(personId) {
        console.log("name : " ,personId, "value : ", personId);
        setForm({ ...form, user_id: personId });
    }

    function downloadPDF() {
        var user_id = form.user_id;
        InventoryService.generateSignedPDf(user_id).then((response) => {
            if(response.data != null){
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                const extension = 'pdf';
                const fileName = 'Fiche_de_detention';
                
                const uniqueFileName = `${fileName}_${user_id}.${extension}`;
                a.download = uniqueFileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }
            else{
                alert("Fiche de détention non générée car cet utilisateur n'a aucun matériel à afficher!")
            }
          })
            .catch((error) => {
              console.error('Erreur lors du téléchargement du PDF :', error);
            });
    }
    
    return (
        <div>
            <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="lg">
                <DialogTitle id="form-dialog-title" color="primary">{"Fiche de détention "}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{fontWeight:"bold",color:'black' }} >
                            {"  "}<br />
                    
                        </DialogContentText>
                        
                    <AutocompleteCombobox onPersonIdChange={handleChangeUser} onDirectionIdChange={handleChangeDir}/>
                    <Spacer y={2} />
                </DialogContent>

                <DialogActions>                    
                    <Button variant="contained" color="primary" onClick={downloadPDF}
                    style={{ backgroundColor:"green", color:"#FFFFFF" }}>Télécharger</Button>
                    <Button variant="contained" color="secondary" onClick={closeDialog} >Fermer</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

