import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ParticipatedValidation from './ParticipatedValidation';
import MyValidation from './MyValidation';
import MyPendingDemands from './MyPendingDemands';
import MainContainer from '../../../components/MainContainer';
import { headerDataMission } from '../../../data/modules/mission-header';
import { AntTab, AntTabs } from '../../../components/ant-tab';
import MyRequestState from './MyRequestState';
import AppBar from "@material-ui/core/AppBar";
import Spacer from "../../../components/Spacer";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    elevation: 0
  },
  tab1: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      width: "100%"
    }
  },
}));

export default function AcceptedHelpList() {



  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainContainer {...headerDataMission}>
      <Spacer y={2}/>
      {value == 0 && <Grid item xl={12}>
        <Paper className={classes.paper} variant="outlined">
          <MyValidation />
        </Paper>
      </Grid>}
      {value == 1 && <Grid item xl={12}>
        <Paper className={classes.paper} variant="outlined">
          <MyPendingDemands />
        </Paper>
      </Grid>}
      {value == 2 && <Grid item xl={12}>
        <Paper className={classes.paper} variant="outlined">
          <ParticipatedValidation />
        </Paper>
      </Grid>}
      {value == 3 && <Grid item xl={12}>
        <Paper className={classes.paper} variant="outlined">
          <MyRequestState />
        </Paper>
      </Grid>}

    </MainContainer>
    ///
  );
}
