import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const StockInventoryService = {
  async getHistory(name_materiel,code_immo,state) {
    try {
      const result = await axios.get(`${apiUrl}/StockInventory/`,{params: {name_materiel:name_materiel,code_immo:code_immo,state:state}});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async exportExcel() {
    try {
      const result = await axios.get(`${apiUrl}/StockInventory/export`);
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};
export default StockInventoryService;
