import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../components/Title";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import { DataGrid } from '@material-ui/data-grid';
import Message from "../tools/Message";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import moment from "moment";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import Spacer from "../../components/Spacer";
import { useNavigate } from 'react-router-dom';
import MettingService from "../../services/MettingService";
import ValidateMeetingDialog from "../tools/ValidateMettingDialog";
import { hasPrivilege } from "../../utils/privilege";
import { useContext } from "react";
import { userDetailContext } from "../../App";
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlined from '@mui/icons-material/ModeEditOutlined';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function MettingRequestTable(props) {
  const contextData = useContext(userDetailContext);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const [isid, setIsid] = useState(0);
  const [comment, setComment] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'voulez vous supprimer?',
  });
  const [confirmationMetting, setConfirmationMetting] = React.useState({
    openDialogMetting: false,
    messageDialogMetting: 'Vous approuvez cette réunion?',
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const { openDialogMetting, messageDialogMetting } = confirmationMetting;
  const [message, setMessage] = React.useState({
    openMessage: false,
    color: 'green',
    messageInfo: '',
  });
  const { openMessage, color, messageInfo } = message;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

    useEffect(() => {
        setOpenLoadingSimpleBackdrop(true);
        MettingService.getMettingRequest().then((result) => {
        if (result && result.data) {
            setRows(result.data.map((row) => ({ ...row, id: row.id_meeting })));
            setOpenLoadingSimpleBackdrop(false);
        }
        else{
            setOpenLoadingSimpleBackdrop(false);
        }
        });
    }, []);

    const handleEdit = (id) => {
      console.log(id);
      navigate("/Logistic/meeting_management/add/" + id);
    };
  
    const handleDelete = (id) => {
      setIsid(id);
      setConfirmationDialog({ ...confirmationDialog, openDialog: true, messageDialog: 'voulez vous supprimer?' });
    };
  
    const handleApproved = (id) => {
      setIsid(id);
      setConfirmationMetting({ ...confirmationMetting, openDialogMetting: true, messageDialogMetting: '' });
      setAnchorEl(null);
      setSelectedRow(null);
    };
  
  function loadTable() {
    MettingService.getMettingRequest().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.id_meeting })));
      }
    });
  }

  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false, messageDialog: 'voulez vous supprimer?' });
  }

  function closeDialogMetting() {
    setConfirmationMetting({ ...confirmationMetting, openDialogMetting: false, messageDialogMetting: '' });
  }

  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false, messageDialog: 'voulez vous supprimer?' });
    MettingService.deleteMettingById(isid).then((result) => {
      if (result.status==true) {
        setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.message });
        loadTable();
      } else {
        setMessage({ ...message, openMessage: true, color: 'red', messageInfo: result.message });
      }
      }).catch((err) => {
      setMessage({ ...message, openMessage: true, color: 'red', messageInfo: 'Erreur'.isid });
    });
  }

  function confirmDialogApproved(comment) {
    setConfirmationMetting({ ...confirmationDialog, openDialogMetting: false, messageDialogMetting: 'Vous confirmez cette réunion?' });
    MettingService.ApprovedMettingByIdMetting(comment,isid).then((result) => {
      if (result.status==true) {
        setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.message });
        loadTable();
      } else {
        setMessage({ ...message, openMessage: true, color: 'red', messageInfo: result.message });
      }
      }).catch((err) => {
        console.log(err);
      setMessage({ ...message, openMessage: true, color: 'red', messageInfo: 'Erreur'.isid });
    });
  }

  function handleRefused(comment) {
    setConfirmationMetting({ ...confirmationMetting, openDialogMetting: false, messageDialogMetting: 'Vous confirmez cette réunion?' });
    MettingService.RefusedMettingByIdMetting(comment,isid).then((result) => {
      if (result.status==true) {
        setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.message });
        loadTable();
      } else {
        setMessage({ ...message, openMessage: true, color: 'red', messageInfo: result.message });
      }
      }).catch((err) => {
      setMessage({ ...message, openMessage: true, color: 'red', messageInfo: 'Erreur'.isid });
    });
  }


  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function handleCloseMessage() {
    setMessage({ ...message, openMessage: false });
  }

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const columns = [
    {
      field: 'date_meeting',
      headerName: 'Date',
      width: 130,
      valueFormatter: (params) => {
        return `${moment(params.row.date_meeting).format('DD/MM/YYYY')}`;
      },
    },
    { 
      field: 'time_meeting_start',
      headerName: 'Début',
      width: 120,
      valueFormatter: (params) => {
        return `${moment(params.row.time_meeting_start, 'HH:mm:ss').format('HH:mm')}`;
      },
    },
    { 
      field: 'time_meeting_end',
      headerName: 'Fin',
      width: 110,
      valueFormatter: (params) => {
        return `${moment(params.row.time_meeting_end, 'HH:mm:ss').format('HH:mm')}`;
      },
    },
    { 
      field: 'salle',
      headerName: 'Porte',
      width: 120,
    },
    { 
      field: 'user_meeting',
      headerName: 'Utilisateur',
      width: 150,
    },
    { 
        field: 'object_meeting',
        headerName: 'Objet',
        width: 230,
    },
    // { 
    //   field: 'statut',
    //   headerName: 'Statut',
    //   width: 150,
    //   renderCell: (params) => {
    //     if (params.row.statut == "0") {
    //       return (
    //         <div
    //           style={{
    //             backgroundColor: "red",
    //             width: 150,
    //             textAlign: "center",
    //             color: "rgb(248, 248, 248)",
    //             fontWeight: "bold",
    //             fontFamily: "Arial, Helvetica, sans-serif",
    //           }}
    //         >
    //           Refusé
    //         </div>
    //       );
    //     } else if (params.row.statut == "1") {
    //       return (
    //         <div
    //           style={{
    //             backgroundColor: "rgb(58, 139, 58)",
    //             width: 150,
    //             textAlign: "center",
    //             color: "rgb(248, 248, 248)",
    //             fontWeight: "bold",
    //             fontFamily: "Arial, Helvetica, sans-serif",
    //           }}
    //         >
    //           Approuvé
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <div
    //           style={{
    //             backgroundColor: "rgb(52, 168, 168, 0.747)",
    //             width: 150,
    //             textAlign: "center",
    //             color: "rgb(248, 248, 248)",
    //             fontWeight: "bold",
    //             fontFamily: "Arial, Helvetica, sans-serif",
    //           }}
    //         >
    //           En attente
    //         </div>
    //       );
    //     }
    //   }
    // },
    // { 
    //   field: 'comment',
    //   headerName: 'Commentaire',
    //   width: 200,
    // },
    ((hasPrivilege(contextData.privileges, "Admin-meeting-Modify")) || (hasPrivilege(contextData.privileges, "Admin-meeting-Delete")) 
    || (hasPrivilege(contextData.privileges, "button_approved_meeting"))) &&
      {
        field: 'action',
        headerName: 'Action',
        disableClickEventBulbing: true,
        renderCell: (params) => (
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleMenuOpen(event, params.row)}
            >
              <MoreVertOutlined />
            </IconButton>

            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl) && selectedRow && selectedRow.id_meeting === params.row.id_meeting}
              onClose={handleMenuClose}
            >
              {hasPrivilege(contextData.privileges, "Admin-meeting-Modify") && (
                <MenuItem onClick={() => handleEdit(params.row.id_meeting)} style={{ color: "#357bff" }}>
                  <ModeEditOutlined style={{ color: "#357bff" }}/>    Modifier
                </MenuItem>
              )}

              {hasPrivilege(contextData.privileges, "Admin-meeting-Delete") && (
                <MenuItem onClick={() => handleDelete(params.row.id_meeting)} style={{ color: "#f82657" }}>
                <DeleteOutlined style={{ color: "#f82657" }}/>    Supprimer
                </MenuItem>
              )}

              {hasPrivilege(contextData.privileges, "button_approved_meeting") && (
                <MenuItem onClick={() => handleApproved(params.row.id_meeting)}>
                  <CheckCircleOutlined /> Approbation
                </MenuItem>
              )}

            </Menu>
          </div>
        ),
        width: 120,
      },
  ];
  return (
    <React.Fragment>
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <ValidateMeetingDialog comment={comment} openDialogMetting={openDialogMetting} messageDialogMetting={messageDialogMetting} closeDialogMetting={closeDialogMetting} confirmDialogApproved={confirmDialogApproved} handleRefused={handleRefused}/>
      <Message open={openMessage} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      {/* <Title>Demandes réunions</Title> */}
      {/* <Spacer y={2} /> */}
      <div style={{ height: "85vh", width: '100%' }} className={classes.root} >
      
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
        />
      </div>
    </React.Fragment>
  );
}
