import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import {
    Grid,
    Paper,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerData } from "../../../data/modules/my-account-header";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { hasPrivilege } from "../../../utils/privilege";
import Message from "../../tools/Message";
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import PlanningService from "../../../services/PlanningService";

import TextField from '@mui/material/TextField';
import DirectionService from "../../../services/DirectionService";
import UserService from "../../../services/UserService";
import BalanceConfigurationService from "../../../services/BalanceConfigurationService";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { fr } from "date-fns/locale";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import UserMaternityLeaveService from "../../../services/UserMaternityLeaveService";
import { convertCompilerOptionsFromJson } from "typescript";

const bullRed = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
    >
        *
    </Box>
)
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "row",
        maxWidth: 600,
        elevation: 0,
    },
    form: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    datePicker: {
        margin: 0,
    },
    businessInput: {
        display: "none",
    },
    disabled: {
        cursor: "default",
        opacity: "0.1",
    },
    solde1: {
        background: "red",

    },
    disabledSolde: {
        background: "rgb(235, 235, 228)",
        cursor: "default",
        pointerEvents: "none !important",
    },
    text: {
        fontSize: "16px",
        fontWeight: "bold",
    },
}));


function UserMaternityLeaveAdd(props) {
    const params = useParams();
    const id = params.id;

    const idToEdit = id ? id : "";

    const [form, setForm] = useState({
        direction_id: "",
        service_id: "",
        user_id: "",
        real_balance: "",
        userName: "",
        balance_configuration_id: "",
        beginDate: " ",
        endDate: " ",
    });
    const [message, setMessage] = useState({
        open: false,
        color: 'green',
        messageInfo: '',
    });
    const { open, color, messageInfo } = message;
    const contextData = useContext(userDetailContext);

    const classes = useStyles();

    const [dir, setDir] = useState([]);
    const [service, setService] = useState([]);
    const [users, setUsers] = useState([]);
    const [idservice, setIdservice] = useState(0);
    const [iddir, setIddir] = React.useState(0);
    const [iduser, setIdUser] = React.useState(0);
    const [isUserEmpty, setisUserEmpty] = React.useState(false);
    const [errorMessageUserEmpty, setErrorMessageUserEmpty] = React.useState("");
    const [beginDateEmpty, setBeginDateEmpty] = React.useState(false);
    const [endDateEmpty, setEndDateEmpty] = React.useState(false);
    const [errorMessageBeginDate, setErrorMessageEmptyBeginDate] = React.useState("");
    const [errorMessageEndDate, setErrorMessageEmptyEndDate] = React.useState("");
    const [isUserUndefined, setisUserUndefined] = React.useState(false);
    const [validDebut, setValiddebut] = useState(false);
    const [validFin, setValidFin] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }
    // function handleSelect() {
    //     DirectionService.getDirection().then((res) => {
    //         if (res && res.status) {
    //             setDir(res.data);
    //         }
    //     })
    // }
    useEffect(() => {

        setForm({
            direction_id: "",
            service_id: "",
            user_id: "",
            real_balance: "",
            userName: "",
            balance_configuration_id: "",
            beginDate: " ",
            endDate: ""
        });
        DirectionService.getDirection().then((res) => {
            if (res && res.status) {
                setDir(res.data);
            }
        })


        if (idToEdit) {
            // setOpenLoadingSimpleBackdrop(true);
            UserMaternityLeaveService.getMaternityLeaveById(idToEdit).then((res) => {
                if (res && res.status) {

                    setForm({
                        direction_id: res.data.direction_id,
                        service_id: res.data.service_id,
                        beginDate: res.data.user_maternity_leave_beginDate,
                        endDate: res.data.user_maternity_leave_endDate,
                        user_id: res.data.user_id
                    });

                    const user = {
                        "user_id": res.data.user_id,
                        "name": res.data.user_name,
                        "email": res.data.email
                    };
                    // console.log("user edited***", user);
                    PlanningService.Direction(res.data.direction_id).then((results) => {
                        if (results && results.data) {
                            setService(results.data);
                        }
                    })
                    UserService.getUserByServiceAndFemalSex(res.data.direction_id, res.data.service_id).then((results) => {
                        if (results && results.status) {
                            console.log("voalohany", iddir);
                            console.log("faaroa", results.data);
                            setUsers(results.data);

                            setisUserEmpty(false);
                            console.log("fahatelo", results.data);
                            setIdUser(user);
                        } else {
                            setisUserEmpty(true);
                            setErrorMessageUserEmpty(results.message);
                        }
                    })
                    // setOpenLoadingSimpleBackdrop(false);
                } else {
                    // setOpenLoadingSimpleBackdrop(false);
                }
            });
        }
    }, [location]);

    useEffect(() => {
        if (idToEdit && users.length !== 0) {
            UserService.getUserSummaryByUserId(form.user_id).then((res) => {
                if (res && res.status) {
                    setIdUser({
                        user_id: res.data.user_id,
                        name: res.data.name,
                        email: res.data.email
                    });
                }
            })
        }
    }, [users])

    function handleChangeDirection(event) {
        const { name, value } = event.target;
        setIddir(value);
        setForm({ ...form, [name]: value });
        PlanningService.Direction(value).then((results) => {
            if (results && results.data) {
                setService(results.data);
            }
        })
    }
    function handleChangeService(ev) {
        const { name, value } = ev.target;
        setForm({ ...form, [name]: value });
        setIdservice(value);
        UserService.getUserByServiceAndFemalSex(iddir, value).then((results) => {
            if (results && results.status) {
                console.log("id dir", iddir);
                console.log("ressssssss", results.data);
                setUsers(results.data);
                setisUserEmpty(false);
            } else {
                setisUserEmpty(true);
                setErrorMessageUserEmpty(results.message);
            }
        })
    }
    function handleChangeUsers(ev, value) {
        // const { name, value } = ev.target;
        console.log(value);
        setIdUser(value);
        // setForm({ ...form, userId: value });
        setisUserUndefined(false);
    }

    function handleClick() {

        if (idToEdit) {
            UserMaternityLeaveService.updateMaternityLeave(idToEdit, form.beginDate,form.endDate).then((res)=>{
                if(res && res.status){
                    setMessage({ ...message, open: true, color: 'green', messageInfo: res.message });
                        
                    navigate('/maternity_leave');
                }
            })
        } else {
            if (iduser !== "" && form.beginDate !== "" && form.endDate !== "") {

                UserMaternityLeaveService.saveUserMaternityLeave(iduser.user_id, form.beginDate, form.endDate).then((res) => {
                    if (res && res.status) {
                        setMessage({ ...message, open: true, color: 'green', messageInfo: res.message });
                        setForm({
                            direction_id: " ",
                            service_id: " ",
                            user_id: " ",
                            beginDate: " ",
                            endDate: " "
                        })
                        setIdUser({
                            "name": "",
                            "user_id": 0,
                            "email": ""
                        });
                        navigate('/maternity_leave');
                    } else {

                        setMessage({ ...message, open: true, color: 'red', messageInfo: res.message });
                    }
                })
            } else {
                if (iduser === "") {
                    setisUserUndefined(true);
                    setErrorMessageUserEmpty("Ce champ est obligatoire");
                }
                if (form.beginDate === "") {
                    setBeginDateEmpty(true);
                    setErrorMessageEmptyBeginDate("Ce champ est obligatoire");
                }
                if (form.endDate === "") {
                    setEndDateEmpty(true);
                    setErrorMessageEmptyEndDate("Ce champ est obligatoire");
                }
            }
        }
    }
    function handleCancel() {
        navigate("/maternity_leave");
    }

    function handleBeginDateChange(e) {

        const debutDate = moment(new Date(e)).format("YYYY-MM-DD");
        var threeMonthAfter = new Date(e.setMonth(e.getMonth() + 3));
        setForm({ ...form, beginDate: debutDate, endDate: threeMonthAfter });

    }

    function handleEndDateChange(e) {
        const endDate = moment(new Date(e)).format("YYYY-MM-DD");
        setForm({ ...form, endDate: endDate });
    }
    return (
        <MainContainer {...headerData}>
            <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            <Paper className={classes.paper} variant="outlined">
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <label>Direction{bullRed}</label>
                    </Grid>
                    <Grid item xs={8}>

                        <FormControl fullWidth variant="outlined" size="small">
                            <Select disabled={idToEdit} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={form.direction_id} name="direction_id" onChange={handleChangeDirection}>
                                {dir.map((row) => (
                                    <MenuItem value={row.direction_id}>{row.name}  ( {row.code} )</MenuItem>

                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <label>Service{bullRed}</label>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <Select disabled={idToEdit} error={isUserEmpty} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={form.service_id} name="service_id" onChange={handleChangeService} >
                                {service.map((row) => (
                                    <MenuItem value={row.service_id}>{row.name}  ( {row.code} )</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {isUserEmpty && <><Spacer y={1} /><Alert severity="error">{errorMessageUserEmpty}</Alert></>}
                    </Grid>

                    <Grid item xs={4}>
                        <label>Utilisateur{bullRed}</label>
                    </Grid>

                    <Grid item xs={8}>
                        <Autocomplete
                            onChange={handleChangeUsers}
                            options={users}
                            value={iduser !== 0? iduser: null}
                            defaultValue={null}
                            getOptionLabel={(option) => option.name || ""}
                            disabled={idToEdit?true:false}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: "search" }}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={4}>
                        <label>Date début{bullRed}</label>
                    </Grid>
                    <Grid item xs={8}>
                        <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                inputFormat="dd/MM/yyyy"
                                value={form.beginDate}
                                onChange={handleBeginDateChange}
                                renderInput={(params) =>
                                    <TextField {...params} error={validDebut} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" />
                                }
                            />
                        </LocalizationProvider>
                        {beginDateEmpty && <><Spacer y={1} /><Alert severity="error">{errorMessageBeginDate}</Alert></>}

                    </Grid>
                    <Grid item xs={4}>
                        <label>Date fin{bullRed}</label>
                    </Grid>
                    <Grid item xs={8}>
                        <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                inputFormat="dd/MM/yyyy"
                                value={form.endDate}
                                onChange={handleEndDateChange}
                                renderInput={(params) =>
                                    <TextField {...params} error={validFin} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" />
                                }
                            />
                        </LocalizationProvider>

                        {endDateEmpty && <><Spacer y={1} /><Alert severity="error">{errorMessageEndDate}</Alert></>}
                    </Grid>
                    <>
                        <Spacer y={2} />
                        <Grid item xs={12}>
                            <label className={classes.text}>({bullRed}):Champs obligatoires</label>
                        </Grid>
                        <Spacer y={2} />
                        <Grid container item xs={12} direction="row-reverse">
                            {hasPrivilege(contextData.privileges, 'Admin-Button-Save-Leave') &&
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleClick}
                                    style={{ fontWeight: 600 }}
                                >
                                    ENREGISTRER
                                </Button>
                            }
                            <Spacer x={2} />
                            <Button
                                variant="contained"
                                color="grey"
                                onClick={handleCancel}
                                style={{ fontWeight: 600 }}
                            >
                                ANNULER
                            </Button>
                        </Grid>
                    </>
                </Grid>
            </Paper>
        </MainContainer>
    );
}
export default UserMaternityLeaveAdd;