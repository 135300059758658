import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import LeaveTable from "./LeaveTable";
import { Button } from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataRH } from "../../../data/modules/hr-header";
import { userDetailContext } from "../../../App";
import { hasPrivilege } from "../../../utils/privilege";
import Planning from "../../tools/planning";
import RequestValidationService from "../../../services/RequestValidationService";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import Message from "../../tools/Message";
import { useNavigate, useLocation } from 'react-router-dom';
import BalanceConfigurationService from "../../../services/BalanceConfigurationService";
import UserService from "../../../services/UserService";
import LeaveService from "../../../services/LeaveService";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function LeaveList(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const contextData = useContext(userDetailContext);
  const [status, setStatus] = useState(1);
  const [activeButton, setActiveButton] = useState(true);
  const [message, setMessage] = useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const [isLoading, setisLoading] = useState(false);
  function handleClick() {
    navigate("/me/leaves/add");
  }
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez-vous vraiment envoyer votre planification de congé de la date du',
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  /* ********** Samuela: solde réel déjà configuré dans la base de données ********* */
  const [isRealBalanceAlreadyConfigurate, setisRealBalanceAlreadyConfigurate] = useState(false);
  const [errorMessageForRealBalanceEmpty, seterrorMessageForRealBalanceEmpty] = useState("");

  const [userConnectedName, setUserConnectedName] = useState('');
  const [userConnectedActualBalance, setUserConnectedActualBalance] = useState('');

  function handelClickConfirmation() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: true });
  }
  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function LoadTable() {
    // setOpenLoadingSimpleBackdrop(true);
    navigate("/me/leaves");
    // window.location.reload();
    // navigate(0);
    // setOpenLoadingSimpleBackdrop(false);
  }
  function confirmDialog() {
    setisLoading(true);
    RequestValidationService.saveRequestValidation().then((result) => {
      var responseErrorMessage = result.message;
      if (result.status) {
        setisLoading(false);
        setMessage({ ...message, open: true, color: 'green', messageInfo: "votre planning a été envoyé avec succès" });
        cancelDialog();
        LoadTable();
      } else {
        setisLoading(false);
        cancelDialog();
        setMessage({ ...message, open: true, color: 'red', messageInfo: responseErrorMessage });
        
      }
    });
  }
  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  const classes = useStyles();
  function handleChangeStatus(event) {
    const { name, value } = event.target;
    setStatus(value);

  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  useEffect(() => {
    RequestValidationService.verification().then((res) => {
      if (res && res.status) {
        setActiveButton(false);
      }
    });

    /** Check if real balance is already configurate for user connected */
    // BalanceConfigurationService.getConfigBalanceByUserConnected().then((res) => {
    //   if (res && res.data) {
    //     setisRealBalanceAlreadyConfigurate(true);
    //     localStorage.setItem("isInitialBalanceConfigurated", true);
    //   } else {
    //     setisRealBalanceAlreadyConfigurate(false);
    //     seterrorMessageForRealBalanceEmpty(res.message);
    //     localStorage.setItem("isInitialBalanceConfigurated", false);
    //   }
    // });

    const userConnectedId = localStorage.getItem("USER");
    UserService.getUserSummaryByUserId(userConnectedId).then((res) => {
      if (res && res.status) {
        setUserConnectedName((!res.data.first_name && res.data.first_name!== null && res.data.first_name !== "")?res.data.first_name : res.data.last_name);
        // setUserConnectedName(res.data.name);
        // localStorage.setItem("userFirstName", res.data.first_name);
      }
    });

    // BalanceConfigurationService.getConfigBalanceByUserConnected().then((res) => {
    //   if (res && res.data) {
    //     setUserConnectedActualBalance(res.data.real_balance);
    //   }
    // });


  }, [location]);

  useEffect(() => {
    // console.log("niova", userConnectedName);

    if (userConnectedName.trim().length !== 0) {
      BalanceConfigurationService.isInitialBalanceConfigurated().then((res) => {
        if (res && res.status) {
          setisRealBalanceAlreadyConfigurate(true);
          // localStorage.setItem("isRealBalanceAlreadyConfigurate", true);
          localStorage.setItem("isInitialBalanceConfigurated", true);
          // setOpenLoadingSimpleBackdrop(false);
        } else {
          setisRealBalanceAlreadyConfigurate(false);
          seterrorMessageForRealBalanceEmpty(res.message);
          localStorage.setItem("isInitialBalanceConfigurated", false);
          // setOpenLoadingSimpleBackdrop(false);
        }
      })
      // LeaveService.getRealBalanceBefore().then((result) => {
      //   console.log("ressssssssss", result)
      //   if (result && result.status) {
      //     localStorage.setItem("realBefore", result.data);
      //     setUserConnectedActualBalance(result.data);
      //   }
      // });
      BalanceConfigurationService.getUserConnectedBalanceDetail().then((result) => {
        console.log("ressssssssss", result)
        if (result && result.status) {
          localStorage.setItem("realBefore", result.data.actualBalance);
          setUserConnectedActualBalance(result.data.actualBalance);
        }
      });
    }
  }, [userConnectedName]);

  return (
    <MainContainer {...headerDataRH}>
      <Planning openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} isLoading={isLoading}/>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Grid container item xs={12}>
        <Grid item xs={6}>
          {hasPrivilege(contextData.privileges, 'RH_Button_Create_Planning') &&
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClick}
              style={{ fontWeight: 600 }}
            >
              CREER
        </Button>
          }
        </Grid>
        <Grid item xs={3}>
        </Grid >
        <Grid item xs={3}>
          {hasPrivilege(contextData.privileges, 'RH_Button_Validate_Planning') && activeButton &&
            <Button
              variant="contained"
              color="secondary"
              onClick={handelClickConfirmation}
              style={{ fontWeight: 600 }}
            >
              ENVOYER PLANIFICATION
        </Button>
          }
        </Grid>

      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3} item>

        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <LeaveTable userConnectedName={userConnectedName} userConnectedActualBalance={userConnectedActualBalance} />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
