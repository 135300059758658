export const headerData = {
    title: 'Mes validations',
    submodules: [
        createData(0, "Validations réelles", "/me/validations"),
        createData(0, "Acceptation demande d'appui", "/acceptedHelp"),
        createData(1, "Validations plannings", "/planning/table"),
    ],
}

function createData(id, moduleName, ref) {
    return { id, moduleName, ref };
}