import React, { Component } from "react";
import Keyboard from "react-simple-keyboard";
import TextField from "@material-ui/core/TextField";
import layout from "simple-keyboard-layouts/build/layouts/french";
import "react-simple-keyboard/build/css/index.css";
import Spacer from "../../components/Spacer";
import Alert from "@material-ui/lab/Alert";

class KeybordVirtuel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      layoutName: "default",
      input: "",
    };
  }

  

  onChange = input => {
    this.setState({
      input: input
    });

    this.props.onChangeInput(input);
    // console.log("Input changed", input);
  };

  onKeyPress = button => {
    // console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  onChangeInput = event => {
    let input = event.target.value;
    this.setState(
      {
        input: input
      },
      () => {
        this.keyboard.setInput(input);
      }
    );
  };

  render() {
    return (
      <div>
        <TextField
          inputProps={{
            readOnly: true,
          }}
          autoFocus
          margin="dense"
          required
          id="code-pin"
          name="codePin"
          label="Code PIN"
          type="text"
          fullWidth
          value={this.state.input}
          onChange={e => this.onChangeInput(e)}
        />
        <Spacer y={2}></Spacer>
        {/* <input
          value={this.state.input}
          placeholder={"Code"}
          onChange={e => this.onChangeInput(e)}
        /> */}
        <Keyboard
          keyboardRef={r => (this.keyboard = r)}
          theme={"hg-theme-default myTheme1"}
          layout={layout.layout}
          layoutName={this.state.layoutName}
          onChange={input => this.onChange(input)}
          onKeyPress={button => this.onKeyPress(button)}

        />
      </div>
    );
  }
}

export default KeybordVirtuel
