import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props){
  const openAlertDialog = props.openAlertDialog;
  const closeAlertDialog = props.closeAlertDialog;
  return (
    <div>
      <Dialog open={openAlertDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="form-dialog-title" color="primary" /*style={{backgroundColor: '#0000B3'}}*/ >{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Le sexe et le numéro CIN sont incohérents, veuillez les verifier."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={closeAlertDialog} >Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

