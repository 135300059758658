import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spacer from "../../components/Spacer";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import AcceptRequestService from "../../services/AcceptRequestService";
import InventoryService from "../../services/InventoryService";
import Alert from "@material-ui/lab/Alert";
import Loading from "../../components/loading";
import Message from "../tools/Message";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  step1Title: {
    textAlign: "center",
  },
  step1Grid: {
    padding: "10px",
  },
  gridItem: {
    height: "25px",
  },
}));

export default function ValidateImmobilisationRespLogisticsRequest(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [form, setForm] = useState({
    mdp: "",
  });
  const [openCodePin, setOpenCodePin] = useState(false);
  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);
  const [errorMdpMessage, setErrorMdpMessage] = useState('');
  const [inputValue, setInputValue] = useState("");
  const [failedCodePin, setFailedCodePin] = useState(false);
  const [errorMessageFailedCodePin, setErrorMessageFailedCodePin] = useState(false);
  const open = props.openValidateRequest;
  const handleCloseValidateRequest = props.handleCloseValidateRequest;
  const module_type_id = 4;
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = React.useState({
    openMessage: false,
    color: "green",
    messageInfo: "",
  });
  const { openMessage, color, messageInfo } = message;

  useEffect(() => { }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }

  function handleClickPassword() {
    if (form.mdp.trim() == "") {
      setError(true);
      setErrorMdpMessage("Mot de passe requis!!!");
    }
    else {
      setLoading(true);
      AcceptRequestService.verifyMdp(form.mdp, module_type_id).then((result) => {
        if (result.status) {
          setLoading(true);

          var firstValidationStorage          = localStorage.getItem("firstValidationStorage");
          var confirmationByOldHolderStorage  = localStorage.getItem("confirmationByOldHolderStorage");
          var validationByHolderStorage       = localStorage.getItem("validationByHolderStorage");
          var validationAffectation           = localStorage.getItem("validationAffectation");
          var declinedByOldHolderStorage      = localStorage.getItem("declinedByOldHolderStorage");

          console.log(
              "firstValidationStorage  : ", firstValidationStorage,
              "confirmationByOldHolderStorage : " , confirmationByOldHolderStorage,
              "validationByHolderStorage : " , validationByHolderStorage,
              "validationAffectation : " , validationAffectation
          );
          if(validationAffectation){console.log("1-validationTransfert");
            validationTransfert();
            localStorage.removeItem("validationAffectation");
          }
          if(firstValidationStorage){console.log("2-firstValidationStorage");
            validationByLogisticsManager();
            localStorage.removeItem("firstValidationStorage");
          }
          if(confirmationByOldHolderStorage){console.log("3-confirmationByOldHolderStorage");
            confirmationByOldHolder();
            localStorage.removeItem("confirmationByOldHolderStorage");
          }
          if(validationByHolderStorage){console.log("4-validationByHolderStorage");
            validateStateAffectationHolder();
            localStorage.removeItem("validationByHolderStorage");
          }
          
        } else {
          setError(true);
          setErrorMdpMessage(result.message);
          setLoading(false);
        }
      }).catch((err) => {
        setError(true);
        setErrorMdpMessage('Une erreur s\'est produite');
        setLoading(false);
      });
    }
  }

  function handleFocusPassword() {
    setError(false);
  }


  function handleCloseMessage() {
    setMessage({ ...message, openMessage: false });
  }

  function validationByLogisticsManager() {
    var allImmoSelected = localStorage.getItem("allImmoSelectedIdStorage");
    InventoryService.validateStateAffectationLogisticsManager(allImmoSelected).then((result) => {
      if (result.status == true) {
          setMessage({
              ...message,
              openMessage: true,
              color: "green",
              messageInfo: result.message,
          });
          
          const intervalId = setInterval(() => {window.location.reload();}, 2500);                
      }
      else{
          setMessage({
              ...message,
              openMessage: true,
              color: "red",
              messageInfo: result.message,
          });
      }
    });
  }
  function validationTransfert() {
    var allImmoSelected = localStorage.getItem("allImmoSelectedIdStorage");
    InventoryService.validateStateTransfert(allImmoSelected).then((result) => {
      if (result.status == true) {
          setMessage({
              ...message,
              openMessage: true,
              color: "green",
              messageInfo: result.message,
          });
          
          const intervalId = setInterval(() => {window.location.reload();}, 2500);                
      }
      else{
          setMessage({
              ...message,
              openMessage: true,
              color: "red",
              messageInfo: result.message,
          });
      }
    });
  }

  function confirmationByOldHolder() {
    var id    = localStorage.getItem("immoImmoHistoryIdStorage");
    var state = localStorage.getItem("immoStatusStorage");
    console.log(id,'/',state);

    InventoryService.validateStateByOldHolder(state,id).then((result) => {
      if (result.status == true) {
        setLoading(false);
        setMessage({
            ...message,
            openMessage: true,
            color: "green",
            messageInfo: result.message,
        });
          
        setInterval(() => { window.location.reload(); }, 2500);            
      }
      else{
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: result.message,
        });
      }
    });
  }

  function validateStateAffectationHolder() {
    var id    = localStorage.getItem("immoImmoHistoryIdStorage");
    var state = localStorage.getItem("immoStatusStorage");
    console.log(id,'/',state);

    InventoryService.validateStateAffectationHolder(state,id).then((result) => {
      if (result.status == true) {
        setLoading(false);
        setMessage({
            ...message,
            openMessage: true,
            color: "green",
            messageInfo: result.message,
        });
          
        setInterval(() => { window.location.reload(); }, 2500);            
      }
      else{
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: result.message,
        });
      }
    });
  }

  return (
    <>
      
      <Message
        open={openMessage}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      {step == 1 &&
        <Dialog open={open} onClose={handleCloseValidateRequest} aria-labelledby="form-dialog-title1" fullWidth maxWidth="sm">
          <DialogTitle id="form-dialog-title1">
            Validation electronique
        </DialogTitle>
          <br />
          <div className={classes.root}>
            <div>
              <h3 className={classes.step1Title}>Votre mot de passe </h3>
            </div>
            <Grid className={classes.step1Grid} container>
              <Grid className={classes.gridItem} item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  size="small"
                  label="Votre mot de passe"
                  variant="outlined"
                  value={form.mdp}
                  name="mdp"
                  type="password"
                  onChange={handleChange}
                  onFocus={handleFocusPassword}
                />
              </Grid>
              <Grid item xs={12}>

                <Spacer y={3} />
                {isLoading && <Loading />}
                {error && (
                  <Alert severity="error">{errorMdpMessage}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Spacer y={2} />
                <DialogActions>
                  <Button style={{ backgroundColor: "##f50057", color: "#FFFFFF" }} onClick={handleCloseValidateRequest} color="secondary" variant="contained" >
                    Annuler
                  </Button>
                  <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF", }} onClick={handleClickPassword} color="secondary" variant="contained" >
                    Valider
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      }
    </>
  );
}
