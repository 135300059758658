
/**
 * Fonction natao hi testena raha manana ny privilege tadiavina ny user
 * @param {array(priv)} listPrivilege // liste des privileges de l'utilisateur
 * @param {string} privilege // code du privilege 
 */
 export const hasPrivilege = (listPrivilege, privilege) => {
    for(const prv of listPrivilege) { //tetezina ireo privilege ananany
        if(prv.label === privilege) {//jerena raha toa ka mitovy amin'ny privilege tadiavina ny coden'ilay privilege ao anaty lisitra
            return true; // averina satria marina fa ananany ilay privilege
        } // vita ny andrana
    } // tapitra notetezina izy rehetra 
    // tsy nahitana privilege manana code mitovy amin'ilay notadiavina mihitsy
    return false; // tsy ananany ilay privilege ka dia diso izany no averina 

} // mody !! viloma