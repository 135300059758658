import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from "@material-ui/core/SnackbarContent";


export default function Result(props){

 const color=props.color;
 const open=props.open;
 const message=props.message;
 const [state, setState] = React.useState({
    open: props.open,
    vertical: 'bottom',
    horizontal: 'right',
    message:props.message,
  });
  const { vertical, horizontal } = state;
  
  const handleClose=props.handleClose;
  return (
    <div>
        <Snackbar  anchorOrigin={{ vertical, horizontal }}     onClose={handleClose}  open={open}  key={vertical + horizontal}>
        <SnackbarContent
            onClose={handleClose}
            message={message}
            autoHideDuration={1000}
            style={{backgroundColor:color , color:"white"}}
            />
        </Snackbar>
    </div>
  );
}