import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const EquipmentRankingService = {
  async save(
    equipment_category_id,
    equipment_ranking_code,
    equipment_ranking_name,
    equipment_ranking_desc,
    equipment_config
  ) {
    try {
      const result = await axios.post(`${apiUrl}/equipmentRanking/add/`, {
        equipment_category_id,
        equipment_ranking_code,
        equipment_ranking_name,
        equipment_ranking_desc,
        equipment_config,
      });
      if (result) {
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getEquipmentCategory() {
    try {
      const result = await axios.get(`${apiUrl}/equipmentCategory/`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getAllEquipmentRanking() {
    try {
      const result = await axios.get(`${apiUrl}/equipmentRanking/`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getEquipmentCategoryByType(type) {
    try {
      const result = await axios.get(
        `${apiUrl}/equipment_ranking/type/${type}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getEquipmentRankingById(id) {
    try {
      const result = await axios.get(`${apiUrl}/equipmentRanking/id/${id}`);
      console.log(result.data);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getByCategory(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/equipmentRanking/category/${id}`
      );
      console.log(result.data);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async update(
    equipment_category_id,
    equipment_ranking_code,
    equipment_ranking_name,
    equipment_ranking_desc,
    equipment_config,
    id
  ) {
    try {
      const result = await axios.put(`${apiUrl}/equipmentRanking/${id}`, {
        equipment_category_id,
        equipment_ranking_code,
        equipment_ranking_name,
        equipment_ranking_desc,
        equipment_config,
      });
      if (result) {
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async deleteEquipmentRanking(id) {
    try {
      const result = await axios.delete(`${apiUrl}/EquipmentRanking/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data);
      }
      throw error;
    }
  },
};
export default EquipmentRankingService;
