import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FamilyMemberService from "../../../services/FamilyMemberService";
import TextField from "@material-ui/core/TextField";
import { Button, MenuItem, FormControl, Select, InputLabel} from "@material-ui/core";
import MuiPhoneNumber from 'material-ui-phone-number';
import ConfirmationDialog from "../../tools/ConfirmationDialog";
import Message from "../../tools/Message";
import Alert from "@material-ui/lab/Alert";

import Address from "../../tools/Address";
import Phone from "../../tools/Phone";
import UserInfo from "./UserInfo";
import AlertDialog from "../../tools/AlertDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition1: {
    float: 'left',
  },
  labelPosition: {
    float: 'right',
  },
  text: {
    width: 370,
  }
}));

export default function Joint(props) {
  const classes = useStyles();
  const [form, setForm] = useState({
    person_id: 0, first_name:"", last_name:"", personnal_email:"", card_number:"", birth_date:"",
    telephone:"", sex_id: props.sex_id, card_deliverance_date:"", address:props.address , marital_status: "Marié(e)",
    birth_place:"", card_deliverance_place:"", card_duplicate_date:"", user_id: props.user_id, relationship_type_id: 1,
    value_personal_config:[]});
  const [personalConfig, setPersonalConfig] = useState([]);
  const [valuePersonalConfigTemp, setValuePersonalConfigTemp] = useState([]);
  const jointData = props.defaultValueOfJoint;
  const [age, setAge] = useState(0);
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const [isValidFirst_name, setIsValidFirst_name] = useState(false);
  const [isValidBirth_date, setIsValidBirth_date] = useState(false);
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [isValidCard_number, setIsValidCard_number] = useState(false);
  const [isValidCard_deliverance_date, setIsValidCard_deliverance_date] = useState(false);
  const [isValidCard_deliverance_place, setIsValidCard_deliverance_place] = useState(false);
  const [isValidCard_duplicate_date, setIsValidCard_duplicate_date] = useState(false);
  const [isValidAge, setIsValidAge] = useState(false);

  const [userInfoData, setUserInfoData] = React.useState({
    openUserInfo : false,
    data : null
  });

  const { openUserInfo, data } = userInfoData;

  function closeUserInfo() {
    setUserInfoData({...userInfoData, openUserInfo: false});
  }
  // 
  const [alertDialogData, setAlertDialogData] = React.useState({
    openAlertDialog : false
  });

  const { openAlertDialog } = alertDialogData;

  function closeAlertDialog() {
    setAlertDialogData({...alertDialogData, openAlertDialog: false});
  }

  function checkSexCINConsistency(event){
    const { name, value } = event.target;
    
    if(Array.from(value)[6]!==props.sex_id+""){
      setAlertDialogData({...alertDialogData, openAlertDialog: true});
      console.log("TSY MITOVY ========== ", Array.from(value)[6], " _______ ", props.sex_id);
    }
  }

  function handleCloseMessage(){
    setMessage({...message, open: false});
  }

  useEffect(() => {
    FamilyMemberService.getJointPersonalConfig().then((result) => {
      if(result && result.data){
        setPersonalConfig(result.data);
      }
    });
    //
    if(jointData !== undefined){
      setForm({
        ...jointData,
        marital_status: "Marié(e)", user_id: props.user_id, relationship_type_id: 1, value_personal_config:[]
      });
      setAge(new Date().getFullYear() - new Date(jointData.birth_date).getFullYear());
    }
    // 
  }, [jointData]);

  function toTableCaractere(letter){
    let table = [];
    let i=0;
    for(i=0; i<letter.length; i++){
      table[i] = letter.charAt(i);
    }
    return table;
  }

  function firstLetterToUpperCase(letter){
    let table = toTableCaractere(letter);
    let i=0;
    for(i=0; i<table.length-1; i++){
      if(table[i]==" "){
        table[i+1] = table[i+1].toUpperCase();
      }
    }
    letter = table.join("");
    letter = letter.charAt(0).toUpperCase()+letter.slice(1);
    return letter;
  }

  function handleChangeFormValue(event){
    const { name, value } = event.target;
    if(name=='first_name'){
      setForm({ ...form, [name]: value.toUpperCase() });
    }
    else if(name=='last_name' || name=='birth_place' || name=='address' || name=='card_deliverance_place'){
      setForm({ ...form, [name]: firstLetterToUpperCase(value) });
    }
    else if(name=='birth_date'){
      setForm({ ...form, [name]: value });
      const agePerson = new Date().getFullYear() - new Date(value).getFullYear();
      if(!Number.isNaN(agePerson)){
        setAge(agePerson);
      }
      if(agePerson < 18 || agePerson > 70){
        setIsValidAge(true);
        setIsValidBirth_date(false);
      }else{
        setIsValidAge(false);
      }
    }
    else{
      setForm({ ...form, [name]: value });
    }
  }

  function handleChangePhoneValue(event){
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }

  function handleChangePersonalConfigValue(event){
    const { name, value } = event.target;
    valuePersonalConfigTemp[name] = {
      "personal_config_id":name,
      "value":value
    };
  }

  function handleClick(){
    if(form.first_name===''){
        setIsValidFirst_name(true);
    }
    if(form.birth_date===''){
        setIsValidBirth_date(true);
    }
    if(form.address===''){
        setIsValidAddress(true);
    }
    if(form.card_number===''){
        setIsValidCard_number(true);
    }
    if(form.card_deliverance_date===''){
        setIsValidCard_deliverance_date(true);
    }
    if(form.card_deliverance_place===''){
        setIsValidCard_deliverance_place(true);
    }
    if(form.card_duplicate_date===''){
      setIsValidCard_duplicate_date(true);
    }
    if(form.first_name!=='' && form.birth_date!=='' && form.address!=='' && form.card_number!=='' && form.card_deliverance_date!=='' && form.card_deliverance_place!=='' && form.card_duplicate_date!==''){
      var iTemp = 0;
      for(var i=0; i<valuePersonalConfigTemp.length; i++){
        if(typeof valuePersonalConfigTemp[i] !== 'undefined'){
          form.value_personal_config[iTemp] = valuePersonalConfigTemp[i];
          iTemp++;
        }
      }
      FamilyMemberService.saveFamilyMember(form).then((result) => {
        if(result.data){
          console.log("FAMILY MEMBER DATA === ", result.data);
          setUserInfoData({...userInfoData, openUserInfo: true, data: result.data});
        }else{
          console.log("TSY MISY DATA", result.data);
        }
        if(result.status){
          setMessage({...message, open: true, color: 'green', messageInfo: result.message});
        }else{
          setMessage({...message, open: true, color: 'red', messageInfo: result.message});
        }
      }).catch((err) => {
        setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      });
    }else{
      console.log("MISY TSY FENO");
    }

  }

  function handleClickUpdate(){
    if(form.first_name===''){
      setIsValidFirst_name(true);
    }
    if(form.birth_date===''){
        setIsValidBirth_date(true);
    }
    if(form.address===''){
        setIsValidAddress(true);
    }
    if(form.card_number===''){
        setIsValidCard_number(true);
    }
    if(form.card_deliverance_date===''){
        setIsValidCard_deliverance_date(true);
    }
    if(form.card_deliverance_place===''){
        setIsValidCard_deliverance_place(true);
    }
    if(form.card_duplicate_date===''){
      setIsValidCard_duplicate_date(true);
    }
    if(form.first_name!=='' && form.birth_date!=='' && form.address!=='' && form.card_number!=='' && form.card_deliverance_date!=='' && form.card_deliverance_place!=='' && form.card_duplicate_date!==''){
      FamilyMemberService.updateFamilyMember(form, form.person_id).then((result) => {
        if(result.status){
          setMessage({...message, open: true, color: 'green', messageInfo: result.message});
        }else{
          setMessage({...message, open: true, color: 'red', messageInfo: result.message});
        }
      }).catch((err) => {
        setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      });
    }
  }
  
  const personalConfigItems = [];
  for(var i=0; i<personalConfig.length; i++){
    if(personalConfig[i].type_field === 'Choix'){
      personalConfigItems.push(
        <>
        <Grid item xs={4}>
          <label className={classes.labelPosition1}>{personalConfig[i].name_field}</label>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-outlined-label">{personalConfig[i].name_field}</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label={personalConfig[i].name_field} name={personalConfig[i].personal_config_id} onChange={handleChangePersonalConfigValue}>
              {personalConfig[i].choice.map((items) => (
                <MenuItem value={items.personal_config_choice_id}>{items.label_choice}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        </>
      );
    }else{
      personalConfigItems.push(
        <>
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>{personalConfig[i].name_field}</label>
        </Grid>
        <Grid item xs={8}>
            <TextField fullWidth type="" id="outlined-basic" size="small" label={personalConfig[i].name_field} variant="outlined" name={personalConfig[i].personal_config_id} onChange={handleChangePersonalConfigValue} />
        </Grid>
        </>
      );
    }
  }
  const saveButton = [];
  if(typeof jointData === 'undefined'){
    saveButton.push(
      <>
      <Grid item xs={12}>
        <Button className={classes.labelPosition} variant="contained" color="primary" onClick={handleClick} >Enregistrer</Button>
      </Grid>
      </>
    );
  }else{
    saveButton.push(
      <>
      <Grid item xs={12}>
        <Button className={classes.labelPosition} variant="contained" color="primary" onClick={handleClickUpdate} >Enregistrer</Button>
      </Grid>
      </>
    );
  }

  function onChangeAddress(event){
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }

  return (
    <Grid container spacing={3} className={classes.paper} variant="outlined">
      <AlertDialog openAlertDialog={openAlertDialog} closeAlertDialog={closeAlertDialog} />
      <UserInfo openUserInfo={openUserInfo} data={data} closeUserInfo={closeUserInfo} />
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>Nom*</label>
        </Grid>
        <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="Nom" variant="outlined" name="first_name" value={form.first_name} onChange={handleChangeFormValue} />
            {isValidFirst_name && <Alert severity="error">Veuillez remplir ce champ</Alert>}
        </Grid>
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>Prénom</label>
        </Grid>
        <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="Prénom" variant="outlined" name="last_name" value={form.last_name} onChange={handleChangeFormValue} />
        </Grid>
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>E-mail</label>
        </Grid>
        <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="E-mail" variant="outlined" name="personnal_email" value={form.personnal_email} onChange={handleChangeFormValue} />
        </Grid>
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>Téléphone</label>
        </Grid>
        <Grid item xs={8}>
          <Phone name="telephone" value={form.telephone} onChange={handleChangePhoneValue} />
        </Grid>
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>Date de naissance*</label>
        </Grid>
        <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" variant="outlined" type="date" name="birth_date" value={form.birth_date} onChange={handleChangeFormValue} />
            {isValidBirth_date && <Alert severity="error">Veuillez remplir ce champ</Alert>}
            {isValidAge && <Alert severity="error">Vérifier votre date de naissance</Alert>}
        </Grid>
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>Lieu de naissance</label>
        </Grid>
        <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="Lieu de naissance" variant="outlined" name="birth_place" value={form.birth_place} onChange={handleChangeFormValue} />
        </Grid>
        {/* auto */}
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>Age</label>
        </Grid>
        <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="Age" variant="outlined" /*type="number"*/ value={age} />
        </Grid>
        {/*  */}
        <Address  x1={4} x2={8} name="address" value={form.address} onChange={onChangeAddress}/>
        {/* {isValidAddress && <Alert severity="error">Veuillez remplir ce champ</Alert>} */}
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>CIN*</label>
        </Grid>
        <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="CIN" variant="outlined" name="card_number" value={form.card_number} onChange={handleChangeFormValue} onBlur={checkSexCINConsistency} inputProps={{maxLength:15}} />
            {isValidCard_number && <Alert severity="error">Veuillez remplir ce champ</Alert>}
        </Grid>
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>Date de délivrance*</label>
        </Grid>
        <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" variant="outlined" type="date" name="card_deliverance_date" value={form.card_deliverance_date} onChange={handleChangeFormValue} />
            {isValidCard_deliverance_date && <Alert severity="error">Veuillez remplir ce champ</Alert>}
        </Grid>
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>Lieu de délivrance*</label>
        </Grid>
        <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="Lieu de délivrance" variant="outlined" name="card_deliverance_place" value={form.card_deliverance_place} onChange={handleChangeFormValue} />
            {isValidCard_deliverance_place && <Alert severity="error">Veuillez remplir ce champ</Alert>}
        </Grid>
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>Date de duplicata*</label>
        </Grid>
        <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" variant="outlined" type="date" name="card_duplicate_date" value={form.card_duplicate_date} onChange={handleChangeFormValue} />
            {isValidCard_duplicate_date && <Alert severity="error">Veuillez remplir ce champ</Alert>}
        </Grid>
        {/*  */}
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
            <label className={classes.labelPosition1} style={{ textDecorationLine: 'underline' }} >Autres champs à remplir : </label>
        </Grid>
          {personalConfigItems}
        {/*  */}
        {saveButton}
    </Grid>
  );
}
