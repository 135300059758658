import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";

export default function Phone(props) {
  function getMadaNumberPhone(value) {
    // if(value.length===3 || value.length===6 || value.length===10 ){
    //   value = value + " ";
    // }
    if (value) {
      // clean the input for any non-digit values.
      const phoneNumber = value.replace(/[^\d]/g, '');

      // phoneNumberLength is used to know when to apply our formatting for the phone number
      const phoneNumberLength = phoneNumber.length;

      // we need to return the value with no formatting if its less then four digits
      // this is to avoid weird behavior that occurs if you  format the area code to early

      if (phoneNumberLength < 4) { return phoneNumber };

      if (phoneNumberLength < 6) {
        return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 5)}`;
      }
      // the formatted number
      if (phoneNumberLength < 9) {
        return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 5)} ${phoneNumber.slice(5, 8)}`;
      }

      // finally, if the phoneNumberLength is greater then eleven, we add the last
      // bit of formatting and return it.
      return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 5)} ${phoneNumber.slice(5, 8)} ${phoneNumber.slice(8, 10)}`;
    } else {
      return "";
    }
    // if (value) {
    //   var length = value.length;
    //   var values = value.split('');
    //   var sep = " ";
    //   var temp = [];
    //   for(var i=0; i<length; i++){
    //     if(values[i]===sep){

    //       temp.push(values[i]);
    //     }
    //   }
    //   // value = temp.join("");

    //   if(length<3){
    //     value = value.substring(0,3);
    //   }
    //   if(length>3 && length<6){
    //     value = value.substring(0,3)+sep+value.substring(4, 6);
    //   }
    //   if(length>6 && length<10){
    //     value = value.substring(0,3)+sep+value.substring(4, 6)+sep+value.substring(7, 10);
    //   }
    //   if(length>10){
    //     value = value.substring(0,3)+sep+value.substring(4, 6)+sep+value.substring(7, 10)+sep+value.substring(11);
    //   }
    //   return value;
    // } else {
    //   return "034 00 000 00";
    // }
  }

  return (
    <>
      {props.isAnnuaire &&
        <TextField fullWidth id="standard-basic" size="small" variant="standard" name={props.name} value={getMadaNumberPhone(props.value)} onChange={props.onChange} onFocus={props.onFocus} inputProps={{ maxLength: 13 }} disabled={props.disabled} placeholder="034 00 000 00" />
      }
      {!props.isAnnuaire &&
        <TextField fullWidth id="outlined-basic" size="small" label="Téléphone" variant="outlined" name={props.name} value={getMadaNumberPhone(props.value)} onChange={props.onChange} onFocus={props.onFocus} inputProps={{ maxLength: 13 }} disabled={props.disabled} placeholder="034 00 000 00" />
      }
    </>
  );
}
