import { Grid,Button } from "@material-ui/core";
import { headerDataState } from "../../data/modules/custom-states-header";
import MainContainer from '../../components/MainContainer';

import { useNavigate } from 'react-router-dom';
import Spacer from "../../components/Spacer";
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import LeaveLastWeekTable from "./LeaveLastWeekTable";
export default function LeaveLastWeek(){
   
  const navigate = useNavigate();
    function handleNext(){
        navigate("/leave_week");
  }
    return(
        <MainContainer {...headerDataState}>
            <Grid >
                <LeaveLastWeekTable/>
            </Grid>
            <Spacer y={2} />
            <Grid container item xs={12}>
                
                <Button variant="contained" style={{ fontWeight: 600 }} onClick={handleNext}>Semaine courante<KeyboardDoubleArrowRightOutlinedIcon/></Button>
                <Spacer x={2} />
      
            </Grid>
        </MainContainer>
    )
}