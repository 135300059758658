import {
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { set } from "date-fns";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../components/MainContainer";
import Spacer from "../../components/Spacer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import EquipmentRankingService from "../../services/EquipmentRankingService";
import Message from "../tools/Message";
import Alert from "@material-ui/lab/Alert";
import Box from "@mui/material/Box";
import EquipmentConfigService from "../../services/EquipmentConfigService";
const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
    }}
  >
    *
  </Box>
);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 1000,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: "left",
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));
function EquipmentRankingAdd(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [equipment_category, setEquipmentCategory] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [data, setData] = React.useState([]);
  const fileName = "myfile";
  const [ecIsEmpty, setEcIsEmpty] = useState(false);
  const [nameIsEmpty, setNameIsEmpty] = useState(false);
  const [config, setConfig] = useState([]);
  const [codeIsEmpty, setCodeIsEmpty] = useState(false);
  const [message, setMessage] = useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;
  const [form, setForm] = useState({
    equipment_category_id: "",
    equipment_ranking_code: "",
    equipment_ranking_name: "",
    equipment_ranking_desc: "",
    equipment_config: [],
  });
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  function handleChange(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
    if (name == "equipment_ranking_code") {
      setForm({ ...form, [name]: value.toUpperCase() });
    }
  }
  function handleClick() {
    const idGroup = splitLocation[4];
    if (idGroup) {
      EquipmentRankingService.update(
        form.equipment_category_id,
        form.equipment_ranking_code,
        form.equipment_ranking_name,
        form.equipment_ranking_desc,
        form.equipment_config,
        idGroup
      )
        .then((results) => {
          if (results.status) {
            setMessage({
              ...message,
              open: true,
              color: "green",
              messageInfo: "Succèes",
            });
            setTimeout(() => {
              navigate("/Logistic/ranking");
                }, 1000);
          } else {
            setMessage({
              ...message,
              open: true,
              color: "red",
              messageInfo: "Une erreur s'est produite. Veuillez réessayer",
            });
          }
        })
        .catch((err) => {
          alert("error");
        });
    } else {
      EquipmentRankingService.save(
        form.equipment_category_id,
        form.equipment_ranking_code,
        form.equipment_ranking_name,
        form.equipment_ranking_desc,
        form.equipment_config
      )
        .then((res) => {
          var responseErrorMessage = res.message;
          if (res.status) {
            setMessage({
              ...message,
              open: true,
              color: "green",
              messageInfo: "Succès",
            });
            setTimeout(() => {
            navigate("/Logistic/ranking");
              }, 1000);
          } else {
            setMessage({
              ...message,
              open: true,
              color: "red",
              messageInfo: responseErrorMessage,
            });
          }
        })
        .catch((err) => {
          setMessage({
            ...message,
            open: true,
            color: "red",
            messageInfo: "Une erreur s'est produite. Veuillez réessayer",
          });
        });
      if (
        form.equipment_category_id == null ||
        form.equipment_category_id == undefined ||
        form.equipment_category_id == ""
      ) {
        setEcIsEmpty(true);
      } else if (
        form.equipment_ranking_name == null ||
        form.equipment_ranking_name == undefined ||
        form.equipment_ranking_name == ""
      ) {
        setNameIsEmpty(true);
      } else if (
        form.equipment_ranking_code == null ||
        form.equipment_ranking_code == undefined ||
        form.equipment_ranking_code == ""
      ) {
        setCodeIsEmpty(true);
      }
    }
  }
  function handleSelect() {
    EquipmentRankingService.getEquipmentCategory().then((result) => {
      if (result && result.data) {
        setEquipmentCategory(
          result.data.map((d) => {
            return {
              id: d.equipment_category_id,
              code: d.account_code,
              name: d.name_category,
              desc: d.desc_category,
            };
          })
        );
      }
    });
  }
  function handleSelect2() {
    EquipmentConfigService.getAllEquipmentConfig().then((result) => {
      if (result && result.data) {
        setConfig(
          result.data.map((d) => {
            return {
              equipment_config_id: d.equipment_config_id,
              configuration: d.configuration,
              description_config: d.description_config,
            };
          })
        );
      }
    });
  }
  const getItemLabel = (id) => {
    for (const row of config) {
      if (row.equipment_config_id === id) {
        return row.configuration;
      }
    }
    return "";
  };
  function handleCancel() {
    navigate("/Logistic/ranking");
  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  useEffect(() => {
    localStorage.removeItem("verify");
    const idGroup = splitLocation[4];
    console.log(idGroup);

    
    if (idGroup) {
      EquipmentConfigService.getAllEquipmentConfig().then((result) => {
        if (result && result.data) {
          setConfig(
            result.data.map((d) => {
              return {
                equipment_config_id: d.equipment_config_id,
                configuration: d.configuration,
                description_config: d.description_config,
              };
            })
          );
        }
      });
      EquipmentRankingService.getEquipmentRankingById(idGroup).then((info) => {
        console.log(info.data);
        setForm({
          id: idGroup,
          equipment_category_id: info.data.equipment_category_id,
          equipment_ranking_code: info.data.equipment_ranking_code,
          equipment_ranking_name: info.data.equipment_ranking_name,
          equipment_ranking_desc: info.data.equipment_ranking_desc,
          equipment_config: info.data.equipment_config.map(
            (pv) => pv.equipment_config_id
          ),
        });
      });
      EquipmentRankingService.getEquipmentCategory().then((result) => {
        if (result && result.data) {
          setEquipmentCategory(
            result.data.map((d) => {
              return {
                id: d.equipment_category_id,
                code: d.account_code,
                name: d.name_category,
                desc: d.desc_category,
              };
            })
          );
        }
      });
    }
  }, []);
  return (
    <MainContainer {...headerDataImmo}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label class="aligneleft">Categories{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Categories
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Categories"
                value={form.equipment_category_id}
                onFocus={handleSelect}
                name="equipment_category_id"
                onChange={handleChange}
              >
                {equipment_category.map((row) => (
                  <MenuItem value={row.id}>{row.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {ecIsEmpty && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Ce champ est obligatoire!</Alert>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <label class="aligneleft">Nom{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Nom"
              variant="outlined"
              value={form.equipment_ranking_name}
              onChange={handleChange}
              name="equipment_ranking_name"
            />
            {nameIsEmpty && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Ce champ est obligatoire!</Alert>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <label class="aligneleft">Code{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Code"
              variant="outlined"
              value={form.equipment_ranking_code}
              onChange={handleChange}
              name="equipment_ranking_code"
            />
            {codeIsEmpty && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Ce champ est obligatoire!</Alert>
              </>
            )}
          </Grid>

          <Grid item xs={4}>
            <label class="aligneleft">Description</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Description"
              variant="outlined"
              value={form.equipment_ranking_desc}
              onChange={handleChange}
              name="equipment_ranking_desc"
            />
          </Grid>
          <Grid item xs={4}>
            <label class="aligneleft">Configuration</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Configuration
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Configuration"
                size="small"
                defaultValue={{ label: "Couleur", value: "Couleur" }}
                multiple
                onFocus={handleSelect2}
                onChange={handleChange}
                value={form.equipment_config}
                name="equipment_config"
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={getItemLabel(value)}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
              >
                {config.map((row) => (
                  <MenuItem value={row.equipment_config_id}>
                    <Checkbox
                      checked={form.equipment_config.includes(
                        row.equipment_config_id
                      )}
                    />

                    <ListItemText primary={row.configuration} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}
export default EquipmentRankingAdd;
