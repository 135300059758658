import { TextField, FormControl } from "@material-ui/core";

export default function TextColumnFilter({
    // column,
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    return (
        <FormControl
            fullWidth
            size="small"
        >
            <TextField
                type="text"
                id="outlined-basic"
                variant="outlined"
                size="small"
                onChange={e => {
                    setFilter(e.target.value)
                }}
            />
        </FormControl>
    )
}