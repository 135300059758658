import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MainContainer from '../../components/MainContainer';
import { headerData } from '../../data/modules/dashboard-header';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { AntTab, AntTabs } from '../../components/ant-tab';
import AppBar from "@material-ui/core/AppBar";
import Spacer from "../../components/Spacer";
import ListLeavesInDay from "./ListLeavesInDay";
import ListLeavesPlanning from './ListLeavesPlanning';
import ListLeaveReal from "./ListLeaveReal";
import MonthlyReport from "./MonthlyReport";
import LeavePerSemester from "./LeavePerSemester";
import PlanningPerSemester from "./PlanningPerSemester";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    elevation: 0
  },
  paperLeaveInDay:{
    
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    elevation: 0
  },
  tab1:{
    width: "40%"
  }
}));

export default function Dashboard() {

  const classes = useStyles()
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <MainContainer {...headerData}>
      <Grid container xs={12}>
        <AppBar position="static" color="default">
          <AntTabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
            <AntTab disableRipple label="Congé du jour" className={classes.tab1} />
            <AntTab label="Rapport d'activité mensuel" className={classes.tab1} />
            <AntTab label="Suivi congé par semestre" className={classes.tab1} />
            <AntTab label="Suivi planning par semestre" className={classes.tab1} />
            <AntTab label="Liste congé réel entre deux dates" className={classes.tab1} />
            <AntTab label="Liste congé planifié entre deux dates" className={classes.tab1} />
            {/* <AntTab label="Export état" className={classes.tab1} /> */}
          </AntTabs>
        </AppBar>
      </Grid>
      <Spacer y={2} />
      {value == 0 && <Grid item>
        <Paper className={classes.paperLeaveInDay} variant="outlined">
          <ListLeavesInDay />
        </Paper>
      </Grid>}
      {value == 1 && <Grid item>
        <Paper className={classes.paper} variant="outlined">
          <MonthlyReport/>
        </Paper>
      </Grid>}
      {value == 2 && <Grid item>
        <Paper className={classes.paper} variant="outlined">
          <LeavePerSemester/>
        </Paper>
      </Grid>}
      {value == 3 && <Grid item>
        <Paper className={classes.paper} variant="outlined">
          <PlanningPerSemester/>
        </Paper>
      </Grid>}
      {value == 4 && <Grid item>
        <Paper className={classes.paper} variant="outlined">
          <ListLeaveReal/>
        </Paper>
      </Grid>}
      {value == 5 && <Grid item>
        <Paper className={classes.paper} variant="outlined">
          <ListLeavesPlanning />
        </Paper>
      </Grid>}
    </MainContainer>
  );
}
