import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../components/Title";
import { DeleteOutlineRounded, CreateTwoTone } from "@material-ui/icons";
import { DataGrid } from '@material-ui/data-grid';
import PersonalSettingService from "../../services/PersonalSettingService";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import Message from "../tools/Message";
import Spacer from "../../components/Spacer";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function PersonalSettingTable() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [id_to_delete, setId_to_delete] = useState(0);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez-vous vraiment supprimer ce champ?',
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    PersonalSettingService.getPersonalSettings().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.personal_config_id })));

        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);
  const handleEdit = (id) => (ev) => {
    navigate("/champ_dynamique/add/" + id);
  };
  const handleDelete = (id) => (ev) => {
    setId_to_delete(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true });
  };

  const columns = [
    { field: 'name_field', headerName: 'Nom du champ', width: 150, },
    { field: 'type_field', headerName: 'Type champ', width: 200, },
    { field: 'required', headerName: 'Obligation', width: 200, },
    { field: 'relationship_name', headerName: 'Affiché dans', width: 200, },
    { field: 'label_choice', headerName: 'Choix possibles', width: 200, },
    {
      field: 'action',
      headerName: 'Actions',
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={handleEdit(params.id)} title="Modifier" ><CreateTwoTone style={{ color: "black" }} /></Button>
            <Button onClick={handleDelete(params.id)} title="Supprimer"><DeleteOutlineRounded style={{ color: "red" }} /></Button>
          </>
        );
      },
      width: 189,
    },
  ];

  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    PersonalSettingService.deletePersonalSetting(id_to_delete).then((result) => {
      PersonalSettingService.getPersonalSettings().then((result) => {
        if (result && result.data) {
          setRows(result.data.map((row) => ({ ...row, id: row.personal_config_id })));
        }
      });
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
    });
  }
  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  return (
    <React.Fragment>
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Title>Listes des Champs Dynamiques</Title>
      <Spacer y={2} />
      <div style={{ height: "70vh", width: '100%' }}>
        <DataGrid
          rows={rows} columns={columns}
        />
      </div>
    </React.Fragment>
  );
}
