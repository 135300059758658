import { Grid } from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import Spacer from "../../components/Spacer";
import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { headerDataImmo } from "../../data/modules/logistic-header";
import EquipmentTable from "./EquipmentTable";
import { useNavigate } from 'react-router-dom';
export default function EquipmentList() {
  const navigate = useNavigate();
  function handleClick() {
    navigate("/Logistic/materiels/add");
  }
  return (
    <MainContainer {...headerDataImmo}>
      <Grid container item xs={12}>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          CREER
                </Button>
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <EquipmentTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  )
}