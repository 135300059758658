import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataAdmin } from "../../data/modules/admin-header";
import PrivilegeService from "../../services/PrivilegeService";
import { hasPrivilege } from "../../utils/privilege";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";
import Message from "../tools/Message";

import Box from '@mui/material/Box';
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

function PrivilegeAdd(props) {
  const [failedLogin, setFailedLogin] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();

  const [modules, setModules] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;


  const params = useParams();
  const idPrivilege = params.id ? params.id : "";
  const [form, setForm] = useState({
    name: "",
    desc: "",
    module_id: [],
  });

  function handleClick() {
    PrivilegeService.savePrivilege(
      form.name,
      form.desc,
      form.module_id,
      idPrivilege
    )
      .then((res) => {
        var responseErrorMessage = res.message;
        if (res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
          navigate("/privileges");
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: responseErrorMessage });
        }
      })
      .catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
      });

  }
  function handleChange(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
  }
  // function handleSelect() {
  //   PrivilegeService.getModules().then((result) => {
  //     if (result && result.data) {
  //       setModules(
  //         result.data.map((d) => {
  //           return { id: d.module_id, label: d.name, desc: d.desc };
  //         })
  //       );

  //     }
  //   });
  // }
  useEffect(() => {
    if (idPrivilege) {
      console.log("this ID", idPrivilege);
      PrivilegeService.PrivilegeById(idPrivilege).then((info) => {
        console.log("this", info.data);
        setForm({
          name: info.data.name,
          desc: info.data.desc,

          module_id: info.data.module.module_id,
          id: idPrivilege,
        });
      });
    };

    PrivilegeService.getModules().then((result) => {
      if (result && result.data) {
        setModules(
          result.data.map((d) => {
            return { id: d.module_id, label: d.name, desc: d.desc };
          })
        );

      }
    });
  }, []);
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  const contextData = useContext(userDetailContext);
  function handleCancel() {
    navigate("/privileges");
  }
  return (
    <MainContainer {...headerDataAdmin}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        {/* {hasPrivilege(contextData.privileges, 'Admin-Button-Save-Privilege') &&
      Admin-Privilege-List */}
        {hasPrivilege(contextData.privileges, 'Admin-Privilege-List') &&
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            ENREGISTRER
          </Button>
        }

        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
        {failedLogin && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>

          <Grid item xs={4}>
            <label class="aligneleft">Module{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Module
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Module"
                value={form.module_id}
                // onFocus={handleSelect}
                name="module_id"
                onChange={handleChange}
              >
                {modules.map((row) => (
                  <MenuItem value={row.id}>{row.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <label class="aligneleft">Description{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Description"
              variant="outlined"
              value={form.desc}
              onChange={handleChange}
              name="desc"
            />
          </Grid>

          <Grid item xs={4}>
            <label class="aligneleft">Code{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Code"
              variant="outlined"
              value={form.name}
              name="name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <label className={classes.text}>({bullRed}):Champs obligatoires</label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}
export default PrivilegeAdd;
