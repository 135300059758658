
import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import FonctionTable from "./fonctionTable";
import { headerDataAdmin } from "../../data/modules/admin-header";
import { userDetailContext } from "../../App";
import FonctionService from "../../services/FonctionService";
import ExportExcel from "./ExportExcel";
import { hasPrivilege } from "../../utils/privilege";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function FonctionList(props) {


  const navigate = useNavigate();
  const classes = useStyles();
  const contextData = useContext(userDetailContext);
  const [fileName, setFileName] = useState('fonction');
  const [etat, setEtat] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  useEffect(() => {
    FonctionService.getfonction().then((result) => {
      if (result && result.data) {
        setDataToExport(result.data);
      }
    });
  }, []);
  function handleClick() {
    navigate("/fonction/add/");
  }
  function handleCloseDialog() {
    setEtat({
      open: false,
      message: 'message'
    });
  }
  function handelClikImport() {
    setEtat({
      open: true,
    });
  }

  return (
    <MainContainer {...headerDataAdmin}>
      <Grid container item xs={12}>
        {hasPrivilege(contextData.privileges, 'Admin-Button-Import-Fonction') &&
          <Button variant="contained" onClick={handelClikImport} style={{ fontWeight: 600 }}>
            IMPORTER
        </Button>
        }
        <Spacer x={2} />
        {hasPrivilege(contextData.privileges, 'Admin-Button-Export-Fonction') &&

          <ExportExcel dataToExport={dataToExport} fileName={fileName} />
        }
        <Spacer x={2} />
        {hasPrivilege(contextData.privileges, 'Admin-Fonction-Add') &&
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            CREER
        </Button>
        }
        <Spacer x={2} />
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <FonctionTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
