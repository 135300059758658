import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const SeatsService = {

   async getSeatsByDirectionId(id){
    try {
        const result = await axios.get(`${apiUrl}/seats/getSeatsByDirection/${id}` );
        if (result) {
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    }
,
    async getAllSeats(){
        try {
            const result = await axios.get(`${apiUrl}/seats/getAllSeats`  );
            if (result) {
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        }
    ,
}
export default SeatsService;