import React, { useState, useEffect } from "react";
import {
    Button
} from "@material-ui/core";
import Message from "../../tools/Message";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import UserMaternityLeaveService from "../../../services/UserMaternityLeaveService";
import { Navigate, useLocation } from 'react-router-dom';
import {
    CreateTwoTone,
    DeleteOutlineRounded
} from "@material-ui/icons";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from '@material-ui/core/styles';
import MainContainer from "../../../components/MainContainer";
import { headerDataSettings } from "../../../data/modules/settings-header";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useContext } from "react";
import { hasPrivilege } from "../../../utils/privilege";
import Spacer from "../../../components/Spacer";
import { userDetailContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ConfirmationDialog from "../../tools/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        elevation: 0
    },
}));

function UserMaternityLeaveList(props) {
    const params = useParams();
    const idToEdit = params.id;
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
    const [maternityLeaveRow, setMaternityLeaveRow] = React.useState([]);
    const [idToDelete, setIdToDelete] = React.useState(0);
    const classes = useStyles();
    const location = useLocation();
    const contextData = useContext(userDetailContext);
    const navigate = useNavigate();
    
    const [form, setForm] = useState({
        module_type_id: "",
        libelle_module_type: "",
    });
    const [message, setMessage] = React.useState({
        open: false,
        color: 'green',
        messageInfo: '',
    });
    const { open, color, messageInfo } = message;

    const [confirmationDialog, setConfirmationDialog] = React.useState({
        openDialog: false,
        messageDialog: 'voulez vous supprimer?',
    });
    const { openDialog, messageDialog } = confirmationDialog;
    function loadAllMaternityLeave() {

        setOpenLoadingSimpleBackdrop(true);
        UserMaternityLeaveService.getAllMaternityLeave().then((result) => {
            if (result && result.status) {
                console.log("ressss", result.data);
                setMaternityLeaveRow(result.data.map((row) => ({ ...row, id: row.user_maternity_leave_id })));
                setOpenLoadingSimpleBackdrop(false);
            } else {
                setMaternityLeaveRow([]);
                setOpenLoadingSimpleBackdrop(false);
            }
        });
    }

    useEffect(() => {
        loadAllMaternityLeave();

    }, [location]);

    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }

    const handleEditAnnualCalendar = (id) => (ev) => {
        

        navigate(`/maternity_leave/add/${id}`);
    };

    const handleDeleteMaternityLeave = (id) => (ev) => {
        setConfirmationDialog({ ...confirmationDialog, openDialog: true, messageDialog: 'Voulez-vous vraiment le supprimer?' });
        setIdToDelete(id);
    };

    function handleClick() {

        navigate("/maternity_leave/add");
    }

    function closeDialog() {
        setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    }
    function confirmDialog() {
        setConfirmationDialog({ ...confirmationDialog, openDialog: false });
        UserMaternityLeaveService.deleteUserMaternityLeave(idToDelete).then((result) => {
            if (result.status) {
                setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
                loadAllMaternityLeave();
            } else {
                setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
            }
        }).catch((err) => {
            setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        });
    }

    function cancelDialog() {
        setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    }


    const configColumns = [
        {
            field: 'user_name',
            headerName: 'Utilisateur',
            width: 200,
        },
        {
            field: 'start_date',
            headerName: 'Date début',
            width: 350,
        },
        {
            field: 'end_date',
            headerName: 'Date fin',
            width: 350,
        },
        {
            field: 'action',
            headerName: 'Action',
            disableClickEventBulbing: true,
            renderCell: (params) => {
                return (
                    <div>
                        <Button onClick={handleEditAnnualCalendar(params.id)} title="Modifier validateur"><CreateTwoTone style={{ color: "black" }} />
                        </Button>
                        <Button onClick={handleDeleteMaternityLeave(params.id)} title="Supprimer cette configuration">
                            <DeleteOutlineRounded style={{ color: "red" }} />
                        </Button>
                    </div>
                );
            },
            width: 175,
        },
    ];

    return (
        <><MainContainer {...headerDataSettings}>
            <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />

            <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />

            <Grid container item xs={12}>
                <Spacer x={2} />

                {hasPrivilege(contextData.privileges, 'RH_Button_Create_Balance_Configuration') &&
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClick}
                        style={{ fontWeight: 600 }}
                    >
                        CREER
            </Button>
                }

                <Spacer x={2} />
            </Grid>
            <Spacer y={2} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper} variant="outlined">
                        <div style={{ height: "70vh", width: '100%' }} className={classes.root} >
                            <DataGrid
                                rows={maternityLeaveRow}
                                columns={configColumns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                disableSelectionOnClick
                            />
                        </div>

                    </Paper>
                </Grid>
            </Grid>
        </MainContainer>
        </>
    );
}
export default UserMaternityLeaveList;