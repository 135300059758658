import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const BalanceConfigurationService = {
    async findAllConfig(id_dir, id_service) {
        try {
            const result = await axios.get(`${apiUrl}/BalanceConfiguration/findAllBalance/${id_dir}/${id_service}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async addBalance(id_user, real_balance,observation) {
        try {
            const result = await axios.post(`${apiUrl}/BalanceConfiguration/addBalance`, {
                user_id: id_user,
                real_balance: real_balance,
                observation:observation
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async deleteBalanceConfiguration(id_user) {
        try {
            const result = await axios.delete(`${apiUrl}/BalanceConfiguration/deleteBalance/${id_user}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getConfigBalanceByUserId(id_user) {
        try {
            const result = await axios.get(`${apiUrl}/BalanceConfiguration/findBalanceConfigByUserId/${id_user}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updateBalance(id_balance, userId, real_balance) {
        try {
            const result = await axios.put(`${apiUrl}/BalanceConfiguration/updateBalance/${id_balance}`, {
                user_id: userId,
                balance_configuration_id: id_balance,
                real_balance: real_balance
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },

    async getConfigBalanceByUserConnected() {
        try {
            const result = await axios.get(`${apiUrl}/BalanceConfiguration/findBalanceConfigByUserConnected`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },

    async checkIfDateNowIsEqualToCountDayInLeaveType() {
        try {
            const result = await axios.get(`${apiUrl}/BalanceConfiguration/checkIfDateNowIsEqualToCountDayInLeaveType`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },

    async updateBalancePerMonth(leaveTypeData) {
        try {
            const result = await axios.post(`${apiUrl}/BalanceConfiguration/updateBalancePerMonth`, {
                leaveType: leaveTypeData
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },

    async isInitialBalanceConfigurated() {
        try {
            const result = await axios.get(`${apiUrl}/BalanceConfiguration/isInitialBalanceConfigurated`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },


    async getLeaveSummaryBalanceByUserConnected() {
        try {
            const result = await axios.get(`${apiUrl}/BalanceConfiguration/getLeaveSummaryBalanceByUserId`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },

    async getInitialBalanceByUserConnected() {
        try {
            const result = await axios.get(`${apiUrl}/BalanceConfiguration/getInitialBalanceByUserConnected`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },


    /**
     * Function to export summary balance for user connected
     * @param {*} type 
     * @param {*} content 
     * @param {*} initialBalance
     * @param {*} initialBalancedate 
     * @param {*} actualBalance
     * @author Samuela
     * @returns 
     */

    async exportSummaryBalanceByUserConnected(type,content, initialBalance, initialBalancedate, actualBalance) {
        try {
            const result = await axios.post(`${apiUrl}/BalanceConfiguration/exportSummaryBalanceByUserConnected`, {
                initialBalance: initialBalance,
                initialBalancedate: initialBalancedate,
                actualBalance: actualBalance,
                content: content,
                type: type
            });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },

    
    async getLeaveSummaryBalanceBySomeUser(listUser) {
        try {
            const result = await axios.post(`${apiUrl}/BalanceConfiguration/getLeaveSummaryBalanceBySomeUser`, 
            {listUser: listUser});
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },

    
    async getUserConnectedBalanceDetail() {
        try {
            const result = await axios.get(`${apiUrl}/BalanceConfiguration/getUserBalanceDetail`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}

export default BalanceConfigurationService;
