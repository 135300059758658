import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  Box,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Dialog,
  DialogContent,
  IconButton,
  Collapse,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from '@mui/material';
import {Card, CardContent, CardActionArea } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem, InputLabel, Table } from "@material-ui/core";
import { extendMoment } from "moment-range";
import { useNavigate,useParams } from "react-router-dom";
import { userDetailContext } from "../../App";
import Moment from "moment";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import MettingService from "../../services/MettingService";
import MeetingRoomService from "../../services/MeetingRoomService";
import { format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import Message from "../tools/Message";
import MailConfigAddService from "../../services/MailConfigAddService";


// import React, { useState, useEffect } from "react";
import { Menu} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from 'react-router-dom';
import MailListPersonService from "../../services/MailListPersonService";
import { ExpandLess, ExpandMore, FormatBold } from "@material-ui/icons";
import MailHistoryService from "../../services/MailHistoryService";
import MailHistoryPop from "./MailHistoryPop";
import MailHistoryPopService from "../../services/MailHistoryPopService";
import { headerMail } from "../../data/modules/mail-header";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import Spacer from "../../components/Spacer";

const MailHistory = () => {
  
  
  const[history, setHistory] = useState([]);
  const[thisuser, setThisuser] = useState('');
  const[idget, setIdget] = useState(null);
  const[messageget, setMessageget] = useState(null);
  const[subjectget, setSubjectget] = useState(null);
  const[historyDetails, setHistoryDetails] = useState([]);
  
  const [isOpen, setIsOpen] = useState(false);
  
  const[idMail, setIdMail] = useState([]);
  
  
  /////////////////////////////////////////////////RECHERCHEEEE
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
      elevation: 0,
    },
    tab1: {
      width: "50%",
    },
}));
const classes = useStyles();

const [rows, setRows] = useState([]);



////////////////////////////////////////////////////////////

useEffect(() => {
    const fetchData = () => {
      try {
        setThisuser(localStorage.getItem("USER"));
        console.log('THIS USER  :  ' , thisuser);
        console.log('TEST:  ' , localStorage.getItem("USER"));
  
      } catch (error) {
        console.error("Failed to fetch data fa tena misy tsy mety:", error);
      }
    };
  
    fetchData();
  }, []);

useEffect(() => {
  const fetchData = async () => {
    try {
      // const hist = await MailHistoryService.getMailHistoryNoDetails(localStorage.getItem("USER"));
      const hist = await MailHistoryService.getAllHistory(localStorage.getItem("USER"));

      setHistory(hist.data);
      setRows(hist.data.map((row) => ({ ...row, id: row.mailsent_id })));

    } catch (error) {
      console.error("Failed to fetch data fa tena misy tsy mety:", error);
    }
  };

  fetchData();
}, []);



//////////////  DIALOG ///
const [opend, setOpend] = React.useState(false);
const [scroll, setScroll] = React.useState('paper');

const handleClose = () => {
  setOpend(false);
};


const [id, setId]= useState('');
const [files, setFiles] = useState([]);
const [ability, setAbility] = useState('');
const [textbutton, setTextbutton] = useState('');
const [sizeoffiles, setSizeoffiles] = useState('');




const handleClick = async (id, scrollType) => {

  console.log("CLIQUE : ", id);
  setId(id);
  const selectedItem = filteredRows.find(item => item.id === id);

  console.log("SELECTED : ", selectedItem);

  if (selectedItem) {
    await setMessageget(selectedItem.message);
    await setSubjectget(selectedItem.subject);

    const historyDetails = await MailHistoryService.getMailHistoryWithDetails(thisuser, id);
    await setHistoryDetails(historyDetails.data);

    const fs = await MailHistoryService.getFiles(id);
    console.log("FS : ", fs.data);
    setFiles(fs.data);

    // console.log("SIZE : ", fs.data.length);

    

    if (!Array.isArray(files)) {
      setSizeoffiles(0);
      setFiles([]);
      // setAbility("false");
      // setTextbutton("AUCUN FICHIER");
    } else {
      if (fs.data && Array.isArray(fs.data)) {
        if (fs.data.length > 0) {
          setAbility(true);
          setTextbutton("TELECHARGER LES FICHIERS");
        } else {
          setAbility(false);
          setTextbutton("AUCUN FICHIER");
        }
      } else {
        setSizeoffiles(0);
        setFiles([]);
        setAbility(false);
        setTextbutton("AUCUN FICHIER");
      }
    }
        // if(sizeoffiles > 0){
        //   setAbility("false");
        //   setTextbutton("TELECHARGER LES FICHIERS");
        // }else if(sizeoffiles == 0){
        //   setAbility("true");
        //   setTextbutton("AUCUN FICHIER");
        // }


    // console.log("Détails : ", historyDetails.data);

    await setOpend(true);
    setScroll(scrollType);
  } else {
    console.error("Élément non trouvé dans modifiedHistoryArray");
  }
};


    const handleChangeColor = (event) => {
      event.target.style.backgroundColor = '#f0f0f0';
    };
  
    const handleResetColor = (event) => {
      event.target.style.backgroundColor = ''; 
    };
  
  
  /////////////////////////DATA TABLE///////






  // const columns =  [
  //   { field: 'mailsent_id', headerName: 'Mail_id', width: 150, textAlign: "center" },
  //   { field: 'subject', headerName: 'Subject', width: 150 },
  //   { field: 'message', headerName: 'Message', width: 250 },
  //   { field: 'noms', headerName: 'Nom des destinataires', width: 250},
  //   { field: 'mails', headerName: 'Destinataires Mails', width: 250},
  //   { field: 'message', headerName: 'Message', width: 250 },
  //   { field: 'date', headerName: 'Date', width: 150, valueFormatter: (params) => {
  //     return `${moment(params.row.date).format('DD/MM/YYYY')}`;
  //     }, 
  //   }
  // ];
  
  // const modifiedHistoryArray = Array.isArray(history)
  // ? history.map((item, index) => ({
  //   ...item,
  //   id: item.id || index,
  // }))
  // : [];






  /////////////////RECHERCHE
  const [valeurSujet, setValeurSujet] = useState('');
  const [valeurNom, setValeurNom] = useState('');
  const [valeurMail, setValeurMail] = useState('');
  const [valeurDate, setValeurDate] = useState('');

  const valueDateFormatter = (date) => {

    return `${moment(date).format('MM/DD/YYYY')}`;
  }

  const [state, setState] = useState({
    sujet: '',
    nom: '',
    mail: '',
    date: ''
  });

  const handleChangesSujet = (e) => {
    setState({ ...state, sujet: e.target.value });
    setValeurSujet(e.target.value);
    console.log("Valeur : ", valeurSujet);
    console.log("State : ", state);

  };

  const handleChangesNom = (e) => {
    setState({ ...state, nom: e.target.value });
    setValeurNom(e.target.value);
    console.log("Valeur : ", valeurNom);
    console.log("State : ", state);
  };

  const handleChangesMail = (e) => {
    setState({ ...state, mail: e.target.value });
    setValeurMail(e.target.value);
    console.log("Valeur : ", valeurMail);
    console.log("State : ", state);
  };

  const handleChangesDate = (e) => {
    setState({ ...state, date: e.target.value });
    setValeurDate(e.target.value);
    console.log("Valeur : ", valeurDate);
    console.log("State : ", state);
  };

  const handledownload = async (event) => {
     
      try {
        console.log("ITO LE IDD : ", id);
        const zipData = await  MailHistoryService.exportPj(id);;

        // URL pour le fichier ZIP
        const blob = new Blob([zipData], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);

        // élément <a> pour déclencher le téléchargement
        const link = document.createElement('a');
        link.href = url;
        link.download = `mail_${subjectget}_file.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error handling download:', error);
    }
  };



  const filteredRows = rows.filter((row) =>
  row.subject.toLowerCase().includes(valeurSujet)&&
  row.noms.toLowerCase().includes(valeurNom) &&
  row.mails.toLowerCase().includes(valeurMail) &&
  // // row.date.toLowerCase().includes(valeurDate.toLowerCase())
  row.date.includes(valeurDate)
  );

  console.log("BOUCLEE : ", filteredRows.map(f => f.subject));
    


  return (
    <MainContainer {...headerMail}>
      <React.Fragment>
      <Dialog
        open={opend}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title"> {subjectget} </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>    
          <DialogContentText  dangerouslySetInnerHTML={{ __html: messageget }}></DialogContentText>
            <Grid item xs={12}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <label>Envoyé à : </label>
                <IconButton edge="start" onClick={() => setIsOpen(!isOpen)} style={{ alignSelf: "center"}}>
                  {!isOpen ? <ExpandMore /> : <ExpandLess />}
                </IconButton>
              </div>
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Grid container>
                  <Grid item xs={12}>
                    {historyDetails.map((item, index) => (
                      <p key={index}> <h7> {item.first_name_receiver} {item.last_name_receiver} : </h7>{item.personnal_email_receiver}, </p>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Button variant="contained" disabled={!ability} onClick={handledownload}>{textbutton}</Button> */}
                    {(() => {
                      if (textbutton === "TELECHARGER LES FICHIERS") {
                        return (
                          <Button variant="contained" onClick={handledownload}>
                            {textbutton}
                          </Button>
                        );
                      } else {
                        return (
                          <Button variant="contained" disabled onClick={handledownload}>
                            {textbutton}
                          </Button>
                        );
                      }
                    })()}
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>


       <Container sx={{ width: '900px' }}>
            {/* <Box component="form" mt={2}> */}
          <Box component="form" mt={2} sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
              <Grid container spacing={3}>
                  <Grid item xs={12}>
                      <Typography variant="h4" gutterBottom textAlign={"center"}>
                      Historique Mail
                      </Typography>
                  </Grid>
              </Grid>
          </Box>

          {/* //////////////////////////////////////// */}
          <Box>
          <Grid container xs={12}>
            <Grid container xs={12} spacing={2}>
              {/* Première ligne */}
              <Grid item xs={6} sm={6} md={6}>
                <Grid item xs={12} sm={12} md={12}>
                  <label className={classes.labelPosition}>Sujet:</label>
                  <Spacer y={0.2}></Spacer>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    // defaultValue={setValeurChamps('sujet')}
                    value={valeurSujet}
                    name="sujet"
                    type="text"
                    onChange={handleChangesSujet}
                    // onChange={(e) => setValeurSujet(e.target.value)}
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Grid item xs={12} sm={12} md={12}>
                  <label className={classes.labelPosition}>Nom:</label>
                  <Spacer y={0.2}></Spacer>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    // defaultValue={setValeurChamps('nom')}
                    value={valeurNom}
                    name="nom"
                    type="text"
                    onChange={handleChangesNom}
                    // onChange={(e) => setValeurNom(e.target.value)}
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <Grid item xs={12} sm={12} md={12}>
                  <label className={classes.labelPosition}>Mail:</label>
                  <Spacer y={0.2}></Spacer>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    // defaultValue={setValeurChamps('mail')}
                    value={valeurMail}
                    name="mail"
                    type="text"
                    // onChange={handleChangesMail}
                    onChange={(e) => setValeurMail(e.target.value)}
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Grid item xs={12} sm={12} md={12}>
                  <label className={classes.labelPosition}>Date:</label>
                  <Spacer y={0.2}></Spacer>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    // defaultValue={setValeurChamps('date')}
                    value={valeurDate}
                    name="date"
                    type="date"
                    // onChange={handleChangesDate}
                    onChange={(e) => setValeurDate(e.target.value)}

                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
   
          {/* //////////////////////////////////// */}
          <Box mt={4}>
            <Grid container spacing={1}>
              <div style={{ height: 600, width: '100%', overflowY: 'auto', }}>
                {/* <DataGrid
          
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  onRowClick={(params) => handleClick(params.row, params.id)}
                  disableSelectionOnClick
                /> */}

                <Table>
                  <TableHead style={{textAlign: 'center'}}>
                    <TableRow>
                      <TableCell>Mailsent ID</TableCell>
                      <TableCell>Sujet</TableCell>
                      <TableCell>Message</TableCell>
                      <TableCell>Nom</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows.map((row) => (
                      <TableRow 
                      key={row.id} 
                      onClick={() => handleClick(row.id)}
                      style={{
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: '#f0f0f0',
                          cursor: 'pointer'
                        }
                      }}
                      >
                        <TableCell>{row.mailsent_id}</TableCell>
                        <TableCell style={{width : "200px"}}>{row.subject}</TableCell>
                        <TableCell dangerouslySetInnerHTML={{ __html: row.message }} style={{width : "300px"}}></TableCell>
                        <TableCell>{row.noms}</TableCell>
                        <TableCell>{row.mails}</TableCell>
                        <TableCell>{valueDateFormatter(row.date)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

              </div>
            </Grid>
          </Box>
        </Container>
    </MainContainer>
  );




};

export default MailHistory;
