import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const FileService = {
  async add(
    name,
    upload_time,
    filename,
    uploader_user_id,
    desc
  ) {

    try {
      const result = await axios.post(`${apiUrl}/File/`, {
        name,
        upload_time,
        filename,
        uploader_user_id,
        desc
      });
      if (result) {
        // TODO: replace result.data.message by the token
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async saveFile(
    name,
    upload_time,
    filename,
    uploader_user_id,
    desc,
    id
  ) {
    try {
      const result = id
        ? await axios.put(`${apiUrl}/File/id/${id}`, {
          name,
          upload_time,
          filename,
          uploader_user_id,
          desc,
        })
        : await axios.post(`${apiUrl}/File/add/`, {
          name,
          upload_time,
          filename,
          uploader_user_id,
          desc,
        });
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
// utilis� dans cong�
  async generateFile(id) {
    try {
     
    const result = await axios.get(`${apiUrl}/File/generatePDF/${id}`);
  
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data);
      }
      throw error;
    }
  },

  async getUser() {
    try {
      const result = await axios.get(`${apiUrl}/User/getById`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getFile() {
    try {
      const result = await axios.get(`${apiUrl}/File/`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getFileById(id) {
    try {
      const result = await axios.get(`${apiUrl}/file/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async deleteFile(filename) {
    try {
      const result = await axios.delete(`${apiUrl}/file/deleteFile/${filename}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async readFile(filename) {
    try {
      const result = await axios.get(`${apiUrl}/File/readFile/${filename}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        }});
      if (result) {
        // TODO: replace result.data.message by the token

        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async readFileContent(path, filename) {
    try {
      const result = await axios.get(`${apiUrl}/file/read/${path}/${filename}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        }});
      if (result) {
        // TODO: replace result.data.message by the token
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getFilenameById(fileId) {
    try {
      const result = await axios.get(`${apiUrl}/File/id/${fileId}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getPdfNumberPage(folderName, pdfFileName) {
    try {
      const result = await axios.get(`${apiUrl}/file/pageNumber/${folderName}/${pdfFileName}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async readModeleImmo() {
    try {
      const result = await axios.get(`${apiUrl}/file/readModelImmo`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/vnd.ms-excel'
        }});
      if (result) {
        // TODO: replace result.data.message by the token
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async readExcelFileContent(path, filename) {
    try {
      const result = await axios.get(`${apiUrl}/file/readExcelFile/${path}/${filename}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        }});
      if (result) {
        // TODO: replace result.data.message by the token
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async readUserManualFile() {
    try {
      const result = await axios.get(`${apiUrl}/file/readUserManualFile`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        }});
      if (result) {
        // TODO: replace result.data.message by the token
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async readVcfFile(filename) {
    try {
      const result = await axios.get(`${apiUrl}/file/readVcfFile/${filename}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'text/x-vcard'
        }});
      if (result) {
        // TODO: replace result.data.message by the token
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async downloadPGIMobile() {
    try {
      const result = await axios.get(`${apiUrl}/file/downloadPGIMobile`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/vnd.android.package-archive'
        }});
      if (result) {
        // TODO: replace result.data.message by the token
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
};

export default FileService;
