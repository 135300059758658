import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const AddressService = {
    async getProvince(){
        try{
            const result = await axios.get(`${apiUrl}/address/province`);
            if (result) {
              return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getRegion(id_direction){
        try{
            const result = await axios.get(`${apiUrl}/address/region/${id_direction}`);
            if (result) {
              return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getDistrict(id_region){
        try{
            const result = await axios.get(`${apiUrl}/address/district/${id_region}`);
            if (result) {
              return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getCommon(id_district){
        try{
            const result = await axios.get(`${apiUrl}/address/common/${id_district}`);
            if (result) {
              return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getFokontany(id_commune){
        try{
            const result = await axios.get(`${apiUrl}/address/fokontany/${id_commune}`);
            if (result) {
              return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getNomFokontany(id_fokontany){
        try{
            const result = await axios.get(`${apiUrl}/address/fokontany_name/${id_fokontany}`);
            if (result) {
              return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
}

export default AddressService;
