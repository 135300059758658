import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DirectionService from '../services/DirectionService';
import ServiceService from '../services/ServiceService';
import FonctionService from '../services/FonctionService';
import PersonnelService from '../services/PersonnelService';

const useStyles = makeStyles((theme) => ({
    // Vos styles CSS ici
}));

function AutocompleteCombobox({ onPersonIdChange }) {
    const [inputDirectionValue, setInputDirectionValue] = useState('');
    const [selectedDirection, setSelectedDirection] = useState(null);
    const [inputServiceValue, setInputServiceValue] = useState('');
    const [selectedService, setSelectedService] = useState(null);
    const [inputFunctionValue, setInputFunctionValue] = useState('');
    const [selectedFunction, setSelectedFunction] = useState(null);
    const [inputPersonnelValue, setInputPersonnelValue] = useState('');
    const [selectedPersonnel, setSelectedPersonnel] = useState(null);
    const [personId, setPersonId] = useState(null); // Ajout de l'état personId

    const classes = useStyles();
    const [directions, setDirections] = useState([]);
    const [services, setServices] = useState([]);
    const [functions, setFunctions] = useState([]);
    const [personnel, setPersonnel] = useState([]);

    useEffect(() => {
        DirectionService.getDir()
            .then((result) => {
                if (result && result.data) {
                    setDirections(result.data.map((row) => ({ value: row.direction_id, label: row.name })));
                }
            });
    }, []);

    const loadServices = (directionId) => {
        ServiceService.getServById(directionId)
            .then((result) => {
                if (result && result.data) {
                    setServices(result.data.map((row) => ({ value: row.service_id, label: row.name })));
                }
            });
    };

    const loadFunctions = (serviceId) => {
        FonctionService.getById(serviceId)
            .then((result) => {
                if (result && result.data) {
                    setFunctions(result.data.map((row) => ({ value: row.function_id, label: row.name })));
                }
            });
    };

    const loadPersons = (functionId) => {
        PersonnelService.getPerson(functionId)
            .then((result) => {
                if (result && result.data) {
                    setPersonnel(result.data.map((row) => ({
                        value: row.person_id,
                        label: `${row.first_name} ${row.last_name}`,
                    })));
                }
            });
    };

															   
											  
																		  
							  
												
										
				   
							   
			
		 
    const handleDirectionChange = (event, newDirection) => {
        setSelectedDirection(newDirection);
        setInputDirectionValue(newDirection ? newDirection.label : '');
        if (newDirection) {
																						  
            loadServices(newDirection.value);
            setSelectedService(null);
        } else {
            setServices([]);
        }
    };

    const handleServiceChange = (event, newService) => {
        setSelectedService(newService);
        setInputServiceValue(newService ? newService.label : '');
        if (newService) {
            loadFunctions(newService.value);
            setSelectedFunction(null);
        } else {
            setFunctions([]);
        }
    };

    const handleFunctionChange = (event, newFunction) => {
        setSelectedFunction(newFunction);
        setInputFunctionValue(newFunction ? newFunction.label : '');

        if (newFunction) {
            loadPersons(newFunction.value);
            setSelectedPersonnel(null);
            setInputPersonnelValue('');
        } else {
            setPersonnel([]);
        }
    };

    const handlePersonChange = (event, newPerson) => {
        setSelectedPersonnel(newPerson);
        setInputPersonnelValue(newPerson ? newPerson.label : '');
        if (newPerson) {
            setPersonId(newPerson.value); // Mettre à jour l'état personId
            onPersonIdChange(newPerson.value); // Transmettre l'ID de la personne
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <label className={classes.labelPosition}>Directions*</label>
            </Grid>
            <Grid item xs={8}>
                <Autocomplete labelId="demo-simple-select-outlined-label" size="small"
                    options={directions}
                    getOptionLabel={(option) => option.label}
                    value={selectedDirection}
                    inputValue={inputDirectionValue}
                    onInputChange={(event, newInputValue) => setInputDirectionValue(newInputValue)}
                    onChange={handleDirectionChange}
                    renderInput={(params) => <TextField {...params} label="Sélectionnez une direction" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={3}>
                <label className={classes.labelPosition}>Services*</label>
						  
						  
            </Grid>
            <Grid item xs={8}>
                <Autocomplete labelId="demo-simple-select-outlined-label" size="small"
                    options={services}
                    getOptionLabel={(option) => option.label}
                    value={selectedService}
                    inputValue={inputServiceValue}
                    onInputChange={(event, newInputValue) => setInputServiceValue(newInputValue)}
                    onChange={handleServiceChange}
                    renderInput={(params) => <TextField {...params} label="Sélectionnez un service" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={3}>
                <label className={classes.labelPosition}>Fonctions*</label>
            </Grid>
            <Grid item xs={8}>
                <Autocomplete labelId="demo-simple-select-outlined-label" size="small"
                    options={functions}
                    getOptionLabel={(option) => option.label}
                    value={selectedFunction}
                    inputValue={inputFunctionValue}
                    onInputChange={(event, newInputValue) => setInputFunctionValue(newInputValue)}
                    onChange={handleFunctionChange}
                    renderInput={(params) => <TextField {...params} label="Sélectionnez une fonction" variant="outlined" />}
                />
            </Grid>
            <Grid item xs={3}> 
                <label className={classes.labelPosition}>Personnel*</label>
            </Grid>
            <Grid item xs={8}>
                <Autocomplete labelId="demo-simple-select-outlined-label" size="small"
                    options={personnel}
                    getOptionLabel={(option) => option.label}
                    value={selectedPersonnel}
                    inputValue={inputPersonnelValue}
                    onInputChange={(event, newInputValue) => setInputPersonnelValue(newInputValue)}
                    onChange={handlePersonChange}
                    renderInput={(params) => <TextField {...params} label="Sélectionnez un personnel" variant="outlined" />}
                />
            </Grid>
        </Grid>
    );
}

export default AutocompleteCombobox;
