//import React, { Component } from 'react'
//import CssBaseline from '@material-ui/core/CssBaseline'; import Container from '@material-ui/core/Container';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { AppBar, Toolbar } from '@material-ui/core';
import CardPortal from "./CardPortal"
import MainContainer from '../../components/MainContainer';
import { headerData } from '../../data/modules/portal-header';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100%',
    left: '0px',
  },
  welcom: {
    fontSize: '4.5rem',
    justifyContent: 'center',

  },
}));
function Portal() {
  const classes = useStyles();
  return (
    <MainContainer {...headerData}>
      <div className={classes.container}>
        <CardPortal />
      </div>
    </MainContainer>
  )
}

export default Portal;
