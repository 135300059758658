import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    minWidth: 200,
    textAlign: 'center',
  },
  avatar: {
    width: 60,
    height: 60,
    margin: 'auto',
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 8,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: palette.grey[500],
    marginBottom: '0.875em',
  },
  statLabel: {
    fontSize: 12,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
    textDecoration: 'underline',
  },
  statValue: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 4,
    letterSpacing: '1px',
  },
  gridLeft:{
    borderRight:"grey 1px solid"
  },
  containerGrid:{
    padding: "10px"
  }
}));

export default function ProfileCard(props) {

  const info = props.info;
  const apiUrl = process.env.REACT_APP_API_URL;
  const styles = useStyles();
  const shadowStyles = useFadedShadowStyles();
  const borderedGridStyles = useGutterBorderedGridStyles({
    borderColor: 'rgba(0, 0, 0, 0.08)',
    height: '50%',
  });
  return (
    <Card className={cx(styles.card, shadowStyles.root)}>
      <CardContent>
        <Avatar className={styles.avatar} src={info.name_image} />
        <h3 className={styles.heading}>{info.first_name} {info.last_name}</h3>
        <span className={styles.subheader}>{info.function_name}</span>
      </CardContent>
      <Divider light />
      <Grid container justifyContent>
        {/* <Box display={'flex'}> */}

        <Grid container item xs={12} className={styles.containerGrid}>
          <Grid item xs={6} className={styles.gridLeft}>
            {/* <Box p={2} flex={'auto'} className={borderedGridStyles.item}> */}
            <p className={styles.statLabel}>Direction</p>
            <p className={styles.statValue}>{info.direction_code}</p>
            {/* </Box> */}
          </Grid>
          {/* <Box p={2} flex={'auto'} className={borderedGridStyles.item}> */}

          <Grid item xs={6} >
            <p className={styles.statLabel}>Service</p>
            <p className={styles.statValue}>{info.service_code}</p>
          </Grid>
          {/* </Box> */}
        </Grid>
        {/* </Box> */}
        <Grid container item xs={12} className={styles.containerGrid}>
          {/* <Box display={'flex'}> */}
          {/* <Box p={2} flex={'auto'} className={borderedGridStyles.item}> */}
          <Grid item xs={6} className={styles.gridLeft}>
            <p className={styles.statLabel}>Date de départ</p>
            <p className={styles.statValue}>{info.date_start}</p>
          </Grid>
          {/* </Box> */}
          {/* <Box p={2} flex={'auto'} className={borderedGridStyles.item}> */}
          <Grid item xs={6}>
            <p className={styles.statLabel}>Date de retour</p>
            <p className={styles.statValue}>{info.date_end}</p>
          </Grid>
          {/* </Box> */}
          {/* </Box> */}
        </Grid>
      </Grid>
    </Card>
  );
};


