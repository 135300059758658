export const headerDataMission = {
    title: 'Mission',
    submodules: [
        createData(0, "Planification", "/mission/planning_mission"),
        createData(1, "Demande d'appui", "/mission/demande"),
        createData(2, "Elaboration TDR", "/mission/elaboration_tdr"),
    ],
}

function createData(id, moduleName, ref) {
    return { id, moduleName, ref };
}