import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import FileValidationRequestService from "../../../services/FileValidationRequestService";
import { FindInPageTwoTone } from "@material-ui/icons";
import { DataGrid } from "@material-ui/data-grid";
import { escapeRegExp } from "../../../utils";
import { Done, Clear } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import RequestSummary from "../../tool-accepted/RequestSummary";
import { useNavigate } from "react-router-dom";
import LoadingSimpleBackdrop from "../../tool-accepted/LoadingSimpleBackdrop";
import { makeStyles } from "@material-ui/core/styles";
import Title from "../../../components/Title";
import { createTheme } from "@material-ui/core/styles";
import AcceptedHelpService from "../../../services/AcceptedHelpService";
const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => {
    const backgroundColor =
      theme.palette.mode === 'dark' ? '#376331' : '#FFFF62';
    return {
      root: {
        '& .MuiDataGrid-cell--editable': {
          backgroundColor,
        },
      },
    };
  },
  { defaultTheme },
);
function MyValidation(props) {
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [requestSummaryId, setRequestSummaryId] = useState({});
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [openDialogRequestSummary, setOpenDialogRequestSummary] = React.useState(false);
  const [isLoading, setLoading] = useState(false);

  const [message, setMessage] = React.useState({
    openMessage: false,
    color: "green",
    messageInfo: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  useEffect(() => {
    console.log("aaa45aaaaa");


    setOpenLoadingSimpleBackdrop(true);
    AcceptedHelpService.getAll().then((result) => {
      console.log("***************");
      console.log(result);
      if (result && result.data) {
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.id,
          }))
        );

        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });

  }, []);

  const validateFile = (row) => () => {
    console.log("ieruieruieruieurieurieuirueir");
    console.log(row.mission_request_id);
    setRequestSummaryId(row);
    setOpenDialogRequestSummary(true);
    setOpenDialog(true);
  }


  const handleAnnuler = (row) => () => {
    console.log("Annuler");
  }



  function getFullName(params) {
    return `${params.getValue(params.id, "submitter_person_first_name") || ""
      } ${params.getValue(params.id, "submitter_person_last_name") || ""}`;
  }
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = [...rows].filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
  };
  const columns = [
    // {
    //   field: 'number',
    //   headerName: 'N°',
    //   width: 250,
    // },
    {
      field: 'type_mission_request',
      headerName: 'Type de module',
      width: 250,
    },
    {
      field: 'reason',
      headerName: 'Raison',
      width: 250,
    },
    {
      field: 'name',
      headerName: 'Nom du demandeur',
      width: 250,
    },
    {
      field: 'date',
      headerName: 'Date désiré',
      width: 250,
    },
    {
      field: 'number_day',
      headerName: 'Nombre(s) de jour(s)',
      width: 250,
    },
    {
      field: "type_mission_request",
      headerName: "Type",
      width: 200,
      editable: true,
    },
    {
      field: "mission_request_user_required_state",
      headerName: "Status",
      width: 200,
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <span>
          {params.row.mission_request_user_required_state !== "validée" &&
            <Button
              onClick={validateFile(params.row)}
              title="Passer à la validation"
            >
              <Done style={{ color: "green" }} />
            </Button>
          }

          {params.row.mission_request_user_required_state === "validée" &&
            <Button onClick={handleAnnuler(params.id)} title="Annulation de la demande">
              <CloseIcon style={{ color: "red" }} />
            </Button>
          }
        </span>
      ),
      editable: true,
    },
  ];
  const handleCloseRequestSummary = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setOpenDialogRequestSummary(false);
    window.location.reload(false);

  }
  return (
    <React.Fragment>
      {openDialog &&
        <RequestSummary
          requestSummaryId={requestSummaryId} openDialogRequestSummary={openDialogRequestSummary} handleCloseRequestSummary={handleCloseRequestSummary} />}

      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Listes des demandes d'acceptations</Title>
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          className={classes.root}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
          isCellEditable={(params) => params.row.is_priority == "1"}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },

          }}
        />
      </div>
    </React.Fragment>
  );
}
export default MyValidation;

