import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const DirectionService = {
  async saveDirection(type, name, code, desc, d_id, id) {
    try {
      const result = id ?
        await axios.put(`${apiUrl}/direction/${id}`, {
          id: id,
          type: type,
          name: name,
          code: code,
          description: desc,
          direction_parent_id: parseInt(d_id)
        })
        :
        await axios.post(`${apiUrl}/direction`, {
          type: type,
          name: name,
          code: code,
          description: desc,
          direction_parent_id: parseInt(d_id)
        });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async directionById(id) {
    try {
      const result = await axios.get(`${apiUrl}/direction/id/${id}`);
      if (result) {
        // console.log("result ", result);
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async getDirection() {
    try {
      const result = await axios.get(`${apiUrl}/direction/`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async getWithDirection() {
    try {
      const result = await axios.get(`${apiUrl}/direction`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,

  async deleteDirection(id) {
    try {
      const result = await axios.delete(`${apiUrl}/direction/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getDirection() {
    try {
      const result = await axios.get(`${apiUrl}/direction/`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,


  async getService() {
    try {
      const result = await axios.get(`${apiUrl}/direction//export/`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async importDirection() {
    try {
      const result = await axios.post(`${apiUrl}/direction/import/`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },
  async getDir() {
    try {
        const result = await axios.get(`${apiUrl}/direction/index2`);
        if (result) {
            // TODO: replace result.data.message by the token

            return result.data;
        }
    }
    catch (error) {
        if (error.response) {
            return error.response.data;
        }
    }
},

}

export default DirectionService;