
import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import SeatTable from "./SeatTable";
import { headerDataAdmin } from "../../data/modules/admin-header";
import { userDetailContext } from "../../App";
import DirectionService from "../../services/DirectionService";
import { hasPrivilege } from "../../utils/privilege";
import { useNavigate } from 'react-router-dom';
import SeatService from "../../services/SeatService";



const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function SeatList(props) {


  const navigate = useNavigate();
  const classes = useStyles();
  const contextData = useContext(userDetailContext);
  // const [etat, setEtat] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  useEffect(() => {
    SeatService.getAllSeat().then((result) => {
      if (result && result.data) {
        setDataToExport(result.data);
      }
    });
  }, []);
  function handleClick() {
    navigate("/seat/add/");
  }
  // function handleCloseDialog() {
  //   setEtat({
  //     open: false,
  //     message: 'message'
  //   });
  // }
  // function handelClikImport() {
  //   setEtat({
  //     open: true,
  //   });
  // }

  return (
    <MainContainer {...headerDataAdmin}>
      <Grid container item xs={12}>
        {/* {hasPrivilege(contextData.privileges, 'Admin-Button-Import-Direction') &&
        <Button variant="contained" onClick={handelClikImport} style={{ fontWeight: 600 }}>
              IMPORTER
        </Button>
} */}
        {/* <ImportCSV isActive={etat.open} closeDialog={handleCloseDialog} />

        <Spacer x={2} /> */}
        {/* {hasPrivilege(contextData.privileges, 'Admin-Button-Export-Direction') &&

        <ExportExcel dataToExport={dataToExport} fileName={fileName} />
} */}
        <Spacer x={2} />
        {hasPrivilege(contextData.privileges, 'Admin-Direction-Add') &&
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            CREER
          </Button>
        }
        <Spacer x={2} />
        {/* <TextField
          id="outlined-basic" 
          label="Recherche"
          variant="outlined"
          size="small"
        /> */}
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            < SeatTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
