import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../components/Title";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import DirectionService from "../../services/DirectionService";
import {DataGrid, GridToolbarContainer,GridToolbarExport} from '@material-ui/data-grid';
import Message from "../personnel/Message";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import Result from "../personnel/Result";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';
import BuildingService from "../../services/BuildingService";
import SeatService from "../../services/SeatService";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  textField: {
    float:'rigth',
    width:'20%',
  },
  select:{
    width:'20%',
  },
}));

export default function SeatTable(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [etat, setEtat] = useState({open:false, message:"message",accept:false});
  const [etats, setEtats] = useState({open:false, message:"message",color:""});
  const [isid,setIsid]=useState(0);
  const [message, setMessage] = React.useState({
    openMessage: false,
    color: 'green',
    messageInfo: '',
  });

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  

  const handleDelete = (id) => (ev) => {
    setEtat({
      open: true,
      message: "Voulez vous vraiment supprimer  ?"
    });
    setIsid(id);
  };
  function handleMessage() {
    setEtat({
      open: false,
      message: ""
    });
  }
  
  function  handleAccept () {
    setEtat({
              open: false,
              message: "",
              accept:true
            }); 
            SeatService.deleteSeat(isid).then((results) => {
              console.log(isid + '**********');
               SeatService.getAllSeat().then((result) => {
                      if (result && result.data) {
                        setRows(result.data.map((row)=>({...row, id:row.seat_id})));
                      }
                });
        if(results.status)
        {
          setEtats({ ...etats, open: true, color:'green',message:results.message });
          navigate("/seat");
        }
        else
        {
          setEtats({ ...etats, open: true, color:'red',message:results.message });
        }
      });
  }

  const handleCloseMessage = () => {
    setEtats({ ...etats, open: false });
  };
  const handleEdit = (id) => (ev) => {
    navigate("/seat/add/" + id);
  };

const columns = [
  {
    field: 'direction',
    headerName: 'Direction',
    width: 150,
  },
  {
    field: 'seat',
    headerName: 'Bureau',
    width: 350,
  },
  { 
  field:'action',
  headerName: 'Action', 
  disableClickEventBulbing:true,
  renderCell:(params)=>{
    return(
      <div>
     <Button onClick={handleEdit(params.id)} title="Modifier un privilège"><CreateTwoTone style={{ color: "black" }} />
            </Button>
      <Button onClick={handleDelete(params.id)}title="Supprimer">
      <DeleteOutlineRounded style={{ color: "red" }} />
    </Button>
    </div>
    );
  },
  width: 200 ,
  },
];

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    SeatService.getAllSeat().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row)=>({...row, id:row.seat_id})));
        setOpenLoadingSimpleBackdrop(false);
      }else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);


  return (
    <React.Fragment>
      <Result message={etats.message} color={etats.color} open={etats.open} handleClose={handleCloseMessage}/>
      <Message isActive={etat.open} message={etat.message} accept={etat.accept} handleClose={handleMessage} handleConfirm={handleAccept}/>
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Listes des Bureaux</Title>
      <div style={{  height: 400,width: '100%' }} className={classes.root}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row.seat_id}
        />
    </div>
    </React.Fragment>
  );
}
