import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FileValidationRequestService from "../../../services/FileValidationRequestService";
import LoadingSimpleBackdrop from "../../tool-accepted/LoadingSimpleBackdrop";

import { DataGrid } from "@material-ui/data-grid";
import { escapeRegExp } from "../../../utils";
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",

  },
}));

export default function ParticipatedValidation() {
  
  const classes = useStyles();
  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  
  const [searchText, setSearchText] = React.useState("");

  // const classes = useStyles();
  const [rows, setRows] = useState([]);
  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    FileValidationRequestService.getParticipatedDemands().then((result) => {
      // console.log("result",result.data);
      if (result && result.data) {
        // setRows(result.data)
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.file_validation_request_id,
          }))
        );

        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);

  
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = [...rows].filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
  };

  const columns = [
    {
      field: "submission_time",
      headerName: "Date de l'action",
      width: 250,
      editable: true,
    },
    {
      field: "submitter_person_first_name",
      headerName: "Demandeur",
      width: 350,
    },
    {
      field: "libelle_module_type",
      headerName: "Type",
      width: 200,
      editable: true,
    },
    {
      field: "validateur",
      headerName: "Status",
      width: 350,
      editable: true,
      valueFormatter: (params) => {
        switch (params.row.state) {
          case 'DECLINED':
              return "Refusé";
          case 'CONFIRMED':
              return "Validé";
      }
      }
    }
  ];
  return (
    <>
      <React.Fragment>
        <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
        <div style={{ height: "70vh", width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={rows}
            className={classes.root}
            pageSize={7}
            rowsPerPageOptions={[7]}
            disableSelectionOnClick
            isCellEditable={(params) => params.row.is_priority == "1"}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(""),
              },

            }}
          />
        </div>
      </React.Fragment>
    </>
  );
}
