import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header"; 
import TDRElaborationTable from "./TDRElaborationTable";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function PlanningMissionList() {
  const classes = useStyles();
  return (
    <MainContainer {...headerDataMission}>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
          <TDRElaborationTable />
        </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
