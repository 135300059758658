import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button ,RadioGroup,
  FormControlLabel,
  Radio} from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from "react";

export default function ExportExcel(props) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const [openDialog, setOpenDialog] = useState(false);
  const [exportChoice, setExportChoice] = useState("0");
  const [dataToExportFinal, setdataToExportFinal] = useState([]);
  const [filename, setFilename] = useState("");
  const [wscolsFinal, setwscolsFinal] = useState("");

  useEffect(()=>{
    setExportChoice("1");
    console.log("dataToExport", props.dataToExport);
  },[props]);
  
  useEffect(()=>{
    
  },[exportChoice]);
  

  function exportToCSV(dataToExport, fileName, wscols) {
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    ws["!cols"] = wscols;
    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  function handleClose(){
    setOpenDialog(false);
  }

  function handleChangeExportChoice(e){
    setExportChoice(e.target.value);
  }

  function handleClickExport(){
    setOpenDialog(true);
  }

  function handleExportFinal(){
    if(exportChoice === "1"){
    console.log("historique***");
    console.log("res****", props.dataToExport);
    setdataToExportFinal(props.dataToExport);
    setFilename(props.filenameHistory);
    setwscolsFinal(props.wscols);
    exportToCSV(props.dataToExport, props.filenameHistory, props.wscols);
  }else{
    
    console.log("détenteur***");
    console.log("res dét****", props.dataDetenteurExport);
    setdataToExportFinal(props.dataDetenteurExport);
    setFilename(props.filenameDetenteur);
    setwscolsFinal(props.wscolsDetenteur);
    exportToCSV(props.dataDetenteurExport, props.filenameDetenteur, props.wscolsDetenteur);
  }
    // exportToCSV(dataToExportFinal, filename, wscolsFinal);
  }
  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickExport}
        // onClick={(e) =>
        //   exportToCSV(props.dataToExport, props.fileName, props.wscols)
        // }
        style={{ fontWeight: 600 }}
        startIcon={<DownloadIcon />}
      >
        EXPORTER
    </Button>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title" style={{ color: 'white', }}>
          {"Choix export"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" >
            
          <RadioGroup aria-label="sex_id" name="sex_id" value={exportChoice} onChange={handleChangeExportChoice}>
            <FormControlLabel value="1" control={<Radio />} label="Historique d'inventaire" />
            <FormControlLabel value="2" control={<Radio />} label="Détenteur immmobilisation" />
          </RadioGroup>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExportFinal} variant="contained" color="primary">
            EXPORTER
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
