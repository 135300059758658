import React, { useState, useEffect } from "react";
import moment from "moment";
import { DataGrid } from "@material-ui/data-grid";
import { Grid, Divider } from "@material-ui/core";
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import ListLeavesinDayTable from "../../screens/dashboard/ListLeavesInDayTable";
import LeaveLastWeekService from "../../services/LeaveLastWeekService";
import Title from "../../components/Title";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import Spacer from "../../components/Spacer";
import UserService from "../../services/UserService";
import {
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";
export default function LeaveLastWeekTable(props) {
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [rows, setRows] = useState([]);
  const [statuus, setStatuus] = useState(0);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [status, setStatus] = useState(1);
  const [valueSelected, setValueSelected] = useState("");
  const [userDr, setUserDr] = useState("");
  const [userDG, setUserDG] = useState("");
  const [showFilter, setShowFilter] = useState("false");
  // function LoadTable() {
  //   setOpenLoadingSimpleBackdrop(true);
  //   LeaveLastWeekService.getLeavesByOptions().then((result) => {
  //     if (result && result.data) {

  //       setRows(result.data.map((row) => ({ ...row, id: row.leave_id, file: row.file_request_id })));
  //       setOpenLoadingSimpleBackdrop(false);
  //     } else {
  //       setOpenLoadingSimpleBackdrop(false);
  //     }

  //   });
  // }

  function LoadTable(value) {
    console.log("atoppp", value);
    if (value == 0) {
      console.log("thjjbj", value);
      setOpenLoadingSimpleBackdrop(true);
      LeaveLastWeekService.getLeavesByOptions().then((result) => {
        if (result && result.data) {
  
          setRows(result.data.map((row) => ({ ...row, id: row.leave_id, file: row.file_request_id })));
          setOpenLoadingSimpleBackdrop(false);
        } else {
          setOpenLoadingSimpleBackdrop(false);
        }
  
      });


    } else {
      console.log("sdsdsds", value);
      LeaveLastWeekService.getLeavesStaffPerLastWeek().then((result) => {
        if (result && result.data) {

          setRows(result.data.map((row) => ({ ...row, id: row.leave_id, file: row.file_request_id })));
          setOpenLoadingSimpleBackdrop(false);
        } else {
          setOpenLoadingSimpleBackdrop(false);
        }

      });
    }
  }
  
  useEffect(() => {
    // LoadTable(valueSelected);
    setShowFilter(false);
    setValueSelected(0);
    
    LeaveLastWeekService.getLeaveType().then((result) => {
      if (result && result.data) {
        setLeaveTypes(
          result.data.map((d) => {
            return { id: d.leave_type_id, label: d.name, desc: d.desc };
          })
        );
      }
    });

    UserService.isUserConnectedDG().then((result) => {
      if (result && result.status) {
        setUserDr(true);
      } else {
        setUserDr(false);
      }
    });

    UserService.isUserConnectedDr().then((result) => {
      if (result && result.status) {
        setUserDG(true);
      } else {
        setUserDG(false);
      }
    });

  }, [props]);

  
  useEffect(() => {
    LoadTable(valueSelected);

  }, [valueSelected]);


  useEffect(() => {
    console.log("eeee", userDr);
    if (userDr) {
      setShowFilter(true);
    }else{
      
      setShowFilter(false);
    }

  }, [userDr]);

  
  useEffect(() => {
    if (userDG) {
      setShowFilter(true);
    }else{
      
      setShowFilter(false);
    }

  }, [userDG]);

  
  function handleChangeValue(e) {
    setValueSelected(e.target.value);
  }

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay();
    var diff = d.getDate() - day + (day == 0 ? -6 : -6);
    return new Date(d.setDate(diff));
  }
  const a = getMonday(new Date())
  console.log(a);

  function getFullName(params) {
    return `${params.getValue(params.id, 'last_name') || ''} ${params.getValue(params.id, 'first_name') || ''
      }`;
  }
  const columns = [


    {
      field: 'fullName',
      headerName: 'Nom et prénom(s)',
      width: 275,
      valueGetter: getFullName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        getFullName(cellParams1).localeCompare(getFullName(cellParams2)),
    },
    { field: "direction_code", headerName: "Direction", width: 200 },
    { field: "service_code", headerName: "Service", width: 200 },
    { field: "function_name", headerName: "Fonctions", width: 200 },
    {
      field: "begin_time",
      headerName: "Date de départ",
      width: 200,
      valueFormatter: (params) => {
        return `${moment(params.row.begin_time).format('DD/MM/YYYY')} ${params.row.begin_time_session === 'morning' ? 'Matin' : 'Après-midi'}`;
      },
    },
    {
      field: "end_time",
      headerName: "Date de retour",
      width: 200,

      valueFormatter: (params) => {
        return `${moment(params.row.end_time).format('DD/MM/YYYY')} ${params.row.end_time_session === 'morning' ? 'Matin' : 'Après-midi'}`;
      },
    },

    { field: "states", headerName: "Validation hiérarchique", width: 200 },


  ]
  return (
    <>
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Les congés de la semaine du {a.getDate()}/{(a.getMonth() + 1).toString()}/{a.getFullYear()} </Title>
      {showFilter &&
        <>
          <Spacer y={2} />
          <Grid container xs={12} justifyContent="center">
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <Select
                  onChange={handleChangeValue}
                  id="demo-simple-select-outlined"
                  value={valueSelected}
                  name="direction_id"
                // onChange={handleChange}
                >
                  <MenuItem value={0}>{"Tous"}</MenuItem>
                  <MenuItem value={1}>{"Staff"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Spacer y={2} />
        </>
      }
      <Grid>
        <div style={{ height: "65vh", width: "100%" }}>
          <DataGrid
            columns={columns ? columns : undefined}

            rows={rows}

            rowsPerPageOptions={[7]}
            pageSize={7}


          />

        </div>

      </Grid>
    </>
  )
}