import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    TextField
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from "@material-ui/lab/Alert";
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        elevation: 0
    },
}));


const bullRed = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
    >
        *
    </Box>
)
export default function PersonnalSpecialValidatorEdit(props) {
    const userName = props.userName;
    const showSuperiorConfig = props.showSuperiorConfig;
    const superiorConfigInput = props.superiorConfigInput;
    const users = props.users;
    const iduser = props.iduser;
    const isEdit = props.isEdit;
    const validatorNotMatch = props.validatorNotMatch;
    const handleValidateEdit = props.handleValidateEdit;
    const handleCloseEdit = props.handleCloseEdit;
    const handleChangeUsers = props.handleChangeUsers;
    const userNameInfosEdit = props.userNameInfosEdit;
    const classes = useStyles();
    
    return (
        <Grid container spacing={2} xs={12}>
            <Grid item xs={4}>
                <label>Utilisateur:</label>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="user"
                    value={userName}
                    disabled
                />
            </Grid>
            <Grid item xs={4}>
                Supérieur hiérarchique spécial en cours:
            </Grid>            
            <Grid item xs={8}>
                <strong> { userNameInfosEdit } </strong>
            </Grid>
            {showSuperiorConfig && superiorConfigInput.map((input) => {
                return (
                    <>
                        <Grid item xs={4}>
                            <label>{input.label}{bullRed}</label>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                onChange={handleChangeUsers}
                                options={users}
                                name={input.name}
                                defaultValue={input.idUser}
                                getOptionLabel={(option) => option.name}
                                
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        name={input.name}
                                        InputProps={{ ...params.InputProps, type: "search" }}
                                    />
                                )}
                            />
                        </Grid>
                    </>
                )
            })}

            <Grid item xs={12}>
                {validatorNotMatch && <Alert severity="error">"Veuillez remplir tous les champs concernants les supérieurs hiérarchiques"</Alert>}
            </Grid>

            <Grid container item xs={12} direction="row-reverse">
                <Button
                    type="submit"
                    style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF" }}
                    onClick={handleValidateEdit}
                    color="primary"
                    variant="contained"
                >
                    Valider
                </Button>
                <Spacer x={2} />
                <Button
                    style={{ backgroundColor: "#f50057", color: "#FFFFFF" }}
                    onClick={handleCloseEdit}
                    variant="contained"
                >
                    Annuler
                </Button>
            </Grid>
        </Grid>
    );
}
