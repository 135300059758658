import React, { useState, useEffect, Fragment } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Spacer from "../../components/Spacer";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import ValidationPlanningService from "../../services/ValidationPlanningService";
import TraceabilityService from "../../services/TraceabilityService";
import { CircularProgress } from "@material-ui/core";
import Box from '@mui/material/Box';
import { Alert, AlertTitle } from '@mui/material';

export default function RefusedPlanning(props) {
  const isRefusedAll = props.isRefusedAll;
  const openDialog = props.openDialog;
  const messageDialog = props.messageDialog;
  const closeDialog = props.closeDialog;
  const confirmDialog = props.confirmDialog;
  const cancelDialog = props.cancelDialog;
  const openRefuseStep1 = props.openRefuseStep1;
  const handleRefuseClose = props.handleRefuseClose;
  //const handleObservation = props.handleObservation;
  const closeDialog4 = props.closeDialog4;
  const closeConfirm = props.closeConfirm;
  const confirmRefusedAll = props.confirmRefusedAll;
  const listPendingPlanning = props.listPendingPlanningId;
  const [form, setForm] = useState({
    observation: '',
  });
  const [isLoadingRefused, setIsLoadingRefused] = useState(false);
  const [isEmptyObservation, setisEmptyObservation] = useState(false);

  // const handleRefuse = props.handleRefuse;
  // useEffect(()=>{

  //   console.log("niova", listPendingPlanning)
  //   if(listPendingPlanning!= undefined && listPendingPlanning.length != 0){

  //     console.log("listPendiiiiiiiiiiii", listPendingPlanning)
  //     setlistPendingPlanningFinal(listPendingPlanning);
  //   }
  // }, props);

  const bullRed = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
    >
      *
    </Box>
  )

  const useStyles = makeStyles((theme) => ({
    contenue: {
      height: "20vh",
    },
    seeMore: {
      marginTop: theme.spacing(3),
    },
    btnAnnulerDialog: {
      background: "red",
      color: "white",
    },
    btnValiderDialog: {
      background: "rgb(19, 62, 103)",
      color: "white",
    },
    label: {
      fontWeight: "bold",
    },
    step1Title: {
      textAlign: "center",
    },
    step1Grid: {
      padding: "10px",
    },
    gridItem: {
      height: "25px",
    },
  }));
  const classes = useStyles();
  // const envoyerDialog = props.envoyerDialog;
  const idRequest = props.idRequest;
  const id_parameter = props.id_parameter;
  const type = props.type;

  function handleRefuse() {
    const validation_state = 1;
    ValidationPlanningService.declinedVP(idRequest).then((res) => {
      TraceabilityService.updateTVP(
        form.observation,
        validation_state,
        res.data
      ).then((result) => {
        if (result.status) {
          closeConfirm();
          setForm({ ...form, observation: "" });
        }
      })
    })
  }


  function handleRefuseAll() {
    if (form.observation.trim()?.length !== 0 && form.observation.trim() !== "") {

      setisEmptyObservation(false);
      const validation_state = 1;
      // console.log("listPendooooooooo", listPendingPlanning);
      // listPendingPlanning.map((id) => {
      setIsLoadingRefused(true);
      ValidationPlanningService.declinedAllVP(listPendingPlanning).then((res) => {
        if (res && res.status) {

          console.log("ldeclined All VP", res.data);
          TraceabilityService.updateAllTVP(
            form.observation,
            validation_state,
            res.data
          ).then((result) => {
            if (result.status) {
              console.log("Update All TVP", res.data);
              setForm({ ...form, observation: "" });
              setIsLoadingRefused(false);
              confirmRefusedAll();
            } else {

              setIsLoadingRefused(false);
            }
          })
        } else {

          setIsLoadingRefused(false);
        }
      })

    } else {
      setisEmptyObservation(true);
    }
    // })
  }

  function handleObservation(event) {
    const { name, value } = event.target;
    setForm({ ...form, observation: value });
    setisEmptyObservation(false);
  }



  return (
    <div>
      <Dialog
        open={openRefuseStep1}
        onClose={handleRefuseClose}
        aria-labelledby="form-dialog-title1"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title1">Motif de Refus</DialogTitle> <br />
        <DialogContent>
          <div className={classes.contenue}>

            <Grid className={classes.step1Grid} container>
              <Grid container item xs={12}>
                <Grid item xs={4}>
                  <label>Observation{bullRed} </label>
                </Grid>
                <Grid className={classes.gridItem} item xs={7}>
                  <TextField
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    minRows="5"
                    label="observation"
                    variant="outlined"
                    value={form.observation}
                    name="observation"
                    multiline
                    onChange={handleObservation}

                  />
                  {isEmptyObservation && (
                    <>
                      <Spacer y={1}></Spacer>
                      <Alert severity="error">Ce champ est obligatoire!</Alert>
                    </>
                  )}
                </Grid>
              </Grid>

              <Spacer y={2} />
              <Grid item xs={12}>

              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "##f50057", color: "#FFFFFF" }}
            onClick={closeDialog4}
            color="secondary"
            variant="contained"
          >
            Annuler
                </Button>
          {isLoadingRefused ? <CircularProgress /> :
            <Button
              style={{
                backgroundColor: "rgb(19, 62, 103)",
                color: "#FFFFFF",
              }}
              onClick={(isRefusedAll != undefined && isRefusedAll) ? handleRefuseAll : handleRefuse}
              color="primary"
              variant="contained"
            >
              Envoyer
                </Button>
          }
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="form-dialog-title" color="primary" >{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {messageDialog} */}
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              Vous ne pouvez passer à la validation que si vos subordonnés ont tous envoyé leur planning
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={confirmDialog} >Confirmer</Button>
          <Button variant="contained" color="secondary" onClick={cancelDialog} >Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
