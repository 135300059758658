import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Spacer from "../../components/Spacer";
import LeavePerWeekTable from "./LeavePerWeekTable";
import LeaveLastWeekTable from "./LeaveLastWeekTable";
import LeaveNextWeekTable from "./LeaveNextWeekTable";
import { AntTab, AntTabs } from '../../components/ant-tab';
import AppBar from "@material-ui/core/AppBar";
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    elevation: 0
  },
  paperLeaveInDay: {

    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    elevation: 0
  },
  tab1: {
    width: "40%"
  }
}));
export default function LeavePerWeek() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  function handleLast() {
    navigate("/leave_last_week");
  }
  function handleNext() {
    navigate("/leave_next_week");
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (

    <>


      <Grid container xs={12}>
        <AppBar position="static" color="default">
          <AntTabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
            <AntTab disableRipple label="Congé de cette semaine" className={classes.tab1} />
            <AntTab label="Congé de la semaine précédente" className={classes.tab1} />
            <AntTab label="Congé de la semaine prochaine" className={classes.tab1} />
            {/* <AntTab label="Export état" className={classes.tab1} /> */}
          </AntTabs>
        </AppBar>
      </Grid>
      <Spacer y={2} />
      {value == 0 && <Grid item>
        <Paper className={classes.paperLeaveInDay} variant="outlined">
          <LeavePerWeekTable />
        </Paper>
      </Grid>}
      {value == 1 && <Grid item>
        <Paper className={classes.paper} variant="outlined">
          <LeaveLastWeekTable />
        </Paper>
      </Grid>}
      {value == 2 && <Grid item>
        <Paper className={classes.paper} variant="outlined">
          <LeaveNextWeekTable />
        </Paper>
      </Grid>}
    </>
  )
}