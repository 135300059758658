import React, { useState, useEffect } from "react";
import {
    Grid,
    Button,
    FormControl,
    Select,
    TableContainer
} from "@material-ui/core";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Spacer from "../../components/Spacer";
import LeaveService from "../../services/LeaveService";
import Moment from "moment";
import { extendMoment } from "moment-range";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
    NavigateNext,
    NavigateBefore,

} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import { fr } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
    refusedPlanning: {
        width: "auto",
    },
    validatePlanning: {
        width: "auto",
    },
    mois: {
        textAlign: "center",
        fontSize: "22px",
    },
    bouton: {
        justifyContent: "center",
        display: "block"
    },
    root: {
        borderTopWidth: 1,
        borderColor: 'black',
        borderStyle: 'solid',
        Width: "100",
        textAlign: "center",
    },
    roots: {
        backgroundColor: '#3CA3FC',
        Width: "100",
        textAlign: "center !important",
        verticalAlign: "middle"
    },
    rootss: {
        background: "linear-gradient(to left, yellow 10%,#045DE9 90%);",
        Width: "100",
        textAlign: "center !important",
        verticalAlign: "middle"
    },
    container: {
        [theme.breakpoints.up("sm")]: {
            width: "100%"
        }
    },
    tableContainerOfData: {
        [theme.breakpoints.up("sm")]: {
            width: "200px"
        }
        // minWidth: "600"
    }
}));
function ListLeavesPlanning(props) {
    const [resultFinal, setResultFinal] = useState([]);
    const [debutDate, setDebutDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isValid, setIsValid] = useState(1);
    const moment = extendMoment(Moment);

    const [idMonth, setIdMonth] = useState([]);
    const [month, setMonth] = useState([]);
    const [mois, setMois] = useState("");
    const [ligne, setLigne] = useState(0);
    const [max, setMax] = useState();
    const [totalDay, setTotalDay] = useState([]);
    const [jour, setJour] = useState(0);
    const [jours, setJours] = useState([]);
    const [tableMonth, setTableMonth] = useState([]);
    const [id, setId] = useState(0);
    const [emptyResult, setEmptyResult] = useState(false);
    const [errorMessageForEmptyResult, setErrorMessage] = useState(0);
    const [errorDate, setErrorDate] = useState(false);

    const [ligneForMonth, setLigneForMonth] = useState(0);
    const [isBrowseMonth, setIsBrowseMonth] = useState(false);
    const [isResult, setIsResult] = useState(false);
    const [messageError, setMessageError] = React.useState(true);
    const [showMessageError, setShowMessageError] = React.useState(false);
    const classes = useStyles();
    //Samuela: paramètrage pour le loader
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

    useEffect(() => {

    }, []);

    function LoadTableValidPlanning(state, jour, mois, debutDatetime, dateEndTime) {
        setOpenLoadingSimpleBackdrop(true);
        LeaveService.getPlanningLeave(state, jour, mois, debutDatetime, dateEndTime).then((results) => {
            if (results && results.status) {
                setResultFinal(results.table);
                setOpenLoadingSimpleBackdrop(false);
                // setEmptyResult(false);

                setMessageError(results.status);
                setShowMessageError(false);
            } else {
                // setEmptyResult(true);
                setErrorMessage(results.message);
                setOpenLoadingSimpleBackdrop(false);
                setShowMessageError(true);
                setResultFinal([]);
            }
        });
    }

    function handleDebutDate(e) {

        setDebutDate(e);
        if (endDate && endDate !== null && endDate !== undefined) {
            if (endDate < e) {
                setErrorDate(true);
            } else {
                // setEndDate(e);
                setErrorDate(false);
                LeaveService.getMonth(e.toISOString(), endDate.toISOString()).then((result) => {
                    if (result && result.data) {
                        setMonth(result.data);
                        setIdMonth(result.month);
                        setMois(result.data[0]);
                        setMax(result.data.length - 1);
                        setTotalDay(result.total);
                        setJour(result.total[0]);
                        jourcomplet(result.total[0]);
                        setTableMonth(result.month);
                        setId(result.month[0]);
                        setLigneForMonth(result.month[0]);
                        console.log("mois", result.data[0]);
                        console.log("jour", result.data[0]);
                        LoadTableValidPlanning(isValid, result.total[0], result.month[0], e.toISOString(), endDate.toISOString());

                    }
                });
                setIsResult(true);
            }
        }
    }

    function handleEndDate(e) {
        setEndDate(e);
        if (debutDate && debutDate !== null && debutDate !== undefined) {
            if (debutDate > e) {
                setErrorDate(true);
            } else {
                setErrorDate(false);
                LeaveService.getMonth(debutDate.toISOString(), e.toISOString()).then((result) => {
                    if (result && result.data) {
                        setMonth(result.data);
                        setIdMonth(result.month);
                        setMois(result.data[0]);
                        setMax(result.data.length - 1);
                        setTotalDay(result.total);
                        setJour(result.total[0]);
                        jourcomplet(result.total[0]);
                        setTableMonth(result.month);
                        setId(result.month[0]);
                        setLigneForMonth(result.month[0]);
                        console.log("mois", result.month[0]);
                        console.log("jour", result.data[0]);
                        LoadTableValidPlanning(isValid, result.total[0], result.month[0], debutDate.toISOString(), e.toISOString());

                    }
                });
                setIsResult(true);
            }
        }
    }

    function handleChangeState(e) {
        setIsValid(e.target.value);
        if (debutDate && endDate) {
            LoadTableValidPlanning(e.target.value, jour, id, debutDate.toISOString(), endDate.toISOString());
        }
    }

    function jourcomplet($id) {
        const day = new Array();
        for (let index = 0; index < $id; index++) {
            day[index] = index + 1;
        }
        setJours(day);
    }

    function handlenext() {
        const ma = ligne + 1;
        const ms = month[ma];
        const ligne_month = ligneForMonth + 1;
        setMois(ms);
        setLigne(ma);
        setLigneForMonth(ligne_month);
        const next = totalDay[ma];
        const year = new Date(debutDate.toISOString()).getFullYear();
        const beginTime = `${year + "-" + (ligne_month < 10 ? "0" + ligne_month : ligne_month) + "-01"}`;
        const endTime = `${year + "-" + (ligne_month < 10 ? "0" + ligne_month : ligne_month) + "-" + next}`;
        console.log("ma***", ma);
        console.log("ma***", month);
        setJour(next);
        jourcomplet(next);
        setId(tableMonth[ma]);
        LoadTableValidPlanning(isValid, next, tableMonth[ma], beginTime, endTime);

        setIsBrowseMonth(true);
    }
    function handlebefore() {
        const ma = ligne - 1;
        const ms = month[ma];
        const ligne_month = ligneForMonth - 1;
        setMois(ms);
        setLigne(ma);
        setLigneForMonth(ligne_month);
        const before = totalDay[ma];
        const year = new Date(debutDate.toISOString()).getFullYear();
        const beginTime = `${year + "-" + (ligne_month < 10 ? "0" + ligne_month : ligne_month) + "-01"}`;
        const endTime = `${year + "-" + (ligne_month < 10 ? "0" + ligne_month : ligne_month) + "-" + before}`;
        setJour(before);
        jourcomplet(before);
        setId(tableMonth[ma]);
        // loadtable(before, tableMonth[ma], beginTime, endTime);
        LoadTableValidPlanning(isValid, before, tableMonth[ma], beginTime, endTime);
        setIsBrowseMonth(true);
    }

    var iter = 2;
    var isEnd = true;

    var iterWithType = 2;
    var isEndWithType = true;

    function renderCount() {
        return iter;
    }

    function bornSup() {
        return isEnd;
    }

    function setBornSup(param) {

        if (!isEnd) {
            iter++;
        }
        isEnd = param;
    }

    function initBornSup() {
        iter = 2;
        isEnd = true;
        return true;
    }

    function renderCountWithType() {
        return iterWithType;
    }

    function bornSupWithType() {
        return isEndWithType;
    }

    function setBornSupWithType(param) {

        if (!isEndWithType) {
            iterWithType++;
        }
        isEndWithType = param;
    }

    function initBornSupWithType() {
        iterWithType = 2;
        isEndWithType = true;
        return true;
    }
    return (
        <React.Fragment>

            <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
            <Grid container>
                <Grid container spacing={3} justifyContent="center" item>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={isValid} name="service_id" onChange={handleChangeState}>
                                <MenuItem value="1">Validé</MenuItem>
                                <MenuItem value="0">Non validé</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3} md={3} lg={3}>
                        <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                inputFormat="dd/MM/yyyy"
                                value={debutDate}
                                onChange={handleDebutDate}
                                renderInput={(params) =>
                                    <TextField {...params} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" error={false} label="Date de début" />
                                }
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                        <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                inputFormat="dd/MM/yyyy"
                                value={endDate}
                                onChange={handleEndDate}
                                renderInput={(params) =>
                                    <TextField {...params} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" error={false} label="Date fin" />
                                }
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>

                    {endDate === "" && <> <Spacer y={3} /><Grid item xs={12}><Alert>Veuillez choisir les dates dans la selection</Alert></Grid></>}
                    {errorDate &&
                        <>
                            <Spacer y={3} />
                            <Grid item xs={12}>
                                <Alert severity="error">La date de debut doit être inférieur à la date fin </Alert>
                            </Grid>
                        </>
                    }
                    {endDate !== "" && messageError && !errorDate &&
                        <p className={classes.mois} >
                            {ligne == 0 && max == 0 &&
                                <>
                                    <div className={classes.bouton} >
                                        {mois}
                                    </div>
                                </>
                            }
                            {ligne == 0 && max > 0 &&
                                <>
                                    <div className={classes.bouton} >
                                        {mois}<Button onClick={handlenext}> <NavigateNext /></Button>
                                    </div>
                                </>
                            }
                            {ligne != 0 && ligne != max &&
                                <>
                                    <div className={classes.bouton} >
                                        <Button onClick={handlebefore}> <NavigateBefore /></Button> {mois}<Button onClick={handlenext}> <NavigateNext /></Button>
                                    </div>
                                </>
                            }
                            {ligne != 0 && max != 0 && ligne == max &&
                                <>
                                    <div className={classes.bouton} >
                                        <Button onClick={handlebefore}> <NavigateBefore /></Button> {mois}
                                    </div>
                                </>
                            }
                        </p>
                    }
                </Grid >

            </Grid>

            {/* {emptyResult &&
                <>
                    <Spacer y={3} />
                    <Alert severity="error">{errorMessageForEmptyResult} </Alert>
                </>
            } */}


            {showMessageError &&
                <Grid item xs={12}>
                    <Alert severity="error">{errorMessageForEmptyResult} {isBrowseMonth ? " pour ce mois" : " entre ces deux dates"}</Alert>
                </Grid>
            }
            {!emptyResult &&
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" className="tableContainerOfData" fixedHeader={false} style={{ tableLayout: "auto" }}>
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                {endDate !== "" && resultFinal.length > 0 && !emptyResult && !errorDate &&
                                    <>
                                        <TableCell className={classes.root}>Demandeur</TableCell>

                                        {jours.map((row) => (
                                            <TableCell className={classes.root}>{row}</TableCell>
                                        ))}
                                    </>
                                }
                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {endDate !== "" && resultFinal.length > 0 && !errorDate &&
                                resultFinal.map((row) => (
                                    <TableRow >
                                        <TableCell className={classes.root}>{row["name"]}  {row["lastname"]}</TableCell>
                                        {jours.map((index) => (
                                            <>
                                                {(row["type"] == row[index] && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] == row[index + 1]) ? setBornSupWithType(false) : ""}
                                                {row["type"] == row[index] && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] != row[index + 1] && !bornSupWithType() &&

                                                    <TableCell className={classes.rootss} colSpan={renderCountWithType()}>
                                                    </TableCell>
                                                }
                                                {row["type"] == row[index] && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] != row[index + 1] && bornSupWithType() &&

                                                    <TableCell className={classes.rootss}>
                                                    </TableCell>
                                                }
                                                {(row["type"] == row[index] && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] != row[index + 1] && !bornSupWithType()) ? initBornSupWithType() : ""}


                                                {(row["type"] != row[index] && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] == row[index + 1]) ? setBornSup(false) : ""}

                                                {row["type"] != row[index] && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] != row[index + 1] && !bornSup() &&
                                                    <TableCell className={classes.roots} colSpan={renderCount()}>
                                                    </TableCell>

                                                }
                                                {row["type"] != row[index] && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] != row[index + 1] && bornSup() &&
                                                    <TableCell className={classes.roots}>

                                                    </TableCell>

                                                }
                                                {(row["type"] != row[index] && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] != row[index + 1] && !bornSup()) ? initBornSup() : ""}


                                                {row[index] == "null" && <TableCell className={classes.root}></TableCell>}
                                            </>
                                        ))}
                                    </TableRow >
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }

        </React.Fragment >
    );
}
export default ListLeavesPlanning;