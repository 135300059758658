import { viVN } from "@material-ui/core/locale";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const RubriquesService = {
  async saveRubrique(
    code_couleur,
    codification,
    titre,
    description,
    idMere,
    idRubrique,
    visionnage
  ) {
    try {
      // console.log("code_couleur: ", code_couleur);
      // console.log("Edit idRubrique: ", idRubrique);
      // console.log("Edit codification: ", codification);
      // console.log("nouvelle code couleur: ", code_couleur);
      const result = idRubrique
        ? await axios.put(`${apiUrl}/rubrique/update_rubrique/${idRubrique}`, {
            id_rubrique: idRubrique,
            code_couleur: code_couleur,
            codification: codification,
            titre_rubrique: titre,
            desc_rubrique: description,
            rubrique_mere: idMere,
            visionnage: visionnage,
          })
        : await axios.post(`${apiUrl}/rubrique/post_rubrique`, {
            code_couleur: code_couleur,
            codification: codification,
            titre_rubrique: titre,
            desc_rubrique: description,
            id_rubrique_mere: idMere,
            visionnage: visionnage,
          });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  //pour l'affichage des noms des rubriques dans le ménu déroulant
  async getNom() {
    try {
      const result = await axios.get(`${apiUrl}/rubrique/list_rubrique`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  //pour l'affichage des rubriques dans le tableau
  async getRubrique() {
    try {
      const result = await axios.get(`${apiUrl}/rubrique/get_rubriques`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getRubriqueMere() {
    try {
      const result = await axios.get(`${apiUrl}/rubrique/get_rubrique_mere`);
      if (result) {
        //console.log(result.data);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getModuleChilds(id) {
    try {
      const result = await axios.get(`${apiUrl}/rubrique/get_module_child/${id}`);
    
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async RubriqueById(id) {
    try {
      const result = await axios.get(`${apiUrl}/rubrique/id/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async deleteRubrique(id) {
    try {
      console.log("*********************service id rubrique to delete: ", id);
      const result = await axios.delete(
        `${apiUrl}/rubrique/delete_rubrique/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getDirection() {
    try {
      const result = await axios.get(`${apiUrl}/rubrique/direction`);
      if (result) {
        // console.log(result.data);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getService(idDir) {
    try {
      const result = await axios.get(`${apiUrl}/rubrique/service/${idDir}`);
      if (result) {
        console.log(result.data);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getFunction() {
    try {
      const result = await axios.get(`${apiUrl}/rubrique/function`);
      if (result) {
        //console.log(result.data);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getEmployeByDirection(idDir) {
    try {
      const result = await axios.get(
        `${apiUrl}/rubrique/employedirection/${idDir}`
      );
      if (result) {
        console.log(result.data);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getEmployeByService(idSer) {
    console.log("id ser reçu par le service web:", idSer);
    try {
      const result = await axios.get(
        `${apiUrl}/rubrique/employeservice/${idSer}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getEmployeByFonction(idFonction) {
    console.log("id fonction recue", idFonction);
    try {
      const result = await axios.get(
        `${apiUrl}/rubrique/employefonction/${idFonction}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getUserSuperior(idUser) {
    console.log("id employé de reference reçue dans le service web: ", idUser);
    try {
      const result = await axios.get(`${apiUrl}/rubrique/superior/${idUser}`);
      //const result = await axios.get(`${apiUrl}/rubrique/superior/${idUser}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getUserSubordonate(id) {
    try {
      const result = await axios.get(`${apiUrl}/rubrique/subordonate/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async savePrivilege(
    type,
    create,
    read,
    update,
    omit,
    canSetPrivilege,
    id_rubrique_or_document,
    userId,
    condition,
    idFonction,
    selectedEmploye,
    activeRowId = 0
  ) {
    console.log(' type : ', type);
    console.log('create : ', create);
    console.log('read : ', read);
    console.log('update : ', update);
    console.log('omit : ', omit);
    console.log('id_rubrique_or_document : ', id_rubrique_or_document);
    console.log('Liste des utilisateur(s) à ajouter des privilèges: ', userId);
    console.log('type de données user id', typeof(userId));
    console.log('condition : ', condition);
    console.log('employé de reference: ', selectedEmploye,);
    try {
      const result =
        activeRowId !== 0
          ? await axios.put(
              `${apiUrl}/rubriquePrivilege/update_action_field/${activeRowId}`,
              {

                typePrivilege: type,
                id_rubrique_or_document: id_rubrique_or_document,
                canCreate: create,
                canRead: read,
                canUpdate: update,
                canOmit: omit,
                canSetprivilege: canSetPrivilege,
              }
            )
          : await axios.post(`${apiUrl}/rubriquePrivilege/post_privileges`, {
              type_privilege: type,
              canCreate: create,
              canRead: read,
              canUpdate: update,
              canOmit: omit,
              canSetprivilege: canSetPrivilege,
              id_rubrique_or_document: id_rubrique_or_document,
              user_id: userId,
              condition: condition,
              selected_function_id: idFonction,
              employe_reference: selectedEmploye,
    
            });

      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getUsersRight(idRubriqueOrDocument, typePrivilege) {
    try {
      const result = await axios.post(
        `${apiUrl}/rubriquePrivilege/user_right`,
        {
          idRubriqueOrDocument: idRubriqueOrDocument,
          typePrivilege: typePrivilege,
        }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async deletePrivilege(privilegeId, idRubriqueOrDocument) {
    try {
      const result = await axios.delete(
        `${apiUrl}/rubriquePrivilege/delete_privilege/${privilegeId}/${idRubriqueOrDocument}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getActionToEditCheckbox(privilegeId) {
    try {
     
      const result = await axios.get(
        `${apiUrl}/rubriquePrivilege/action/${privilegeId}`
      );

      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getRubriqueForLoggedInUser(userId) {
    try {
      const result = await axios.get(
        `${apiUrl}/rubrique/get_rubrique_for_each_logged_in_user/${userId}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getAction(idRubrique, userId = null) {
    if (userId !== null) {
      try {
        // console.log("services: ", userId, "/", idRubrique);
        const result = await axios.get(
          `${apiUrl}/rubrique/get_action/${idRubrique}/${userId}`
        );
        if (result) {
          return result.data;
        }
      } catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    } else {
      try {
        //console.log("services: ", userId, "/", idRubrique);
        const result = await axios.get(
          `${apiUrl}/rubrique/get_action/${idRubrique}`
        );
        if (result) {
          return result.data;
        }
      } catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    }
  },

  async saveHistory(user_id, module_id, module_name, action, ip_address) {
    try {
      console.log("user_id:", user_id);
      console.log("module_id: ", module_id);
      console.log("module_name: ", module_name);
      console.log("action: ", action);
      console.log("ip_address: ", ip_address);

      const result = await axios.post(`${apiUrl}/GedHistory/post_history`, {
        user_id: user_id,
        module_id: module_id,
        module_name: module_name,
        action: action,
        ip_address: ip_address,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};

export default RubriquesService;
