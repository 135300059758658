import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect, useContext } from "react";

import { Grid, Paper } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { InsertDriveFile } from "@material-ui/icons";
import Spacer from "../../components/Spacer";
import InventoryTable from "./InventoryTable";
import InventoryToStock from "./InventoryToStock";
import InventoryAffectation from "./InventoryAffectation";
import InventoryValidation from "./InventoryValidation";
import InventoryDetentionSheet from "./InventoryDetentionSheet";
import ExportExcel from "./ExportExcel";
import InventoryService from "../../services/InventoryService";
import UserService from "../../services/UserService";
import ImmobilisationService from "../../services/ImmobilisationService";
import FilterValidation from "../../components/FilterValidation";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  style1: {
    fontSize: "5em",
  },
}));
export default function InventoryList() {
  const classes = useStyles();
  var myCurrentDate = new Date();

  //console.log("currentDAte", myCurrentDate);
  //console.log("immo list : ",localStorage.getItem("immoArrayStorage"));

  var date =
    myCurrentDate.getFullYear() +
    "-" +
    (myCurrentDate.getMonth() + 1) +
    "-" +
    myCurrentDate.getDate()+
    "-" +
    myCurrentDate.getHours()+
    "-" +
    myCurrentDate.getMinutes()+
    "-" +
    myCurrentDate.getSeconds();

  const [dataToExport, setDataToExport] = useState([]);
  const [dataDetenteurExport, setdataDetenteurExport] = useState([]);
  const [dataImmo, setDataImmo] = useState([]);
  const [rows, setRows] = useState([]);
  const [fileName, setFileName] = useState("Inventaire enregistré le " + date);
  const [filenameDetenteur, setfilenameDetenteur] = useState("detenteur_immo_" + date);
  const [isOpenStock, setIsOpenStock] = useState(false);
  const [isOpenAffectedTo, setIsOpenAffectedTo] = useState(false);
  const [isOpenDetentionSheet, setIsOpenDetentionSheet] = useState(false);
  const [isOpenFirstValidation, setIsFirstValidation] = useState(false);

  const wscols = [
    { wch: 25 },
    { wch: 25 },
    { wch: 45 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 45 },
    { wch: 10 },
    { wch: 25 },
  ];

  const wscolsDetenteur = [
    { wch: 25 },
    { wch: 25 },
    { wch: 45 },
    { wch: 45 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
  ];

  useEffect(() => {
    InventoryService.exportExcel().then((result) => {
      if (result && result.data) {
        setDataToExport(result.data);
      }
    });
    InventoryService.exportDetenteurExcel().then((result) => {
      if (result && result.data) {
        setdataDetenteurExport(result.data);
      }
    });

  }, []);
  
  function handleClickStock() {
    var allImmoSelected = localStorage.getItem("immoArrayStorage");
    console.log("xhr : ", allImmoSelected);
    ImmobilisationService.getAllSelectedCodeImmo(allImmoSelected).then((result) => {
      if (result && result.data) {
        setDataImmo(result.data);
      }
    });
    setIsOpenStock(true);
  }
  
  function handleCloseDialogStock() {
    setIsOpenStock(false);
  }
  
  function handleClickAffectedTo() {
    var allImmoSelected = localStorage.getItem("immoArrayStorage");
    ImmobilisationService.getAllSelectedCodeImmo(allImmoSelected).then((result) => {
      if (result && result.data) {
        setDataImmo(result.data);
      }
    });
    UserService.getAllUserActive().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.user_id })));
      } 
    });
    setIsOpenAffectedTo(true);
  }

  function handleClickDetentionSheet() {
    setIsOpenDetentionSheet(true);
  }

  const handleSubmit = (e) => {
    window.location.reload();

  };
  const Refresh = (e) => {
    e.preventDefault();
      localStorage.removeItem('name_materiel');
      localStorage.removeItem('code_immo');
      localStorage.removeItem('state');
      localStorage.removeItem('logistics_manager');
      localStorage.removeItem('status1');
      localStorage.removeItem('status2');
      localStorage.setItem('name_materiel',"");
      localStorage.setItem('code_immo',"");
      localStorage.setItem('state',"");
      localStorage.setItem('logistics_manager',"");
      localStorage.setItem('status1',"");
      localStorage.setItem('status2',"");
      
      window.location.reload()
  };
  function handleClickValidatedTo() {
    var allImmoSelected = localStorage.getItem("immoArrayStorage");
    ImmobilisationService.getAllSelectedCodeImmo(allImmoSelected).then((result) => {
      if (result && result.data) {
        setDataImmo(result.data);
      }
    });
    setIsFirstValidation(true);
  }

  function handleCloseDialogAffectedTo() {
    setIsOpenAffectedTo(false);
  }

  function handleCloseDialogValidation() {
    setIsFirstValidation(false);
  }

  function handleCloseDialogDetentionSheet() {
    setIsOpenDetentionSheet(false);
  }

  return (
    <MainContainer {...headerDataImmo}>
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickStock}
          style={{ fontWeight: 600 }}
        >
          CLASSEMENT EN STOCK
        </Button>
        <Spacer x={2} />

        <Button
          variant="contained"
          color="primary"
          onClick={handleClickAffectedTo}
          style={{ fontWeight: 600 , backgroundColor:"yellowgreen"}}
        >
          CHANGEMENT DE DETENTEUR
        </Button>
        <Spacer x={2} />

        <Button
          variant="contained"
          color="primary"
          onClick={handleClickValidatedTo}
          style={{ fontWeight: 600, backgroundColor:"green", color:"#FFFFFF" }}
        >
          VALIDER L'AFFECTATION
        </Button>

        <Spacer x={2} />
        <Button
            variant="contained"
            onClick={Refresh}
            style={{ fontWeight: 600, backgroundColor:'darkred', color:'white'}}
          >
            réinitialiser le filtre
        </Button>
        <Spacer x={2} />

        <Button
            variant="contained"
            color="primary"
            onClick={handleClickAffectedTo}
            style={{ fontWeight: 600} }
          >
            Filtrer
        </Button>
        <Spacer x={2} />

        <FilterValidation type={1} />

        <ExportExcel
          dataToExport={dataToExport}
          dataDetenteurExport={dataDetenteurExport}
          filenameHistory={fileName}
          filenameDetenteur={filenameDetenteur}
          style={classes.style1}
          wscols={wscols}
          wscolsDetenteur={wscolsDetenteur}
        />
        <Spacer x={2} />

        <Button
            variant="contained"
            color="secondary"
            onClick={handleClickDetentionSheet}
            style={{ fontWeight: 600, marginLeft: "auto"} }
            startIcon={<InsertDriveFile />}
          >
            Fiche de détention
        </Button>

        <InventoryToStock
          dataImmo={dataImmo}
          open={isOpenStock}
          closeDialog={handleCloseDialogStock}
        />

        <InventoryAffectation
          dataImmo={dataImmo}
          rows={rows}
          open={isOpenAffectedTo}
          closeDialog={handleCloseDialogAffectedTo}
          fullWidth
          maxWidth="sm"
        />

        <InventoryValidation
          dataImmo={dataImmo}
          open={isOpenFirstValidation}
          closeDialog={handleCloseDialogValidation}
        />

        <InventoryDetentionSheet
          dataImmo={dataImmo}
          rows={rows}
          open={isOpenDetentionSheet}
          closeDialog={handleCloseDialogDetentionSheet}
          fullWidth
          maxWidth="sm"
        />
      
      </Grid>
    

      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <InventoryTable />
          </Paper>
        </Grid>
      </Grid>
      
    </MainContainer>
  );
}
