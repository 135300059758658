import { makeStyles } from "@material-ui/core/styles";
const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[200]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
    {
      backgroundColor: "transparent",
    },
  },
  content: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  contentLabel: {
    paddingRight: theme.spacing(1),
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
    fontWeight: "inherit",
    color: "inherit",
    textDecoration: "inherit",
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 6,
    
  },
  labelTextBold: {
    fontWeight: "bold",
    flexGrow: 1,
    
  },
  grandTitre: {
   
    fontSize:"18px",
  },
  sousTitre: {
   
    fontSize:"15px",
  },
  activeMenu: {
    background: "rgb(106, 207, 246)",
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  grandT:{
    fontSize:"50px",
  },
}));
export default useTreeItemStyles;