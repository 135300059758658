import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const UserAnnualCalendarService = {
  //ADD/EDIT USER SPECIAL VALIDATEUR 
  async saveUserAnnualCalendar(userId, annualCalendarId, id) {
    try {
      const result = id != 0 ?
        await axios.put(`${apiUrl}/userAnnualCalendar/id/${id}`, {
          annualCalendarId,
          id
        })
        :
        await axios.post(`${apiUrl}/userAnnualCalendar/`, {
          userId,
          annualCalendarId
        });
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async getConfigAnnualCalendar(userId) {
    try {
      const result = await axios.get(`${apiUrl}/userAnnualCalendar/getConfigAnnualCalendar/${userId}`);
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async deleteUserAnnualCalendar(annualCalendarId) {
    try {
      const result = await axios.delete(`${apiUrl}/userAnnualCalendar/deleteUserAnnualCalendar/${annualCalendarId}`);
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

}

export default UserAnnualCalendarService;