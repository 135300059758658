import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const ViewingMettingService = {
    async getViewingMetting(){
        try {
            const result = await axios.get(`${apiUrl}/viewingmetting/`  );
            if (result) {
              // TODO: replace result.data.message by the token
              
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }  
    },

}

export default ViewingMettingService;