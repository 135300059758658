import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const TimeTrackingService = {
  
  async getAllTimeTracking(filters) {
    try {
      // Utilisez les filtres pour construire l'URL de la requête
      const queryString = Object.keys(filters)
        .map((key) => `${key}=${encodeURIComponent(filters[key])}`)
        .join("&");

      const result = await axios.get(`${apiUrl}/TimeTracking/getAllTimeTracking?${queryString}`);
      if (result) {
        return result.data;

      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
}
export default TimeTrackingService;