import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const UserMaternityLeaveService = {
  //ADD/EDIT USER SPECIAL VALIDATEUR 
  async saveUserMaternityLeave(userId, beginDate, endDate) {
    try {
      const result = await axios.post(`${apiUrl}/userMaternityLeave/`, {
          userId,
          beginDate,
          endDate
        });
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async getAllMaternityLeave() {
    try {
      const result = await axios.get(`${apiUrl}/userMaternityLeave/getAllUserInMaternityLeave`);
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async deleteUserMaternityLeave(id) {
    try {
      const result = await axios.delete(`${apiUrl}/userMaternityLeave/deleteUserInMaternityLeave/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  
  async getMaternityLeaveById(id) {
    try {
      const result = await axios.get(`${apiUrl}/userMaternityLeave/getMaternityLeaveById/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async updateMaternityLeave(id, beginDate, endDate) {
    try {
        const result = await axios.put(`${apiUrl}/userMaternityLeave/updateMaternityLeaveById/${id}`,{
            beginDate,
            endDate
        });
        if (result) {
            return result.data;
        }
    } catch (error) {
        if (error.response) {
            return error.response.data;
        }
    }
},
}

export default UserMaternityLeaveService;