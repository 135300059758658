import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../../components/Title";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import TDRService from "../../../services/TDRService";
import { DataGrid } from '@material-ui/data-grid';
import ConfirmationDialog from "../../tools/ConfirmationDialog";
import Message from "../../tools/Message";
import CheckIcon from "@material-ui/icons/Send";
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';
import ValidationService from "../../../services/ValidationService";


const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  }
}));

export default function DemandeTable(props) {

  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [id_to_delete, setId_to_delete] = useState(0);
  const [id_to_annuler, setId_to_annuler] = useState(0);
  const [urg, setUgr] = useState(0);
  const [etat, setEtat] = useState({ open: false, message: "message", accept: false });
  const [isstatus, setIsstatus] = useState(0);
  const [ligne, setLigne] = useState([]);
  const [lien, setLien] = useState();
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const [opens, setOpens] = React.useState(false);
  const [ouvert, setOuvert] = React.useState(false);

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  const handleClickOpen = (id) => {
    setOpens(true);
    TDRService.Alls(id).then((result) => {
      if (result && result.data) {
        setLigne(result.data);
        console.log("d ho ato ve le");
      }
    });

  };


  const { open, color, messageInfo } = message;
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez vous vraiment supprimer  ?',
  });
  const { openDialog, messageDialog } = confirmationDialog;

  const handleDelete = (id) => (ev) => {
    setId_to_delete(id);
    setOuvert(true);
  };


  const handleClickClose = () => {
    setOpens(false);
    setOuvert(false);
  };


  const handleAnnuler = (id) => (ev) => {
    setId_to_annuler(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true });
  };

  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    ValidationService.delete(id_to_annuler).then((result) => {
      TDRService.updateTDR(id_to_annuler).then((result) => {
        TDRService.All().then((result) => {
          if (result && result.data) {
            setRows(result.data.map((row) => ({ ...row, id: row.mission_request_id })));
          }
        });

        if (result.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      });
    });
  }

  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function giveListe(id) {
    const ids = id[0];
    TDRService.Alls(ids).then((result) => {
      if (result && result.data) {
        setLigne(result.data);
      }
    });
    TDRService.pdf(ids).then((result) => {
      if (result && result.data) {
        setLien(result.data);
      }
    });
  }
  const classes = useStyles();
  useEffect(() => {

    setOpenLoadingSimpleBackdrop(true);
    TDRService.All().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.mission_request_id, file_id: row.file_id })));
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
    if (lien) {
      TDRService.saveFile(lien, id_to_delete)
        .then((result) => {
        })
        .catch((err) => {
          alert("error");
        });
    }
  }, [setRows]);

  function handleMessage() {
    setEtat({
      open: false,
      message: ""
    });
  }


  const handleGenerateFile = (id, file_id) => (ev) => {
    console.log(file_id + " ====== **");
    setOpenLoadingSimpleBackdrop(true);
    handleClickOpen(id);
    setId_to_delete(id);
    setIsstatus(file_id);
    TDRService.pdf(id).then((result) => {
      if (result && result.data) {
        setLien(result.data);
        navigate("/mission/demande");
        setOpenLoadingSimpleBackdrop(false);
      }
    });
    setOpenLoadingSimpleBackdrop(false);
  }

  const handleEdit = (id) => (ev) => {
    navigate("/mission/demande/add/" + id);
  };

  function getFullName(params) {
    return `${params.getValue(params.id, 'first_name') || ''} ${params.getValue(params.id, 'last_name') || ''
      }`;
  }


  const handleClose = () => {
    setOpens(false);
    TDRService.saveFile(lien, id_to_delete)
      .then((result) => {
      })
      .catch((err) => {
        alert("error");
      });
    ValidationService.saveValidation(id_to_delete)
      .then((result) => {
        var responseErrorMessage = result.message;
        TDRService.All().then((result) => {
          if (result && result.data) {
            setRows(result.data.map((row) => ({ ...row, id: row.mission_request_id })));
            setOpenLoadingSimpleBackdrop(false);
          } else {
            setOpenLoadingSimpleBackdrop(false);
          }
        });

        if (result.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: responseErrorMessage });
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: responseErrorMessage });
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      });
    setOpens(false);

  };

  const columns = [
    // {
    //   field: 'number',
    //   headerName: 'N°',
    //   width: 200,
    // },
    {
      field: 'name',
      headerName: 'Composant',
      width: 200,
    },
    {
      field: 'type_mission_request',
      headerName: 'Type de demande',
      width: 250,
    },
    {
      field: 'reason',
      headerName: 'Raison',
      width: 300,
    },
    {
      field: 'name_demandeur',
      headerName: 'Demandeur',
      width: 250,
    },
    {
      field: 'number_day',
      headerName: 'Nombre(s) de jour(s)',
      width: 250,
    },
    {
      field: 'date',
      headerName: 'Date désiré',
      width: 250,
    },
    {
      field: 'action',
      headerName: 'Action',
      disableClickEventBulbing: true,
      renderCell: (params) => {
        console.log("params 45  " + params.row.file_id);
        setUgr(params.row.file_id);
        return (
          <div>
            {params.row.file_id === null &&
              <Button onClick={handleGenerateFile(params.id, params.row.file_id)} title='Envoyer pour validation' >
                <CheckIcon style={{ color: "green" }} />
              </Button>
            }
            {params.row.file_id !== null &&
              <Button onClick={handleAnnuler(params.id)} title="Annulation de l'attente">
                <CloseIcon style={{ color: "red" }} />
              </Button>

            }
            {/* {params.row.file_id === null &&
              <Button onClick={handleEdit(params.id)}><CreateTwoTone style={{ color: "black" }} />
              </Button>

            } */}
            {params.row.file_id === null &&
              <Button onClick={handleDelete(params.id)}>
                <DeleteOutlineRounded style={{ color: "red" }} />
              </Button>
            }

          </div>
        );
      },
      width: 200,
    },
  ];
  function handleCloseMessage() {
    setMessage({ ...message, open: false });

  }


  const handleToClose = () => {
    setOuvert(false);
    TDRService.delete(id_to_delete).then((result) => {
      TDRService.All().then((result) => {
        if (result && result.data) {
          setRows(result.data.map((row) => ({ ...row, id: row.mission_request_id })));
          setOuvert(false);
        } else {
          setOuvert(false);

        }
      });
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
      }
    }).catch((err) => {
      setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
    });
  };



  return (
    <React.Fragment>
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Listes des demandes d'appuis</Title>
      <div style={{ height: 400, width: '100%' }} className={classes.root}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        // onRowClick={handleClickOpen}
        // onSelectionModelChange={itm => 
        //           giveListe(itm)
        //         }
        />
      </div>
      <div>
        <Dialog
          open={opens}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title" style={{ color: 'white', }}>
            {"Information Demande d'appui"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ width: 400, }}>
              {ligne.map((row) => (
                <Grid container spacing={2} >
                  <Grid item xs={5}>
                    <label className={classes.label}>Type de demande:</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label className={classes.label1}>{row.type_mission_request}</label>
                  </Grid>
                  <Grid item xs={5}>
                    <label className={classes.label}>Raison :</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label className={classes.label1}>{row.reason}</label>
                  </Grid>
                  <Grid item xs={5}>
                    <label className={classes.label}>Composant:</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label className={classes.label1}>{row.name}</label>
                  </Grid>
                  <Grid item xs={5}>
                    <label className={classes.label}>Voir pdf:</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label className={classes.label1}><Link to={"/PdfRender/" + lien}>.pdf</Link></label>
                  </Grid>
                </Grid>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleClickClose} variant="contained" color="secondary">
              Annuler
            </Button>
            <Button onClick={handleClose} variant="contained" color="primary">
              Envoyer
            </Button>
          </DialogActions>
        </Dialog>


        <div stlye={{}}>
          <Dialog open={ouvert} 
          // onClose={handleToClose}
          >
            <DialogTitle id="form-dialog-title" style={{ color: 'white', }}>
              {"Information Demande d'appui"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                voulez-vous vraiment supprimer ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClickClose} variant="contained" color="secondary">
              Annuler
            </Button>
              <Button onClick={handleToClose}
                variant="contained" color="primary">
                valider
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </React.Fragment>
  );
}
