import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Spacer from "../../components/Spacer";
import Grid from "@material-ui/core/Grid";
import {
  Button
} from "@material-ui/core";
import PersonnelService from "../../services/PersonnelService";
import { useLocation } from 'react-router-dom';
import Message from "./Message";
import Alert from "@material-ui/lab/Alert";
import Util from "../tools/util";
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  select: {
    width: 400,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  datePicker: {
    margin: 0,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      float: 'right',
    },
  },
  forms:
  {
    width: 500,
  },
  labelPosition: {
    float: 'left',
  },
  lien: {
    float: 'right',
  },
}));

export default function Fiscale() {

  const navigate = useNavigate();
  const classes = useStyles();
  const util = new Util();
  const [etat, setEtat] = useState({
    opens: false, message: "message"
  });
  const { opens, message } = etat;
  const [form, setForm] = useState({
    nif: "",
    stat: "",
  });
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const split = splitLocation.length - 1;
  const splits = splitLocation[split];

  const [nifIsEmpty, setNifIsEmpty] = useState(false);
  const [StatIsEmpty, setStatIsEmpty] = useState(false);

  const [person, setPerson] = useState({
    first_name: "",
    last_name: "",
    personnal_email: "",
    card_number: "",
    card_date: "",
    card_place: "",
    card_duplicate: "",
    birth_date: "",
    birth_place: "",
    telephone: "",
    sex: "",
    address: "",
    marital_status: "",
    number_children: "",
  });
  const [user, setUser] = useState({
    personnal_email: "",
    registration_number: "",
    office_number: "",
    phone_number: "",
    urgence: "",
    name_image: "",
    nif: "",
    stat: "",
  });
  const [fonction, setFonction] = useState({
    fonction: "",
    end_time: "",
    porte: "",
    siege: "",
    batiment: "",
  });
  const [service, setService] = useState();
  const [superieur, setSuperieur] = useState();
  const [group, setGroup] = useState();

  function setStatFormat(value) {
    if (value) {
      const stat = value.replace(/[^\d]/g, '');
      const number = stat.length;
      if (number < 6) {
        return stat;
      }
      if (number < 8) {
        return `${stat.slice(0, 5)} ${stat.slice(5, 7)}`;
      }
      if (number < 12) {
        return `${stat.slice(0, 5)} ${stat.slice(5, 7)} ${stat.slice(7, 11)}`;
      }

      if (number < 13) {
        return `${stat.slice(0, 5)} ${stat.slice(5, 7)} ${stat.slice(7, 11)} ${stat.slice(11, 12)}`;
      }

      return `${stat.slice(0, 5)} ${stat.slice(5, 7)} ${stat.slice(7, 11)} ${stat.slice(11, 12)} ${stat.slice(12, 17)}`;
    } else {
      return "";
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    const nombre = value.length;
    setForm({ ...form, [name]: value });
    if (name == "stat") {
      const nombre = value.length;
      setForm({ ...form, [name]: setStatFormat(value) });
      setStatIsEmpty(false);
    }
    else {
      const nif = value.replace(/[^\d]/g, '');

      setForm({ ...form, [name]: nif });
      setNifIsEmpty(false);
    }
    localStorage.setItem("nif", JSON.stringify(form.nif));
    localStorage.setItem("stat", JSON.stringify(form.stat));
  };
  useEffect(() => {
    setForm({
      nif: JSON.parse(localStorage.getItem("nif")),
      stat: JSON.parse(localStorage.getItem("stat")),
    });
    SavePerson();
    SaveUSer();
    SaveFunction();
    SaveService();
  }, [setForm]);

  function SavePerson() {
    setPerson({
      first_name: JSON.parse(localStorage.getItem("first_name")),
      last_name: JSON.parse(localStorage.getItem("last_name")),
      personnal_email: JSON.parse(localStorage.getItem("personnal_email")),
      card_number: JSON.parse(localStorage.getItem("cin")),
      card_date: JSON.parse(localStorage.getItem("date_cin")),
      card_place: JSON.parse(localStorage.getItem("lieu_cin")),
      card_duplicate: JSON.parse(localStorage.getItem("datep_cin")),
      birth_date: JSON.parse(localStorage.getItem("ddn")),
      birth_place: JSON.parse(localStorage.getItem("lieu")),
      telephone: JSON.parse(localStorage.getItem("urgence")),
      sex: JSON.parse(localStorage.getItem("sex_id")),
      address: JSON.parse(localStorage.getItem("adresse")),
      marital_status: JSON.parse(localStorage.getItem("marital")),
      number_children: JSON.parse(localStorage.getItem("nombre_enfant")),
    });
  }

  function SaveUSer() {

    setUser({
      nif: JSON.parse(localStorage.getItem("nif")),
      stat: JSON.parse(localStorage.getItem("stat")),
      personnal_email: JSON.parse(localStorage.getItem("personnal_email")),
      name_image: JSON.parse(localStorage.getItem("name_image")),
      urgence: JSON.parse(localStorage.getItem("urgence")),
      phone_number: JSON.parse(localStorage.getItem("flotte")),
      office_number: JSON.parse(localStorage.getItem("bureau")),
      registration_number: JSON.parse(localStorage.getItem("matricule")),
    });
  }
  function SaveFunction() {
    setFonction({
      fonction: JSON.parse(localStorage.getItem("fonction")),
      end_time: "",
      porte: JSON.parse(localStorage.getItem("porte")),
      siege: JSON.parse(localStorage.getItem("siege")),
      batiment: JSON.parse(localStorage.getItem("batiment")),
    });
  }

  function SaveService() {
    setService(JSON.parse(localStorage.getItem("service")));
    setSuperieur(JSON.parse(localStorage.getItem("superieur")));
    setGroup(JSON.parse(localStorage.getItem("groups")));
  }
  function remove() {
    localStorage.removeItem('last_name');
    localStorage.removeItem('first_name');
    localStorage.removeItem('personnal_email');
    localStorage.removeItem('cin');
    localStorage.removeItem('date_cin');
    localStorage.removeItem('lieu_cin');
    localStorage.removeItem('datep_cin');
    localStorage.removeItem('ddn');
    localStorage.removeItem('lieu');
    localStorage.removeItem('urgence');
    localStorage.removeItem('sex_id');
    localStorage.removeItem('adresse');
    localStorage.removeItem('type');
    localStorage.removeItem('nombre_enfant');
    localStorage.removeItem('nif');
    localStorage.removeItem('stat');
    localStorage.removeItem('name_image');
    localStorage.removeItem('flotte');
    localStorage.removeItem('bureau');
    localStorage.removeItem('matricule');
    localStorage.removeItem('fonction');
    localStorage.removeItem('porte');
    localStorage.removeItem('siege');
    localStorage.removeItem('batiment');
    //localStorage.removeItem('service');
    localStorage.removeItem('superieur');
    localStorage.removeItem('groups');
    localStorage.removeItem('dir');
    localStorage.removeItem('age');
    localStorage.removeItem('marital');
  }
  function handleClick() {
    if (form.nif !== null && form.stat !== null) {
      const id = splitLocation[3];
      if (splitLocation.length == 5) {
        PersonnelService.saveAll(person.first_name, person.last_name, person.personnal_email, person.card_number, person.card_date, person.card_place, person.card_duplicate, person.birth_date, person.birth_place,
          person.telephone, person.sex, person.address, person.marital_status, person.number_children, user.registration_number, user.office_number, user.phone_number, user.urgence, user.name_image, user.nif, user.stat
          , service, fonction.fonction, fonction.porte, fonction.siege, fonction.batiment, superieur, group, id)
          .then((result) => {
            const iduser = result.data;
            console.log("iduser  :" ,iduser);
            localStorage.setItem("contract", JSON.stringify(iduser));
            if (iduser !== null || form.bureau !== "") {
              remove();
            }
            navigate("/personnel/add/contract/");
            window.location.reload();

          })
          .catch((err) => {
            alert("error");
          });
      }
      else {
        PersonnelService.savePerson(person.first_name, person.last_name, person.personnal_email, person.card_number, person.card_date, person.card_place, person.card_duplicate, person.birth_date, person.birth_place,
          person.telephone, person.sex, person.address, person.marital_status, person.number_children, user.registration_number, user.office_number, user.phone_number, user.urgence, user.name_image, user.nif, user.stat
          , service, fonction.fonction, fonction.end_time, fonction.porte, fonction.siege, fonction.batiment, superieur, group)
          .then((result) => {
            const iduser = result.data;
            console.log("iduser  :" ,iduser);
            localStorage.setItem("contract", JSON.stringify(iduser));
            if (iduser !== null || form.bureau !== "") {
              remove();
            }
            navigate("/personnel/add/contract");
            window.location.reload();
          })
          .catch((err) => {
            alert("error");
          });
      }
    } else {
      if (form.nif === null || form.nif === "") {
        setNifIsEmpty(true);
      }
      if (form.stat === null || form.stat === "") {
        setStatIsEmpty(true);
      }
    }
  }

  return (
    <React.Fragment>
      <Message isActive={etat.open} message={etat.message} />
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={3}>
          <label className={classes.labelPosition}>NIF{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            label="NIF"
            variant="outlined"
            inputProps={{ maxLength: 10 }}
            onChange={handleChange}
            value={form.nif}
            name="nif"
          />
          {nifIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>STAT{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            label="STAT"
            variant="outlined"
            onChange={handleChange}
            inputProps={{ maxLength: 24 }}
            value={form.stat}
            name="stat"
          />
          {StatIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>

        </Grid>
        <Grid item xs={8}>
          <div className={classes.root}>
            <Button variant="contained" color="primary" onClick={handleClick}  >
              Valider
            </Button>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}