import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const RubriqueSecondaireService = {
    async saveSecondaire(nom, description, rubrique_principale_id, id) {
        try {
            const result = id ?
                await axios.put(`${apiUrl}/rubriqueSecondaire/${id}`, {
                    id: id,
                    nom: nom,
                    description: description,
                    rubrique_principale_id: parseInt(rubrique_principale_id)
                })
                :
                await axios.post(`${apiUrl}/rubriqueSecondaire`, {
                    id: id,
                    nom: nom,
                    description: description,
                    rubrique_principale_id: parseInt(rubrique_principale_id)
                });
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },


    async secondaireById(id) {
        try {
            const result = await axios.get(`${apiUrl}/rubriqueSecondaire/id/${id}`);
            if (result) {
                return result.data;
            }
        }
        catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
    ,
    async getSecondaire() {
        try {
            const result = await axios.get(`${apiUrl}/rubriqueSecondaire/`);
            if (result) {
                return result.data;
            }
        }
        catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
    ,


    async getNom() {
        try {
            const result = await axios.get(`${apiUrl}/rubriqueSecondaire/nom`);
            if (result) {
                return result.data;
            }
        }
        catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
    ,

    async deleteSecondaire(id) {
        try {
            const result = await axios.delete(`${apiUrl}/rubriqueSecondaire/${id}`);
            if (result) {
                return result.data;
            }
        }
        catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}

export default RubriqueSecondaireService;