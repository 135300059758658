import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const MissionRequestValidationService = {
    async sendRequestValidatationMission(mission_id, user_id){
        try{
            const result = await axios.post(`${apiUrl}/missionRequestValidation`, {
                'mission_id' : mission_id,
                'user_id' : user_id
            });
            if (result) {
                // TODO: replace result.data.message by the token
                return result.data;
            }
        }catch(error){
            if(error.response){
              return error.response.data;
            }
        }
    },
}

export default MissionRequestValidationService;
