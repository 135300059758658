import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { fr } from "date-fns/locale";
import Alert from "@material-ui/lab/Alert";
import {
  NavigateNext,
  NavigateBefore,

} from "@material-ui/icons";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  TableContainer,
  TextField,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import LeaveService from "../../services/LeaveService";
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from "@material-ui/core/Dialog";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Spacer from "../../components/Spacer";
import Timeline from "../planning/Timeline";
import Slide from '@mui/material/Slide';
import Moment from "moment";
import { extendMoment } from "moment-range";
import DialogContent from '@material-ui/core/DialogContent';

import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const moment = extendMoment(Moment);

const bullGreen = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: 'green', }}
  >

  </Box>

);
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', backgroundColor: 'red' }}
  >

  </Box>

);
const bullBlue = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', backgroundColor: '#6ACFF6' }}
  >

  </Box>

);
const bullBlueEdited = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', backgroundColor: '#133E67' }}
  >

  </Box>

);
const bullBlueYellow = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: '#FFFF00' }}
  >

  </Box>

);

const bullBeige = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: '#FAF8EB', border: '0.5px solid grey' }}
  >

  </Box>

);

const useStyles = makeStyles((theme) => ({
  refusedPlanning: {
    width: "auto",
  },
  validatePlanning: {
    width: "auto",
  },
  mois: {
    textAlign: "center",
    fontSize: "22px",
  },
  bouton: {
    justifyContent: "center",
  },
  root: {
    borderTopWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
    Width: "auto",
    textAlign: "center",
  },
  roots: {
    backgroundColor: '#3CA3FC',
    Width: "auto",
    textAlign: "center",
  },
  rootss: {
    background: "linear-gradient(to left, yellow 10%,#045DE9 90%);",
    Width: "auto",
    textAlign: "center",
  },
  confirmed:
  {
    backgroundColor: 'green',
    Width: "auto",
    textAlign: "center",
  },
  refused:
  {
    backgroundColor: 'red',
    Width: "auto",
    textAlign: "center",
  },
  h2: {
    textAlign: "center",
  },
  text: {
    textAlign: "center",
    textDecoration: "underline",
  },
  texts: {
    color: "red",
    textAlign: "center",

  },
  title: {
    fontWeight: "bold",

  },
  roots: {
    backgroundColor: '#3CA3FC',
    Width: "auto",
    textAlign: "center",
  },

  divLegendeTimeline: {
    display: "flex",
    justifyContent: "center"
  },
  daynotCompted: {
    backgroundColor: '#FAF8EB',
  },
  leavePending: {
    backgroundColor: '#6ACFF6',
  },
  leaveConfirmed: {
    backgroundColor: '#133E67',
  },
  leaveValidate: {
    backgroundColor: '#FFFF00',
  },
  leaveConsumes: {
    backgroundColor: 'green',
  },
  leaveCanceled: {
    backgroundColor: 'red',
  },
  timelineGrid: {
    position: 'absolute',
    top: '16vh',
    margin: '0'
  }
}));

export default function ListLeaveReal(props) {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [beginDate, setBeginDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [messageError, setMessageError] = React.useState(true);
  const [showMessageError, setShowMessageError] = React.useState(false);
  const classes = useStyles();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [month, setMonth] = useState([]);
  const [idMonth, setIdMonth] = useState([]);
  const [mois, setMois] = useState("");
  const [max, setMax] = useState();
  const [totalDay, setTotalDay] = useState([]);
  const [jour, setJour] = useState(0);
  const [jours, setJours] = useState([]);
  const [tableMonth, setTableMonth] = useState([]);
  const [id, setId] = useState(0);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [final, setFinal] = useState([]);
  const [ligne, setLigne] = useState(0);
  const [ligneForMonth, setLigneForMonth] = useState(0);
  const [isBrowseMonth, setIsBrowseMonth] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [errorDate, setErrorDate] = useState(true);
  const [openDialogTimeline, setOpenDialogTimeline] = useState(false);
  const [value, setValue] = useState(1);
  const id_parameter = 1;
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");


  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");
  const [difference_In_Days, setdifference_In_Days] = useState("");
  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([]);
  const [displayMode, setDisplayMode] = useState(0);

  function genererFichier() {
    navigate("/validation");
  }
  function handleBeginDateChange(e) {
    const datedebut = moment(new Date(e)).format("YYYY-MM-DD");
    setBeginDate(e);
    setDateStart(moment(new Date(e)).format("DD-MM-YYYY"));
    if (endDate && endDate !== null && endDate !== undefined) {
      if (endDate < datedebut) {
        setErrorDate(false);
      } else {
        // setEndDate(e);
        setErrorDate(true);
        setOpenLoadingSimpleBackdrop(true);
        LeaveService.getMonth(e.toISOString(), endDate.toISOString()).then((result) => {
          if (result && result.data) {
            setMonth(result.data);
            setIdMonth(result.month);
            setMois(result.data[0]);
            setMax(result.data.length - 1);
            setTotalDay(result.total);
            setJour(result.total[0]);
            jourcomplet(result.total[0]);
            setTableMonth(result.month);
            setId(result.month[0]);
            setLigneForMonth(result.month[0]);
            console.log("mois", result.data[0]);
            console.log("jour", result.data[0]);
            LeaveService.getActualLeave(result.month[0], result.total[0], datedebut, moment(new Date(endDate)).format("YYYY-MM-DD")).then((result) => {
              if (result && result.status) {
                setGroups(result.groups);
                setItems(result.items);
                // setFinal(result.table);
                setMessageError(result.status);
                setShowMessageError(false);

                setOpenLoadingSimpleBackdrop(false);
              } else {
                setFinal([]);
                setShowMessageError(true);
                setIsBrowseMonth(false);
                setOpenLoadingSimpleBackdrop(false);
              }
            });
            // loadtable(result.total[0], result.month[0]);
          }
        });
        setIsResult(true);
      }
    }
  }

  function handleEndDateChange(e) {
    const datefin = moment(new Date(e)).format("YYYY-MM-DD");
    setEndDate(e);
    setDateEnd(moment(new Date(e)).format("DD-MM-YYYY"));
    if (beginDate && beginDate !== null && beginDate !== undefined) {
      if (beginDate > datefin) {
        setErrorDate(false);
      } else {
        setErrorDate(true);
        setOpenLoadingSimpleBackdrop(true);
        LeaveService.getMonth(beginDate.toISOString(), e.toISOString()).then((result) => {
          if (result && result.data) {
            setMonth(result.data);
            setIdMonth(result.month);
            setMois(result.data[0]);
            setMax(result.data.length - 1);
            setTotalDay(result.total);
            setJour(result.total[0]);
            jourcomplet(result.total[0]);
            setTableMonth(result.month);
            setId(result.month[0]);
            setLigneForMonth(result.month[0]);
            console.log("mois", result.month[0]);
            console.log("jour", result.data[0]);
            LeaveService.getActualLeave(result.month[0], result.total[0], moment(new Date(beginDate)).format("YYYY-MM-DD"), datefin).then((result) => {
              if (result && result.status) {
                setGroups(result.groups);
                setItems(result.items);
                // setFinal(result.table);
                setMessageError(result.status);
                setShowMessageError(false);
                setOpenLoadingSimpleBackdrop(false);
              } else {
                setFinal([]);
                setShowMessageError(true);
                setIsBrowseMonth(false);
                setOpenLoadingSimpleBackdrop(false);
              }
            });
            // loadtable(result.total[0], result.month[0]);
          }
        });
        setIsResult(true);
      }
    }
  }
  function jourcomplet($id) {
    const day = new Array();
    for (let index = 0; index < $id; index++) {
      day[index] = index + 1;
    }
    setJours(day);
  }
  function loadtable(jour, mois, begin_time, end_time) {
    // if(displayMode === 1){
    //   setOpenLoadingSimpleBackdrop(true);
    // }
    LeaveService.getActualLeave(mois, jour, begin_time, end_time).then((result) => {
      if (result && result.status) {
        console.log("final", result.table);
        setFinal(result.table);
        setMessageError(result.status);
        setShowMessageError(false);
        // if(displayMode === 1){
        //   setOpenLoadingSimpleBackdrop(false);
        // }
      } else {
        setShowMessageError(true);
        console.log("final", result.table);
        setFinal([]);
        // if(displayMode === 1){
        //   setOpenLoadingSimpleBackdrop(false);
        // }
      }
    });
  }
  function handlenext() {
    const ma = ligne + 1;
    const ms = month[ma];
    const ligne_month = ligneForMonth + 1;
    setMois(ms);
    setLigne(ma);
    setLigneForMonth(ligne_month);
    const next = totalDay[ma];
    const year = new Date(beginDate.toISOString()).getFullYear();
    const beginTime = `${year + "-" + (ligne_month < 10 ? "0" + ligne_month : ligne_month) + "-01"}`;
    const endTime = `${year + "-" + (ligne_month < 10 ? "0" + ligne_month : ligne_month) + "-" + next}`;
    console.log("ma***", ma);
    console.log("ma***", month);
    setJour(next);
    jourcomplet(next);
    setId(tableMonth[ma]);
    // loadtable(next, tableMonth[ma], beginTime, endTime);
    setIsBrowseMonth(true);
  }
  function handlebefore() {
    const ma = ligne - 1;
    const ms = month[ma];
    const ligne_month = ligneForMonth - 1;
    setMois(ms);
    setLigne(ma);
    setLigneForMonth(ligne_month);
    const before = totalDay[ma];
    const year = new Date(beginDate.toISOString()).getFullYear();
    const beginTime = `${year + "-" + (ligne_month < 10 ? "0" + ligne_month : ligne_month) + "-01"}`;
    const endTime = `${year + "-" + (ligne_month < 10 ? "0" + ligne_month : ligne_month) + "-" + before}`;
    setJour(before);
    jourcomplet(before);
    setId(tableMonth[ma]);
    // loadtable(before, tableMonth[ma], beginTime, endTime);
    setIsBrowseMonth(true);
  }


  const handleClose = () => {
    setOpenDialogTimeline(false);
    setValue(1);
    // setDisplayMode(1);
  };

  useEffect(() => {
    if (value === 2) {
      setOpenDialogTimeline(true);
      setDisplayMode(0);
      // console.log("ccccc**", groups.length);
    } else {
      setDisplayMode(1);
    }
  }, [value]);

  useEffect(() => {

    console.log("mois**", mois);
    console.log("jour**", jour);
    console.log("id**", id);


    const ligne_month = id;
    if (beginDate && beginDate !== null) {

      const year = new Date(beginDate.toISOString()).getFullYear();
      const beginTime = `${year + "-" + (ligne_month < 10 ? "0" + ligne_month : ligne_month) + "-01"}`;
      const endTime = `${year + "-" + (ligne_month < 10 ? "0" + ligne_month : ligne_month) + "-" + jour}`;

      console.log("ligneForMonth**", ligneForMonth);
      console.log("beginTime**", beginTime);
      console.log("endTime**", endTime);

      loadtable(jour, id, beginTime, endTime);
    }
    // if (final.length != 0) {
    //   console.log("final**", final);
    // }
  }, [id]);

  useEffect(() => {
    if (groups.length != 0 && items.length != 0) {

      setDisplayMode(0);
      console.log("groups.length**", groups.length);
      console.log("items.length**", items.length);
      var Difference_In_Time = endDate.getTime() - beginDate.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      setdifference_In_Days(Difference_In_Days);
      setValue(2);
    }
  }, [groups, items]);


  useEffect(() => {
    if (displayMode === 0) {
      setOpenDialogTimeline(true);
    } else {
      setOpenDialogTimeline(false);
    }
  }, [displayMode]);

  // useEffect(() => {
  //   if (openDialogTimeline) {
  //     setDisplayMode(0);
  //   } else {
  //     setDisplayMode(1);
  //   }
  // }, [openDialogTimeline]);

  function handleChangedisplayMode(e) {
    const value = e.target.value;
    setDisplayMode(value);
  }

  return (
    <React.Fragment>
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />

      <Grid container item xs={12} spacing={3} justifyContent="center">
        {/* <Grid item xs={3}>
        </Grid> */}
        <Grid item xs={3}>

          <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              value={beginDate}
              onChange={handleBeginDateChange}
              renderInput={(params) =>
                <TextField {...params} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" />
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              value={endDate}
              onChange={handleEndDateChange}
              renderInput={(params) =>
                <TextField {...params} fullWidth variant="outlined" id="date-picker-dialog" size="small" color="primary" />
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-outlined-label">Mode d'affichage</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Mode d'affichage" value={displayMode} name="displayMode" onChange={handleChangedisplayMode} >

              <MenuItem value={0}>Vue global</MenuItem>
              <MenuItem value={1}>Vue par mois</MenuItem>

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {messageError == true &&
            <>

              <div className={classes.divLegendeTimeline}>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullBeige}:Jour non comptabilisé(férié/weekend)
            </Typography>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullBlue}:En attente de validation
            </Typography>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullBlueEdited}:Congé confirmé par un supérieur hiérarchique
            </Typography>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullBlueYellow}:Congé confirmé par tous supérieurs hiérarchiques requis
            </Typography>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullGreen}:Congé consommé
            </Typography>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullRed}:congé Annulé ou refusé par un supérieur hierarchique
            </Typography>
              </div>
              <p className={classes.mois} >
                {ligne == 0 && max == 0 &&
                  <>
                    <div className={classes.bouton} >
                      {mois}
                    </div>
                  </>
                }
                {ligne == 0 && max > 0 &&
                  <>
                    <div className={classes.bouton} >
                      {mois}<Button onClick={handlenext}> <NavigateNext /></Button>
                    </div>
                  </>
                }
                {ligne != 0 && ligne != max &&
                  <>
                    <div className={classes.bouton} >
                      <Button onClick={handlebefore}> <NavigateBefore /></Button> {mois}<Button onClick={handlenext}> <NavigateNext /></Button>
                    </div>
                  </>
                }
                {ligne != 0 && max != 0 && ligne == max &&
                  <>
                    <div className={classes.bouton} >
                      <Button onClick={handlebefore}> <NavigateBefore /></Button> {mois}
                    </div>
                  </>
                }
              </p>
            </>
          }
        </Grid >
        {isResult == false &&
          <Grid item xs={12}>
            <Alert >Veuillez choisir deux dates</Alert>
          </Grid>
        }
        {showMessageError &&
          <Grid item xs={12}>
            <Alert severity="error">Aucun congé valider {isBrowseMonth ? " pour ce mois" : " entre ces deux dates"}</Alert>
          </Grid>
        }
        {errorDate == false &&
          <Grid item xs={12}>
            <Alert severity="error">La date de debut doit etre inferieur à la date fin </Alert>
          </Grid>
        }
        <Grid item xs={12}>
          {final.length != 0 &&
            <TableContainer className={classes.root}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.root}>Demandeur</TableCell>
                    {jours.map((row) => (
                      <TableCell className={classes.root}>{row}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {final.map((row) => (
                    <TableRow >
                      {row['exist'] != null &&
                        <TableCell className={classes.root}>{row["name"]}  {row["lastname"]}</TableCell>}
                      {jours.map((index) => (
                        <>
                          {row['exist'] != null && row[index] != "null" && row[index] == "dayNotCompted" && <TableCell className={classes.daynotCompted}></TableCell>}
                          {row['exist'] != null && row[index] == "null" && row[index] != "dayNotCompted" && <TableCell className={classes.root}></TableCell>}
                          {row['exist'] != null && row[index] != "null" && row[index] != "dayNotCompted" && row['state'] == 0 && <TableCell className={classes.leavePending}></TableCell>}
                          {row['exist'] != null && row[index] != "null" && row[index] != "dayNotCompted" && row['state'] == 1 && <TableCell className={classes.leaveConfirmed}></TableCell>}
                          {row['exist'] != null && row[index] != "null" && row[index] != "dayNotCompted" && row['state'] == 2 && <TableCell className={classes.leaveValidate}></TableCell>}
                          {row['exist'] != null && row[index] != "null" && row[index] != "dayNotCompted" && row['state'] == 3 && <TableCell className={classes.leaveConsumes}></TableCell>}
                          {row['exist'] != null && row[index] != "null" && row[index] != "dayNotCompted" && row['state'] == 4 && <TableCell className={classes.leaveCanceled}></TableCell>}
                        </>
                      ))}
                    </TableRow >
                  ))}
                </TableBody>
              </Table>

            </TableContainer>
          }
        </Grid>

      </Grid>

      <Dialog
        fullScreen
        open={openDialogTimeline}
        onClose={handleClose}
        TransitionComponent={Transition}
        disableScrollLock
      >

        <DialogTitle>
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" zIndex="4000">
                Liste demande de congé réel entre <strong>{dateStart}</strong> et <strong>{dateEnd}</strong>
              </Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent style={{ padding: "0" }}>

          <DialogContentText id="alert-dialog-description" >
            <div style={{background:"white", position: "fixed", minHeight: "10vh", top: "6vh", margin: "auto", width: "100%", zIndex:"2000" }}>
              <div style={{height:"3vh"}}></div>
              <Typography sx={{ fontSize: 15, color: 'black', fontWeight: 'bold', textAlign: 'center' }} color="text.secondary" gutterBottom>
                <u>Légende des couleurs</u>
              </Typography>

              <div className={classes.divLegendeTimeline}>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullBeige}:Jour non comptabilisé(férié/weekend)
              </Typography>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullBlue}:En attente de validation
              </Typography>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullBlueEdited}:Congé confirmé par un supérieur hiérarchique
              </Typography>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullBlueYellow}:Congé confirmé par tous supérieurs hiérarchiques requis
              </Typography>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullGreen}:Congé consommé
              </Typography>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullRed}:congé Annulé ou refusé par un supérieur hierarchique
              </Typography>
              </div>

            </div>

            <Grid container xs={12} className={classes.timelineGrid}>
              <Grid item xs={12}>
                <Timeline open={openDialogTimeline} groups={groups} items={items}
                  start_time={beginDate} end_time={endDate} difference_In_Days={difference_In_Days}
                  id_parameter={id_parameter} openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />

              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>

  );
}
