export const headerDataSettings = {
    title: 'Paramétrage',
    
    submodules: [
        createData(0, "Financement", "/funding"),
        createData(1, "Type et validation", "/number_validation"),
        createData(2, "Facteur de comptage", "/leave_type"),
        createData(3, "Jours non comptabilisés", "/annual_calendar"),
        createData(4, "Periode de validation", "/validation_planning"),
        createData(5, "Configuration solde", "/balance_configuration"),
        createData(6, "Champ Dynamique", "/champ_dynamique"),
    ],
}

function createData(id, moduleName, ref) {
    return { id, moduleName, ref };
}