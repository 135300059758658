import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Acces_directionTable from './acces_directionTable';
import { Button } from '@material-ui/core';
import Spacer from '../../components/Spacer';
import MainContainer from '../../components/MainContainer';
import { headerDataAdmin } from '../../data/modules/admin-header';
import { userDetailContext } from "../../App";
import {useContext} from "react";
import { hasPrivilege } from "../../utils/privilege";
import Title from "../../components/Title";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    elevation: 0
  },
}));


export default function Acces_directionList() {

  const navigate = useNavigate();
  const classes = useStyles();
  function handleClick() {
      navigate("/acces_direction/add");
  }
  const contextData = useContext(userDetailContext);
  return (
    
    <MainContainer {...headerDataAdmin}>
      <Grid container item xs={12}>
        <Spacer x={2} />
        {hasPrivilege(contextData.privileges, 'Admin-Button-Export-Privilege') &&
        <Button
          variant="contained"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
         
         export
        </Button>
          }
        <Spacer x={2} />
        {hasPrivilege(contextData.privileges, 'Admin-Privilege-Add') &&
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }}>
          CREER
        </Button>
         }
        <Spacer x={2} />
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <Acces_directionTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
