import {
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { set } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../components/MainContainer";
import Spacer from "../../components/Spacer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import EquipmentConfigService from "../../services/EquipmentConfigService";
import Message from "../tools/Message";
import Alert from "@material-ui/lab/Alert";
import Box from "@mui/material/Box";
const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
    }}
  >
    *
  </Box>
);
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 1000,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: "left",
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));
function EquipmentConfigAdd(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [equipment_config, setEquipmentConfig] = useState([]);
  const [configIsEmpty, setConfigIsEmpty] = useState(false);
  const [message, setMessage] = useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;
  const [form, setForm] = useState({
    equipment_config_id: "",
    configuration: "",
    description_config: "",
    type_config: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  function handleChange(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
  }
  function handleClick() {
    const idGroup = splitLocation[4];
    console.log("configuration :"+form.description_config);
    if (idGroup) {
      EquipmentConfigService.update(
        form.configuration,
        form.description_config,
        idGroup
      )
        .then((results) => {
          if (results.status) {
            setMessage({
              ...message,
              open: true,
              color: "green",
              messageInfo: "succès",
            });
            setTimeout(() => {
              navigate("/Logistic/config");
            }, 1000);
            
          } else {
            setMessage({
              ...message,
              open: true,
              color: "red",
              messageInfo: "Une erreur s'est produite. Veuillez réessayer",
            });
          }
        })
        .catch((err) => {
          alert("error");
        });
    } else {
      EquipmentConfigService.save(
        form.configuration,
        form.description_config,
        form.type_config
      )
        .then((results) => {
          if (results.status) {
            setMessage({
              ...message,
              open: true,
              color: "green",
              messageInfo: results.data.message,
            });
            setTimeout(() => {
              navigate("/Logistic/config");
            }, 1000);
            
          } else {
            setMessage({
              ...message,
              open: true,
              color: "red",
              messageInfo: "Une erreur s'est produite. Veuillez réessayer",
            });
          }
        })
        .catch((err) => {
          alert("error");
        });
    }
    if (form.configuration == null) {
      setConfigIsEmpty(true);
    }
  }
  function handleCancel() {
    navigate("/Logistic/config");
  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  useEffect(() => {
    const idGroup = splitLocation[4];
    if(idGroup){
      setForm({
        configuration : localStorage.getItem("configuration"),
        description_config : localStorage.getItem("description_config"),
        type_config : localStorage.getItem("type_config"),
      });
      localStorage.removeItem("configuration");
      localStorage.removeItem("description_config");
      localStorage.removeItem("type_config");
      setIsDisabled(true);
    }
    
  }, []);
  return (
    <MainContainer {...headerDataImmo}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />

      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Configuration {bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Configuration"
              variant="outlined"
              onChange={handleChange}
              value={form.configuration}
              name="configuration"
            />
            {configIsEmpty && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Ce champ est obligatoire!</Alert>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Type {bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Type"
                name="type_config"
                disabled={isDisabled}
                value={form.type_config}
                onChange={handleChange}
              >
                <MenuItem value="TEXTE">TEXTE</MenuItem>
                <MenuItem value="NUMERIQUE">NUMERIQUE</MenuItem>
                <MenuItem value="DATE">DATE</MenuItem>
                <MenuItem value="BOOLEEN">BOOLEEN</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Description</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Description"
              variant="outlined"
              onChange={handleChange}
              value={form.description_config}
              name="description_config"
            />
          </Grid>
          <Grid item xs={12}>
            <label className={classes.text}>
              ({bullRed}):Champs obligatoires
            </label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}
export default EquipmentConfigAdd;
