import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Paper } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import ServiceTable from "./ServiceTable";
import { headerDataAdmin } from "../../data/modules/admin-header";
import { userDetailContext } from "../../App";
import ServiceService from "../../services/ServiceService";
import ExportExcel from "./ExportExcel";
import ImportCSV from "./ImportCSV";
import { hasPrivilege } from "../../utils/privilege";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function ServiceList() {
  const navigate = useNavigate();
  var myCurrentDate = new Date();
  var date = myCurrentDate.getFullYear() + '_' + (myCurrentDate.getMonth()+1) + '_' + myCurrentDate.getDate() +'_'+ myCurrentDate.getHours()+'_'+ myCurrentDate.getMinutes()+'_'+ myCurrentDate.getSeconds();
  
  const classes = useStyles();
  const contextData = useContext(userDetailContext);
  const [fileName, setFileName] = useState('service_'+date);
  const [etat, setEtat] = useState({open:false});

  const [dataToExport, setDataToExport] = useState([]);
  useEffect(() => {
    ServiceService.exportExcel().then((result) => {
      if(result && result.data){
        setDataToExport(result.data);
      }
    });
  }, []);

  function handleClick() {
    navigate("/services/add");
  }

  function handleClickImport() {
    setEtat({
      open: true
    });
  }

  function handleCloseDialog(){
    setEtat({
      open: false
    });
  }

  return (
    <MainContainer {...headerDataAdmin}>
      <Grid container item xs={12}>
      {/* {hasPrivilege(contextData.privileges, 'Admin-Button-Import-Service') && */}
        <Button variant="contained" onClick={handleClickImport} style={{ fontWeight: 600 }} >IMPORTER</Button>
      {/* } */}
        <ImportCSV isActive={etat.open} closeDialog={handleCloseDialog} />
        <Spacer x={2} />
        {/* {hasPrivilege(contextData.privileges, 'Admin-Button-Export-Service') && */}
        <ExportExcel dataToExport={dataToExport} fileName={fileName} />
        {/* } */}
        <Spacer x={2} />
        {hasPrivilege(contextData.privileges, 'Admin-Service-Add') &&
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >CREER</Button>}
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
          <ServiceTable />
        </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
