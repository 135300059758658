import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import Button from "@material-ui/core/Button";
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import EquipmentConfigService from "../../services/EquipmentConfigService";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import Message from "../tools/Message";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { escapeRegExp } from "../../utils";
import Title from "../../components/Title";

export default function EquipmentConfigRankingTable() {
  const [rows, setRows] = useState([]);

  const [id_to_delete, setId_to_delete] = useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: "Voulez vous vraiment supprimer  ?",
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    React.useState(false);
  function loadTable() {
    setOpenLoadingSimpleBackdrop(true);
    EquipmentConfigService.getListRanking().then((result) => {
      if (result && result.status) {
        console.log("result******", result);
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.equipment_ranking_id,
          }))
        );
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }
  useEffect(() => {
    loadTable();
  }, []);
  const handleDelete = (id) => (ev) => {
    setId_to_delete(id);
    setConfirmationDialog({
      ...confirmationDialog,
      openDialog: true,
      messageDialog: "Voulez vous vraiment supprimer?",
    });
  };
  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    EquipmentConfigService.deleteEquipmentConfig(id_to_delete)
      .then((result) => {
        if (result.status) {
          loadTable();
          setMessage({
            ...message,
            open: true,
            color: "green",
            messageInfo: result.message,
          });
        } else {
          setMessage({
            ...message,
            open: true,
            color: "red",
            messageInfo: result.message,
          });
        }
      })
      .catch((err) => {
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: "erreur",
        });
      });
  }
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = [...rows].filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
  };
  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  const handleEdit = (id) => (ev) => {
    window.location.href = "/Logistic/config/add/" + id;
  };
  const columns = [
    {
      field: "equipment_ranking_name",
      headerName: "Classement matériel",
      width: 500,
    },
    {
      field: "configuration",
      headerName: "configuration matériel",
      width: 500,
    },
  ];
  return (
    <>
      <ConfirmationDialog
        openDialog={openDialog}
        messageDialog={messageDialog}
        closeDialog={closeDialog}
        confirmDialog={confirmDialog}
        cancelDialog={cancelDialog}
      />
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <Title>Liste des configurations des matériels</Title>

      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          pageSize={7}
          disableSelectionOnClick
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
        />
      </div>
    </>
  );
}
