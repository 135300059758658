import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MainContainer from "../../../components/MainContainer";
import { headerDataLibrary } from "../../../data/modules/rubrique_header";
import Spacer from "../../../components/Spacer";
import { useLocation, useNavigate } from "react-router";
import DocumentService from "../../../services/DocumentService";
import Result from "../../personnel/Result";
import { Edit } from "@material-ui/icons";
import RubriquesService from "../../../services/RubriquesService";
import SeatService from "../../../services/SeatService";
import SeatsService from "../../../services/SeatsService";
import BuildingService from "../../../services/BuildingService";

const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
    }}
  >
    *
  </Box>
);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: "left",
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

export default function EmplacementAdd() {
  const classes = useStyles();

  const navigate = useNavigate();

  const [form, setForm] = useState({
    id: "",
    direction_id: "",
    seat_id: "",
    building_id: "",
    storage_place: "",
    storage_1: "",
    storage_2: "",
    storage_3: "",
  });

  const [etats, setEtats] = useState({
    open: false,
    message: "",
    color: "",
  });

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [direction, setDirection] = useState([]);
  const [seat, setSeat] = useState([]);
  const [building, setBuilding] = useState([]);

  function handleClickAnnuler() {
    navigate("/emplacement");
  }

  function handleClickEnregistrer() {
    DocumentService.saveLocation(
      form.direction_id,
      form.seat_id,
      form.building_id,
      form.storage_place,
      form.storage_1,
      form.storage_2,
      form.storage_3,
      form.id
    ).then((response) => {
      if (response.status) {
        console.log("Emplacement enregistré");
        setEtats({
          ...etats,
          open: true,
          color: "green",
          message: response.message,
        });
        navigate("/emplacement");
      } else {
        console.log("tsy tafiditra");
        setEtats({
          ...etats,
          open: true,
          color: "red",
          message: response.message,
        });
      }
    });
  }

  function handleCloseResultMessage() {
    setEtats({ ...etats, open: false });
  }

  useEffect(() => {
    const EditId = splitLocation[3];
    RubriquesService.getDirection().then((response) => {
      if (response.status) {
        setDirection(response.data);
      }
    });

    SeatsService.getAllSeats().then((result) => {
      if (result.status == true) {
        setSeat(result.data);
      }
    });

    BuildingService.getBuilding().then((result) => {
      if (result.status) {
        setBuilding(result.data);
      }
    });
    if (EditId) {
      
      DocumentService.getLocationById(EditId).then((library) => {
        if (library.status) {
          console.log("Edit data : ", library.data);
          setForm({
            id: EditId,
            direction_id: library.data.direction_id,
            seat_id: library.data.seat_id,
            building_id: library.data.building_id,
            storage_place: library.data.storage_place,
            storage_1: library.data.storage_1,
            storage_2: library.data.storage_2,
            storage_3: library.data.storage_3,
          });

          DocumentService.getSeat(library.data.direction_id).then((result) => {
            if (result.status == true) {
              setSeat(result.data);
              console.log("bureau pour direction_id", library.data.direction_id, ": ", result.data);
            }
          });

          DocumentService.getBuilding(library.data.seat_id).then((result) => {
            if (result.status) {
              setBuilding(result.data);
              console.log("batiment pour seat_id", library.data.seat_id, ": ", result.data);
            }
          });
        }
      
      });
    }
  }, [location]);


  function handleChange(event) {
    const { name, value } = event.target;

    switch (name) {
      case "building_id":
        setForm({ ...form, [name]: value });
        break;
      case "storage_place":
        setForm({ ...form, [name]: value });
        break;
      case "storage_1":
        setForm({ ...form, [name]: value });
        break;
      case "storage_2":
        setForm({ ...form, [name]: value });
        break;
      case "storage_3":
        setForm({ ...form, [name]: value });
        break;
    }
  }

  function handleChangeDirection(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    DocumentService.getSeat(value).then((result) => {
      if (result.status == true) {
        setSeat(result.data);
        console.log("bureau pour direction_id", value, ": ", result.data);
      }
      else{
        setEtats({
          ...etats,
          open: true,
          color: "red",
          message: result.message,
        });
      }
    });
  }
  function handleChangeSeat(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    DocumentService.getBuilding(value).then((result) => {
      if (result.status) {
        setBuilding(result.data);
        console.log("batiment pour seat_id", value, ": ", result.data);
      }
    });
  }

  return (
    <MainContainer {...headerDataLibrary}>
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClickEnregistrer}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>

        <Spacer x={2} />

        <Button
          variant="contained"
          color="grey"
          onClick={handleClickAnnuler}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>

      <Spacer y={4} />

      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Direction {bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Choisir 
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={form.direction_id}
                name="direction_id"
                label="Direction"
                onChange={handleChangeDirection}
              >
                {direction.map((row) => (
                  <MenuItem value={row.direction_id}>{row.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>Bureau{bullRed}</label>
          </Grid>

          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Choisir 
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={form.seat_id}
                name="seat_id"
                label="Bureau"
                onChange={handleChangeSeat}
              >
                {seat.map((row) => (
                  <MenuItem value={row.seat_id}>{row.seat_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>Bâtiment{bullRed}</label>
          </Grid>

          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Choisir
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={form.building_id}
                name="building_id"
                label="Bâtiment"
                onChange={(e) => handleChange(e)}
              >
                {building.map((row) => (
                  <MenuItem value={row.building_id}>{row.building_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Lieu de stockage{bullRed}
            </label>
          </Grid>

          <Grid item xs={8}>
            <TextField
              id="outlined-multiline-static"
              multiline
              size="small"
              variant="outlined"
              onChange={(e) => handleChange(e)}
              value={form.storage_place}
              name="storage_place"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Rangement 1 {bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              variant="outlined"
              onChange={(e) => handleChange(e)}
              inputProps={{ textTransform: "capitalize" }}
              value={form.storage_1}
              name="storage_1"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>Rangement 2</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              variant="outlined"
              onChange={(e) => handleChange(e)}
              inputProps={{ textTransform: "capitalize" }}
              value={form.storage_2}
              name="storage_2"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>Rangement 3</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              variant="outlined"
              onChange={(e) => handleChange(e)}
              inputProps={{ textTransform: "capitalize" }}
              value={form.storage_3}
              name="storage_3"
            />
          </Grid>

          <Grid item xs={12}>
            <label className={classes.text}>
              ({bullRed}):Champs obligatoires{" "}
            </label>
          </Grid>
        </Grid>
      </Paper>

      <Result
        message={etats.message}
        color={etats.color}
        open={etats.open}
        handleClose={handleCloseResultMessage}
      />
    </MainContainer>
  );
}
