export const headerDataLibrary = {
    title: 'GED',
    submodules: [
        createData(0, "Rubrique Principale", "/principale"),
        createData(1, "Rubrique Secondaire", "/secondaire"),
        createData(2, "Document", "/document"),
        // createData(3, "Annuaire", "/annuaire"),
        // createData(4, "Information sur la Famille", "/information_famille"),
    ],
}

function createData(id, moduleName, ref) {
    return { id, moduleName, ref };
}