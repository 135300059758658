import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const UserSpecialValidatorService = {
  //ADD/EDIT USER SPECIAL VALIDATEUR 
  async saveUserSpecialSuperior(userId, listUserSup, id) {
    try {
      const result = id ?
        await axios.put(`${apiUrl}/userSpecialValidator/id/${id}`, {
          userId,
          id,
          listUserSup
        })
        :
        await axios.post(`${apiUrl}/userSpecialValidator/`, {
          userId,
          listUserSup
        });
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async getConfigSpecialValidateur(userId) {
    try {
      const result = await axios.get(`${apiUrl}/userSpecialValidator/getConfigSpecialValidateur/${userId}`);
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async deleteSpecialValidator(userId) {
    try {
      const result = await axios.delete(`${apiUrl}/userSpecialValidator/deleteSpecialValidator/${userId}`);
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  
  async getSupSpecialValidateurByUserId(userId) {
    try {
      const result = await axios.get(`${apiUrl}/userSpecialValidator/getSupSpecialValidateur/${userId}`);
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  
  async getById(id) {
    try {
      const result = await axios.get(`${apiUrl}/userSpecialValidator/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

}

export default UserSpecialValidatorService;