import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const EquipmentConfigService = {
  async save(configuration, description_config, type_config) {
    try {
      const result = await axios.post(`${apiUrl}/equipmentConfig`, {
        configuration,
        description_config,
        type_config,
      });
      if (result) {
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async saveConfig(formData) {
    try {
      const result = await axios.post(
        `${apiUrl}/equipmentConfig/configuration`,
        {
          immo_config: formData.immo_config,
        }
      );
      if (result) {
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getAllEquipmentConfig() {
    try {
      const result = await axios.get(`${apiUrl}/equipmentConfig/`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getConfigById(id) {
    try {
      const result = await axios.get(`${apiUrl}/equipmentConfig/config/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getListRanking() {
    try {
      const result = await axios.get(`${apiUrl}/equipmentConfig/list`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async verification(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/equipmentConfig/verification/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getAllEquipmentConfigId(id) {
    try {
      const result = await axios.get(`${apiUrl}/equipmentConfig/id/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async update(configuration, description_config, id) {
    try {
      const result = await axios.put(`${apiUrl}/equipmentConfig/${id}`,{
        configuration,
        description_config
      });
      if (result) {
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async deleteEquipmentConfig(id) {
    try {
      const result = await axios.delete(`${apiUrl}/EquipmentConfig/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data);
      }
      throw error;
    }
  },
  async deleteConfig(id) {
    try {
      const result = await axios.delete(
        `${apiUrl}/EquipmentConfig/config/${id}`
      );
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data);
      }
      throw error;
    }
  },
};
export default EquipmentConfigService;
