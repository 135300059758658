import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@mui/material/TextField';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  dialogActions: {
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  button: {
    minWidth: '100px',
    margin: theme.spacing(1),
  },
}));

export default function ValidateMeetingDialog(props) {
  const { openDialogMetting, messageDialogMetting, closeDialogMetting, handleRefused, confirmDialogApproved } = props;
  const [comment, setComment] = useState('');
  const classes = useStyles();

  useEffect(() => {
    setComment('');
  }, [openDialogMetting]);

  return (
    <Dialog open={openDialogMetting} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        {"Validation de Réunion"}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description">
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="body1" gutterBottom>
                {messageDialogMetting}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                label="Commentaire"
                variant="outlined"
                fullWidth
                multiline
                minRows={6}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.button}
          style={{ backgroundColor: "#f50057", color: "#FFFFFF" }}
          color="secondary"
          variant="contained"
          onClick={closeDialogMetting}
        >
          Annuler
        </Button>
        <Button
          className={classes.button}
          style={{ backgroundColor: "#3f51b5", color: "#FFFFFF" }}
          color="primary"
          variant="contained"
          onClick={() => handleRefused(comment)}
        >
          Refuser
        </Button>
        <Button
          className={classes.button}
          style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF" }}
          color="primary"
          variant="contained"
          onClick={() => confirmDialogApproved(comment)}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}
