import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataAdmin } from "../../data/modules/admin-header";
import LeaveTypeService from "../../services/LeaveTypeService";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";
import Message from "../tools/Message";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import Box from '@mui/material/Box';
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  CountDayOfMonth: {
    margin: "auto"
  },
  lblGrid: {
    margin: "auto"
  }
}));

function LeaveTypeAdd(props) {
  const [failedLogin, setFailedLogin] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const [message, setMessage] = useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const [errorMessage, setErrorMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [data, setData] = React.useState([]);
  const [isLibelleEmpty, setLibelleIsEmpty] = React.useState(false);
  const [isCountFactorEmpty, setCountFactorIsEmpty] = React.useState(false);
  const [isCountDayEmpty, setCountDayIsEmpty] = React.useState(false);
  const [isDescEmpty, setDescIsEmpty] = React.useState(false);
  const [isCountDayValid, setCountDayValid] = React.useState(true);

  const params = useParams();
  const id = params.id;
  const leave_type_id = id ? id : "";
  const [form, setForm] = useState({
    name: "",
    desc: "",
    leave_count_factor: "",
    leave_type_date: ""
  });

  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez vous vraiment supprimer  ?',
  });

  const { openDialog, messageDialog } = confirmationDialog;

  function saveLeaveType() {
    LeaveTypeService.saveLeaveType(
      form.name,
      form.desc,
      form.leave_count_factor,
      form.leave_type_date,
      leave_type_id
    )
      .then((res) => {
        var responseErrorMessage = res.message;
        if (res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: res.message });
          navigate("/leave_type");
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: res.message });
        }
      })
      .catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'Une erreur est produite,Veuillez réessayer' });
      });
  }

  function handleClick() {

    if (form.name !== "" && form.leave_count_factor !== "" && form.leave_type_date !== "" && form.leave_type_date < 32) {
      LeaveTypeService.checkCountDate(form.leave_type_date).then((res) => {
        if (res.status) {
          saveLeaveType();
        } else {
          setConfirmationDialog({ ...confirmationDialog, openDialog: true, messageDialog: res.message });
        }
      });
    } else if (form.name === "") {
      setLibelleIsEmpty(true);
    } else if (form.leave_count_factor === "") {
      setCountFactorIsEmpty(true);
    } else if (form.leave_type_date === "") {
      setCountDayIsEmpty(true);
    } else if (form.desc === "") {
      setDescIsEmpty(true);
    } else if (form.leave_type_date > 31) {
      setCountDayValid(false);
    }
  }

  function handleChange(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
    if (name == 'leave_type_date') {
      const countDayOfMonth = value.replace(/[^\d]/g, '');
      setForm({ ...form, [name]: countDayOfMonth });
    }
  }
  useEffect(() => {
    if (leave_type_id) {
      LeaveTypeService.LeaveTypeById(leave_type_id).then(({ data: info }) => {
        setForm({
          name: info.name,
          desc: info.desc,
          leave_count_factor: info.leave_count_factor,
          leave_type_date: info.leave_type_date,
          leave_type_id: leave_type_id,
        });
      });
    }
  }, []);
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  const contextData = useContext(userDetailContext);
  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    saveLeaveType();
  }
  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function handleCancel() {
    navigate("/leave_type");
  }
  return (
    <MainContainer {...headerDataAdmin}>
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} isCountFactParam={true} />
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
        {failedLogin && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={4} className={classes.lblGrid}>
            <label class="aligneleft">Libellé{bullRed}:</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Libellé"
              variant="outlined"
              onChange={handleChange}
              value={form.name}
              name="name"
              onFocus={(e) => setLibelleIsEmpty(false)}
              error={isLibelleEmpty}
            />

          </Grid>
          {isLibelleEmpty && <><Grid item xs={4}></Grid><Grid item xs={8}><Alert severity="error">Ce champ est obligatoire</Alert></Grid></>}
          <Grid item xs={4} className={classes.lblGrid}>
            <label class="aligneleft">Description{bullRed}:</label>
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Description"
              variant="outlined"
              onChange={handleChange}
              value={form.desc}
              name="desc"
            />
          </Grid>
          {isDescEmpty && <><Grid item xs={4}></Grid><Grid item xs={8}><Alert severity="error">Ce champ est obligatoire</Alert></Grid></>}
          <Grid item xs={4} className={classes.lblGrid}>
            <label class="aligneleft">Facteur de comptage{bullRed}:</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="facteur de comptage"
              variant="outlined"
              onChange={handleChange}
              value={form.leave_count_factor}
              name="leave_count_factor"
              onFocus={(e) => setCountFactorIsEmpty(false)}
              error={isCountFactorEmpty}
            />
          </Grid>
          {isCountFactorEmpty && <><Grid item xs={4}></Grid><Grid item xs={8}><Alert severity="error">Ce champ est obligatoire</Alert></Grid></>}
          <Grid item xs={4} className={classes.lblGrid}>
            <label class="aligneleft">Jour de comptage{bullRed}:</label>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="date-picker-dialog"
              size="small"
              type="numeric"
              variant="outlined"
              onChange={handleChange}
              value={form.leave_type_date}
              name="leave_type_date"
              inputProps={{ maxLength: 2 }}
              onFocus={(e) => setCountDayIsEmpty(false)}
              error={isCountDayEmpty}
            />
          </Grid>
          <Grid className={classes.CountDayOfMonth} item xs={4}>
            du mois
          </Grid>
          {isCountDayEmpty && <><Grid item xs={4}></Grid><Grid item xs={8}><Alert severity="error">Ce champ est obligatoire</Alert></Grid></>}
          {!isCountDayValid && <><Grid item xs={4}></Grid><Grid item xs={8}><Alert severity="error">Le jour du mois ne dépasse pas 31!!</Alert></Grid></>}
          <Grid item xs={12}>
            <label className={classes.text}>({bullRed}):Champs obligatoires</label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}
export default LeaveTypeAdd;
