import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataRH } from "../../data/modules/hr-header";
import PersonnelTable from "./PersonnelTable";
import PersonnelService from "../../services/PersonnelService";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../utils/privilege";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  button: {
    float: 'right',
  },
}));

export default function PersonnelList() {
  const navigate = useNavigate();
  function handleClick() {
    navigate("/personnel/add");
  }

  useEffect(() => {
    PersonnelService.getList().then((result) => {
      if (result && result.data) {
        setDataToExport(result.data);
      }
    });
  }, []);

  const classes = useStyles();
  const [dataToExport, setDataToExport] = useState([]);
  const contextData = useContext(userDetailContext);

  return (
    <MainContainer {...headerDataRH}>
      <Grid container item xs={12}>
        <Spacer x={2} />
        {hasPrivilege(contextData.privileges, 'RH_Button_Create_Management_staff') &&
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            CREER
        </Button>}
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <PersonnelTable />
          </Paper>
        </Grid>

      </Grid>
    </MainContainer>
  );
}
