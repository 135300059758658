import React, { useState, useEffect } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import AttachFile from "@material-ui/icons/AttachFile";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { headerDataLibrary } from "../../../data/modules/rubrique_header";

import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Chip,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { useLocation } from "react-router-dom";
import Result from "../../personnel/Result";
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../../utils/privilege";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import DocumentService from "../../../services/DocumentService";
import RubriquesService from "../../../services/RubriquesService";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpIcon from "@mui/icons-material/Help";
import HelpDialog from "../../tools/HelpDialog";
import Title from "../../../components/Title";
import { useParams } from "react-router-dom";

const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
    }}
  >
    *
  </Box>
);
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: "left",
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

function DocumentAdd() {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const classes = useStyles();

  const [form, setForm] = useState({
    id: "",
    resume: "",
    mot_cle: "",
    auteur: "",
    titre: "",
    fichier: "",
    nom_rubrique_secondaire: "",
    rubrique_secondaire_id: [],
    rubrique_mere: "",
    rubrique_mere_id: "",
  });

  const Input = styled("input")({
    display: "none",
  });
  const params = useParams();
  const idDocument = params.id ? params.id : "";

  const [date_start, setDate_start] = useState(null);

  const [nameFile, setNameFile] = useState("Cliquez pour uploader");

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [open, setOpen] = React.useState(false);
  const [etats, setEtats] = useState({
    open: false,
    message: "message",
    color: "",
  });

  const navigate = useNavigate();

  const [dir, setDir] = useState([]);
  const [fichier, setFichier] = useState([]);
  const [iddir, setIddir] = React.useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });

  const [isFilenameEmpty, setIsFilenameEmpty] = useState(false);

  const [rubrique, setRubrique] = useState([]);

  const [loggedInUser, setLoggedInUser] = useState([]);

  const handleClickOpen = () => {
    navigate("/document/add/");
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/document");
  };

  const handleCloseMessage = () => {
    setEtats({ ...etats, open: false });
  };

  function handleChangeFile(event) {
    setSelectedFile(event.target.files[0]);
    setNameFile(event.target.files[0].name);
    console.log("setname " + nameFile);
    setForm({ ...form, fichier: event.target.files[0].name });
    console.log("****", event.target);
    console.log("********", event.target.files);
    console.log("************ ", event.target.files[0]);
  }

  useEffect(() => {
    const UserId = localStorage.getItem("USER");


    const activeUserId = parseInt(UserId);
    setLoggedInUser((oldArray) => [...oldArray, activeUserId]);

    if (idDocument) {
        setForm({
          id: idDocument
        });
    }


    DocumentService.getUserName(UserId).then((result) => {
      if (result.status) {
        setForm({
          auteur: result.data.name,
        });
      }
    });

    RubriquesService.getNom().then((res) => {
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const id = res.data[i]["id_rubrique"];

          RubriquesService.getAction(id, UserId).then((response) => {
            if (response.status) {
              // console.log("Action obtenue");
              const action = response.data["action"];

              if (action[0] === "1") {
                setRubrique((oldArray) => [...oldArray, res.data[i]]);
                console.log("rubrique can create: ", res.data[i]);
              } else {
                console.log(
                  "L'utilisateur connecté n'a pas le droit de creer un document pour rubrique de id ",
                  id
                );
              }
            } else {
              console.log(
                "Aucune action correspondante pour telle rubrique et cet utilisateur"
              );
            }
          });
        }
      }
    });

    const idGroup = splitLocation[3];
    if (idGroup) {
      // console.log("atoo",idGroup);
      DocumentService.documentById(idGroup).then((library) => {
        setForm({
          ...form,
          id: library.data.id,
          resume: library.data.resume,
          date_edition: library.data.date_edition,
          date_creation: library.data.date_creation,
          mot_cle: library.data.mot_cle,
          auteur: library.data.auteur,
          titre: library.data.titre,
          fichier: library.data.fichier,
          rubrique_mere: library.data.id_rubrique,
        });

        console.log("etooo",library.data.resume);
        console.log("itooo",form);
        //console.log("*********fichier:", library.data.fichier);
        setNameFile(library.data.fichier);
        DocumentService.getFichier(idGroup).then((result) => {
          if (result && result.data) {
            const filenameResult = result.data.fichier;
            DocumentService.readFileContent(filenameResult).then((result) => {
              if (result) {
                // console.log("un blob va etre creer");
                const data = new Blob([result.data], { type: fileType });
                //FileSaver.saveAs(data, filenameResult);
                setSelectedFile(data);
              }
            });
          }
        });

        setIddir(library.data.rubrique_secondaire_id);

        setFichier(library.data.fichier);

        DocumentService.getFichier(idGroup).then((response) => {
          if (response.status) {
            console.log("get fichier result: ", response);
          } else {
            console.log("fichier tsy azo");
          }
        });
      });
    }
  }, [setForm]);

  function handleClickEnregistrer() {

    const userId = localStorage.getItem("USER");
    
    if (form.fichier !== "" && form.fichier !== null && selectedFile !== null) {
      const formData = new FormData();
      formData.append("fichier", selectedFile, selectedFile.name);
      DocumentService.uploadFile(formData).then((response) => {
        if (response.status) {
          setMessage({
            ...message,
            open: true,
            color: "green",
            messageInfo: response.message,
          });

          console.log("form: ", form);

          DocumentService.saveDocument(
            form.auteur,
            form.resume,
            form.mot_cle,
            form.titre,
            form.fichier,
            form.rubrique_mere,
            form.id
          ).then((result) => {
            if (result.status) {

              console.log('doc enregistré');
              
              const lastInsertedDocumentId = result.lastInsertedDocumentId;

              if (result.insertedDocument !== undefined) {
                const documentIdInserted =
                  result.insertedDocument["document_id"];
                const documentName = result.insertedDocument["titre"];

                RubriquesService.saveHistory(
                  userId,
                  documentIdInserted,
                  documentName,
                  "Ajout d'un document"
                ).then((historyResult) => {
                  if (historyResult.status) {
                    console.log("Historique enregistrée");
                  } else {
                    console.log("Historique non enregistrée");
                  }
                });
              } else {
                const documentIdUpdated = result.updatedDocument["document_id"];
                const documentName = result.updatedDocument["titre"];

                RubriquesService.saveHistory(
                  userId,
                  documentIdUpdated,
                  documentName,
                  "Modification d'un document"
                ).then((historyResult) => {
                  if (historyResult.status) {
                    console.log("Historique de modification enregistrée");
                  } else {
                    console.log("Historique de modification non enregistrée");
                  }
                });
              }

              RubriquesService.savePrivilege(
                "document",
                0,
                1,
                0,
                0,
                1,
                lastInsertedDocumentId,
                loggedInUser,
                "specifique",
                1,
                userId
              ).then((resultPrivilege) => {
                if (resultPrivilege.status) {
                  console.log(
                    "resultPrivilege: ******************* ",
                    resultPrivilege
                  );
                  console.log("nety ny ketrika  tafiditra ny pv");
                } else {
                  console.log("tsy nety ny fika tsy tafiditra ny pv");
                }
              });

              setEtats({
                ...etats,
                open: true,
                color: "green",
                message: result.message,
              });

              navigate("/document");

              window.location.reload();
            } else {
              setEtats({
                ...etats,
                open: true,
                color: "red",
                message: result.message,
              });
            }
          });
        }
      });
    } else {
      console.log("champs vide");
      setEtats({
        ...etats,
        open: true,
        color: "red",
        message: "Veuillez renseigner les champs vide.",
      });
    }
  }

  function CapitalString(str) {
    var nextCapital = true;
    var newString = "";
    for (var i = 0; i < str.length; i++) {
      var cur = str.charAt(i);
      if (nextCapital) {
        newString += cur.toUpperCase();
      } else {
        newString += cur.toLowerCase();
      }
      if (cur === " ") {
        nextCapital = true;
      } else {
        nextCapital = false;
      }
    }
    return newString;
  }

  // function handleChange(ev) {
  //   const { name, value } = ev.target;
  //   if (name == "titre") {
  //     setForm({ ...form, [name]: value.toUpperCase() });
  //   } else if (name == "resume") {
  //     setForm({ ...form, [name]: CapitalString(value) });
  //   } else {
  //     setForm({ ...form, [name]: value });
  //   }
  // }

  function handleChangeTitle(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    // console.log(form);
  }

  function handleChangeResume(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    // console.log(form);
  }

  function handleChangeMotCle(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    // console.log(form);
  }

  function handleChangeRubriqueMere(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    // console.log(form);
  }

  const contextData = useContext(userDetailContext);
  function handleCancel() {
    navigate("/document");
  }

  const [helpDialog, setHelpDialog] = useState({
    open: false,
  });

  function handleOpenHelpDialog(e) {
    setHelpDialog({
      ...helpDialog,
      open: true,
    });
  }

  function handleCloseHelpDialog(e) {
    setHelpDialog({
      ...helpDialog,
      open: false,
    });
  }

  return (
    <MainContainer {...headerDataLibrary}>
      <HelpDialog
        openHelpDialog={helpDialog.open}
        closeHelpDialog={(e) => {
          handleCloseHelpDialog(e);
        }}
      />

      <Result
        message={etats.message}
        color={etats.color}
        open={etats.open}
        handleClose={handleCloseMessage}
      />
      {/* <Grid container item xs={12}>
        {hasPrivilege(
          contextData.privileges,
          "Admin-Button-Save-Direction"
        ) && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickEnregistrer}
            style={{ fontWeight: 600 }}
          >
            ENREGISTRER
          </Button>
        )}
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid> */}

      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClickEnregistrer}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>

        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>

      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Auteur</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              //label="Auteur"
              variant="outlined"
              //onChange={handleChange}
              inputProps={{ textTransform: "capitalize" }}
              value={form.auteur}
              name="auteur"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>Titre{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              //label="Titre"
              variant="outlined"
              onChange={handleChangeTitle}
              inputProps={{ textTransform: "capitalize" }}
              value={form.titre}
              name="titre"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>Resumé{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="outlined-multiline-static"
              // label="Multiline"
              multiline
              rows={3}
              size="small"
              //label="resumé"
              className={classes.text}
              variant="outlined"
              onChange={handleChangeResume}
              value={form.resume}
              name="resume"
            />
          </Grid>

          {/* <Grid item xs={4}>
            <label className={classes.label}>Date d'édition{bullRed}:</label>
          </Grid> */}
          {/* <Grid item xs={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={form.date_edition}
                onChange={handleEditionChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="date_edition"
                    fullWidth
                    variant="outlined"
                    id="date-picker-dialog"
                    size="small"
                    color="primary"
                    error={false}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid> */}

          <Grid item xs={4}>
            <label className={classes.labelPosition}>Mots clé{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="outlined-multiline-static"
              // label="Multiline"
              multiline
              rows={3}
              size="small"
              //label="Mot clé"
              placeholder="Séparez par une virgule ex: note, facturation, etc"
              className={classes.text}
              variant="outlined"
              onChange={handleChangeMotCle}
              value={form.mot_cle}
              name="mot_cle"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>
              Rubrique mère{bullRed}
            </label>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                Choisir une option
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={form.rubrique_mere}
                name="rubrique_mere"
                // placeholder="Choisir une option"
                label="Choisir une option"
                onChange={handleChangeRubriqueMere}
              >
                {/* <MenuItem value={0}>Choisir la rubrique mère</MenuItem> */}

                {rubrique.map((row) => (
                  <MenuItem value={row.id_rubrique}>
                    {row.titre_rubrique}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <Button onClick={(e) => handleOpenHelpDialog(e)} title="Aide">
              <HelpOutlineIcon style={{ color: "grey" }} />
            </Button>
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition1}>
              Document à uploader{bullRed}
            </label>
          </Grid>
          <Grid item xs={8}>
            <label htmlFor="icon-button-file">
              <Input
                accept=""
                id="icon-button-file"
                type="file"
                name="fichier"
                // value={fichier}
                onChange={handleChangeFile}
              />
              <IconButton aria-label="upload picture" component="span">
                <AttachFile />
                {/* <h6>Cliquez-ici pour télécharger</h6> */}
                {/* <label>{nameFile}</label> */}
              </IconButton>
            </label>
            {nameFile == null ? (
              <label>{nameFile}</label>
            ) : (
              <label>{nameFile}</label>
            )}
            {isFilenameEmpty && (
              <Alert severity="error">
                Veuillez télécharger le fichier concerné
              </Alert>
            )}
          </Grid>

          <Grid item xs={12}>
            <label className={classes.text}>
              ({bullRed}):Champs obligatoires
            </label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}
export default DocumentAdd;
