export const headerData = {
    title: 'Mon compte',
    submodules: [
        createData(0, "Mes validations", "/me/validations"),
        createData(1, "Planning de congé", "/me/leaves"),
        createData(2, "Mes informations", "/me"),
    ],
}

function createData(id, moduleName, ref) {
    return { id, moduleName, ref };
}