import { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button , Paper} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import MettingRequestTable from "./MettingRequestTable";
import { headerDataImmo } from "../../data/modules/logistic-header";
import { userDetailContext } from "../../App";
import { hasPrivilege } from "../../utils/privilege";
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import MeetingStat from './MeetingStat';
import MeetingApprovedTable from './MeetingApprovedTable';
import MeetingRefusedTable from './MeetingRefusedTable';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function MettingRequestList(props) {
  const navigate = useNavigate();
  const contextData = useContext(userDetailContext);
  function handleClick() {
    navigate("/Logistic/meeting_management/add");
  }

  const classes = useStyles();

  const [selectedStat, setSelectedStat] = useState('Demandes de réunions');

  return (
    <MainContainer {...headerDataImmo}>
      <Grid container spacing={3}>
        <Box mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MeetingStat setSelectedStat={setSelectedStat} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {/* <Paper className={classes.paper}> */}
                {selectedStat === 'Demandes de réunions' && <MettingRequestTable />}
                {selectedStat === 'Réunions Approuvées' && <MeetingApprovedTable />}
                {selectedStat === 'Réunions Refusées' && <MeetingRefusedTable />}
              {/* </Paper> */}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      
    </MainContainer>
  );
}
