import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
    Drawer,
    AppBar,
    Toolbar,
    Typography,
    Divider,
    IconButton,
    Badge,
    Container,
    Popover,
    ListItem,
    List,
    ListItemText,
    ListItemIcon,

} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import TreeItem from "@material-ui/lab/TreeItem";
import Spacer from "./Spacer";
import Popper from "@material-ui/core/Popper";
import { Link } from "react-router-dom";
import {
    TimeToLeaveTwoTone,
    ContactsTwoTone,
    GroupTwoTone,
    ExitToAppTwoTone,
    AccountCircle,
    Settings,
    CloudUpload,
    FontDownload,
    CloudDownload,
    GetApp,
} from "@material-ui/icons";

import InboxIcon from "@material-ui/icons/Inbox";
import SidebarTreeView from './SidebarTreeView';
import handleExpandClick from './SidebarTreeView';
import useStyles from "./main.styles";
import AuthService from "../services/AuthService";
import NotificationService from "../services/NotificationService";
import { useLocation } from 'react-router-dom';
import ValidationService from "../services/ValidationService";
import { isSetAccessor } from "typescript";
import HelpComponentPopover from "../screens/tools/HelpComponentPopover";
import Button from '@mui/material/Button';
import FileService from "../services/FileService";
import * as FileSaver from "file-saver";

function ListItemLink(props) {
    return <ListItem button component={Link} {...props} />;

}

export default function MainContainer(props) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const [rows, setRows] = useState([]);
    const [number, setNumber] = useState(0);
    const [numberOfNotification, setNumberOfNotification] = useState(0);
    const [notificationUserSubId, setUserSubNotificationId] = useState([]);

    //Notification parameters for user superior
    const [rowsOfUserSuperior, setRowsOfUserSuperior] = useState([]);
    const [UserSupNumberOfNotification, setUserSupNumberOfNotification] = useState(0);
    const [notificationUserSupId, setnotificationUserSupId] = useState([]);

    const [notificationId, setnotificationId] = useState([]);
    function loadNotification() {
        NotificationService.getstatus().then((result) => {
            if (result && result.data) {
                setNumber(result.number);
                setRows(result.data);
                setUserSubNotificationId(result.notification_id);
            }
        });
    }

    //function to load notification for user superior
    function loadUserSuperiorNotification() {
        NotificationService.getUserSupNotification().then((result) => {
            if (result && result.data) {
                setUserSupNumberOfNotification(result.number);
                setRowsOfUserSuperior(result.data);
                setnotificationUserSupId(result.notification_id);
            }
        });
    }
    useEffect(() => {
        loadNotification();
        loadUserSuperiorNotification();
    }, []);

    useEffect(() => {
        const notificationNumber = number + UserSupNumberOfNotification;
        setNumberOfNotification(notificationNumber);
    }, [number]);

    useEffect(() => {
        const notificationNumber = number + UserSupNumberOfNotification;
        setNumberOfNotification(notificationNumber);
    }, [UserSupNumberOfNotification]);

    useEffect(() => {
        setnotificationId(notificationUserSupId.concat(notificationUserSubId));
    }, [notificationUserSubId]);

    useEffect(() => {
        setnotificationId(notificationUserSubId.concat(notificationUserSupId));
    }, [notificationUserSupId]);

    const handleDrawerClose = () => {
        setOpen(false);
    };
    // Popover dans Notification
    const [anchorEl, setAnchorEl] = React.useState(null);

    // const notificationHandleClick = (event) => {
    //   setAnchorEl(anchorEl ? null : event.currentTarget);
    // };
    const handleClick1 = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const openNotificationPopup = Boolean(anchorEl);
    const id = openNotificationPopup ? "simple-popper" : undefined;
    //Popover

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClickLogout = () => {
        AuthService.logOut()
    };

    const handleClickMyAccount = () => {
    };

    //popover

    const [accountPopoverAnchorEl, setAccountPopoverAnchorEl] =
        React.useState(null);

    const [notificationPopoverAnchorEl, setNotificationPopoverAnchorEl] =
        React.useState(null);

    const [helpPopoverAnchorEl, setHelpPopoverAnchorEl] =
        React.useState(null);

    const accountPopoverhandleClick = (event) => {
        setAccountPopoverAnchorEl(event.currentTarget);
    };

    const notificationPopoverhandleClick = (event) => {
        setNotificationPopoverAnchorEl(event.currentTarget);
    };

    const helpPopoverhandleClick = (event) => {
        setHelpPopoverAnchorEl(event.currentTarget);
    };

    const accountPopoverhandleClose = () => {
        setAccountPopoverAnchorEl(null);
    };
    const notificationPopoverhandleClose = () => {
        setNotificationPopoverAnchorEl(null);
    };

    const helpPopoverhandleClose = () => {
        setHelpPopoverAnchorEl(null);
    };

    const handleClickMarkAsRead = () => {
        NotificationService.markAsRead(notificationId).then((result) => {
            if (result && result.status) {
                loadNotification();
                loadUserSuperiorNotification();
            }
        });
    };

    function handleClickNotifRow(notificationId) {
        NotificationService.markAsRead(notificationId).then((result) => {
            if (result && result.status) {
                loadNotification();
                loadUserSuperiorNotification();
            }
        });
    }

    function downloadUserManuel() {
        FileService.readUserManualFile().then((result) => {
            if (result && result.status) {
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const data = new Blob([result.data], { type: fileType });
                FileSaver.saveAs(data, "MUL_PGI_3.6.pdf");
            }
        })
    }

    function downloadPGIMobile() {
        FileService.downloadPGIMobile().then((result) => {
            if (result && result.status) {
                const fileType = "application/vnd.android.package-archive";
                const data = new Blob([result.data], { type: fileType });
                FileSaver.saveAs(data, "pgi_mobile.apk");
            }
        })
    }

    const accountPopoverOpen = Boolean(accountPopoverAnchorEl);
    const accountPopoverId = accountPopoverOpen
        ? "account-simple-popover"
        : undefined;

    const notificationPopoverOpen = Boolean(notificationPopoverAnchorEl);
    const notificationPopoverId = notificationPopoverOpen
        ? "account-simple-popover"
        : undefined;

    const helpPopoverOpen = Boolean(helpPopoverAnchorEl);
    const helpPopoverId = helpPopoverOpen
        ? "account-simple-popover"
        : undefined;

    const location = useLocation();
    const { pathname } = location;

    const getOptions = React.useCallback(async () => {
        const result = await ValidationService.getValidationState()
        if (result && result.data) {
            return result.data.map((d) => {
                return d.state;
            })
        }
    });

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(
                            classes.menuButton,
                            open && classes.menuButtonHidden
                        )}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h3" variant="h6" color="inherit">
                        {props.title}

                    </Typography>
                    {props.submodules.length !== 0 && (
                        <Container className={classes.submodules}>
                            {props.submodules
                                .map((row) => (
                                    <Link
                                        to={row.ref}
                                        variant="subtitle1"
                                        color="inherit"

                                        key={row.ref}
                                        className={
                                            clsx(classes.submoduleLink, (pathname === row.ref) && classes.activeMenu)
                                        }
                                    >
                                        {row.moduleName}

                                    </Link>
                                ))
                                .reduce((prev, curr) => [prev, <Spacer key={curr.ref} x={2} />, curr])}
                        </Container>
                    )}
                    <div className={classes.expand} nowrap="true"></div>

                    {/* Notification */}

                    <IconButton
                        color="inherit"
                        aria-describedby={notificationPopoverId}
                        onClick={notificationPopoverhandleClick}
                    >
                        <Badge badgeContent={numberOfNotification} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>

                    <Popover
                        id={notificationPopoverId}
                        open={notificationPopoverOpen}
                        anchorEl={notificationPopoverAnchorEl}
                        onClose={notificationPopoverhandleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className={classes.popover}>
                            <List>
                                {numberOfNotification > 0 &&
                                    <a style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }} onClick={handleClickMarkAsRead}>Tout marquer comme lu</a>
                                }
                                <Divider />
                                {rowsOfUserSuperior.map((rowOfUserSup) => (
                                    <>
                                        <ListItem button style={{ backgroundColor: rowOfUserSup.status == 1 ? "" : "#F5F5F5" }} onClick={() => handleClickNotifRow(rowOfUserSup.notificatonId)}>
                                            <ListItemText
                                                primary=""
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            className={classes.inline}
                                                            color="textPrimary"
                                                        >
                                                            {/* <span><b>RAZAFISOA Nivo</b> a deposé un demande de congé</span> */}
                                                            <span>{rowOfUserSup.message}</span>
                                                            {/* {" — Il y a 18mn"} */}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </>
                                ))}

                                <Divider />
                                <Divider />
                                {rows.map((row) => (
                                    <>
                                        <ListItem button style={{ backgroundColor: row.status == 1 ? "" : "#F5F5F5" }} onClick={() => handleClickNotifRow(row.notificationId)}>
                                            <ListItemText
                                                primary=""
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            className={classes.inline}
                                                            color="textPrimary"
                                                        >
                                                            {/* <span><b>RAZAFISOA Nivo</b> a deposé un demande de congé</span> */}
                                                            <span>{row.message}</span>
                                                            {/* {" — Il y a 18mn"} */}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </>
                                ))}

                                <Divider />
                            </List>
                        </div>
                    </Popover>

                    {/* Parametrage*/}
                    <IconButton
                        aria-describedby={accountPopoverId}
                        color="inherit"
                        onClick={accountPopoverhandleClick}
                    >
                        <Badge color="secondary">
                            <Settings />
                        </Badge>
                    </IconButton>

                    <Popover
                        id={accountPopoverId}
                        open={accountPopoverOpen}
                        anchorEl={accountPopoverAnchorEl}
                        onClose={accountPopoverhandleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItemLink to="/myAccount" onClick={handleClickMyAccount} >
                                <ListItemIcon>
                                    <AccountCircle />
                                </ListItemIcon>
                                <ListItemText primary="Mon compte" />
                            </ListItemLink>
                            <ListItemLink to="/" onClick={handleClickLogout} >
                                <ListItemIcon>
                                    <ExitToAppTwoTone />
                                </ListItemIcon>
                                <ListItemText primary="Déconnexion" />
                            </ListItemLink>
                        </List>
                    </Popover>

                    {/* Help */}
                    <IconButton
                        color="inherit"
                        aria-describedby={helpPopoverId}
                        onClick={helpPopoverhandleClick}
                    >
                        <Badge color="secondary">
                            <GetApp/>
                        </Badge>
                    </IconButton>

                    <Popover
                        id={helpPopoverId}
                        open={helpPopoverOpen}
                        anchorEl={helpPopoverAnchorEl}
                        onClose={helpPopoverhandleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <div className="popoverHelp">
                            <List>
                                <p><a style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }} onClick={downloadUserManuel}>Manuel d'utilisation</a></p>
                            </List>
                            
                            <List>
                                <p><a style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }} onClick={downloadPGIMobile}>PGI mobile(apk)</a></p>
                            </List>
                        </div>
                    </Popover>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <div className={classes.logo} link="/home"></div>
                    <IconButton onClick={handleDrawerClose}>
                        <MenuIcon />
                    </IconButton>
                </div>
                <Divider />
                {/*
        <List>{mainListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
        <Divider /> */}
                <SidebarTreeView />
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                
                <Container
                    maxWidth={props.maxWidth ?? !pathname.includes("/planning/table/validation/")?"lg":""}
                    className={classes.container}
                >
                    {props.children}
                </Container>
            </main>
        </div>
    );
}

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        "&:hover > $content": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:focus > $content, &$selected > $content": {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[200]})`,
            color: "var(--tree-view-color)",
        },
        "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
        {
            backgroundColor: "transparent",
        },
    },
    content: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        "$expanded > &": {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    contentLabel: {
        paddingRight: theme.spacing(1),
    },
    group: {
        marginLeft: 0,
        "& $content": {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: "inherit",
        color: "inherit",
    },
    labelRoot: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.5, 0),
        fontWeight: "inherit",
        color: "inherit",
        textDecoration: "inherit",
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: "inherit",
        flexGrow: 1,
    },
}));

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const {
        labelText,
        labelIcon: LabelIcon,
        labelInfo,
        color,
        bgColor,
        link,
        handleClick,
        ...other
    } = props;

    return (
        <TreeItem
            label={
                <Link to={link} className={classes.labelRoot} >
                    <LabelIcon color="inherit" className={classes.labelIcon} />
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography
                        variant="caption"
                        color="inherit"
                        className={classes.contentLabel}
                    >
                        {labelInfo}
                    </Typography>
                </Link>
            }
            style={{
                "--tree-view-color": color,
                "--tree-view-bg-color": bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    link: PropTypes.string,

};


