import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Title from "../../components/Title";
import PersonnelService from "../../services/PersonnelService";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import MainContainer from "../../components/MainContainer";
import { headerDataRH } from "../../data/modules/hr-header";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from "@material-ui/core/AppBar";
import { AntTab, AntTabs } from "../../components/ant-tab";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import profilo from "./profilo.jpg";
import Grid from "@material-ui/core/Grid";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { Paper } from "@material-ui/core";
import ExportExcel from "./ExportExcel";
import Spacer from "../../components/Spacer";
import ActualBalanceService from "../../services/ActualBalanceService";
import PlanningService from "../../services/PlanningService";
import { escapeRegExp } from "../../utils";
import DirectionService from "../../services/DirectionService";
import SeatsService from "../../services/SeatsService";

import TextField from "@material-ui/core/TextField";
import { hasPrivilege } from "../../utils/privilege";
import { userDetailContext } from "../../App";
import { useContext } from "react";

import {
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import BuildingService from "../../services/BuildingService";
import Phone from "../tools/Phone";
import Alert from "@material-ui/lab/Alert";
import UserService from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import FonctionService from "../../services/FonctionService";
import FileService from "../../services/FileService";
import * as FileSaver from "file-saver";
import { useLocation } from 'react-router-dom';
import Avatar from "@material-ui/core/Avatar";



const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  ExportExcel: {
    color: red,
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  label: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: 'medium',
  },
  label1: {
    color: 'black',
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));


export default function Annuaire(props) {
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getFullYear() +
    "/" +
    (myCurrentDate.getMonth() + 1) +
    "/" +
    myCurrentDate.getDate();


  const contextData = useContext(userDetailContext);

  const location = useLocation();
  const [rows, setRows] = useState([]);
  const [ligne, setLigne] = useState({});
  const [open, setOpen] = React.useState(false);
  const [openDialogExport, setOpenDialogExport] = React.useState(false);
  const [isOpen2, setIsOpen2] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [dir, setDir] = useState([]);
  const [dirDialog, setDirDialog] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  const [service, setService] = useState([]);
  const [serviceDialog, setServiceDialog] = useState([]);
  const [fonctionDialog, setFonctionDialog] = useState([]);
  const [value, setValue] = React.useState(0);
  const [idservice, setIdservice] = useState(0);
  const [iddir, setIddir] = React.useState("");
  const [fileName, setFileName] = useState("Code Immo matériel " + date);
  const [searchText, setSearchText] = React.useState("");
  const [form, setForm] = useState({
    direction_id: 0,
    service_id: 0
  });
  const [iddirDialog, setIddirDialog] = React.useState(0);
  const [idServiceDialog, setIdServiceDialog] = React.useState(0);
  const [idSeatsDialog, setIdSeatsDialog] = React.useState(0);
  const [seats, setSeats] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [idbuildingDialog, setIdBuildingDialog] = React.useState(0);
  const [idFonctionDialog, setIdFonctionDialog] = React.useState(0);
  const [dataFilter, setDataFilter] = useState([]);
  const [serviceFilter, setServiceFilter] = useState(0);
  const [directionFilter, setDirectionFilter] = useState(0);

  const [isEditingInformation, setIsEditingInformation] = React.useState(false);

  const [number, setNumber] = useState(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' ', '']);
  const [compt, setCompt] = useState(false);
  const [isPhoneNumberEmpty, setIsPhoneNumberEmpty] = useState(false);
  const [isPhoneNumberLengthNotValid, setPhoneNumberLengthNotValid] = useState(false);
  const [message, setMessage] = useState({
    openMessage: false,
    color: 'green',
    messageInfo: '',
  });
  const { openMessage, color, messageInfo } = message;
  const [showDetailId, setShowDetailID] = useState(0);
  const [opens, setOpens] = React.useState(false);
  /**Display list by user connected'direction */
  const [userDir, setUserDir] = useState("");
  const [userDirCode, setUserDirCode] = useState("");
  const [displayDirectionList, setDisplayDirectionList] = useState("");
  const [dropDownListDirection, setDropDownListDirection] = useState(0);
  const [userConnectedId, setuserConnectedId] = useState("");
  const [displayBtnExportOwnQr, setdisplayBtnExportOwnQr] = useState(false);
  const [lien,setLien]=useState("");
  const classes = useStyles();

  const navigate = useNavigate();

  // paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChange1 = (event) => {
    setLien(event.target.files[0]);
    setLigne({ ...ligne, name_image: event.target.files[0] });
  };

  const handleAccept = (event) => {
    setOpens(false);
    setIsEditingInformation(true);
    const formData = new FormData();
    formData.append("photos", ligne.name_image);
    formData.append("name_image", ligne.first_name); 
    PersonnelService.importphotos(formData).then((res) => {
      setLigne({ ...ligne, name_image: res });
   
    })
    .catch((error) => {
      console.error("Erreur :", error); 
    });
    // console.log(ligne);
  };
  
  function loadTable(id_direction, id_service) {
    setOpenLoadingSimpleBackdrop(true);
    PersonnelService.findAllPersonnal(id_direction, id_service).then((res) => {
      if (res && res.data) {
        setRows(
          res.data.map((row) => ({
            ...row,
            id: row.user_id,
          }))
        );
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setRows([]);
        setOpenLoadingSimpleBackdrop(false);
      }
    })
  }

  useEffect(() => {

    setUserDir(localStorage.getItem("USER_DIR"));
    setUserDirCode(localStorage.getItem("USER_DIR_CODE"));
    setIddir(localStorage.getItem("USER_DIR"));
    setuserConnectedId(localStorage.getItem("USER"));
    // if (location === "/annuaire") {
    //   loadTable(iddir, idservice);
    // }
    DirectionService.getDirection().then((res) => {
      if (res && res.status) {
        setDir(res.data);
      }
    });

  }, [location]);


  useEffect(() => {
    // if (userDir !== "") {
    //   if (userDir !== "1") {
    //     console.log(userDir);

    //     setForm({ ...form, "direction_id": userDir });
    //     setIddir(userDir);
    //   } else {
    //   }
    // }

    setForm({ ...form, "direction_id": userDir });
    setIddir(userDir);
  }, [userDir]);


  useEffect(() => {
    if (iddir !== 0 && iddir !== "") {

      console.log(iddir);
      loadTable(iddir, idservice);
      PlanningService.Direction(iddir).then((results) => {
        if (results && results.data) {
          setService(results.data);
          // setForm({...form, 'service_id': "0"});
        }
      })
      // if (iddir != 1) {
      //   setDisplayDirectionList(false);
      // } else {
      //   setDisplayDirectionList(true);
      // }
    }
  }, [iddir]);



  // useEffect(() => {
  //   if (displayDirectionList) {
  //     setDropDownListDirection(true);
  //   } else {
  //     setDropDownListDirection(false);
  //   }
  // }, [displayDirectionList]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = [...rows].filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
  };
  function handleChangeDirection(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    setIddir(value);
    // PlanningService.Direction(value).then((results) => {
    //   if (results && results.data) {
    //     setService(results.data);
    //     // setForm({...form, 'service_id': "0"});
    //   }
    // })
    // loadTable(value, idservice);
  }
  function handleChangeService(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
    setIdservice(value);
    loadTable(iddir, value);
  }

  const handleUpload = () => {
    setOpens(true);
  };

  function handleSaveDetailDialog() {
    setLien("");
    UserService.saveUserDetailAnnuaire(
      ligne.user_id, ligne.person_id,
      ligne.first_name, ligne.last_name,
      ligne.flotte, ligne.email, ligne.interne,
      idServiceDialog, ligne.traceability_service_id,
      idFonctionDialog, ligne.traceability_function_id,
      idSeatsDialog, idbuildingDialog, ligne.porte,ligne.name_image).then((res) => {
        var responseErrorMessage = res.message;
        if (res.status) {
          setMessage({ ...message, openMessage: true, color: 'green', messageInfo: "Succès" });
          navigate("/annuaire");
          setOpenLoadingSimpleBackdrop(false);
          setOpen(false);
        } else {
          setMessage({ ...message, openMessage: true, color: 'red', messageInfo: responseErrorMessage });
          setOpenLoadingSimpleBackdrop(false);
        }
      })
      .catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
      });
  }

  function handleOnChangeFieldDialog(ev) {
    setIsEditingInformation(true);
    const { name, value } = ev.target;
    if (name == "direction_id") {
      setLigne({ ...ligne, [name]: value });
      setIddirDialog(value);
      PlanningService.Direction(value).then((results) => {
        if (results && results.data) {
          setServiceDialog(results.data);
        }
      });

      SeatsService.getSeatsByDirectionId(value).then((results) => {
        if (results && results.data) {
          setSeats(results.data);
        }
      });
    } else if (name == "service_id") {
      setLigne({ ...ligne, [name]: value });
      setIdServiceDialog(value);
    } else if (name == "seat_id") {

      setLigne({ ...ligne, [name]: value });
      setIdSeatsDialog(value);
      BuildingService.getBuildingBySeat(value).then((results) => {
        if (results && results.data) {
          setBuildings(results.data);
        }
      });
    } else if (name == "building_id") {

      setLigne({ ...ligne, [name]: value });
      setIdBuildingDialog(value);
    } else if (name == "flotte") {
      var i = 0;
      var a = 0;
      for (let index = 0; index < value.length; index++) {
        for (let indexs = 0; indexs < number.length; indexs++) {
          if (number[indexs] == value[index]) {
            i++;
          }
        }
      }
      if (i != value.length) {
        setCompt(true);
      }
      else {
        setCompt(false);
        setLigne({ ...ligne, [name]: value });
      }
    } else if (name == "interne") {
      const phoneNumber = value.replace(/[^\d]/g, '');
      setLigne({ ...ligne, [name]: phoneNumber });
    } else if (name == "function_id") {
      setIdFonctionDialog(value);
      setLigne({ ...ligne, [name]: value });

    } else {
      setLigne({ ...ligne, [name]: value });
    }
  }

  const handleClickOpen = () => {
    setOpen(true);                                                                                                                                                                                       
    if (ligne.direction_id != undefined) {
      setIddirDialog(ligne.direction_id);
    }
  };

  const handleClose = () => {
    setOpen(false);
    // loadTable(iddir, idservice);
  };

  const handleClickOpenDialogExportVCF = () => {
    setOpenDialogExport(true);
  };
  const handleClickOpenDialogExportPDF = () => {
    downloadPDF();
  };

  const handleClose2 = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setOpenDialogExport(false);
  };

  function handleCloseDialog2() {
    setIsOpen2(false);
  }
  // useEffect(() => {
  //   setOpenLoadingSimpleBackdrop(true);
  //   PersonnelService.getList().then((result) => {
  //     if (result && result.data) {
  //       setRows(result.data.map((row) => ({ ...row, id: row.person_id })));

  //       setOpenLoadingSimpleBackdrop(false);
  //     } else {
  //       setOpenLoadingSimpleBackdrop(false);
  //     }
  //   });
  // }, [setRows]);

  useEffect(() => {
    setDataFilter(rows);
    setServiceFilter(form.service_id);
    setDirectionFilter(form.direction_id);
  }, [rows]);


  useEffect(() => {

    if (!open) {
      if (iddir != 0 && iddir != "") {
        loadTable(iddir, idservice);

      }
    } else {

      if (showDetailId !== 0) {
        console.log(showDetailId)
        giveListe(showDetailId);
        setdisplayBtnExportOwnQr(showDetailId[0] == userConnectedId?true:false);
      }
    }
  }, [open]);

  // useEffect(() => {
  //   setServiceFilter(form.service_id);
  // }, [form.service_id]);

  function giveListe(id) {
    // setOpenLoadingSimpleBackdrop(true);
    const ids = id[0];
    setLigne([]);
    setIdServiceDialog(0);
    setIdSeatsDialog(0);
    setIdBuildingDialog(0);
    setIdFonctionDialog(0);
    setIsEditingInformation(false);
    PersonnelService.getListe(ids).then((result) => {
      if (result && result.data) {
        setLigne(result.data);
        const dir = result.data.direction_id;
        const service = result.data.service_id;
        const seat = result.data.seat_id;
        const building = result.data.building_id;
        const fonction = result.data.function_id;
        // console.log("ligne")
        setIddirDialog(dir);

        //get all direction
        DirectionService.getDirection().then((res) => {
          if (res && res.status) {
            setDirDialog(res.data);
          }
        });

        //get all function
        FonctionService.getfonction().then((results) => {
          if (results && results.data) {
            setFonctionDialog(results.data);
            // setOpenLoadingSimpleBackdrop(false);
          }
        });

        //get service by direction
        if (dir != "" && dir != null && dir != undefined) {
          PlanningService.Direction(dir).then((results) => {
            if (results && results.data) {
              setServiceDialog(results.data);
            }
          });


          //get seats by direction
          SeatsService.getSeatsByDirectionId(dir).then((results) => {
            if (results && results.data) {
              setSeats(results.data);
            }
          });

          //get building by seat
          BuildingService.getBuildingBySeat(seat).then((results) => {
            if (results && results.data) {
              setBuildings(results.data);
            }
          });
        } else {
          //get seats by direction
          SeatsService.getAllSeats().then((results) => {
            if (results && results.data) {
              setSeats(results.data);
            }
          });

          //get building by seat
          BuildingService.getBuildings().then((results) => {
            if (results && results.data) {
              setBuildings(results.data);
            }
          });
        }
        setIdServiceDialog(service);
        setIdSeatsDialog(seat);
        setIdBuildingDialog(building);
        setIdFonctionDialog(fonction);

      }
    });
  }

  function giveList() {
    PersonnelService.getList().then((result) => {
      if (result && result.data) {
        setLigne(result.data);
      }
    });
  }

  function getFullName(params) {
    return `${params.getValue(params.id, 'last_name') || ''} ${params.getValue(params.id, 'first_name') || ''
      }`;
  }

  function handleExportVcf() {
    PersonnelService.exportVcf(dataFilter, serviceFilter, directionFilter).then((result) => {
      if (result && result.status) {
        const fileName = result.data;
        FileService.readVcfFile(fileName).then((result) => {
          console.log(result);
          if (result && result.status) {
            const data = new Blob([result.data], { type: "text/vcard;charset=utf-8" });
            FileSaver.saveAs(data, fileName);
            // setIsLoading(false);
            setOpenDialogExport(false);
          }
        });
      }
    });
  }

  function exportSingleContactVcf() {
    PersonnelService.exportSingleVcf(ligne).then((result) => {
      if (result && result.status) {
        const fileName = result.data;
        FileService.readVcfFile(fileName).then((result) => {
          if (result && result.status) {
            const data = new Blob([result.data], { type: "text/vcard;charset=utf-8" });
            FileSaver.saveAs(data, fileName);
            // setIsLoading(false);

            setOpen(false);
          }
        });
      }
    });
  }
  function exportSingleQR(id_user) {
    // const exportSingleQR = async (user_id) => {
    console.log(id_user);
    PersonnelService.generateSingleQrPdf(id_user).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      const extension = 'pdf';
      const fileName = 'Qr_code_' + id_user;

      const uniqueFileName = `${fileName}.${extension}`;
      a.download = uniqueFileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

    })
      .catch((error) => {
        console.error('Erreur lors du téléchargement du PDF :', error);
      });
  };
  // telechargement du fichier PDF
  const downloadPDF = async () => {
    // Appel de l'API pour générer le fichier PDF

    setOpenLoadingSimpleBackdrop(true);
    PersonnelService.generateQrPdf(iddir,idservice).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      const extension = 'pdf';
      const fileName = 'Qr_code';

      const uniqueFileName = `${fileName}.${extension}`;
      a.download = uniqueFileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      setOpenLoadingSimpleBackdrop(false);
    })
      .catch((error) => {
        console.error('Erreur lors du téléchargement du PDF :', error);

        setOpenLoadingSimpleBackdrop(false);
      });
  };


  // console.log("ligne*******", ligne);
  const columns = [
    {
      field: 'direction',
      headerName: 'Direction',
      width: 150,
    },
    {
      field: 'service_code',
      headerName: 'Service',
      width: 200,
    },
    {
      field: 'fullName',
      headerName: 'Nom et prénom(s)',
      width: 350,
      valueGetter: getFullName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        getFullName(cellParams1).localeCompare(getFullName(cellParams2)),
    },
    {
      field: 'fonction',
      headerName: 'Fonction',
      width: 250,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 250,
    },
    {
      field: 'flotte',
      headerName: 'Flotte',
      width: 250,
    },
    {
      field: 'interne',
      headerName: 'Numero interne',
      width: 250,
    },
    {
      field: 'seat',
      headerName: 'Bureau',
      width: 200,
    },
    {
      field: 'building',
      headerName: 'Batiment',
      width: 200,
    },
    {
      field: 'porte',
      headerName: 'Porte',
      width: 200,
    },
  ];

  return (
    <MainContainer {...headerDataRH}>

      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <React.Fragment>
        <Grid container xs={12} spacing={1} justifyContent={"flex-end"}>
          {hasPrivilege(contextData.privileges, 'Sub_menu_annuaire') &&
            <Button
              variant="contained"
              onClick={handleClickOpenDialogExportVCF}
              style={{ fontWeight: 600 }}
            >
              EXPORT VCF
          </Button>
          }
          <Spacer x={2} />

          {hasPrivilege(contextData.privileges, 'Annuaire_btn_export_qr_code') &&
            <Button
              variant="contained"
              onClick={handleClickOpenDialogExportPDF}
              style={{ fontWeight: 600 }}
            >
              EXPORT QR code
          </Button>
          }
          <Spacer x={2} />
        </Grid>
        <Spacer y={2} />
        <Dialog
          open={openDialogExport}
          onClose={handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >

          <DialogTitle id="form-dialog-title1">Exportation fichier vcf</DialogTitle> <br />
          <Spacer y={3} />
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={5} sm={6} lg={5}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-outlined-label">Direction</InputLabel>
                    <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Direction" value={form.direction_id} name="direction_id" onChange={handleChangeDirection}  >
                      <MenuItem value={0}> Toutes </MenuItem>
                      {dir.map((row) => (
                        <MenuItem value={row.direction_id}>{row.code}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5} sm={6} lg={5}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-outlined-label">Service</InputLabel>
                    <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Service" value={form.service_id} name="service_id" onChange={handleChangeService} >
                      <MenuItem value={0}> Tous </MenuItem>
                      {service.map((row) => (
                        <MenuItem key="{row.service_id}" value={row.service_id}>{row.code}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </Grid>

              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="grey" onClick={handleClose2}>
              Annuler
            </Button>
            <Button
              onClick={handleExportVcf}
              variant="contained"
              color="primary">
              Exporter
            </Button>
          </DialogActions>
        </Dialog>




        <Paper className={classes.paper} variant="outlined">
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={5} sm={6} lg={5}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">Direction</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Direction" value={form.direction_id} name="direction_id" onChange={handleChangeDirection}  >
                  <MenuItem value={0}>
                    Toutes
                  </MenuItem>
                  {dir.map((row) => (
                    <MenuItem value={row.direction_id}> {row.name} ({row.code}) </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5} sm={6} lg={5}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">Service</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Service" value={form.service_id} name="service_id" onChange={handleChangeService} >
                  <MenuItem value={0}>
                    Tous
                  </MenuItem>
                  {service.map((row) => (
                    <MenuItem key="{row.service_id}" value={row.service_id}>{row.code}</MenuItem>
                  ))}
                </Select>
              </FormControl>

            </Grid>

          </Grid>
          <Spacer y={3} />
          <Title>Annuaire</Title>
          <div style={{ height: "70vh", width: '100%' }} className={classes.root} >
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={7}
              rowsPerPageOptions={[7]}
              // disableSelectionOnClick
              onRowClick={handleClickOpen}
              // onSelectionModelChange={itm =>
              //   giveListe(itm)
              // }
              onSelectionModelChange={itm => setShowDetailID(itm)}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
          </div>
        </Paper>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title" style={{ color: 'white', }}>
            {"Information Personnelle"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ width: 400, }} >
              <Grid container spacing={2} >
                <Grid item xs={4}>
                </Grid >
                {/* <Grid item xs={8}>
                  {ligne.name_image === "" && (
                    <img src={profilo} style={{ width: "170px", height: "170px" }} accept="Image/" />
                  )}
                  {ligne.name_image === null && (
                    <img src={profilo} style={{ width: "170px", height: "170px" }} accept="Image/" />
                  )}
                  {ligne.name_image !== "" && ligne.name_image !== null && (
                    <img src={ligne.name_image} style={{ width: "170px", height: "170px" }} title="Image" accept="Image/" />
                  )}
                </Grid> */}
                 <Grid item xs={8}>
                 <Button title="Cliquez pour inserer une photo">
                  <Avatar
                    style={{ width: "170px", height: "170px", cursor: "pointer" }}
                    type="file"
                    accept="image/*"
                    onClick={handleUpload}
                  >
                    <img
                      style={{ width: "170px", height: "170px" }}
                    />
                     {lien && <img src={URL.createObjectURL(lien)} alt="Uploaded" style={{ width: "170px", height: "170px" }} />}
                     {ligne.name_image !== "" && ligne.name_image !== null && (
                      <img src={ligne.name_image} style={{ width: "170px", height: "170px" }} title="Image" accept="Image/" />
                  )}
                  </Avatar>
                </Button>
                 </Grid>
                <Grid item xs={4}>
                  <label className={classes.label}>Nom :</label>
                </Grid>
                <Grid item xs={8}>
                  {!hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') && <label className={classes.label1}>{ligne.last_name}</label>}
                  {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
                    <TextField
                      fullWidth
                      id="standard-basic"
                      size="small"
                      variant="standard"
                      onChange={handleOnChangeFieldDialog}
                      value={ligne.last_name}
                      name="last_name"
                      inputProps={{
                        style: { textTransform: 'uppercase', }
                      }}

                    />
                  }
                </Grid>
                <Grid item xs={4}>
                  <label className={classes.label}>Prénom(s):</label>
                </Grid>
                <Grid item xs={8}>
                  {!hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') && <label className={classes.label1}>{ligne.first_name}</label>}
                  {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
                    <TextField
                      fullWidth
                      id="standard-basic"
                      size="small"
                      variant="standard"
                      onChange={handleOnChangeFieldDialog}
                      value={ligne.first_name}
                      name="first_name"
                      inputProps={{
                        style: { textTransform: 'capitalize', }
                      }}

                    />
                  }
                </Grid>
                <Grid item xs={4}>
                  <label className={classes.label}>E-mail:</label>
                </Grid>
                <Grid item xs={8}>
                  {!hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') && <label className={classes.label1}>{ligne.email}</label>}
                  {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
                    <TextField
                      fullWidth
                      id="standard-basic"
                      size="small"
                      variant="standard"
                      onChange={handleOnChangeFieldDialog}
                      value={ligne.email}
                      name="email"

                    />
                  }
                </Grid>
                <Grid item xs={4}>
                  <label className={classes.label}>Flotte:</label>
                </Grid>
                <Grid item xs={8}>
                  {!hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') && <label className={classes.label1}>{ligne.flotte}</label>}

                  {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
                    <>
                      <Phone isAnnuaire={true} x1={3} x2={8} name="flotte" value={ligne.flotte} onChange={handleOnChangeFieldDialog} />

                      {compt && <><Spacer y={1} /><Alert severity="error">Le numero de téléphone ne contient pas de lettres</Alert></>}
                      {isPhoneNumberEmpty && <><Spacer y={1} /><Alert severity="error">Ce champ est obligatoire</Alert></>}
                      {isPhoneNumberLengthNotValid && <><Spacer y={1} /><Alert severity="error">Veuillez vérifier votre numéro de téléphone. La longueur n'est pas valide</Alert></>}
                    </>
                  }
                </Grid>
                <Grid item xs={4}>
                  <label className={classes.label}>Numéro interne:</label>
                </Grid>
                <Grid item xs={8}>
                  {!hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') && <label className={classes.label1}>{ligne.interne}</label>}
                  {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
                    <TextField
                      fullWidth
                      id="standard-basic"
                      size="small"
                      variant="standard"
                      onChange={handleOnChangeFieldDialog}
                      value={ligne.interne}
                      name="interne"
                      inputmode="numeric"
                      inputProps={{
                        maxLength: 3,
                        style: { textTransform: 'capitalize', }
                      }}

                    />
                  }
                </Grid>
                <Grid item xs={4}>
                  <label className={classes.label}>Direction:</label>

                </Grid>
                <Grid item xs={8}>
                  {!hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') && <label className={classes.label1}>{ligne.direction}</label>}

                  {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
                    <FormControl fullWidth variant="standard" size="small">
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={iddirDialog}
                        name="direction_id"
                        onChange={handleOnChangeFieldDialog}  >
                        {dirDialog.map((row) => (
                          <MenuItem value={row.direction_id}> {row.code}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </Grid>
                <Grid item xs={4}>
                  <label className={classes.label}>Service:</label>

                </Grid>
                <Grid item xs={8}>
                  {!hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') && <label className={classes.label1}>{ligne.service}</label>}

                  {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
                    <FormControl fullWidth variant="standard" size="small">
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={idServiceDialog}
                        name="service_id"
                        onChange={handleOnChangeFieldDialog}  >
                        {serviceDialog.map((row) => (
                          <MenuItem value={row.service_id}>{row.code}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </Grid>

                <Grid item xs={4}>
                  <label className={classes.label}>Fonction:</label>
                </Grid>
                <Grid item xs={8}>
                  {!hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') && <label className={classes.label1}>{ligne.fonction}</label>}
                  {/* {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
                    <TextField
                      fullWidth
                      id="standard-basic"
                      size="small"
                      variant="standard"
                      onChange={handleOnChangeFieldDialog}
                      multiline
                      value={ligne.fonction}
                      name="fonction"
                      inputProps={{
                        style: { textTransform: 'capitalize', }
                      }}

                    />
                  } */}
                  {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
                    <FormControl fullWidth variant="standard" size="small">
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={idFonctionDialog}
                        name="function_id"
                        onChange={handleOnChangeFieldDialog}  >
                        {fonctionDialog.map((row) => (
                          <MenuItem style={{ whiteSpace: 'normal' }} value={row.function_id}> {row.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </Grid>


                <Grid item xs={4}>
                  <label className={classes.label}>Bureau:</label>
                </Grid>
                <Grid item xs={8}>
                  {!hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') && <label className={classes.label1}>{ligne.seat}</label>}

                  {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
                    <FormControl fullWidth variant="standard" size="small">
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={idSeatsDialog}
                        name="seat_id"
                        onChange={handleOnChangeFieldDialog}  >
                        {seats.map((row) => (
                          <MenuItem value={row.seat_id}> {row.seat_name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </Grid>
                <Grid item xs={4}>
                  <label className={classes.label}>Batiment:</label>
                </Grid>
                <Grid item xs={8}>
                  {!hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') && <label className={classes.label1}>{ligne.building}</label>}

                  {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
                    <FormControl fullWidth variant="standard" size="small">
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={idbuildingDialog}
                        name="building_id"
                        onChange={handleOnChangeFieldDialog}  >
                        {buildings.map((row) => (
                          <MenuItem value={row.building_id}> {row.building_name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </Grid>
                <Grid item xs={4}>
                  <label className={classes.label}>Porte:</label>
                </Grid>
                <Grid item xs={8}>
                  {!hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') && <label className={classes.label1}>{ligne.porte}</label>}
                  {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
                    <TextField
                      fullWidth
                      id="standard-basic"
                      size="small"
                      variant="standard"
                      onChange={handleOnChangeFieldDialog}
                      value={ligne.porte}
                      name="porte"
                      inputProps={{
                        style: { textTransform: 'capitalize', }
                      }}

                    />
                  }
                </Grid>

              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <DialogActions>
              {(hasPrivilege(contextData.privileges, 'Annuaire_btn_export_qr_code') || displayBtnExportOwnQr) &&
                <Button onClick={() => exportSingleQR(ligne.user_id)} variant="contained" color="primary">
                  QR code
                </Button>
              }
            </DialogActions>

            {hasPrivilege(contextData.privileges, 'Sub_menu_annuaire') &&
              <Button onClick={exportSingleContactVcf} variant="contained" color="primary">
                Export vcf
              </Button>
            }
            {hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
              <>
                <Button
                  onClick={handleSaveDetailDialog}
                  variant="contained"
                  color="primary"
                  disabled={isEditingInformation ? false : true}>
                  Enregistrer
                </Button>
                <Button
                  variant="contained"
                  color="grey"
                  onClick={handleClose}
                  style={{ fontWeight: 600 }}
                >
                  ANNULER
                </Button>
              </>
            }
            {!hasPrivilege(contextData.privileges, 'Btn_editing_annuaire') &&
              <Button onClick={handleClose} variant="contained" color="primary">
                OK
              </Button>
            }
          </DialogActions>
        </Dialog>
        <div>
        <Dialog
          open={opens}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title" style={{ color: "white" }}>
            {"Information"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {
                "La taille de l'image doit être < 1Mb et le type doit être .jpg , .png , .gif"
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <input
              accept="image/*"
              variant="contained"
              color="primary"
              className={classes.type}
              id="contained-button-file"
              onChange={handleChange1}
              multiple
              name="photos"
              type="file"
            />
            {/* <label htmlFor="contained-button-file"> */}
              <Button variant="contained" color="primary" component="span" onClick={handleAccept}>
                Accept
              </Button>
            {/* </label> */}
          </DialogActions>
        </Dialog>
      </div>
      </React.Fragment>
    </MainContainer>
  );
}
