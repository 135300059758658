import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CandidatureService from "../../services/CandidatureService";
import { DataGrid } from '@material-ui/data-grid';
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';
import Title from "../../components/Title";
import Spacer from "../../components/Spacer";
import { Button } from "@material-ui/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from "axios";
import Message from "../tools/Message";


const apiUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function CandidatureTable(props) {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  function exportToCSV(id){
    setOpenLoadingSimpleBackdrop(true);
    CandidatureService.getlistidentity(id).then((result) => {
      if (result && result.data) {
          if (result.data[0].listcandidat===undefined ) {
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur d'exportation, veuillez réessayer" });
            setOpenLoadingSimpleBackdrop(false);
          }
          if (result.data[0].deux===undefined ) {
            setOpenLoadingSimpleBackdrop(false);
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur d'exportation, veuillez réessayer" });
          }
          else{
            const ws1 = XLSX.utils.json_to_sheet(result.data[0].listcandidat);
            const ws2 = XLSX.utils.json_to_sheet(result.deux); 
    
            const wb = {
              Sheets: {
                'Liste détaillée': ws1,
                'Liste par sous_domaine': ws2
              },
              SheetNames: ['Liste détaillée', 'Liste par sous_domaine']
            };
    
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, result.data[0].libelle_type + fileExtension);
            setMessage({ ...message, open: true, color: 'green', messageInfo: "Export excel succès" });
            setOpenLoadingSimpleBackdrop(false);
          }
        
      }
      else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
    
  };
  
async function exportCV(id) {
  setOpenLoadingSimpleBackdrop(true);
  try {
      const response = await axios.get(`${apiUrl}/Candidature/downloadFiles/${id}`);
      const zipLink = response.data.data.zip_link; 

      // Créer un lien de téléchargement pour le fichier ZIP
      const link = document.createElement('a');
      link.href = zipLink;
      link.setAttribute('download', `files.zip`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(zipLink);
      setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
      setOpenLoadingSimpleBackdrop(false);
  } catch (error) {
    setMessage({ ...message, open: true, color: 'red', messageInfo: "Erreur, veuillez réessayer" });
      setOpenLoadingSimpleBackdrop(false);
  }
}




  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    CandidatureService.list().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.id_type })));
        setOpenLoadingSimpleBackdrop(false);
      }
      else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);

 
  const columns = [
    { 
      field: 'libelle_type',
      headerName: 'Poste',
      width: 300,
    },
    // { 
    //   field: 'description',
    //   headerName: 'Référence AMI',
    //   width: 200,
    // },
    {
      field: 'formatted_date_debut_publication',
      headerName: 'Date début publication',
      width: 200,
    },
    { 
      field: 'formatted_date_fin_publication',
      headerName: 'Date fin publication',
      width: 200,
    },
    {
      field: 'number_identity',
      headerName: 'Nombre des canditats',
      width: 200,
    },
    {
      field: 'cloture',
      headerName: 'Statut',
      width: 120,
      renderCell: (params) => (
        params.value === "0" ? "Ouvert  " : "Cloturé"
      )
    }, 
    {
      field: 'cv',
      headerName: 'Fichiers',
      width: 250,
      renderCell: (params) => {
        return (
          <div>
                <Button
                  variant="contained"
                  style={{ fontWeight: 600, backgroundColor: '#2196f3', color: 'white' }}
                  startIcon={<GetAppIcon />}
                  onClick={(e) => exportCV(params.row.id_type)}
                >
                Fichiers(CV,LM)
                </Button>
          </div>
        );
      },
    },
    {
      field: 'ct',
      headerName: 'Actions ',
      width: 250,
      renderCell: (params) => {
        return (
          <div>
                <Button variant="contained"  onClick={(e) => exportToCSV(params.row.id_type)}  style={{ fontWeight: 600 }} >EXPORTER EXCEL</Button>
          </div>
        );
      },
    },
    
    
  ];
  
  function handleCloseMessage(){
    setMessage({...message, open: false});
  }
  return (
    <React.Fragment>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Listes des Candidatures</Title>
      <Spacer y={2} />
      <div style={{ height: "70vh", width: '100%' }} className={classes.root} >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
        />
      </div>
    </React.Fragment>
    
  );
}
