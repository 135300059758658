import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Paper } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataSettings } from "../../data/modules/settings-header";
import PersonalSettingTable from "./PersonalSettingTable";
import { hasPrivilege } from "../../utils/privilege";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function PersonalSettingList() {
  const navigate = useNavigate();
  const classes = useStyles();
  function handleClick() {
    navigate("/champ_dynamique/add");
  }
  const contextData = useContext(userDetailContext);
  return (
    <MainContainer {...headerDataSettings}>
      <Grid container item xs={12}>
        {hasPrivilege(contextData.privileges, 'Admin-ChampD-Add') &&
          <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >CREER</Button>
        }
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <PersonalSettingTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
