import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FamilyMemberService from "../../../services/FamilyMemberService";
import TextField from "@material-ui/core/TextField";
import MuiPhoneNumber from 'material-ui-phone-number';
import Message from "../../tools/Message";
import Alert from "@material-ui/lab/Alert";

import Address from "../../tools/Address";

import { Button, MenuItem, FormControl, Select, InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  snackbarStyleViaNestedContent: {
    backgroundColor: "red",
    color: "white"
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition1: {
    float: 'left',
  },
  labelPosition: {
    float: 'right',
  },
  text: {
    width: 370,
  }
}));

export default function Child(props) {
  const classes = useStyles();
  const [form, setForm] = useState({
    person_id: 0, first_name:"", last_name:"", personnal_email:"", card_number:"", birth_date:"",
    telephone:"", sex_id:"1", card_deliverance_date:"", address:props.address, marital_status:"",
    birth_place:"", card_deliverance_place:"", card_duplicate_date:"", user_id: props.user_id, relationship_type_id: 2,
    value_personal_config:[]});
  const [personalConfig, setPersonalConfig] = useState([]);
  const [valuePersonalConfigTemp, setValuePersonalConfigTemp] = useState([]);
  const [countChild, setCountChild] = React.useState(0); //isan-jaza
  const childData = props.defaultValueOfChild;
  const [age, setAge] = useState(0);
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  function handleCloseMessage(){
    setMessage({...message, open: false});
  }

  const [isValidFirst_name, setIsValidFirst_name] = useState(false);
  const [isValidBirth_date, setIsValidBirth_date] = useState(false);
  const [isValidAddress, setIsValidAddress] = useState(false);

  useEffect(() => {
    FamilyMemberService.getChildPersonalConfig().then((result) => {
      if(result && result.data){
        setPersonalConfig(result.data);
      }
    });
    FamilyMemberService.getCountChild(props.user_id).then((result) => {
      setCountChild(result.data);
    });
    //
    if(typeof childData !== 'undefined'){
        setForm({
          ...childData,
          user_id: props.user_id, relationship_type_id: 2, value_personal_config:[]
        });
        setAge(new Date().getFullYear() - new Date(childData.birth_date).getFullYear());
    }
    // 
  }, [childData]);

  function toTableCaractere(letter){
    let table = [];
    let i=0;
    for(i=0; i<letter.length; i++){
      table[i] = letter.charAt(i);
    }
    return table;
  }

  function firstLetterToUpperCase(letter){
    let table = toTableCaractere(letter);
    let i=0;
    for(i=0; i<table.length-1; i++){
      if(table[i]==" "){
        table[i+1] = table[i+1].toUpperCase();
      }
    }
    letter = table.join("");
    letter = letter.charAt(0).toUpperCase()+letter.slice(1);
    return letter;
  }

  function handleChangeFormValue(event){
    const { name, value } = event.target;
    if(name=='first_name'){
      setForm({ ...form, [name]: value.toUpperCase() });
    }
    else if(name=='last_name' || name=='birth_place' || name=='address' || name=='card_deliverance_place'){
      setForm({ ...form, [name]: firstLetterToUpperCase(value) });
    }
    else if(name=='birth_date'){
      setForm({ ...form, [name]: value });
      const agePerson = new Date().getFullYear() - new Date(value).getFullYear();
      setAge(agePerson);
    }
    else{
      setForm({ ...form, [name]: value });
    }
  }

  function handleChangePersonalConfigValue(event){
    const { name, value } = event.target;
    valuePersonalConfigTemp[name] = {
      "personal_config_id":name,
      "value":value
    };
  }
  // 

  function handleClick(){
    if(form.first_name===''){
      setIsValidFirst_name(true);
    }
    if(form.birth_date===''){
        setIsValidBirth_date(true);
    }
    if(form.address===''){
        setIsValidAddress(true);
    }
    if(form.first_name!=='' && form.birth_date!=='' && form.address!==''){
      var iTemp=0;
      for(var i=0; i<valuePersonalConfigTemp.length; i++){
        if(typeof valuePersonalConfigTemp[i] !== 'undefined'){
          form.value_personal_config[iTemp] = valuePersonalConfigTemp[i];
          iTemp++;
        }
      }
      console.log("FORM DATA === ", form);
      FamilyMemberService.saveFamilyMember(form).then((result) => {
        if(result.status){
          setMessage({...message, open: true, color: 'green', messageInfo: result.message});
        }else{
          setMessage({...message, open: true, color: 'red', messageInfo: result.message});
        }
      }).catch((err) => {
        setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      });
    }
    
  }

  function handleClickUpdate(){
    console.log("FORM DATA UPDATE === ", form);
    FamilyMemberService.updateFamilyMember(form, form.person_id).then((result) => {
      if(result.status){
        setMessage({...message, open: true, color: 'green', messageInfo: result.message});
      }else{
        setMessage({...message, open: true, color: 'red', messageInfo: result.message});
      }
    }).catch((err) => {
      setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
    });
    window.scrollTo(0, 0);
  }

  function onChangeAddress(event){
    const { name, value } = event.target;
      setForm({ ...form, address: value });
  }

  function handleChangePhoneValue(event){
    setForm({ ...form, ["telephone"]: event });
  }
  
  const personalConfigItems = [];
  for(var i=0; i<personalConfig.length; i++){
    if(personalConfig[i].type_field === 'Choix'){
      personalConfigItems.push(
        <>
        <Grid item xs={5}>
          <label className={classes.labelPosition1}>{personalConfig[i].name_field}</label>
        </Grid>
        <Grid item xs={7}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-outlined-label">{personalConfig[i].name_field}</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label={personalConfig[i].name_field} name={personalConfig[i].personal_config_id} onChange={handleChangePersonalConfigValue}>
              {personalConfig[i].choice.map((items) => (
                <MenuItem value={items.personal_config_choice_id}>{items.label_choice}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        </>
      );
    }else{
      personalConfigItems.push(
        <>
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>{personalConfig[i].name_field}</label>
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth type="" id="outlined-basic" size="small" label={personalConfig[i].name_field} variant="outlined" name={personalConfig[i].personal_config_id} onChange={handleChangePersonalConfigValue} />
        </Grid>
        </>
      );
    }
  }

  const saveButton = [];
    if(typeof childData === 'undefined'){
      saveButton.push(
        <>
        <Grid item xs={12}>
          <Button className={classes.labelPosition} variant="contained" color="primary" onClick={handleClick} >{"Enregistrer enfant "+props.rank}</Button>
        </Grid>
        </>
      );
    }else{
      saveButton.push(
        <>
        <Grid item xs={12}>
          <Button className={classes.labelPosition} variant="contained" color="primary" onClick={handleClickUpdate} >{"Enregistrer enfant "+props.rank}</Button>
        </Grid>
        </>
      );
    }

  return (
    <Grid container spacing={3} className={classes.paper} variant="outlined">
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>Nom*</label>
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth id="outlined-basic" size="small" label="Nom" variant="outlined" name="first_name" value={form.first_name} onChange={handleChangeFormValue} />
            {isValidFirst_name && <Alert severity="error">Veuillez remplir ce champ</Alert>}
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>Prénom</label>
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth id="outlined-basic" size="small" label="Prénom" variant="outlined" name="last_name" value={form.last_name} onChange={handleChangeFormValue} />
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>E-mail</label>
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth id="outlined-basic" size="small" label="E-mail" variant="outlined" name="personnal_email" value={form.personnal_email} onChange={handleChangeFormValue}/>
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>Téléphone</label>
        </Grid>
        <Grid item xs={7}>
            <MuiPhoneNumber fullWidth id="outlined-basic" size="small" label={"Téléphone"} variant="outlined" defaultCountry={'mg'} name="telephone" value={form.telephone} onChange={handleChangePhoneValue}/>
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>Date de naissance*</label>
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth id="outlined-basic" size="small" variant="outlined" type="date" name="birth_date" value={form.birth_date} onChange={handleChangeFormValue} />
            {isValidBirth_date && <Alert severity="error">Veuillez remplir ce champ</Alert>}
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>Lieu de naissance</label>
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth id="outlined-basic" size="small" label="Lieu de naissance" variant="outlined" name="birth_place" value={form.birth_place} onChange={handleChangeFormValue} />
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>Age</label>
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth id="outlined-basic" size="small" label="Age" variant="outlined" /*type="number"*/ value={age} />
        </Grid>
        <Address x1={5} x2={7} address={form.address} onChangeAddress={onChangeAddress}/>
        {/* {isValidAddress && <Alert severity="error">Veuillez remplir ce champ</Alert>} */}
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>CIN</label>
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth id="outlined-basic" size="small" label="CIN" variant="outlined" name="card_number" value={form.card_number} onChange={handleChangeFormValue} inputProps={{maxLength:12}} />
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>Date de délivrance</label>
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth id="outlined-basic" size="small" variant="outlined" type="date" name="card_deliverance_date" value={form.card_deliverance_date} onChange={handleChangeFormValue} />
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>Lieu de délivrance</label>
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth id="outlined-basic" size="small" label="Lieu de délivrance" variant="outlined" name="card_deliverance_place" value={form.card_deliverance_place} onChange={handleChangeFormValue} />
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>Date de duplicata</label>
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth id="outlined-basic" size="small" variant="outlined" type="date" name="card_duplicate_date" value={form.card_duplicate_date} onChange={handleChangeFormValue} />
        </Grid>
        <Grid item xs={5}>
          <label className={classes.labelPosition1}>Sexe*</label>
        </Grid>
        <Grid item xs={7}>
          <RadioGroup aria-label="sex_id" name="sex_id" value={form.sex_id} onChange={handleChangeFormValue}>
            <FormControlLabel value="1" control={<Radio />} label="Homme" />
            <FormControlLabel value="2" control={<Radio />} label="Femme" />
          </RadioGroup>
        </Grid>
        <Grid item xs={5}>
            <label className={classes.labelPosition1}>Situation matrimoniale</label>
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth id="outlined-basic" size="small" label="Situation matrimoniale" variant="outlined" name="marital_status" value={form.marital_status} onChange={handleChangeFormValue} />
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={7}>
            <label className={classes.labelPosition1} style={{ textDecorationLine: 'underline' }} >Autres champs à remplir : </label>
        </Grid>
        {personalConfigItems}
        {saveButton}
    </Grid>
  );
}
