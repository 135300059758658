import { useEffect, useState } from "react";
import { useParams } from 'react-router';
import { Document, Page, pdfjs } from "react-pdf";
import FileService from "../../../services/FileService";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



function RenderFile(props) {
  const params = useParams();
  const fileId = params.id;
  const [pdf, setPdf] = useState("");
  const [pageNumber, setStateNumPage] = useState(0);

  useEffect(() => {
    FileService.getFilenameById(fileId).then((result) => {
    if (result) {
        // setFilename(result.data.filename);
        FileService.readFile(result.data.filename).then((result) => {
          if (result) {
            const blob = new Blob([result.data], {
              type: 'application/pdf',
            });
            setPdf(blob);
          }
        });

    }
    });
    
  }, []);

  function onDocumentLoad() {

    setStateNumPage(1);
  }

  return (
    <>
      <Document file={pdf}
        onLoadSuccess={onDocumentLoad}
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </>
  );

}
export default RenderFile;
