import useTreeItemStyles from "./styledtreeitem.styles";
import PropTypes from "prop-types";
import TreeItem from "@material-ui/lab/TreeItem";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    isBold,
   grandTitre,
   sousTitre,
    label,
    // handleClick,
    color,
    bgColor,
    link,
    params,  
    isActiveMenu,
    ...other
  } = props;
function handleClick()
{
  localStorage.setItem('module',JSON.stringify({label}));
}
  return (
    <TreeItem
      label={
        <Link to={link} className={classes.labelRoot} onClick={handleClick}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={isBold ? classes.labelTextBold : classes.labelText}  >
            {labelText}
          </Typography>
          <Typography
            variant="caption"
            color="inherit"
            className={classes.contentLabel}
          >
            {labelInfo}
          </Typography>
        </Link>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,

      }}
      className={isActiveMenu ? classes.activeMenu : ""}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  link: PropTypes.string,
};
export default StyledTreeItem;