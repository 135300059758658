import React, { useState, useEffect } from 'react';
import MainContainer from "../components/MainContainer";
import Spacer from "../components/Spacer";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from '@material-ui/core/Grid';
import { headerData } from "../data/modules/my-validations-header";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InventoryService from '../services/InventoryService';

function FilterValidation(props) {
    const {type} = props;
    const useStyles = makeStyles((theme) => ({
        paper: {
          padding: theme.spacing(2),
          display: "flex",
          overflow: "auto",
          flexDirection: "column",
          elevation: 0,
        },
        style1: {
          fontSize: "5em",
        },
      }));
    const classes = useStyles();
    const [form, setForm] = useState({
        name_materiel: "",
        code_immo: "",
        state: "",
        logistics_manager:"",
        status1: "",
        status2: "",
      });
  const [logistics_manager, setLogistics_manager] = useState([]);

        const handleChanges = (e) => {

            const { name, value } = e.target;
                localStorage.setItem(name,value);
            };
            const setValeurChamps = (name)=>{
              const valeurLocalStorage = localStorage.getItem(name);
              console.log("valeur champs sont : "+valeurLocalStorage)
              return valeurLocalStorage;
            }
            const SetFilter = (name,value)=>{
              if(!value){
                if(localStorage.getItem(name)){
                  localStorage.setItem(name,localStorage.getItem(name));
                }
              }
              else{
        
                localStorage.setItem(name,value);
              }
             
            }
              useEffect(() => {
                InventoryService.logistics_manager().then((res) => {
                  if (res && res.status) {
                    setLogistics_manager(res.data);
                  }
                });
                SetFilter('name_materiel',localStorage.getItem('name_materiel'));
                SetFilter('state',localStorage.getItem('state'));
                SetFilter('logistics_manager',localStorage.getItem('logistics_manager'));
                SetFilter('status1',localStorage.getItem('status1'));
                SetFilter('status2',localStorage.getItem('status2'));
                SetFilter('code_immo',localStorage.getItem('code_immo'));
              }, []);

    return (
      <Grid container item xs={12}>
        
        
      <Spacer y={4} /> 
      <Grid container xs={12} spacing={2} >
           <Grid item xs={12} sm={6} md={4}>
           <Spacer y={2} />
           <label className={classes.labelPosition}>
                  Matériel:
                </label>
                <Spacer y={0.2}></Spacer>
            <TextField
              
              id="outlined-basic"
              size="small"
              variant="outlined"
              defaultValue={setValeurChamps('name_materiel')}
              name="name_materiel"
              type="text"
              onChange={handleChanges}
              style={{ width: '300px' }}
            />
        </Grid>
        
       
        {type !== 1 && (
                    <>
                        <Grid item xs={12} sm={6} md={4}>
                            <Spacer y={2} />
                            <label className={classes.labelPosition}>
                                Statut Ancien:
                            </label>
                            <FormControl fullWidth variant="outlined" size="small">
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={setValeurChamps('status1')}
                                    name="status1"
                                    style={{ width: '300px' }}
                                    onChange={handleChanges}
                                >
                                    <MenuItem value='CONFIRMED'>Validé</MenuItem>
                                    <MenuItem value='PENDING'>Non Validé</MenuItem>
                                    <MenuItem value='DECLINED'>Refusé</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        </>
                )}
         {type !== 1 && (
                    <>
                        <Grid item xs={12} sm={6} md={4}>
                            <Spacer y={2} />
                            <label className={classes.labelPosition}>
                                Statut Nouveau:
                            </label>
                            <FormControl fullWidth variant="outlined" size="small">
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={setValeurChamps('status2')}
                                    name="status2"
                                    style={{ width: '300px' }}
                                    onChange={handleChanges}
                                >
                                    <MenuItem value='CONFIRMED'>Validé</MenuItem>
                                    <MenuItem value='PENDING'>Non Validé</MenuItem>
                                    <MenuItem value='DECLINED'>Refusé</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        </>
                        )}
          <Grid item xs={12} sm={6} md={4} >
          <Spacer y={2} />
        <label className={classes.labelPosition}>
        Code Immobilisation:
                </label>
                <Spacer y={0.2}></Spacer>
            <TextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              defaultValue={setValeurChamps('code_immo')}
              name="code_immo"
              type="text"
              onChange={handleChanges}
              style={{ width: '300px'}}
            />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
        <Spacer y={2} />
          <label className={classes.labelPosition}>
                  Etat Matériel:
                </label>
            <FormControl fullWidth variant="outlined" size="small">
             
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={setValeurChamps('state')}
                name="state"
                style={{ width: '300px'}}
                onChange={handleChanges}
              >
                  <MenuItem value='TRES BON ETAT'>TRES BON ETAT</MenuItem>
                  <MenuItem value='BON ETAT'>BON ETAT</MenuItem>
                  <MenuItem value='MOYEN ETAT'>MOYEN ETAT</MenuItem>
                  <MenuItem value='MAUVAIS ETAT'>MAUVAIS ETAT</MenuItem>
              </Select>
            </FormControl>
           
          </Grid>
          {type !== 1 && (
                    <>
          <Grid item xs={12} sm={6} md={4} >
          <Spacer y={2} />
        <label className={classes.labelPosition}>
        Responsable logistique:
                </label>
                
        <FormControl fullWidth variant="outlined" size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={setValeurChamps('logistics_manager')}
                label="logistics_manager"
                name="logistics_manager"
                style={{ width: '300px'}}
                onChange={handleChanges}
              >
                {logistics_manager.map((row) => (
                  <MenuItem value={row.last_name}>
                    {row.last_name}({row.first_name})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </Grid>
        </>
                        )}
        <Grid item xs={12} sm={6} md={4} >

        </Grid>
        <Grid item xs={12} sm={6} md={4} >

        </Grid>
        
          <Grid item xs={12} sm={6} md={4} >
             
          

           </Grid>
           </Grid>
           </Grid>
         
            
          

    );
}

export default FilterValidation;
