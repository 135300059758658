import React from "react";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import KeybordVirtuel from "./KeybordVirtuel";
import Spacer from "../../components/Spacer";
import Alert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
const apiBaseUrl = process.env.REACT_APP_API_BASEURL;

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  step1Title: {
    textAlign: "center",
  },
  step1Grid: {
    padding: "10px",
  },
  gridItem: {
    height: "25px",
  },
}));

export default function ElectronicValidation(props) {
  const confirmDialog = props.confirmDialog;
  const cancelDialog = props.cancelDialog;
  const openDialog = props.openDialog;
  const openFirstStep = props.openDialogFirstStep;
  const dataValidation = props.data;
  const type = props.type;
  const closeDialog1 = props.closeDialog1;
  const closeDialog2 = props.closeDialog2;
  const closeDialog3 = props.closeDialog3;
  const closeDialog4 = props.closeDialog4;
  const validateStep1 = props.validateStep1;
  const refuseStep1 = props.refuseStep1;
  const validateStep2 = props.validateStep2;
  const handleRefuse = props.handleRefuse;
  const validateStep3 = props.validateStep3;
  const openSecondStep = props.openDialogSecondStep;
  const openThirdStep = props.openDialogThirdStep;
  const openRefuseStep1 = props.openDialogRefuseStep;
  const handleChangePwdTextField = props.handleChangePwdTextField;
  const handleObservation = props.handleObservation;
 

  const errorMessage = props.errorMessage;
  const isValid = props.isValid;
  const failedLogin = props.failedLogin;

  const failedCodePin = props.failedCodePin;
  const errorMessageFailedCodePin = props.errorMessageFailedCodePin;

  const onChangeInputValue = props.onChangeInputValue;
  const navigate = useNavigate();
  const classes = useStyles();
  const [reason, setReason] = React.useState("");

  
  const [isEmptyPassword, setEmptyPassword] = useState(false);
  const closeDialog = props.closeDialog;
  const handleClose = (event, reason) => {
      if (reason === "backdropClick") {
        return false;
      }
  };
  
  const handleRefuseClose = () => {
    if (reason === "backdropClick") {
      return false;
    }
    
  };

  function handleFocusPassword() {
    setEmptyPassword(false);
  }

  return (
    <div>
      <Dialog 
        open={openDialog} 
        onClose={closeDialog} 
        aria-labelledby="alert-dialog-title" 
        aria-describedby="alert-dialog-description" 
      >
        <DialogTitle id="form-dialog-title" color="primary">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez vous vraiment envoyer  ? 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={confirmDialog} >Confirmer</Button>
          <Button variant="contained" color="secondary" onClick={cancelDialog} >Annuler</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openFirstStep}
        onClose={handleClose}
        aria-labelledby="form-dialog-title1"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title1">
          Validation éléctronique - Etape 1
        </DialogTitle>{" "}
        <br />
        <div className={classes.root}>
          <div>
            <h3 className={classes.step1Title}>Détails du fichier à valider</h3>
          </div>
          {type === "congé" && (
            <Grid className={classes.step1Grid} container>
              <Grid className={classes.gridItem} item xs={4}>
                <label className={classes.label}>Type:</label>
              </Grid>
              <Grid className={classes.gridItem} item xs={7}>
                <label className={classes.label1}>
                  {dataValidation.leave_type_id === 1 ? "Congé" : "Permission"}
                </label>
              </Grid>

              <Grid className={classes.gridItem} item xs={4}>
                <label className={classes.label}>Demandeur:</label>
              </Grid>
              <Grid className={classes.gridItem} item xs={7}>
                <label className={classes.label1}>
                  {dataValidation.submitter_firstname}{" "}
                  {dataValidation.submitter_lastname}
                </label>
              </Grid>

              <Grid className={classes.gridItem} item xs={4}>
                <label className={classes.label}>Date de Départ:</label>
              </Grid>
              <Grid className={classes.gridItem} item xs={2}>
                <label className={classes.label1}>
                  {dataValidation.begin_time}
                </label>
              </Grid>
              <Grid item xs={6}>
                <label className={classes.label1}>
                  {dataValidation.begin_time_session === "morning"
                    ? "Matin"
                    : "Après-midi"}
                </label>
              </Grid>

              <Grid className={classes.gridItem} item xs={4}>
                <label className={classes.label}>Date de retour:</label>
              </Grid>
              <Grid className={classes.gridItem} item xs={2}>
                <label className={classes.label1}>
                  {dataValidation.end_time}
                </label>
              </Grid>
              <Grid item xs={6}>
                <label className={classes.label1}>
                  {dataValidation.end_time_session === "morning"
                    ? "Matin"
                    : "Après-midi"}
                </label>
              </Grid>

              <Grid className={classes.gridItem} item xs={4}>
                <label className={classes.label}>Nombre de jours:</label>
              </Grid>
              <Grid className={classes.gridItem} item xs={7}>
                <label className={classes.label1}>
                  {dataValidation.duration}
                </label>
              </Grid>
              <Grid className={classes.gridItem} item xs={4}>
                <label className={classes.label}>Motif:</label>
              </Grid>
              <Grid className={classes.gridItem} item xs={7}>
                <label className={classes.label1}>
                  {dataValidation.reason}
                </label>
              </Grid>

              <Grid item xs={12}>
                <br></br>
                La demande sous forme{" "}
                <Link
                  to={"/leavePdfRender/" + dataValidation.filename}
                  target="_blank"
                >
                  pdf.
                </Link>
                <br />
                Voulez vous le valider?
                <Spacer y={2} />
                <DialogActions>
                  <Button
                    style={{ backgroundColor: "##f50057", color: "#FFFFFF" }}
                    onClick={closeDialog1}
                    color="secondary"
                    variant="contained"
                  >
                    Annuler
                  </Button>
                  <Button
                    style={{ backgroundColor: "#3f51b5", color: "#FFFFFF" }}
                    onClick={refuseStep1}
                    color="primary"
                    variant="contained"
                  >
                    Refuser
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "rgb(19, 62, 103)",
                      color: "#FFFFFF",
                    }}
                    onClick={validateStep1}
                    color="primary"
                    variant="contained"
                  >
                    Valider
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          )}
        </div>
      </Dialog>
      <Dialog
        open={openSecondStep}
        onClose={handleClose}
        aria-labelledby="form-dialog-title2"
        fullWidth
        maxWidth="sm"
      >
        {/* <form className={classes.form}> */}
        <DialogTitle id="form-dialog-title2">
          Validation éléctronique - Etape 2
        </DialogTitle>
        {type === "congé" && (
          <div>
            <DialogContent>
              <DialogContentText>
                Veuillez entrer votre mot de passe pour confirmer la validation
                de ce document.
              </DialogContentText>
              <TextField
                error={failedLogin}
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth={true}
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                onChange={handleChangePwdTextField}
                autoComplete="current-password"
                //value={form.password || ""}
                onFocus={handleFocusPassword}
              />

              {isValid && <Alert severity="error">{errorMessage}</Alert>}
              {failedLogin && <Alert severity="error">{errorMessage}</Alert>}
            </DialogContent>
            <DialogActions>
              <Button
                style={{ backgroundColor: "##f50057", color: "#FFFFFF" }}
                onClick={closeDialog2}
                color="secondary"
                variant="contained"
              >
                Annuler
              </Button>
              <Button
                style={{
                  backgroundColor: "rgb(19, 62, 103)",
                  color: "#FFFFFF",
                }}
                onClick={validateStep2}
                color="primary"
                variant="contained"
              >
                Valider
              </Button>
            </DialogActions>
          </div>
        )}
        {/* </form> */}
      </Dialog>

      <Dialog
        open={openThirdStep}
        onClose={handleClose}
        aria-labelledby="form-dialog-title3"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title3">
          Validation éléctronique - Etape 3
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez entrer le code pin envoyé dans votre boite E-mail.
          </DialogContentText>

          <KeybordVirtuel onChangeInput={onChangeInputValue} />

          {failedCodePin && (
            <Alert severity="error">{errorMessageFailedCodePin}</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "##f50057", color: "#FFFFFF" }}
            onClick={closeDialog3}
            color="secondary"
            variant="contained"
          >
            Annuler
          </Button>
          <Button
            type="submit"
            style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF" }}
            onClick={validateStep3}
            color="primary"
            variant="contained"
          >
            Valider
          </Button>
        </DialogActions>
        {/* <DialogActions>
          <Button onClick={handleCloseKeyboard} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleValidatePinCode} color="primary">
            Valider
          </Button>
        </DialogActions> */}
      </Dialog>
      {/* //manokatra  ao am refuser  */}
      <Dialog
        open={openRefuseStep1}
        onClose={handleRefuseClose}
        aria-labelledby="form-dialog-title1"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title1">Motif de Refus</DialogTitle> <br />
        <div className={classes.root}>
          {/* <div>
            <h3 className={classes.step1Title}>Observation </h3>
          </div> */}

          <Grid className={classes.step1Grid} container>
            {/* <Grid className={classes.gridItem} item xs={4}>
                <label className={classes.label}>Motif de refus:</label>
              </Grid> */}
             <Grid item xs={4}>
              <label>Observation</label>
            </Grid>
            <Grid className={classes.gridItem} item xs={7}>
              {/* <label className={classes.label1}>{dataValidation.leave_type_id === 1? "Congé":"Permission"}</label> */}
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="observation"
                variant="outlined"
                name="observation"
                onChange={handleObservation}
              />
              
            </Grid>

            <Grid item xs={12}>
              <Spacer y={2} />
              <DialogActions>
                <Button
                  style={{ backgroundColor: "##f50057", color: "#FFFFFF" }}
                  onClick={closeDialog4}
                  color="secondary"
                  variant="contained"
                >
                  Annuler
                </Button>
                <Button
                  style={{
                    backgroundColor: "rgb(19, 62, 103)",
                    color: "#FFFFFF",
                  }}
                  // onClick={validateStep1}
                  onClick={handleRefuse}
                  color="primary"
                  variant="contained"
                >
                  Envoyer
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
