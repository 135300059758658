import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const DocumentService = {
  async saveDocument(auteur, resume, mot_cle, titre, fichier, id_rubrique, id) {
    try {
      console.log("resume", resume);

      console.log("mot_cle", mot_cle);
      console.log("auteur", auteur);
      console.log("titre", titre);
      console.log("fichier", fichier);
      console.log("rubrique_mere_id", id_rubrique);
      console.log("id", id);

      console.log("services, id doc to edit: ", id);
      console.log("nouveau rubrique_mere_id", id_rubrique);
      //console.log("nouveau fichier", fichier);

      const result = id
        ? await axios.put(`${apiUrl}/document/update_document/${id}`, {
            resume: resume,
            mot_cle: mot_cle,
            auteur: auteur,
            titre: titre,
            fichier: fichier,
            rubrique_id: id_rubrique,
          })
        : await axios.post(`${apiUrl}/document/post_document`, {
            resume: resume,
            mot_cle: mot_cle,
            auteur: auteur,
            titre: titre,
            fichier: fichier,
            id_rubrique: id_rubrique,
          });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async AddDocumentLocation(state_id, current_holder, location_id, id) {
    try {
      console.log("state_id", state_id);
      console.log("current_holder", current_holder);
      console.log("location_id", location_id);
      console.log("id", id);

      const result = await axios.put(`${apiUrl}/document/add_document_location/${id}`, {
        state_id: state_id,
        current_holder: current_holder,
        location_id: location_id,
      });
      if (result) {
        console.log(result);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async documentById(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/document/get_document_by_id/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getDocument() {
    try {
      const result = await axios.get(`${apiUrl}/document/get_document`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async deleteDocument(id) {
    try {
      const result = await axios.delete(
        `${apiUrl}/document/delete_document/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async uploadFile(formData) {
    try {
      const result = await axios.post(`${apiUrl}/document/upload`, formData);
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async download(id) {
    //tsy nampiasaina?
    try {
      const result = await axios.get(`${apiUrl}/document/down/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getFichier(id) {
    try {
      const result = await axios.get(`${apiUrl}/document/getFichier/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async readFileContent(filename) {
    try {
      const result = await axios.get(
        `${apiUrl}/document/readFile/${filename}`,
        {
          responseType: "arraybuffer", //reponse attendu au format binaire
          headers: {
            "Content-Type": "application/docx",

            "Content-Type": "application/pdf",
            "Content-Type": "application/xlsx",
            "Content-Type": "application/zip",
            "Content-Type": "application/x-vcard",

            Accept: "application/docx",
            Accept: "application/xlsx",
            Accept: "application/pdf",
            Accept: "application/x-vcard",
            Accept: "application/zip",
          },
        }
      );
      if (result) {
        // TODO: replace result.data.message by the token
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getAction(userId, idDocument) {
    try {
      // console.log("services, idDocument:  ", idDocument);
      //console.log("services , userId: ", userId, "/", idDocument);
      const result = await axios.get(
        `${apiUrl}/document/get_action/${userId}/${idDocument}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.result) {
        return error.result.data;
      }
    }
  },

  async getDocumentTitle() {
    try {
      const result = await axios.get(
        `${apiUrl}/GedClassification/get_document_title`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.result) {
        return error.result.data;
      }
    }
  },

  async saveClassification(
    document_id,
    current_holder,
    storage_address,
    location,
    document_status,
    editId
  ) {
    try {
      console.log("document_id: ", document_id);
      console.log("current_holder: ", current_holder);

      console.log("storage_address: ", storage_address);

      console.log("location: ", location);

      console.log("document_status: ", document_status);

      console.log("editId: ", editId);

      const result =
        editId == 0
          ? await axios.post(
              `${apiUrl}/GedClassification/post_classification`,
              {
                document_id: document_id,
                current_holder: current_holder,
                storage_address: storage_address,
                location: location,
                document_status: document_status,
              }
            )
          : await axios.put(
              `${apiUrl}/GedClassification/put_classification/${editId}`,
              {
                document_id: document_id,
                current_holder: current_holder,
                storage_address: storage_address,
                location: location,
                document_status: document_status,
              }
            );

      if (result) {
        // console.log(result);
        // console.log(result.data); status, message, data
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async saveLocation(
    direction_id,
    seat_id,
    building_id,
    storage_place,
    storage_1,
    storage_2,
    storage_3,
    id
  ) {
    try {
      console.log("direction_id: ", direction_id);
      console.log("seat_id: ", seat_id);
      console.log("building_id: ", building_id);
      console.log("storage_place: ", storage_place);
      console.log("storage_1: ", storage_1);
      console.log("storage_2: ", storage_2);
      console.log("storage_3: ", storage_3);
      console.log("editId: ", id);

      const result =
        id == 0
          ? await axios.post(`${apiUrl}/GedClassification/post_location`, {
              direction_id: direction_id,
              seat_id: seat_id,
              building_id: building_id,
              storage_place: storage_place,
              storage_1: storage_1,
              storage_2: storage_2,
              storage_3: storage_3,
            })
          : await axios.put(`${apiUrl}/GedClassification/put_location/${id}`, {
              direction_id: direction_id,
              seat_id: seat_id,
              building_id: building_id,
              storage_place: storage_place,
              storage_1: storage_1,
              storage_2: storage_2,
              storage_3: storage_3,
            });

      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getLocation() {
    try {
      const result = await axios.get(
        `${apiUrl}/GedClassification/get_location`
      );
      if (result) {
        
        return result.data;
      
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getClassification() {
    //à supprimer
    try {
      const result = await axios.get(
        `${apiUrl}/GedClassification/get_classification`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.result) {
        return error.result.data;
      }
    }
  },

  async getLocationById(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/GedClassification/get_location_by_id/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.result) {
        return error.result.data;
      }
    }
  },

  async getClassificationById(id) {
    //fafaina
    try {
      const result = await axios.get(
        `${apiUrl}/GedClassification/get_classification_by_id/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.result) {
        return error.result.data;
      }
    }
  },

  async deleteLocation(id) {
    try {
      const result = await axios.delete(
        `${apiUrl}/gedClassification/delete_location/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.result) {
        return error.result.data;
      }
    }
  },

  async deleteClassification(id) {
    //fafaina
    try {
      const result = await axios.delete(
        `${apiUrl}/gedClassification/delete_classification/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.result) {
        return error.result.data;
      }
    }
  },

  async getUserName(id) {
    try {
      const result = await axios.get(`${apiUrl}/document/getUserName/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.result) {
        return error.result.data;
      }
    }
  },

  async getEtat() {
    try {
      const result = await axios.get(`${apiUrl}/document/get_etat`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.result) {
        return error.result.data;
      }
    }
  },

  async getSeat(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/GedClassification/get_seats/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.result) {
        return error.result.data;
      }
    }
  },

  async getBuilding(id) {
    try {
      const result = await axios.get(
        `${apiUrl}/GedClassification/get_buildings/${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.result) {
        return error.result.data;
      }
    }
  },

  async getLocationForRollingList() {
    try {
      const result = await axios.get(`${apiUrl}/Document/get_location`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.result) {
        return error.result.data;
      }
    }
  },
};

export default DocumentService;
