import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';

const apiUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "row",
      maxWidth: 600,
      elevation: 0,
    },
    form: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    labelPosition1: {
      float: 'left',
    },
    text: {
      width: 370,
    },
    labelPosition: {
      float: 'left',
      fontWeight: 'bold'
    },
    root: {
      width: 200,
      height: 200,
      marginTop: '5.5%',
    },
  }));
export default function UserInfo(props){
  const classes = useStyles();
  const openUserInfo = props.openUserInfo;
  const data = props.data;

  const closeUserInfo = props.closeUserInfo;
  return (
    <div>
      <Dialog open={openUserInfo} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="form-dialog-title" color="primary" /*style={{backgroundColor: '#0000B3'}}*/ >{"Information sur l'utilisateur"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container /*className={classes.paper}*/ variant="outlined">
                {data!== null && <Grid container item spacing={3} xs={12}>
                    <Grid item xs={12}>
                        <Card className={classes.root}>
                        <img
                            src={apiUrl+"/Image/"+data.name_image}
                            title={data.first_name}
                            alt={data.first_name}
                            height={200}
                            width={200}
                        />
                        </Card>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition}>Nom : </label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition1}>{data.first_name}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition}>Prénom : </label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition1}>{data.last_name}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition}>Fonction : </label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition1}>{data.function_name}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition}>Direction : </label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition1}>{data.direction_name}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition}>Sexe : </label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition1}>{data.sex}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition}>E-mail : </label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition1}>{data.email}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition}>Contact en cas d'urgence : </label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelPosition1}>{data.emergency_phone}</label>
                    </Grid>
                </Grid>}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={closeUserInfo} >Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
