export const headerDataImmo = {
  title: "Logistiques",
  submodules: [
    createData(0, "Gestion immobilisation", "/logistic/immobilisations"),
    createData(1, "Catégorie matériel", "/logistic/category"),
    createData(2, "Classement matériel", "/logistic/ranking"),
    createData(3, "Configuration matériel", "/logistic/config"),
    createData(4, "Historique d'inventaire", "/logistic/inventory"),
    createData(5, "Génération QrCode", "/logistic/generateQrCode"),
    createData(6, "Réunion", "/Logistic/meeting_management"),
  ],
};

function createData(id, moduleName, ref) {
  return { id, moduleName, ref };
}
