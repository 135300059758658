import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Child from './Child';
import FamilyMemberService from "../../../services/FamilyMemberService";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import { Box } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  labelPosition: {
    float: 'left',
  },
  labelPosition1: {
    float: 'right',
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
}));

export default function PaginationChild(props) {
  const classes = useStyles();
  const [countChild, setCountChild] = React.useState(0); //isan-jaza
  const [value, setValue] = useState(1);
  const handleChange = (event, value) => {
    setValue(value);
  };

  const [defaultValueOfChild, setDefaultValueOfChild] = React.useState([]); 
  useEffect(() => {
    FamilyMemberService.getCountChild(props.user_id).then((result) => {
      setCountChild(result.data);
    });
    // 
    FamilyMemberService.getChildByUserId(props.user_id).then((result) => {
      setDefaultValueOfChild(result.data);
    });
    // 
  }, []);
  const child = [];
  for(var i=0; i<countChild; i++){
    child.push(
      <TabPanel value={value-1} index={i}>
        <label className={classes.labelPosition}>Enfant {value}</label>
        <Child user_id={props.user_id} rank={value} defaultValueOfChild={defaultValueOfChild[i]} address={props.address}/>
      </TabPanel>
      
    );
  }
  
  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.paper} variant="outlined">
        {child}
      </Grid>
      <Pagination count={countChild} page={value} onChange={handleChange}/>
    </div>
  );
}