import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';  
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ViewingMettingService from "../../services/ViewingMettingService";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { TextField , Typography, Grid, IconButton } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import dayjs from 'dayjs';

moment.locale('fr');

const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    width: '40%',
    maxHeight: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section_time: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  time: {
    width: '50%',
  },
  time_style: {
    width: '100%',
  },
  link: {
    cursor: 'pointer',
    width: '100%',
  },
  link_style: {
    width: '100%',
  },
}));

function EventPopup({ event, handleClose }) {
  const classes = useStyles();

  const handleClick = () => {
    if (event.link) {
      window.open('https://meet.jit.si/'+event.link, '_blank'); // Ouvre le lien dans un nouvel onglet
    }
  };

  return (
    <Modal
      open={!!event}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={!!event}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <Typography variant="h5">{event?.title}</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.content}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <p>Date et Heure</p>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      mask="__ / __ / ____"
                      disabled
                      value={event.date_meeting}
                      renderInput={(params) =>
                        <TextField {...params} fullWidth variant="outlined" size="small" id="date-picker-dialog"  color="primary" error={false} />
                      }
                    />
                  </LocalizationProvider>
                </div>

                <div className={classes.section_time}>
                  <div className={classes.time}>
                    <TextField className={classes.time_style} id="outlined-basic" disabled size="small" label={event.time_meeting_start.format('HH:mm')} variant="outlined" /> 
                  </div>

                  <div>
                    <IconButton>
                      <ArrowForwardOutlinedIcon />
                    </IconButton>
                  </div>

                  <div className={classes.time}>
                    <TextField className={classes.time_style} id="outlined-basic" disabled size="small" label={event.time_meeting_end.format('HH:mm')} variant="outlined" /> 
                  </div>

                </div>
              </Grid>

              <Grid item xs={12}>
                <p>Objet</p>
                  <div className={classes.link}>
                    <TextField className={classes.time_style} id="outlined-basic" disabled size="small" label={event.object_meeting} variant="outlined" /> 
                  </div>                
              </Grid>

              <Grid item xs={12}>
                <p>Lien</p>
                  <div className={classes.link}>
                  <TextField
                  className={classes.link_style}
                  id="outlined-read-only-input"
                  size="small"
                  value={'https://meet.jit.si/'+event.link}
                  variant="outlined"
                  onClick={handleClick}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                  </div>                
              </Grid>

            </Grid>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

function MyCalendar() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const getDate = (time , dateString) => {
    try {

      let dateObj = new Date();
  
      if (dateString) {
        const [year, month, day] = dateString.split('-');
        dateObj.setFullYear(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)); // Months are 0-indexed
      }
      if (time) {
        const [hours, minutes, seconds] = time.split(':');
        dateObj.setHours(parseInt(hours, 10), parseInt(minutes, 10), parseInt(seconds || 0)); // Default seconds to 0
      }
      return dayjs(dateObj);
    } catch (error) {
      console.error("Error parsing time:", error);
      return null; // Or handle the error as needed
    }
  };

  useEffect(() => {
    ViewingMettingService.getViewingMetting().then(response => {
      const backendEvents = response.data.map(event => ({
        title: event.utilisateur + ' - Salle ' + event.room,
        start: new Date(event.annee, event.mois - 1, event.jour, event.heure_debut, event.minute_debut),
        end: new Date(event.annee, event.mois - 1, event.jour, event.heure_fin, event.minute_fin),
        date_meeting: getDate(null,event.date_meeting),
        time_meeting_start: getDate(event.time_meeting_start),
        time_meeting_end: getDate(event.time_meeting_end),
        object_meeting: event.object_meeting,
        link: event.link,
      }));
      console.log(backendEvents);
      setEvents(backendEvents);
    }).catch(error => {
      console.error('Erreur lors de la récupération des événements:', error);
    });
  }, []);

  const handleSelectEvent = (event) => {
    console.log(event);
    setSelectedEvent(event);
  };

  const handleClose = () => {
    setSelectedEvent(null);
  };

  return (
    <div style={{ height: '90vh' }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onSelectEvent={handleSelectEvent}
        messages={{
          date: 'Date',
          time: 'Heure',
          event: 'Événement',
          allDay: 'Toute la journée',
          week: 'Semaine',
          work_week: 'Semaine de travail',
          day: 'Jour',
          month: 'Mois',
          previous: 'Précédent',
          next: 'Suivant',
          yesterday: 'Hier',
          tomorrow: 'Demain',
          today: "Aujourd'hui",
          agenda: 'Agenda',
          noEventsInRange: "Il n'y a pas d'événements dans cette période.",
          showMore: total => `+ ${total} événement(s) supplémentaire(s)`
        }}
      />
      {selectedEvent && <EventPopup event={selectedEvent} handleClose={handleClose} />}
    </div>
  );
}

export default MyCalendar;
