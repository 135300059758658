import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const MeetingRoomService = {
    async getMettingRoom(){
        try {
            const result = await axios.get(`${apiUrl}/meetingroom/`  );
            if (result) {
              // TODO: replace result.data.message by the token
              
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }  
    },


   //modification de donnée
   async MeetingRoomById(id){
    try {
        const result = await axios.get(`${apiUrl}/meetingroom/id/${id}`  );
        if (result) {
          // TODO: replace result.data.message by the token

          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    } ,

}

export default MeetingRoomService;