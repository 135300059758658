import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const ValidationService = {
  async validatePassword(password) {
    try {
      const result = await axios.get(`${apiUrl}/validation/checkPassword/${password}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
        return error.response.data;
    }
  },

  async electronicValidation(codePin, validationId, requesttype,filerequest) {
    try {
      const result = await axios.put(`${apiUrl}/validation/electronicValidation/`,{
        codePin: codePin,
        validationId: validationId,
        requesttype: requesttype,
        filerequest:filerequest
      });
      if (result) {
        return result.data;
      }
    }
    catch (error) {
        return error.response.data;
    }
  },
  async status(id) {
    try {
      const result = await axios.get(`${apiUrl}/LeaveSolde/status/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
        return error.response.data;
    }
  },
  async declined(id) {
    try {
      const result = await axios.put(`${apiUrl}/Validation/updateValidation/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
        return error.response.data;
    }
  },
  async getAllStateRequestByUser() {
    try {
      const result = await axios.get(`${apiUrl}/Validation/getAllStateRequestByUser`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
        return error.response.data;
    }
  },

  async getDetailOfLeave(validationId) {
    try {
      const result = await axios.get(`${apiUrl}/Validation/getDetailOfLeave/${validationId}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
        return error.response.data;
    }
  },
  async saveValidation(id) {
    try {
      const result = await axios.get(`${apiUrl}/validation/saveValidation/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
        return error.response.data;
    }
  },
  async delete(id) {
    try {
      const result = await axios.delete(`${apiUrl}/validation/${id}`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }

  
}

export default ValidationService;
  
