import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const DayService = {
  async getDay(){
    try {
        const result = await axios.get(`${apiUrl}/Day/`);
        if (result) {

          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    
  }
   ,
       async getMonth(id,param){
        try {
            const result = await axios.get(`${apiUrl}/Day/getDay/${id}/${param}`);
            if (result) {
    
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        
      }
       ,
       async getDays(){
        try {
            const result = await axios.get(`${apiUrl}/Day/getDays/`);
            if (result) {
    
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        
      }
       ,
       async saveDay(month,number,param) {
        try {
          const result = await axios.post(`${apiUrl}/Day`, {
            month:month,
            number:number,
            param:param,
          });
          if (result) {
             return result.data;
          }
         } catch (error) {
           if (error.response) {
             return error.response.data;
            }
          }
   } ,

   async deleteDay(id,param){
    try {
        const result = await axios.delete(`${apiUrl}/Day/${id}/${param}`  );
        if (result) {
           return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    },
    async DayWeek(id,param){
      try {
          const result = await axios.post(`${apiUrl}/Day/DayWeek/${id}/${param}`);
          if (result) {
  
            return result.data;
          }
        } 
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      
    }
     ,
    
   

}

export default DayService;