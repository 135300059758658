import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import {
    Grid,
    Paper,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField
} from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        elevation: 0
    },
}));


export default function DayNotComptedPerPersonList(props) {
    const rows = props.rows;
    // const columns = props.columns;
    const handleEditAnnualCalendar = props.handleEditAnnualCalendar;
    const handleDeleteAnnualCalendar = props.handleDeleteAnnualCalendar;
    const classes = useStyles();

    const configColumns = [
        {
            field: 'user_name',
            headerName: 'Utilisateur',
            width: 200,
        },
        {
            field: 'day_label',
            headerName: 'Paramètrage',
            width: 350,
        },
        {
            field: 'action',
            headerName: 'Action',
            disableClickEventBulbing: true,
            renderCell: (params) => {
                return (
                    <div>
                        <Button onClick={handleEditAnnualCalendar(params.id)} title="Modifier validateur"><CreateTwoTone style={{ color: "black" }} />
                        </Button>
                        <Button onClick={handleDeleteAnnualCalendar(params.id)} title="Supprimer cette configuration">
                            <DeleteOutlineRounded style={{ color: "red" }} />
                        </Button>
                    </div>
                );
            },
            width: 175,
        },
    ];

    return (
        // <Grid container spacing={3}>
        //     {/* Recent Orders */}
        //     <Grid item xs={12}>
        <React.Fragment>

            <div style={{ height: "40vh", width: '100%' }} className={classes.root} >
                <DataGrid
                    rows={rows}
                    columns={configColumns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                />
            </div>
        </React.Fragment>
        //     </Grid>
        // </Grid>
    );
}
