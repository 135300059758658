import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ServiceService from "../../services/ServiceService";
import Message from "../tools/Message";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ImportCSV(props) {
  const openDialog = props.isActive;
  const closeDialog = props.closeDialog;
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const [buttonName, setButtonName] = useState('Choisir un fichier');

  function onFileChange(event) {
    setSelectedFile(event.target.files[0]);
    setButtonName(event.target.files[0].name);
  }
  function onFileUpload() {
    const formData = new FormData();
    formData.append(
      "service_csv",
      selectedFile,
      selectedFile.name
    );
    ServiceService.importService(formData).then((result) => {
      if (result.status) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
      }
    })
      .catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      });

  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  return (
    <div>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="form-dialog-title" color="primary" /*style={{backgroundColor: '#0000B3'}}*/ >{"Importer des services"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {" Pour importer des services, vous devez selectionner le bouton \"Choisir un fichier\"."}<br />
            {" - Choisissez en suite un \"fichier .CSV\" dans votre répertoire, ayant un ou plusieur ligne du modèle suivant:"}<br />
            <label style={{ marginLeft: '3em' }}>{"\t code direction, nom service, code service, description service ."}</label><br />
            {" - Cliquer ensuite le bouton \"IMPORTER\" pour insérer les informations dans votre fichier .CSV."}<br /><br />
            {" NB: Assurez-vous que tous les contenus dans votre fichier .CSV sont conformes à l'exemple précédent. "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <input
            id="contained-button-file"
            type="file" name="service_csv" onChange={onFileChange} style={{ display: 'none' }} />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="" component="span" >{buttonName}</Button>
          </label>
          {buttonName !== 'Choisir un fichier' && <Button variant="contained" color="primary" onClick={onFileUpload} >IMPORTER</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}

