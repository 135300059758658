import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const EquipmentService={
    async save(equipment_name,equipment_category,equipment_code,equipment_description){
        try{
            const result=await axios.post(`${apiUrl}/equipment`,
            {
                equipment_name,
                equipment_category,
                equipment_code,
                
                equipment_description,
            }
            );
            if(result){
                return result;
            }
        }catch(error){
            if(error.response){
                return error.response.data;
            }
        }
    },
    async getAllEquipment() {
        try {
          const result = await axios.get(`${apiUrl}/equipment/`);
          if (result) {
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      },
      async update(equipment_name,equipment_category,equipment_code,equipment_description,id) {
        try {
          const result = await axios.put(`${apiUrl}/equipment/${id}`,
            {
               equipment_name,
               equipment_category,
               equipment_code,
               
               equipment_description,
            }
          );
          if (result) {
            return result;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      },
      async deleteEquipment(id) {
        try {
          const result = await axios.delete(`${apiUrl}/Equipment/id/${id}`);
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            throw new Error(error.response.data);
          }
          throw error;
        }
      },
       async getEquipmentById(id) {
    try {
      const result = await axios.get(`${apiUrl}/equipment/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
}
export default EquipmentService;