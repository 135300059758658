import { Grid, Paper } from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import EquipmentRankingTable from "./EquipmentRankingTable";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  tab1: {
    width: "50%",
  },
}));
export default function EquipmentRankingList() {
  const navigate = useNavigate();
  const classes = useStyles();

  function handleClick() {
    navigate("/Logistic/ranking/add");
  }
  return (
    <MainContainer {...headerDataImmo}>
      <Grid container item xs={12}>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          CREER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <EquipmentRankingTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
