import React, { useState, useEffect } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import PlanningMissionService from "../../../services/PlanningMissionService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Message from "../../tools/Message";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header";
import Title from "../../../components/Title"; 
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    bold: {
        fontWeight: 600
    },
    labelPosition1: {
        float: 'right',
    },
});

function AgendaMission(props) {
    const navigate = useNavigate();
    const classes = useStyles();
    const [message, setMessage] = React.useState({
        open: false,
        color: 'green',
        messageInfo: '',
      });
    const { open, color, messageInfo } = message;
    const [days, setDays] = useState([]);
    const [missionary, setMissionary] = useState([]);
    const params = useParams();
    const mission_planning_id = params.id;
    const [form, setForm] = useState({mission_planning_agenda_details : []});
        for(var i=0; i<days.length; i++){
            if(form.mission_planning_agenda_details.length < days.length){
                form.mission_planning_agenda_details.push(
                    {
                        date:days[i],
                        activity:"",
                        participants:"",
                        observation:"",
                        user_id:[]
                    }
                );
            }

        }
        
    useEffect(() => {
        PlanningMissionService.getMissionPlanningDays(mission_planning_id).then((result) => {
            if (result && result.data) {
                setDays(result.data);
            }
        });

        PlanningMissionService.getMissionary(mission_planning_id).then((result) => {
            if (result && result.data) {
              setMissionary(result.data);
            }
        });
        
    }, []);
    function handleChange(event, index){
        const { name, value } = event.target;
        event.preventDefault();
        let values = [...form.mission_planning_agenda_details];
        if(name==="activity"){
            values[index].activity = value;
        }
        if(name==="participants"){
            values[index].participants = value;
        }
        if(name==="observation"){
            values[index].observation = value;
        }
        setForm({ ...form, values });
    }
    
    function handleChangeMissionary(event, value, index) {
        event.preventDefault();
        let values = [...form.mission_planning_agenda_details];
        values[index].user_id = value;
        setForm({ ...form, values });
    }

    function handleCloseMessage(){
        setMessage({...message, open: false});
    }

    function handleClick(){
        PlanningMissionService.saveMissionPlanningAgenda(form, mission_planning_id, params.modif).then((result) => {
            if(result.status){
              setMessage({...message, open: true, color: 'green', messageInfo: result.message});
              navigate("/mission/elaboration_tdr");
            }else{
              setMessage({...message, open: true, color: 'red', messageInfo: result.message});
            }
          })
          .catch((err) => {
            setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
        });
    }
    // 
    return(
        <MainContainer {...headerDataMission}>
        <Title>Agenda</Title>
        <Spacer y={2} />
        <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.bold} >Date</TableCell>
                            <TableCell className={classes.bold} >Activités</TableCell>
                            <TableCell className={classes.bold} >Missionnaires</TableCell>
                            <TableCell className={classes.bold} >Participants</TableCell>
                            <TableCell className={classes.bold} >Observation</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {form.mission_planning_agenda_details.map((items, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="th" scope="row" className={classes.bold} ><TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={items.date} /></TableCell>
                            {/*  */}
                            <TableCell><TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={items.activity} name="activity" onChange={(event) => handleChange(event, index)} /></TableCell>
                            <TableCell>
                                <Autocomplete multiple id="tags-outlined" options={missionary} getOptionLabel={(option) => option.user_name}
                                    filterSelectedOptions
                                    onChange={(event, value) => handleChangeMissionary(event, value, index)}
                                    value={items.user_id}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        id="outlined-basic"
                                        size="small"
                                        variant="outlined"
                                    />
                                    )} 
                                />
                            </TableCell>
                            <TableCell><TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={items.participants} name="participants" onChange={(event) => handleChange(event, index)} /></TableCell>
                            <TableCell><TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={items.observation} name="observation" onChange={(event) => handleChange(event, index)} /></TableCell>
                            {/*  */}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Spacer y={2} />
            <Grid container spacing={3} className={classes.paper}>
                <Grid item xs={12}>
                    <div className={classes.labelPosition1}>
                    <Grid container item xs={12} >
                        <Button variant="contained" color="primary" onClick={handleClick} style={{ fontWeight: 600 }} >ENREGISTRER</Button>
                    </Grid>
                    </div>        
                </Grid>
            </Grid>
        </MainContainer>
    );
}
export default AgendaMission;
