import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from 'react';
import LeaveService from '../../services/LeaveService';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle, Typography } from '@mui/material';
import Spacer from '../../components/Spacer';
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  id: {
    color: "#3f51b5",
    fontWeight: "bold",
  },
  actualBalance: {
    textAlign: "right",
    margin: "auto"
  },
  detail: {
    fontSize: "13px"
  },
  divDetail: {
    justifyContent: "center"
  }
}));
export default function ConfirmationDialog(props) {
  const classes = useStyles();
  const openDialog = props.openDialog;
  const messageDialog = props.messageDialog;
  const closeDialog = props.closeDialog;
  const confirmDialog = props.confirmDialog;
  const cancelDialog = props.cancelDialog;
  const isParametrage = props.isCountFactParam ? true : false;
  const isCancel = props.isCancel;
  const leave_id = props.idLeave;
  const isPlanning = props.isPlanning;
  const confirmLeaveAdd = props.confirmLeaveAdd;
  const handleJustePlanningDialog = props.handleJustePlanningDialog;
  const handleValidateLeaveDialog = props.handleValidateLeaveDialog;
  const loadingPlanning = props.loadingPlanning;
  const loadingValidate = props.loadingValidate;
  const isValidatorNumberConfigurate = props.isValidatorNumberConfigurate;
  const dayOffNumberWithoutValidation = props.dayOffNumberWithoutValidation;
  const dayOffRequested = props.dayOffRequested;

  const [duration, setduration] = useState("");
  const [realDuration, setRealDuration] = useState("");
  const [beginMoment, setBeginMoment] = useState("");
  const [endMoment, setEndMoment] = useState("");
  const [leavecode, setLeavecode] = useState("");
  const [nomprenom, setNomprenom] = useState("");

  useEffect(() => {
    console.log(leave_id);
    if (leave_id !== 0) {
      LeaveService.getLeaveById(leave_id).then((res) => {
        if (res && res.status) {
          console.log("ressssssssssss", res);
          setLeavecode(res.data.leave_code);
          setNomprenom(`${res.data.last_name + " " + res.data.first_name}`)
          setBeginMoment(res.data.date_start);
          setEndMoment(res.data.date_end);
          setRealDuration(res.data.duration);
          setduration(0);
        }
      });
    }
  }, props)
  // const envoyerDialog = props.envoyerDialog;
  return (
    <div>
      <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="form-dialog-title" color="primary" /*style={{backgroundColor: '#0000B3'}}*/ >{(isCancel && !isPlanning) ? "Annulation demande de congé" : ((isCancel && isPlanning) ? "Annulation planification" : "Confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {(!isCancel && !isPlanning && !confirmLeaveAdd) && messageDialog}
            {((isCancel && !isPlanning && !confirmLeaveAdd) || isPlanning) &&
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                  L'annulation d'un congé revient à une demande avec <strong> 0 nombre de jour demandé</strong>.
                  Voir détails ci-après:
                </Alert>
                </Grid>
                <Grid container item spacing={2} className={classes.detail} xs={8}>
                  <Grid item xs={5}>
                    <label className={classes.id}>Identification:</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label className={classes.id}>{leavecode}</label>
                  </Grid>

                  <Grid item xs={5}>
                    <label className={classes.label}>Nom et Prénom:</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label className={classes.label1}>{nomprenom}</label>
                  </Grid>


                  <Grid item xs={5}>
                    <label className={classes.label}>Date de Départ:</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label className={classes.label1}>{beginMoment}</label>
                  </Grid>

                  <Grid item xs={5}>
                    <label className={classes.label}>Date de retour:</label>
                  </Grid>
                  <Grid item xs={7}>
                    <label className={classes.label1}>{endMoment}</label>

                  </Grid>

                  <Grid item xs={5}>
                    <label className={classes.label}>Jour demandé :</label>
                  </Grid>

                  <Grid item xs={7}>
                    <label className={classes.label1}>{realDuration}</label>
                  </Grid>

                  <Grid item xs={5}>
                    <label className={classes.label}>Jour demandé après confirmation annulation:</label>
                  </Grid>

                  <Grid item xs={7}>
                    <label className={classes.label1}>{duration}</label>
                  </Grid>
                </Grid>
                <Grid item xs={7}>
                  <div style={{ textAlign: "center" }}>Confirmez-vous votre action?</div>
                </Grid>
              </Grid>
            }

            {(!isCancel && !isPlanning && confirmLeaveAdd) &&
              <>
              {isValidatorNumberConfigurate && dayOffRequested <= dayOffNumberWithoutValidation &&
                <span>La durée de votre demande en congé est <b>inférieur à {dayOffNumberWithoutValidation} jours</b>.<br/><br/>Dans le cadre d'une configuration spéciale sans validation associée à votre compte,
                vous avez la possibilité de le valider directement, ce qui déclenchera l'envoi automatique d'une notification aux équipes des ressources humaines.<br/><br/> Voulez-vous le valider directement ou c'est juste un planning?</span>
              }
              
              {isValidatorNumberConfigurate && dayOffRequested > dayOffNumberWithoutValidation &&
                <span>La durée de votre demande en congé est <b> supérieur à {dayOffNumberWithoutValidation} jours</b>.<br/><br/> Dans le cadre d'une configuration spéciale sans validation associée à votre compte,
                cette demande de congé sera envoyé pour validation de votre supérieur hierarchique.
                <br/><br/> Voulez-vous l'envoyer directement à votre 
                supérieur hiérarchique pour demander sa validation ou c'est juste un planning?</span>
              }
              
              {!isValidatorNumberConfigurate &&
                <span>Voulez-vous l'envoyer directement à votre supérieur hiérarchique pour demander sa validation ou c'est juste un planning? </span>
              }
              </>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!confirmLeaveAdd &&
            <>
              <Button variant="contained" color="primary" onClick={confirmDialog} >{isParametrage ? "OK" : "CONFIRMER"}</Button>
              {!isParametrage && <Button variant="contained" color="secondary" onClick={cancelDialog} >Annuler</Button>}
              {/* <Button variant="contained" color="secondary" onClick={envoyerDialog} >Envoyer</Button> */}
            </>
          }
          {confirmLeaveAdd &&
            <>
              {!isParametrage && <Button variant="contained" color="secondary" onClick={cancelDialog} disabled={(loadingPlanning || loadingValidate) ? true : false}>Retour</Button>}
              <Button
                style={loadingValidate ? { backgroundColor: "#F0F0F0", color: "#000000" } : { backgroundColor: "rgb(106, 207, 246)", color: "#FFFFFF" }}
                variant="contained"
                color="primary"
                onClick={handleJustePlanningDialog}
                disabled={(loadingValidate) ? true : false} >{loadingPlanning ? <CircularProgress /> : "Juste un planning"}</Button>

              <Button
                style={loadingPlanning ? { backgroundColor: "#F0F0F0", color: "#000000" } : { backgroundColor: 'rgb(19, 62, 103)', color: "#FFFFFF" }}
                variant="contained"
                color="primary"
                onClick={handleValidateLeaveDialog}
                disabled={(loadingPlanning) ? true : false}>{loadingValidate ? <CircularProgress /> : (isValidatorNumberConfigurate && dayOffRequested <= dayOffNumberWithoutValidation)? "Valider":"Envoyer pour validation"}</Button>
            </>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
