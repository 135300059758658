import { useEffect, useState } from "react";
import { useParams } from 'react-router';
import { Document, Page, pdfjs} from "react-pdf";
import FileService from "../../../services/FileService";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



function LeavePdfRender(props) {
  const params = useParams();
  const filename = params.id;
  console.log("props   *****", filename);
  const [pdf, setPdf] = useState("");
  const [pageNumber, setStateNumPage] = useState(0);

  useEffect(() => {
    FileService.readFile(filename).then((result) => {
      if (result) {
        const blob = new Blob([result.data], {
          type: 'application/pdf',
        });
        
        const fileURL = URL.createObjectURL(blob);
        const w = window.open(fileURL, '_blank');
        w && w.focus();
        URL.revokeObjectURL(fileURL);
      }
    });
  }, []);

  function onDocumentLoad() {

    setStateNumPage(1);
  }

  return (
    <>
      <Document file={pdf}
        onLoadSuccess={onDocumentLoad}
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </>
  );

}
export default LeavePdfRender;
