import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import {
    Grid,
    Paper,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerData } from "../../../data/modules/my-account-header";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { hasPrivilege } from "../../../utils/privilege";
import Message from "../../tools/Message";
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import PlanningService from "../../../services/PlanningService";

import TextField from '@mui/material/TextField';
import DirectionService from "../../../services/DirectionService";
import UserService from "../../../services/UserService";
import BalanceConfigurationService from "../../../services/BalanceConfigurationService";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
const bullRed = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
    >
        *
    </Box>
)
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "row",
        maxWidth: 600,
        elevation: 0,
    },
    form: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    datePicker: {
        margin: 0,
    },
    businessInput: {
        display: "none",
    },
    disabled: {
        cursor: "default",
        opacity: "0.1",
    },
    solde1: {
        background: "red",

    },
    disabledSolde: {
        background: "rgb(235, 235, 228)",
        cursor: "default",
        pointerEvents: "none !important",
    },
    text: {
        fontSize: "16px",
        fontWeight: "bold",
    },
}));


function BalanceConfigurationAdd(props) {
    const params = useParams();
    const id = params.id;

    const idToUpdate = id ? id : "";

    const [form, setForm] = useState({
        direction_id: "",
        service_id: "",
        user_id: "",
        real_balance: "",
        userName: "",
        balance_configuration_id: ""
    });
    const [message, setMessage] = useState({
        open: false,
        color: 'green',
        messageInfo: '',
    });
    const { open, color, messageInfo } = message;
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const contextData = useContext(userDetailContext);

    const classes = useStyles();

    const [dir, setDir] = useState([]);
    const [service, setService] = useState([]);
    const [users, setUsers] = useState([]);
    const [numberdirection, setNumberdirection] = useState(0);
    const [numberservice, setNumberservice] = useState(0);
    const [idservice, setIdservice] = useState(0);
    const [iddir, setIddir] = React.useState(0);
    const [iduser, setIdUser] = React.useState(0);
    const [isUserEmpty, setisUserEmpty] = React.useState(false);
    const [errorMessageUserEmpty, setErrorMessageUserEmpty] = React.useState("");
    const [isSoldeEmpty, setisSoldeEmpty] = React.useState(false);
    const [errorMessageSoldeEmpty, setErrorMessageSoldeEmpty] = React.useState("");
    const [isUserUndefined, setisUserUndefined] = React.useState(false);
    const navigate = useNavigate();
    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }
    function handleSelect() {
        DirectionService.getDirection().then((res) => {
            if (res && res.status) {
                setDir(res.data);
            }
        })
    }
    useEffect(() => {

        setForm({
            direction_id: "",
            service_id: "",
            user_id: "",
            real_balance: "",
            userName: "",
            balance_configuration_id: "",
            observation:""
        });
        /**   DirectionService.getDirection().then((res) => {
              if (res && res.status) {
                  setDir(res.data);
              }
          })*/
        if (idToUpdate) {
            DirectionService.getDirection().then((res) => {
                if (res && res.status) {
                    setDir(res.data);
                }
            });
            BalanceConfigurationService.getConfigBalanceByUserId(idToUpdate).then((res) => {
                if (res && res.data) {
                    console.log(res.data);
                    setForm({
                        direction_id: res.data.direction_id,
                        service_id: res.data.service_id,
                        user_id: res.data.user_id,
                        real_balance: res.data.real_balance,
                        userName: `${res.data.last_name} ${res.data.first_name}`,
                        balance_configuration_id: res.data.initialBalanceConfiguration_id
                    })
                    PlanningService.Direction(res.data.direction_id).then((results) => {
                        if (results && results.data) {
                            setService(results.data);
                        }
                    })
                }
            })
        }
    }, []);


    function handleChangeDirection(event) {
        const { name, value } = event.target;
        setIddir(value);
        setForm({ ...form, [name]: value });
        PlanningService.Direction(value).then((results) => {
            if (results && results.data) {
                setService(results.data);
            }
        })
    }
    function handleChangeService(ev) {
        const { name, value } = ev.target;
        setForm({ ...form, [name]: value });
        setIdservice(value);
        UserService.getUserByService(value).then((results) => {
            if (results && results.status) {
                setUsers(results.data);
                setisUserEmpty(false);
            } else {
                setisUserEmpty(true);
                setErrorMessageUserEmpty("Aucun utilisateur n'est associé à ce service, ou toutes les configurations sont déjà définies");
            }
        })
    }
    function handleChangeUsers(ev) {
        const { name, value } = ev.target;
        setIdUser(value);
        setForm({ ...form, [name]: value });
        setisUserUndefined(false);
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
      }


    function handleChangeBalance(ev) {
        const { name, value } = ev.target;
        const newValue = value.replace(/[^0-9.]/g, '');
        setForm({ ...form, [name]: newValue });
        setisSoldeEmpty(false);
    }
    function handleClick(ev) {

        if (idToUpdate) {
            if (form.real_balance === "") {
                setisSoldeEmpty(true);
                setErrorMessageSoldeEmpty("Ce champ est obligatoire");
            } else {
                // BalanceConfigurationService.updateBalance(form.balance_configuration_id,form.user_id, form.real_balance).then((res) => {
                //     if (res && res.status) {
                //         setMessage({ ...message, open: true, color: 'green', messageInfo: res.message });
                //         setTimeout(function () { navigate("/balance_configuration") ; }, 1000);
                //     } else {
                //         setMessage({ ...message, open: true, color: 'red', messageInfo: res.message });
                //     }
                // });
                BalanceConfigurationService.addBalance(form.user_id, form.real_balance,form.observation).then((res) => {
                    if (res && res.status) {
                        setMessage({ ...message, open: true, color: 'green', messageInfo: res.message });
                        setForm({
                            direction_id: " ",
                            service_id: " ",
                            user_id: " ",
                            real_balance: " ",
                            observation:""
                        })

                        
                        setTimeout(function () { navigate("/balance_configuration") ; }, 500);
                    } else {

                        setMessage({ ...message, open: true, color: 'red', messageInfo: res.message });
                    }
                })
            }
        } else {

            if (form.direction_id !== "" && form.service_id !== "" && form.user_id !== "" && form.real_balance !== "") {
                BalanceConfigurationService.addBalance(form.user_id, form.real_balance,form.observation).then((res) => {
                    if (res && res.status) {
                        setMessage({ ...message, open: true, color: 'green', messageInfo: res.message });
                        setForm({
                            direction_id: " ",
                            service_id: " ",
                            user_id: " ",
                            real_balance: " ",
                            observation:""
                        })
                    } else {

                        setMessage({ ...message, open: true, color: 'red', messageInfo: res.message });
                    }
                })
            } else {
                if (form.user_id === "") {
                    setisUserUndefined(true);
                    setErrorMessageUserEmpty("Ce champ est obligatoire");
                }
                if (form.real_balance === "") {
                    setisSoldeEmpty(true);
                    setErrorMessageSoldeEmpty("Ce champ est obligatoire");
                }
            }
        }
    }
    function handleCancel() {
        navigate("/balance_configuration");
    }
    return (
        <MainContainer {...headerData}>
            <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            <Paper className={classes.paper} variant="outlined">
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <label>Direction{bullRed}</label>
                    </Grid>
                    <Grid item xs={8}>

                        <FormControl fullWidth variant="outlined" size="small">
                            <Select disabled={idToUpdate} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={form.direction_id} name="direction_id" onChange={handleChangeDirection} onFocus={handleSelect}>
                                {dir.map((row) => (
                                    <MenuItem value={row.direction_id}>{row.name}  ( {row.code} )</MenuItem>

                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <label>Service{bullRed}</label>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <Select disabled={idToUpdate} error={isUserEmpty} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={form.service_id} name="service_id" onChange={handleChangeService} >
                                {service.map((row) => (
                                    <MenuItem value={row.service_id}>{row.name}  ( {row.code} )</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {isUserEmpty && <><Spacer y={1} /><Alert severity="error">{errorMessageUserEmpty}</Alert></>}
                    </Grid>

                    <Grid item xs={4}>
                        <label>Utilisateur{bullRed}</label>
                    </Grid>

                    <Grid item xs={8}>
                        {!idToUpdate && <FormControl fullWidth variant="outlined" size="small">
                            <Select error={isUserUndefined} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={form.user_id} name="user_id" onChange={handleChangeUsers} >
                                {users.map((row) => (
                                    <MenuItem value={row.user_id}>{row.first_name} {row.last_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                        {idToUpdate &&
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                variant="outlined"
                                value={form.userName}
                                disabled
                            />}

                        {isUserUndefined && <><Spacer y={1} /><Alert severity="error">{errorMessageUserEmpty}</Alert></>}
                    </Grid>
                    <Grid item xs={4}>
                        <label>Solde initial sans tenir compte ce mois{bullRed}</label>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            error={isSoldeEmpty}
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            value={form.real_balance}
                            name="real_balance"
                            onChange={handleChangeBalance}
                        />

                        {isSoldeEmpty && <><Spacer y={1} /><Alert severity="error">{errorMessageSoldeEmpty}</Alert></>}
                    </Grid>
                    <Grid item xs={4}>
                        <label>Observation </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        value={form.observation}
                        name="observation"
                        label=""
                        onChange={handleChange}
                       />
                    </Grid>
                    <>
                        <Spacer y={2} />
                        <Grid item xs={12}>
                            <label className={classes.text}>({bullRed}):Champs obligatoires</label>
                        </Grid>
                        <Spacer y={2} />
                        <Grid container item xs={12} direction="row-reverse">
                            {hasPrivilege(contextData.privileges, 'Admin-Button-Save-Leave') &&
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleClick}
                                    style={{ fontWeight: 600 }}
                                >
                                    ENREGISTRER
                                </Button>
                            }
                            <Spacer x={2} />
                            <Button
                                variant="contained"
                                color="grey"
                                onClick={handleCancel}
                                style={{ fontWeight: 600 }}
                            >
                                ANNULER
                            </Button>
                        </Grid>
                    </>
                </Grid>
            </Paper>
        </MainContainer>
    );
}
export default BalanceConfigurationAdd;