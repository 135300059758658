import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const HistoryMettingService = {

    async export(id){
      try {
        const response = await axios({
            url: `${apiUrl}/historymetting/export/${id}`,
            method: 'GET',
            responseType: 'blob' // Important pour télécharger des fichiers binaires
        });
        return response.data; // Retourne les données binaires du fichier ZIP
      } catch (error) {
          if (error.response) {
              console.error('Error:', error.response.data);
              return error.response.data;
          } else {
              console.error('Error:', error.message);
          }
      }
    },

    async importPv(formData) {
      try {
        const result = await axios.post(`${apiUrl}/historymetting/import`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return result.data;
      } catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    },


    async saveMettingPv(pv, id_meeting) {
      if (!id_meeting) {
          return { status: false, message: 'ID manquant' };
      }

      try {
          const response = await axios.post(`${apiUrl}/historymetting/pv/`, {
              id_meeting,
              pv
          });
          return response.data;
      } catch (error) {
          if (error.response) {
              return error.response.data;
          } 
      }
    },


    async getHistoryMetting(){
        try {
            const result = await axios.get(`${apiUrl}/historymetting/`  );
            if (result) {
              // TODO: replace result.data.message by the token
              
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }  
    },

    async getMettingFinish(){
      try {
          const result = await axios.get(`${apiUrl}/historymetting/meetingfinish`  );
          if (result) {
            // TODO: replace result.data.message by the token
            
            return result.data;
          }
        } 
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }  
    },

  }

export default HistoryMettingService;