import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button, TextField } from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import DocumentTable from "./DocumentTable";
import { userDetailContext } from "../../../App";
import DirectionService from "../../../services/DirectionService";
import { hasPrivilege } from "../../../utils/privilege";
import { useNavigate } from "react-router-dom";
import { headerDataLibrary } from "../../../data/modules/rubrique_header";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function DocumentList(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const contextData = useContext(userDetailContext);
  const [etat, setEtat] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);

  function handleClick() {
    navigate("/document/add/");
  }
  function handleCloseDialog() {
    setEtat({
      open: false,
      message: "message",
    });
  }
  function handelClikImport() {
    setEtat({
      open: true,
    });
  }

  return (
    <>
      <MainContainer {...headerDataLibrary}>
        <Grid container item xs={12}>
          {/* {hasPrivilege(contextData.privileges, 'Admin-Button-Import-Direction') &&
        <Button variant="contained" onClick={handelClikImport} style={{ fontWeight: 600 }}>
              IMPORTER
        </Button>
} */}
          {/* <ImportCSV isActive={etat.open} closeDialog={handleCloseDialog} />

        <Spacer x={2} /> */}
          {/* {hasPrivilege(contextData.privileges, 'Admin-Button-Export-Direction') &&

        <ExportExcel dataToExport={dataToExport} fileName={fileName} />
} */}
          <Spacer x={2} />
          {/* {hasPrivilege(contextData.privileges, "Admin-Direction-Add") && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClick}
              style={{ fontWeight: 600 }}
            >
              CREER
            </Button> 
          )} */}

          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            CREER
          </Button>

          <Spacer x={2} />
          {/* <TextField
          id="outlined-basic" 
          label="Recherche"
          variant="outlined"
          size="small"
        /> */}
        </Grid>
        <Spacer y={2} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper} variant="outlined">
              {/* <Grid container spacing={2} style={{ marginLeft: "700px" }}>
                <Spacer y={3} />  */}

              {/*  <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="titre"
                    style={{ width: 200 }}
                    label="Recherche par titre"
                  />
                </Grid>

                <Spacer x={2} />

                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="mot_cle"
                    style={{ width: 200 }}
                    label="Recherche par mot clé"
                  />
                </Grid> */}

              {/* <Grid item xs={2}>
                  <Button>
                    <SearchIcon />
                  </Button>
                </Grid> */}
              {/* </Grid> */}
              <Spacer x={2} />
              <DocumentTable />
            </Paper>
          </Grid>
        </Grid>
      </MainContainer>
    </>
  );
}
