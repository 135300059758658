import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataSettings } from "../../data/modules/settings-header";
import NumberValidationService from "../../services/NumberValidationService";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";
import Message from "../tools/Message";
import Box from '@mui/material/Box';

const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)



const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function NumberValidationAdd(props) {
  const navigate = useNavigate();
  const [failedLogin, setFailedLogin] = useState(false);
  const classes = useStyles();

  const [modules, setModules] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  const params = useParams();
  const request_type_id = params.id ? params.id : "";
  const [form, setForm] = useState({
    minimum_validator: "",
    module_type_id: "",
  });

  const [validationNumber, setvalidationNumber] = useState(0);

  function handleClick() {
    NumberValidationService.saveRequestType(
      form.minimum_validator,
      form.module_type_id,
      request_type_id,
      validationNumber
    )
      .then((res) => {
        if (res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: res.message });
          navigate("/number_validation");
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: res.message });
        }
      })
      .catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'Une erreur est produite,Veuillez réessayer' });
      });

  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  function handleChange(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value });
  }
  useEffect(() => {
    if (request_type_id) {
      NumberValidationService.RequestTypeById(request_type_id).then(({ data: info }) => {
        setForm({
          minimum_validator: info[0].minimum_validator,
          module_type_id: info[0].module_type_id,
        });
        setvalidationNumber(info[0].validation_number);
      });
    }
    NumberValidationService.getModuleType().then((result) => {
      if (result && result.data) {
        setModules(result.data);
      }
    });
  }, []);

  const contextData = useContext(userDetailContext);
  function handleCancel() {
    navigate("/number_validation");
  }

  function handleChangeNumberOfValidation(e) {
    setvalidationNumber(e.target.value);
  }

  return (
    <MainContainer {...headerDataSettings}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
        {failedLogin && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>

          <Grid item xs={4}>
            <label class="aligneleft">Type de module</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small" disabled={request_type_id?true:false}>
              <InputLabel id="demo-simple-select-outlined-label">
                Type de module
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="ModuleType"
                value={form.module_type_id}
                name="module_type_id"
                onChange={handleChange}
              >
                {modules.map((row) => (
                  <MenuItem value={row.module_type_id}>{row.libelle_module_type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <label class="aligneleft">Validateur minimum</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Validateur minimum"
              variant="outlined"
              value={form.minimum_validator}
              name="minimum_validator"
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={4}>
            <label>Nombre étape de Validation:</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              variant="outlined"
              name="validatorNumber"
              inputProps={{ maxLength: 1 }}
              value={validationNumber}
              onChange={handleChangeNumberOfValidation}
            />
            <Spacer y={0.5} />
            {validationNumber == 1 && (
              <Alert severity="info"><i>Simple validation: vérification mot de passe</i></Alert>
            )}
            {validationNumber == 2 && (
              <Alert severity="info"><i>Double validation: vérification mot de passe et Code PIN</i></Alert>
            )}
          </Grid>

        </Grid>
      </Paper>
    </MainContainer>
  );
}
export default NumberValidationAdd;
