
import { makeStyles } from '@material-ui/core/styles';
import  { useState } from "react";
import {
    Grid,
    Button,
    TextField
} from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, InputLabel, Select, MenuItem, InputBase, styled } from '@mui/material';
import Spacer from "../../../components/Spacer";
import Box from '@mui/material/Box';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        elevation: 0
    },
}));
const CustomSelect = styled(Select)(({ theme }) => ({
    // Personnalisez le style si nécessaire
    '& .MuiSelect-select': {
        padding: theme.spacing(1),
    },
}));


const bullRed = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
    >
        *
    </Box>
)
export default function DayNotComptedPerPersonAdd(props) {
    const userName = props.userName;
    const validatorNumber = props.validatorNumber;
    const showSuperiorConfig = props.showSuperiorConfig;
    const superiorConfigInput = props.superiorConfigInput;
    const param = props.param;
    const idParam = props.idParam;
    const isEdit = props.isEdit;
    const annualCalendarId = props.annualCalendarId;
    const handleChangeNumberOfValidatorMission = props.handleChangeNumberOfValidatorMission;
    const handleValidate = props.handleValidate;
    const handleClose = props.handleClose;
    const [selectedParam, setSelectedParam] = useState(
        param.find((option) => option.annual_calendar_id === idParam) || ''
    );

    // Fonction pour gérer les changements de sélection
    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedParam(newValue);

        if (props.handleChangeParam) {
            props.handleChangeParam(newValue);
        }
    };
    //const handleChangeParam = props.handleChangeParam;

    
    const classes = useStyles();
    return (

        <Grid container spacing={2} xs={12}>
            <Grid item xs={4}>
                <label>Utilisateur:</label>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="user"
                    value={userName}
                    disabled
                />
            </Grid>
            
            <Grid item xs={4}>
              <label class="aligneleft">Paramètrage jour non comptabilisé:</label>
            </Grid>

            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined" size="small">

                {/* <Select labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={idParam}
                  onChange={handleChangeParam}>
                  {param.map((row) => (
                    <MenuItem value={row.annual_calendar_id}>{row.day_label}</MenuItem>

                  ))}
                </Select> */}
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="param-select">Paramètrage</InputLabel>
                            <CustomSelect
                                id="param-select"
                                value={selectedParam}
                                onChange={handleSelectChange}
                                label="Paramètrage"
                            >
                                {param.map((option) => (
                                    <MenuItem key={option.annual_calendar_id} value={option.annual_calendar_id}>
                                        {option.day_label}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>

              </FormControl>
            </Grid>

            <Grid container item xs={12} direction="row-reverse">

                <Button
                    type="submit"
                    style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF" }}
                    onClick={handleValidate}
                    color="primary"
                    variant="contained"
                >
                    Valider
                </Button>
                <Spacer x={2} />
                <Button
                    style={{ backgroundColor: "#f50057", color: "#FFFFFF" }}
                    onClick={handleClose}
                    variant="contained"
                >
                    Annuler
                </Button>
            </Grid>
        </Grid>
    );
}
