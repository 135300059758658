import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LeaveService from "../../services/LeaveService";
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Alert, AlertTitle } from '@mui/material';
import { CircularProgress } from "@material-ui/core";

export default function ConfirmationDialog(props) {
  const openDialog = props.openDialog;
  const messageDialog = props.messageDialog;
  const closeDialog = props.closeDialog;
  const confirmDialog = props.confirmDialog;
  const cancelDialog = props.cancelDialog;
  const isLoading = props.isLoading;
  const [datestart, setDatestart] = useState("");
  const [dateend, setDateend] = useState("");
  useEffect(() => {
    LeaveService.getparam().then((result) => {
      if (result && result.data) {
        //console.log("ato lay valiny ooo",result.data);
        setDatestart(result.data[0].date_start);
        setDateend(result.data[0].date_end);
      }
    });

    if(openDialog){
      localStorage.setItem('status',2);
      console.log("miditra ato voalohany")
    }

  }, [props]);
  return (
    <div>
      <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="form-dialog-title" color="primary">{"Confirmation"}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
            <Grid container spacing={2} >
              <Grid item xs={12}>
                {messageDialog}
              </Grid >
              <Grid item xs={3}>
                Date début :
              </Grid >
              <Grid item xs={8}>
                <b>{datestart}</b>
              </Grid >
              <Grid item xs={3}>
                Date fin :
              </Grid >
              <Grid item xs={8}>
                <b>{dateend}</b>
              </Grid >
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>Info</AlertTitle>
                  Assurez-vous de bien saisir toute votre plannification entre ces deux date car une fois envoyé, vous ne pouvez pas ajouter d'autres
                </Alert>
              </Grid>
              {/* <Grid item xs={12}>
                Confirmez vous votre action?
              </Grid > */}
            </Grid>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={confirmDialog} >{isLoading ? <CircularProgress /> : "CONFIRMER"} </Button>
          <Button variant="contained" color="secondary" onClick={cancelDialog} >ANNULER</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
