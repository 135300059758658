import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Button ,TextField,FormControl, Select,MenuItem} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataSettings } from "../../../data/modules/settings-header";
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../../utils/privilege";
import { useNavigate } from 'react-router-dom';
import TimeTrackingService from "../../../services/TimeTrackingService";
import ExportExcel from "../../personnel/ExportExcel";
import TimeTrackingTable from "./TimeTrackingTable";
import ImmobilisationService from "../../../services/ImmobilisationService";
const useStyles = makeStyles((theme) => ({

    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        elevation: 0,
    },
}));

export default function TimeTracking(props) {

    const navigate = useNavigate();
    const contextData = useContext(userDetailContext);
    const [directions, setDirection] = useState([]);
    const today = new Date().toISOString().split('T')[0];
    const [filters, setFilters] = useState({
        direction_id: 1,
        date_debut: today,
        date_fin: today,
        nom: '',
        statut:'Tous'
      });
    
      //Changement valeur filtre
      const handleChanges = (e) => {

        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
      };
      const handleSubmit = (e) => {
        e.preventDefault();
        
        console.log(filters);
      };

    function handleClick() {
        const timeStampNow = (new Date()).toISOString().replace(/[^0-9]/g, '').slice(0, -3)
    }

    const classes = useStyles();
    const [dateNow, setDateNow] = useState("");
    const [listTimeTracking, setListTimeTrackingToExport] = useState([]);
    const [listTimeTrackingDisplay, setListTimeTrackingToDisplay] = useState([]);
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

    useEffect(() => {
        const timeStampNow = (new Date()).toISOString().replace(/[^0-9]/g, '').slice(0, -3);
        setDateNow(timeStampNow);
        setOpenLoadingSimpleBackdrop(true);
        TimeTrackingService.getAllTimeTracking(filters).then((result) => {
            if (result.status) {
                setListTimeTrackingToExport(result.data);
                setListTimeTrackingToDisplay(
                    result.data.map((row,index) => ({
                        ...row,
                        id: index,
                    }))
                );
                setOpenLoadingSimpleBackdrop(false);
            } else {
                setListTimeTrackingToExport([]);
                setListTimeTrackingToDisplay([]);
                setOpenLoadingSimpleBackdrop(false);
            }
        }).catch((err) => {
            setOpenLoadingSimpleBackdrop(false);
        });
    }, [props,filters]);
    useEffect(()=>{
        ImmobilisationService.direction().then((result) => {
            if (result && result.data) {
              setDirection(result.data);
            }
          });
    },[]);

    return (
        <MainContainer {...headerDataSettings}>
            <Grid container item xs={12}>
                <Spacer x={2} />
                <ExportExcel dataToExport={listTimeTracking} fileName={"pointage_"+dateNow} />

                <Spacer x={2} />
                    {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ fontWeight: 600}}
                    >
                        Filtrer
                    </Button> */}
              <Spacer x={2} />
            </Grid>
            {/* <Grid style={{ display: 'flex', flexDirection: 'column',marginTop:'20px' }}> */}
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid item xs={12} sm={6} md={4} style={{ marginTop: 'auto' }}>
                    <label className={classes.labelPosition}>
                    Direction:
                    </label>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="dir"
                            name="direction_id"
                            style={{ width: '300px'}}
                            defaultValue={filters.direction_id}
                            onChange={handleChanges}
                        >
                            {directions.map((row) => (
                            <MenuItem value={row.direction_id}>
                                {row.name}({row.code})
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} style={{ marginTop: 'auto' }}>
                    <label className={classes.labelPosition}>
                    Nom et prénom(s):
                    </label>
                    <Spacer y={0.2}></Spacer>
                    <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="nom"
                    onChange={handleChanges}
                    type="text"
                    style={{ width: '300px' }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} style={{ marginTop: 'auto' }}>
                    <label className={classes.labelPosition}>
                    Statut:
                    </label>
                    <Spacer y={0.2}></Spacer>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="statut"
                            name="statut"
                            defaultValue={filters.statut}
                            style={{ width: '300px'}}
                            onChange={handleChanges}
                        >

                            <MenuItem value="Tous">
                               Tous
                            </MenuItem>
                            <MenuItem value="Absent">
                               Absent
                            </MenuItem>
                            <MenuItem value="Présent">
                               Présent
                            </MenuItem>
                        </Select>
                        </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.labelPosition}>
                    Date début:
                    </label>
                    <Spacer y={0.2}></Spacer>
                    <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    defaultValue={filters.date_debut}
                    name="date_debut"
                    onChange={handleChanges}
                    type="date"
                    style={{ width: '300px' }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <label className={classes.labelPosition}>
                    Date Fin:
                    </label>
                    <Spacer y={0.2}></Spacer>
                    <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="date_fin"
                    defaultValue={filters.date_fin}
                    onChange={handleChanges}
                    type="date"
                    style={{ width: '300px' }}
                    />
                </Grid>

               
                </Grid>


            <Spacer y={2} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper} variant="outlined">
                        <TimeTrackingTable rows={listTimeTrackingDisplay} />
                    </Paper>
                </Grid>
            </Grid> 
        </MainContainer>
    );
}
