import { useEffect, useState } from "react";
import { useParams } from 'react-router';
import { Document, Page, pdfjs } from "react-pdf";
import FileService from "../../services/FileService";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DisplayFile(props) {
  const params = useParams();
    const path = params.path;
    const filename = params.filename;
    const folderName = params.path;
    const [pdf, setPdf] = useState("");
    const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    FileService.readFileContent(path, filename).then((result) => {
      if (result) {
        const blob = new Blob([result.data], {
          type: 'application/pdf',
        });
        // setPdf(blob);
        
        const fileURL = URL.createObjectURL(blob);
        const w = window.open(fileURL, '_blank');
        w && w.focus();
        URL.revokeObjectURL(fileURL);
      }
    });
  }, []);

  function onDocumentLoad() {
    FileService.getPdfNumberPage(folderName, filename).then((result) => {
      if(result.status){
        setPageNumber(result.data);
      }
    });
  }
    //
    const pages = [];

    for(var i=0; i<pageNumber; i++) {
      pages.push(
        <>
          <Page size="A4" pageNumber={i+1} /><br />
        </>
      );
    }
  return (
    <>
      <Document file={pdf} onLoadSuccess={onDocumentLoad} >
        {pages}
      </Document>
    </>
  );
}
export default DisplayFile;
