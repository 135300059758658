import MainContainer from "../../components/MainContainer";
import { headerMyDataImmo } from "../../data/modules/my-materials-header";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect, useContext } from "react";
import UserService from '../../services/UserService';
import { Grid, Paper } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import StatistiqueEtatTable from "./StatistiqueEtatTable";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  style1: {
    fontSize: "5em",
  },
}));
export default function StatistiqueEtatList() {
    const classes = useStyles();
    const handleChanges = (e) => {

      const { name, value } = e.target;
          localStorage.setItem(name,value);
      };
      const setValeurChamps = (name)=>{
        const valeurLocalStorage = localStorage.getItem(name);
        console.log("valeur champs sont : "+valeurLocalStorage)
        return valeurLocalStorage;
      }
      const SetFilter = (name,value)=>{
        if(!value){
          if(localStorage.getItem(name)){
            localStorage.setItem(name,localStorage.getItem(name));
          }
        }
        else{
  
          localStorage.setItem(name,value);
        }
        
      }
  useEffect(() => {
    SetFilter('dateState1',localStorage.getItem('dateState1'));
    SetFilter('dateState2',localStorage.getItem('dateState2'));
}, []);
const handleSubmit = (e) => {
  window.location.reload();

};
  return (
    <>
      <MainContainer {...headerMyDataImmo}>
        
        <Grid container item xs={12}>
        <Spacer x={2} />
                <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ fontWeight: 600} }
              >
                Filtrer
              </Button>
        </Grid>
        
        <Spacer y={2} />
        <Grid container xs={12} spacing={2} >

        <Grid item xs={12} sm={6} md={4} >
            <label className={classes.labelPosition}>
                  Date début:
                </label>
                <Spacer y={0.2}></Spacer>
                <TextField
                  labelId="demo-simple-select-label"
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  defaultValue={setValeurChamps('dateState1')}
                  name="dateState1"
                  type="date"
                  style={{ width: '300px'}}
                  onChange={handleChanges}
                />
                
          </Grid>
          <Spacer y={2} />
          <Grid item xs={12} sm={6} md={4} >
</Grid>
          <Spacer x={2} />
          <Grid item xs={12} sm={6} md={4} >
            <label className={classes.labelPosition}>
                  Date fin:
                </label>
                <Spacer y={0.2}></Spacer>
                <TextField
                  labelId="demo-simple-select-label"
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  defaultValue={setValeurChamps('dateState2')}
                  name="dateState2"
                  type="date"
                  style={{ width: '300px'}}
                  onChange={handleChanges}
                />
  <Spacer y={2} />
          </Grid>
        </Grid>
        

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Paper className={classes.paper} variant="outlined">
              <StatistiqueEtatTable />

            </Paper>
          </Grid>
        </Grid>
      </MainContainer>
    </>

  );
}
