//import React from 'react';
import { useEffect, useState } from "react";
import Title from "../../../components/Title";
import { escapeRegExp } from "../../../utils";
import { DataGrid } from "@material-ui/data-grid";
import Spacer from "../../../components/Spacer";
import Alert from "@material-ui/lab/Alert";
import { getYear } from "date-fns";

export default function LeaveBalanceSummaryTable(props) {
    const rows = props.rows;
    const initialBalance = props.initialBalance;
    const initialBalancedate = props.initialBalancedate;
    const initialBalancedateFormat = props.initialBalancedateFormat;
    const actualBalance = props.actualBalance;
    const [searchText, setSearchText] = useState("");

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = [...rows].filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
    };

    useEffect(()=>{
        console.log("month", getMonthName(1));
    },[props])
    const columns = [

        {
            field: 'real_balance',
            headerName: 'Cumul ',
            width: 150
        },
        {
            field: "observation",
            headerName: "Observation",
            width: 250,
            valueGetter: getObservation,
        },
        {
            field: 'begin_time',
            headerName: 'Date de départ en congé',
            width: 250,
            valueGetter: getBeginTimeLeave,
        },
        {
            field: "end_time",
            headerName: "Date de retour en congé",
            width: 250,
            valueGetter: getEndTimeLeave,
        },
        {
            field: "creation_datetime_format",
            headerName: "Date d'ajout au système",
            width: 250,
        }
    ];

    function getBeginTimeLeave(params) {
        return params.row.leave_id === null ? "-":params.row.begin_time;
    }

    function getEndTimeLeave(params) {
        return params.row.leave_id === null ? "-":params.row.end_time;
    }
    
    function getObservation(params) {
        if(params.row.isInitial === "1"){
            return "Solde Initial le "+params.row.creation_datetime_format;
        }
        if(params.row.real_balance < 0 && params.row.leave_id !== null){
            return "Cumul par rapport à une demande de congé";
        }
        if(params.row.real_balance > 0 && params.row.factor_per_month === params.row.creation_datetime_date && params.row.isInitial !== "1"){
            return "Droit de congé mois de "+getMonthName(params.row.creation_datetime_date);
        }
        if(params.row.real_balance < 0 && params.row.leave_id === null){
            return "Congé annuel "+getMonthName(params.row.creation_datetime_date)+" "+getYear(new Date(params.row.creation_datetime_date));
        }
    }

    
    function getMonthName(date) {
        const newDate = new Date(date);
        const monthList = ["Janvier","Février" ,"Mars", "Avril", "Mai", "Juin", "Juillet", "Août" ,"Septembre", "Octobre" ,"Novembre", "Décembre" ];
        var monthName = monthList[newDate.getMonth()];
        return monthName;
    }

    return (
        <>
            <Title>Récapitulatif solde de congé</Title>
            <span><u>Solde initial actif le {initialBalancedateFormat} </u>: <b>{initialBalance}</b> jour(s)</span>
            <Spacer y={1} />
            <span><u>Solde actuel </u>: <b>{actualBalance}</b> jour(s)</span>
            <Spacer y={2} />

            {rows.length > 0 &&
                <div style={{ height: "70vh", width: "100%" }}>
                    <DataGrid
                        columns={columns}
                        rows={rows}
                        pageSize={7}
                        rowsPerPageOptions={[7]}
                        disableSelectionOnClick
                        componentsProps={{
                            toolbar: {
                                value: searchText,
                                onChange: (event) => requestSearch(event.target.value),
                                clearSearch: () => requestSearch(""),
                            },
                        }}
                    />
                </div>
            }
            {rows.length == 0 &&

                <div style={{ height: "70vh", width: "100%" }}>
                    <span>
                        <Alert severity="warning">Aucune demande de congé n'a encore été confirmée</Alert>
                    </span>
                </div>
            }
        </>
    );
}
