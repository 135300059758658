import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Message from "../tools/Message";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Loading from '../../components/loading';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
import DirectionService from "../../services/DirectionService";
import FundingService from "../../services/FundingService";
import Spacer from "../../components/Spacer";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    gridLoading: {
        margin: "auto"
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function GenerateQrCode(props) {
    const openDialog = props.openDialog;
    const closeDialog = props.closeDialog;
    const handleChange = props.handleChange;
    const confirmDialog = props.confirmDialog;
    const cancelDialog = props.cancelDialog;
    const value = props.value;
    const isLoading = props.isLoading;
    const handleChangeDirection = props.handleChangeDirection;
    const handleChangeFinancement = props.handleChangeFinancement;
    const isShowMessage = props.isShowMessage;

    const [message, setMessage] = React.useState({
        open: false,
        color: 'green',
        messageInfo: '',
    });
    const { open, color, messageInfo } = message;
    const classes = useStyles();

    const [dir, setDir] = useState([]);
    const direction_id = props.direction_id;
    const financement_id = props.financement_id;
    const [financement, setFinancement] = useState([]);
    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }

    useEffect(() => {
        DirectionService.getDirection().then((res) => {
            if (res && res.status) {
                setDir(res.data);
            }
        });
        FundingService.getFunding().then((result) => {
            if (result && result.status) {
                setFinancement(result.data);
                console.log(result.data);
            }
        });
    }, []);
    return (
        <div>
            <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            <Dialog open={openDialog} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm">
                <DialogTitle id="form-dialog-title" color="primary" /*style={{backgroundColor: '#0000B3'}}*/ >{"Générer QrCode"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container>
                            <Grid item xs={12}>
                                <FormLabel component="legend">Pour quelle type d'immo?</FormLabel>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={4}>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="gender"
                                            name="controlled-radio-buttons-group"
                                            value={value}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="0" control={<Radio />} label="Immo" />
                                            <FormControlLabel value="1" control={<Radio />} label="Extrat" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={8} spacing={1}>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <InputLabel id="demo-simple-select-label">Direction</InputLabel>
                                            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Direction" value={direction_id} name="direction" onChange={handleChangeDirection} >

                                                <MenuItem value={0}>{"Toutes DIR"}</MenuItem>
                                                {dir.map((row) => (
                                                    <MenuItem value={row.direction_id}>{row.code}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <InputLabel id="demo-simple-select-label">Financement</InputLabel>
                                            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="financement" value={financement_id} name="financement" onChange={handleChangeFinancement} >

                                                <MenuItem value={0}>{"Tous financements"}</MenuItem>
                                                {financement.map((row) => (
                                                    <MenuItem value={row.funding_id}>{row.code}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {isShowMessage &&
                                        <Grid item xs={12}>
                                            <p style={{color: "orange"}}>Aucune donnée trouvée pour ce filtre</p>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {isLoading && <Grid xs={7} className={classes.gridLoading}>
                        <p>Veuillez patienter, ceci peut prendre beaucoup de temps</p>
                        <Loading />
                    </Grid>}
                    {!isLoading &&
                        <>
                            <Button variant="contained" color="primary" onClick={confirmDialog} >Confirmer</Button>
                            <Button variant="contained" color="secondary" onClick={cancelDialog} >Annuler</Button>
                        </>
                    }
                </DialogActions>
            </Dialog>

            {/* <ExportExcel dataToExport={dataToExport} fileName={fileName} /> */}
        </div >
    );
}

