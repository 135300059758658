import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const ImmobilisationService = {
  async getAll() {
    try {
      const result = await axios.get(`${apiUrl}/codeImmo/getAll`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getList() {
    try {
      const result = await axios.get(`${apiUrl}/codeImmo/list`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getAllCodeImmo(page,
    name,
    code,
    date1,
    date2,
    funding,
    dir) {
    try {
      const result = await axios.get(`${apiUrl}/codeImmo/${page}`,{params: {name:name, code:code,date1:date1,date2:date2,
        funding:funding, dir:dir}});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getFilter(page,
    name,
    code,
    date1,
    date2,
    funding,
    dir
    ) {
    try {
      const result = await axios.get(`${apiUrl}/codeImmo/filter/${page}`,{params: {name:name, code:code,date1:date1,date2:date2,
      funding:funding, dir:dir}} );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getFilterExtrat(page,
    name,
    code,
    date1,
    date2,
    funding,
    dir) {
    try {
      const result = await axios.get(`${apiUrl}/filterExtrat_get/${page}`,{params: {name:name, code:code,date1:date1,date2:date2,
        funding:funding, dir:dir}} );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getEquipmentRanking(name) {
    try {
      const result = await axios.get(`${apiUrl}/codeImmo/ranking/${name}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getAllSelectedCodeImmo(allImmoSelected) {
    try {
      const result = await axios.get(`${apiUrl}/codeImmo/immoSelected`, {
        params: {
          allImmoSelected:allImmoSelected
        },
      });

      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async generateQrCode(valueType, direction, financement) {
    try {
      const result = await axios.get(`${apiUrl}/codeImmo/getAll`, {
        params: {
          valueType: valueType,
          direction_id: direction,
          financement_id: financement,
        },
      });
      if (result) {
        if (result.status == 200) {
          return result.data;
        } else if (result.status == 206) {
          return result;
        } else {
          return result.data;
        }
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async direction() {
    try {
      const result = await axios.get(`${apiUrl}/codeImmo/direction`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async funding() {
    try {
      const result = await axios.get(`${apiUrl}/codeImmo/fincancement`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async ordre(id) {
    try {
      const result = await axios.get(`${apiUrl}/codeImmo/ordre/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async idmax() {
    try {
      const result = await axios.get(`${apiUrl}/codeImmo/idmax`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async save(
    equipment_ranking_id,
    name,
    code,
    acquis,
    unite,
    date,
    finance,
    dir,
    ordre,
    quantity,
    isExtra
  ) {
    try {
      const result = await axios.post(`${apiUrl}/codeImmo`, {
        equipment_ranking_id,
        name,
        code,
        acquis,
        unite,
        date,
        finance,
        dir,
        ordre,
        quantity,
        isExtra,
      });
      if (result) {
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async readFile(filename, valueType, type) {
    try {
      const result = type== 0 ? await axios.get(
        `${apiUrl}/CodeImmo/readFile/${filename}/${valueType}/${type}`,
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      ) : await axios.get(
        `${apiUrl}/CodeImmo/readFile/${filename}/${valueType}/${type}`,
        {
          responseType: 'arraybuffer',
          headers: {
            "Content-Type": "multipart/form-data"
          },
        }
      );
      if (result) {
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getCodeImmoById(id) {
    try {
      const result = await axios.get(`${apiUrl}/CodeImmo/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async deleteCodeImmo(id) {
    try {
      const result = await axios.delete(`${apiUrl}/CodeImmo/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data);
      }
      throw error;
    }
  },
  async update(equipment_ranking_id, name, code, acquis, unite, date, finance, dir, ordre, id) {
    try {
      const result = await axios.put(`${apiUrl}/codeImmo/id/${id}`, {
        equipment_ranking_id,
        name,
        code,
        acquis,
        unite,
        date,
        finance,
        dir,
        ordre,
        id
      });
      if (result) {
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async importFile(formData) {
    try {
      const result = await axios.post(
        `${apiUrl}/CodeImmo/do_import/`,
        formData
      );
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async importFileUpdate(formData) {
    try {
      const result = await axios.post(
        `${apiUrl}/CodeImmo/do_import_update/`,
        formData
      );
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getAllCodeImmoExtra(page,
    name,
    code,
    date1,
    date2,
    funding,
    dir) {
    try {
      const result = await axios.get(`${apiUrl}/codeImmo/extra/${page}`,{params: {name:name, code:code,date1:date1,date2:date2,
        funding:funding, dir:dir}});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getTotalCountOfImmo(type,name,code,date1,date2,funding,dir) {
    try {
      const result = await axios.get(
        `${apiUrl}/codeImmo/getTotalCountOfImmo/${type}`,{params: {name:name, code:code,date1:date1,date2:date2,
          funding:funding, dir:dir}}
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getCategoryWithRanking(options) {
    try {
      const result = await axios.get(
        `${apiUrl}/equipmentCategory/withRanking`,
        { params: options }
      );
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getEquipment(options) {
    try {
      const result = await axios.get(`${apiUrl}/equipment/`, {
        params: options,
      });
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async exportExcel(direction, funding) {
    try {
      const result = await axios.get(
        `${apiUrl}/codeImmo/export/${direction}/${funding}`
      );
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async exportExcelExtrat(direction, funding) {
    try {
      const result = await axios.get(
        `${apiUrl}/codeImmo/exportExtrat/${direction}/${funding}`
      );
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async exportExcelImmoAndExtrat(direction, funding) {
    try {
      const result = await axios.get(
        `${apiUrl}/codeImmo/exportImmoAndExtrat/${direction}/${funding}`
      );
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getAllCodeImmoByDirection(type, direction) {
    try {
      const result = await axios.get(
        `${apiUrl}/codeImmo/getImmoByDirection/${type}/${direction}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  
  async getListDevise() {
    try {
      const result = await axios.get(
        `${apiUrl}/codeImmo/getListDevise`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
};

export default ImmobilisationService;
