import React, { useState, useEffect } from "react";

// import ListLeavesInDayService from "../../services/ListLeavesInDayService";
// import ListLeaveCardPortal from "./ListLeaveCardPortal";
import {
    Grid,
    Paper,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField
} from "@material-ui/core";
import UserService from "../../../services/UserService";
import Message from "../../tools/Message";
import UserSpecialValidatorService from "../../../services/UserSpecialValidatorService";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import DayNotComptedPerPersonAdd from "./DayNotComptedPerPersonAdd";
import DayNotComptedPerPersonList from "./DayNotComptedPerPersonList";
import AnnualCalendarService from "../../../services/AnnualCalendarService";
import UserAnnualCalendarService from "../../../services/UserAnnualCalendarService";

function DayNotComptedPerPerson(props) {
    const handleChangeNumberOfValidator = props.handleChangeNumberOfValidator;
    // const handleChangeNumberOfValidatorMission = props.handleChangeNumberOfValidatorMission;
    // const validatorNumber = props.validatorNumber;
    // const validatorNumber = props.validatorNumber;
    const userId = props.userId;
    const userName = props.userName;
    const userDirection = props.userDirection;
    const [validatorNumber, setvalidatorNumber] = useState(0);
    const [openSpecialSettingDialog, setOpenSpecialSettingDialog] = React.useState(false);
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
    const [showSuperiorConfig, setShowSuperiorConfig] = React.useState(false);
    const [superiorConfigInput, setSuperiorConfigInput] = React.useState([]);
    const [idValidatorNumber, setIdValidatorNumber] = useState(0);
    const [param, setParam] = React.useState([]);
    const [idParam, setIdParam] = React.useState(0);
    const [listidsup, setlistidsup] = React.useState([]);
    const [supListSpecial, setSupListSpecial] = React.useState([]);
    const [validatorNotMatch, setValidatornotmatch] = React.useState(false);
    const setId = props.id;
    const handleClose = props.handleCloseDialog;

    const [isAdd, setIsAdd] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [rows, setRows] = useState([]);
    const [idModule, setIdModule] = React.useState(0);
    const [annualCalendarRow, setAnnualCalendarRow] = React.useState([]);
    const [annualCalendarId, setIdAnnualCalendarConfig] = React.useState(0);
    const [form, setForm] = useState({
        module_type_id: "",
        libelle_module_type: "",
    });
    const [select, setSelect] = useState({
        value: "first",
        label: "first"
    });
    const [message, setMessage] = React.useState({
        open: false,
        color: 'green',
        messageInfo: '',
    });
    const { open, color, messageInfo } = message;
    function loadConfigAnnualCalendar() {

        setOpenLoadingSimpleBackdrop(true);
        UserAnnualCalendarService.getConfigAnnualCalendar(userId).then((result) => {
            if (result.status) {
                setAnnualCalendarRow(result.data.map((row) => ({ ...row, id: row.user_annual_calendar_id })));
                setOpenLoadingSimpleBackdrop(false);
                setIsAdd(false);
                setIsEdit(false);
            } else {
                setAnnualCalendarRow([]);
                setOpenLoadingSimpleBackdrop(false);
                setIsAdd(false);
                setIsEdit(false);
            }
        });
    }

    useEffect(() => {

        AnnualCalendarService.getAnnualCalendar().then((result) => {
            if (result && result.data) {
                setParam(result.data);
                setIdParam(result.data[0].annual_calendar_id);
            } else {
            }
        });

        loadConfigAnnualCalendar();

    }, [props]);


    useEffect(() => {
        if (isEdit) {
            UserAnnualCalendarService.getConfigAnnualCalendar(userId).then((result) => {
                if (result && result.status) {
                    setIdParam(result.data[0].user_annual_calendar_calendarId);
                    setIdAnnualCalendarConfig(result.data[0].user_annual_calendar_id)
                }
            });
        }
    }, [isEdit]);

    function handleValidate() {
        UserAnnualCalendarService.saveUserAnnualCalendar(userId, idParam, annualCalendarId).then((result) => {
            if (result && result.status) {
                setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
                setIsAdd(false);
                setIsEdit(false);
                loadConfigAnnualCalendar();
            } else {
                setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });

            }
        });
    }


    function handleChangeNumberOfValidatorMission(ev) {
        const { name, value } = ev.target;
        const newValue = value.replace(/[^0-9]/g, '');
        setvalidatorNumber(newValue);
    }


    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }

    const handleEditAnnualCalendar = (id) => (ev) => {
        setIsEdit(true);
    };

    const handleDeleteAnnualCalendar = (id) => (ev) => {
        // console.log("id param**", id);
        UserAnnualCalendarService.deleteUserAnnualCalendar(id).then((result) => {
            if (result && result.status) {
                setMessage({ ...message, open: true, color: 'green', messageInfo: "Suppression avec succès" });  
                // setIsAdd(false);
                // setIsEdit(false);
                loadConfigAnnualCalendar();
            }
        });
    };
    function handleClickCreate() {
        setIsAdd(true);
    }


    function handleChangeParam(id) {
        setIdParam(id);
    }

    return (
        <>
            <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />

            <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            {(!isAdd && !isEdit) &&
                <Grid container spacing={2}>
                    {annualCalendarRow.length == 0 &&
                        < Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleClickCreate}
                                style={{ fontWeight: 600 }}
                            >
                                CREER
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <DayNotComptedPerPersonList rows={annualCalendarRow} handleEditAnnualCalendar={handleEditAnnualCalendar}
                            handleDeleteAnnualCalendar={handleDeleteAnnualCalendar} />
                    </Grid>
                </Grid>
            }
            {(isAdd || isEdit) &&
                <DayNotComptedPerPersonAdd userName={userName} annualCalendarId={annualCalendarId}
                    showSuperiorConfig={showSuperiorConfig} superiorConfigInput={superiorConfigInput}
                    param={param} idParam={idParam} validatorNotMatch={validatorNotMatch} isEdit={isEdit}
                    handleChangeNumberOfValidatorMission={handleChangeNumberOfValidatorMission}
                    handleValidate={handleValidate} handleClose={handleClose} handleChangeParam={handleChangeParam} />
            }
        </>
    );
}
export default DayNotComptedPerPerson;