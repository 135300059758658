import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  Box,
  Collapse,
  IconButton,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Dialog,
  DialogContent,
  Alert,
  Avatar,
  Stack,
  LinearProgress,
  CircularProgress,
  ListItemIcon,
} from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem, InputLabel} from "@material-ui/core";
import { extendMoment } from "moment-range";
import { useNavigate,useParams } from "react-router-dom";
import { userDetailContext } from "../../App";
import Moment from "moment";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import MettingService from "../../services/MettingService";
import MeetingRoomService from "../../services/MeetingRoomService";
import { format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import Message from "../tools/Message";
import MailConfigAddService from "../../services/MailConfigAddService";


// import React, { useState, useEffect } from "react";
import { Menu} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from 'react-router-dom';
import MailListPersonService from "../../services/MailListPersonService";
import { CheckBox, CloudUpload, Delete, ExpandLess, ExpandMore, FormatBold, FormatStrikethroughOutlined, FormatUnderlined, Label, LabelImportantOutlined } from "@material-ui/icons";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import MailBoxService from "../../services/MailBoxService";
import { headerMail } from "../../data/modules/mail-header";
import MailDialogCheking from "./MailDialogcheking";
import CommentBox from "./CommentBox";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 



const MailBox = () => {

const location = useLocation();

const [list, setList] = useState([]);

const [subject, setSubject] = useState('');
const [message, setMessage] = useState('');

const [idfunction , setIdfunction] = useState([]);
const [idLastmail , setIdLastmail] = useState('');
const [thisUser , setThisUser] = useState('');

const [isOpen, setIsOpen] = useState(false);

const navigate = useNavigate();

const selectedValueFonctionIdList =  location.state?.selectedValueFonctionIdList;

const [checkTrueFalse, setCheckTrueFalse] = useState('');
// const [checkTrueFalse, setCheckTrueFalse] = useState("info");

const [selectedFiles, setSelectedFiles] = useState([]);


////////////////TESTTTTT
const usersss = [
  { user_id: 1, name: 'John Doe', personnal_email: 'manjakarasolomanana@gmail.com' },
  { user_id: 2, name: 'Jane Smith', personnal_email: 'manjakarasolomanana1@gmail.com' },
  { user_id: 3, name: 'Alice Johnson', personnal_email: 'manjakaraphael@gmail.com' },
];

const [user, setUser] = useState([]);




///////////////////////


useEffect(() => {
  setList(location.state?.selectedUsers || []);
}, [location.state?.selectedUsers]);

useEffect(() => {
  const emails = usersss.map(user => user.personnal_email);
  // const emails = list.map(user => list.personnal_email);      //REEL

  setUser(emails);
}, [usersss]);
// }, [list]);


useEffect(() => {
  setIdfunction(list);
}, [list]); 


useEffect(() => {
  const fetchData = async () => {
    try {
      const idl = localStorage.getItem("USER");
      setThisUser(idl);
      console.log('THIS USER  :  ' , idl);
      // console.log('LAST ID SET:  ' , idLastmail);

    } catch (error) {
      console.error("Failed to fetch data fa tena misy tsy mety:", error);
    }
  };

  fetchData();
}, []);


const handlesubject = (event) => {
    setSubject(event.target.value);

};

const handlemessage = (event) => {
  // setMessage(event.target.value);
  setMessage(event);


};

const handleReturn = (event) => {
  const selectedValueDirectionIdList =  location.state?.selectedValueDirectionIdList;
  const selectedValueServiceIdList =  location.state?.selectedValueServiceIdList;
  const selectedValueFonctionIdList =  location.state?.selectedValueFonctionIdList;
    navigate("/maillist" , { state: { selectedValueDirectionIdList , selectedValueServiceIdList, selectedValueFonctionIdList } });
  };
  
const [messagepop, setMessagepop] = React.useState({
  open: false,
  color: "green",
  messageInfo: "",
});
const { open, color, messageInfo } = messagepop;


const [listCheckChange, setListCheckChange] = useState([]);

//////////////// FILE //////
// const handleFileSelect = (event) => {
//   console.log("LOG EVENT : ", event.target.files);
//   setSelectedFiles(Array.from(event.target.files));

//   console.log("Taille : ", selectedFiles.map(s=> s.size));

// };

const handleFileSelect = useCallback((event) => {
  console.log("LOG EVENT : ", event.target.files);
  setSelectedFiles(Array.from(event.target.files));
}, []);

useEffect(() => {
  const input = document.querySelector('input[type="file"]');
  if (input) {
    input.addEventListener('change', handleFileSelect);
  }

  return () => {
    if (input) {
      input.removeEventListener('change', handleFileSelect);
    }
  };
}, [handleFileSelect]);

const removeFile = (fileName) => {
  setSelectedFiles(selectedFiles.filter(file => file.name !== fileName));
};

////////////////////////////////////////////////////////////////////////////////////////////
const [progress, setProgress] = useState(0);
const [loading, setLoading] = useState(true);
const [openprog, setOpenprog] = useState(false);
// const [loading, setLoading] = useState('');

const [showMessagePop, setShowMessagePop] = useState(false);


const [resultat, setResultat] = useState('');

const calculateTotalSize = () => {
  let total = 0;
  selectedFiles.forEach(file => {
    total += file.size;
  });
  return total;
};

  const handleNextt = async (event, scrollType) => {

    setLoading(true);
    setProgress(0);

    console.log("SELECTED FILES : ", selectedFiles);

    const dixMo = 10 * 1024 * 1024;
    const sizefiles = calculateTotalSize();
    console.log("10 Mo : ", dixMo);
    console.log("SIZE FILES : ", calculateTotalSize());

    
  const formData = new FormData();

  if (Array.isArray(selectedFiles)) {
    selectedFiles.forEach((file, index) => {
      formData.append(`file${index}`, file);
      console.log('File added to formData:', formData);
    });
  } else if (selectedFiles instanceof File) {
    formData.append("file", selectedFiles);
  } else {
    return;
  }

  console.log("FORM DATA : ", formData);

  console.log("IREO OLONA : ", list);




    MailBoxService.importPj(formData)
    .then(result => {

      if(sizefiles >= dixMo){
        setMessagepop({ ...messagepop, open: true, color: "red", messageInfo: "Fichier trop lourd ! Veuillez contacter le service SI" });
        console.log("Fichier Mavesatra !!!!");

        const errror = "error";

        for (let index = 0; index < list.length; index++) {
        
          listCheckChange.push(errror);
          setProgress((prevProgress) => Math.min(prevProgress + 25, 100));
        }

        setTimeout(() => {
          setOpend(true);
          setScroll(scrollType);
          setLoading(false);
        }, 5000);

        

      }else if(result != true){
        setMessagepop({ ...messagepop, open: true, color: "red", messageInfo: "Une erreur s'est produite lors de la selection de fichier" });
        console.log("Tena tsy lasa ty eee !!!!");

        const errror = "error";

        for (let index = 0; index < list.length; index++) {
        
          listCheckChange.push(errror);
          setProgress((prevProgress) => Math.min(prevProgress + 25, 100));
        }

        setTimeout(() => {
          setOpend(true);
          setScroll(scrollType);
          setLoading(false);
        }, 5000);

      }else{
        setTimeout(() => {
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
        // for (let index = 0; index < usersss.length; index++) {
        //   const element = usersss[index];
          console.log("sujet :", subject);
          console.log("message :", message);
          console.log("element :", element);

          const filenames = selectedFiles.map(file=>file.name);
          console.log("Filenames : ", filenames);
          MailBoxService.send(subject, message, element.personnal_email,filenames)
          .then(result => {
              if (!result.success) {
                console.log("False : ", result.success);

                setCheckTrueFalse("error");
                console.log("Tsirairay Error : ", checkTrueFalse);
                
              }else{
                console.log("True : ", result.success);

                setCheckTrueFalse("success");
                console.log("Tsirairay Success: ", checkTrueFalse);

              }

              listCheckChange.push(result.check);
              console.log("CHecking : " ,listCheckChange);

              setProgress((prevProgress) => Math.min(prevProgress + 25, 100));
          })

        }
      }, 3000);


            setTimeout(() => {
              setOpend(true);
              setScroll(scrollType);
              setLoading(false);
            }, 5000);

            console.log("RESULTAT : ", resultat);

            //////////////////////////////
            const errorIndices = listCheckChange.reduce((acc, _, idx) => {
              if (_ === 'error') acc.push(idx);
              return acc;
            }, []);

            console.log("Error indice : ", errorIndices);

            const newlist = list.filter((_, idx) => errorIndices.includes(idx))

            console.log("New LIST : ", newlist);

            ////////////////////////////////

          MailBoxService.insert(subject, message)

          .then(() => {
            console.log("Last ID verif : ",MailBoxService.getLastMailId() );
              return MailBoxService.getLastMailId();
          })
          .then(idLastmail => {

            MailBoxService.insert_pj(idLastmail.data.last, selectedFiles.map(file => file.name))
            .then(()=>{
              
            })
            return MailBoxService.insert_details(idfunction.map(n => n.function_id), idLastmail.data.last, thisUser, list.map(item => item.user_id));
          
          })
      }
    })


  
  }


  function handleCloseMessage() {
    setMessagepop({ ...messagepop, openMessage: false });
  }

  //////////DIALOG////
  
  const [opend, setOpend] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const apiUrl = process.env.REACT_APP_API_URL_Image;



  const imageUrl = (name)=>{
    const imageUrls = `${apiUrl}${name}`;
    return imageUrls;
  };

  const handleClose = () => {
    setOpend(false);
    navigate("/mailsend");
  };

  const renderAlerts = () => {
    const alertsArray = [];
    for (let i = 0; i < list.length; i++) {
      // for (let i = 0; i < usersss.length; i++) {

      
      alertsArray.push(
        <Stack direction="row" spacing={2}>
  
          <Avatar alt={imageUrl("default")} src={imageUrl(list[i].name_image)} />
          {/* <Avatar alt={imageUrl("default")} src={imageUrl("images.jpg")} /> */}
          <Alert 
            severity={listCheckChange[i]} 
            key={list[i].user_id} 
            // key={usersss[i].user_id}
            sx={{ width: '100%', maxWidth: '800px' }}
          >
            {list[i].personnal_email}
            {/* {usersss[i].personnal_email} */}
          </Alert>
        </Stack>
      );
    }
  
    return alertsArray;
  };

  const allmail = list.map(l => l.email).join(', ');

  //////////////////////////////////////////////// MESSAGE

  // const modules = {
  //   toolbar: [
  //     [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
  //     [{size: []}],
  //     ['bold', 'italic', 'underline', 'strike'],
  //     [{'list': 'ordered'}, {'list': 'bullet'},{'indent': '-1'}, {'indent': '+1'}],
  //     ['link', 'image'],
  //     ['clean']                                         
  //   ],
  // };
  

  return (
    <MainContainer {...headerMail}>
    <React.Fragment>
      <Dialog
        open={opend}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title" style={{textAlign : "center"}}>Rapport d'envoie</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>    
        {renderAlerts()}

       

        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

      {/* //// */}
        <Message open={messagepop.open} color={messagepop.color} messageInfo={messagepop.messageInfo} handleClose={handleCloseMessage} />
        <Container sx={{ width: '900px' }}>
            <Box component="form" mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom style={{textAlign : "center"}}>
                        Envoyer votre mail
                        </Typography>
                        <input type="hidden" value={idfunction} name="idfunction"></input>
                        <input type="hidden" value={idLastmail} name="idLastmail"></input>
                        <input type="hidden" value={thisUser} name="thisUser"></input>
                        <input type="hidden" value={list} name="list"></input>
                    </Grid>
                    <Grid item xs={12}>
                      <label>Nombre total des déstinataires : <strong>{list.length}</strong></label>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <label>Envoyé à :</label>
                          <IconButton edge="start" onClick={() => setIsOpen(!isOpen)} style={{ alignSelf: "center" }}>
                            {isOpen ? <ExpandMore /> : <ExpandLess />}
                          </IconButton>
                        </div>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                          <Grid container>
                            <Grid item xs={12}>
                              {/* {list.map((item, index) => (
                                <p key={index}>{item.email}, </p>
                              ))} */}
                              {allmail}
                            </Grid>
                          </Grid>
                        </Collapse>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        placeholder="Objet..."
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="subject"
                        onChange={handlesubject}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <TextField
                        placeholder="Message..."
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="message"
                        onChange={handlemessage}
                        multiline
                        minRows={3}
                        /> */}

                    <CommentBox class_name='form_control comment_box' value={message} setValue={handlemessage} />
                    
                    {/* <ReactQuill value={message} onChange={handlemessage} modules={modules} /> */}


                    </Grid>
                    <Grid item xs={12}>
                      <div style={{display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      border: '2px dashed #d3d3d3',
                      padding: 20,
                      marginBottom: 20,}} 
                      >
                        <CloudUpload fontSize="large" />
                        <Typography variant="body1">Parcourir des fichiers</Typography>
                        <Button variant="contained" component="label">
                          Parcourir
                          <input type="file" name="selectedFiles" multiple hidden onClick={handleFileSelect}/>
                        </Button>
                      </div>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                      }} 
                      >
                      {selectedFiles.map((file, index) => (
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: 10,
                          border: '1px solid #d3d3d3',
                          borderRadius: 5,
                          }}  
                          key={index}
                        >
                          <Typography variant="body2">{file.name}</Typography>
                          <IconButton size="small" onClick={() => removeFile(file.name)}>
                            <Delete />
                          </IconButton>
                        </div>
                      ))}
                    </div>
                    </Grid>
                    <Box mt={2} display="flex" justifyContent="space-between" width="100%">
                        <Button onClick={handleReturn} style={{ minWidth: '50px', maxWidth: '150px', marginRight: '10px' }}>
                          PRECEDENT
                        </Button>
                        {/* <Button onClick={handleClickOpend('paper')} style={{ minWidth: '50px', maxWidth: '150px', marginRight: '10px' }}>
                          Test
                        </Button> */}
                        <Button variant="contained" color="primary" onClick={handleNextt} style={{ minWidth: '50px', maxWidth: '150px' }}>
                          ENVOYER
                        </Button>

{/* <Button 
  variant="contained" 
  color="primary" 
  onClick={handleNextt} 
  style={{ minWidth: '50px', maxWidth: '150px' }}
  disabled={!loading}
>
  {!loading ? 'REUSSI...' : 'ENVOYER'}
  {loading && (
  <CircularProgress 
    color="inherit" 
    size={25} 
    thickness={4} 
    variant="determinate" 
    value={progress} 
    sx={{ ml: 2 }}
  />
  )}
</Button> */}


                    </Box>
                </Grid>

            </Box>
        </Container>
    </MainContainer>
  );

};

export default MailBox;


