import React, { useContext } from "react";
import MainContainer from "../../../components/MainContainer";
import { headerDataLibrary } from "../../../data/modules/rubrique_header";
import EmplacementAdd from "./EmplacementAdd";
import { Button, Grid, Paper, makeStyles } from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import { useNavigate } from "react-router";

import { hasPrivilege } from "../../../utils/privilege";
import { userDetailContext } from "../../../App";
import EmplacementTable from "./EmplacementTable";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function EmplacementList() {
  const navigate = useNavigate();

  const classes = useStyles();

  const contextData = useContext(userDetailContext);

  function handleClickCreer() {
    navigate("/emplacement/add/");
  }

  return (
    <MainContainer {...headerDataLibrary}>
      <Spacer x={2} />

      <Grid>
        {hasPrivilege(
          contextData.privileges,
          "button_create_document_location"
        ) && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickCreer}
            style={{ fontWeight: 600 }}
          >
            CREER
          </Button>
        )}

        <Spacer x={2} />
      </Grid>

      <Spacer y={2} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <EmplacementTable />
      
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
