import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spacer from "../../components/Spacer";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import RefusedRequestService from "../../services/RefusedRequestService";
import Message from "./Message";
import Alert from "@material-ui/lab/Alert";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  step1Title: {
    textAlign: "center",
  },
  step1Grid: {
    padding: "10px",
  },
  gridItem: {
    height: "25px",
  },
}));

export default function RefusedRequest(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const type_observation = props.type_observation;
  const file_validation_request_id = props.file_validation_request_id;
  const validation_id = props.validation_id;
  const open = props.openRefusedRequest;
  const handleCloseRefusedRequest = props.handleCloseRefusedRequest;
  const formular = props.formular;
  const module_type_id = props.module_type_id;
  const handleCloseRefusedDialog = props.handleCloseRefusedDialog;

  const [error, setError] = useState('');
  const [message, setMessage] = useState({
    openMessage: false,
    color: "green",
    messageInfo: "",
  });
  const { openMessage, color, messageInfo } = message;

  const [form, setForm] = useState({
    validation_id : validation_id,
    observation : '',
    observation_detailed : []
  });

  for(var i=0; i<formular.length; i++){
    if(form.observation_detailed.length < formular.length){
        form.observation_detailed.push(
          {
            field_label : formular[i].field_label,
            observation_type_detailed_id : formular[i].observation_type_detailed_id,
            file_validation_request_id : file_validation_request_id,
            observation : ''
          }
        );
    }
  }

  function handleChangeGlobalObservation(event){
    const {name, value} = event.target;
    setForm({ ...form, validation_id: validation_id, observation: value });
  }

  function handleChange(event, index){
    const { name, value } = event.target;
    event.preventDefault();
    let values = [...form.observation_detailed];
    values[index].observation = value;
    setForm({ ...form, values });
}

  function handleRefusedRequest(){
    RefusedRequestService.addObservation(module_type_id, validation_id, form, type_observation, file_validation_request_id).then((result) => {
        if (result.status) {
          setMessage({
            ...message,
            openMessage: true,
            color: "green",
            messageInfo: result.message,
          });
          handleCloseRefusedDialog();
          setTimeout(function () { navigate("/me/validations"); }, 2000);
          window.location.reload();
        } else {
          setError('Motif invalide');
        }
      }).catch((err) => {
        setError('Motif invalide');
      });
  }
  
  useEffect(() => {

  }, []);

  function handleCloseMessage() {
    setMessage({ ...message, openMessage: false });
  }

  return (
    <>
      <Message open={openMessage} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Dialog open={open} onClose={handleCloseRefusedRequest} aria-labelledby="form-dialog-title1" fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title1">
          Refus de demande de validation - Etape 1
        </DialogTitle>
        <br />
        <div className={classes.root}>
          <div>
            <h3 className={classes.step1Title}>Motif de refus</h3>
          </div>
            <Grid className={classes.step1Grid} container>
              <Grid className={classes.gridItem} item xs={4}>
                <label className={classes.label}>{"Observation globale : "}</label>
              </Grid>
              <Grid className={classes.gridItem} item xs={8}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    label="Observation globale"
                    variant="outlined"
                    value={form.observation}
                    name="observation"
                    onChange={handleChangeGlobalObservation}
                />
              </Grid><br /><br /><br />  
              {/*  */}
              {form.observation_detailed.map((items, index) => (
                <>
                  <Grid className={classes.gridItem} item xs={4}>
                    <label className={classes.label}>{items.field_label} {" : "}</label>
                  </Grid>
                  <Grid className={classes.gridItem} item xs={8}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        size="small"
                        label={items.field_label}
                        variant="outlined"
                        value={items.observation}
                        name="observation"
                        onChange={(event) => handleChange(event, index)}
                    />
                  </Grid>
                </>
              ))}
              {/*  */}
              <Grid item xs={4}></Grid>
              <Grid item xs={8}>
                <Spacer y={3} />
                {error !='' && (
                  <Alert severity="error">{error}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Spacer y={2} />
                <DialogActions>
                  <Button style={{ backgroundColor: "##f50057", color: "#FFFFFF" }} onClick={handleCloseRefusedRequest} color="secondary" variant="contained" >
                    Annuler
                  </Button>
                  <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF",}} onClick={handleRefusedRequest} color="secondary" variant="contained" >
                    Enregistrer
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
        </div>
      </Dialog>
    </>
  );
}
