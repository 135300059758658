import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import ValidationPlanningService from "../../services/ValidationPlanningService";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import Message from "../tools/Message";
import Switch from "@mui/material/Switch";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import { escapeRegExp } from "../../utils";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));
const label = { inputProps: { "aria-label": "Switch demo" } };

export default function ValidationSettingTable() {
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = useState([]);
  const [id_to_delete, setId_to_delete] = useState(0);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: "Voulez vous vraiment supprimer  ?",
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  function loadTable() {
    setOpenLoadingSimpleBackdrop(true);
    ValidationPlanningService.getValidationPlanning().then((result) => {
      if (result && result.data) {
        setRows(
          result.data.map((row) => ({
            ...row,
            id: row.id_planning_interval_parameter,
          }))
        );
        setOpenLoadingSimpleBackdrop(false);
      }else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }

  useEffect(() => {
    loadTable();
  }, []);

  const handleEdit = (id) => (ev) => {
    navigate("/validation_planning/add/" + id);
  };
  const handleDelete = (id) => (ev) => {
    setId_to_delete(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true, messageDialog: "Voulez vous vraiment supprimer?" });
  };
  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    ValidationPlanningService.deletePlanningService(id_to_delete)
      .then((result) => {
        if (result.status) {
          loadTable();
          setMessage({
            ...message,
            open: true,
            color: "green",
            messageInfo: result.message,
          });
        } else {
          setMessage({
            ...message,
            open: true,
            color: "red",
            messageInfo: result.message,
          });
        }
      })
      .catch((err) => {
        setMessage({
          ...message,
          open: true,
          color: "red",
          messageInfo: "erreur",
        });
      });
  }

  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = [...rows].filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
  };

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const column = [
    {
      field: "date_start",
      headerName: "Date de début",
      width: 200,
      valueFormatter: (params) => {
        return `${moment(params.row.date_start).format('DD/MM/YYYY')}`;
      },
    },
    {
      field: "date_end",
      headerName: "Date fin",
      width: 200,
      valueFormatter: (params) => {
        return `${moment(params.row.date_end).format('DD/MM/YYYY')}`;
      },
    },
    {
      field: "date_start_notification",
      headerName: "Date d'alerte",
      width: 200,
      valueFormatter: (params) => {
        return `${moment(params.row.date_start_notification).format('DD/MM/YYYY')}`;
      },
    },
    { field: "libelle_planning_interval_parameter", headerName: "Description", width: 200 },
    {
      field: "active",
      headerName: "Active",
      width: 200,
      renderCell: (params) => {

        const id = params.row.id_planning_interval_parameter;
        const handleSwitchChange = (id) => (event) => {
          ValidationPlanningService.saveValidationPlanningIs_active(id, event.target.checked).then((res) => {
            if (res && res.status) {
              loadTable();
              setMessage({
                ...message,
                open: true,
                color: "green",
                messageInfo: res.message,
              });
            } else {
              setMessage({
                ...message,
                open: true,
                color: "red",
                messageInfo: res.message,
              });
            }
          });
        };
        return (
          <Switch
            checked={params.row.is_active === "0" ? false : true}
            onChange={handleSwitchChange(params.row.id_planning_interval_parameter)}
            inputProps={{ "aria-label": "controlled" }}
            title="Activation"
          />
        );
      }
    },
    {
      field: "libelle_module_type",
      headerName: "Type",
      width: 200
    },
    {
      field: "Action",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <span>
          <Button onClick={handleEdit(params.row.id_planning_interval_parameter)} title="Modifier ce paramètre">
            <CreateTwoTone style={{ color: "black" }} />
          </Button>


          <Button onClick={handleDelete(params.row.id_planning_interval_parameter)} title="Supprimer ce paramètre">
            <DeleteOutlineRounded style={{ color: "red" }} />
          </Button>

        </span>
      ),
    },
  ];

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, sortBy, filters }) => {
      var newId = fetchIdRef.current++;
      const fetchId = newId;
      (async () => {
        setLoading(true);
        var options = { page: pageIndex + 1, perPage: pageSize };
        options.sort_by = sortBy.map(
          (e) => e.id + "." + (e.desc ? "desc" : "asc")
        );
        if (filters !== undefined) {
          filters.forEach((e) => (options[e.id + "[contains]"] = e.value));
        }
        const result = await ValidationPlanningService.getValidationPlanning();
        if (result && result.data) {
          setData(
            result.data.map((row) => ({
              ...row,
              ["id"]: row.id_planning_interval_parameter,
            }))
          );
          setRowCount(result.count);
          setPageCount(Math.ceil(result.count / pageSize));
        }
        setLoading(false);
      })();
    },
    []
  );

  return (
    <React.Fragment>
      
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}/>
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          columns={column}
          //components={{ Toolbar: CustomToolbar }}
          rows={rows}
          pageSize={7}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
        />
      </div>

      <ConfirmationDialog
        openDialog={openDialog}
        messageDialog={messageDialog}
        closeDialog={closeDialog}
        confirmDialog={confirmDialog}
        cancelDialog={cancelDialog}
      />
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
    </React.Fragment>
  );
}
