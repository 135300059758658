import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FamilyMemberService from "../../../services/FamilyMemberService";
import TextField from "@material-ui/core/TextField";
import { Button, MenuItem,
  FormControl,
  Select,
  InputLabel} from "@material-ui/core";
import Message from "../../tools/Message";
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition1: {
    float: 'left',
  },
  labelPosition: {
    float: 'right',
  },
  text: {
    width: 370,
  }
}));

export default function Child(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [form, setForm] = useState({user_id:localStorage.getItem("contract"), relationship_type_id: 7, value_personal_config:[]});
  const [personalConfig, setPersonalConfig] = useState([]);
  const [valuePersonalConfigTemp, setValuePersonalConfigTemp] = useState([]);
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  function handleCloseMessage(){
    setMessage({...message, open: false});
  }

  useEffect(() => {
    FamilyMemberService.getPersonalPersonalConfig().then((result) => {
      if(result && result.data){
        setPersonalConfig(result.data);
      }
    });
    
  }, []);

  function handleChangePersonalConfigValue(event){
    const { name, value } = event.target;
    valuePersonalConfigTemp[name] = {
      "personal_config_id":name,
      "value":value
    };
  }

  function handleClick(){
    for(var i=0; i<valuePersonalConfigTemp.length; i++){
      if(valuePersonalConfigTemp[i]!=null){
        form.value_personal_config.push(valuePersonalConfigTemp[i]);
      }
    }
    console.log("FORM DATA OTHER === ", form);
    FamilyMemberService.savePersonalConfigPersonal(form).then((result) => {
      if(result.status){
        setMessage({...message, open: true, color: 'green', messageInfo: result.message});
        navigate("/personnel");
      }else{
        setMessage({...message, open: true, color: 'red', messageInfo: result.message});
      }
    }).catch((err) => {
      setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
    });
  }
  // gestion planning
  const personalConfigItems = [];
  for(var i=0; i<personalConfig.length; i++){
    if(personalConfig[i].type_field === 'Choix'){
      personalConfigItems.push(
        <>
        <Grid item xs={4}>
          <label className={classes.labelPosition1}>{personalConfig[i].name_field}</label>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-outlined-label">{personalConfig[i].name_field}</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label={personalConfig[i].name_field} name={personalConfig[i].personal_config_id} onChange={handleChangePersonalConfigValue}>
              {personalConfig[i].choice.map((items) => (
                <MenuItem value={items.personal_config_choice_id}>{items.label_choice}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        </>
      );
    }else{
      personalConfigItems.push(
        <>
        <Grid item xs={4}>
            <label className={classes.labelPosition1}>{personalConfig[i].name_field}</label>
        </Grid>
        <Grid item xs={8}>
            <TextField fullWidth type="" id="outlined-basic" size="small" label={personalConfig[i].name_field} variant="outlined" name={personalConfig[i].personal_config_id} onChange={handleChangePersonalConfigValue} />
        </Grid>
        </>
      );
    }
  }
  
  return (
    <Grid container spacing={3} className={classes.paper} variant="outlined">
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
        {/*  */}
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
            <label className={classes.labelPosition1} style={{ textDecorationLine: 'underline' }} >Autres champs à remplir : </label>
        </Grid>
          {personalConfigItems}
        {/*  */}
        <Grid item xs={12}>
          <Button className={classes.labelPosition} variant="contained" color="primary" onClick={handleClick} >Enregistrer</Button>
        </Grid>
    </Grid>
  );
}
