import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const Acces_directionService = {
    async insert(direction,privilege) {
        try {
          const result = await axios.post(`${apiUrl}/Acces_Direction/index`,{
            privilege:privilege,
            direction:direction
          });
          if (result) {
            return result;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      },
      async getlist() {
        try {
            const result = await axios.get(`${apiUrl}/Acces_Direction/getlists`);
            if (result && result.data) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
      },
      async deleteacces(id) {
        try {
            const result = await axios.get(`${apiUrl}/Acces_Direction/delete/`+id);
            if (result && result.data) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
      },
}
export default Acces_directionService;