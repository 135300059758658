import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
export default function ExportExcel(props) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  function exportToCSV(dataToExport, fileName) {
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <Button
      variant="contained"
      onClick={(e) => exportToCSV(props.dataToExport, props.fileName)}
      style={{ fontWeight: 600 }}
      startIcon={<DownloadIcon />}
    >
      EXPORTER
    </Button>
  );
}
