
import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useGmailListItemStyles } from '@mui-treasury/styles/listItem/gmail';
import Inbox from '@material-ui/icons/Inbox';
import Star from '@material-ui/icons/Star';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  menu: {
    background: "#ff0000 !important",
  },

  grid: {
    background: "#F4F7F7",
  }
}));

const HelpComponentPopover = () => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [selectedItem1, setSelectedItem1] = React.useState(true);
  const [selectedItem2, setSelectedItem2] = React.useState(false);
  const selectedStyles = useGmailListItemStyles({
    color: '#606161',
    opacity: "0.54",
    collapsed,
  });
  const styles = useGmailListItemStyles({ collapsed });
  const classes = useStyles();

  function handleClickSelectedItem1(){
    setSelectedItem1(true);
    setSelectedItem2(false);
  }

  function handleClickSelectedItem2(){
    setSelectedItem2(true);
    setSelectedItem1(false);
  }
  return (
    <Box minWidth={500} bgcolor={'#fff'}>
      <Grid container>
        <Grid xs={4}>
          <List>
            <ListItem
              button
              selected={selectedItem1}
              classes={selectedStyles}
              // classes={{ selected: selectedStyles }}
              onClick={handleClickSelectedItem1}
            >
              <Inbox />
            Présentation
          </ListItem>
            <ListItem
              button
              selected={selectedItem2}
              classes={selectedStyles}
              onClick={handleClickSelectedItem2}
            >
              <Star />
            A propos
          </ListItem>
          </List>
        </Grid>
        <Grid xs={8}>
          <div className={classes.grid}>sdsdsdsdd</div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HelpComponentPopover;
