import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from '@material-ui/data-grid';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import HistoryMettingService from "../../services/HistoryMettingService";
import { hasPrivilege } from "../../utils/privilege";
import { useContext } from "react";
import { userDetailContext } from "../../App";
import { IconButton, Menu, MenuItem } from '@mui/material';
import PvPopup from './PvPopup';
import NoteAddOutlined from '@mui/icons-material/NoteAddOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Message from "../tools/Message";
// import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function MettingFinishTable(props) {
  const contextData = useContext(userDetailContext);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const [isid, setIsid] = useState(0);
  const [message, setMessage] = React.useState({
    openMessage: false,
    color: 'green',
    messageInfo: '',
  });
  const { openMessage, color, messageInfo } = message;

  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

    useEffect(() => {
        setOpenLoadingSimpleBackdrop(true);
        HistoryMettingService.getMettingFinish().then((result) => {
        if (result && result.data) {
            setRows(result.data.map((row) => ({ ...row, id: row.id_meeting })));
            setOpenLoadingSimpleBackdrop(false);
        }
        else{
            setOpenLoadingSimpleBackdrop(false);
        }
        });
    }, []);

    function handleCloseMessage() {
      setMessage({ ...message, openMessage: false });
    }


  const [open, setOpen] = useState(false);

  const handleOpen = (id) => {
    setIsid(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = async (id,date_meeting,user_meeting) => {
    try {
        const zipData = await HistoryMettingService.export(id);

        // URL pour le fichier ZIP
        const blob = new Blob([zipData], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);

        // élément <a> pour déclencher le téléchargement
        const link = document.createElement('a');
        link.href = url;
        link.download = `reunion_${user_meeting}_${date_meeting}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error handling download:', error);
    }
};


  const columns = [
    {
      field: 'date_meeting',
      headerName: 'Date',
      width: 150,
      valueFormatter: (params) => {
        return `${moment(params.row.date_meeting).format('DD/MM/YYYY')}`;
      },
    },
    { 
      field: 'salle',
      headerName: 'Porte',
      width: 150,
    },
    { 
      field: 'user_meeting',
      headerName: 'Utilisateur',
      width: 150,
    },
    { 
        field: 'object_meeting',
        headerName: 'Objet',
        width: 250,
    },
    {
      field: 'pv',
      headerName: 'PV',
      disableClickEventBulbing: true,
      renderCell: (params) => (
        <div>
          {params.row.pv !== 'AUCUN' ? (
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={() => handleClick(params.row.id_meeting, params.row.date_meeting, params.row.user_meeting)}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          ) : null}
        </div>
      ),
      width: 120,
    },
    (hasPrivilege(contextData.privileges, "Admin-meeting-Add-pv")) &&
      {
        field: 'action',
        headerName: 'Action',
        disableClickEventBulbing: true,
        renderCell: (params) => (
          <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={() => handleOpen(params.row.id_meeting)}
              >
                <NoteAddOutlined />
              </IconButton>
              <PvPopup open={open} handleClose={handleClose} id={isid} setMessage={setMessage}/>
          </div>
        ),
        width: 120,
      },

  ];
  return (
    <React.Fragment>
      <Message open={message.openMessage} color={message.color} messageInfo={message.messageInfo} handleClose={handleCloseMessage} />
      <div style={{ height: "85vh", width: '100%' }} className={classes.root} >
      
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[7]}
          disableSelectionOnClick
        />
      </div>
    </React.Fragment>
  );
}
