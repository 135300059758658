import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import KeybordVirtuel from "./KeybordVirtuel";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function CodePinDialog(props) {
  const open = props.openCodePin;
  const handleCloseCodePin = props.handleCloseCodePin;
  const handleValidateCodePin = props.handleValidateCodePin;
  const onChangeInput = props.onChangeInputValue;
  const failedCodePin = props.failedCodePin;
  const errorMessageFailedCodePin = props.errorMessageFailedCodePin;
  return (
    <Dialog
        open={open}
        onClose={handleCloseCodePin}
        aria-labelledby="form-dialog-title2"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title2">
          Validation éléctronique - Etape 2
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez entrer le code envoyer dans votre boite E-mail.
          </DialogContentText>
          <KeybordVirtuel onChangeInput={onChangeInput}/>
          {failedCodePin && (
            <Alert severity="error">{errorMessageFailedCodePin}</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "##f50057", color: "#FFFFFF" }}
            onClick={handleCloseCodePin}
            color="secondary"
            variant="contained"
          >
            Annuler
          </Button>
          <Button
            type="submit"
            style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF" }}
            onClick={handleValidateCodePin}
            color="primary"
            variant="contained"
          >
            Valider
          </Button>
        </DialogActions>
        
      </Dialog>
  );
}
