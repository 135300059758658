import { useState, useEffect } from "react";
import ListLeavesInDayService from "../../services/ListLeavesInDayService";
import ListLeaveCardPortal from "./ListLeaveCardPortal";

function ListLeavesInDay(props) {
  const [leaveInDay,setLeaveInDay]= useState([]);
  useEffect(() => {
    ListLeavesInDayService.getLeavesByOptions().then((result) => {
      if (result && result.data) {
        setLeaveInDay(
          result.data
        );
      }
    });

    let todayDate=new Date();
    console.log(todayDate.getDate())
  }, [props]);
  console.log("leavaInDay",leaveInDay)
  return (
    
    <ListLeaveCardPortal/>
    
  );
}
export default ListLeavesInDay;