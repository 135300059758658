import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import PlanningMissionService from "../../../services/PlanningMissionService";
import Alert from "@material-ui/lab/Alert";
import Message from "../../tools/Message";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 750,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: 'left',
  },
  labelPosition1: {
    float: 'right',
  },
  text: {
    width: 370,
  }
}));

function PlanningMissionDetailsAdd(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const MISSION_PLANNING_TEMP = JSON.parse(localStorage.getItem('MISSION_PLANNING_TEMP'));
  const [form, setForm] = useState({
    category_mission_id:MISSION_PLANNING_TEMP.category_mission_id, 
    mission_title:MISSION_PLANNING_TEMP.mission_title, 
    direction_destination:MISSION_PLANNING_TEMP.direction_destination, 
    district_destination:MISSION_PLANNING_TEMP.district_destination, 
    transport_type:MISSION_PLANNING_TEMP.transport_type, 
    mission_begin_date:MISSION_PLANNING_TEMP.mission_begin_date,
    mission_end_date:MISSION_PLANNING_TEMP.mission_end_date,
    common:MISSION_PLANNING_TEMP.commune_destination,
    location:MISSION_PLANNING_TEMP.localisation_destination,
    type_activity_id:MISSION_PLANNING_TEMP.type_activity_id,
    funding_id:MISSION_PLANNING_TEMP.funding_id,
    partnership:"",
    observation:"",
    user_id:[]
  });
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  const [direction, setDirection] = useState([]);
  const [service, setService] = useState([]);
  const [intervenant, setIntervenant] = useState([]);

  const [direction_id, setDirection_id] = useState(0);
  const [service_id, setService_id] = useState(0);

  const [isValidUser_id, setIsValidUser_id] = useState(false);

  useEffect(() => {
    console.log(MISSION_PLANNING_TEMP.direction_destination  + "sdksdjksdjksdjkj");
    PlanningMissionService.getDirection().then((result) => {
      if (result && result.data) {
        setDirection(result.data);
      }
    });
    PlanningMissionService.getService().then((result) => {
      if (result && result.data) {
        setService(result.data);
      }
    });
    PlanningMissionService.getIntervenant(direction_id, service_id).then((result) => {
      if (result && result.data) {
        setIntervenant(result.data);
      }
    });
    if(direction_id===null || service_id===null){
      PlanningMissionService.getIntervenant(0, 0).then((result) => {
        if (result && result.data) {
          setIntervenant(result.data);
        }
      });
    }
  }, [direction_id, service_id]);

  function handleChange(event){
    const {name, value} = event.target;
    setForm({ ...form, [name]: value });
  }

  function handleChangeDirection(event, value){
    setDirection_id(value);
  }

  function handleChangeService(event, value){
    setService_id(value);
  }
  
  function handleChangeIntervenants(event, value){
    setForm({ ...form, user_id: value });
  }

  function handleCloseMessage(){
    setMessage({...message, open: false});
  }

  function handleClick(){
    console.log("sxdsdsdsdd******999999999969");
    console.log(MISSION_PLANNING_TEMP.funding_id);

    if(form.user_id.length===0){
      setIsValidUser_id(true);
    }else{
    console.log(MISSION_PLANNING_TEMP.common);

      PlanningMissionService.saveMissionPlanning(form, MISSION_PLANNING_TEMP.mission_planning_id).then((result) => {
        if(result.status){
          setMessage({...message, open: true, color: 'green', messageInfo: result.message});
          // localStorage.setItem("MISSION_PLANNING_TEMP_ID", JSON.stringify(result.mission_planning_id));
          localStorage.removeItem("MISSION_PLANNING_TEMP");
          navigate("/mission/planning_mission");
        }else{
          setMessage({...message, open: true, color: 'red', messageInfo: result.message});
        }
      })
      .catch((err) => {
        setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      });
    }
  }

  return (
    <Grid container spacing={3} className={classes.paper}>
        <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
        <Grid item xs={12}>
          <fieldset>
            <legend>Autre(s) participant(s) possible</legend><br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={form.partnership} name="partnership" onChange={handleChange} />
              </Grid>
            </Grid><br />
          </fieldset>
        </Grid>
        <Grid item xs={12}>
        <fieldset>
            <legend>Missionnaires</legend><br />
            <Grid container spacing={3}>
            <Grid item xs={2}>
                <label className={classes.labelPosition}>Direction</label>
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                  onChange={handleChangeDirection}
                  options={direction} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Direction"
                      size="small"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                    />
                  )}
                />
            </Grid>
            <Grid item xs={2}>
                <center><label>Service</label></center>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                onChange={handleChangeService}
                options={service} getOptionLabel={(option) => option.name + ' (' + option.code + ')'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Service"
                    size="small"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: "search" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
                <label className={classes.labelPosition}>Intervenants</label>
            </Grid>
            <Grid item xs={10}>
              <Autocomplete multiple id="tags-outlined" options={intervenant} getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                filterSelectedOptions
                onChange={handleChangeIntervenants}
                value={form.user_id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    label="Intervenants"
                    variant="outlined"
                    placeholder="Intervenants"
                  />
                )} 
              />
              {isValidUser_id && <Alert severity="error">Veuillez remplir ce champ</Alert>}
            </Grid>
            </Grid><br />
        </fieldset>
        </Grid>
        <Grid item xs={12}>
        <fieldset>
            <legend>Observation</legend><br />
            <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField fullWidth id="outlined-basic" size="small" label="" variant="outlined" value={form.observation} name="observation" onChange={handleChange} />
            </Grid>
            </Grid><br />
        </fieldset>
        </Grid>
        <Grid item xs={12}>
            <div className={classes.labelPosition1}>
            <Grid container item xs={12} >
              <Button variant="contained" color="primary" onClick={handleClick} style={{ fontWeight: 600 }} >ENREGISTRER</Button>
            </Grid>
            </div>        
        </Grid>
    </Grid>
  );
}

export default PlanningMissionDetailsAdd;

