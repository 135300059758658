import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const MailHistoryReceiveService = {
    // async getMailHistory(id){
    //     try {
    //         const result = await axios.get(`${apiUrl}/MailHistory/${id}`  );
    //         if (result) {
    //           return result.data;
    //         }
    //       } 
    //       catch (error) {
    //         if (error.response) {
    //           return error.response.data;
    //         }
    //       }  
    // },

    async getMailHistoryNoDetails(id){
        try {
            const result = await axios.get(`${apiUrl}/MailHistory/HistoryNoDetails/${id}`  );
            if (result) {
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }  
    },


    async getMailHistoryWithDetails(thisuser,idget){
      try {

      //           console.log('BEFORE ???');
      // console.log('BEFORE : ', thisuser);
      // console.log('BEFORE 2 : ', idget);
          // const result = await axios.get(`${apiUrl}/MailHistory/HistoryWithDetails/${id}/${idmail}`  );
          const result = await axios.post(`${apiUrl}/MailHistory/HistoryWithDetails/`, {
            thisuser,idget
        });
          if (result) {
            return result.data;
          }
        } 
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }  
  },


  async getAllHistory(id){
    try {
        const result = await axios.get(`${apiUrl}/MailHistory/AllHistory/${id}`  );
        if (result) {
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }  
},

async getReceiveHistory(id){
    try {
        const result = await axios.get(`${apiUrl}/MailHistory/HistoryReceive/${id}`  );
        if (result) {
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }  
},

///////////////////////////////////////
async exportPj(id) {
  try {
    const response = await axios({
        url: `${apiUrl}/MailMulti/export/${id}`,
        method: 'GET',
        responseType: 'blob' // Important pour télécharger des fichiers binaires
    });
    return response.data; // Retourne les données binaires du fichier ZIP
  } catch (error) {
      if (error.response) {
          console.error('Error:', error.response.data);
          return error.response.data;
      } else {
          console.error('Error:', error.message);
      }
  }
},

async getFiles(id){
  try {
      const result = await axios.get(`${apiUrl}/MailHistory/files/${id}`  );
      if (result) {
        return result.data;
      }
    } 
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }  
},


}

export default MailHistoryReceiveService;