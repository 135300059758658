import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const PrivilegeService = {
  async add(name, desc, module_id) {
    try {
      const result = await axios.post(`${apiUrl}/privileges/`, {
        name,
        desc,
        module_id
      });
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async savePrivilege(name, desc, module_id, id) {
    try {
      const result = id ?
        await axios.put(`${apiUrl}/privilege/id/${id}`, {
          name,
          desc,
          module_id

        })
        :
        await axios.post(`${apiUrl}/privilege/add/`, {
          name,
          desc,
          module_id

        });
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async getModules() {
    try {
      const result = await axios.get(`${apiUrl}/module/`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async deletePrivilege(id) {
    try {
      const result = await axios.delete(`${apiUrl}/privilege/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getPrivilege(options) {
    try {
      const result = await axios.get(`${apiUrl}/privilege/`, {
        params: options,
        // paramsSerializer: params => {
        //   return qs.stringify(params)
        // }
      });
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,


  async getUserPrivilege() {
    try {
      // const token = localStorage.getItem("ACCESS_TOKEN");
      const result = await axios.get(`${apiUrl}/user/userPrivilege`);
      if (result) {
        // TODO: replace result.data.message by the token
       // console.log(result.data);

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        console.log(error.response.data);
        return error.response.data;
      }
    }

  }
  ,


  async PrivilegeById(id) {
    try {
      const result = await axios.get(`${apiUrl}/privilege/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,

}

export default PrivilegeService;