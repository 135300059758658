import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

/**
 * @returns a loader
 * Written By Samuela 
 */
function loading() {
    return (
        <div align="center">
            <Loader
                type="Circles"
                color="rgb(19,62,103)"
                height={100}
                width={100}
            />
        </div>
    )
}

export default loading
