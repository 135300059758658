import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const NotificationService = {
 async getstatus() {
    try {
      const result = await axios.get(`${apiUrl}/LeaveSolde/notif`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async saveNotification(code,status,request_type_id,date,state){
    try{
      await axios.post(`${apiUrl}/Notification/`, {
        code,
        status,
        request_type_id,
        date,
        state
      });
    }catch (error){
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async markAsRead(notificationId){
    try{
      const result = await axios.put(`${apiUrl}/Notification/markAsRead`, {
        notificationIds: notificationId
      });
      if (result) {
        return result.data;
      }
    }catch (error){
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getUserSupNotification() {
    try {
      const result = await axios.get(`${apiUrl}/Notification/getUserSupNotification`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  
};

export default NotificationService;
