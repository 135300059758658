import React, { useEffect, useState } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

export default function Message(props) {
  const [state, setState] = React.useState({
    vertical: 'bottom',
    horizontal: 'right',
  });
  const { vertical, horizontal } = state;
  const open = props.open;
  const color = props.color;
  const messageInfo = props.messageInfo;
  const handleClose = props.handleClose;
  return (
    <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} onClose={handleClose} key={vertical + horizontal} autoHideDuration={3000} >
        <SnackbarContent onClose={handleClose} aria-describedby="message-id2" style={{backgroundColor: color, color: "white"}}
            message={
                <span id="message-id2">
                <div>{messageInfo}</div>
                </span>
            }
        />
    </Snackbar>
  );
}
