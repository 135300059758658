export const headerDataAdmin = {
    title: 'Administration',
    submodules: [
        createData(0, "Groupes", "/groups"),
        createData(1, "Utilisateurs", "/users"),
        createData(2, "Privilèges", "/privileges"),
        createData(3, "Fonctions", "/fonction"),
        createData(4, "Directions", "/direction"),
        createData(5, "Antennes", "/antenne"),
        createData(6, "Services", "/services"),
        createData(7, "Bureau", "/seat"),
        createData(8, "Batiment", "/building")
    ],
}

function createData(id, moduleName, ref) {
    return { id, moduleName, ref};
}

