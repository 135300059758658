import React, { useState, useEffect } from "react";

// import ListLeavesInDayService from "../../services/ListLeavesInDayService";
// import ListLeaveCardPortal from "./ListLeaveCardPortal";
import {
    Grid,
    Paper,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import UserService from "../../services/UserService";
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@material-ui/lab/Autocomplete';

function PersonnalValidatorMission(props) {
    const handleChangeNumberOfValidator = props.handleChangeNumberOfValidator;
    // const handleChangeNumberOfValidatorMission = props.handleChangeNumberOfValidatorMission;
    const validatorNumber = props.validatorNumber;
    // const validatorNumberMission = props.validatorNumberMission;
    const userName = props.userName;
    const [validatorNumberMission, setValidatorNumberMission] = useState("");
    const [openSpecialSettingDialog, setOpenSpecialSettingDialog] = React.useState(false);
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
    const [idValidatorNumber, setIdValidatorNumber] = useState(0);
    const setId = props.id;
    const handleClose = props.handleCloseDialog;
    // const handleValidate = props.handleValidate;
    const [message, setMessage] = React.useState({
        openMessage: false,
        color: 'green',
        messageInfo: '',
    });
    const [rows, setRows] = useState([]);
    const [idModule, setIdModule] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = useState({
        module_type_id: "",
        libelle_module_type: "",
    });
    const [select, setSelect] = useState({
        value: "first",
        label: "first"
    });

    useEffect(() => {
        UserService.getAllModule().then((result) => {
            if (result && result.data) {
                setRows(result.data);
            } else {
            }
        });

        UserService.checkValidatornumberByUserIdMission(setId).then((result) => {
            if (result && result.status) {
                console.log("12*/ 1 ");
                console.log(result);
                setIdValidatorNumber(result.data.user_validator_number_id);
                setValidatorNumberMission(result.data.user_validator_number_validatorNumber);
                setIdModule(2);
            } else {
                console.log("firy ary io e");
                console.log(result);
                setValidatorNumberMission(0);
                setIdValidatorNumber(0);
                setIdModule(2);
                console.log(validatorNumber);
            }
        })
    }, [props]);

    function loadAllUser() {
        setOpenLoadingSimpleBackdrop(true);
        UserService.getAllModule().then((result) => {
            if (result && result.data) {
                setOpenLoadingSimpleBackdrop(false);
            } else {
                setOpenLoadingSimpleBackdrop(false);
            }
        });
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setIdModule(value);
        setForm({ ...form, [name]: value });
    }



    function handleValidate() {
       setLoading(true);
        UserService.saveValidatorNumberMission(idValidatorNumber, setId, validatorNumberMission, idModule).then((result) => {
            if (result && result.status) {
                // setIsValidatorNumberConfigurated(true);
                setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.message });
                setOpenSpecialSettingDialog(false);
                setLoading(false);
                loadAllUser();
                handleClose(false);
            }
        });
    }


    function handleChangeNumberOfValidatorMission(ev) {
        const { name, value } = ev.target;
        const newValue = value.replace(/[^0-9]/g, '');
        setValidatorNumberMission(newValue);
    }



    return (
        <Grid container spacing={2} xs={12}>

            <Grid item xs={4}>
                <label>Module :</label>
            </Grid>
            <Grid item xs={8}>
                <FormControl fullWidth variant="outlined" size="small" disabled>
                    <InputLabel id="demo-simple-select-outlined-label" >
                        Module
                    </InputLabel>
                    <Select labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={idModule}
                        name="Module"
                        label="rubrique "
                        onChange={(value) => {
                            setSelect(value);
                        }}
                        isOptionDisabled={(option) =>
                            option.value === "disabled" && select.value !== "removeDisabled"
                        }
                    >
                        {rows.map((row) => (
                            <MenuItem value={row.module_type_id}>{row.libelle_module_type}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>



            <Grid item xs={4}>
                <label>Utilisateur:</label>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="user"
                    value={userName}
                    disabled
                />
            </Grid>
            <Grid item xs={4}>
                <label>Nombre de validateur:</label>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="validatorNumber"
                    inputProps={{ maxLength: 1 }}
                    value={validatorNumberMission}
                    onChange={handleChangeNumberOfValidatorMission}
                />
            </Grid>





            <Grid container item xs={12} direction="row-reverse">

               <LoadingButton
                      onClick={handleValidate}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                >
                    Valider
                </LoadingButton>
                <Spacer x={2} />
                <Button
                    style={{ backgroundColor: "#f50057", color: "#FFFFFF" }}
                    onClick={handleClose}
                    variant="contained"
                >
                    Annuler
                </Button>
            </Grid>
        </Grid>
    );
}
export default PersonnalValidatorMission;