import React, { useState, useEffect } from "react";
import {
    Grid,
    Paper,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import UserService from "../../../services/UserService";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from "@material-ui/lab/Alert";
import Message from "../../tools/Message";
import UserSpecialValidatorService from "../../../services/UserSpecialValidatorService";
import PersonnalSpecialValidatorList from "./PersonnalSpecialValidatorList";
import PersonnalSpecialValidatorAdd from "./PersonnalSpecialValidatorAdd";
import PersonnalSpecialValidatorEdit from "./PersonnalSpecialValidatorEdit";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { setSyntheticLeadingComments } from "typescript";

function PersonnalSpecialValidator(props) {
    const handleChangeNumberOfValidator = props.handleChangeNumberOfValidator;
    const userId = props.userId;
    const userName = props.userName;
    const userDirection = props.userDirection;
    const [validatorNumber, setvalidatorNumber] = useState(0);
    const [openSpecialSettingDialog, setOpenSpecialSettingDialog] = React.useState(false);
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
    const [showSuperiorConfig, setShowSuperiorConfig] = React.useState(false);
    const [superiorConfigInput, setSuperiorConfigInput] = React.useState([]);
    const [idValidatorNumber, setIdValidatorNumber] = useState(0);
    const [users, setUsers] = React.useState([]);
    const [iduser, setIdUsers] = React.useState([]);
    const [listidsup, setlistidsup] = React.useState([]);
    const [supListSpecial, setSupListSpecial] = React.useState([]);
    const [validatorNotMatch, setValidatornotmatch] = React.useState(false);
    const [maxValidatorNumber, setMaxValidatorNumber] = React.useState(false);
    //const setId = useState(0);
    const [idSup, setIdSup] = useState("");
    const handleClose = props.handleCloseDialog;
    const [isAdd, setIsAdd] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [rows, setRows] = useState([]);
    const [idModule, setIdModule] = React.useState(0);
    const [specialValidatorConfigRow, setspecialValidatorConfigRow] = React.useState([]);
    const [userNameInfosEdit, setUserNameInfosEdit] = useState("");

    const [form, setForm] = useState({
        module_type_id: "",
        libelle_module_type: "",
    });
    const [select, setSelect] = useState({
        value: "first",
        label: "first"
    });
    const [message, setMessage] = React.useState({
        open: false,
        color: 'green',
        messageInfo: '',
    });
    const { open, color, messageInfo } = message;

    useEffect(() => {
        UserService.getAllModule().then((result) => {
            if (result && result.data) {
                setRows(result.data);
            } else {
            }
        });


        UserService.getAllUserByDirection(userDirection).then((result) => {
            if (result && result.data) {
                setUsers(result.data);
            } else {
            }
        });

        loadConfigSpecialValidator();

    }, [props]);


    useEffect(() => {
        if (validatorNumber <= 2) {
            setShowSuperiorConfig(true);
            setMaxValidatorNumber(false);
            if (isEdit) {                  
                // Change Jefferson          
                var superiorConfigInputTemp = [];
                for (var i = 0; i < 1; i++) {
                    var tempInput = [];
                    tempInput.label = "A remplacer par: ";
                    tempInput.name = "superior" + (i + 1);
                    superiorConfigInputTemp.push(tempInput);
                }
                setSuperiorConfigInput(superiorConfigInputTemp);
                /*
                Source by Samuela
                console.log("supListSpecial**",supListSpecial);
                var superiorConfigInputTemp = [];
                for (var j = 0; j < supListSpecial; j++) {
                    supListSpecial.map((sup, index)=>{

                        console.log("supListSpecialezezez**",sup);
                        var idUser = [];
                        idUser.name = sup.name;
                        idUser.user_id = sup.userId;
                        idUser.email = sup.email;
    
                        var tempInput = [];
                        tempInput.label = "Supérieur hiérarchique " + (index + 1);
                        tempInput.name = "superior" + (index + 1);
                        tempInput.idUser = idUser;
                        superiorConfigInputTemp.push(tempInput);
                    })
                }
                
                console.log("supListSpecialTable**",superiorConfigInputTemp);
                setSuperiorConfigInput(superiorConfigInputTemp);
                */
            } else {
                var superiorConfigInputTemp = [];
                for (var i = 0; i < validatorNumber; i++) {
                    var tempInput = [];
                    tempInput.label = "Supérieur hiérarchique " + (i + 1);
                    tempInput.name = "superior" + (i + 1);
                    superiorConfigInputTemp.push(tempInput);
                }
                setSuperiorConfigInput(superiorConfigInputTemp);
            }
        }
        else{
            setMaxValidatorNumber(true);
        }
    }, [validatorNumber]);

    useEffect(() => {
        if (superiorConfigInput.length >= 1) {
            setShowSuperiorConfig(true);
        } else {
            setShowSuperiorConfig(false);
        }
    }, [superiorConfigInput]);

    useEffect(() => {
        if (isEdit) {
            setShowSuperiorConfig(true);
            UserSpecialValidatorService.getSupSpecialValidateurByUserId(userId).then((result) => {
                if (result && result.status) {
                    setSupListSpecial(result.data);
                    setvalidatorNumber(result.data.length);
                    setOpenLoadingSimpleBackdrop(false);
                } else {
                    setOpenLoadingSimpleBackdrop(false);
                }
            });
        }
    }, [isEdit]);
    
    function loadConfigSpecialValidator() {
        setOpenLoadingSimpleBackdrop(true);
        UserSpecialValidatorService.getConfigSpecialValidateur(userId).then((result) => {
            console.log("userId : ", userId, "result : ", result);
            if (result && result.status) {
                setspecialValidatorConfigRow(result.data.map((row) => ({ ...row, id: row.user_special_validator_id })));
                setOpenLoadingSimpleBackdrop(false);
                setIsAdd(false);
            } else {
                setOpenLoadingSimpleBackdrop(false);
            }
        });
    }

    function loadAllUser() {
        setOpenLoadingSimpleBackdrop(true);
        UserService.getAllModule().then((result) => {
            if (result && result.data) {
                setOpenLoadingSimpleBackdrop(false);
            } else {
                setOpenLoadingSimpleBackdrop(false);
            }
        });
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setIdModule(value);
        setForm({ ...form, [name]: value });
    }

    function handleValidate() {
        console.log("validatorNumber : ", validatorNumber,  "length : ", listidsup.length, "listidsup : ", listidsup);        
        if (validatorNumber != listidsup.length) {
            setValidatornotmatch(true);
        } else {
            var listSupFinal = [];

            listidsup.map((row, index) => {
                var temp = {};
                temp['user_special_validator_id'] = userId;
                temp['user_key'] = userId;
                temp['user_sup'] = row.user_id;
                if (index > 0) {
                    // temp.user_id = row[index+1].
                    // console.log('row-----', listSupFinal[index-1]);
                    temp['user_special_validator_id'] = listSupFinal[index - 1]['user_sup'];
                }
                listSupFinal.push(temp);
            })
            
            UserSpecialValidatorService.saveUserSpecialSuperior(userId, listSupFinal).then((result) => {
                if (result && result.status) {
                    setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
                    loadConfigSpecialValidator();                    
                    listidsup.splice(0, listidsup.length);
                } else {
                    setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
                }
            });
            setValidatornotmatch(false);
        }
    }

    function handleValidateEdit() {
        if (listidsup.length == 0) {
            setValidatornotmatch(true);
        } else {
            var listSupFinal = listidsup[0].user_id;
            
            UserSpecialValidatorService.saveUserSpecialSuperior(userId, listSupFinal, idSup).then((result) => {
                if (result && result.status) {
                    setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
                    setIsEdit(false);
                    setvalidatorNumber(0);
                    loadConfigSpecialValidator();
                    listidsup.splice(0, listidsup.length);
                } else {
                    setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
                }
            });
            setValidatornotmatch(false);
        }
    }

    function handleChangeNumberOfValidatorMission(ev) {
        const { name, value } = ev.target;
        const newValue = value.replace(/[^0-9]/g, '');
        setvalidatorNumber(newValue);
    }

    function handleChangeUsers(ev, value) {
        setlistidsup(oldArray => [...oldArray, value]);
    }

    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }

    const handleEditSpecialValidator = (id) => (ev) => {
        setIdSup(id);
        setIsEdit(true);
        UserSpecialValidatorService.getById(id).then((result) => {
            if (result) {   
                UserService.getUserId(result.data).then((result) => {
                    if (result && result.status) {
                        setUserNameInfosEdit(result.data.person.last_name+ " "+ result.data.person.first_name);
                    }
                });
            }
        });
    };

    const handleDeleteSpecialValidator = (id) => (ev) => {
        UserSpecialValidatorService.deleteSpecialValidator(id).then((result) => {
            if (result && result.status) {
                setMessage({ ...message, open: true, color: 'green', messageInfo: "Suppression avec succès" });
                loadConfigSpecialValidator();
            }
        });
    };

    function handleClickCreate() {
        setIsAdd(true);
    }

    function handleCloseEdit() {
        setIsEdit(false);
        loadConfigSpecialValidator();                              
    }

    return (
        <>
            <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />

            <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />

            {(!isAdd && !isEdit) &&
                <Grid container spacing={2}>
                    {specialValidatorConfigRow.length == 0 &&
                        < Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleClickCreate}
                                style={{ fontWeight: 600 }}
                            >
                                CREER
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <PersonnalSpecialValidatorList 
                            rows={specialValidatorConfigRow} 
                            handleEditSpecialValidator={handleEditSpecialValidator}
                            handleDeleteSpecialValidator={handleDeleteSpecialValidator} />
                    </Grid>
                </Grid>
            }

            {(isAdd) &&
                <PersonnalSpecialValidatorAdd 
                    userName={userName}
                    validatorNumber={validatorNumber}
                    showSuperiorConfig={showSuperiorConfig}
                    superiorConfigInput={superiorConfigInput}
                    users={users}
                    iduser={iduser}
                    validatorNotMatch={validatorNotMatch}
                    maxValidatorNumber={maxValidatorNumber}
                    isEdit={isEdit}
                    handleChangeNumberOfValidatorMission={handleChangeNumberOfValidatorMission}
                    handleValidate={handleValidate}
                    handleClose={handleClose}
                    handleChangeUsers={handleChangeUsers} />
            }

            {(isEdit) &&
                <PersonnalSpecialValidatorEdit 
                    userName={userName}
                    validatorNumber={validatorNumber}
                    showSuperiorConfig={showSuperiorConfig}
                    superiorConfigInput={superiorConfigInput}
                    users={users}
                    iduser={iduser}
                    validatorNotMatch={validatorNotMatch}
                    maxValidatorNumber={maxValidatorNumber}
                    isEdit={isEdit}
                    handleChangeNumberOfValidatorMission={handleChangeNumberOfValidatorMission}
                    userNameInfosEdit={userNameInfosEdit}
                    handleValidateEdit={handleValidateEdit}
                    handleCloseEdit={handleCloseEdit}
                    handleChangeUsers={handleChangeUsers} />
            }
        </>
    );
}
export default PersonnalSpecialValidator;