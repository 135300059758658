import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  Box,
} from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { extendMoment } from "moment-range";
import { useNavigate,useParams } from "react-router-dom";
import { userDetailContext } from "../../App";
import Moment from "moment";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import MettingService from "../../services/MettingService";
import MeetingRoomService from "../../services/MeetingRoomService";
import { format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import Message from "../tools/Message";
import MailConfigAddService from "../../services/MailConfigAddService";


// import React, { useState, useEffect } from "react";
import { Menu} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from 'react-router-dom';
import MailListPersonService from "../../services/MailListPersonService";
import { FormatBold } from "@material-ui/icons";
import { headerMail } from "../../data/modules/mail-header";
import { DataGrid } from "@material-ui/data-grid";

const MailListPerson = () => {

const location = useLocation();
const idd = location.state?.selectedValueFonction;

/////
const selectedValueDirectionIdList =  location.state?.selectedValueDirectionIdList;
const selectedValueServiceIdList =  location.state?.selectedValueServiceIdList;
const selectedValueFonctionIdList =  location.state?.selectedValueFonctionIdList;
////

const navigate = useNavigate();
// console.log('ID VE MANDEHA : ', idd);

const[person, setPerson] = useState([]);
const[p, setP] = useState([]);

const [checkedBoxes, setCheckedBoxes] = useState({});

const [selectedUsers, setSelectedUsers] = useState([]);
const [selectedItems, setSelectedItems] = useState([]);

const [rows, setRows] = useState([]);


useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Direction PASSED :'. selectedValueDirectionIdList);
        console.log('Service PASSED :'. selectedValueServiceIdList);
        console.log('Fonction PASSED :'. selectedValueFonctionIdList);

        if(selectedValueDirectionIdList.length > 0 && selectedValueServiceIdList.length == 0 && selectedValueFonctionIdList.length == 0){
          const liste = await MailListPersonService.getUserByMultipleDirection(selectedValueDirectionIdList);
          setPerson(liste.data);
          setRows(liste.data.map((row) => ({ ...row, id: row.user_id })));

        }else if(selectedValueServiceIdList.length > 0 && selectedValueFonctionIdList.length == 0){
          const liste = await MailListPersonService.getUserByMultipleService(selectedValueServiceIdList);
          setPerson(liste.data);
          setRows(liste.data.map((row) => ({ ...row, id: row.user_id })));
          
        }else if(selectedValueFonctionIdList.length > 0){
          const liste = await MailListPersonService.getUserByMultipleFonction(selectedValueFonctionIdList);
          setPerson(liste.data);
          setRows(liste.data.map((row) => ({ ...row, id: row.user_id })));
        }

      } catch (error) {
        console.error("Failed to fetch data fa tena misy tsy mety:", error);
      }
    };

    fetchData();
  }, []);


useEffect(() => {
    const initialState = person.reduce((acc, curr) => ({ ...acc, [curr.user_id]: true }), {});
    setCheckedBoxes(initialState);
    setSelectedUsers(person.filter(p => initialState[p.user_id]));
    console.log('USERS : ', selectedUsers);
}, [person]);


const handleCheckboxChange = (userId) => {
    setCheckedBoxes(prevState => ({
        ...prevState,
        [userId]: !prevState[userId]
    }));
    
};

const handleChecked = (userId) => async(event) => {

  setCheckedBoxes(prevState => ({
    ...prevState,
    [userId]: !prevState[userId]
  }));

  console.log("id param: ", userId, event.target.checked);

  const userExists = selectedUsers.some(selectedUserId => selectedUserId === userId);
  console.log('EXISTE ? ',userExists);

  const pers = await MailListPersonService.getUserById(userId);
  setP(pers);

  if(event.target.checked == true){
    selectedUsers.push(p.data[0]);
    console.log('NAMPIANA : ', userId);
    console.log('NAMPIANA : ', selectedUsers);

  }

  else{

    const index = selectedUsers.findIndex(user => user.user_id === userId);
    if (index > -1) {
      selectedUsers.splice(index, 1);
    }

   
    console.log('NALANA : ', userId);
    console.log('NALANA : ', selectedUsers);

  }    
  setSelectedUsers(selectedUsers => [...selectedUsers]);

}; 






/////////////////////////////////////////////////
// const [rows, setRows] = useState([]);
const columns = [
    { field: 'checkbox', headerName: '', width: 50, renderCell: (params) => (
      <Checkbox
      value={params.row.user_id}
      checked={!!checkedBoxes[params.row.user_id]}
      onChange={handleChecked(params.row.user_id)}
      />
    )},
    { field: 'direction_code', headerName: 'Direction', width: 150 },
    { field: 'service_code', headerName: 'Service', width: 150 },
    { field: 'code', headerName: 'Fonction', width: 150 },
    { field: 'name', headerName: 'Nom-Prénom', width: 200, filterable: true,
    renderCell: (params) => `${params.row.first_name} ${params.row.last_name}`, },
    { field: 'email', headerName: 'Mail', width: 200 }
  ];
  
  const modifiedPersonArray = Array.isArray(person)
  ? person.map((item, index) => ({
    ...item,
    id: item.id || index, // Ensure each item has a unique ID
  }))
  : [];

  // const handleDeselect = (event) => {
  //   setCheckedBoxes([]);
  //   setSelectedUsers([]);
  // };

  const handleReturn = (event) => {
    navigate("/mailsend");
  };
  
  //////
  const [messagepop, setMessagepop] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = messagepop;
  
  function handleCloseMessage() {
    setMessagepop({ ...messagepop, openMessage: false });
  }
  
  
  const handleNextt = (event) => {
    console.log("FONCTIONS : " , selectedValueFonctionIdList);
    const personsize = selectedUsers.length;
    if(personsize == 0){
      setMessagepop({ ...messagepop, open: true, color: "red", messageInfo: "Veuillez choisir au moins une personne" });
    }else{
      navigate("/sendmail", { state: { selectedUsers, idd, selectedValueDirectionIdList, selectedValueServiceIdList, selectedValueFonctionIdList}});
    }

  };

  return (
    <MainContainer {...headerMail}>
        <Message open={messagepop.open} color={messagepop.color} messageInfo={messagepop.messageInfo} handleClose={handleCloseMessage} />
          <Container sx={{ width: '900px' }}>
            <Box component="form" mt={2} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
                  Choisissez vos destinataires
                </Typography>
              </Grid>
              <Box mt={4}> {/* Added mt={2} here to create space */}
                <Grid container spacing={3}>
                  <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                      rows={modifiedPersonArray}
                      columns={columns}
                      pageSize={5}
                      disableSelectionOnClick
                    />
                  </div>

                  <Box display="flex" justifyContent="space-between" width="100%">
                    <Button onClick={handleReturn} style={{ minWidth: '50px', maxWidth: '150px', marginLeft: '50px' }}>
                      PRECEDENT
                    </Button>
                    {/* <Button onClick={handleDeselect} style={{ minWidth: '50px', maxWidth: '150px', marginLeft: '50px' }}>
                      DESELECT ALL
                    </Button> */}
                    <Button variant="contained" color="primary" onClick={handleNextt} style={{ minWidth: '50px', maxWidth: '150px' }}>
                      SUIVANT
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Box>
        </Container>
    </MainContainer>
  );

};

export default MailListPerson;
