import { useEffect, useState } from "react";
import { useParams } from 'react-router';
import { Document, Page, pdfjs } from "react-pdf";
import TDRService from "../../../services/TDRService";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



function PdfRender(props) {

  const params = useParams();
  const id = params.id;
  console.log("props   *****", id);
  const [pdf, setPdf] = useState("");
  const [pageNumber, setStateNumPage] = useState(0);

  useEffect(() => {
    TDRService.renderpdf(id).then((result) => {
      if (result) {
        const blob = new Blob([result.data], {
          type: 'application/pdf',
        });
        setPdf(blob);
      }
    });
  }, []);

  function onDocumentLoad() {

    setStateNumPage(1);
  }

  return (
    <>
      <Document file={pdf}
        onLoadSuccess={onDocumentLoad}
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </>
  );

}
export default PdfRender;
