import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const NumberValidationService = {
    async add(minimum_validator, module_type_id) {
        try {
          const result = await axios.post(`${apiUrl}/RequestType/`, {
            minimum_validator,
            module_type_id
          });
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
    }
    ,
    async saveRequestType(minimum_validator, module_type_id,request_type_id, validationNumber) {
        try {
          const result = request_type_id ?
            await axios.put(`${apiUrl}/RequestType/id/${request_type_id}`, {
                minimum_validator,
                module_type_id,
                validationNumber
    
            })
            :
            await axios.post(`${apiUrl}/RequestType/add/`, {
                minimum_validator,
                module_type_id,
                validationNumber
    
            });
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      }
      ,
      async getModuleType() {
        try {
          const result = await axios.get(`${apiUrl}/ModuleType/`);
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        }
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      },
      async deleteRequestType(id) {
        try {
          const result = await axios.delete(`${apiUrl}/RequestType/id/${id}`);
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        }
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      }
      ,
      async getRequestType(options) {
        try {
          const result = await axios.get(`${apiUrl}/RequestType/`, {
            params: options,
            // paramsSerializer: params => {
            //   return qs.stringify(params)
            // }
          });
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        }
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
    
      }
      ,
      async RequestTypeById(id) {
        try {
          const result = await axios.get(`${apiUrl}/RequestType/id/${id}`);
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        }
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
    }
    ,
}
export default NumberValidationService;
