import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../components/Title";
import { CreateTwoTone, DeleteOutlineRounded, Settings } from "@material-ui/icons";
import UserService from "../../services/UserService";
import { DataGrid } from '@material-ui/data-grid';
import Message from "../tools/Message";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { useNavigate, useLocation } from 'react-router-dom';
import { hasPrivilege } from "../../utils/privilege";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import SpecialSettingDialog from "./SpecialSettingDialog";
const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function UserTable(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [rows, setRows] = useState([]);
  const [userInfo, setUserInfo] = useState("");
  const classes = useStyles();
  const [isid, setIsid] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'voulez vous supprimer?',
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    openMessage: false,
    color: 'green',
    messageInfo: '',
  });
  const { openMessage, color, messageInfo } = message;
  const contextData = useContext(userDetailContext);
  const [openSpecialSettingDialog, setOpenSpecialSettingDialog] = React.useState(false);

  const [idValidatorNumber, setIdValidatorNumber] = useState(0);
  const [validatorNumber, setValidatorNumber] = useState("");
  const [validatorNumberMission, setValidatorNumberMission] = useState("");
  const [id, setId] = useState("");
  const [isValidatorNumberConfigurated, setIsValidatorNumberConfigurated] = useState(false);
  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  useEffect(() => {
    loadAllUser();
  }, [location]);

  const handleDelete = (id) => (ev) => {
    setIsid(id);
    setConfirmationDialog({ ...confirmationDialog, openDialog: true, messageDialog: 'voulez vous supprimer?' });
  };

  function loadAllUser() {
    setOpenLoadingSimpleBackdrop(true);
    UserService.getAllUser().then((result) => {
      if (result && result.data) {
        setRows(result.data.map((row) => ({ ...row, id: row.user_id })));
        setOpenLoadingSimpleBackdrop(false);
      } else {
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }

  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false, messageDialog: 'voulez vous supprimer?' });
  }
  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false, messageDialog: 'voulez vous supprimer?' });
    UserService.updateisactive(isid).then((result) => {
      if (result.status) {

        setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.message });
        loadAllUser();
      } else {
        setMessage({ ...message, openMessage: true, color: 'red', messageInfo: result.message });
      }
    }).catch((err) => {
      setMessage({ ...message, openMessage: true, color: 'red', messageInfo: 'erreur' });
    });
  }
  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function handleCloseMessage() {
    setMessage({ ...message, openMessage: false });
  }


  const handleEdit = (id) => (ev) => {
    navigate("/users/add/" + id);
    window.location.reload();
  };


  function handleCloseSettingDialog() {
    setOpenSpecialSettingDialog(false);
  }

  function handleValidate() {
    // console.log(idValidatorNumber);
    UserService.saveValidatorNumber(userInfo.user_id, validatorNumber, idValidatorNumber).then((result) => {
      if (result && result.status) {
        setIsValidatorNumberConfigurated(true);
        setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.message });
        setOpenSpecialSettingDialog(false);
        loadAllUser();
      }
    });
  }

  function handleChangeNumberOfValidator(ev) {
    const { name, value } = ev.target;
    const newValue = value.replace(/[^0-9]/g, '');
    setValidatorNumber(newValue);
  }


  function handleChangeNumberOfValidatorMission(ev) {
    const { name, value } = ev.target;
    const newValue = value.replace(/[^0-9]/g, '');
    setValidatorNumberMission(newValue);
  }

  const handleClickSpecialSetting = (id) => (ev) => {
    setOpenSpecialSettingDialog(true);
    UserService.checkValidatornumberByUserId(id).then((result) => {
      if (result && result.status) {
        console.log("12*/ 1 ");
        console.log(result);
        setId(id);
        setIdValidatorNumber(result.data.user_validator_number_id);
        setValidatorNumber(result.data.user_validator_number_validatorNumber);
      } else {
        console.log("firy ary io e");
        console.log(result);
        setValidatorNumber(0);
        setId(id);
        console.log(validatorNumber);
      }
    });

    UserService.checkValidatornumberByUserIdMission(id).then((res) => {
      if (res && res.status) {
        console.log("12*/ 2");
        console.log(res);
        setValidatorNumberMission(res.data.user_validator_number_validatorNumber);
        console.log("res + " + validatorNumberMission);
      } else {
        setValidatorNumberMission(0);
      }
    });

    UserService.getUserSummaryByUserId(id).then((result) => {
      if (result && result.status) {
        setUserInfo(result.data)
        console.log(result);
      }
    });
  };

  const columns = [
    {
      field: 'registration_number',
      headerName: 'Matricule',
      width: 150,
    },
    {
      field: 'direction',
      headerName: 'Direction',
      width: 150,
    },
    {
      field: 'service',
      headerName: 'Service',
      width: 150,
    },
    {
      field: 'function_name',
      headerName: 'Fonction',
      width: 150,
    },
    {
      field: 'last_name',
      headerName: 'Nom',
      width: 250,
    },
    {
      field: 'first_name',
      headerName: 'Prénom',
      width: 250,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'telephone',
      headerName: 'Téléphone',
      width: 200,
    },
    {
      field: 'group',
      headerName: 'Groupes',
      width: 250,
    },
    {
      field: 'action',
      headerName: 'Action',
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Button onClick={handleEdit(params.id)} title="Modifier un utilisateur"><CreateTwoTone style={{ color: "black" }} />
            </Button>
            <Button onClick={handleDelete(params.id)} title="Desactiver un utilisateur">
              <DeleteOutlineRounded style={{ color: "red" }} />
            </Button>

            {hasPrivilege(contextData.privileges, "Other-Me-Validations") && (
              <Button onClick={handleClickSpecialSetting(params.id)} title="Paramètre spécial">
                <Settings style={{ color: "grey" }} />
              </Button>
            )}
          </div>
        );
      },
      width: 200,
    },
  ];
  return (
    <React.Fragment>
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <Message open={openMessage} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <SpecialSettingDialog openSpecialDialog={openSpecialSettingDialog} handleCloseDialog={handleCloseSettingDialog}
        userInfo={userInfo} handleValidate={handleValidate} validatorNumber={validatorNumber}
        validatorNumberMission={validatorNumberMission} id={id}
        handleChangeNumberOfValidator={handleChangeNumberOfValidator}
        handleChangeNumberOfValidatorMission={handleChangeNumberOfValidatorMission}
      />
      <Title>Listes des Utilisateurs</Title>
      <div style={{ height: "70vh", width: '100%' }} className={classes.root} >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 100, 150, 200]}
          disableSelectionOnClick
        />
      </div>
    </React.Fragment>
  );
}
