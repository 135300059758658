import React, { useState, useEffect } from "react";
import {
    Grid,
    Paper,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import UserService from "../../services/UserService";
import DirectionService from "../../services/DirectionService";
import PlanningService from "../../services/PlanningService";
import LoadingButton from '@mui/lab/LoadingButton';
import { id } from "date-fns/locale";
import { Navigate } from "react-router";

function Userhierarchie(props) {
    const userName = props.userName;
    const handleClose = props.handleCloseDialog;
    const setId = props.id;
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
    const [openSpecialSettingDialog, setOpenSpecialSettingDialog] = React.useState(false);
    const [dirSupHierarchique, setDirSupHierarchique] = useState([]);
    const [serviceSupHierarchique, setServiceSupHierarchique] = useState([]);
    const [idserviceSupHierarchique, setIdserviceSupHierarchique] = useState(0);
    const [iddirSupHierarchique, setIddirSupHierarchique] = useState(0);
    const [users, setUsers] = useState([]);
    const [idUserSup, setIdUserSup] = React.useState(0);
    const [idbase,setIdbase]=useState(0);
    const [loading, setLoading] = React.useState(false);

    const idUser = 0;
    const [message, setMessage] = React.useState({
        openMessage: false,
        color: 'green',
        messageInfo: '',
    });

    function handleChangeDirectionSupHierarchique(event) {
        const { name, value } = event.target;
        setIddirSupHierarchique(value);
        setUsers([]);
        PlanningService.Direction(value).then((results) => {
            if (results && results.data) {
              setServiceSupHierarchique(results.data);
            }
          })
      }

      function handleChangeServiceSupHierarchique(ev) {
        const { name, value } = ev.target;
        setIdserviceSupHierarchique(value);
    
        UserService.getAllUserByService(idUser ? true : false, value, idUser ? idUser : 0).then((results) => {
          if (results && results.status) {
            setUsers(results.data);
          } else {
          }
        });
      }
      

      function handleChangeUsers(ev,newValue) {
        setIdUserSup(newValue);
      }

    useEffect(() => {
        setIddirSupHierarchique(0);
        setIdserviceSupHierarchique(0);
        setIdUserSup(0);
        setIdbase(0);
        DirectionService.getDirection().then((res) => {
            if (res && res.status) {
              setDirSupHierarchique(res.data);
            }
          });

          console.log("id",setId);
          UserService.getsuphierarchie(setId).then((result) => {
            if (result && result.data ) {
                console.log("response a",result.data);
                if (setId=== result.data.user_id) {
                    setIddirSupHierarchique(result.data.direction_id);
                    setIdserviceSupHierarchique(result.data.service_id);
                    setIdUserSup(result.data.superior_id);
                    setIdbase(result.data.user_superior_id);
                } else {
                    setIddirSupHierarchique(0);
                    setIdserviceSupHierarchique(0);
                    setIdUserSup(0);
                    setIdbase(0);
                }
           
            }
         });

    }, [props]);

    useEffect(() => {
        PlanningService.Direction(iddirSupHierarchique).then((results) => {
            if (results && results.data) {
              setServiceSupHierarchique(results.data);
            }
          })
 
    }, [iddirSupHierarchique]);

    useEffect(() => {
        UserService.getAllUserByService(idUser ? true : false, idserviceSupHierarchique, idUser ? idUser : 0).then((results) => {
            if (results && results.status) {
              setUsers(results.data);
            } else {
            }
          });
 
    }, [idserviceSupHierarchique]);

    function loadAllUser() {
        setOpenLoadingSimpleBackdrop(true);
    }




    function handleValidate() {
        setLoading(true);
        if (idbase!==0) {
            UserService.updatesuphierarchie(idUserSup,setId,idbase).then((result) => {
                if (result && result.status) {
                    setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.message });
                    setOpenSpecialSettingDialog(false);
                    setLoading(false);
                    loadAllUser();
                    handleClose(false);
                }
             });
        } else {
            UserService.issuphierarchie(idUserSup,setId).then((result) => {
                if (result && result.status) {
                    setMessage({ ...message, openMessage: true, color: 'green', messageInfo: result.message });
                    setOpenSpecialSettingDialog(false);
                    setLoading(false);
                    loadAllUser();
                    handleClose(false);
                }
             });
        }
         
    }

    return (
        <Grid container spacing={2} xs={12}>

            <Grid item xs={4}>
                 <label class="aligneleft">Direction :</label>
            </Grid>
            <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">

                    <InputLabel id="demo-simple-select-outlined-label" >

                    Direction
                    </InputLabel>

                    <Select labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={iddirSupHierarchique}
                    name="direction_id"
                    label="Direction"
                    onChange={handleChangeDirectionSupHierarchique}
                    >
                    {dirSupHierarchique.map((row) => (
                        <MenuItem value={row.direction_id}>{row.code}</MenuItem>

                    ))}
                </Select>
                </FormControl>
            </Grid>



            <Grid item xs={4}>
                <label>Service :</label>
            </Grid>
            <Grid item xs={8}>
             <FormControl fullWidth variant="outlined" size="small">

                <InputLabel id="demo-simple-select-outlined-label" >

                Service
                </InputLabel>

                <Select labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={idserviceSupHierarchique}
                name="service_id"
                onChange={handleChangeServiceSupHierarchique}
                label="Service" >
                {serviceSupHierarchique.map((row) => (
                    <MenuItem value={row.service_id}>{row.code}</MenuItem>
                ))}
                </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <label>Supérieur hiérarchique :</label>
            </Grid>
            <Grid item xs={8}>
                 <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-outlined-label" >
                       Supérieur hiérarchique
                    </InputLabel>
                       <Select
                                labelId="select-superieur-label"
                                id="select-superieur"
                                name="user_id"
                                 value={users.find(option => option.user_id === idUserSup)?.user_id || ''}
                                 onChange={(event) => handleChangeUsers(event, event.target.value)}
                                label="Supérieur hiérarchique"
                            >
                                {users.map((option) => (
                                    <MenuItem key={option.user_id} value={option.user_id}>
                                        {`${option.first_name} ${option.last_name}`}
                                    </MenuItem>
                                ))}
                            </Select>
                     </FormControl>
            </Grid>
            <Grid container item xs={12} direction="row-reverse">
                 <LoadingButton
                      onClick={handleValidate}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                >
                    Valider
                </LoadingButton>
                <Spacer x={2} />
                <Button
                    style={{ backgroundColor: "#f50057", color: "#FFFFFF" }}
                    onClick={handleClose}
                    variant="contained"
                >
                    Annuler
                </Button>
            </Grid>
        </Grid>
    );
}
export default Userhierarchie;