import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Spacer from "../../components/Spacer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddressService from "../../services/AddressService";

import { MenuItem, FormControl, Select, InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition1: {
    float: 'left',
  },
  labelPosition: {
    float: 'right',
  },
  text: {
    width: 370,
  }
}));

export default function Address(props) {
  const classes = useStyles();
  const [form, setForm] = useState({ id_direction: "", id_region: "", id_district: "", id_commune: "", id_fokontany: "" });
  const [addressFinal, setAddressFinal] = useState("");
  const [province, setProvince] = useState([]);
  const [region, setRegion] = useState([]);
  const [district, setDistrict] = useState([]);
  const [commune, setCommune] = useState([]);
  const [fokontany, setFokontany] = useState([]);
  const [nomFokontany, setNomFokontany] = useState('');
  const [gps, setGps] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(false);

  useEffect(() => {
    AddressService.getProvince().then((result) => {
      if (result && result.data) {
        setProvince(result.data);
      }
    });
  }, []);

  function handleChangeDirectionValue(event) {
    const { name, value } = event.target;
    setForm({ ...form, id_direction: value });
    AddressService.getRegion(value).then((result) => {
      if (result && result.data) {
        setRegion(result.data);
      }
    });
  }

  function handleChangeRegionValue(event) {
    const { name, value } = event.target;
    setForm({ ...form, id_region: value });
    AddressService.getDistrict(value).then((result) => {
      if (result && result.data) {
        setDistrict(result.data);
      }
    });
  }

  function handleChangeDistrictValue(event) {
    const { name, value } = event.target;
    setForm({ ...form, id_district: value });
    AddressService.getCommon(value).then((result) => {
      if (result && result.data) {
        setCommune(result.data);
      }
    });
  }

  function handleChangeCommuneValue(event) {
    const { name, value } = event.target;
    setForm({ ...form, id_commune: value });
    AddressService.getFokontany(value).then((result) => {
      if (result && result.data) {
        setFokontany(result.data);
      }
    });
  }

  function handleChangeFokontanyValue(event) {
    const { name, value } = event.target;
    setIsValidAddress(true);
    AddressService.getNomFokontany(value).then((result) => {
      setNomFokontany(result.data);
    });
  }

  function setAddress(event) {
    const { name, value } = event.target;
    setAddressFinal(value + " " + nomFokontany);
  }

  return (
    <>
      <Grid item xs={props.x1}>
        <label className={classes.labelPosition1}>Adresse*</label>
      </Grid>
      <Grid item xs={props.x2}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="demo-simple-select-outlined-label">Province</InputLabel>
          <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Province" name="id_direction" onChange={handleChangeDirectionValue}>
            {province.map((items) => (
              <MenuItem value={items.id_direction}>{items.nom_direction}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Spacer y={3} />
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="demo-simple-select-outlined-label">Région</InputLabel>
          <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Région" name="id_region" onChange={handleChangeRegionValue}>
            {region.map((items) => (
              <MenuItem value={items.id_region}>{items.nom_region}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Spacer y={3} />
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="demo-simple-select-outlined-label">District</InputLabel>
          <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="District" name="id_district" onChange={handleChangeDistrictValue}>
            {district.map((items) => (
              <MenuItem value={items.id_district}>{items.nom_district}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Spacer y={3} />
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="demo-simple-select-outlined-label">Commune</InputLabel>
          <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Commune" name="id_commune" onChange={handleChangeCommuneValue}>
            {commune.map((items) => (
              <MenuItem value={items.id_commune}>{items.nom_commune}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Spacer y={3} />
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="demo-simple-select-outlined-label">Fokontany</InputLabel>
          <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Fokontany" name="id_fokontany" onChange={handleChangeFokontanyValue}>
            {fokontany.map((items) => (
              <MenuItem value={items.id_fokontany}>{items.nom_fokontany}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Spacer y={3} />
        <TextField fullWidth id="outlined-basic" size="small" label="Logement" variant="outlined" name={props.name} value={props.value} onChange={props.onChange} onBlur={setAddress} />
        <Spacer y={3} />
        <TextField fullWidth id="outlined-basic" size="small" variant="outlined" value={addressFinal} disabled/>

        {props.isAdressNull && (
          <>
            <Spacer y={1}></Spacer>
            <Alert severity="error">L'adresse est obligatoire!</Alert>
          </>
        )}
        <Spacer y={3} />
        {/* {!isValidAddress && <Alert severity="error">Veuillez remplir ce champ</Alert>}
                {isValidAddress && <Alert severity="success">Adresse valide</Alert>} */}
      </Grid>
      <Spacer y={3} />
      <Grid item xs={props.x1}>
        <label className={classes.labelPosition1}>GPS</label>
      </Grid>
      <Grid item xs={props.x2}>
        <TextField fullWidth id="outlined-basic" size="small" variant="outlined" name={props.gpsName} value={props.gpsValue} onChange={props.onChangeGps} />

      </Grid>
    </>
  );
}
