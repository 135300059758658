import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../../../components/Title";
import { Done, Clear } from "@material-ui/icons";
import { DataGrid } from '@material-ui/data-grid';
import PlanningMissionService from "../../../services/PlanningMissionService";
import ConfirmationDialog from "../../tools/ConfirmationDialog";
import Message from "../../tools/Message";

// 
import { userDetailContext } from "../../../App";
import {useContext} from "react";
import Spacer from "../../../components/Spacer";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { useNavigate } from 'react-router-dom';
// 

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function PlanningMissionTable() {
  const navigate = useNavigate();

  const contextData = useContext(userDetailContext);
  const [rows, setRows] = useState([]);
  const [id_to_delete, setId_to_delete] = useState(0);
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog: 'Voulez vous vraiment supprimer  ?',
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  
  //Samuela: paramètrage pour le loader
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    PlanningMissionService.getPlanning().then((result) => {
      if(result && result.data){
        setRows(result.data?.map((row)=>({...row, id:row.mission_planning_id})));
        setOpenLoadingSimpleBackdrop(false);
      }else{
        setOpenLoadingSimpleBackdrop(false);
      }
    });
  }, []);

  function closeDialog(){
    setConfirmationDialog({...confirmationDialog, openDialog: false});
  }

  function handleCloseMessage(){
    setMessage({...message, open: false});
  }

  function closeDialog(){
    setConfirmationDialog({...confirmationDialog, openDialog: false});
  }
  function confirmDialog(){
    setConfirmationDialog({...confirmationDialog, openDialog: false});
    PlanningMissionService.deleteMissionPlanning(id_to_delete).then((result) => {
      PlanningMissionService.getPlanning().then((result) => {
        if(result && result.data){
          setRows(result.data?.map((row)=>({...row, id:row.mission_planning_id})));
        }
      });
      if(result.status){
        setMessage({...message, open: true, color: 'green', messageInfo: result.message});
        navigate("/mission/planning_mission");
      }else{
        setMessage({...message, open: true, color: 'red', messageInfo: result.message});
      }
    }).catch((err) => {
      setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
    });
  }

  function cancelDialog(){
    setConfirmationDialog({...confirmationDialog, openDialog: false});
  }

  const columns = [
    // { field: 'number', headerName: 'N°', width: 200, },
    { field: 'location', headerName: 'Direction', width: 200, },
    { field: 'user_name', headerName: 'Intervenant(s)', width: 250, },
    { field: 'mission_title', headerName: 'Intitulé', width: 200, },
    { field: 'day_number', headerName: 'Nombre du jour', width: 200, },
    { field: 'date', headerName: 'Date', width: 200, },
    { field: 'status', headerName: 'Statut', width: 200, },
    { field: 'tdr_agenda', headerName: 'TDR/Agenda', 
    renderCell: (params) => {
      return (
        <strong>
          <Button>
            {params.row.isTDRExist==1 && <Done style={{ color: "green" }} />}
            {params.row.isTDRExist==0 && <Clear style={{ color: "red" }} />}
          </Button>
          <Button>
            {params.row.isAgendaExist==1 && <Done style={{ color: "green" }} />}
            {params.row.isAgendaExist==0 && <Clear style={{ color: "red" }} />}
          </Button>
        </strong>
      );
    },
    width: 200, },
  ];
  
  return (
    <React.Fragment>
      <ConfirmationDialog openDialog={openDialog} messageDialog={messageDialog} closeDialog={closeDialog} confirmDialog={confirmDialog} cancelDialog={cancelDialog} />
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
      <Title>Planifications</Title>
      <Spacer y={2} />
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} />
      </div>
    </React.Fragment>
  );
}
