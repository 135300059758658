import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  Button,
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataAdmin } from "../../data/modules/admin-header";
import DirectionService from "../../services/DirectionService";
import { useLocation } from 'react-router-dom';
import Result from "../personnel/Result";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../utils/privilege";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";

const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

function DirectionAdd(props) {

  
  const params = useParams();
  const idDir = params.id;

  const classes = useStyles();

  const [form, setForm] = useState({
    id: "",
    type: "Direction",
    name: "",
    code: "",
    desc: "",
    d_id: 0,
  });

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [open, setOpen] = React.useState(false);
  const [etats, setEtats] = useState({ open: false, message: "message", color: "" });
  const navigate = useNavigate();

  const handleClickOpen = () => {
    navigate("/direction/add/");
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/direction");
  };

  const handleCloseMessage = () => {
    setEtats({ ...etats, open: false });

  };

  useEffect(() => {
    const idGroup = splitLocation[3];
    if (idDir) {
      DirectionService.directionById(idGroup).then((direction) => {
        setForm({
          id: direction.data.direction_id,
          type: direction.data.type,
          name: direction.data.name,
          code: direction.data.code,
          desc: direction.data.description,
          d_id: direction.data.direction_parent_id,
        });
      });
    }
  }, [setForm]);


  function handleClick() {
    DirectionService.saveDirection(form.type, form.name, form.code, form.desc, form.d_id, form.id)
      .then((results) => {
        if (results.status) {
          setEtats({ ...etats, open: true, color: 'green', message: results.message });
          navigate("/direction");
        }
        else {
          setEtats({ ...etats, open: true, color: 'red', message: results.message });
        }
      })
      .catch((err) => {
        alert("error");
      });
  }
  function CapitalString(str) {
    var nextCapital = true;
    var newString = "";
    for (var i = 0; i < str.length; i++) {
      var cur = str.charAt(i);
      if (nextCapital) {
        newString += cur.toUpperCase();
      } else {
        newString += cur.toLowerCase();
      }
      if (cur === " ") {
        nextCapital = true;
      } else {
        nextCapital = false;
      }
    }
    return newString;
  };

  function handleChange(ev) {
    const { name, value } = ev.target;
    console.log(name, "*************", value);
    if (name == 'code') {
      setForm({ ...form, [name]: value.toUpperCase() })
    }
    else if (name == 'name') {
      setForm({ ...form, [name]: CapitalString(value) })
    }
    else {
      setForm({ ...form, [name]: value })

    }
  }
  const contextData = useContext(userDetailContext);
  function handleCancel() {
    navigate("/direction");
  }
  return (
    <MainContainer {...headerDataAdmin}>
      <Result message={etats.message} color={etats.color} open={etats.open} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        {hasPrivilege(contextData.privileges, 'Admin-Button-Save-Direction') &&
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            ENREGISTRER
          </Button>
        }
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Nom{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Nom"
              variant="outlined"
              onChange={handleChange}
              inputProps={{ textTransform: 'capitalize', }}
              value={form.name}
              name="name"
            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>Code{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Code"
              variant="outlined"
              onChange={handleChange}
              inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
              value={form.code}
              name="code"

            />
          </Grid>

          <Grid item xs={4}>
            <label className={classes.labelPosition}>Description</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="outlined-multiline-static"
              // label="Multiline"
              multiline
              rows={3}
              size="small"
              label="Description"
              className={classes.text}
              variant="outlined"
              onChange={handleChange}
              value={form.desc}
              name="desc"
            />
          </Grid>
          <Grid item xs={12}>
            <label className={classes.text}>({bullRed}):Champs obligatoires</label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default DirectionAdd;
