import React from "react";
import { styled, alpha } from '@mui/material/styles';
import { Grid, Paper } from "@material-ui/core";
import Menu from '@mui/material/Menu';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MainContainer from '../../components/MainContainer';
import { headerDataState } from '../../data/modules/custom-states-header';
import './styles.css';
import ActualBalanceTable from "./ActualBalanceTable";
import LeavePerWeek from "./LeavePerWeek";
import BalanceValidation from "./BalanceValidation";
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
export default function CustomStates() {

  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function handleMenu1() {
    navigate("/actual_balance");
  }
  function handleMenu2() {
    navigate("/leave_week");
  }
  function handleMenu3() {
    navigate("/leave_last_week");
  }
  function handleMenu4() {
    navigate("/leave_next_week");
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <MainContainer  {...headerDataState}>


      <Grid container item xs={12}>
        <div className="App">
          <h1>Etats personalisés</h1>
          <Tabs>
            <TabList>
              <Tab>
                <p>Soldes congés restants</p>
              </Tab>
              <Tab>
                <p>Congé par semaine</p>
              </Tab>
              <Tab>
                <p>Etats validation</p>
              </Tab>

            </TabList>

            <TabPanel>
              <div className="panel-content">

                <Paper className={classes.paper} variant="outlined">
                  <ActualBalanceTable />
                </Paper>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="panel-content">
                <LeavePerWeek />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="panel-content">
                <BalanceValidation/>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="panel-content">
                <h2>Any content 4</h2>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="panel-content">
                <h2>Any content 5</h2>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Grid>
    </MainContainer>
  )
}