import React from "react";
import { useState, useEffect, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import ImmoList from "./ImmoList";
import ExtratList from "./ExtratList";
import { AntTab, AntTabs } from "../../components/ant-tab";
import AppBar from "@material-ui/core/AppBar";
import ImmobilisationService from "../../services/ImmobilisationService";
import ImportExcel from "./ImportExcel";
import GenerateQrCode from "./GenerateQrCode";
import * as FileSaver from "file-saver";
import { useNavigate } from "react-router-dom";
import ExportExcel from "./ExportExcel";
import TextField from "@material-ui/core/TextField";
import EquipmentCategoryService from "../../services/EquipmentCategoryService";
import EquipmentRankingService from "../../services/EquipmentRankingService";
import { useLocation } from "react-router-dom";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  tab1: {
    width: "50%",
  },
}));

export default function ImmobilisationList() {
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getFullYear() +
    "/" +
    (myCurrentDate.getMonth() + 1) +
    "/" +
    myCurrentDate.getDate();

  const [dataToExport, setDataToExport] = useState([]);
  const [fileName, setFileName] = useState("Code Immo matériel " + date);
  const [funding, setFunding] = useState([]);
  localStorage.removeItem("verify");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpen2, setIsOpen2] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [openGenerateQrCode, setopenGenerateQrCode] = React.useState(false);
  const [closeGenerateQrCode, setcloseGenerateQrCode] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [valueType, setValueType] = React.useState(0);
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [directions, setDirection] = useState([]);
  const [listOfCategory, setCategoryList] = useState([]);
  const [category, setCategory] = useState([]);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [form, setForm] = useState({
    name: "",
    code: "",
    date1: "",
    date2:"",
    funding: "",
    dir: "",
  });
  
  

  useEffect(() => {
    EquipmentCategoryService.getAllEquipmentCategory().then((res) => {
      if (res && res.status) {
        setCategoryList(res.data);
        console.log(directions);
      } 
    });

    const idGroup = splitLocation[4];
    ImmobilisationService.direction().then((result) => {
      if (result && result.data) {
        setDirection(result.data);
      }
    });
    ImmobilisationService.funding().then((result) => {
      if (result && result.data) {
        setFunding(result.data);
      }
    });
    
}, []);


  const openModal = () => {
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


    //modif handlechange
   const handleChanges = (e) => {

    const { name, value } = e.target;
    if (localStorage.getItem(name)) {
      
    }
    setForm({ ...form, [name]: value });
    };
    const setValeurChamps = (name)=>{
      const valeurLocalStorage = localStorage.getItem(name);
      console.log("valeur champs sont : "+valeurLocalStorage)
      return valeurLocalStorage;
    }
    const SetFilter = (name,value)=>{
      if(!value){
        if(localStorage.getItem(name)){
          localStorage.setItem(name,localStorage.getItem(name));
        }
      }
      else{

        localStorage.setItem(name,value);
      }
     
    }

    //


    
  const [message, setMessage] = useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    
      SetFilter('name',form.name);
      SetFilter('date1',form.date1);
      SetFilter('date2',form.date2);
      SetFilter('funding',form.funding);
      SetFilter('code',form.code);
      SetFilter('dir',form.dir);
     
      //navigate('/Logistic/immobilisations');
      window.location.reload();
    //}
    
  };
  const Refresh = (e) => {
    e.preventDefault();
      localStorage.removeItem('name');
      localStorage.removeItem('code');
      localStorage.removeItem('date1');
      localStorage.removeItem('date2');
      localStorage.removeItem('funding');
      localStorage.removeItem('dir');
      localStorage.setItem('name',"");
      localStorage.setItem('code',"");
      localStorage.setItem('date1',"");
      localStorage.setItem('date2',"");
      localStorage.setItem('funding',"");
      localStorage.setItem('dir',"");
      
      window.location.reload()
    
    //}
    
  };
  function isSet(){
    const name = localStorage.getItem('name');
    const code = localStorage.getItem('code');
    const date1 = localStorage.getItem('date1');
    const date2 = localStorage.getItem('date2');
    const fund = localStorage.getItem('funding');
    const dir = localStorage.getItem('dir');
    if (name !=="" || code !==""  || date1 !==""  ||date2 !==""  ||fund !=="" ||dir !=="" ) {
      return true;
      
    }
    return false;
  }
  function handleClick() {
    navigate("/Logistic/immo/add");
  }

  function handleCloseDialog() {
    setIsOpen(false);
  }

  function handleCloseDialog2() {
    setIsOpen2(false);
  }

  function handleClickImport() {
    setIsOpen(true);
  }

  function handleClickExport() {
    setIsOpen2(true);
  }

  function handleChangeRadioButtonForTypeImmo(e) {
    setValueType(e);
  }

  function handleClickGenerateQrCode() {
    setopenGenerateQrCode(true);
  }

  function cancelGenerateQrCode() {
    setopenGenerateQrCode(false);
  }
  

  function confirmGenerateQrCode() {
    setIsLoading(true);
    ImmobilisationService.generateQrCode(valueType).then((result) => {
      if (result && result.status) {
        ImmobilisationService.readFile(result.pdfGenerated, valueType).then(
          (res) => {
            if (res) {
              console.log("ato ve", res);
              console.log("ato ve", valueType);
              if (valueType == 1) {
                console.log("tokony miditra ato ve", valueType);
                const blob = new Blob([res.data], {
                  type: "application/pdf",
                });
                const fileURL = URL.createObjectURL(blob);
                const w = window.open(fileURL, "_blank");
                w && w.focus();
                URL.revokeObjectURL(fileURL);
                setopenGenerateQrCode(false);
                setIsLoading(false);
              } else if (valueType == 0) {
                //valueType==0 pour l'immo et 1 pour l'extrat
                const blob = new Blob([res.data], {
                  type: "application/zip",
                });

                FileSaver.saveAs(blob, "immo_qrCode.zip");
                setopenGenerateQrCode(false);
                setIsLoading(false);
              }
            }
          }
        );
      } else {
        setIsLoading(false);
        setopenGenerateQrCode(false);
      }
    });
  }

  function handleChangeRadioButtonForTypeImmo(e) {
    setValueType(e.target.value);
  }
 
  return (
    <MainContainer {...headerDataImmo}>
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          CREER
        </Button>
        <Spacer x={2} />



        <Button
          variant="contained"
          onClick={handleClickImport}
          style={{ fontWeight: 600 }}
        >
          IMPORTER
        </Button>
        <Spacer x={2} />

       {/* <Button
          variant="contained"
          onClick={handleClickImport}
          style={{ fontWeight: 600 }}
        >
          Importer2
        </Button>
        <Spacer x={2} />*/} 

        <Button
          variant="contained"
          onClick={handleClickExport}
          style={{ fontWeight: 600 }}
        >
          EXPORTER
        </Button>
        
        <Spacer x={2} />
        
              <Button
                  variant="contained"
                  onClick={Refresh}
                  style={{ fontWeight: 600, backgroundColor:'darkgreen', color:'white' }}
                >
                  réinitialiser
                </Button>
                <Spacer x={2} />
                <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ fontWeight: 600}}
              >
                Filtrer
              </Button>
      </Grid>
      {/* ajout formulaire :narisolo  */}
      <Spacer y={4} /> 
      <Grid container xs={12} spacing={2} >
           <Grid item xs={12} sm={6} md={4}>
           <label className={classes.labelPosition}>
                  Matériel:
                </label>
                <Spacer y={0.2}></Spacer>
            <TextField
              
              id="outlined-basic"
              size="small"
              variant="outlined"
              defaultValue={setValeurChamps('name')}
              name="name"
              type="text"
              onChange={handleChanges}
              style={{ width: '300px' }}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={4} >
        <label className={classes.labelPosition}>
                  Code:
                </label>
                <Spacer y={0.2}></Spacer>
            <TextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              defaultValue={setValeurChamps('code')}
              name="code"
              type="text"
              onChange={handleChanges}
              style={{ width: '300px'}}
            />
        </Grid>
       
      
            
         
          <Grid item xs={12} sm={6} md={4} >
            <label className={classes.labelPosition}>
                  Date début:
                </label>
                <Spacer y={0.2}></Spacer>
                <TextField
                  
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  defaultValue={setValeurChamps('date1')}
                  name="date1"
                  type="date"
                  style={{ width: '300px'}}
                  onChange={handleChanges}
                />
                
          </Grid>
       
          <Grid item xs={12} sm={6} md={4}>
          <label className={classes.labelPosition}>
                  Financement:
                </label>
            <FormControl fullWidth variant="outlined" size="small">
             
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={setValeurChamps('funding')}
                name="funding"
                style={{ width: '300px'}}
                onChange={handleChanges}
              >
                
                {funding.map((row) => (
                  <MenuItem value={row.funding_id}>{row.code}</MenuItem>
                ))}
              </Select>
            </FormControl>
           
          </Grid>
          <Grid item xs={12} sm={6} md={4} >
        <label className={classes.labelPosition}>
                  Direction:
                </label>
        <FormControl fullWidth variant="outlined" size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={setValeurChamps('dir')}
                label="dir"
                name="dir"
                style={{ width: '300px'}}
                onChange={handleChanges}
              >
                {directions.map((row) => (
                  <MenuItem value={row.direction_id}>
                    {row.name}({row.code})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </Grid>
        
          <Grid item xs={12} sm={6} md={4}  >
            <label className={classes.labelPosition}>
                  Date fin:
                </label>
                <Spacer y={0.2}></Spacer>
                <TextField
                  
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  defaultValue={setValeurChamps('date2')}
                  name="date2"
                  type="date"
                  style={{ width: '300px'}}
                  onChange={handleChanges}
                />
                
          </Grid>
        <Grid item xs={12} sm={6} md={4} >

        </Grid>
        <Grid item xs={12} sm={6} md={4} >

        </Grid>
        
          <Grid item xs={12} sm={6} md={4} >
             
          

           </Grid>
         
            
          
      </Grid>
  

      <Spacer y={4} />
      <Grid container xs={12}>
        <AppBar position="static" color="default">
          <AntTabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <AntTab disableRipple label="Immo" className={classes.tab1} />
            <AntTab label="Extrat" className={classes.tab1} />
          </AntTabs>
        </AppBar>
      </Grid>
      <Spacer y={2} />
     
   
    {value === 0 && (
        <Grid item xl={12}>
          <Paper className={classes.paper} variant="outlined">
            <ImmoList />
          </Paper>
        </Grid>
      )}
      {value === 1 && (
        <Grid item xl={12}>
          <Paper className={classes.paper} variant="outlined">
            <ExtratList />
          </Paper>
        </Grid>
      )}
      <Spacer y={2} />
      <ExportExcel
        dataToExport={dataToExport}
        fileName={fileName}
        open={isOpen2}
        closeDialog={handleCloseDialog2}
      />

      <ImportExcel open={isOpen} closeDialog={handleCloseDialog} modif={2} />
      
      <GenerateQrCode
        openDialog={openGenerateQrCode}
        closeDialog={closeGenerateQrCode}
        handleChange={handleChangeRadioButtonForTypeImmo}
        value={valueType}
        cancelDialog={cancelGenerateQrCode}
        confirmDialog={confirmGenerateQrCode}
        isLoading={isLoading}
      />
    </MainContainer>
    
  );
}
