import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const EquipmentCategoryService = {
    async save(account_code, name_category, desc_category) {
        try {
            const result = await axios.post(`${apiUrl}/equipmentCategory`,
                {
                    account_code,
                    name_category,
                    desc_category,


                }
            );
            if (result) {
                return result;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getAllEquipmentCategory() {
        try {
            const result = await axios.get(`${apiUrl}/equipmentCategory/`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async update(account_code, name_category, desc_category, id) {
        try {
            const result = await axios.put(`${apiUrl}/equipmentCategory/${id}`,
                {
                    account_code, name_category, desc_category,
                }
            );
            if (result) {
                return result;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getEquipmentCategoryById(id) {
        try {
            const result = await axios.get(`${apiUrl}/equipmentCategory/id/${id}`);
            if (result) {
                // TODO: replace result.data.message by the token

                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async deleteEquipmentCategory(id) {
        try {
            const result = await axios.delete(`${apiUrl}/EquipmentCategory/id/${id}`);
            if (result) {
                // TODO: replace result.data.message by the token

                return result.data;
            }
        } catch (error) {
            if (error.response) {
                throw new Error(error.response.data);
            }
            throw error;
        }
    },
}
export default EquipmentCategoryService;