import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import { useState } from "react";
import Copyright from "../../components/Copyright";
import ResetPassService from "../../services/ResetPassService";
import useStyles from "./resetpassword.styles";
import Loading from "../../components/loading";

function ResetPassword() {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [form, setForm] = useState({});
  const [isEmailSent, setEmailSent] = useState(false);
  const [failedResetPass, setFailedResetPass] = useState(false);

  // Samuela: set loading while fetching api data
  const [isLoading, setLoading] = useState(false); 

  function handleSubmit(event) {
    event.preventDefault();
    setFailedResetPass(false);

    setLoading(true);

    ResetPassService.resetPassword(form.email)
      .then((res) => {
        var responseErrorMessage = res.message;
        if (res.status) {
          setSuccessMessage('Un email de réinitialisation vous est envoyé! Veuillez le consulter!');
          setEmailSent(true);
          setLoading(false);
        } else {
          setFailedResetPass(true);
          setErrorMessage(responseErrorMessage);
          setLoading(false);

        }
      })
      .catch((err) => {
        setFailedResetPass(true);
        setErrorMessage("Une erreur s'est produite. Veuillez réessayer");
      });
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  function handleFocusEmail() {
    setFailedResetPass(false);
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.logo}>
        </div>
        <div className={classes.divCenter}>
          <div className={classes.alert}>
            Veuillez entrer votre adresse email pour recevoir un lien de réinitialisation
            de mot de passe confirmation
          </div>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              type="email"
              error={failedResetPass}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse e-mail"
              name="email"
              autoComplete="email"
              onChange={handleChange}
              autoFocus
              value={form.email || ""}
              onFocus={handleFocusEmail}
            />
            {/* Samuela: set loading while fetching api data */}
            {isLoading && <Loading />}

            {failedResetPass && <Alert severity="error">{errorMessage}</Alert>}

            {isEmailSent && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isLoading}
            >
              Envoyer
            </Button>
          </form>
        </div>
      </div>
      <Box mt={6}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default ResetPassword;
