import React, { useEffect, useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FamilyMemberService from "../../../services/FamilyMemberService";
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';
import AttachFile from '@material-ui/icons/AttachFile';
import { Button} from "@material-ui/core";
import Message from "../../tools/Message";
import Alert from "@material-ui/lab/Alert";
import ServiceService from "../../../services/ServiceService";
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)
const USER = "USER";

const useStyles = makeStyles((theme) => ({
  snackbarStyleViaNestedContent: {
    backgroundColor: "red",
    color: "white"
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition1: {
    float: 'left',
  },
  labelPosition: {
    float: 'right',
  },
  text: {
    width: 370,
  }
}));
const Input = styled('input')({
  display: 'none',
});
export default function Contract(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [form, setForm] = useState({
    filename: "",
    uploader_user_id: JSON.parse(localStorage.getItem("contract")),
    begin_date: "",
    end_date: "",
    desc: "",
    user_id: JSON.parse(localStorage.getItem("contract"))
  });
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  const [isBegin_dateEmpty, setIsBegin_dateEmpty] = useState(false);
  const [isEnd_dateEmpty, setIsEnd_dateEmpty] = useState(false);
  const [isFilenameEmpty, setIsFilenameEmpty] = useState(false);

  function handleChangeFormValue(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }

  const [nameFile, setNameFile] = useState('(PJ concernant le contrat)');
  const [selectedFile, setSelectedFile] = useState(null);

  function handleChangeFile(event) {
    setSelectedFile(event.target.files[0]);
    setNameFile(event.target.files[0].name);
    setForm({ ...form, filename: event.target.files[0].name });
  }

  function handleClick() {
    console.log("CONTRACT FORM DATA === ", form);
    if (form.filename === '') {
      setIsFilenameEmpty(true);
    }
    if (form.begin_date === '') {
      setIsBegin_dateEmpty(true);
    }
    if (form.end_date === '') {
      setIsEnd_dateEmpty(true);
    }
    if (form.filename !== '' && form.begin_date !== '' && form.end_date !== '') {
      const formData = new FormData();
      formData.append(
        "filename",
        selectedFile,
        selectedFile.name
      );
      FamilyMemberService.uploadFile(formData).then((result) => {
        if (result.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          FamilyMemberService.saveContract(form).then((result) => {
            if (result.status) {
              setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
              navigate("/personnel/add/autres");
              window.location.reload();
            } else {
              setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
            }
          })
            .catch((err) => {
              setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
            });
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
        }
      })
        .catch((err) => {
          setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
        });
    }
  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  useEffect(() => {
    const serviceId = JSON.parse(localStorage.getItem("service"));
    
    console.log(
        "storage contract: ", JSON.parse(localStorage.getItem("contract")),
        "storage service : ", JSON.parse(localStorage.getItem("service"))
    );

    ServiceService.getServiceById(serviceId).then((res) => {
      if (res && res.status) {
        const direction_id = res.data.direction_id;
        console.log("direction_id*********", direction_id);
        setForm({ ...form, direction: direction_id });
      }
    });

  }, []);
  return (
    <Grid container spacing={3} className={classes.paper} variant="outlined">
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid item xs={4}>
        <label className={classes.labelPosition1}>Date début du contrat{bullRed}</label>
      </Grid>
      <Grid item xs={8}>
        <TextField fullWidth className={classes.text} id="outlined-basic" size="small" variant="outlined" type="date" name="begin_date" value={form.begin_date} onChange={handleChangeFormValue} />
        {isBegin_dateEmpty && <Alert severity="error">Veuillez remplir ce champ</Alert>}
      </Grid>
      <Grid item xs={4}>
        <label className={classes.labelPosition1}>Date fin du contrat{bullRed}</label>
      </Grid>
      <Grid item xs={8}>
        <TextField fullWidth className={classes.text} id="outlined-basic" size="small" variant="outlined" type="date" name="end_date" value={form.end_date} onChange={handleChangeFormValue} />
        {isEnd_dateEmpty && <Alert severity="error">Veuillez remplir ce champ</Alert>}
      </Grid>
      <Grid item xs={4}>
        <label className={classes.labelPosition1}>Pièce jointe{bullRed}</label>
      </Grid>
      <Grid item xs={8}>
        <label htmlFor="icon-button-file">
          <Input accept="" id="icon-button-file" type="file" name="filename" onChange={handleChangeFile} />
          <IconButton aria-label="upload picture" component="span" >
            <AttachFile />
          </IconButton>
        </label>
        <label>
          {nameFile}
        </label>
        {isFilenameEmpty && <Alert severity="error">Veuillez télécharger le fichier concerné</Alert>}
      </Grid>
      <Grid item xs={4}>
        <label className={classes.labelPosition1}>Description</label>
      </Grid>
      <Grid item xs={8}>
        <TextField multiline className={classes.text} width="auto" rows={3} rowsMax={5} id="outlined-basic" size="small" label="Description" variant="outlined" value={form.desc} name="desc" onChange={handleChangeFormValue} />
      </Grid>
      <Grid item xs={12}>
        <Button className={classes.labelPosition} variant="contained" color="primary" onClick={handleClick} >Enregistrer</Button>
      </Grid>
    </Grid>
  );
}
