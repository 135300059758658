
import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { CircularProgress, Grid } from "@material-ui/core";
import { TableContainer } from "@material-ui/core";
import { headerData } from '../../data/modules/my-validations-header';
import MainContainer from '../../components/MainContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import PlanningService from "../../services/PlanningService";
import TableRow from '@material-ui/core/TableRow';
import { useLocation } from 'react-router-dom';
import UserService from "../../services/UserService";
import moment from 'moment'

import Box from '@mui/material/Box';
import Message from "../tools/Message";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import {
  NavigateNext,
  NavigateBefore,
  Check,
  Close
} from "@material-ui/icons";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TraceabilityService from "../../services/TraceabilityService";
import ValidationPlanningService from "../../services/ValidationPlanningService";
import RefusedPlanning from "../tools/RefusedPlanning";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { useParams } from "react-router-dom";

import clsx from "clsx";
import Timeline from "./Timeline";
import Slide from '@mui/material/Slide';
import Dialog from "@material-ui/core/Dialog";


import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Spacer from "../../components/Spacer";
import ValidatePlanningDialog from "../tools/ValidatePlanningDialog";
import LeaveService from "../../services/LeaveService";
import FileService from "../../services/FileService";
import * as FileSaver from "file-saver";
import ValidatePlanningRequest from "../tools/ValidatePlanningRequest";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const bullGreen = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: 'green', }}
  >

  </Box>

);
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', backgroundColor: 'red' }}
  >

  </Box>

);
const bullBlue = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', backgroundColor: '#6ACFF6' }}
  >

  </Box>

);
const bullBlueEdited = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', backgroundColor: '#133E67' }}
  >

  </Box>

);
const bullBlueYellow = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: 'linear-gradient(to right,#045DE9,yellow )' }}
  >

  </Box>

);

const bullBeige = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: '#FAF8EB', border: '0.5px solid grey' }}
  >

  </Box>

);
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const useStyles = makeStyles((theme) => ({
  refusedPlanning: {
    width: "auto",
  },
  validatePlanning: {
    width: "auto",
  },
  mois: {
    textAlign: "center",
    fontSize: "22px",
  },
  bouton: {
    justifyContent: "center",
    display: "block"
  },
  root: {
    borderTopWidth: 1,
    borderColor: 'black',
    borderStyle: 'outset',
    //Width: "auto",
    textAlign: "center",
    marginLeft: "14em",
    borderCollapse: "separate",
  },
  planningEdited: {
    backgroundColor: '#133E67',
    Width: "auto",
    textAlign: "center !important",
    padding: "6px 15px 6px 15px !important",
    verticalAlign: "middle"
  },
  roots: {
    backgroundColor: '#3CA3FC',
    Width: "auto",
    textAlign: "center !important",
    padding: "6px 15px 6px 15px !important",
    verticalAlign: "middle"
  },
  rootss: {
    background: "linear-gradient(to left, yellow 10%,#045DE9 90%);",
    Width: "auto",
    textAlign: "center !important",
    padding: "6px 15px 6px 15px !important",
    verticalAlign: "middle"
  },
  confirmed:
  {
    backgroundColor: 'green',
    Width: "auto",
    textAlign: "center",
  },
  refused:
  {
    backgroundColor: 'red',
    Width: "auto",
    textAlign: "center",
  },
  h2: {
    textAlign: "center",
  },
  text: {
    textAlign: "center",
    textDecoration: "underline",
  },
  texts: {
    color: "red",
    textAlign: "center",

  },
  btn: {
    display: "block",
    minWidth: "10px !important",
    margin: "auto"
  },
  name: {


    textAlign: "left",
    Width: "auto",

    position: "absolute",
    visibility: "visible",
    marginLeft: "-14.5em",

  },
  name2: {


    textAlign: "center",
    Width: "auto",

    position: "absolute",
    visibility: "visible",
    marginLeft: "-14.5em",
    height: "12%",
    textDecoration: "underline",
    fontWeight: "bold",

  },
  demandeur: {
    width: "200px",
    borderLeft: "0.5px solid black",
  },
  submitterName: {
    width: "200px",
    height: "90px",
    backgroundColor: "#C5C5C5",
    borderLeft: "0.5px solid black"
  },
  divLegendeTimeline: {
    display: "flex",
    justifyContent: "center"
  },
  buttonGlobalView: {
    backgroundColor: '#A6A6A6',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgb(19, 62, 103)',
      color: '#fff',
    }
  },
  timelineGrid: {
    flexBasis: '0%',
  },
  btnValiderTous: {
    backgroundColor: 'rgb(19, 62, 103)',
    color: '#fff',
  },
  tableRow: {
    height: '90px',
  },
  dayNotCompted: {
    backgroundColor: '#FAF8EB',
    // opacity:"0.5",
  },
  tableContainerValidationPlanning: {
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
    [theme.breakpoints.up('md')]: {
      width: '1000px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1200px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '1350px',
    }
  },
  tableValidationPlanning: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '650px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '850px',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '1150px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '1200px',
    }
  }
}));
function ValidationPlanning(props) {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState([]);
  const [dateStart, setDateStart] = useState([]);
  const [dateEnd, setDateEnd] = useState([]);
  const [dir, setDir] = useState([]);
  const [service, setService] = useState([]);
  const [iddir, setIddir] = React.useState(0);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // const id_parameter = splitLocation[4];
  // const type = splitLocation[5];

  const params = useParams();
  const id_parameter = params.id;
  const type = params.type;

  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");
  const [difference_In_Days, setdifference_In_Days] = useState("");

  const classes = useStyles();
  // const [datestart, setDatestart] = useState(0);
  const [dateend, setDateend] = useState(0);
  const [month, setMonth] = useState([]);
  const [mois, setMois] = useState("");
  const [ligne, setLigne] = useState(0);
  const [max, setMax] = useState();
  const [totalDay, setTotalDay] = useState([]);
  const [jour, setJour] = useState(0);
  const [jours, setJours] = useState([]);
  const [planningFinal, setPlanningFinal] = useState([]);
  const [tableMonth, setTableMonth] = useState([]);
  const [id, setId] = useState(0);
  const [idMonth, setIdMonth] = useState([]);
  const [days, setDays] = useState([]);
  const [final, setFinal] = useState([]);
  const [numberdirection, setNumberdirection] = useState(0);
  const [numberservice, setNumberservice] = useState(0);
  const [aservice, setAservice] = useState([]);
  const [idservice, setIdservice] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [numberTable, setNumberTable] = useState();
  const [openRefuseStep1, setOpenRefuseStep1] = React.useState(false);
  const [idRequest, setIdRequest] = useState();
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [idMonthPlanning, setIdMonthPlanning] = useState();
  const [message, setMessage] = React.useState({
    openMessage: false,
    color: 'green',
    messageInfo: '',
  });
  const { openMessage, color, messageInfo } = message;
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    // setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [count, setCount] = useState(1);
  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([]);
  const [listPendingPlanningId, setListPendingPlanningId] = useState([]);
  const [listPendingPlanningFinal, setlistPendingPlanningFinal] = useState([]);
  const [listPendingPlanningUserId, setListPendingPlanningUserId] = useState([]);
  const [openDialogTimeline, setOpenDialogTimeline] = useState(false);
  const [openValidatePlanning, setopenValidatePlanning] = useState();
  const [value, setValue] = useState(1);

  const [semesterData, setSemesterData] = useState([]);
  const [periodMonth, setPeriodMonth] = useState([]);

  const [monthStart, setMonthStart] = useState("");
  const [monthEnd, setMonthEnd] = useState("");
  const [monthList, setMonthList] = useState([]);
  const [monthWithDayArray, setmonthWithDayArray] = useState([]);
  const [planningYear, setplanningYear] = useState("");
  const [isExportExcel, setisExportExcel] = React.useState(false);
  const [isValidateAll, setisValidateAll] = React.useState(false);
  const [isRefusedAll, setisRefusedAll] = React.useState(false);
  const [isTherePendingPlanning, setisTherePendingPlanning] = React.useState(false);
  const [openValidateRequest, setopenValidateRequest] = React.useState(false);
  const [moduleTypeId, setModuleTypeId] = React.useState(0);

  const handleClose = () => {
    setOpenDialogTimeline(false);
    setValue(1);
  };

  function loadtable(jour, mois, sa) {
    // setOpenLoadingSimpleBackdrop(true);
    PlanningService.Final(id_parameter, jour, mois, sa).then((results) => {
      if (results) {
        // console.log("resssssssss ID", results.table);
        setGroups(results.groups);
        setItems(results.items);
        setFinal(results.table);
        setListPendingPlanningId(results.listPlanningId);
        setListPendingPlanningUserId(results.listUserPlanningId);
        setNumberTable(results.data);
        // setOpenLoadingSimpleBackdrop(false);
      }
    });
  }

  useEffect(() => {
    setMonthList([]);
    PlanningService.getById(id_parameter).then((results) => {
      if (results && results.data) {
        setDateStart(results.data.date_start);
        setDateEnd(results.data.date_end);
        setMonthList(results.data.listMonth);
        var startTime = new Date(results.data.date_start_original);
        var endTime = new Date(results.data.date_end_original);
        var Difference_In_Time = endTime.getTime() - startTime.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        setMonthStart(startTime.getMonth() + 1);
        setMonthEnd(endTime.getMonth() + 1);
        setstartTime(startTime);
        setendTime(endTime);
        setplanningYear(startTime.getFullYear());
        setdifference_In_Days(Difference_In_Days);
      }
    });
    UserService.getUserInferior().then((results) => {
      if (results && results.data) {
        setFirstName(results.data);
      }
    });

    setisRefusedAll(false);

    setOpenLoadingSimpleBackdrop(true);
    PlanningService.Month(id_parameter).then((result) => {

      if (result && result.data) {
        setMonth(result.data);
        setIdMonth(result.month);
        setMois(result.data[0]);
        setMax(result.data.length - 1);
        setTotalDay(result.total);
        setJour(result.total[0]);
        jourcomplet(result.total[0]);
        setTableMonth(result.month);
        setId(result.month[0]);
        PlanningService.Service().then((results) => {

          if (results && results.service) {
            setService(results.service);
            setDir(results.direction);
            setIddir(results.iddir);
            setIdservice(results.idService);
            setNumberdirection(results.countdirection);
            setNumberservice(results.countservice);

            if (results.iddir == null) {
              setIddir(results.service[1]["direction_id"]);
              setIdservice(results.service[1]["service_id"]);
              loadtable(result.total[0], result.month[0], results.service[1]["service_id"]);

              PlanningService.Direction(results.service[1]["direction_id"]).then((results) => {
                if (results && results.data) {
                  setService(results.data);
                }
              })
            } else {
              loadtable(result.total[0], result.month[0], results.idService);
              setIdMonthPlanning(result.month[0]);
              PlanningService.Direction(results.iddir).then((results) => {
                if (results && results.data) {
                  setService(results.data);
                }
              })

            }

            setOpenLoadingSimpleBackdrop(false);
          }
        })
      }
    });

    if (id_parameter != undefined && id_parameter) {
      PlanningService.getById(id_parameter).then((result) => {
        if (result && result.status) {
          setModuleTypeId(result.data.module_type_id);
        }
      })
    }
    

    // setValue(2);

  }, [location]);

  // useEffect(() => {

  // }, [dateStart]);

  // useEffect(() => {
  //   if (items !== undefined && items !== null && items.length !== 0) {
  //     setOpenDialogTimeline(true);
  //   }
  // }, [items]);


  useEffect(() => {
    if (value === 2) {
      setOpenDialogTimeline(true);
    }
  }, [value]);


  useEffect(() => {
    if (listPendingPlanningId != undefined && listPendingPlanningId.length != 0) {
      setlistPendingPlanningFinal(listPendingPlanningId);
      setisTherePendingPlanning(true);
    } else {
      setisTherePendingPlanning(false);
    }
  }, [listPendingPlanningId]);

  useEffect(() => {
    setmonthWithDayArray([]);
    if (monthList !== undefined && monthList.length != 0) {
      monthList.map((item) => {
        LeaveService.getDayOfMonth(item.id).then((res) => {
          if (res && res.status) {
            var monthwithDayArrayLengthTemp = []
            monthwithDayArrayLengthTemp.id = item.id;
            monthwithDayArrayLengthTemp.name = item.name;
            monthwithDayArrayLengthTemp.dayArrayLength = res.data.length;
            // monthwithDayArrayLengthTemp.monthBefore = monthBefore;
            setmonthWithDayArray(oldArray => [...oldArray, monthwithDayArrayLengthTemp]);
          }
        });
      });
    }
  }, [monthList]);

  useEffect(() => {
    console.log("listPendingPlanningUserId", listPendingPlanningUserId);
    if (monthWithDayArray.length != 0 && monthWithDayArray.length == monthList.length && listPendingPlanningUserId.length != 0) {
      monthWithDayArray.map((item) => {
        // loadPlanningForAllUser(item.id, item.dayArrayLength, iddir, idservice, item.name, planningYear, listPendingPlanningUserId);
      });
    }
    
  }, [listPendingPlanningUserId]);

  

  useEffect(() => {
    if (groups.length != 0 && items.length!= 0) {
      console.log("groups.length**", groups.length);
      console.log("items.length**", items.length);
      setValue(2);
    }
  }, [groups, items]);


  // useEffect(() => {
  //   if (openRefuseStep1) {
  //     console.log("listeeeeeeeeeeeeee222222 pendiiiiiiii", listPendingPlanningFinal);
  //   }
  // }, [openRefuseStep1]);


  // useEffect(() => {
  //   // console.log("niova semesterData", semesterData);
  //   if (semesterData.length != 0 && semesterData.length == monthList.length) {
  //     const semesterDataSorted = [...semesterData].sort((a, b) => {
  //       return a.monthId - b.monthId;
  //     });
  //     setSemesterData(semesterDataSorted);
  //   }
  // }, [semesterData]);

  // console.log("month", idMonthPlanning);
  function jourcomplet($id) {
    const day = new Array();
    for (let index = 0; index < $id; index++) {
      day[index] = index + 1;
    }
    setJours(day);
  }

  function handleChangeDirection(event) {
    const { name, value } = event.target;
    setIddir(value);
    PlanningService.Direction(value).then((results) => {
      if (results && results.data) {
        setService(results.data);
      }
    })
  }
  function handleChangeService(ev) {
    const { name, value } = ev.target;
    setIdservice(value);
    loadtable(jour, id, value);
  }
  function handlenext() {
    const ma = ligne + 1;
    const ms = month[ma];
    setMois(ms);
    setLigne(ma);
    const next = totalDay[ma];
    setJour(next);
    jourcomplet(next);
    setId(tableMonth[ma]);
    loadtable(next, tableMonth[ma], idservice);

    setIdMonthPlanning(tableMonth[ma]);
  }

  function handlebefore() {
    const ma = ligne - 1;
    const ms = month[ma];
    setMois(ms);
    setLigne(ma);
    const before = totalDay[ma];
    setJour(before);
    jourcomplet(before);
    setId(tableMonth[ma]);
    loadtable(before, tableMonth[ma], idservice);
    setIdMonthPlanning(tableMonth[ma]);
  }

  function handleConfirmed(ids, user_id) {
    const validation_state = 0;
    const observation = null;
    ValidationPlanningService.confirmedVP(ids).then((res) => {
      TraceabilityService.updateTVP(
        observation,
        validation_state,
        res.data
      ).then((result) => {
        ValidationPlanningService.verification(user_id, id_parameter).then((r) => {
          if (r && r.data) {
            setMessage({ ...message, openMessage: true, color: 'green', messageInfo: "Succès" });
            // console.log("Conf1");
            loadtable(jour, id, idservice);
            // console.log("conf2");
          }
        })
      })
    })
  }

  const handleValidate = (UserId) => {

    ValidationPlanningService.getState(UserId, id_parameter, idMonthPlanning).then((res) => {
      if (res && res.data) {
        ValidationPlanningService.verification(UserId, id_parameter).then((r) => {
          if (r && r.data) {
            setMessage({ ...message, openMessage: true, color: 'green', messageInfo: "Succès" });
            loadtable(jour, id, idservice);
          }
        })
      }
    })
  }
  function handleRefused(id) {
    setIdRequest(id);
    setOpenRefuseStep1(true);
  }

  function handleRefusedTimeline() { // open motif dialog
    setopenValidatePlanning(null);
    setOpenRefuseStep1(true);
  }

  function handleConfirmRefused() {
    setOpenRefuseStep1(false);
    setMessage({ ...message, openMessage: true, color: 'green', messageInfo: "Succès" });
    loadtable(jour, id, idservice);
  }

  function handleConfirmRefusedTimeline() {
    setOpenRefuseStep1(false);
    setopenValidatePlanning(false);
    setMessage({ ...message, openMessage: true, color: 'green', messageInfo: "Succès" });
    loadtable(jour, id, idservice);
  }


  function handleConfirmValidateTimeline() {
    setOpenRefuseStep1(false);
    setopenValidatePlanning(false);
    setMessage({ ...message, openMessage: true, color: 'green', messageInfo: "Succès" });
    loadtable(jour, id, idservice);
  }

  const handleCancel = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setOpenRefuseStep1(false);
  }


  function handleCloseMessage() {
    setMessage({ ...message, openMessage: false });
  }

  function handleClickTimeline() {
    navigate("/TimelineView/" + groups + "/" + items);
  }

  var iter = 2;
  var isEnd = true;

  var iterWithType = 2;
  var isEndWithType = true;

  function renderCount() {
    return iter;
  }

  function bornSup() {
    return isEnd;
  }

  function setBornSup(param) {

    if (!isEnd) {
      iter++;
    }
    isEnd = param;
  }

  function initBornSup() {
    iter = 2;
    isEnd = true;
    return true;
  }

  function renderCountWithType() {
    return iterWithType;
  }

  function bornSupWithType() {
    return isEndWithType;
  }

  function setBornSupWithType(param) {

    if (!isEndWithType) {
      iterWithType++;
    }
    isEndWithType = param;
  }

  function initBornSupWithType() {
    iterWithType = 2;
    isEndWithType = true;
    return true;
  }

  function handleValidateAll() {
    // setisValidateAll(true);
    // ValidationPlanningService.getStateValidationAll(listPendingPlanningUserId, id_parameter).then((res) => {
    //   if (res && res.status) {
    //     ValidationPlanningService.verificationValidationAll(listPendingPlanningUserId, id_parameter).then((r) => {
    //       if (r && r.status) {
    //         setMessage({ ...message, openMessage: true, color: 'green', messageInfo: "Succès" });
    //         loadtable(jour, id, idservice);
    //         setisValidateAll(false);
    //       }
    //     })
    //   } else {
    //     setisValidateAll(false);
    //   }
    // })
    setopenValidateRequest(true);
  }

  function closeStep1() {
    setopenValidateRequest(false);
    setMessage({ ...message, openMessage: true, color: 'green', messageInfo: "Succès" });
    loadtable(jour, id, idservice);
  }

  function handleCloseValidateRequest() {
    setopenValidateRequest(false);
  }

  function handleRefusedAll() {
    setlistPendingPlanningFinal(listPendingPlanningId);
    setisRefusedAll(true);
    setOpenRefuseStep1(true);
  }


  function handleConfirmRefusedAll() {
    setOpenRefuseStep1(false);
    setMessage({ ...message, openMessage: true, color: 'green', messageInfo: "Succès" });
    loadtable(jour, id, idservice);
  }

  function handleExportExcel() {
    // console.log("semester data", semesterDataSorted);
    setisExportExcel(true);
    const semesterDataSorted = [...semesterData].sort((a, b) => {
      return a.monthId - b.monthId;
    });
    LeaveService.doExportPlanningForValidation("validation_planning", monthStart, monthEnd, semesterDataSorted, iddir, idservice).then((res) => {
      if (res && res.status) {
        const filenameResult = res.data;
        console.log("file***", filenameResult);
        FileService.readExcelFileContent("Leaves", filenameResult).then((result) => {
          if (result && result.status) {
            const data = new Blob([result.data], { type: fileType });
            FileSaver.saveAs(data, filenameResult);
            setisExportExcel(false);
          }
        })
      } else {
        setisExportExcel(false);
      }
    })
  }


  function handleExportExcelGlobalView() {
    // console.log("semester data", semesterDataSorted);
    setisExportExcel(true);
    // setSemesterData({...semesterData, period:periodMonth});
    const semesterDataSorted = [...semesterData].sort((a, b) => {
      return a.monthId - b.monthId;
    });

    const periodMonthSorted = [...periodMonth].sort((a, b) => {
      return a.monthId - b.monthId;
    });
    LeaveService.doExportPlanningForValidationGlobalView("validation_planning_global_view", monthStart, monthEnd, periodMonthSorted, semesterDataSorted, iddir, idservice, listPendingPlanningUserId).then((res) => {
      if (res && res.status) {
        const filenameResult = res.data;
        console.log("file***", filenameResult);
        FileService.readExcelFileContent("Leaves", filenameResult).then((result) => {
          if (result && result.status) {
            const data = new Blob([result.data], { type: fileType });
            FileSaver.saveAs(data, filenameResult);
            setisExportExcel(false);
          }
        })
      } else {
        setisExportExcel(false);
      }
    })
  }

  function handleViewPerMonth() {
    setValue(1);
  }

  function handleGlobalView() {
    setValue(2);
  }

  function loadPlanningForAllUser(id, dayArray, direction, service, monthName, planningYear, listUserPlanningId) {
    LeaveService.getPlanningForValidation(id, dayArray, direction, service, planningYear, listUserPlanningId).then((results) => {
      if (results) {
        if (results.status) {
          var dataForSemesterTemp = {};
          var period = {};
          dataForSemesterTemp.monthId = id;
          dataForSemesterTemp.monthName = monthName;
          // dataForSemesterTemp.monthBefore = monthBeforeTxt;
          dataForSemesterTemp.data = results.data;
          dataForSemesterTemp.dayList = dayArray;
          period.monthId = id;
          period.monthName = monthName;
          period.dayList = dayArray;
          // dataForSemesterTemp.period = period;
          setSemesterData(oldArray => [...oldArray, dataForSemesterTemp]);
          setPeriodMonth(oldArray => [...oldArray, period]);
        }
      }
    });
  }
  return (
    <MainContainer {...headerData}>
      <React.Fragment>
        <ValidatePlanningRequest
          openValidateRequest={openValidateRequest}
          listPendingPlanningUserId={listPendingPlanningUserId}
          id_parameter={id_parameter}
          closeStep1={closeStep1}
          module_type_id={moduleTypeId}
          handleCloseValidateRequest={handleCloseValidateRequest} />

        <RefusedPlanning idRequest={idRequest} id_parameter={id_parameter} type={type} openRefuseStep1={openRefuseStep1}
          closeDialog4={handleCancel} closeConfirm={handleConfirmRefused} confirmRefusedAll={handleConfirmRefusedAll}
          listPendingPlanningId={listPendingPlanningFinal} isRefusedAll={isRefusedAll} />
        <Message open={openMessage} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
        {/* <ValidatePlanningDialog open={openValidationPlanning}/> */}
        <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
        <h2 className={classes.text} >Validation du planning du {type} du {dateStart} au {dateEnd} </h2>
        <Grid container justifyContent="center" spacing={2}>
          <Grid container item xs={2} justifyContent="center">
            <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF" }}
              color="primary" variant="contained" onClick={handleViewPerMonth}>
              Vue par mois
              </Button>
          </Grid>
          <Grid container item xs={2} justifyContent="center">
            <Button className={classes.buttonGlobalView} variant="contained" onClick={handleGlobalView}>
              Vue global
              </Button>
          </Grid>
        </Grid>
        <Spacer y={2} />
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={1}>
          </Grid >
          {/* <Grid item xs={1}>
            <p>Direction :</p>
          </Grid > */}
          {/* <Grid item xs={4}>
            {numberdirection > 1 &&
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">Direction</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="direction" value={iddir} name="direction" onChange={handleChangeDirection} >
                  {dir.map((row) => (
                    <MenuItem value={row.direction_id}>{row.name}  ( {row.code} )</MenuItem>

                  ))}
                </Select>
              </FormControl>
            }
            {numberdirection == 1 &&
              <>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="demo-simple-select-outlined-label">Direction</InputLabel>
                  <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="direction" value={iddir} name="direction" onChange={handleChangeDirection} disabled>
                    {dir.map((row) => (
                      <MenuItem value={row.direction_id} disabled>{row.name}  ( {row.code} )</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            }
          </Grid> */}
          {/* <Grid item xs={1}>
            <p>Service :</p>
          </Grid >
          <Grid item xs={4}>
            {numberservice > 1 &&
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">Service</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="service" value={idservice} name="service" onChange={handleChangeService} >
                  {service.map((row) => (
                    <MenuItem value={row.service_id}>{row.name}  ( {row.code} )</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
            {numberservice == 1 &&
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">Service</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="service" value={idservice} name="service" onChange={handleChangeService} disabled >
                  {service.map((row) => (
                    <MenuItem value={row.service_id}>{row.name}  ( {row.code} )</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }

          </Grid> */}
          {/* {numberTable == 0 &&
            <Grid item xs={12}>
              <p className={classes.texts}>Personne n'a pas encore envoyé du planning </p>
            </Grid>
          } */}
          {numberTable !== 0 &&
            <>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} >
                  <Typography sx={{ fontSize: 15, color: 'black', fontWeight: 'bold', textAlign: 'center' }} color="text.secondary" gutterBottom>
                    <u>Légende des couleurs</u>
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2}>
                  <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                    {bullBeige}:Jour non comptabilisé( férié / weekend)
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2}>
                  <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                    {bullBlue}:En attente de validation
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2}>
                  <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                    {bullBlueEdited}:Planning modifié en attente de validation
                  </Typography>
                </Grid>
                {/* <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                    {bullBlueYellow}:Planication comprise entre une intervalle de mois
                  </Typography>
                </Grid> */}
                <Grid item xs={4} sm={2} md={2} lg={2}>
                  <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                    {bullGreen}:Planification acceptée
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2}>
                  <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                    {bullRed}:Planification refusée
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <p className={classes.mois} >
                  {ligne == 0 && ligne == max &&
                    <>
                      <div className={classes.bouton} >
                        {mois}
                      </div>
                    </>
                  }
                  {ligne == 0 && max > 1 &&
                    <>
                      <div className={classes.bouton} >
                        {mois}<Button onClick={handlenext}> <NavigateNext /></Button>
                      </div>
                    </>
                  }
                  {ligne != 0 && ligne != max &&
                    <>
                      <div className={classes.bouton} >
                        <Button onClick={handlebefore}> <NavigateBefore /></Button> {mois}<Button onClick={handlenext}> <NavigateNext /></Button>
                      </div>
                    </>
                  }
                  {ligne == max &&
                    <>
                      <div className={classes.bouton} >
                        <Button onClick={handlebefore}> <NavigateBefore /></Button> {mois}
                      </div>
                    </>
                  }
                </p>
              </Grid >
              {/* <Box sx={{ minWidth: 275, backgroundColor: 'silver' }}>
                <CardContent>
                  <Typography sx={{ fontSize: 22, color: 'black' }} color="text.secondary" gutterBottom>
                    Légende couleur
              </Typography>
                  <Typography variant="h5" component="div">

                  </Typography>
                  <Typography sx={{ mb: 1.5, fontSize: 17, color: 'black' }}  >
                    {bullGreen}:Planification acceptée
              </Typography>
                  <Typography sx={{ mb: 1.5, fontSize: 17, color: 'black' }}  >
                    {bullRed}:Planification refusée
              </Typography>
                  <Typography sx={{ mb: 1.5, fontSize: 17, color: 'black' }}  >
                    {bullBlue}:En attente de planification
              </Typography>
                  <Typography sx={{ mb: 1.5, fontSize: 17, color: 'black' }}  >
                    {bullBlueYellow}:Planication comprise entre une intervalle de mois
              </Typography>

                </CardContent>
              </Box> */}
              <Grid item xs={12} >
                <TableContainer className={clsx(classes.root, classes.tableContainerValidationPlanning)}>
                  <Table className={classes.tableValidationPlanning} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={clsx(classes.name, classes.demandeur)}>Demandeur</TableCell>

                        {jours.map((row) => (
                          <TableCell className={classes.root}>{row}</TableCell>
                        ))}
                        {/* <TableCell className={classes.root}>Action</TableCell> */}
                      </TableRow>

                    </TableHead>
                    <TableBody>
                      {final.map((row) => (
                        <TableRow className={classes.tableRow}>
                          <TableCell className={clsx(classes.name2, classes.submitterName)}>{row["name"]}  {row["lastname"]}</TableCell>
                          {jours.map((index) => (

                            <>

                              {row[index] == "dayNotCompted" && <TableCell className={classes.dayNotCompted}></TableCell>}
                              {row[index] == "confirmed" && <TableCell className={classes.confirmed}></TableCell>}
                              {row[index] == "refused" && <TableCell className={classes.refused}></TableCell>}
                              {/* {row["type"] != row[index] && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" &&

                            <TableCell className={classes.roots} colSpan={row[row[index]]}>
                              <div classeName={classes.validatePlanning}> <Button size="small" onClick={() => handleConfirmed(row[index], row["user_id"])} title="Valider">  <Check style={{ color: "green" }} /></Button>
                                <Button size="small" onClick={() => handleRefused(row[index])} title="Refuser">  <Close style={{ color: "red" }} /></Button>
                              </div>
                            </TableCell>

                          }*/}
                              {(row["type"] == row[index] && row[index] != "dayNotCompted" && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] == row[index + 1]) ? setBornSupWithType(false) : ""}
                              {row["type"] == row[index] && row[index] != "dayNotCompted" && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] != row[index + 1] && !bornSupWithType() &&

                                <TableCell className={row[index] !== "planning_edited" ? classes.rootss : classes.planningEdited} colSpan={renderCountWithType()}>
                                  {/* <Button className={classes.btn} size="small" onClick={() => handleConfirmed(row[index], row["user_id"])} title="Valider">  <Check style={{ color: "green" }} /></Button>
                                  <Button className={classes.btn} size="small" onClick={() => handleRefused(row[index])} title="Refuser">  <Close style={{ color: "red" }} /></Button> */}
                                </TableCell>
                              }
                              {row["type"] == row[index] && row[index] != "dayNotCompted" && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] != row[index + 1] && bornSupWithType() &&

                                <TableCell className={row[index] !== "planning_edited" ? classes.rootss : classes.planningEdited}>
                                  {/* <Button className={classes.btn} size="small" onClick={() => handleConfirmed(row[index], row["user_id"])} title="Valider">  <Check style={{ color: "green" }} /></Button>
                                  <Button className={classes.btn} size="small" onClick={() => handleRefused(row[index])} title="Refuser">  <Close style={{ color: "red" }} /></Button> */}
                                </TableCell>
                              }
                              {(row["type"] == row[index] && row[index] != "dayNotCompted" && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] != row[index + 1] && !bornSupWithType()) ? initBornSupWithType() : ""}


                              {(row["type"] != row[index] && row[index] != "dayNotCompted" && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] == row[index + 1]) ? setBornSup(false) : ""}

                              {row["type"] != row[index] && row[index] != "dayNotCompted" && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] != row[index + 1] && !bornSup() &&
                                <TableCell className={row[index] !== "planning_edited" ? classes.roots : classes.planningEdited} colSpan={renderCount()}>
                                  {/* <Button className={classes.btn} size="small" onClick={() => handleConfirmed(row[index], row["user_id"])} title="Valider">  <Check style={{ color: "green" }} /></Button>
                                  <Button className={classes.btn} size="small" onClick={() => handleRefused(row[index])} title="Refuser">  <Close style={{ color: "red" }} /></Button> */}
                                </TableCell>

                              }
                              {row["type"] != row[index] && row[index] != "dayNotCompted" && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] != row[index + 1] && bornSup() &&
                                <TableCell className={row[index] !== "planning_edited" ? classes.roots : classes.planningEdited}>
                                  {/* <Button className={classes.btn} size="small" onClick={() => handleConfirmed(row[index], row["user_id"])} title="Valider">  <Check style={{ color: "green" }} /></Button>
                                  <Button className={classes.btn} size="small" onClick={() => handleRefused(row[index])} title="Refuser">  <Close style={{ color: "red" }} /></Button> */}

                                </TableCell>

                              }
                              {(row["type"] != row[index] && row[index] != "dayNotCompted" && row[index] != "null" && row[index] != "confirmed" && row[index] != "refused" && row[index] != row[index + 1] && !bornSup()) ? initBornSup() : ""}


                              {(row[index] == "null" && row[index] != "dayNotCompted") && <TableCell className={classes.root}></TableCell>}
                            </>
                          ))}
                          {/* {row["notNull"] &&
                            <TableCell className={classes.root}>
                              <Button variant="contained" color="primary" onClick={() => handleValidate(row["user_id"])}>Valider tous</Button>

                            </TableCell>
                          }
                          {!row["notNull"] &&
                            <TableCell className={classes.root}>
                              <Button disabled={true} variant="contained" color="primary" onClick={() => handleValidate(row["user_id"])}>Valider tous</Button>

                            </TableCell>
                          } */}
                        </TableRow >
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid container alignItems="center" justifyContent="center">
                <div className={classes.divLegendeTimeline}>
                  {isTherePendingPlanning &&
                    <>
                      {isExportExcel ? <CircularProgress /> :
                        <Button className={classes.btnExportExcel} variant="contained" onClick={handleExportExcel}>
                          EXPORT .xls
                        </Button>
                      }
                      <Spacer x={1} />
                      <Button className={classes.btnRefuserTous} variant="contained" color="secondary" onClick={handleRefusedAll}>
                        REFUSER
                      </Button>
                      <Spacer x={1} />
                      {isValidateAll ? <CircularProgress /> :
                        <Button className={classes.btnValiderTous} variant="contained" color="primary" onClick={handleValidateAll}>
                          VALIDER
                      </Button>
                      }
                    </>
                  }
                </div>
              </Grid>
              {/* <Grid item xs={12}>
                <Button variant="contained" color="secondary" onClick={handleClickTimeline}>
                  Timeline
                </Button>
                {/* <Timeline groups={groups} items={items} /> */}
              {/* </Grid> */}
            </>
          }
        </Grid>
        <Dialog
          fullScreen
          open={openDialogTimeline}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Validation planning de congé
              </Typography>
              {/* 
              <Button color="inherit" onClick={handleValidateAll}>
                VALIDER TOUS
              </Button> */}
            </Toolbar>
          </AppBar>
          <Spacer y={1} />

          <h2 className={classes.text} >Validation du planning du {type} du {dateStart} au {dateEnd} </h2>
          <Typography sx={{ fontSize: 15, color: 'black', fontWeight: 'bold', textAlign: 'center' }} color="text.secondary" gutterBottom>
            <u>Légende des couleurs</u>
          </Typography>
          <div className={classes.divLegendeTimeline}>
            <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
              {bullBeige}:Jour non comptabilisé(férié/weekend)
            </Typography>
            <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
              {bullBlue}:En attente de validation
            </Typography>
            <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
              {bullBlueEdited}:Planning modifié en attente de validation
            </Typography>
            <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
              {bullGreen}:Planification acceptée
            </Typography>
            <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
              {bullRed}:Planification refusée
            </Typography>
          </div>
          <Grid container xs={12} className={classes.timelineGrid}>
            {/* <div><u>Légende des couleurs</u></div> */}
            {/* <Grid justifyContent="center" item xs={12}>
              <Grid item xs={12} >
                <Typography sx={{ fontSize: 15, color: 'black', fontWeight: 'bold', textAlign: 'center' }} color="text.secondary" gutterBottom>
                  <u>Légende des couleurs</u>
                </Typography>
              </Grid>
              <Grid item xs={4} sm={2} md={2} lg={2}>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullBlue}:En attente de validation
                  </Typography>
              </Grid>
              <Grid item xs={4} sm={2} md={2} lg={2}>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullGreen}:Planification acceptée
                  </Typography>
              </Grid>
              <Grid item xs={4} sm={2} md={2} lg={2}>
                <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                  {bullRed}:Planification refusée
                  </Typography>
              </Grid>
            </Grid> */}
            <Grid item xs={12}>
              <Timeline open={openDialogTimeline} openValidatePlanningTimeline={openValidatePlanning} groups={groups} items={items}
                start_time={startTime} end_time={endTime} difference_In_Days={difference_In_Days}
                id_parameter={id_parameter} type={type} closeDialog4={handleCancel}
                closeConfirm={handleConfirmRefusedTimeline} openRefuseStep1={openRefuseStep1}
                handleRefusedTimeline={handleRefusedTimeline} openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
                closeValidate={handleConfirmValidateTimeline} />

            </Grid>
          </Grid>
          <Spacer y={2} />
          <div className={classes.divLegendeTimeline}>
            {isTherePendingPlanning &&
              <>
                {isExportExcel ? <CircularProgress /> :
                  <Button className={classes.btnExportExcel} variant="contained" onClick={handleExportExcelGlobalView}>
                    EXPORT .xls
                  </Button>
                }
                <Spacer x={1} />
                <Button className={classes.btnRefuserTous} variant="contained" color="secondary" onClick={handleRefusedAll}>
                  REFUSER
                </Button>
                <Spacer x={1} />
                {isValidateAll ? <CircularProgress /> :
                  <Button className={classes.btnValiderTous} variant="contained" color="primary" onClick={handleValidateAll}>
                    VALIDER
                  </Button>
                }
              </>
            }
          </div>
        </Dialog>
      </React.Fragment>
    </MainContainer>

  );
}
export default ValidationPlanning;
