import { Grid, Paper } from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import EquipmentConfigTable from "./EquipmentConfigTable";
import { useNavigate } from "react-router-dom";
export default function EquipmentConfigList() {
  const navigate = useNavigate();
  function handleClick() {
    navigate("/Logistic/config/add");
  }
  return (
    <MainContainer {...headerDataImmo}>
      <Grid container item xs={12}>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          CREER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <EquipmentConfigTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
