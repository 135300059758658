import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const ValidationPlanningService = {
  async insert(id) {
    try {
      const result = await axios.delete(`${apiUrl}/LeavePlanning/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async add(date_start, date_end) {
    try {
      const result = await axios.post(`${apiUrl}/validationPlanning/`, {
        date_start,
        date_end
      });
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async ValidationPlanningById(id) {
    try {
      const result = await axios.get(`${apiUrl}/validationPlanning/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async saveValidationPlanningIs_active(id, is_active) {
    try {
      // antsoina 
      const result = await axios.put(`${apiUrl}/validationPlanning/updateActifById`, {
        id: id,
        is_active: is_active,
      })
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async saveValidationPlanning(date_start, date_end, libelle_planning_interval_parameter, date_start_notification, is_active, next_parameter_id, id, periodicity) {
    try {
      console.log("this is form", date_start, date_end);
      const result = id ?
        await axios.put(`${apiUrl}/validationPlanning/id/${id}`, {
          date_start,
          date_end,
          libelle_planning_interval_parameter,
          date_start_notification,
          is_active,
          next_parameter_id,
          periodicity,

        })
        :
        await axios.post(`${apiUrl}/validationPlanning/`, {
          date_start,
          date_end,
          libelle_planning_interval_parameter,
          date_start_notification,
          is_active,
          next_parameter_id,
          periodicity,


        });
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getValidationPlanning() {
    try {
      const result = await axios.get(`${apiUrl}/validationPlanning/`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async deletePlanningService(id) {
    try {
      const result = await axios.delete(`${apiUrl}/validationPlanning/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  //   async getPrivilege(options) {
  //     try {
  //       const result = await axios.get(`${apiUrl}/privilege/`, {
  //         params: options,
  //         // paramsSerializer: params => {
  //         //   return qs.stringify(params)
  //         // }
  //       });
  //       if (result) {
  //         // TODO: replace result.data.message by the token
  //         return result.data;
  //       }
  //     }
  //     catch (error) {
  //       if (error.response) {
  //         return error.response.data;
  //       }
  //     }
  //   }
  //   ,


  async getAllPlanningIntervalExcepId(idPlanning) {
    try {
      const result = await axios.get(`${apiUrl}/validationPlanning/getAllPlanningIntervalExcepId/${idPlanning}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },




  async declinedVP(id) {
    try {
      const result = await axios.put(`${apiUrl}/ValidationPlanning/updateVPDeclined/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async confirmedVP(id) {
    try {
      const result = await axios.put(`${apiUrl}/ValidationPlanning/updateVPConfirmed/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {

      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getState(UserId, idParameter, month) {
    try {
      const result = await axios.get(`${apiUrl}/ValidationPlanning/state/${UserId}/${idParameter}/${month}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async verification(UserId, idParameter) {
    try {
      const result = await axios.get(`${apiUrl}/ValidationPlanning/verification/${UserId}/${idParameter}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async getStateValidationAll(ListUserId, idParameter) {
    try {
      const result = await axios.get(`${apiUrl}/ValidationPlanning/stateValidationAll/${idParameter}`, {
        params: {
          "listPlanningUserId": ListUserId
        }
      });
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async verificationValidationAll(UserId, idParameter) {
    try {
      const result = await axios.get(`${apiUrl}/ValidationPlanning/verificationValidationAll/${idParameter}`, {
        params: {
          "listPlanningUserId": UserId
        }
      });
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getPlanningDetail(idPlanning) {
    try {
      const result = await axios.get(`${apiUrl}/ValidationPlanning/getPlanningDetail/${idPlanning}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      return error.response.data;
    }
  },

  async getUserValidatorOfPlanningId(idPlanning) {
    try {
      const result = await axios.get(`${apiUrl}/ValidationPlanning/getUserValidatorOfPlanningId/${idPlanning}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      return error.response.data;
    }
  },

  async remindToSendPlanning(listMail) {
    try {
      const result = await axios.post(`${apiUrl}/ValidationPlanning/remindToSendPlanning`, {
        listMail
      });
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      return error.response.data;
    }
  },

  async declinedAllVP(listVp) {
    try {
      const result = await axios.put(`${apiUrl}/ValidationPlanning/updateAllVPDeclined`,{
        listVp: listVp
      });
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

}
export default ValidationPlanningService;