import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import Copyright from "../../components/Copyright";
import useStyles from "../../screens/authentication/signin.styles";
import AuthService from "../../services/AuthService";
import { useNavigate, Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import { useLocation } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function SignIn(props) {
  const classes = useStyles();

  const location = useLocation();
  const [form, setForm] = useState({});
  const [alertSeverity, setAlertSeverity] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [failedLogin, setFailedLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showTitlePreProd, setShowTitlePreProd] = useState(false);
  

  useEffect(() => {
    if (location.pathname === "/") {
      localStorage.clear();
    }
    if(window.location.hostname == "2fidmg.mg"){
      setShowTitlePreProd(true);
    }
  }, [props]);


  function handleSubmit(event) {
    event.preventDefault();
    setFailedLogin(false);
    setloading(true);
    // console.log("this is the form", form);
    if (!form.email) {
      setIsValid(false);
      setErrorMessage("Adresse mail requis");
      setloading(false);
    }
    if (!form.password) {
      setIsValid(false);
      setErrorMessage("Mot de passe requis");
      setloading(false);
    }

    AuthService.login(form.email, form.password, isChecked)
      .then((res) => {
        var responseErrorMessage = res.message;
        if (res.status) {
          navigate("/home");
          setloading(false);
        } else {
          setFailedLogin(true);
          setErrorMessage(responseErrorMessage);
          setAlertSeverity("error");
          setloading(false);
        }
      })
      .catch((err) => {
        setFailedLogin(true);
        setErrorMessage("Une erreur s'est produite. Veuillez réessayer");
        setloading(false);
      });
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);

  function handleFocusEmail() {
    setFailedLogin(false);
  }
  function handleFocusPassword() {
    setFailedLogin(false);
  }

  const handleChangeCheckbox = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.logo}>
          {/* <img className={classes.imgLogo} src="./assets/images/fid_logo.png" alt="logo"></img> */}
        </div>
        {showTitlePreProd && <h1 style={{color:"red", fontWeight:"bold"}}>SERVEUR TEST</h1>}
        <div className={classes.divCenter}>
          <div className={classes.alert}>Connectez-vous ici</div>
          <form className={classes.form}>
            <TextField
              type="email"
              error={failedLogin}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse e-mail"
              name="email"
              autoComplete="email"
              onChange={handleChange}
              autoFocus
              value={form.email || ""}
              onFocus={handleFocusEmail}
            />
            <TextField
              error={failedLogin}
              variant="outlined"
              margin="normal"
              required={true}
              fullWidth={true}
              name="password"
              label="Mot de passe"
              type={showPassword ? "text" : "password"}
              id="password"
              onChange={handleChange}
              autoComplete="current-password"
              value={form.password || ""}
              onFocus={handleFocusPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label="Visualiser mot de passe"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            {isValid && (
              <Alert severity="error">{errorMessage}</Alert>
            )}
            {failedLogin && (
              <Alert severity="error">{errorMessage}</Alert>
            )}
            <FormControlLabel control={<Checkbox checked={isChecked} onChange={handleChangeCheckbox} />} label="Connexion par délégation de pouvoir" />
            <Button
              onClick={handleSubmit}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading ? true : false}
            >
              {loading ? <CircularProgress /> : "Se connecter"}

            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  to="/reset-password"
                  variant="body2"
                  className={classes.linkForgotPwd}
                >
                  Mot de passe oublié?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      <Box mt={6}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default SignIn;
