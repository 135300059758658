import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MainContainer from "../../components/MainContainer";
import { headerDataRH } from "../../data/modules/hr-header";
import { useNavigate, useParams } from 'react-router-dom';
import PersonnelService from "../../services/PersonnelService";
import Spacer from "../../components/Spacer";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Message from "./Message";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      float: 'right',
    },
  },
  lien: {
    float: "right",
    fontSize: "18px",
  },
  button: {
    fontSize: "18px",
  },
  labelPosition: {
    float: 'left',
    fontSize: "20px",
  },
  labelTitle: {
    float: "left",
    textDecoration: 'underline',
    fontSize: "25px",
    color: 'blue',
  },
  labelSuivant: {
    width: '100%',
  },
}));



function Update(props) {

  const navigate = useNavigate();
  const params = useParams();
  const splitLocation = params.id;
  const [isValidEmail, setIsvalidEmail] = useState(false);
  const [isValidCIN, setIsvalidCIN] = useState(false);
  const regexForCIN = /^[0-6]/;
  const [setting, setSetting] = useState("");
  const [util, setUtil] = useState("");
  const [fiscale, setFiscale] = useState("");
  const [person, setPerson] = useState("");
  const [options, setOptions] = useState([]);
  const regexForEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
  const [etat, setEtat] = useState({ open: false, message: "message" });
  const [tests, setTests] = useState([]);
  const classes = useStyles();
  const [services, setServices] = useState([]);
  const [form, setForm] = useState({
    id: "",
    nombre_enfant: "",
    type: "",
    adresse: "",
    datep_cin: "",
    lieu_cin: "",
    date_cin: "",
    cin: "",
    lieu: "",
    ddn: "",
    matricule: "",
    service: "Service Juridique",
    fonction: "Directeur Generale",
    flotte: "",
    bureau: "",
    porte: "",
    siege: "",
    batiment: "",
    group: "Super Admin",
    personnal_email: "",
    last_name: "",
    first_name: "",
    nif: "",
    stat: "",
    urgence: "",
  });

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const [age, setAge] = useState(0);
  function handleFocusEmail() {
    setIsvalidEmail(false);

  }
  function handleBlurEmail(e) {
    if (!regexForEmail.test(e.target.value)) {
      setIsvalidEmail(true);
    }
  }
  function handleClick() {
    setSetting("setting");
  }
  function handleClicku() {
    setUtil("util");
  }
  function handleClickp() {
    setPerson("personnel");
    setAge(getAge(form.ddn));
  }
  function handleClickf() {
    setFiscale("fiscale");
  }
  function handleChange(e) {
    const { name, value } = e.target;
    if (name == "ddn") {
      setAge(getAge(value))
      setForm({ ...form, [name]: value })
    }
    else if (name == 'first_name') {
      setForm({ ...form, [name]: value.toUpperCase() })
    }
    else if (name == 'last_name') {
      setForm({ ...form, [name]: CapitalString(value) })
    }
    else if (name == 'cin') {
      setForm({ ...form, [name]: value })
      const number = form.cin.length;
      if (number < 6) {
        if (!regexForCIN.test(value)) {
          setIsvalidCIN(true);
        }
        else {
          setIsvalidCIN(false);
        }
      }
    }
    else {
      setForm({ ...form, [name]: value })
    }
  }
  function CapitalString(str) {
    var nextCapital = true;
    var newString = "";
    for (var i = 0; i < str.length; i++) {
      var cur = str.charAt(i);
      if (nextCapital) {
        newString += cur.toUpperCase();
      } else {
        newString += cur.toLowerCase();
      }
      if (cur === " ") {
        nextCapital = true;
      } else {
        nextCapital = false;
      }
    }
    return newString;
  };

  function handlesave() {
    PersonnelService.saveAll(form.nombre_enfant, form.type, form.adresse, form.nif, form.stat, form.lieu_cin, form.datep_cin, form.date_cin, form.cin, form.lieu, form.ddn, form.matricule, form.service, form.fonction, form.flotte, form.bureau, form.siege, form.batiment, form.porte, form.group, form.personnal_email, form.last_name, form.first_name, form.urgence, form.id)
      .then((result) => {
        navigate("/personnel");
      })
      .catch((err) => {
        alert("error");
      });
  }

  useEffect(() => {
    PersonnelService.getGroup().then((result) => {
      if (result && result.data) {
        setTests(result.data);
      }
    });
    PersonnelService.getFunction().then((result) => {
      if (result && result.data) {
        setOptions(result.data);
      }
    });
    PersonnelService.getServices().then((result) => {
      if (result && result.data) {
        setServices(result.data);
      }
    });
    const id = splitLocation;
    if (splitLocation) {
      PersonnelService.getUpdate(id).then((result) => {
        setForm({
          id: result.data[0].person_id,
          nombre_enfant: result.data[0].nombre_enfant,
          type: result.data[0].type,
          adresse: result.data[0].adresse,
          datep_cin: result.data[0].datep_cin,
          lieu_cin: result.data[0].lieu_cin,
          date_cin: result.data[0].date_cin,
          cin: result.data[0].cin,
          lieu: result.data[0].lieu,
          ddn: result.data[0].ddn,
          matricule: result.data[0].matricule,
          service: result.data[0].service,
          fonction: result.data[0].fonction,
          flotte: result.data[0].flotte,
          bureau: result.data[0].bureau,
          siege: result.data[0].seat,
          batiment: result.data[0].building,
          porte: result.data[0].porte,
          group: "1",
          personnal_email: result.data[0].personnal_email,
          last_name: result.data[0].last_name,
          first_name: result.data[0].first_name,
          nif: result.data[0].nif,
          stat: result.data[0].stat,
        });
      });
    }

  }, []);

  function handleDelete() {
    setEtat({
      open: true,
      message: "Voulez-vous vraiment abandonner?"
    });
  }

  function handleMessage() {
    setEtat({
      open: false,
      message: ""
    });
  }
  function handleAccept() {
    navigate("/personnel");
  }
  const titre = " Compte Utilisateur>>>>";
  return (
    <MainContainer {...headerDataRH}>
      <Message isActive={etat.open} message={etat.message} handleClose={handleMessage} handleConfirm={handleAccept} />
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <Button variant="outlined" color="primary" className={classes.labelSuivant} >
            {titre}
          </Button>
        </Grid>
        <Grid item xs={8}>

        </Grid>
        <Spacer y={6} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Nom*</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            label="Nom"
            variant="outlined"
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={handleChange}
            value={form.first_name}
            name="first_name"
          />
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Prénom(s)</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            label="Prénom"
            variant="outlined"
            inputProps={{ textTransform: 'capitalize', }}
            onChange={handleChange}
            value={form.last_name}
            name="last_name"
          />
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Email*</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            label="email"
            variant="outlined"
            onChange={handleChange}
            onFocus={handleFocusEmail}
            onBlur={handleBlurEmail}
            value={form.personnal_email}
            name="personnal_email"
          />
          {isValidEmail && <div style={{ color: "red", textAlign: "left" }}><span>Adresse email invalide</span></div>}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <Button variant="outlined" color="primary" onClick={handleClick} className={classes.labelSuivant} >
            Paramétrage
          </Button>
        </Grid>
        <Grid item xs={8}>

        </Grid>
        {setting === "setting" && (
          <>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Groupes*</label>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined" className={classes.forms} size="small">
                <InputLabel id="demo-simple-select-outlined-label">Group</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="group" value={form.group} name="group" onChange={handleChange}>
                  {tests.map((row) => (
                    <MenuItem value={row.group_id}>{row.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <Button variant="outlined" color="primary" onClick={handleClicku} className={classes.labelSuivant} >
                Information Utile
              </Button>
            </Grid>
            <Grid item xs={8}>

            </Grid>
          </>
        )}
        {util === "util" && (
          <>      <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Siège</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="Siège"
                variant="outlined"
                onChange={handleChange}
                value={form.siege}
                name="siege"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Batiment</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="Batiment"
                variant="outlined"
                onChange={handleChange}
                value={form.batiment}
                name="batiment"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Porte</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                type='number'
                id="outlined-basic"
                size="small"
                label="Porte"
                variant="outlined"
                pattern="[0-9]*"
                inputmode="numeric"
                onChange={handleChange}
                value={form.porte}
                name="porte"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Numero interne</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="Bureau"
                variant="outlined"
                onChange={handleChange}
                value={form.bureau}
                name="bureau"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Flotte</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="Flotte"
                variant="outlined"
                onChange={handleChange}
                value={form.flotte}
                name="flotte"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Numero d'urgence</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="Urgence"
                variant="outlined"
                onChange={handleChange}
                value={form.urgence}
                name="urgence"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Fonction</label>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">Fonction</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Fonction" value={form.fonction} name="fonction" onChange={handleChange}>
                  {options.map((row) => (
                    <MenuItem value={row.function_id}>{row.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Service</label>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">Service</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Service" value={form.service} name="service" onChange={handleChange}>
                  {services.map((row) => (
                    <MenuItem value={row.service_id} >{row.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Matricule</label>
            </Grid>
            <Grid item xs={8}>
              <TextField fullWidth id="outlined-basic" size="small" label="Matricule" variant="outlined" onChange={handleChange} inputProps={{ textTransform: 'capitalize', }} value={form.matricule} name="matricule" />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <Button variant="outlined" color="primary" onClick={handleClickp} className={classes.labelSuivant} >
                Information Personnelle
              </Button>
            </Grid>
            <Grid item xs={8}>

            </Grid>
          </>
        )}
        {person === "personnel" && (
          <>
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Date de naissance</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth

                id="date-picker-dialog"
                size="small"
                type="date"
                variant="outlined"
                inputProps={{ style: { textTransform: "uppercase" } }}
                onChange={handleChange}
                value={form.ddn}
                name="ddn"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Lieu de naissance</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="Lieu"
                variant="outlined"
                inputProps={{ textTransform: 'capitalize', }}
                onChange={handleChange}
                value={form.lieu}
                name="lieu"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Age</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="Age"
                variant="outlined"
                onChange={handleChange}
                value={age}
                name="age"
                disabled
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>CIN</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="CIN"
                variant="outlined"
                onChange={handleChange}
                inputProps={{ maxLength: 12 }}
                value={form.cin}
                name="cin"
              />
              {isValidCIN && <div style={{ color: "red", textAlign: "left" }}><span>Le sixième chiffre doit être 1 ou 2</span></div>}
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Date de délivrance CIN</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                type="date"
                variant="outlined"
                onChange={handleChange}
                value={form.date_cin}
                name="date_cin"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Lieu de délivrance CIN</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                variant="outlined"
                onChange={handleChange}
                value={form.lieu_cin}
                name="lieu_cin"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Date de duplicate CIN</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                type="date"
                variant="outlined"
                onChange={handleChange}
                value={form.datep_cin}
                name="datep_cin"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Adresse</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="Adresse"
                variant="outlined"
                inputProps={{ textTransform: 'capitalize', }}
                onChange={handleChange}
                value={form.adresse}
                name="adresse"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Situation matrimoniale</label>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">Situation matrimoniale</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Type" value={form.type} name="type" onChange={handleChange}>
                  <MenuItem value="celibataire">Célibataire</MenuItem>
                  <MenuItem value="marie">Marié(e)</MenuItem>
                  <MenuItem value="divorce">Divorcé(e)</MenuItem>
                  <MenuItem value="veuve">Veuf(ve)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>Nombre Enfant</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                type='number'
                id="outlined-basic"
                size="small"
                label="Nombre Enfant "
                variant="outlined"
                onChange={handleChange}
                value={form.nombre_enfant}
                name="nombre_enfant"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <Button variant="outlined" color="primary" onClick={handleClickf} className={classes.labelSuivant} >
                Information Fiscale
              </Button>
            </Grid>
            <Grid item xs={8}>

            </Grid>

          </>
        )}
        {fiscale === "fiscale" && (
          <>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>NIF</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="NIF"
                variant="outlined"
                inputProps={{ maxLength: 10 }}
                onChange={handleChange}
                value={form.nif}
                name="nif"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
              <label className={classes.labelPosition}>STAT</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                type="number"
                id="outlined-basic"
                size="small"
                label="STAT"
                variant="outlined"
                onChange={handleChange}
                value={form.stat}
                name="stat"
              />
            </Grid>
            <Spacer y={9} />
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={8}>
              <div className={classes.root}>
                <Button variant="contained" color="primary" onClick={handlesave}  >
                  Enregistrer
                </Button>
                <Button variant="contained" color="secondary" onClick={handleDelete}>
                  Annuler
                </Button>
              </div>
            </Grid>
          </>
        )}

      </Grid>

    </MainContainer>
  );
}
export default Update;