import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Spacer from "../../components/Spacer";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import PersonnelService from "../../services/PersonnelService";
import Message from "./Message";
import Util from "../tools/util";
import ConfirmationDialog from "../tools/ConfirmationDialog";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Avatar from "@material-ui/core/Avatar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
const bullRed = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "5px",
      transform: "scale(0.8)",
      color: "red",
      fontSize: "20px",
    }}
  >
    *
  </Box>
);
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  labelPosition: {
    float: "left",
  },
  lien: {
    float: "right",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      float: "right",
    },
  },
  type: {
    display: "none",
  },
  input: {
    float: "right",
  },
  img: {
    float: "right",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  small: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

export default function CompteUser(props) {
  const navigate = useNavigate();
  const id = props.id;
  const [etat, setEtat] = useState({ open: false, message: "message" });
  const [isaccept, setIsaccept] = useState(false);
  const [isValidEmail, setIsvalidEmail] = useState(false);
  const regexForEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
  const [opens, setOpens] = React.useState(false);

  const [lastNameIsEmpty, setlastNameIsEmpty] = React.useState(false);
  const [sexisEmpty, setsexIsEmpty] = React.useState(false);
  const [personnalEmailIsEmpty, setpersonnalEmailIsEmpty] =
    React.useState(false);
  const [cinIsEmpty, setcinIsEmpty] = React.useState(false);
  const [fieldIsEmpty, setfieldIsEmpty] = React.useState(false);

  const [file, setFile] = React.useState(null);
  const [state, setState] = useState({
    file: null,
    picture: null,
  });
  const [isid, setIsid] = useState("");
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    openDialog: false,
    messageDialog:
      "cette personne existe dèja ,voulez-vous reprendre les information ?",
  });
  const { openDialog, messageDialog } = confirmationDialog;
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const idGroup = splitLocation[3];
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    personnal_email: "",
    sex_id: "1",
    name_image: "",
    cin: "",
    url_name_image: "",
  });
  const util = new Util();

  const handleClose = () => {
    setOpens(false);
  };
  function closeDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    navigate("/personnel/add/parametrage");
  }

  function confirmDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    navigate("/personnel/add/" + isid + "/parametrage");
  }

  function handleFocusEmail() {
    setIsvalidEmail(false);
    setpersonnalEmailIsEmpty(false);
  }
  function handleBlurEmail(e) {
    if (!regexForEmail.test(e.target.value)) {
      setIsvalidEmail(true);
    }
  }

  function handleFocusLastName() {
    setlastNameIsEmpty(false);
  }

  function setCINvalue(value) {
    if (value) {
      const cin = value.replace(/[^\d]/g, "");
      const number = cin.length;
      if (number < 4) {
        return cin;
      }
      if (number < 7) {
        return `${cin.slice(0, 3)} ${cin.slice(3, 6)}`;
      }
      if (number < 10) {
        return `${cin.slice(0, 3)} ${cin.slice(3, 6)} ${cin.slice(6, 9)}`;
      }

      return `${cin.slice(0, 3)} ${cin.slice(3, 6)} ${cin.slice(
        6,
        9
      )} ${cin.slice(9, 12)}`;
    } else {
      return "";
    }
  }
  function handleChange(e) {
    const { name, value } = e.target;
    if (name == "last_name") {
      setForm({ ...form, [name]: value.toUpperCase() });
    } else if (name == "first_name") {
      setForm({ ...form, [name]: util.CapitalString(value) });
    } else if (name == "cin") {
      const newval = setCINvalue(value);
      setForm({ ...form, [name]: newval });
      setcinIsEmpty(false);
      // const number = value.length;
      // for (let index = 0; index < 16; index++) {
      //   if (number == 3) {

      //   }
      //   else if (number == 8) {
      //     setForm({ ...form, [name]: util.separator(value) })
      //   }
      //   else if (number == 13) {

      //     setForm({ ...form, [name]: util.separator(value) })
      //   }
      //   else {
      //     setForm({ ...form, [name]: value })
      //   }
      // }
    } else if (name == "sex_id") {
      setsexIsEmpty(false);
      setForm({ ...form, [name]: value });
    } else {
      setForm({ ...form, [name]: value });
    }
  }

  const handleChange1 = (event) => {
    setState({
      file: URL.createObjectURL(event.target.files[0]),
      picture: event.target.files[0],
    });
    const lien = event.target.files[0];
    setOpens(false);
    setFile(event.target.files[0]);
    const formData = new FormData();
    formData.append("photos", lien, lien.name);
    setForm({ ...form, name_image: lien.name });

    PersonnelService.importService(formData).then((result) => {});
  };

  function handleDelete() {
    setEtat({
      open: true,
      message: "Voulez-vous vraiment abandonner?",
    });
  }

  function cancelDialog() {
    setConfirmationDialog({ ...confirmationDialog, openDialog: false });
  }
  function handleAccept() {
    setIsaccept(true);
  }

  function handleMessage() {
    setEtat({
      open: false,
      message: "",
    });
  }

  const handleUpload = () => {
    setOpens(true);
  };
  function handlePopoverOpen() {}

  function handleClick() {
    if (
      form.last_name !== null &&
      form.personnal_email !== null &&
      form.sex_id !== undefined &&
      form.cin !== null
    ) {
      localStorage.setItem("first_name", JSON.stringify(form.first_name));
      localStorage.setItem("last_name", JSON.stringify(form.last_name));
      localStorage.setItem("sex_id", JSON.stringify(form.sex_id));
      localStorage.setItem(
        "personnal_email",
        JSON.stringify(form.personnal_email)
      );
      localStorage.setItem("name_image", JSON.stringify(form.name_image));
      localStorage.setItem("cin", JSON.stringify(form.cin));

      if (idGroup) {
        if (idGroup !== "parametrage") {
          navigate("/personnel/add/" + idGroup + "/parametrage");
          window.location.reload();
        } else {
          navigate("/personnel/add/parametrage");
          window.location.reload();
        }
      } else {
        navigate("/personnel/add/parametrage");
        window.location.reload();
      }
    } else if (form.last_name === null || form.last_name === "") {
      setlastNameIsEmpty(true);
    } else if (form.personnal_email === null || form.personnal_email === "") {
      setpersonnalEmailIsEmpty(true);
    } else if (form.sex_id === undefined) {
      setsexIsEmpty(true);
    } else if (form.cin === undefined || form.cin === null || form.cin === "") {
      setcinIsEmpty(true);
    } else {
      setfieldIsEmpty(true);
    }
  }
  useEffect(() => {
    let hours =
      Math.abs(
        new Date("2022-03-02 10:10:06") - new Date("2022-03-01 11:10:06")
      ) / 36e5;
    console.log("hours****", hours);
    if (id !== undefined && id > 0) {
      PersonnelService.getUpdate(id).then((result) => {
        localStorage.setItem(
          "first_name",
          JSON.stringify(result.data.first_name)
        );
        localStorage.setItem(
          "last_name",
          JSON.stringify(result.data.last_name)
        );
        localStorage.setItem(
          "personnal_email",
          JSON.stringify(result.data.personnal_email)
        );
        localStorage.setItem("cin", JSON.stringify(result.data.cin));
        localStorage.setItem("date_cin", JSON.stringify(result.data.date_cin));
        localStorage.setItem("lieu_cin", JSON.stringify(result.data.lieu_cin));
        localStorage.setItem(
          "datep_cin",
          JSON.stringify(result.data.datep_cin)
        );
        localStorage.setItem("ddn", JSON.stringify(result.data.ddn));
        localStorage.setItem("lieu", JSON.stringify(result.data.lieu));
        localStorage.setItem("urgence", JSON.stringify(result.data.urgence));
        localStorage.setItem("sex_id", JSON.stringify(result.data.sex_id));
        localStorage.setItem("adresse", JSON.stringify(result.data.adresse));
        localStorage.setItem("type", JSON.stringify(result.data.type));
        localStorage.setItem(
          "nombre_enfant",
          JSON.stringify(result.data.nombre_enfant)
        );
        localStorage.setItem("nif", JSON.stringify(result.data.nif));
        localStorage.setItem("stat", JSON.stringify(result.data.stat));
        localStorage.setItem(
          "name_image",
          JSON.stringify(result.data.name_image)
        );
        localStorage.setItem("flotte", JSON.stringify(result.data.flotte));
        localStorage.setItem("bureau", JSON.stringify(result.data.bureau));
        localStorage.setItem(
          "matricule",
          JSON.stringify(result.data.matricule)
        );
        localStorage.setItem("fonction", JSON.stringify(result.data.fonction));
        localStorage.setItem("porte", JSON.stringify(result.data.porte));
        localStorage.setItem("siege", JSON.stringify(result.data.seat));
        localStorage.setItem("batiment", JSON.stringify(result.data.building));
        localStorage.setItem("service", JSON.stringify(result.data.service));
        localStorage.setItem(
          "superieur",
          JSON.stringify(result.data.superieur)
        );
        localStorage.setItem("groups", JSON.stringify(result.data.group_id));
        localStorage.setItem("dir", JSON.stringify(result.data.dir));
        localStorage.setItem(
          "marital",
          JSON.stringify(result.data.marital_status)
        );
        localStorage.setItem(
          "url_name_image",
          JSON.stringify(result.data._url_name_image)
        );

        setForm({
          first_name: JSON.parse(localStorage.getItem("first_name")),
          last_name: JSON.parse(localStorage.getItem("last_name")),
          personnal_email: JSON.parse(localStorage.getItem("personnal_email")),
          sex_id: JSON.parse(localStorage.getItem("sex_id")),
          cin: JSON.parse(localStorage.getItem("cin")),
          name_image: JSON.parse(localStorage.getItem("name_image")),
          url_name_image: JSON.parse(localStorage.getItem("url_name_image")),
        });
      });
    } else {
      setForm({
        first_name: JSON.parse(localStorage.getItem("first_name")),
        last_name: JSON.parse(localStorage.getItem("last_name")),
        personnal_email: JSON.parse(localStorage.getItem("personnal_email")),
        sex_id: JSON.parse(localStorage.getItem("sex_id")),
        cin: JSON.parse(localStorage.getItem("cin")),
        name_image: JSON.parse(localStorage.getItem("name_image")),
      });
    }
  }, []);
  function handleAccept() {
    navigate("/personnel");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("personnal_email");
    localStorage.removeItem("name_image");
    localStorage.removeItem("cin");
    localStorage.removeItem("sex_id");
  }
  return (
    <React.Fragment>
      <ConfirmationDialog
        openDialog={openDialog}
        messageDialog={messageDialog}
        closeDialog={closeDialog}
        confirmDialog={confirmDialog}
        cancelDialog={cancelDialog}
      />
      <Message
        isActive={etat.open}
        message={etat.message}
        handleClose={handleMessage}
        handleConfirm={handleAccept}
      />
      <Grid container item xs={12}>
        <Grid container item xs={9} spacing={3}>
          <Grid item xs={3}>
            <label className={classes.labelPosition}>Nom{bullRed}:</label>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Nom"
              variant="outlined"
              inputProps={{ style: { textTransform: "uppercase" } }}
              onChange={handleChange}
              value={form.last_name}
              name="last_name"
              onFocus={handleFocusLastName}
            />
            {lastNameIsEmpty && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Ce champ est obligatoire!</Alert>
              </>
            )}
          </Grid>
          <Grid item xs={3}>
            <label className={classes.labelPosition}>Prénom(s):</label>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Prénom"
              variant="outlined"
              inputProps={{ textTransform: "capitalize" }}
              onChange={handleChange}
              value={form.first_name}
              name="first_name"
            />
          </Grid>
          <Grid item xs={3}>
            <label className={classes.labelPosition}>Sexe{bullRed}:</label>
          </Grid>
          <Grid item xs={9}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="sex_id"
                onChange={handleChange}
                value={form.sex_id}
              >
                <FormControlLabel value="2" control={<Radio />} label="Femme" />
                <FormControlLabel value="1" control={<Radio />} label="Homme" />
              </RadioGroup>
            </FormControl>
            {sexisEmpty && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Veuillez spécifier le sex!</Alert>
              </>
            )}
          </Grid>
          <Grid item xs={3}>
            <label className={classes.labelPosition}>Email{bullRed}:</label>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="email"
              variant="outlined"
              onChange={handleChange}
              onFocus={handleFocusEmail}
              onBlur={handleBlurEmail}
              value={form.personnal_email}
              name="personnal_email"
            />
            {isValidEmail && (
              <Alert severity="error">Adress e-mail invalid</Alert>
            )}

            {personnalEmailIsEmpty && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Ce champ est obligatoire!</Alert>
              </>
            )}
          </Grid>
          <Grid item xs={3}>
            <label className={classes.labelPosition}>CIN{bullRed}:</label>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="CIN"
              variant="outlined"
              onChange={handleChange}
              inputProps={{ maxLength: 18 }}
              value={form.cin}
              name="cin"
            />
            {cinIsEmpty && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Ce champ est obligatoire!</Alert>
              </>
            )}
          </Grid>
          <Grid item xs={3}>
            <Spacer y={10} />
            <label className={classes.text}>
              ({bullRed}):Champs obligatoires
            </label>
          </Grid>
          <Grid item xs={9}>
            <Spacer y={6} />
            <div className={classes.root}>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Suivant
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                Annuler
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.input}>
            {state.file == null && (
              <Button title="Cliquez pour inserer une photo">
                <Avatar
                  className={classes.large}
                  type="file"
                  onChange={handleChange1}
                  accept="image/*"
                  onClick={handleUpload}
                  onMouseOver={handlePopoverOpen}
                >
                  {form.name_image !== "" && form.name_image !== null && (
                    <img
                      src={form.url_name_image}
                      style={{ width: "120px", height: "120px" }}
                    />
                  )}
                  {(form.name_image === "" || form.name_image === null) && (
                    <PhotoCamera className={classes.small} />
                  )}
                </Avatar>
              </Button>
            )}
            {state.file !== null && (
              <>
                <Button title="Cliquez pour inserer une photo">
                  <Avatar
                    className={classes.large}
                    type="file"
                    onChange={handleChange1}
                    accept="image/*"
                    onClick={handleUpload}
                    onMouseOver={handlePopoverOpen}
                  >
                    <img
                      src={state.file}
                      style={{ width: "170px", height: "170px" }}
                    />
                  </Avatar>
                </Button>
              </>
            )}
            <Spacer y={2} />
          </div>
        </Grid>
      </Grid>
      <div>
        <Dialog
          open={opens}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title" style={{ color: "white" }}>
            {"Information"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {
                "La taille de l'image doit être < 1Mb et le type doit être .jpg , .png , .gif"
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <input
              accept="image/*"
              variant="contained"
              color="primary"
              className={classes.type}
              id="contained-button-file"
              onChange={handleChange1}
              multiple
              name="photos"
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Accept
              </Button>
            </label>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
