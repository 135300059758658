import React, { useState,useEffect } from "react";
import { Grid, Paper, Typography } from '@mui/material';
import './Meeting.css';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DoneOutlined from '@mui/icons-material/DoneOutlined';
import CloseIcon from '@mui/icons-material/Close';
import MettingService from "../../services/MettingService";

const MeetingStat = ({ setSelectedStat }) => {
  const [request, setRequest] = useState();
  const [approved, setApproved] = useState();
  const [refused, setRefused] = useState();
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

  useEffect(() => {
    setOpenLoadingSimpleBackdrop(true);
    MettingService.getCountMettingRequest().then((result) => {
    if (result && result.data) {
        setRequest(result.data.meeting_request);
        setOpenLoadingSimpleBackdrop(false);
    }
    else{
        setOpenLoadingSimpleBackdrop(false);
    }
    });

    MettingService.getCountMettingApproved().then((result) => {
      if (result && result.data) {
          setApproved(result.data.meeting_approved);
          setOpenLoadingSimpleBackdrop(false);
      }
      else{
          setOpenLoadingSimpleBackdrop(false);
      }
      });

      MettingService.getCountMettingRefused().then((result) => {
        if (result && result.data) {
            setRefused(result.data.meeting_refused);
            setOpenLoadingSimpleBackdrop(false);
        }
        else{
            setOpenLoadingSimpleBackdrop(false);
        }
        });
  }, []);

  const handleClick = (title) => {
    setSelectedStat(title); 
  };

  const stats = [
    { title: 'Demandes de réunions', value: request, icon: <AssignmentOutlinedIcon />, color: '#357bff', background: '#eaf0ff' },
    { title: 'Réunions Approuvées', value: approved, icon: <DoneOutlined />, color: '#16c463', background: '#e4fdea' },
    { title: 'Réunions Refusées', value: refused, icon: <CloseIcon />, color: '#f82657', background: '#ffe9ec' }
  ];

  return (
    <Grid container spacing={3} className="statistics">
      {stats.map((stat, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} onClick={() => handleClick(stat.title)}>
            <Grid container className='item-card'>
              <Grid item >
                <div className="icon-circle" style={{ backgroundColor: stat.background, width: '3rem', height: '3rem' }}>
                  {React.cloneElement(stat.icon, { style: { color: stat.color } })}
                </div>
              </Grid>
              <Grid item >
                <Typography variant="h5">{stat.value}</Typography>
                <Typography variant="body1" component="p">{stat.title}</Typography>
              </Grid>
            </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default MeetingStat;
