import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL;
const ACCESS_TOKEN = 'ACCESS_TOKEN'

 const ResetPassService = {
  // This is the function to reset the password
  async resetPassword(email) {
    try {
      const result = await axios.post(`${apiUrl}/ForgotPassword/requestChange`, {email});
      if(result.data) {
        localStorage.setItem(ACCESS_TOKEN, result.data);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        return 'Une erreur est survenue! Veuillez réessayer.'
      }
    }
  },
  isReset() {
    return localStorage.getItem(ACCESS_TOKEN) !== undefined
  },

  async resetPasswordSetProtected() {
    try {
      const result = await axios.get(`${apiUrl}/ResetPasswordSetProtected`,{
        headers: {
          'Type': 'resetpasswordprotected',
        }});
      if(result.data) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
}

export default ResetPassService;