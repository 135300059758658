import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  Box,
} from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { extendMoment } from "moment-range";
import { useNavigate,useParams } from "react-router-dom";
import { userDetailContext } from "../../App";
import Moment from "moment";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import MettingService from "../../services/MettingService";
import MeetingRoomService from "../../services/MeetingRoomService";
import { format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import Message from "../tools/Message";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const MeetingRequestAdd = () => {

  const bullRed = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
    >
      *
    </Box>
  )

  const useStyles = makeStyles({
    Title: {
      textAlign: 'center',
      color: '#1976d2',
    },
  });

  const classes = useStyles();

  const navigate = useNavigate();
  const params = useParams();
  const idMetting = params.id;

  const [form, setForm] = useState({
    date_meeting: dayjs(),
    id_meeting_room: "",
    user_meeting: "",
    object_meeting: "",
    link: "",
  });

  const [errors, setErrors] = useState({
    time_meeting_start: false,
    time_meeting_end: false,
    id_meeting_room: false,
    user_meeting: false,
    object_meeting: false,
  });
  const [roomData, setRoomData] = useState([]);

  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;

  const handleDateMetting = (newValue) => {
    setForm({ ...form, date_meeting: newValue });
  };

  const handleTimeMetting_start = (newValue) => {
    setForm({ ...form, time_meeting_start: newValue });
  };

  const handleTimeMetting_end = (newValue) => {
    setForm({ ...form, time_meeting_end: newValue });
  };

  function handleRoom(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  function handleUser(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  function handleObjetReunion(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });

  }

  function handleLink(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  useEffect(() => {
    if (idMetting) {
      MettingService.MettingById(idMetting).then(({ data: info }) => {
        setForm({
          id: info.id_meeting,
          date_meeting: getDate(null,info.date_meeting),
          time_meeting_start: getDate(info.time_meeting_start),
          time_meeting_end: getDate(info.time_meeting_end),
          user_meeting: info.user_meeting,
          object_meeting: info.object_meeting,
          link: info.link,
          id_meeting_room: info.id_meeting_room,
        });
      });
    }

    MeetingRoomService.getMettingRoom().then((results) => {
      if (results && results.data) {
        setRoomData(results.data);
      }
    });
  }, []);

  const getDate = (time , dateString) => {
    try {

      let dateObj = new Date();
  
      if (dateString) {
        const [year, month, day] = dateString.split('-');
        dateObj.setFullYear(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)); // Months are 0-indexed
      }
      if (time) {
        const [hours, minutes, seconds] = time.split(':');
        dateObj.setHours(parseInt(hours, 10), parseInt(minutes, 10), parseInt(seconds || 0)); // Default seconds to 0
      }
      return dayjs(dateObj);
    } catch (error) {
      console.error("Error parsing time:", error);
      return null; // Or handle the error as needed
    }
  };

  const handleClick = () => {
    console.log("date : "+form.date_meeting);

    const newErrors = {
      time_meeting_start: form.time_meeting_start === '',
      time_meeting_end: form.time_meeting_end === '',
      id_meeting_room: form.id_meeting_room === '',
      user_meeting: form.user_meeting === '',
      object_meeting: form.object_meeting === '',
    };

    setErrors(newErrors);

    const formattedDate = form.date_meeting.format('YYYY-MM-DD');
    const formattedTimeMeetingStart = form.time_meeting_start.format('HH:mm');
    const formattedTimeMeetingEnd = form.time_meeting_end.format('HH:mm');

    
      MettingService.saveMetting(formattedDate, formattedTimeMeetingStart, formattedTimeMeetingEnd, form.user_meeting, form.object_meeting, form.link, form.id_meeting_room, form.id)
      .then((res) => {
          var responseErrorMessage = res.message;
          if (res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
          navigate("/Logistic/meeting_management");
          } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: responseErrorMessage });
          }
      })
      .catch((err) => {
          setMessage({ ...message, open: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
      });
  };

  function handleCancel() {
    navigate("/Logistic/meeting_management");
  }

  function handleCloseMessage() {
    setMessage({ ...message, openMessage: false });
  }

  return (
    <MainContainer {...headerDataImmo}>
      <Message open={message.open} color={message.color} messageInfo={message.messageInfo} handleClose={handleCloseMessage} />
      <Container 
        sx={{
          width: '700px',
        }}
      >
        <Box component="form" mt={2}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom className={classes.Title}>
                  Planification de Réunion
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  mask="__ / __ / ____"
                  value={form.date_meeting}
                  onChange={(handleDateMetting)}
                  renderInput={(params) =>
                    <TextField {...params} fullWidth variant="outlined" size="small" id="date-picker-dialog"  color="primary" error={false} />
                  }
                />
              </LocalizationProvider>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={form.time_meeting_start}
                  onChange={handleTimeMetting_start}
                  renderInput={(params) =>
                    <TextField {...params} fullWidth variant="outlined" size="small" color="primary" required error={errors.time_meeting_start} />
                  }
                  ampm={false}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={form.time_meeting_end}
                  onChange={handleTimeMetting_end}
                  renderInput={(params) =>
                    <TextField {...params} fullWidth variant="outlined" size="small" color="primary" required error={errors.time_meeting_end} />
                  }
                  ampm={false}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" size="small" required error={errors.id_meeting_room}>
                <InputLabel id="demo-simple-select-outlined-label" >
                  Salle
                </InputLabel>
                <Select labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={form.id_meeting_room}
                  name="id_meeting_room"
                  label="Salle"
                  onChange={handleRoom}>
                  {roomData.map((row) => (
                    <MenuItem value={row.id_meeting_room}>{row.room}</MenuItem>

                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="outlined-basic"
                size="small"
                label="Utilisateur"
                variant="outlined"
                value={form.user_meeting}
                onChange={handleUser}
                name="user_meeting"
                error={errors.user_meeting}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="outlined-basic"
                size="small"
                label="Objet"
                variant="outlined"
                value={form.object_meeting}
                onChange={handleObjetReunion}
                name="object_meeting"
                error={errors.object_meeting}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="Lien"
                variant="outlined"
                value={form.link}
                onChange={handleLink}
                name="link"
              />
            </Grid>
          </Grid>

          <Box mt={2} display="flex" justifyContent="space-between">
            <Button onClick={handleCancel}>
              ANNULER
            </Button>
            <Button variant="contained" color="primary" onClick={handleClick}>
              ENREGISTRER
            </Button>
          </Box>

        </Box>
        {/* Add more steps content as needed */}
      </Container>
    </MainContainer>
    
  );
};

export default MeetingRequestAdd;
