import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, MenuItem, Select, FormControl } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
    Button,
    Checkbox,
    ListItemText,
    Chip, TextField
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataRH } from "../../../data/modules/hr-header";
import { userDetailContext } from "../../../App";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { useNavigate, useLocation } from 'react-router-dom';
import BalanceConfigurationService from "../../../services/BalanceConfigurationService";
import LeaveBalanceSummaryTable from "./LeaveBalanceSummaryTable";
import { GetAppTwoTone } from "@material-ui/icons";
import FileService from "../../../services/FileService";
import * as FileSaver from "file-saver";
import UserService from "../../../services/UserService";
import LeaveBalanceSummaryOfSubordonnate from "./LeaveBalanceSummaryOfSubordonnate";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        elevation: 0,
    },
}));

export default function LeaveBalanceSummary(props) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const navigate = useNavigate();
    const location = useLocation();
    const [rows, setRows] = useState([]);
    const [value, setValue] = useState(1);
    const [userId, setUserId] = useState("");
    const [IsUpperHierarchy, setIsUpperHierarchy] = useState(false);
    const [listOfSubordonnate, setListOfSubordonnate] = useState([]);
    const [detailListOfSubordonnate, setDetailListOfSubordonnate] = useState([]);
    const [specificListOfSubordonnate, setSpecificListOfSubordonnate] = useState([]);
    const [serviceName, setServiceName] = useState("");
    const [initialBalance, setinitialBalance] = useState("");
    const [initialBalancedate, setinitialBalancedate] = useState("");
    const [initialBalancedateFormat, setinitialBalancedateFormat] = useState("");
    const [actualBalance, setactualBalance] = useState("");
    const contextData = useContext(userDetailContext);
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
    const classes = useStyles();

    function getInitialActualBalance() {
        // BalanceConfigurationService.getInitialBalanceByUserConnected().then((res) => {
        //     if (res && res.status) {
        //         setinitialBalance(res.data.initialBalance);
        //         setinitialBalancedate(res.data.initialBalanceDate)
        //     }
        // });
        // BalanceConfigurationService.getConfigBalanceByUserConnected().then((res) => {
        //     if (res && res.status) {
        //         setactualBalance(res.data.real_balance);
        //     }
        // });
        BalanceConfigurationService.getUserConnectedBalanceDetail().then((res) => {
            console.log(res.data)
            if (res && res.status) {
                console.log(res.data);
                setinitialBalance(res.data.initialBalance);
                setinitialBalancedate(res.data.initialBalanceDate);
                setinitialBalancedateFormat(res.data.initialBalanceDateFormat);
                setactualBalance(res.data.actualBalance);
            }
        });
    }

    function LoadTable() {
        setOpenLoadingSimpleBackdrop(true);
        BalanceConfigurationService.getLeaveSummaryBalanceByUserConnected().then((res) => {
            if (res && res.data) {
                setRows(res.data.map((row) => ({ ...row, id: row.balance_configuration_id })));
                setOpenLoadingSimpleBackdrop(false);
            } else {

                setOpenLoadingSimpleBackdrop(false);
            }
        });
    }

    function handleClickExport() {
        var rowsToExport = [];
        var type = 0;
        if (value == 1) {
            rowsToExport = rows;
            type = 1;
        } else if (value == 2 && specificListOfSubordonnate.length == 0) {
            rowsToExport = detailListOfSubordonnate;
            type = 2;
        } else {
            rowsToExport = specificListOfSubordonnate;
            type = 2;
        }
        BalanceConfigurationService.exportSummaryBalanceByUserConnected(type, rowsToExport, initialBalance, initialBalancedateFormat, actualBalance).then((res) => {
            if (res && res.status) {
                const filenameResult = res.data;
                console.log("file***", filenameResult);
                FileService.readExcelFileContent("Leaves", filenameResult).then((result) => {
                    if (result && result.status) {
                        const data = new Blob([result.data], { type: fileType });
                        FileSaver.saveAs(data, filenameResult);
                    }
                })
            }
        })
    }

    function handleChangeValue(ev) {
        setValue(ev.target.value);
    }

    function handleChangeUsers(event, value){
        setSpecificListOfSubordonnate(value);
    }

    useEffect(() => {
        var userId = localStorage.getItem("USER");
        setUserId(userId);
        LoadTable();
        getInitialActualBalance();

    }, [location]);

    useEffect(() => {
        if (userId !== "") {
            UserService.getUserSummaryByUserId(userId).then((res) => {
                if (res && res.status) {
                    if (res.data.service_id != null) {
                        setServiceName(res.data.service_code);
                    }
                }
            });

            UserService.getAllUserSubordonate(userId).then((res) => {
                if (res && res.status && res.data.length > 0) {
                    console.log("ressss", res);
                    setIsUpperHierarchy(true);
                    setListOfSubordonnate(res.data)
                    // setListOfSubordonnate(res.data.map((row) => ({ ...row, id: row.user_id })));
                }
            });
        }
    }, [userId]);

    useEffect(() => {
        if (value == 2) {
            BalanceConfigurationService.getLeaveSummaryBalanceBySomeUser(listOfSubordonnate).then((res) => {
                if (res && res.status) {
                    setDetailListOfSubordonnate(res.data.map((row, index) => ({ ...row, id: index })));
                }
            });
        }

    }, [value]);
    return (
        <MainContainer {...headerDataRH}>
            <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
            <Grid container item xs={12}>
                {IsUpperHierarchy &&
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined" size="small" style={{ background: "rgb(255, 242, 0)" }}>
                            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={value} name="semester" onChange={handleChangeValue} >
                                <MenuItem value="1">Mien</MenuItem>
                                <MenuItem value="2">Tous mes subordonnés</MenuItem>
                                {/* <MenuItem value="3">Spécifique</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Spacer x={1} />
                {/* {value == 3 &&

                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <Select

                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                multiple
                                // onFocus={handleSelectGroups}
                                // onChange={handleChange1}
                                value={userSelected}
                                name="user"
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value}

                                                label={getItemLabel1(value)}
                                                className={classes.chip}
                                            />
                                        ))}
                                    </div>
                                )}
                            >
                                {detailListOfSubordonnate.map((row) => (
                                    <MenuItem value={row.id}>
                                        <Checkbox checked={userSelected.includes(row.index)} />

                                        <ListItemText primary={row.first_name} />
                                    </MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    </Grid>
                } */}
                {value == 2 &&

                    <Grid item xs={3}>
                        <Autocomplete
                            onChange={handleChangeUsers}
                            options={detailListOfSubordonnate}
                            defaultValue={specificListOfSubordonnate}
                            getOptionLabel={(option) => option.first_name}
                            multiple
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label="Subordonnés"
                                    InputProps={{ ...params.InputProps, type: "search" }}
                                />
                            )}
                        />
                    </Grid>
                }

                <Grid item xs={3}
                    style={{ fontWeight: 600, marginLeft: "auto" }}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleClickExport}
                        startIcon={<GetAppTwoTone />}
                    >
                        Exporter version excel
                    </Button>

                </Grid>
            </Grid>
            <Spacer y={2} />
            <Grid container spacing={3} item>
                <Grid item xs={12}>
                    <Paper className={classes.paper} variant="outlined">
                        {value == 1 &&
                            <LeaveBalanceSummaryTable rows={rows} initialBalance={initialBalance} initialBalancedate={initialBalancedate} initialBalancedateFormat={initialBalancedateFormat} actualBalance={actualBalance} />
                        }
                        {value == 2 &&
                            <LeaveBalanceSummaryOfSubordonnate rows={specificListOfSubordonnate.length >0?specificListOfSubordonnate: detailListOfSubordonnate} initialBalancedate={initialBalancedate} />
                        }
                    </Paper>
                </Grid>
            </Grid>
        </MainContainer>
    );
}
