import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import AntenneTable from "./AntenneTable";
import { headerDataAdmin } from "../../data/modules/admin-header";
import { userDetailContext } from "../../App";
import AntenneService from "../../services/AntenneService";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function AntenneList(props) {
  const navigate = useNavigate();
  const contextData = useContext(userDetailContext);
  const [etat, setEtat] = useState([]);
  const [fileName, setFileName] = useState('fileName');
  const [dataToExport, setDataToExport] = useState([]);
  useEffect(() => {
    AntenneService.getService().then((result) => {
      if (result && result.data) {
        setDataToExport(result.data);
      }
    });
  }, []);
  function handleClick() {
    navigate("/antenne/add/");
  }
  function handleCloseDialog() {
    setEtat({
      open: false,
      message: 'message'
    });
  }
  function handelClikImport() {
    setEtat({
      open: true,
    });
  }
  const classes = useStyles();

  return (
    <MainContainer {...headerDataAdmin}>
      <Grid container item xs={12}>
        {/* <Button variant="contained" onClick={handelClikImport} style={{ fontWeight: 600 }}>
              IMPORTER
        </Button> */}
        {/* <ImportCSV isActive={etat.open} closeDialog={handleCloseDialog} /> */}
        <Spacer x={2} />
        {/* <ExportExcel dataToExport={dataToExport} fileName={fileName} /> */}
        <Spacer x={2} />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          CREER
        </Button>
        <Spacer x={2} />
        {/* <TextField
          id="outlined-basic"
          label="Recherche"
          variant="outlined"
          size="small"
        /> */}
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            < AntenneTable />
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
