import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const LeaveNextWeekService = {
  
 

  async getLeaveType() {
    try {
      const result = await axios.get(`${apiUrl}/LeaveType/`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

 
  async getLeavesByOptions(options) {
    try {
      const result = await axios.get(`${apiUrl}/LeaveNextWeek`, {params: options});
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getLeaveById(id) {
    try {
      const result = await axios.get(`${apiUrl}/leave/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  
  async getLeavesStaffPerNextWeek() {
    try {
      const result = await axios.get(`${apiUrl}/LeaveNextWeek/getLeavesStaffPerNextWeek`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
 
  
 
  
};

export default LeaveNextWeekService;
