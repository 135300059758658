import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const GroupService = {
    async saveGroup(name, desc, privilege, id) {
         try {
            const result = id ?
             await axios.put(`${apiUrl}/group/id/${id}`, {
              id,
              name,
              desc,
              privilege
           })
           :
             await axios.post(`${apiUrl}/group/`, {
              name,
              desc,
              privilege
           });
           if (result) {
             // TODO: replace result.data.message by the token
    
              return result.data;
           }
          } catch (error) {
            if (error.response) {
              return error.response.data;
             }
           }
    } ,
    

 //modification de donnée
    async groupById(id){
      try {
          const result = await axios.get(`${apiUrl}/group/id/${id}`  );
          if (result) {
            // TODO: replace result.data.message by the token
  
            return result.data;
          }
        } 
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      }
  ,
  async getModuleWithPrivilege(){
    try {
        const result = await axios.get(`${apiUrl}/module/withPrivilege`  );
        if (result) {
          // TODO: replace result.data.message by the token

          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    }
,
  async getGroupWithPrivilege(options){
    try {
        const result = await axios.get(`${apiUrl}/group/withPrivilege`, 
        {params: options});
        if (result) {
          // TODO: replace result.data.message by the token

          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    }
,

async deleteGroup(id){
    try {
        const result = await axios.delete(`${apiUrl}/group/id/${id}`  );
        if (result) {
          // TODO: replace result.data.message by the token
          return result.data;
        }
      } 
      catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    },

    async getGroup(){
        try {
            const result = await axios.get(`${apiUrl}/group/`  );
            if (result) {
              // TODO: replace result.data.message by the token
    
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        
      }
       ,
        
    

}

export default GroupService;