import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataMission } from "../../../data/modules/mission-header";
import PlanningMissionService from "../../../services/PlanningMissionService";
import LocalisationMisService from "../../../services/LocalisationMisService";

import FundingService from "../../../services/FundingService";
import Alert from "@material-ui/lab/Alert";
import Message from "../../tools/Message";
//
import { AntTab, AntTabs } from "../../../components/ant-tab";
import { Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import PlanningMissionDetailsAdd from "./PlanningMissionDetailsAdd";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useNavigate } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
//

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 750,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: "left",
  },
  labelPosition1: {
    float: "right",
  },
  text: {
    width: 370,
  },
}));

function PlanningMissionAdd(props) {
  //
  const [isValidMission_title, setIsValidMission_title] = useState(false);
  const [isValidType_activity_id, setIsValidType_activity_id] = useState(false);
  const [isValidCategory_mission_id, setIsValidCategory_mission_id] =
    useState(false);
  const [isValidDate, setIsValidDate] = useState(false);
  const [isValidDirection_destination, setIsValidDirection_destination] =
    useState(false);
    const [isValidRegion_destination, setIsValidRegion_destination] =
    useState(false);
  const [isValidDistrict_destination, setIsValidDistrict_destination] =
    useState(false);
  const [isValidTransport_type, setIsValidTransport_type] = useState(false);
  const [isValidCommon, setIsValidCommon] = useState(false);
  const [isValidLocation, setIsValidLocation] = useState(false);
  const [isDateEmpty, setIsDateEmpty] = useState(false);
  const [isValidFunding_id, setIsValidFunding_id] = useState(false);
  const navigate = useNavigate();
  //
  const classes = useStyles();
  const [form, setForm] = useState({
    category_mission_id: {},
    mission_title: "",
    direction_destination: {},
    region_destination: {},
    district_destination: {},
    commune_destination: {},
    localisation_destination: "",
    transport_type: "",
    mission_begin_date: "",
    mission_end_date: "",
    common: "",
    location: "",
    type_activity_id: [],
    funding_id: [],
  });

  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;

  const [activity, setActivity] = useState([]);
  const [category, setCategory] = useState([]);
  const [funding, setFunding] = useState([]);
  const [directionInMis, setDirectionInMis] = useState([]);
  const [regionInMis, setRegionInMis] = useState([]);
  const [districtInMis, setdistrictInMis] = useState([]);
  const [communeInMis, setcommuneInMis] = useState([]);
  const [localisationInMis, setlocalisationInMis] = useState([]);

  const [defaultCategoryMission, setDefaultCategoryMission] = React.useState({
    category_mission_id: 0,
    name: "",
  });

  // const [mission_planning_id, setMission_planning_id] = useState(JSON.parse(localStorage.getItem('MISSION_PLANNING_TEMP_ID')));
  const MISSION_PLANNING_TEMP = JSON.parse(
    localStorage.getItem("MISSION_PLANNING_TEMP")
  );
  useEffect(() => {
    //
    PlanningMissionService.getActivity().then((result) => {
      if (result && result.data) {
        setActivity(result.data);
      }
    });
    PlanningMissionService.getCategory().then((result) => {
      if (result && result.data) {
        setCategory(result.data);
      }
    });
    LocalisationMisService.getAllDirection().then((result) => {
      console.log("resultat ", result);
      if (result && result.data) {
        setDirectionInMis(result.data);
      }
    });

    FundingService.getFunding().then((result) => {
      if (result && result.data) {
        setFunding(result.data);
      }
    });
  }, []);

  function handleClick() {
    console.log(form.district_destination + "sdsdsds");
    if (form.mission_begin_date === "" || form.mission_end_date === "") {
      setIsDateEmpty(true);
    }
    if (form.direction_destination === null) {
      setIsValidDirection_destination(true);
    }
    if (form.region_destination === null) {
      setIsValidRegion_destination(true);
    }
    if (form.district_destination === "") {
      setIsValidDistrict_destination(true);
    }
    if (form.transport_type === "") {
      setIsValidTransport_type(true);
    }
    //
    if (form.category_mission_id === null) {
      setIsValidCategory_mission_id(true);
    }
    if (form.funding_id === null) {
      setIsValidFunding_id(true);
    }
    if (form.type_activity_id.length === 0) {
      setIsValidType_activity_id(true);
    }
    if (form.mission_title === "") {
      setIsValidMission_title(true);
    }
    if (
      form.mission_title !== "" &&
      form.type_activity_id.length !== 0 &&
      form.category_mission_id !== null &&
      form.direction_destination !== null &&
      form.region_destination !== null &&
      form.district_destination !== "" &&
      form.transport_type !== "" &&
      form.mission_begin_date !== "" &&
      form.mission_end_date !== "" &&
      form.funding_id !== null
    ) {
      localStorage.setItem("MISSION_PLANNING_TEMP", JSON.stringify(form));
      setValue(1);
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  }

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  function handleChangeTypeActivity(event, value) {
    setForm({ ...form, type_activity_id: value });
  }
  function handleChangeCategory(event, value) {
    setForm({ ...form, category_mission_id: value });
  }

  function handleChangeFunding(event, value) {
    setForm({ ...form, funding_id: value });
  }

  function handleChangeDirection(event, value) {
    setForm({ ...form, direction_destination: value });
  }

  function handleChangeDate(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    if (form.mission_begin_date >= form.mission_end_date) {
      setIsValidDate(true);
    } else {
      setIsValidDate(false);
    }
  }


  //


  function handleChangeDirection(event, value) {
    console.log("**************1**********");
    console.log(value.libelle);

    setForm({ ...form, direction_destination: value.libelle });
    LocalisationMisService.getRegionsByDirection(value.code).then((result) => {
      console.log("**************2**********");
      console.log(result.data); 

      if (result && result.data) {
        console.log("*************3***********");
        setRegionInMis(result.data);
      }
    });
  }
  
  function handleChangeRegion(event, value) {
    console.log(value.nom_region);
    setForm({ ...form, region_destination: value.nom_region });
    LocalisationMisService.getDistrictByRegion(value.id_region).then((result) => {
      if (result && result.data) {
        setdistrictInMis(result.data);
      }
    });
  }


  function handleChangeDistrict(event, value) {
    console.log(value.nom_district);
    setForm({ ...form, district_destination: value.nom_district });
    LocalisationMisService.getCommunesByDistrict(value.id_district).then((result) => {
      if (result && result.data) {
        setcommuneInMis(result.data);
      }
    });
  }
  

  function handleChangeCommune(event, value) {
    console.log(value);
      setForm({ ...form, commune_destination: value.nom_commune });
    LocalisationMisService.getFokontantByCommune(value.id_commune).then((result) => {
      if (result && result.data) {
        setlocalisationInMis(result.data);
      }
    });
  }

  function handleChangeLocalisation(event, value) {
    setForm({ ...form, localisation_destination: value });
  }





  //
  const [enablePlanningDetails, setEnablePlanningDetails] = useState(true);
  const [enableAgenda, setEnableAgenda] = useState(true);
  const [value, setValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  //
  function handleCancel() {
    navigate("/mission/planning_mission");
  }
  return (
    <MainContainer {...headerDataMission}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <Spacer y={2} />
      <Paper className={classes.root}>
        <AntTabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary" /*centered*/
        >
          <AntTab label="Planification" />
          <AntTab label="Détails Planification" />
          {/* <AntTab label="Agenda de Mission" /> */}
        </AntTabs>
        <TabPanel value={value} index={0}>
          <Grid container spacing={3} className={classes.paper}>
            <Grid item xs={12}>
              <fieldset>
                <legend>Objet de la mission</legend>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <label className={classes.labelPosition}>Financement</label>
                  </Grid>
                  <Grid item xs={10}>
                  <Autocomplete
                      multiple
                      options={funding}
                      getOptionLabel={(option) => option.code}
                      filterSelectedOptions
                      onChange={handleChangeFunding}
                      value={form.funding_id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="outlined-basic"
                          size="small"
                          label="Composante"
                          variant="outlined"
                          placeholder="Composante"
                        />
                      )}
                    />
                    {isValidFunding_id && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <label className={classes.labelPosition}>Composante</label>
                  </Grid>
                  <Grid item xs={10}>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={activity}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      onChange={handleChangeTypeActivity}
                      value={form.type_activity_id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="outlined-basic"
                          size="small"
                          label="Composante"
                          variant="outlined"
                          placeholder="Composante"
                        />
                      )}
                    />
                    {isValidType_activity_id && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <label className={classes.labelPosition}>Catégorie</label>
                  </Grid>
                  <Grid item xs={10}>
                    <Autocomplete
                      multiple
                      onChange={handleChangeCategory}
                      options={category}
                      getOptionLabel={(option) => option.name}
                      // defaultValue={{"category_mission_id": defaultCategoryMission.category_mission_id, "name": defaultCategoryMission.name }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="outlined-basic"
                          label="Catégorie"
                          size="small"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                    {isValidCategory_mission_id && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <label className={classes.labelPosition}>Intitulé</label>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      size="small"
                      label="Intitulé"
                      variant="outlined"
                      value={form.mission_title}
                      name="mission_title"
                      onChange={handleChange}
                    />
                    {isValidMission_title && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )}
                  </Grid>
                </Grid>
                <br />
              </fieldset>
            </Grid>
            <Grid item xs={12}>
              <fieldset>
                <legend>Date de la mission</legend>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <label className={classes.labelPosition}>Du</label>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      size="small"
                      label=""
                      variant="outlined"
                      type="date"
                      value={form.mission_begin_date}
                      name="mission_begin_date"
                      onChange={handleChangeDate}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <center>
                      <label>à</label>
                    </center>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      size="small"
                      label=""
                      variant="outlined"
                      type="date"
                      value={form.mission_end_date}
                      name="mission_end_date"
                      onChange={handleChangeDate}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    {isValidDate && (
                      <Alert severity="error">
                        veuillez verifier les dates de votre mission
                      </Alert>
                    )}
                    {isDateEmpty && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )}
                  </Grid>
                </Grid>
              </fieldset>
            </Grid>
            <Grid item xs={12}>
            <fieldset>
                <legend>Généralité Destination</legend>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <label className={classes.labelPosition}>Direction</label>
                  </Grid>
                  <Grid item xs={9}>
                    <Autocomplete
                      onChange={handleChangeDirection}
                      options={directionInMis}
                      getOptionLabel={(option) => option.libelle}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Direction"
                          size="small"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                    {isValidDirection_destination && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <label className={classes.labelPosition}>Region</label>
                  </Grid>
                  <Grid item xs={9}>
                    <Autocomplete
                      onChange={handleChangeRegion}
                      options={regionInMis}
                      getOptionLabel={(option) => option.nom_region}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Region"
                          size="small"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                    {isValidRegion_destination && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <label className={classes.labelPosition}>District{bullRed}</label>
                  </Grid>
                  <Grid item xs={9}>
                  <Autocomplete
                      onChange={handleChangeDistrict}
                      options={districtInMis}
                      getOptionLabel={(option) => option.nom_district}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="outlined-basic"
                          label="District"
                          size="small"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                    {isValidDistrict_destination && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <label className={classes.labelPosition}>Commune</label>
                  </Grid>
                  <Grid item xs={9}>
                  <Autocomplete
                      onChange={handleChangeCommune}
                      options={communeInMis}
                      getOptionLabel={(option) => option.nom_commune}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="outlined-basic"
                          label="Commune"
                          size="small"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                    {/* {isValidCommon && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )} */}
                  </Grid>
                  <Grid item xs={3}>
                    <label className={classes.labelPosition}>
                      Localisation
                    </label>
                  </Grid>
                  <Grid item xs={9}>
                  <TextField
                      fullWidth
                      id="outlined-basic"
                      size="small"
                      label="Localisation"
                      variant="outlined"
                      value={form.localisation_destination}
                      name="localisation_destination"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <br />
              </fieldset>
            </Grid>
            <Grid item xs={12}>
              <fieldset>
                <legend>Moyen de transport</legend>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <label className={classes.labelPosition}>Transport</label>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid item xs={10}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="demo-simple-select-outlined-label">
                        Transport
                        </InputLabel>
                        <Select
                          // onChange={handleChangeDirection}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="direction"
                          value={form.transport_type}
                          // onFocus={handleSelect}
                          name="transport_type"
                          onChange={handleChange}
                        >
                          <MenuItem value="voiture FID">voiture FID</MenuItem>
                          <MenuItem value="voiture location">voiture location</MenuItem>

                        </Select>
                      </FormControl>
                    </Grid>
                    {isValidTransport_type && (
                      <Alert severity="error">Veuillez remplir ce champ</Alert>
                    )}
                  </Grid>
                </Grid>
                <br />
              </fieldset>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.labelPosition1}
                variant="contained"
                color="primary"
                onClick={handleClick}
                style={{ fontWeight: 600 }}
              >
                VALIDER
              </Button>
              <Button
                variant="contained"
                color="grey"
                onClick={handleCancel}
                style={{ fontWeight: 600 }}
              >
                ANNULER
              </Button>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {MISSION_PLANNING_TEMP !== null && <PlanningMissionDetailsAdd />}
          {MISSION_PLANNING_TEMP === null && (
            <Alert severity="warning">
              Veuillez remplir les informations nécessaires dans la
              Planification de la Mission svp !
            </Alert>
          )}
        </TabPanel>
      </Paper>
    </MainContainer>
  );
}

export default PlanningMissionAdd;