import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spacer from "../../components/Spacer";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import RefusedRequest from "./RefusedRequest";
import ValidateRequest from "./ValidateRequest";
import RefusedRequestService from "../../services/RefusedRequestService";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import LeaveService from "../../services/LeaveService";
import AcceptedHelpService from "../../services/AcceptedHelpService";
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  btnAnnulerDialog: {
    background: "red",
    color: "white",
  },
  btnValiderDialog: {
    background: "rgb(19, 62, 103)",
    color: "white",
  },
  label: {
    fontWeight: "bold",
  },
  step1Title: {
    textAlign: "center",
  },
  step1Grid: {
    padding: "10px",
  },
  gridItem: {
    height: "25px",
  },
}));

export default function RequestSummary(props) {
  const navigate = useNavigate();
  const requestSummaryId = props.requestSummaryId;
  const classes = useStyles();
  const open = props.openDialogRequestSummary;
  const handleCloseRequestSummary = props.handleCloseRequestSummary;
  const [step, setStep] = useState(1);
  const [openRefusedRequest, setOpenRefusedRequest] = useState(false);
  const [openValidateRequest, setOpenValidateRequest] = useState(false);
  const [formular, setFormular] = useState([]);
  const [balanceBefore, setBalanceBefore] = useState(0);
  const [realdelta, setRealdelta] = useState(0);
  const [balanceAfters, setBalanceAfters] = useState(0);
  const [afterdelta, setAfterdelta] = useState(0);
  const [leave, setLeave] = useState([]);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
  const [openDialogRequestSummary, setOpenDialogRequestSummary] = React.useState(false);
  const [isLoading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [message, setMessage] = React.useState({
    openMessage: false,
    color: "green",
    messageInfo: "",
  });

  useEffect(() => {

    setOpenLoadingSimpleBackdrop(true);
    AcceptedHelpService.getById(requestSummaryId.id).then((result) => {
      console.log("aaaaaezazezyyyezezezezezeze");
      console.log(requestSummaryId.number_day);

      if (result && result.data) {
        setLeave(result.data[0]);
        console.log(leave);
        setOpenLoadingSimpleBackdrop(false);
      }
    });

  }, []);
  function handleRefused() {
    RefusedRequestService.getFormular(requestSummaryId.module_type_id, requestSummaryId.type_observation).then((result) => {
      if (result && result.data) {
        setFormular(result.data);
      }
    });
    setStep(2);
    setOpenRefusedRequest(true);
  }

  function handleValidateRequest() {
    setLoading(true);
    console.log("*********456**" + requestSummaryId.mission_request_id);
    AcceptedHelpService.saveMissionRequestUserRequiredSup(requestSummaryId.mission_request_id)
      .then((res) => {
        var responseErrorMessage = res.message;
        if (res.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
          navigate("/mission/planning_mission");
          setLoading(false);
          navigate("/mission/planning_mission");
          setOpenDialogRequestSummary(false);
          setOpenDialog(false);
        } else {
          navigate("/mission/planning_mission");
          setMessage({ ...message, open: true, color: 'red', messageInfo: responseErrorMessage });
        }
      })
      .catch((err) => {
        setLoading(false);
        setOpenDialogRequestSummary(false);
        setOpenDialog(false);
        setMessage({ ...message, open: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
      });
  }


  const handleCloseRefusedRequest = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setStep(1);
    setOpenRefusedRequest(false);
  }

  const handleCloseValidateRequest = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setStep(1);
    setOpenValidateRequest(false);
  }
  return (
    <>
      <RefusedRequest
        type_observation={requestSummaryId.type_observation}
        formular={formular}
        module_type_id={requestSummaryId.module_type_id}
        file_validation_request_id={requestSummaryId.file_validation_request_id}
        validation_id={requestSummaryId.validation_id}
        openRefusedRequest={openRefusedRequest}
        handleCloseRefusedRequest={handleCloseRefusedRequest} />
      <ValidateRequest
        openValidateRequest={openValidateRequest}
        id={requestSummaryId.id}
        mission_request_id={requestSummaryId.mission_request_id}
        mission_request_reference={requestSummaryId.mission_request_reference}
        mission_request_user_required_mission_request_id={requestSummaryId.mission_request_user_required_mission_request_id}
        mission_request_user_required_user_id={requestSummaryId.mission_request_user_required_user_id}
        name={requestSummaryId.name}
        number_day={requestSummaryId.number_day}
        reason={requestSummaryId.reason}
        service_id={requestSummaryId.service_id}
        type_mission_request={requestSummaryId.type_mission_request}
        user_id={requestSummaryId.user_id}
        date={requestSummaryId.date}
        description={requestSummaryId.description}
        file_request_id={requestSummaryId.file_request_id}
        handleCloseValidateRequest={handleCloseValidateRequest} />
      {step == 1 &&
        <Dialog open={open} onClose={handleCloseRequestSummary}
          aria-labelledby="form-dialog-title1" fullWidth maxWidth="sm">
          <DialogTitle id="form-dialog-title1">
            Validation éléctronique de l'acceptation de demande
          </DialogTitle>
          <DialogContent>

            <DialogContentText>

              <Grid container spacing={2} >

                {/* <Grid item xs={4}>
                <label className={classes.id}>Identification:</label>
              </Grid>
             
              <Grid item xs={8}>
                <label className={classes.id}>{leave['leave_code']}</label>
              </Grid> */}

                <Grid item xs={4}>
                  <label className={classes.label}>Nom et Prénom du demandeur:</label>
                </Grid>
                <Grid item xs={8}>
                  <label className={classes.label1}>{leave.name}</label>
                </Grid>

                <Grid item xs={4}>
                </Grid>
                <Grid item xs={6}>
                </Grid>

                <Grid item xs={4}>
                  <label className={classes.label}>Date :</label>
                </Grid>
                <Grid item xs={2}>
                  <label className={classes.label1}>{leave['date']}</label>

                </Grid>
                <Grid item xs={6}>
                </Grid>

                <Grid item xs={4}>
                  <label className={classes.label}>Nombre de jours:</label>
                </Grid>
                <Grid item xs={8}>
                  <label className={classes.label1}>{leave['number_day']}</label>
                </Grid>
                <Grid item xs={4}>
                  <label className={classes.label}>Motif:</label>
                </Grid>
                <Grid item xs={8}>
                  <label className={classes.label1}>{leave['reason']}</label>
                </Grid>
              </Grid>
              <br></br>
              <span>
                La demande sous forme{" "}
                <Link to={"/file/" + requestSummaryId.path_folder + "/" + requestSummaryId.filename} target="_blank" > pdf. </Link>
                <br></br>
                Confirmez-vous votre action?

              </span>
            </DialogContentText>

          </DialogContent>
          <br />
          <DialogActions>
            <Button style={{ backgroundColor: "##f50057", color: "#FFFFFF" }} onClick={handleCloseRequestSummary} color="secondary" variant="contained" >
              Annuler
            </Button>
            <Button style={{ backgroundColor: "#3f51b5", color: "#FFFFFF" }} onClick={handleRefused} color="primary" variant="contained" >
              Refuser
            </Button>
            <Button style={{ backgroundColor: "rgb(19, 62, 103)", color: "#FFFFFF", }} onClick={handleValidateRequest} color="primary" variant="contained" >
              Valider
            </Button>
          </DialogActions>


        </Dialog>}
    </>
  );
}
