import React, { useState, useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import InventoryService from "../../services/InventoryService";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";

let myChart; // Référence globale pour le graphique

const StatistiqueEtatTable = () => {
  const canvasRef = useRef(null);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = useState(false);
  const [data, setData] = useState([
    { label: "BON ETAT", color: "#66bb6a", value: 0 },
    { label: "MAUVAIS ETAT", color: "#ef5350", value: 0 },
    { label: "ETAT MOYEN", color: "#ffd54f", value: 0 },
    { label: "TRES BON ETAT", color: "#42a5f5", value: 0 },
  ]);

  const [numericValues, setNumericValues] = useState({});

  const initializeStorage = (name) => {
    if (!localStorage.getItem(name)) {
      localStorage.setItem(name, "");
      console.log("ambany");
    }
  };

  const loadTable = (date1, date2) => {
    setOpenLoadingSimpleBackdrop(true);
    InventoryService.getEtatValue(date1, date2).then((result) => {
      if (result && result.status) {
        const updatedData = data.map((item) => {
          const foundItem = result.data.find((row) => row.state === item.label);
          return { ...item, value: foundItem ? parseInt(foundItem.nombre) : 0 };
        });
        setData(updatedData);
        setNumericValues(result.data.reduce((acc, item) => ({ ...acc, [item.state]: item.nombre }), {}));
      }
      setOpenLoadingSimpleBackdrop(false);
    });
  };

  useEffect(() => {
    const date1 = localStorage.getItem('dateState1');
    const date2 = localStorage.getItem('dateState2');
    loadTable(date1, date2);
   
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    initializeStorage('dateState1');
    initializeStorage('dateState2');

    if (myChart) {
      myChart.destroy();
    }

    myChart = new Chart(context, {
      type: 'doughnut',
      data: {
        labels: data.map(item => item.label),
        datasets: [{
          data: data.map(item => item.value),
          backgroundColor: data.map(item => item.color),
        }],
      },
      options: {
        // ... (ajoutez ici d'autres options si nécessaire)
      },
    });

  }, [data]); // Mise à jour uniquement lorsque 'data' change

  return (
    <div style={{ display: "flex", justifyContent: "space-between", width: "1000px", height: "500px", position: "relative" }}>
      <div style={{ flex: 1 }}>
        {openLoadingSimpleBackdrop && <LoadingSimpleBackdrop />}
        <canvas ref={canvasRef} width="800" height="500" />
      </div>
      <div style={{ flex: 1, marginLeft: "20px" }}>
        <h3>Nombres des données par état :</h3>
        <ul>
          {Object.entries(numericValues).map(([state, value]) => (
            <li key={state}>{state}: {value}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StatistiqueEtatTable;
