import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { CreateTwoTone, DeleteOutlineRounded } from "@material-ui/icons";
import ConfirmationDialog from "../../tools/ConfirmationDialog";
import Message from "../../tools/Message";
import { DataGrid } from "@material-ui/data-grid";
import { escapeRegExp } from "../../../utils";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import {
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Grid
} from "@material-ui/core";
import PlanningService from "../../../services/PlanningService";
import DirectionService from "../../../services/DirectionService";
import Spacer from "../../../components/Spacer";
import BalanceConfigurationService from "../../../services/BalanceConfigurationService";
import { userDetailContext } from "../../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../../utils/privilege";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
}));

export default function BalanceConfigurationTable() {
    const navigate = useNavigate();
    const [searchText, setSearchText] = React.useState("");
    const [rows, setRows] = useState([]);
    const [id_to_delete, setId_to_delete] = useState(0);
    const [confirmationDialog, setConfirmationDialog] = React.useState({
        openDialog: false,
        messageDialog: "Voulez vous vraiment supprimer  ?",
    });
    const { openDialog, messageDialog } = confirmationDialog;
    const [message, setMessage] = React.useState({
        open: false,
        color: "green",
        messageInfo: "",
    });
    const { open, color, messageInfo } = message;

    const [dir, setDir] = useState([]);
    const [service, setService] = useState([]);

    const contextData = useContext(userDetailContext);
    const [idservice, setIdservice] = useState(0);
    const [iddir, setIddir] = React.useState(0);

    const [form, setForm] = useState({
        direction_id: 0,
        service_id: 0
    });

    //Samuela: paramètrage pour le loader
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);

    function loadTable(id_direction, id_service) {
        setOpenLoadingSimpleBackdrop(true);
        BalanceConfigurationService.findAllConfig(id_direction, id_service).then((res) => {
            if (res && res.data) {
                setRows(
                    res.data.map((row) => ({
                        ...row,
                        id: row.user_id,
                    }))
                );
                setOpenLoadingSimpleBackdrop(false);
            } else {
                setRows([]);
                setOpenLoadingSimpleBackdrop(false);
            }
        })
    }

    useEffect(() => {
        setIddir(0);
        setIdservice(0);
        loadTable(iddir, idservice);
        DirectionService.getDirection().then((res) => {
            if (res && res.status) {
                setDir(res.data);
            }
        })
    }, []);

    const handleEdit = (id) => (ev) => {
        navigate("/balance_Configuration/add/" + id);
    };
    const handleDelete = (id) => (ev) => {
        setId_to_delete(id);
        setConfirmationDialog({ ...confirmationDialog, openDialog: true, messageDialog: "Voulez vous vraiment supprimer?" });
    };
    function closeDialog() {
        setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    }
    function confirmDialog() {
        setConfirmationDialog({ ...confirmationDialog, openDialog: false });
        BalanceConfigurationService.deleteBalanceConfiguration(id_to_delete)
            .then((result) => {
                if (result.status) {
                    loadTable();
                    setMessage({
                        ...message,
                        open: true,
                        color: "green",
                        messageInfo: result.message,
                    });
                } else {
                    setMessage({
                        ...message,
                        open: true,
                        color: "red",
                        messageInfo: result.message,
                    });
                }
            })
            .catch((err) => {
                setMessage({
                    ...message,
                    open: true,
                    color: "red",
                    messageInfo: "erreur",
                });
            });
    }

    function cancelDialog() {
        setConfirmationDialog({ ...confirmationDialog, openDialog: false });
    }

    function handleCloseMessage() {
        setMessage({ ...message, open: false });
    }

    // const requestSearch = (searchValue) => {
    //     setSearchText(searchValue);
    //     const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    //     const filteredRows = [...rows].filter((row) => {
    //         return Object.keys(row).some((field) => {
    //             return searchRegex.test(row[field].toString());
    //         });
    //     });
    // };

    function handleChangeDirection(event) {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
        setIddir(value);
        PlanningService.Direction(value).then((results) => {
            if (results && results.data) {
                setService(results.data);
            }
        })
        loadTable(value, idservice);
    }
    function handleChangeService(ev) {
        const { name, value } = ev.target;
        setForm({ ...form, [name]: value });
        setIdservice(value);
        loadTable(iddir, value);
    }


    function getFullName(params) {
        return `${params.getValue(params.id, 'last_name') || ''} ${params.getValue(params.id, 'first_name') || ''
            }`;
    }

    const column = [

        {
            field: "direction_code",
            headerName: "Direction",
            width: 150,
        },
        {
            field: "service_code",
            headerName: "Service",
            width: 150,
        },
        {
            field: "fullName",
            headerName: "Nom et prénom(s)",
            width: 300,
            valueGetter: getFullName,
            sortComparator: (v1, v2, cellParams1, cellParams2) =>
                getFullName(cellParams1).localeCompare(getFullName(cellParams2)),
            // },
            // valueFormatter: (params) => {
            //     return `${params.row.last_name} ${params.row.first_name === null ? " " : params.row.first_name}`;
            // },
        },
        {
            field: "-",
            headerName: "Solde initial",
            width: 200,
            renderCell: (params) => (
                <span> <u>Le {params.row.initialBalanceDate}:</u> <b>{params.row.initial_balance}</b></span>
            )
        },
        {
            field: "real_balance",
            headerName: "Solde actuel",
            width: 200,
        },
        {
            field: "Action",
            headerName: "Actions",
            width: 200,
            renderCell: (params) => (
                <span>

                    {hasPrivilege(contextData.privileges, 'RH_Button_Modif_Balance_Configuration') &&
                        <Button onClick={handleEdit(params.row.user_id)} title="Modifier cette configuration">
                            <CreateTwoTone style={{ color: "black" }} />
                        </Button>
                    }

                    {hasPrivilege(contextData.privileges, 'RH_Button_Supprimer_Balance_Configuration') &&
                        <Button onClick={handleDelete(params.row.user_id)} title="Supprimer cette configuration">
                            <DeleteOutlineRounded style={{ color: "red" }} />
                        </Button>
                    }
                </span>
            ),
        },
    ];

    return (
        <React.Fragment>

            <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
            <Spacer y={1} />
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={5} sm={6} lg={5}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="demo-simple-select-outlined-label">Direction</InputLabel>
                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Direction" value={form.direction_id} name="direction_id" onChange={handleChangeDirection} >
                            <MenuItem value={0}>Toutes</MenuItem>

                            {dir.map((row) => (
                                <MenuItem value={row.direction_id}>{row.code}</MenuItem>

                            ))}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} md={5} sm={6} lg={5}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="demo-simple-select-outlined-label">Service</InputLabel>
                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Service" value={form.service_id} name="service_id" onChange={handleChangeService} >
                            <MenuItem value={0}>Tous</MenuItem>
                            {service.map((row) => (
                                <MenuItem value={row.service_id}>{row.code}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </Grid>
            </Grid>
            <Spacer y={3} />
            <div style={{ height: "70vh", width: "100%" }}>
                <DataGrid
                    rows={rows}
                    columns={column}
                    pageSize={7}
                    rowsPerPageOptions={[7]}
                    disableSelectionOnClick
                />
            </div>

            <ConfirmationDialog
                openDialog={openDialog}
                messageDialog={messageDialog}
                closeDialog={closeDialog}
                confirmDialog={confirmDialog}
                cancelDialog={cancelDialog}
            />
            <Message
                open={open}
                color={color}
                messageInfo={messageInfo}
                handleClose={handleCloseMessage}
            />
        </React.Fragment>
    );
}
