import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const MissionService = {
    async saveTDRMission(formData, tdrId){
        try{
            const result = tdrId?await axios.put(`${apiUrl}/tDRMission/${tdrId}`, formData)
            :await axios.post(`${apiUrl}/tDRMission`, formData);
            if (result) {
                // TODO: replace result.data.message by the token
                return result.data;
            }
        }catch(error){
            if(error.response){
              return error.response.data;
            }
        }
    },
    async checkAttachment(mission_planning_id){
        try{
            const result = await axios.get(`${apiUrl}/mission/checkAttachment/${mission_planning_id}`);
            if (result) {
                return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getTDRMission(mission_planning_id, user_id, tdrId){
        try{
            const result = tdrId?await axios.get(`${apiUrl}/tDRMission/tdr/${mission_planning_id}/${user_id}/${tdrId}`)
            :await axios.get(`${apiUrl}/tDRMission/tdr/${mission_planning_id}/${user_id}`);
            if (result) {
                return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    }
    ,
  async deleteElaborationTDR(mission_planning_id) {
    try {
      const result = await axios.delete(
        `${apiUrl}/TDRMission/${mission_planning_id}`
      );
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
}

export default MissionService;


