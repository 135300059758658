import { Button, Grid, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { set } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import MainContainer from "../../components/MainContainer";
import Spacer from "../../components/Spacer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import EquipmentService from "../../services/EquipmentService";
import Message from "../tools/Message";
import Alert from "@material-ui/lab/Alert";
import Box from '@mui/material/Box';
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 1000,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  text: {
    width: 370,
  },
  labelPosition: {
    float: 'left',
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));
function EquipmentAdd(props) {
  const classes = useStyles();
  const [form, setForm] = useState({
    equipment_name: "",
    equipment_category: "",
    equipment_code: "",
    equipment_description: "",
  });
  const [eqnIsEmpty, setEqnIsEmpty] = useState(false);
  const [eqcIsEmpty, setEqcIsEmpty] = useState(false);
  const [eqcatIsEmpty, setEqcatIsEmpty] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [message, setMessage] = useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const navigate = useNavigate();
  function CapitalString(str) {
    var nextCapital = true;
    var newString = "";
    for (var i = 0; i < str.length; i++) {
      var cur = str.charAt(i);
      if (nextCapital) {
        newString += cur.toUpperCase();
      } else {
        newString += cur.toLowerCase();
      }
      if (cur === " ") {
        nextCapital = true;
      } else {
        nextCapital = false;
      }
    }
    return newString;
  };
  function handleClick() {
    const idGroup = splitLocation[4];
    if (idGroup) {
      EquipmentService.update(form.equipment_name, form.equipment_category, form.equipment_code, form.equipment_description, idGroup)
        .then((results) => {
          if (results.status) {
            setMessage({ ...message, open: true, color: 'green', messageInfo: "Succèes" });
            navigate("/Logistic/materiels");
          }
          else {
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
          }
        })
        .catch((err) => {
          alert("error");
        });
    } else {
      EquipmentService.save(form.equipment_name, form.equipment_category, form.equipment_code, form.equipment_description)
        .then((results) => {
          if (results.status) {
            console.log(("resultat: ", results.status))
            setMessage({ ...message, open: true, color: 'green', messageInfo: "Succès" });
            navigate("/Logistic/materiels/add");
          }
          else {
            setMessage({ ...message, open: true, color: 'red', messageInfo: "Une erreur s'est produite. Veuillez réessayer" });
          }

        })
        .catch((err) => {
          alert("error");
        });
    }
    if (form.equipment_name == null || form.equipment_name == undefined || form.equipment_name == "") {
      setEqnIsEmpty(true);
    } else if (form.equipment_code == null || form.equipment_code == undefined || form.equipment_code == "") {
      setEqcIsEmpty(true);
    } else if (form.equipment_category == null || form.equipment_category == undefined || form.equipment_category == "") {
      setEqcatIsEmpty(true);
    }
  }
  const [equipment_code, setEquipment_code] = useState(0);
  function handleChange(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value })
      /**if (name == "equipment_name") {
        
        setEquipment_code((value.substring(0,2).toUpperCase()));
        setForm({ ...form, [name]: value });
        
      }else*/ if (name == "equipment_code") {
      setForm({ ...form, [name]: value.toUpperCase() })
    }
    else if (name == "equipment_name" || name == "equipment_category") {
      setForm({ ...form, [name]: CapitalString(value) })
    }
  }
  function handleBlur(ev) {
    const { name, value } = ev.target;
    setForm({ ...form, [name]: value })
    if (name == "equipment_name") {

      setEquipment_code((value.substring(0, 2).toUpperCase()));
      const code = value.substring(0, 2).toUpperCase()
      //setForm({ ...form, [name]: value });
      setForm({ ...form, equipment_code: code });

    }
  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  useEffect(() => {
    const idGroup = splitLocation[4];
    console.log(idGroup)
    if (idGroup) {

      EquipmentService.getEquipmentById(idGroup).then((info) => {
        setForm({
          equipment_name: info.data.equipment_name,
          equipment_category: info.data.equipment_category,
          equipment_code: info.data.equipment_code,
          equipment_description: info.data.equipment_description,
        });
      });
    }
  }, []);
  function handleCancel() {
    navigate("/Logistic/materiels");
  }
  return (

    <MainContainer {...headerDataImmo}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          style={{ fontWeight: 600 }}
        >
          ENREGISTRER
        </Button>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Libellé {bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Libellé"
              variant="outlined"

              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{ textTransform: 'capitalize', }}
              value={form.equipment_name}
              name="equipment_name"
            />
            {eqnIsEmpty && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Ce champ est obligatoire!</Alert>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Code {bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth

              id="outlined-helperText"
              //placeholder={equipment_code}
              size="small"
              label="Code"
              variant="outlined"
              inputProps={{ style: { textTransform: "uppercase" } }}
              value={form.equipment_code}
              onChange={handleChange}
              //
              name="equipment_code"

            />
            {eqcIsEmpty && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Ce champ est obligatoire!</Alert>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Catégories {bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth

              id="outlined-basic"

              size="small"
              label="Catégories"
              variant="outlined"
              inputProps={{ textTransform: 'capitalize', }}
              value={form.equipment_category}
              onChange={handleChange}
              //
              name="equipment_category"

            />
            {eqcatIsEmpty && (
              <>
                <Spacer y={1}></Spacer>
                <Alert severity="error">Ce champ est obligatoire!</Alert>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Description </label>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              size="small"
              label="Description"
              variant="outlined"
              onChange={handleChange}
              value={form.equipment_description}
              name="equipment_description"
            />
          </Grid>
          <Grid item xs={12}>
            <label className={classes.text}>({bullRed}):Champs obligatoires</label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  )
}
export default EquipmentAdd;