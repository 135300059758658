import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Spacer from "../../components/Spacer";
import Grid from "@material-ui/core/Grid";
import { useLocation, useNavigate} from 'react-router-dom';
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Util from "../tools/util";
import Address from "../tools/Address";
import Alert from "@material-ui/lab/Alert";
import Box from '@mui/material/Box';
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  labelPosition: {
    float: 'left',
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      float: 'right',
    },
  },
}));

export default function PersonnelInfo() {

  const navigate = useNavigate();
  const classes = useStyles();
  const [isValidCIN, setIsvalidCIN] = useState(false);
  const regexForCIN = /^[1-2]/;
  const util = new Util();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const idGroup = splitLocation[3];
  const [form, setForm] = useState({
    ddn: "",
    lieu: "",
    age: "",
    cin: "",
    marital: "",
    date_cin: "",
    address: "",
    nombre_enfant: "",
    datep_cin: "",
    lieu_cin: "",
  });

  const [ddnIsEmpty, setDdnIsEmpty] = useState(false);
  const [dateCinIsEmpty, setDateCinIsEmpty] = useState(false);
  const [lieuCinIsEmpty, setLieuCinIsEmpty] = useState(false);
  const [adressIsEmpty, setAddressIsEmpty] = useState(false);
  const [maritalIsEmpty, setMaritalIsEmpty] = useState(false);
  const [enfantIsEmpty, setNombreEnfantIsEmpty] = useState(false);
  const [fieldIsEmpty, setFieldIsEmpty] = useState(false);

  useEffect(() => {
    setForm({
      ddn: JSON.parse(localStorage.getItem("ddn")),
      lieu: JSON.parse(localStorage.getItem("lieu")),
      age: JSON.parse(localStorage.getItem(util.getAge("ddn"))),
      cin: JSON.parse(localStorage.getItem("cin")),
      marital: JSON.parse(localStorage.getItem("marital")),
      date_cin: JSON.parse(localStorage.getItem("date_cin")),
      address: JSON.parse(localStorage.getItem("adresse")),
      nombre_enfant: JSON.parse(localStorage.getItem("nombre_enfant")),
      datep_cin: JSON.parse(localStorage.getItem("datep_cin")),
      lieu_cin: JSON.parse(localStorage.getItem("lieu_cin")),
    });
  }, [setForm]);

  function handleClick() {
    if (form.ddn !== null && form.date_cin !== null && form.lieu_cin !== null && form.address !== null &&
      form.marital !== undefined && form.nombre_enfant !== null) {
      localStorage.setItem("ddn", JSON.stringify(form.ddn));
      localStorage.setItem("lieu", JSON.stringify(form.lieu));
      localStorage.setItem("age", JSON.stringify(form.age));
      localStorage.setItem("cin", JSON.stringify(form.cin));
      localStorage.setItem("marital", JSON.stringify(form.marital));
      localStorage.setItem("date_cin", JSON.stringify(form.date_cin));
      localStorage.setItem("adresse", JSON.stringify(form.address));
      localStorage.setItem("nombre_enfant", JSON.stringify(form.nombre_enfant));
      localStorage.setItem("datep_cin", JSON.stringify(form.datep_cin));
      localStorage.setItem("lieu_cin", JSON.stringify(form.lieu_cin));
      if (idGroup) {
        if (idGroup !== "information") {
         navigate("/personnel/add/" + idGroup + "/fiscale");
         window.location.reload();
        } else {
          navigate("/personnel/add/fiscale");
          window.location.reload();
        }
      } else {
        navigate("/personnel/add/fiscale");
        window.location.reload();
      }
    } else {
      if (form.ddn === null || form.ddn === undefined || form.ddn === "") {
        setDdnIsEmpty(true);
      } else if (form.date_cin === null || form.date_cin === undefined || form.date_cin === "") {
        setDateCinIsEmpty(true);
      } else if (form.lieu_cin === null || form.lieu_cin === undefined || form.lieu_cin === "") {
        setLieuCinIsEmpty(true);
      } else if (form.address === null || form.address === undefined || form.address === "") {
        setAddressIsEmpty(true);
      } else if (form.marital === null || form.marital === undefined || form.marital === "") {
        setMaritalIsEmpty(true);
      } else if (form.nombre_enfant === null || form.nombre_enfant === undefined || form.nombre_enfant === "") {
        setNombreEnfantIsEmpty(true);
      } else {
        setFieldIsEmpty(true);
      }

    }

  }
  //console.log("ato lay date", form.address);
  const [age, setAge] = useState(0);
  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value })
    if (name == "ddn") {
      setAge(util.getAge(value));
      setForm({ ...form, [name]: value });
      setDdnIsEmpty(false);
    }
    else if (name == 'address') {
      setForm({ ...form, [name]: util.CapitalString(value) })
    }
    else if (name == 'cin') {
      setForm({ ...form, [name]: value })
      const number = value.length;
      for (let index = 0; index < 16; index++) {
        if (number == 3) {
          setForm({ ...form, [name]: util.separator(value) })
        }
        else if (number == 8) {
          if (!regexForCIN.test(value)) {
            setIsvalidCIN(true);
          }
          else {
            setIsvalidCIN(false);
          }
          setForm({ ...form, [name]: util.separator(value) })
        }
        else if (number == 13) {

          setForm({ ...form, [name]: util.separator(value) })
        }
        else {
          setForm({ ...form, [name]: value })
        }
      }
      if (number == 6) {
        if (!regexForCIN.test(value)) {
          setIsvalidCIN(true);
        }
        else {
          setIsvalidCIN(false);
        }
      }
    }
    else if (name == 'lieu') {
      setForm({ ...form, [name]: util.CapitalString(value) })
    } else if (name == 'date_cin') {
      setDateCinIsEmpty(false);
    } else if (name == 'lieu_cin') {
      setLieuCinIsEmpty(false);
    } else if (name == 'marital') {
      setMaritalIsEmpty(false);
    } else if (name == 'nombre_enfant') {
      setNombreEnfantIsEmpty(false);
    }

  }

  function onChangeAddress(event) {
    const { name, value } = event.target;
    setForm({ ...form, address: value });
    setAddressIsEmpty(false);
  }

  function onChangeGPS(event) {
    const { name, value } = event.target;
    setForm({ ...form, gps: value });
  }

  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  //console.log("ato lay form", form.address);
  return (
    <React.Fragment>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Date de naissance{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth

            id="date-picker-dialog"
            size="small"
            type="date"
            variant="outlined"
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={handleChange}
            value={form.ddn}
            name="ddn"
          />
          {ddnIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Lieu de naissance</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            label="Lieu"
            variant="outlined"
            inputProps={{ textTransform: 'capitalize', }}
            onChange={handleChange}
            value={form.lieu}
            name="lieu"
          />
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Age</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            label="Age"
            variant="outlined"
            onChange={handleChange}
            value={age}
            name="age"
            disabled
          />
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>CIN</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            label="CIN"
            variant="outlined"
            onChange={handleChange}
            inputProps={{ maxLength: 18 }}
            value={form.cin}
            name="cin"
            disabled
          />
          {isValidCIN && <div style={{ color: "red", textAlign: "left" }}><span>Le sixième chiffre doit être 1 ou 2</span></div>}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Date de délivrance CIN{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            type="date"
            variant="outlined"
            onChange={handleChange}
            value={form.date_cin}
            name="date_cin"
          />
          {dateCinIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Lieu de délivrance CIN{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            variant="outlined"
            onChange={handleChange}
            value={form.lieu_cin}
            name="lieu_cin"
          />
          {lieuCinIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Date de duplicate CIN</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="outlined-basic"
            size="small"
            type="date"
            variant="outlined"
            onChange={handleChange}
            value={form.datep_cin}
            name="datep_cin"
          />
        </Grid>
        <Spacer y={9} />
        <Address x1={3} x2={8} address={form.address} onChange={onChangeAddress} isAdressNull={adressIsEmpty} onChangeGps={onChangeGPS} />
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Situation matrimoniale{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-outlined-label">Situation matrimoniale</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Type" name="marital" value={form.marital} onChange={handleChange}>
              <MenuItem value="celibataire">Célibataire</MenuItem>
              <MenuItem value="marie">Marié(e)</MenuItem>
              <MenuItem value="divorce">Divorcé(e)</MenuItem>
              <MenuItem value="veuve">Veuf(ve)</MenuItem>
            </Select>
          </FormControl>
          {maritalIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
          <label className={classes.labelPosition}>Nombre Enfant{bullRed}</label>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            type='number'
            id="outlined-basic"
            size="small"
            label="Nombre Enfant "
            variant="outlined"
            onChange={handleChange}
            value={form.nombre_enfant}
            name="nombre_enfant"
          />
          {enfantIsEmpty && (
            <>
              <Spacer y={1}></Spacer>
              <Alert severity="error">Ce champ est obligatoire!</Alert>
            </>
          )}
        </Grid>
        <Spacer y={9} />
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={8}>
          <div className={classes.root}>
            <Button variant="contained" color="primary" onClick={handleClick}>
              Valider
            </Button>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
