import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  Box,
  Collapse,
  IconButton,
  Dialog,
  DialogContent,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem, InputLabel} from "@material-ui/core";
import { extendMoment } from "moment-range";
import { useNavigate,useParams } from "react-router-dom";
import { userDetailContext } from "../../App";
import Moment from "moment";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import MettingService from "../../services/MettingService";
import MeetingRoomService from "../../services/MeetingRoomService";
import { format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import Message from "../tools/Message";
import MailConfigAddService from "../../services/MailConfigAddService";


// import React, { useState, useEffect } from "react";
import { Menu} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from 'react-router-dom';
import MailListPersonService from "../../services/MailListPersonService";
import { ExpandLess, ExpandMore, FormatBold, Label, LabelImportantOutlined } from "@material-ui/icons";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import MailBoxService from "../../services/MailBoxService";
import { headerMail } from "../../data/modules/mail-header";


const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    maxWidth: 300,
    maxHeight: '70vh',
    overflow: 'auto',
  },
}));

const MailDialogCheking = ({ opend, handleClose, scroll}) => {

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (opend) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [opend]);

  return (
    <React.Fragment>
      <Dialog
        
      >
        <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {[...new Array(50)]
              .map(
                () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
              )
              .join('\n')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};


export default MailDialogCheking;
