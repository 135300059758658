import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const ActualBalanceService={
    async findAllConfig(id_dir, id_service) {
        try {
            const result = await axios.get(`${apiUrl}/BalanceConfiguration/findAllBalance/${id_dir}/${id_service}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async findAllBalanceValidation(id_dir, id_service) {
        try {
            const result = await axios.get(`${apiUrl}/BalanceConfiguration/findAllBalanceValidation/${id_dir}/${id_service}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getConfigBalanceByUserId(id_user) {
        try {
            const result = await axios.get(`${apiUrl}/BalanceConfiguration/findBalanceConfigByUserId/${id_user}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getConfigBalanceByUserConnected() {
        try {
            const result = await axios.get(`${apiUrl}/BalanceConfiguration/findBalanceConfigByUserConnected`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}
export default ActualBalanceService;