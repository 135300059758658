import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MainContainer from "../../components/MainContainer";
import { headerDataRH } from "../../data/modules/hr-header";
import { AntTab, AntTabs } from "../../components/ant-tab";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import CompteUser from "./CompteUser";
import UtilInformation from "./UtilInformation";
import PersonnelInfo from "./PersonnelInfo";
import Setting from "./setting";
import Fiscale from "./fiscale";
import Other from "../family-member/tabs/Other";
import Contract from "../family-member/tabs/Contract";
import PersonnelService from "../../services/PersonnelService";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  lien: {
    float: "right",
    fontSize: "18px",
  },
  button: {
    fontSize: "18px",
  },
  labelPosition: {
    float: "left",
    fontSize: "20px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function PersonnelAdd(props) {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const split = splitLocation.length - 1;
  const splits = splitLocation[split];
  const id = splitLocation[3];
  const modifid = splitLocation[4];
  const [value, setValue] = React.useState();
  const [disabled, setDisabled] = useState(true);
  const [disabled2, setDisabled2] = useState(true);
  const [disabled3, setDisabled3] = useState(true);
  const [disabled4, setDisabled4] = useState(true);
  const [disabled5, setDisabled5] = useState(true);
  const [disabled6, setDisabled6] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange1 = (event, newValue) => {
    newValue = 1;
    setValue(newValue);
  };
  const handleChange2 = (event, newValue) => {
    newValue = 2;
    setValue(newValue);
  };
  const handleChange20 = (event, newValue) => {
    newValue = 0;
    setValue(newValue);
  };
  const handleChange3 = (event, newValue) => {
    newValue = 3;
    setValue(newValue);
  };
  const handleChange31 = (event, newValue) => {
    newValue = 1;
    setValue(newValue);
  };
  const handleChange4 = (event, newValue) => {
    newValue = 4;
    setValue(newValue);
  };
  const handleChange43 = (event, newValue) => {
    newValue = 2;
    setValue(newValue);
  };

  const classes = useStyles();
  const [user_id, setUser_id] = useState();
  const params = useParams();
  const userID = params.id;
  useEffect(() => {
    console.log("splits", splits);
    // if (userID !== undefined) {
    //   PersonnelService.getUpdate(id).then((result) => {
    //     console.log("resuuult***", result.data);
    //     localStorage.setItem("first_name", JSON.stringify(result.data.first_name));
    //     localStorage.setItem("last_name", JSON.stringify(result.data.last_name));
    //     localStorage.setItem("personnal_email", JSON.stringify(result.data.personnal_email));
    //     localStorage.setItem("cin", JSON.stringify(result.data.cin));
    //     localStorage.setItem("date_cin", JSON.stringify(result.data.date_cin));
    //     localStorage.setItem("lieu_cin", JSON.stringify(result.data.lieu_cin));
    //     localStorage.setItem("datep_cin", JSON.stringify(result.data.datep_cin));
    //     localStorage.setItem("ddn", JSON.stringify(result.data.ddn));
    //     localStorage.setItem("lieu", JSON.stringify(result.data.lieu));
    //     localStorage.setItem("urgence", JSON.stringify(result.data.urgence));
    //     localStorage.setItem("sex_id", JSON.stringify(result.data.sex_id));
    //     localStorage.setItem("adresse", JSON.stringify(result.data.adresse));
    //     localStorage.setItem("type", JSON.stringify(result.data.type));
    //     localStorage.setItem("nombre_enfant", JSON.stringify(result.data.nombre_enfant));
    //     localStorage.setItem("nif", JSON.stringify(result.data.nif));
    //     localStorage.setItem("stat", JSON.stringify(result.data.stat));
    //     localStorage.setItem("name_image", JSON.stringify(result.data.name_image));
    //     localStorage.setItem("flotte", JSON.stringify(result.data.flotte));
    //     localStorage.setItem("bureau", JSON.stringify(result.data.bureau));
    //     localStorage.setItem("matricule", JSON.stringify(result.data.matricule));
    //     localStorage.setItem("fonction", JSON.stringify(result.data.fonction));
    //     localStorage.setItem("porte", JSON.stringify(result.data.porte));
    //     localStorage.setItem("siege", JSON.stringify(result.data.seat));
    //     localStorage.setItem("batiment", JSON.stringify(result.data.building));
    //     localStorage.setItem("service", JSON.stringify(result.data.service));
    //     localStorage.setItem("superieur", JSON.stringify(result.data.superieur));
    //     localStorage.setItem("groups", JSON.stringify(result.data.group_id));
    //     localStorage.setItem("dir", JSON.stringify(result.data.dir));
    //     localStorage.setItem("marital", JSON.stringify(result.data.marital_status));
    //   });
    // }
    if (splits == "parametrage") {
      setValue(1);
      setDisabled(false);
    } else if (splits == "utile") {
      setValue(2);
      setDisabled(false);
      setDisabled2(false);
    } else if (splits == "information") {
      setValue(3);
      setDisabled(false);
      setDisabled2(false);
      setDisabled3(false);
    } else if (splits == "autres") {
      setValue(6);
      setDisabled(false);
      setDisabled2(false);
      setDisabled3(false);
      setDisabled4(false);
      setDisabled5(false);
      setDisabled6(false);
    } else if (id == "contract") {
      setValue(5);
      setDisabled(false);
      setDisabled2(false);
      setDisabled3(false);
      setDisabled4(false);
      setDisabled5(false);
    } else if (splits == "fiscale") {
      setValue(4);
      setDisabled(false);
      setDisabled2(false);
      setDisabled3(false);
      setDisabled4(false);
    } else if (id) {
      setValue(0);
      setDisabled(false);
      setDisabled2(false);
      setDisabled3(false);
      setDisabled4(false);
      setDisabled5(false);
      setDisabled6(false);
    } else {
      setValue(0);
    }
    PersonnelService.getMaxUserId().then((result) => {
      setUser_id(result.data);
    });
  }, []);

  return (
    <MainContainer {...headerDataRH}>
      <Grid container spacing={3}>
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <AntTabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <AntTab label="Compte Utilisateur" {...a11yProps(0)} />
              <AntTab
                label="Paramétrage"
                {...a11yProps(1)}
                disabled={disabled}
              />
              <AntTab
                label="Information Utile"
                {...a11yProps(2)}
                disabled={disabled2}
              />
              <AntTab
                label="Information Personnelle"
                {...a11yProps(3)}
                disabled={disabled3}
              />
              <AntTab
                label="Information Fiscale"
                {...a11yProps(4)}
                disabled={disabled4}
              />
              <AntTab label="Contrat" {...a11yProps(5)} disabled={disabled5} />
              <AntTab label="Autres" {...a11yProps(6)} disabled={disabled6} />
            </AntTabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <CompteUser id={userID} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Setting />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UtilInformation />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PersonnelInfo />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Fiscale />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Contract />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Other user_id={8} />
          </TabPanel>
        </div>
      </Grid>
    </MainContainer>
  );
}
export default PersonnelAdd;
