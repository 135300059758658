import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const PersonalSettingService = {
    async getPersonalSettings(){
        try{
            const result = await axios.get(`${apiUrl}/personalSettings`);
            if (result) {
              return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async getRelationshipType(){
        try{
            const result = await axios.get(`${apiUrl}/personalSettings/relationshipType`);
            if (result) {
              return result.data;
            }
        }catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async savePersonalSettings(formData){
        try{
            const result = await axios.post(`${apiUrl}/personalSettings`, {
                "name_field":formData.name_field,
                "type_field":formData.type_field,
                "required":formData.required,
                "choice":formData.choice,
                "concerning":formData.concerning
            }
            );
            if(result){
                return result.data;
            }
        }catch(error){
            if(error.response){
              return error.response.data;
            }
        }
    },
    async getPersonalSettingsById(personal_config_id){
        try{
            const result = await axios.get(`${apiUrl}/personalSettings/id/${personal_config_id}`);
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
    async updatePersonalSettings(formData){
        try{
            const result = await axios.put(`${apiUrl}/personalSettings/id/${formData.personal_config_id}`, {
                'name_field': formData.name_field,
                'type_field': formData.type_field,
                'required': formData.required
           }
           );
           if (result) {
              return result.data;
           }
        }catch(error){
            if(error.response){
              return error.response.data;
            }
        }
    },
    async deletePersonalSetting(id){
        try {
            const result = await axios.delete(`${apiUrl}/personalSettings/id/${id}`  );
            if (result) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
        }
    },
}

export default PersonalSettingService;
