import { useState, useEffect } from "react";
import { Grid, MenuItem, Select, FormControl } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { escapeRegExp } from "../../utils";
import LeaveService from "../../services/LeaveService";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import DirectionService from "../../services/DirectionService";
import { Button } from "@material-ui/core";
import FileService from "../../services/FileService";
import * as FileSaver from "file-saver";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../utils/privilege";
function MonthlyReport(props) {

    const contextData = useContext(userDetailContext);
    var myCurrentDate = new Date();
    var date = myCurrentDate.getFullYear() + '_' + (myCurrentDate.getMonth() + 1) + '_' + myCurrentDate.getDate() + '_' + myCurrentDate.getHours() + '_' + myCurrentDate.getMinutes() + '_' + myCurrentDate.getSeconds();

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const [dir, setDir] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [rows, setRows] = useState([]);
    const [month, setMonth] = useState(1);
    const [direction_id, setIddir] = useState(1);
    //Samuela: paramètrage pour le loader
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = useState(false);

    function loadTable(currentMonth, currentDirectionId) {

        setOpenLoadingSimpleBackdrop(true);
        LeaveService.getMonthlyReportForAllUser(currentMonth, currentDirectionId).then((result) => {
            if (result && result.status) {
                setRows(result.data.map((row, index) => ({ ...row, id: index })));
                setOpenLoadingSimpleBackdrop(false);
            } else {
                setRows([]);
                setOpenLoadingSimpleBackdrop(false);
            }
        })
    }
    useEffect(() => {
        loadTable(month, direction_id);
        //get all direction
        DirectionService.getDirection().then((res) => {
            if (res && res.status) {
                setDir(res.data);
            }
        })
    }, []);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = [...rows].filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
    };

    function handleClickExport() {
        LeaveService.doExport("monthly_report", month, rows, direction_id).then((res) => {
            if (res && res.status) {
                const filenameResult = res.data;
                console.log("file***", filenameResult);
                FileService.readExcelFileContent("Leaves", filenameResult).then((result) => {
                    if (result && result.status) {
                        const data = new Blob([result.data], { type: fileType });
                        FileSaver.saveAs(data, filenameResult);
                    }
                })
            }
        })
    }

    function handleChange(ev) {
        const monthSelected = ev.target.value;
        setMonth(monthSelected);
        loadTable(monthSelected, direction_id);
    }

    function handleChangeDirection(event) {
        const value = event.target.value;
        setIddir(value);
        loadTable(month, value);
    }

    const column = [
        {
            field: 'matricule',
            headerName: 'Matricule',
            width: 150,
        },
        {
            field: 'direction_code',
            headerName: 'Direction',
            width: 150,
        },
        {
            field: 'name',
            headerName: 'Nom et Prénoms',
            width: 300,
        },
        {
            field: 'function_name',
            headerName: 'Poste actuel',
            width: 300,
        },
        {
            field: 'leave_begin',
            headerName: 'Congé mois d\'avant',
            width: 300,
        },
        {
            field: 'leaveTaken',
            headerName: 'Congé consommé',
            width: 200,
        },
        {
            field: 'PermissionTaken',
            headerName: 'Autre(jours)',
            width: 200,
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 150,
            valueFormatter: (params) => {
                const total = Number(params.row.leaveTaken) + Number(params.row.PermissionTaken);
                return total;
            },
        },
        {
            field: 'real_balance',
            headerName: `Solde congé fin du mois`,
            width: 250,
        },
        {
            field: 'obs',
            headerName: 'OBS',
            width: 150,
        },
        {
            field: 'funding',
            headerName: 'Financement',
            width: 200,
        },
        {
            field: 'service_name',
            headerName: 'Direction/Service',
            width: 250,
        },
    ];
    return (
        <>
            <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={2}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={month} name="month" onChange={handleChange} >
                            <MenuItem value="1">Janvier</MenuItem>
                            <MenuItem value="2">Fevrier</MenuItem>
                            <MenuItem value="3">Mars</MenuItem>
                            <MenuItem value="4">Avril</MenuItem>
                            <MenuItem value="5">Mai</MenuItem>
                            <MenuItem value="6">Juin</MenuItem>
                            <MenuItem value="7">Juillet</MenuItem>
                            <MenuItem value="8">Août</MenuItem>
                            <MenuItem value="9">Septembre</MenuItem>
                            <MenuItem value="10">Octobre</MenuItem>
                            <MenuItem value="11">Novembre</MenuItem>
                            <MenuItem value="12">Décembre</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>

                    <FormControl fullWidth variant="outlined" size="small">
                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={direction_id} name="direction_id" onChange={handleChangeDirection}>
                            {dir.map((row) => (
                                <MenuItem value={row.direction_id}>{row.code}</MenuItem>

                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>

                    {hasPrivilege(contextData.privileges, 'RH_Button_export_monthly_report') && rows.length > 0 &&
                        <Button variant="contained" onClick={handleClickExport} style={{ fontWeight: 600 }}>EXPORTER</Button>
                    }
                </Grid>
                <Grid item xs={12}>
                    <div style={{ height: "70vh", width: "100%" }}>
                        <DataGrid
                            columns={column}
                            rows={rows}
                            pageSize={7}
                            rowsPerPageOptions={[7]}
                            disableSelectionOnClick
                            componentsProps={{
                                toolbar: {
                                    value: searchText,
                                    onChange: (event) => requestSearch(event.target.value),
                                    clearSearch: () => requestSearch(""),
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
        // <ListLeaveCardPortal/>

    );
}
export default MonthlyReport;