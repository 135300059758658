import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import UserTable from "./UserTable";
import { Button, Paper, TextField } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import Title from "../../components/Title";
import MainContainer from "../../components/MainContainer";

import { headerDataAdmin } from "../../data/modules/admin-header";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../utils/privilege";
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function UserList() {
  const navigate = useNavigate();

  const classes = useStyles();
  const contextData = useContext(userDetailContext);

  function handleClick() {
    navigate("/users/add");
  }
  return (
    <MainContainer {...headerDataAdmin}>
      <Grid container item xs={12}>
        {hasPrivilege(contextData.privileges, 'Admin-Button-Import-User') &&
          <Button
            variant="contained"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            {" "}
          Importer
        </Button>
        }
        <Spacer x={2} />
        {hasPrivilege(contextData.privileges, 'Admin-Button-Export-User') &&
          <Button
            variant="contained"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            Exporter
        </Button>
        }
        <Spacer x={2} />
        {hasPrivilege(contextData.privileges, 'Admin-User-Add') &&
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            style={{ fontWeight: 600 }}
          >
            CREER
        </Button>
        }
        <Spacer x={2} />
        {hasPrivilege(contextData.privileges, 'Admin-Button-Recherche-User') &&
          <TextField
            id="outlined-basic"
            label="Recherche"
            variant="outlined"
            size="small"
          />
        }
      </Grid>
      <Spacer y={2} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined">
            <UserTable />
          </Paper>

        </Grid>
      </Grid>
    </MainContainer>
  );
}
