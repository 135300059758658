import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper} from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import { headerDataImmo } from "../../data/modules/logistic-header";
import MeetingFinishTable from "./MeetingFinishTable";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    elevation: 0,
  },
}));

export default function MeetingFinishList(props) {
    const classes = useStyles()

    return (
        <MainContainer {...headerDataImmo}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {/* <Paper className={classes.paper} variant="outlined"> */}
                        <MeetingFinishTable />
                    {/* </Paper> */}
                </Grid>
            </Grid>
        </MainContainer>
    );
}
