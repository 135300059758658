import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const AnnualCalendarService = {
    async add(day_label, years,desc) {
        try {
          const result = await axios.post(`${apiUrl}/AnnualCalendar/`, {
            day_label,
            years,
            desc
          });
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
    }
    ,
    async saveAnnualCalendar(day_label, years,desc,annual_calendar_id) {
      try {
        const result = annual_calendar_id ?
          await axios.put(`${apiUrl}/AnnualCalendar/AnnualCalendarId/${annual_calendar_id}`, {
              day_label,
              years,
              desc
          })
          :
          await axios.post(`${apiUrl}/AnnualCalendar/add/`, {
              day_label,
              years,
              desc
          });
        if (result) {
          // TODO: replace result.data.message by the token
          return result.data;
        }
      } catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    }
    ,
      async deleteAnnualCalendar(id) {
        try {
          const result = await axios.delete(`${apiUrl}/AnnualCalendar/id/${id}`);
          if (result) {
            // TODO: replace result.data.message by the token
            return result.data;
          }
        }
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      }
      ,
      async getAnnualCalendar(){
        try {
            const result = await axios.get(`${apiUrl}/annualCalendar/`);
            if (result) {
              // TODO: replace result.data.message by the token
              return result.data;
            }
          } 
          catch (error) {
            if (error.response) {
              return error.response.data;
            }
          }
        }
      ,
      async AnnualCalendarById(id) {
        try {
          const result = await axios.get(`${apiUrl}/AnnualCalendar/id/${id}`);
          if (result) {
            // TODO: replace result.data.message by the token
    
            return result.data;
          }
        }
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
    }
    ,
    async ActivateAnnualCalendar(annual_calendar_id) {
      try {
        const result = await axios.put(`${apiUrl}/AnnualCalendar/id/${annual_calendar_id}`);
        if (result) {
          return result.data;
        }
      } catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    }
    ,
    async getMonth(id){
      try {
          const result = await axios.get(`${apiUrl}/Day/getMonth/${id}`);
          if (result) {
            // TODO: replace result.data.message by the token
            return result.data;
          }
        } 
        catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      }
    ,

    async desactivateAnnualCalendar(annual_calendar_id) {
      try {
        const result = await axios.put(`${apiUrl}/AnnualCalendar/desactivateAnnualCalendar/${annual_calendar_id}`);
        if (result) {
          return result.data;
        }
      } catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    }
    
}
export default AnnualCalendarService;
