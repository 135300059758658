import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike'],
    [{'list': 'ordered'}, {'list': 'bullet'},{'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']                                         
  ],
};

export default function CommentBox({value = '',setValue,style, class_name}) {
  return (
    <ReactQuill className={class_name} style={style} value={value} onChange={setValue} modules={modules} />
  );
}
