import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Add, Remove } from "@material-ui/icons";
import {
  Button, FormControl, Select, MenuItem, InputLabel, Radio,
  RadioGroup,
  FormControlLabel,
  ListItemText,
  Checkbox,
  Chip
} from "@material-ui/core";
import Spacer from "../../components/Spacer";
import MainContainer from "../../components/MainContainer";
import { headerDataSettings } from "../../data/modules/settings-header";
import PersonalSettingService from "../../services/PersonalSettingService";
import Alert from "@material-ui/lab/Alert";
import Message from "../tools/Message";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
const bullRed = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)', color: 'red', fontSize: '20px' }}
  >
    *
  </Box>
)

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    maxWidth: 600,
    elevation: 0,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  labelPosition: {
    float: 'left',
  },
  text: {
    width: 370,
  },
  champ: {
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

function PersonalSettingAdd(props) {
  const classes = useStyles();
  const [form, setForm] = useState({ name_field: "", type_field: "", required: "1", choice: [], concerning: [] });
  const [relationship_type, setRelationship_type] = useState([]);
  const [choice, setChoice] = useState(false);
  const [choiceTemp, setChoiceTemp] = useState([]);
  const [countChoice, setCountChoice] = useState(0);
  const [isValidName_field, setIsValidName_field] = useState(false);
  const [isValidType_field, setIsValidType_field] = useState(false);
  const [message, setMessage] = React.useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;
  const navigate = useNavigate();
  useEffect(() => {
    PersonalSettingService.getRelationshipType().then((result) => {
      if (result && result.data) {
        setRelationship_type(result.data);
      }
    });
  }, []);

  function handleClick() {
    if (form.name_field === '') {
      setIsValidName_field(true);
    }
    if (form.type_field === '') {
      setIsValidType_field(true);
    }
    if (form.name_field !== '' && form.type_field !== '') {
      for (var i = 1; i < choiceTemp.length; i++) {
        if (choiceTemp[i] != "") {
          form.choice.push({ "label_choice": choiceTemp[i] });
        }
      }
      for (var i = 0; i < form.concerning.length; i++) {
        form.concerning[i] = { "relationship_type_id": form.concerning[i] };
      }
      PersonalSettingService.savePersonalSettings(form).then((result) => {
        if (result.status) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: result.message });
          navigate("/champ_dynamique");
        } else {
          setMessage({ ...message, open: true, color: 'red', messageInfo: result.message });
        }
      }).catch((err) => {
        setMessage({ ...message, open: true, color: 'red', messageInfo: 'erreur' });
      });
    }
  }

  function handleClickChoicePlus() {
    const tempCountChoice = countChoice + 1;
    setCountChoice(tempCountChoice);
  }

  function handleClickChoiceMinus(value) {
    const tempCountChoice = countChoice - 1;
    setCountChoice(tempCountChoice);
    choiceTemp[value] = "";
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    if (name === 'type_field') {
      setChoice(value);
    }
  }

  function handleChangeChoice(event) {
    const { name, value } = event.target;
    for (var i = 0; i <= countChoice; i++) {
      choiceTemp[name] = value;
    }
  }

  const getRelationshipName = (id) => {
    for (const row of relationship_type) {
      if (row.relationship_type_id === id) {
        return row.relationship_name;
      }
    }
    return "";
  };
  //
  const items = [];

  for (var i = 0; i < countChoice - 1; i++) {
    items.push(
      <>
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
          <TextField fullWidth id="outlined-basic" size="small" label={'Choix ' + (i + 2)} /*value={choiceTemp[(i+2)]}*/ name={(i + 2)} onChange={handleChangeChoice} variant="outlined" style={{ float: 'left', width: 241 }} />
          <Button style={{ color: 'black' }} value={'choice' + (i + 2)} onClick={() => handleClickChoiceMinus((i + 2))} ><Remove /></Button>
        </Grid>
      </>
    );
  }
  if (countChoice >= 1) {
    items.push(
      <>
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
          <TextField fullWidth id="outlined-basic" size="small" label={'Choix ' + (countChoice + 1)} /*value={choiceTemp[(countChoice+1)]}*/ name={(countChoice + 1)} onChange={handleChangeChoice} variant="outlined" style={{ width: 241 }} />
          <Button style={{ color: 'black' }} value={'choice' + (countChoice + 1)} onClick={() => handleClickChoiceMinus((countChoice + 1))} ><Remove /></Button>
          <Button style={{ color: 'black' }} onClick={handleClickChoicePlus} ><Add /></Button>
        </Grid>
      </>
    );
  }

  // 
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  function handleCancel() {
    navigate("/champ_dynamique");
  }
  return (
    <MainContainer {...headerDataSettings}>
      <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
      <Grid container item xs={12}>
        <Button variant="contained" color="secondary" onClick={handleClick} style={{ fontWeight: 600 }} >ENREGISTRER</Button>
        <Spacer x={2} />
        <Button
          variant="contained"
          color="grey"
          onClick={handleCancel}
          style={{ fontWeight: 600 }}
        >
          ANNULER
        </Button>
      </Grid>
      <Spacer y={2} />
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Nom du champ{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <TextField fullWidth id="outlined-basic" size="small" label="Nom du champ" variant="outlined" value={form.name_field} name="name_field" onChange={handleChange} />
            {isValidName_field && <Alert severity="error">Veuillez remplir ce champ</Alert>}
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Type champ{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">Type Champ</InputLabel>
              <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Type Champ" value={form.type_field} name="type_field" onChange={handleChange}>
                <MenuItem value="Texte">Texte</MenuItem>
                <MenuItem value="Numérique">Numérique</MenuItem>
                <MenuItem value="Date">Date</MenuItem>
                <MenuItem value="Choix">Choix</MenuItem>
                <MenuItem value="Boolean">Boolean</MenuItem>
              </Select>
            </FormControl>
            {isValidType_field && <Alert severity="error">Veuillez remplir ce champ</Alert>}
          </Grid>
          {choice === 'Choix' && (
            <>
              <Grid item xs={4}>
                <label className={classes.labelPosition}>Choix possibles</label>
              </Grid>
              <Grid item xs={8}>
                <TextField fullWidth id="outlined-basic" size="small" label="Choix 1" /*value={choiceTemp[1]}*/ name="1" onChange={handleChangeChoice} variant="outlined" style={{ float: 'left', width: 241 }} />
                <Button style={{ color: 'black' }} onClick={handleClickChoicePlus} ><Add /></Button>
              </Grid>
              {/* <> */}
              {items}
              {/* </> */}
            </>
          )}
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Obligation{bullRed}</label>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup aria-label="required" name="required" value={form.required} onChange={handleChange}>
              <FormControlLabel value="1" control={<Radio />} label="Oui" />
              <FormControlLabel value="0" control={<Radio />} label="Non" />
            </RadioGroup>
          </Grid>
          <Grid item xs={4}>
            <label className={classes.labelPosition}>Concerné(e)s</label>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">Concerné(e)s</InputLabel>
              <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Concerné(e)s" multiple
                onChange={handleChange}
                value={form.concerning}
                name="concerning"
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={getRelationshipName(value)} className={classes.chip} />
                    ))}
                  </div>
                )}
              >
                {relationship_type.map((row) => (
                  <MenuItem value={row.relationship_type_id}>
                    <Checkbox checked={form.concerning.indexOf(row.relationship_type_id) > -1} />
                    <ListItemText primary={row.relationship_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <label className={classes.champ}>({bullRed}):Champs obligatoires</label>
          </Grid>
        </Grid>
      </Paper>
    </MainContainer>
  );
}

export default PersonalSettingAdd;

