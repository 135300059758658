import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, MenuItem, Select, FormControl } from "@material-ui/core";
import { escapeRegExp } from "../../utils";
import LeaveService from "../../services/LeaveService";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import DirectionService from "../../services/DirectionService";
import { Button } from "@material-ui/core";
import { userDetailContext } from "../../App";
import { useContext } from "react";
import { hasPrivilege } from "../../utils/privilege";
import {
    NavigateNext,
    NavigateBefore,
} from "@material-ui/icons";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableContainer, TablePagination } from "@material-ui/core";
import clsx from "clsx";
import PlanningService from "../../services/PlanningService";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PlanningPerSemesterExportFilter from './PlanningPerSemesterExportFilter';
import FileService from "../../services/FileService";
import * as FileSaver from "file-saver";

const bullBlue = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: '#3CA3FC', }}
    >

    </Box>

);
const bullGrey = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', backgroundColor: '#C5C5C5' }}
    >

    </Box>

);
const useStyles = makeStyles((theme) => ({
    refusedPlanning: {
        width: "auto",
    },
    validatePlanning: {
        width: "auto",
    },
    mois: {
        textAlign: "center",
        fontSize: "22px",
    },
    bouton: {
        justifyContent: "center",
        display: "block"
    },
    root: {
        borderTopWidth: 0.005,
        border: '0.01px solid #C5C5C5',
        borderStyle: 'outset',
        //Width: "auto",
        textAlign: "center",
        // borderCollapse: "separate",
    },
    roots: {
        backgroundColor: '#3CA3FC',
        Width: "auto",
        textAlign: "center !important",
        padding: "6px 15px 6px 15px !important",
        verticalAlign: "middle"
    },
    rootss: {
        background: "linear-gradient(to left, yellow 10%,#045DE9 90%);",
        Width: "auto",
        textAlign: "center !important",
        padding: "6px 15px 6px 15px !important",
        verticalAlign: "middle"
    },
    confirmed:
    {
        backgroundColor: 'green',
        Width: "auto",
        textAlign: "center",
    },
    refused:
    {
        backgroundColor: 'red',
        Width: "auto",
        textAlign: "center",
    },
    h2: {
        textAlign: "center",
    },
    text: {
        textAlign: "center",
        textDecoration: "underline",
    },
    texts: {
        color: "red",
        textAlign: "center",

    },
    btn: {
        display: "block",
        minWidth: "10px !important",
        margin: "auto"
    },
    dayNotCompted: {

        borderTopWidth: 0.005,
        border: '0.01px solid #C5C5C5',
        borderStyle: 'outset',
        //Width: "auto",
        textAlign: "center",
        backgroundColor: "#c5c5c5"
    },
    // name: {
    //     textAlign: "left",
    //     Width: "auto",
    //     position: "absolute",
    //     visibility: "visible",
    // },
    // name2: {
    //     textAlign: "center",
    //     Width: "auto",
    //     position: "absolute",
    //     visibility: "visible",
    //     height: "12%",
    //     textDecoration: "underline",
    //     fontWeight: "bold",
    // },
    demandeur: {
        width: "200px !important",
        height: "74px",
    },
    submitterName: {
        width: "200px",
        height: "74px",
        backgroundColor: "#C5C5C5",
    },

    functionHeader: {
        width: "300px",
        height: "74px"
    },
    functionContent: {
        width: "300px",
        height: "74px",
        backgroundColor: "#C5C5C5",
        marginLeft: "-135px"
    },
    tableContainerValidationPlanning: {
        [theme.breakpoints.up('sm')]: {
            width: '600px',
        },
        [theme.breakpoints.up('md')]: {
            width: '800px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '1000px',
        },
        [theme.breakpoints.up('xl')]: {
            width: '1200px',
            height: '50vh'
        }
    },
    tableValidationPlanning: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '650px',
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '850px',
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: '1150px',
        },
        [theme.breakpoints.up('xl')]: {
            minWidth: '1200px',
            overflowY: 'scroll'
        }
    },
    solde: {
        backgroundColor: 'rgb(255, 242, 0)',
    },
    totalLeaveTaken: {
        backgroundColor: '#C5C5C5',
        color: 'red',
        fontWeight: 'bold'
    },
    stickyNameHeader: {
        position: 'sticky',
        left: '0',
        top: '0',
        zIndex: '2',
    },
    stickyFunctionHeader: {
        position: 'sticky',
        left: '200px',
        top: '0',
        zIndex: '2',
    },
    stickySoldeHeader: {
        position: 'sticky',
        left: '500px',
        top: '0',
        zIndex: '2',
    },
    stickyNameContent: {
        position: 'sticky',
        left: '0',
        zIndex: '1',
    },
    stickyFunctionContent: {
        position: 'sticky',
        left: '200px',
        zIndex: '1',
    },
    stickySoldeContent: {
        position: 'sticky',
        left: '500px',
        zIndex: '1',
    },
    headerSticky: {
        position: 'sticky',
        top: '0',
        zIndex: '1',
    }
}));
function PlanningPerSemester(props) {

    const contextData = useContext(userDetailContext);
    var myCurrentDate = new Date();
    var date = myCurrentDate.getFullYear() + '_' + (myCurrentDate.getMonth() + 1) + '_' + myCurrentDate.getDate() + '_' + myCurrentDate.getHours() + '_' + myCurrentDate.getMinutes() + '_' + myCurrentDate.getSeconds();

    var currentYear = myCurrentDate.getFullYear();
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const [dir, setDir] = useState([]);
    const [service, setService] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [rows, setRows] = useState([]);
    const [valueSemester, setvalueSemester] = useState(1);
    const [direction_id, setIddir] = useState(1);
    const [service_id, setIdService] = useState(0);

    const [valueSemesterFilter, setvalueSemesterFilter] = useState(1);
    const [direction_id_filter, setIddirFilter] = useState(1);
    const [service_id_filter, setIdServiceFilter] = useState(1);

    //Samuela: paramètrage pour le loader
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = useState(false);

    const [monthList, setMonthList] = useState([]);
    const [idMonthSelected, setidMonthSelected] = useState(0);
    const [monthName, setMonthName] = useState("");
    const [mois, setMois] = useState([]);
    const [dayList, setdayList] = useState([]);
    const [ligne, setLigne] = useState(0);
    const [dayArray, setDayArray] = useState([]);
    const [monthBefore, setmonthBefore] = useState("");
    const [numberTable, setNumberTable] = useState(0);
    const [finalList, setFinalList] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);
    const [isOther, setIsOther] = useState(false);
    const [radioButtonValue, setRadioButtonValue] = useState(0);


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentDirectionTxt, setCurrentDirectionTxt] = useState("");
    const [currentSemesterTxt, setCurrentSemesterTxt] = useState("");
    const [currentServiceTxt, setCurrentServiceTxt] = useState("");
    const [monthWithDayArray, setmonthWithDayArray] = useState([]);
    const [semesterData, setSemesterData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const classes = useStyles();

    function loadTablePlanning(id_month, total_day, idDirection, idService) {
        setOpenLoadingSimpleBackdrop(true);
        LeaveService.getPlanningReportForAllUser(id_month, total_day, idDirection, idService).then((results) => {
            if (results) {
                if (results.data) {
                    setFinalList(results.data);
                    setNumberTable(results.data.length);
                    setOpenLoadingSimpleBackdrop(false);
                } else {
                    setFinalList([]);
                    setOpenLoadingSimpleBackdrop(false);
                }
            }
        });
    }

    function loadPlanningForAllUser(id, dayArray, direction, service, name, monthBeforeTxt){
        LeaveService.getPlanningReportForAllUser(id, dayArray, direction, service, name, monthBeforeTxt).then((results) => {
            if (results) {
                if (results.status) {
                    var dataForSemesterTemp = {};
                    dataForSemesterTemp.monthId = id;
                    dataForSemesterTemp.monthName = name;
                    dataForSemesterTemp.monthBefore = monthBeforeTxt;
                    dataForSemesterTemp.data = results.data;
                    dataForSemesterTemp.dayList = dayArray;
                    setSemesterData(oldArray => [...oldArray, dataForSemesterTemp]);
                }
            }
        });
    }

    function getDayOnMonth(month) {
        LeaveService.getDayOfMonth(month).then((res) => {
            if (res && res.status) {
                setDayArray(res.data);
            }
        });
    }

    useEffect(() => {
        // loadTable(month, direction_id);
        //get all direction
        DirectionService.getDirection().then((res) => {
            if (res && res.status) {
                setDir(res.data);
                setIddir(res.data[0].direction_id);
                setCurrentDirectionTxt(res.data[0].code);
            }
        });

        setvalueSemester(1);
        setvalueSemesterFilter(1);

        setCurrentSemesterTxt("1er semestre");

        setMonthList([
            { id: 1, name: "Janvier" },
            { id: 2, name: "Février" },
            { id: 3, name: "Mars" },
            { id: 4, name: "Avril" },
            { id: 5, name: "Mai" },
            { id: 6, name: "Juin" }
        ]);
        setMois(monthList[0]);
    }, []);

    useEffect(() => {
        if (direction_id != undefined) {
            PlanningService.Direction(direction_id).then((results) => {
                if (results && results.data) {
                    setService(results.data);
                    if (results.data.length != 0) {
                        setIdService(results.data[0].service_id);
                        setCurrentServiceTxt(results.data[0].code);
                    }
                }
            })
        }
    }, [direction_id]);

    useEffect(() => {
        setIdServiceFilter(service_id);
        loadTablePlanning(idMonthSelected, dayArray.length, direction_id, service_id);
        setSemesterData([]);
        if (monthWithDayArray.length == 6) {
            monthWithDayArray.map((item) => {
                loadPlanningForAllUser(item.id, item.dayArrayLength, direction_id, service_id, item.name, item.monthBefore);
            });
        }
    }, [service_id]);

    useEffect(() => {
        setMois(monthList[0]);
        setmonthWithDayArray([]);
        if (monthList.length != 0) {
            console.log("list", monthList);
            var iter = 0;
            monthList.map((item) => {
                var monthBefore = "";
                if (iter == 0) {
                    if (valueSemester == 1) {
                        monthBefore = "Décembre";
                        console.log("ato 1", valueSemester);
                    } else {
                        console.log("ato 2", valueSemester);
                        monthBefore = "Juin";
                    }
                } else {
                    var index = iter - 1;
                    monthBefore = monthList[index].name;
                }
                LeaveService.getDayOfMonth(item.id).then((res) => {
                    if (res && res.status) {
                        var monthwithDayArrayLengthTemp = []
                        monthwithDayArrayLengthTemp.id = item.id;
                        monthwithDayArrayLengthTemp.name = item.name;
                        monthwithDayArrayLengthTemp.dayArrayLength = res.data.length;
                        monthwithDayArrayLengthTemp.monthBefore = monthBefore;
                        setmonthWithDayArray(oldArray => [...oldArray, monthwithDayArrayLengthTemp]);
                    }
                });
                iter++;
            });
        }
    }, [monthList]);


    useEffect(() => {
        setSemesterData([]);
        if (monthWithDayArray.length == 6) {
            // console.log("list", monthList);
            monthWithDayArray.map((item) => {
                loadPlanningForAllUser(item.id, item.dayArrayLength, direction_id, service_id, item.name, item.monthBefore);
                // LeaveService.getPlanningReportForAllUser(item.id, item.dayArrayLength, direction_id, service_id).then((results) => {
                //     if (results) {
                //         if (results.status) {
                //             var dataForSemesterTemp = {};
                //             dataForSemesterTemp.monthId = item.id;
                //             dataForSemesterTemp.monthName = item.name;
                //             dataForSemesterTemp.monthBefore = item.monthBefore;
                //             dataForSemesterTemp.data = results.data;
                //             dataForSemesterTemp.dayList = item.dayArrayLength;
                //             setSemesterData(oldArray => [...oldArray, dataForSemesterTemp]);
                //         }
                //     }
                // });
            });
        }
    }, [monthWithDayArray]);

    useEffect(() => {
        if (mois !== undefined) {

            if (mois.length !== 0) {
                let IdSelectedMonth = mois.id;
                setMonthName(mois.name);
                setidMonthSelected(mois.id);
                getDayOnMonth(IdSelectedMonth);
                if (IdSelectedMonth == 1) {
                    setmonthBefore("Déc");
                } else if (IdSelectedMonth == 7) {
                    setmonthBefore("Juin");
                } else {
                    if (valueSemester == 1) { //1st semeter
                        let IdSelectedMonthBefore = IdSelectedMonth - 2;
                        let selectedMonthBefore = monthList[IdSelectedMonthBefore];
                        setmonthBefore(selectedMonthBefore.name);
                    } else { // 2nd semester

                        let IdSelectedMonthBefore = IdSelectedMonth - 8;
                        let selectedMonthBefore = monthList[IdSelectedMonthBefore];
                        setmonthBefore(selectedMonthBefore.name);
                    }
                }
            }
        }
    }, [mois]);

    useEffect(() => {
        if (dayArray != undefined && dayArray.length > 0) {
            setdayList(dayArray);
            loadTablePlanning(idMonthSelected, dayArray.length, direction_id, service_id);
        }
    }, [dayArray]);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = [...rows].filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
    };

    function handleClickExport() {
        setOpenDialog(true);
    }

    function handleChangeSemester(ev) {
        const semesterSelected = ev.target.value;
        setvalueSemester(semesterSelected);
        setvalueSemesterFilter(semesterSelected);
        //loadTable(month, direction_id);
        if (semesterSelected == 1) {
            setCurrentSemesterTxt("1er semestre");
            setMonthList([
                { id: 1, name: "Janvier" },
                { id: 2, name: "Février" },
                { id: 3, name: "Mars" },
                { id: 4, name: "Avril" },
                { id: 5, name: "Mai" },
                { id: 6, name: "Juin" }
            ]);
        } else {
            setCurrentSemesterTxt("2ème semestre");
            setMonthList([
                { id: 7, name: "Juillet" },
                { id: 8, name: "Août" },
                { id: 9, name: "Septembre" },
                { id: 10, name: "Octobre" },
                { id: 11, name: "Novembre" },
                { id: 12, name: "Décembre" }
            ]);
        }
    }

    function handleChangeDirection(event) {
        const value = event.target.value;
        setIddir(value);
        setIddirFilter(value);
        dir.map(item => {
            if (item.direction_id === value) {
                setCurrentDirectionTxt(item.code);
            }
        });
        // console.log(dir);
        // setCurrentDirectionTxt(dir[value]);
        //loadTable(month, value);
    }

    function handleChangeService(ev) {
        const value = ev.target.value;
        setIdService(value);
        setIdServiceFilter(value);
        service.map(item => {
            if (item.service_id === value) {
                setCurrentServiceTxt(item.code);
            }
        });
        // loadTable(iddir, value);
        // loadTablePlanning(idMonthSelected, dayArray.length, direction_id, value);
    }

    function handlenext() {
        const nextMonth = ligne + 1;
        const selectedMonth = monthList[nextMonth];
        setMois(selectedMonth);
        setLigne(nextMonth);

        loadTablePlanning(selectedMonth.id, dayArray.length, direction_id, service_id);
    }

    function handlebefore() {
        const nextMonth = ligne - 1;
        const selectedMonth = monthList[nextMonth];
        setMois(selectedMonth);
        setLigne(nextMonth);
        loadTablePlanning(selectedMonth.id, dayArray.length, direction_id, service_id);
    }

    function cancelDialog() {
        setOpenDialog(false);
    }

    function confirmDialog() {
        const semesterDataSorted = [...semesterData].sort((a, b) => {
            return a.monthId - b.monthId;
        });
        setIsLoading(true);
        console.log("semester data", semesterDataSorted);
        LeaveService.doExportPlanningSemester("semester_planning", valueSemester, monthName, JSON.stringify(semesterDataSorted), direction_id, dayList).then((res) => {
            if (res && res.status) {
                const filenameResult = res.data;
                FileService.readExcelFileContent("Leaves", filenameResult).then((result) => {
                    if (result && result.status) {
                        const data = new Blob([result.data], { type: fileType });
                        FileSaver.saveAs(data, filenameResult);
                        setIsLoading(false);
                        setOpenDialog(false);
                    }
                })
            }
        })
    }

    function handleChangeRadioButton(ev) {
        const value = ev.target.value;
        setIsOther(value);
        setRadioButtonValue(value);
    }

    function handleChangeSemesterFilter(ev) {
        const value = ev.target.value;
        setvalueSemesterFilter(value);
        setvalueSemester(value);
        if (value == 1) {
            setMonthList([
                { id: 1, name: "Janvier" },
                { id: 2, name: "Février" },
                { id: 3, name: "Mars" },
                { id: 4, name: "Avril" },
                { id: 5, name: "Mai" },
                { id: 6, name: "Juin" }
            ]);
        } else {
            setMonthList([
                { id: 7, name: "Juillet" },
                { id: 8, name: "Août" },
                { id: 9, name: "Septembre" },
                { id: 10, name: "Octobre" },
                { id: 11, name: "Novembre" },
                { id: 12, name: "Décembre" }
            ]);
        }
    }

    function handleChangeDirectionFilter(ev) {
        const value = ev.target.value;
        setIddirFilter(value);
        setIddir(value);
    }

    function handleChangeServiceFilter(ev) {
        const value = ev.target.value;
        setIdService(value);
        setIdServiceFilter(value);
    }

    return (
        <>
            <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />

            <PlanningPerSemesterExportFilter openDialog={openDialog} semestre={currentSemesterTxt} direction={currentDirectionTxt}
                service={service} cancelDialog={cancelDialog} dir={dir} idDirFilter={direction_id_filter} valueSemesterFilter={valueSemesterFilter}
                handleChangeRadioButton={handleChangeRadioButton} isOther={isOther} row={semesterData} radioButtonValue={radioButtonValue}
                handleChangeDirection={handleChangeDirectionFilter} handleChangeSemester={handleChangeSemesterFilter}
                handleChangeService={handleChangeServiceFilter} idServiceFilter={service_id_filter} monthSelected={monthName}
                confirmDialog={confirmDialog} isLoading={isLoading}/>

            <Grid container justifyContent="center" spacing={2} >
                <Grid item xs={2}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={valueSemester} name="semester" onChange={handleChangeSemester} >
                            <MenuItem value="1">1er semestre</MenuItem>
                            <MenuItem value="2">2ème semestre</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>

                    <FormControl fullWidth variant="outlined" size="small">
                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={direction_id} name="direction_id" onChange={handleChangeDirection}>
                            {dir.map((row) => (
                                <MenuItem value={row.direction_id}>{row.code}</MenuItem>

                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {service.length > 0 &&
                    <Grid item xs={2}>
                        <FormControl fullWidth variant="outlined" size="small">
                            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={service_id} name="service" onChange={handleChangeService} >

                                <MenuItem value={0}>{"Tous service"}</MenuItem>
                                {service.map((row) => (
                                    <MenuItem value={row.service_id}>{row.code}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={2}>

                    {hasPrivilege(contextData.privileges, 'RH_Button_export_monthly_report') && numberTable != 0 &&
                        <Button variant="contained" onClick={handleClickExport} style={{ fontWeight: 600 }}>EXPORTER</Button>
                    }
                </Grid>

                <Grid container spacing={0} alignItems="center" justify="center">
                    <Grid item xs={12} >
                        <Typography sx={{ fontSize: 15, color: 'black', fontWeight: 'bold', textAlign: 'center' }} color="text.secondary" gutterBottom>
                            <u>Légende des couleurs</u>
                        </Typography>
                    </Grid>
                    <Grid container spacing={0} alignItems="center" justify="center" item xs={12}>
                        <Grid item xs={2}>
                            <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                                {bullBlue}: Jour de congé
                    </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={{ mb: 1.5, fontSize: 12, color: 'black' }}  >
                                {bullGrey}: Jour non compté
                    </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <p className={classes.mois} >
                        {/* {ligne == 0 && ligne == monthList.length &&
                            <>
                                <div className={classes.bouton} >
                                    {monthName}
                                </div>
                            </>
                        } */}
                        {ligne == 0 && monthList.length > 1 &&
                            <>
                                <div className={classes.bouton} >
                                    {monthName}<Button onClick={handlenext}> <NavigateNext /></Button>
                                </div>
                            </>
                        }
                        {ligne != 0 && ligne != monthList.length && ligne != (monthList.length - 1) &&
                            <>
                                <div className={classes.bouton} >
                                    <Button onClick={handlebefore}> <NavigateBefore /></Button> {monthName}<Button onClick={handlenext}> <NavigateNext /></Button>
                                </div>
                            </>
                        }
                        {ligne == (monthList.length - 1) &&
                            <>
                                <div className={classes.bouton} >
                                    <Button onClick={handlebefore}> <NavigateBefore /></Button> {monthName}
                                </div>
                            </>
                        }
                        {/* <div className={classes.bouton} >
                            <Button onClick={handlebefore}> <NavigateBefore /></Button> {monthName}<Button onClick={handlenext}> <NavigateNext /></Button>
                        </div> */}
                    </p>
                </Grid >
                <Grid item xs={12} >
                    <TableContainer className={clsx(classes.root, classes.tableContainerValidationPlanning)}>
                        <Table className={classes.tableValidationPlanning} size="small" style={{ borderCollapse: "separate" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={clsx(classes.stickyNameHeader, classes.demandeur)}>Nom et prénom(s)</TableCell>
                                    <TableCell className={clsx(classes.stickyFunctionHeader, classes.functionHeader)}>Fonction</TableCell>
                                    <TableCell className={clsx(classes.stickySoldeHeader, classes.root, classes.solde)}>Solde {monthBefore}</TableCell>

                                    {dayList.map((row) => (
                                        <TableCell className={clsx(classes.root, classes.headerSticky)}>{row}</TableCell>
                                    ))}
                                    <TableCell className={clsx(classes.root, classes.totalLeaveTaken, classes.headerSticky)}>Total</TableCell>
                                    <TableCell className={clsx(classes.root, classes.solde, classes.headerSticky)}>SOLDE</TableCell>
                                </TableRow>

                            </TableHead>
                            <TableBody>
                                {finalList != undefined && finalList.length > 0 &&
                                    finalList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                        <TableRow >
                                            <TableCell style={{ minWidth: 200, maxWidth: 200 }} className={clsx(classes.stickyNameContent, classes.submitterName)}>{row["lastname"]} {row["name"]}  </TableCell>
                                            <TableCell style={{ minWidth: 300, maxWidth: 300 }} className={clsx(classes.stickyFunctionContent, classes.functionContent)}>{row["function"]}</TableCell>
                                            <TableCell className={clsx(classes.stickySoldeContent, classes.root, classes.solde)}>{row["soldeBefore"]}</TableCell>
                                            {dayList.map((index) => (
                                                <>
                                                    {row[index] != "null" && row[index] != "dayNotCompted" && <TableCell className={classes.roots}></TableCell>}
                                                    {row[index] == "null" && <TableCell className={classes.root}></TableCell>}
                                                    {row[index] == "dayNotCompted" && <TableCell className={classes.dayNotCompted}></TableCell>}
                                                </>
                                            ))}
                                            <TableCell className={clsx(classes.root, classes.totalLeaveTaken)}>{row["leaveTaken"]}</TableCell>
                                            <TableCell className={clsx(classes.root, classes.solde)}>{row["soldeAfter"]}</TableCell>
                                        </TableRow >
                                    ))
                                }
                                {finalList.length == 0 &&
                                    <p>Pas de Planning trouvé pour ce mois</p>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={finalList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
        </>

    );
}
export default PlanningPerSemester;